import React, { Component } from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";
import DatePicker from "react-mobile-datepicker";
import { Button, Card, Col, Divider, Form, Input, message, Modal, Radio, Row, Spin } from "antd";
import { apiCall, promiseTimeout } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./LoyaltyTrx.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOBILE_DATEPICKER_MONTH_LONG_MAP } from "../shared/utils/constants";
import { now } from "../shared/helpers/dateHelper";
import uuid from "uuid/v4";
import connect from "react-redux/es/connect/connect";
import { loyaltyOperations } from "../state/ducks/loyalty";
import { processDecimalInput, validEmail, validPhone } from "../shared/helpers/stringHelper";

const FormItem = Form.Item;

class LoyaltyTrx extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            pos_trx_code: '',
            trx_amount: 0,
            trx_amountText: '0',
            user_identifier_type: 'phone',
            user_identifier_string: '',
            user: null,
            searchUserLoading: false,
            isDatePickerOpened: false,
            formLoading: false
        };

        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
    }

    setFormLoading = state => {
        this.setState({
            formLoading: state
        });
    };

    onChangePosTrxCode = e => {
        this.setState({
            pos_trx_code: e.target.value
        });
    };

    onChangeUserIdentifierType = e => {
        this.setState({
            user_identifier_type: e.target.value,
            user_identifier_string: ''
        });
    };

    onChangeUserIdentifierString = e => {
        this.setState({
            user_identifier_string: e.target.value
        });
    };

    onSearchCustomer = e => {
        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error('Please fill out the user contact.');
            return;
        }

        if (this.state.user_identifier_type === 'phone') {
            if (!validPhone(this.state.user_identifier_string)) {
                message.error('Please input a valid phone number.');
                return;
            }
        }

        if (this.state.user_identifier_type === 'email') {
            if (!validEmail(this.state.user_identifier_string)) {
                message.error('Please input a valid email.');
                return;
            }
        }

        this.setSearchUserLoading(true);
        if (navigator.onLine) {
            promiseTimeout(
                5000,
                new Promise((resolve, reject) => {
                    return apiCall(
                        endpoint.GET_CUSTOMER_INFO,
                        'post',
                        {
                            user_identifier_type: this.state
                                .user_identifier_type,
                            user_identifier_string: this.state
                                .user_identifier_string
                        },
                        response => {
                            resolve(response);
                        },
                        response => {
                            reject(response);
                        }
                    );
                })
            )
                .then(result => {
                    // API call success
                    this.setState({ user: result.data }, () => {
                        this.setSearchUserLoading(false);
                    });
                })
                .catch(error => {
                    if (error === 'timeout') {
                        // Network is not available
                        if (
                            this.state.user_identifier_type === 'qr' ||
                            this.state.user_identifier_type === 'reference'
                        ) {
                            message.error(
                                'Koneksi internet dibutuhkan untuk pencarian customer, mohon coba kembali'
                            );
                        } else {
                            message.warning(
                                'Koneksi internet tidak stabil, data customer akan disinkronisasi otomatis ketika koneksi telah pulih kembali'
                            );
                        }
                    } else {
                        // API throwing error object
                        message.error(error.data.error.message);
                    }

                    if (
                        this.state.user_identifier_type !== 'qr' &&
                        this.state.user_identifier_type !== 'reference'
                    ) {
                        this.setEmptyCustomer();
                    }
                    this.setSearchUserLoading(false);
                });
        } else {
            this.setEmptyCustomer();
            this.setSearchUserLoading(false);
            message.warning(
                'No internet connection, customer data will be synchronized later.'
            );
        }
    };

    setSearchUserLoading = state => {
        this.setState({
            searchUserLoading: state
        });
    };

    handleQrScan = data => {
        if (data) {
            this.setState(
                { user_identifier_string: data },
                this.onSearchCustomer
            );
        }
    };

    handleQrError = err => {
        console.error(err);
    };

    // CUSTOMER DATA
    onChangeUserName = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_name: e.target.value
            }
        });
    };

    onChangeUserDateOfBirth = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_birth_date: e.target.value
            }
        });
    };

    onChangeUserGender = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_gender: e.target.value
            }
        });
    };

    onChangeUserNotes = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_notes: e.target.value
            }
        });
    };

    resetCustomer = () => {
        this.setState({
            user: null
        });
    };

    setEmptyCustomer = () => {
        this.setState({
            user: {
                user_code: null,
                user_name: '',
                user_gender: '',
                user_birth_date: '',
                user_notes: '',
                retrieve_time: ''
            }
        });
    };

    onDatePickerSelected = dateInput => {
        const parsedDate = [
            dateInput.getFullYear(),
            dateInput.getMonth() + 1,
            dateInput.getDate()
        ].join('-');
        this.setState(
            {
                user: {
                    ...this.state.user,
                    user_birth_date: parsedDate
                }
            },
            () => {
                this.toggleDatePicker();
            }
        );
    };

    toggleDatePicker = () => {
        this.setState({
            isDatePickerOpened: !this.state.isDatePickerOpened
        });
    };

    onSubmitForm = e => {
        e.preventDefault();
        e.stopPropagation();
        const stateData = { ...this.state };
        let validForm = true;

        if (empty(stateData.pos_trx_code)) {
            validForm = false;
            message.error('Purchase No. harus diisi!');
        }

        if (
            empty(stateData.user_identifier_type) ||
            empty(stateData.user_identifier_string)
        ) {
            validForm = false;
            message.error('Data customer harus diisi!');
        }

        if (validForm) {
            this.setFormLoading(true);

            const formData = {
                is_offline: 0,
                trxs: [
                    {
                        loyalty_trx_head_id: '',
                        local_trx_id: uuid(),
                        pos_trx_code: stateData.pos_trx_code,
                        trx_amount: stateData.trx_amount,
                        user_identifier_type: stateData.user_identifier_type,
                        user_identifier_string:
                            stateData.user_identifier_string,
                        user_code: empty(stateData.user)
                            ? ''
                            : stateData.user.user_code,
                        user_name: empty(stateData.user)
                            ? ''
                            : stateData.user.user_name,
                        user_birth_date: empty(stateData.user)
                            ? ''
                            : stateData.user.user_birth_date,
                        user_gender: empty(stateData.user)
                            ? ''
                            : stateData.user.user_gender,
                        user_notes: empty(stateData.user)
                            ? ''
                            : stateData.user.user_notes,
                        user_retrieve_time:
                            'retrieve_time' in stateData.user
                                ? stateData.user.retrieve_time
                                : '',
                        trx_time: now(),
                        local_outlet_id: this.props.posConfig.outlet_id
                    }
                ]
            };

            if (navigator.onLine) {
                promiseTimeout(
                    5000,
                    new Promise((resolve, reject) => {
                        return apiCall(
                            endpoint.POST_LOYALTY_TRX,
                            'post',
                            formData,
                            response => {
                                resolve(response);
                            },
                            response => {
                                reject(response);
                            }
                        );
                    })
                )
                    .then(result => {
                        // API call success
                        if ('loyalty_trx_head_id' in result.data[0]) {
                            const loyalty_trx_head_id =
                                result.data[0].loyalty_trx_head_id;
                            if (!empty(loyalty_trx_head_id)) {
                                this.props.addLoyaltyTrx({
                                    ...formData.trxs[0],
                                    loyalty_trx_head_id: loyalty_trx_head_id
                                });
                                this.setFormLoading(false);
                                Modal.success({
                                    title: 'Loyalty Transaction Success!',
                                    content:
                                        'The transaction has been done online.',
                                    onOk: () => {
                                        this.formReset();
                                    }
                                });
                            } else {
                                this.props.addLocalLoyaltyTrx(formData.trxs[0]);
                                this.setFormLoading(false);
                                Modal.success({
                                    title: 'Loyalty Transaction Success!',
                                    content:
                                        'Saving transaction to local device.',
                                    onOk: () => {
                                        this.formReset();
                                    }
                                });
                            }
                        }
                    })
                    .catch(error => {
                        if (error === 'timeout') {
                            // Network is not available
                            this.props.addLocalLoyaltyTrx(formData.trxs[0]);
                            this.setFormLoading(false);
                            Modal.success({
                                title: 'Loyalty Transaction Success!',
                                content: 'Saving transaction to local device.',
                                onOk: () => {
                                    this.formReset();
                                }
                            });
                        } else {
                            // API throwing error object
                            message.error(error.data.error.message);
                            this.setFormLoading(false);
                        }
                    });
            } else {
                this.props.addLocalLoyaltyTrx(formData.trxs[0]);
                this.setFormLoading(false);
                Modal.success({
                    title: 'Loyalty Transaction Success!',
                    content: 'Saving transaction to local device.',
                    onOk: () => {
                        this.formReset();
                    }
                });
            }
        }
    };

    formReset = () => {
        this.setState({
            pos_trx_code: '',
            trx_amount: 0,
            user_identifier_type: 'phone',
            user_identifier_string: '',
            user: null,
            searchUserLoading: false,
            isDatePickerOpened: false,
            formLoading: false
        });
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const resetCustomerBtnLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 8,
                    offset: 4
                }
            }
        };
        const {
            pos_trx_code,
            trx_amountText,
            user_identifier_type,
            user_identifier_string,
            user,
            searchUserLoading,
            formLoading
        } = this.state;

        return (
            <Spin spinning={formLoading}>
                <Card title="Transaksi Loyalty" bordered={false}>
                    <Form onSubmit={this.onSubmitForm}>
                        <Row gutter={48}>
                            <Col sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Purchase No."
                                >
                                    <Input
                                        type="text"
                                        onChange={this.onChangePosTrxCode}
                                        value={pos_trx_code}
                                    />
                                </FormItem>

                                <FormItem
                                    {...formItemLayout}
                                    label="Transaction Amount"
                                >
                                    <Input
                                        defaultValue={0}
                                        type="text"
                                        addonBefore={this.currencyCode}
                                        size="large"
                                        onChange={e => {
                                            const inputCallback = (value, valueText) => {
                                                this.setState({
                                                    trx_amount: value,
                                                    trx_amountText: valueText
                                                });
                                            };
                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                        }}
                                        value={trx_amountText}
                                    />
                                </FormItem>

                                <Spin spinning={searchUserLoading}>
                                    <FormItem
                                        {...formItemLayout}
                                        label="Cari Customer Berdasarkan"
                                    >
                                        <Radio.Group
                                            defaultValue="phone"
                                            buttonStyle="solid"
                                            disabled={user !== null}
                                            value={user_identifier_type}
                                            onChange={
                                                this.onChangeUserIdentifierType
                                            }
                                        >
                                            <Radio.Button value="phone">
                                                Phone
                                            </Radio.Button>
                                            <Radio.Button value="qr">
                                                QR Code
                                            </Radio.Button>
                                            <Radio.Button value="email">
                                                Email
                                            </Radio.Button>
                                            <Radio.Button value="reference">
                                                Reference
                                            </Radio.Button>
                                        </Radio.Group>
                                    </FormItem>

                                    {user_identifier_type === 'phone' && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Phone Number"
                                        >
                                            <Input.Search
                                                addonBefore="+62"
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Check"
                                                size="large"
                                                type="tel"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === 'qr' && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Scan QR Code"
                                        >
                                            <QrReader
                                                onScan={this.handleQrScan}
                                                onError={this.handleQrError}
                                                style={{
                                                    width: 300,
                                                    maxWidth: '100%',
                                                    marginBottom: 10,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            />

                                            <Input.Search
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Check"
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === 'email' && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Email"
                                        >
                                            <Input.Search
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Check"
                                                size="large"
                                                type="email"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === 'reference' && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Reference"
                                        >
                                            <Input.Search
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Check"
                                                size="large"
                                            />
                                        </FormItem>
                                    )}
                                </Spin>

                                {user !== null && (
                                    <FormItem {...resetCustomerBtnLayout}>
                                        <Button
                                            type="danger"
                                            size="large"
                                            block={true}
                                            onClick={this.resetCustomer}
                                            htmlType="button"
                                        >
                                            Change Customer
                                        </Button>
                                    </FormItem>
                                )}
                            </Col>

                            <Col md={8} sm={12} xs={24}>
                                <h4>
                                    Customer Data{' '}
                                    {user !== null
                                        ? user.user_code === null
                                            ? '(New Customer)'
                                            : ''
                                        : ''}
                                </h4>
                                {user === null && `No customer chosen`}
                                {user !== null && (
                                    <React.Fragment>
                                        <div className={styles.formGroup}>
                                            <label>Customer Name</label>
                                            <Input
                                                type="text"
                                                onChange={this.onChangeUserName}
                                                value={user.user_name}
                                            />
                                        </div>

                                        <div className={styles.formGroup}>
                                            <label>Date of Birth</label>
                                            <Input
                                                type="text"
                                                onChange={
                                                    this.onChangeUserDateOfBirth
                                                }
                                                onFocus={this.toggleDatePicker}
                                                readOnly={true}
                                                value={user.user_birth_date}
                                            />
                                            <DatePicker
                                                theme="ios"
                                                dateFormat={[
                                                    'DD',
                                                    [
                                                        'MM',
                                                        month =>
                                                            MOBILE_DATEPICKER_MONTH_LONG_MAP[
                                                                month
                                                            ]
                                                    ],
                                                    'YYYY'
                                                ]}
                                                showFormat="DD-MM-YYYY"
                                                confirmText="APPLY"
                                                cancelText="CANCEL"
                                                max={new Date()}
                                                min={new Date(1930, 0, 1)}
                                                // value={new Date(user.user_birth_date)}
                                                isOpen={
                                                    this.state
                                                        .isDatePickerOpened
                                                }
                                                onSelect={
                                                    this.onDatePickerSelected
                                                }
                                                onCancel={this.toggleDatePicker}
                                            />
                                        </div>

                                        <div className={styles.formGroup}>
                                            <label>Gender</label>
                                            <Radio.Group
                                                defaultValue=""
                                                buttonStyle="solid"
                                                value={user.user_gender}
                                                onChange={
                                                    this.onChangeUserGender
                                                }
                                            >
                                                <Radio.Button value={1}>
                                                    Laki-laki
                                                </Radio.Button>
                                                <Radio.Button value={2}>
                                                    Perempuan
                                                </Radio.Button>
                                            </Radio.Group>
                                        </div>

                                        <div className={styles.formGroup}>
                                            <label>Notes</label>
                                            <Input.TextArea
                                                type="text"
                                                onChange={
                                                    this.onChangeUserNotes
                                                }
                                                rows={4}
                                                value={user.user_notes}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <FormItem {...tailFormItemLayout}>
                                <Button
                                    type="primary"
                                    size="large"
                                    block={true}
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </FormItem>
                        </Row>
                    </Form>
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    addLoyaltyTrx: loyaltyOperations.addLoyaltyTrx,
    addLocalLoyaltyTrx: loyaltyOperations.addLocalLoyaltyTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoyaltyTrx);
