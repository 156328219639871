import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import "react-html5-camera-photo/build/css/index.css";
import {
    Card,
    Form,
    Input,
    Button,
    Row,
    Col,
    Spin,
    Icon,
    Select,
    message,
    Radio, InputNumber
} from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./InventoryStockAdjust.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import connect from "react-redux/es/connect/connect";
import { loyaltyOperations } from "../state/ducks/loyalty";
import { posOperations } from "../state/ducks/pos";
import uuid from 'uuid/v4';
import ErrorHandler from "../shared/classes/ErrorHandler";
import { INV_ADJ_TYPE_DECREASE, INV_ADJ_TYPE_FINAL, INV_ADJ_TYPE_INCREASE} from "../shared/utils/constants";

class InventoryStockAdjust extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            local_trx_id: uuid(),
            inv_sloc_id: this.props.posConfig.storage_locations.length === 1 ? this.props.posConfig.storage_locations[0].inv_sloc_id : null,
            fetchingItems: false,
            fetchedItems: [],
            selectedItem: null,
            currentStockQty: null,
            currentBadStockQty: null,
            qty: 0,
            notes: null,
            adjustmentType: '',
            formLoading: false,
            itemSku: ''
        };

        this.state = { ...this.initialState };
        this.qtyAdjust = React.createRef();
        this.fetchItem = debounce(this.fetchItem, 500);
    }

    componentDidMount() {
        this.fetchItem("");
    }

    fetchItem = (value) => {
        this.setState({ fetchedItems: [], fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?item_name=${value}`,
            "get",
            null,
            (res) => {
                this.setState({ fetchedItems: res.data, fetchingItems: false });
            }
        );
    };

    fetchItemWithSku = () => {
        this.setState({ fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?sku=${this.state.itemSku}`,
            "get",
            null,
            (res) => {
                this.setState({
                    selectedItem: res.data[0],
                    itemSku: '',
                    fetchingItems: false
                }, ()=>{
                    this.qtyAdjust.current.focus();
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    onChangeItemSKU = e => {
        this.setState({
            itemSku: e.target.value
        });
    };

    handleSkuEnterPressed = e => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            this.onSubmitSKU();
        }
    };

    onSubmitSKU = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        let isValidForm = true;

        if(empty(this.state.itemSku)){
            isValidForm = false;
            message.error('SKU produk harus diisi');
        }

        if(isValidForm){
            this.fetchItemWithSku();
        }
    };

    loadSelectedItemDetails = () => {
        const {selectedItem, inv_sloc_id} = this.state;
        apiCall(
            endpoint.GET_INVENTORY_CHECK_ITEM_STOCK + `?pos_item_variant_id=${selectedItem.pos_item_variant_id}&inv_sloc_id=${inv_sloc_id}`,
            "get",
            null,
            (res) => {
                this.setState({
                    currentStockQty: res.data.qty,
                    currentBadStockQty: res.data.drv_bad_qty,
                    qty: res.data.qty,
                    bad_qty: res.data.drv_bad_qty
                });
            }
        );
    };

    onFormSubmit = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            local_trx_id,
            inv_sloc_id,
            selectedItem,
            qty,
            bad_qty,
            notes,
            currentStockQty,
            currentBadStockQty,
            adjustmentType
        } = this.state;

        let final_qty = 0;
        let final_bad_qty = 0;

        if(adjustmentType === INV_ADJ_TYPE_FINAL){
            final_qty = qty;
            final_bad_qty = bad_qty;
        }

        if(adjustmentType === INV_ADJ_TYPE_INCREASE){
            final_qty = qty + currentStockQty;
            final_bad_qty = bad_qty + currentBadStockQty;
        }

        if(adjustmentType === INV_ADJ_TYPE_DECREASE){
            final_qty = currentStockQty - qty;
            final_bad_qty = currentBadStockQty - bad_qty;
        }

        if(empty(selectedItem)){
            message.error('Barang harus dipilih.');
        }else if(empty(adjustmentType)){
            message.error('Tipe penyesuaian harus diisi.');
        }else{
            this.setState({formLoading:true});
            apiCall(
                endpoint.POST_INVENTORY_ADJUSTMENT,
                "post",
                {
                    local_trx_id: local_trx_id,
                    inv_sloc_id: inv_sloc_id,
                    pos_item_variant_id: selectedItem.pos_item_variant_id,
                    inv_unit_id: selectedItem.inv_unit_id,
                    qty: final_qty,
                    bad_qty, final_bad_qty,
                    notes: notes
                },
                (res) => {
                    message.success('Barang berhasil disesuaikan.');
                    this.setState({
                        ...this.initialState,
                        local_trx_id: uuid()
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },() => {
                    this.setState({formLoading:false});
                }
            );
        }
    };

    render() {
        const {
            inv_sloc_id,
            selectedItem,
            fetchingItems,
            fetchedItems,
            currentStockQty,
            currentBadStockQty,
            qty,
            bad_qty,
            notes,
            adjustmentType,
            formLoading
        } = this.state;
        const { posConfig } = this.props;

        const pageTitle = (
            <React.Fragment>
                <Icon type="gold"/>
                &nbsp;&nbsp; Inventory - Penyesuaian Stok
            </React.Fragment>
        );
        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={10} sm={24}>
                        <Spin spinning={formLoading}>
                            <Form onSubmit={this.onFormSubmit}>
                                <React.Fragment>
                                    <div className={styles.formItem}>
                                        <label>Lokasi Stok</label>
                                        {!empty(posConfig.storage_locations) && (
                                            <Select
                                                showSearch
                                                placeholder="Pilih Lokasi Stok"
                                                optionFilterProp="children"
                                                defaultValue={inv_sloc_id}
                                                onChange={e => {
                                                    this.setState({ inv_sloc_id: e });
                                                }}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {posConfig.storage_locations.map(row => {
                                                    return <Select.Option key={`StorageLocation${row.inv_sloc_id}`} value={row.inv_sloc_id}>{row.location_name}</Select.Option>;
                                                })}

                                            </Select>
                                        )}
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Catatan / Keterangan</label>
                                        <Input.TextArea
                                            type="text"
                                            rows={3}
                                            onChange={e => {
                                                this.setState({notes: e.target.value});
                                            }}
                                            value={notes}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Barang</label>
                                        {posConfig.pos_item_search_by_sku === 1 && (
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{ width: '50%' }}>
                                                        <Input
                                                            placeholder="SKU produk"
                                                            onChange={
                                                                this.onChangeItemSKU
                                                            }
                                                            onKeyDown={this.handleSkuEnterPressed}
                                                            addonBefore={<Icon type="barcode" />}
                                                            autoFocus
                                                        />
                                                    </td>
                                                    <td style={{ width: '50%' }}>
                                                        <Select
                                                            showSearch={true}
                                                            placeholder="Pilih Barang"
                                                            notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                            filterOption={false}
                                                            onSearch={this.fetchItem}
                                                            onChange={e => {
                                                                this.setState({
                                                                    selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                                }, this.loadSelectedItemDetails);
                                                            }}
                                                            style={{ width: "100%" }}
                                                            value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                                        >
                                                            {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                                        </Select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}

                                        {posConfig.pos_item_search_by_sku !== 1 && (
                                            <Select
                                                showSearch={true}
                                                placeholder="Pilih Barang"
                                                notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                filterOption={false}
                                                onSearch={this.fetchItem}
                                                onChange={e => {
                                                    this.setState({
                                                        selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                    }, this.loadSelectedItemDetails);
                                                }}
                                                style={{ width: "100%" }}
                                                value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                            >
                                                {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                            </Select>
                                        )}
                                    </div>

                                    {!empty(selectedItem) && (
                                        <React.Fragment>
                                            <div className={styles.formItem}>
                                                <label>Tipe Penyesuaian</label>
                                                <Radio.Group
                                                    value={adjustmentType}
                                                    onChange={e => {
                                                        if(e.target.value === INV_ADJ_TYPE_FINAL){
                                                            this.setState({
                                                                adjustmentType: e.target.value,
                                                                qty: currentStockQty,
                                                                bad_qty: currentBadStockQty
                                                            });
                                                        }else{
                                                            this.setState({
                                                                adjustmentType: e.target.value,
                                                                qty: 0,
                                                                bad_qty: 0
                                                            });
                                                        }
                                                    }}
                                                    buttonStyle="solid">
                                                    <Radio.Button value={INV_ADJ_TYPE_FINAL}>Stok Final</Radio.Button>
                                                    <Radio.Button value={INV_ADJ_TYPE_INCREASE}>Penambahan</Radio.Button>
                                                    <Radio.Button value={INV_ADJ_TYPE_DECREASE}>Pengurangan</Radio.Button>
                                                </Radio.Group>
                                            </div>

                                            {!empty(adjustmentType) && adjustmentType === INV_ADJ_TYPE_FINAL && (
                                                <React.Fragment>
                                                    <h2>Stok Baik</h2>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Stok Baik Saat Ini ({selectedItem.unit_name})</label>
                                                        <Input
                                                            type="text"
                                                            disabled={true}
                                                            value={currentStockQty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Penyesuaian ({selectedItem.unit_name})</label>
                                                        <InputNumber
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({qty: e});
                                                            }}
                                                            value={qty}
                                                            ref={this.qtyAdjust}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Selisih ({selectedItem.unit_name})</label>
                                                        <p>{parseInt(empty(qty)?0:qty) - parseInt(empty(currentStockQty)?0:currentStockQty)}</p>
                                                    </div>

                                                    <h2>Stok Buruk</h2>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Stok Buruk Saat Ini ({selectedItem.unit_name})</label>
                                                        <Input
                                                            type="text"
                                                            disabled={true}
                                                            value={currentBadStockQty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Penyesuaian ({selectedItem.unit_name})</label>
                                                        <InputNumber
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({bad_qty: e});
                                                            }}
                                                            value={bad_qty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Selisih ({selectedItem.unit_name})</label>
                                                        <p>{parseInt(empty(bad_qty)?0:bad_qty) - parseInt(empty(currentBadStockQty)?0:currentBadStockQty)}</p>
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {!empty(adjustmentType) && adjustmentType === INV_ADJ_TYPE_INCREASE && (
                                                <React.Fragment>
                                                    <h2>Stok Baik</h2>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Stok Baik Saat Ini ({selectedItem.unit_name})</label>
                                                        <Input
                                                            type="text"
                                                            disabled={true}
                                                            value={currentStockQty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Penambahan ({selectedItem.unit_name})</label>
                                                        <InputNumber
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({qty: e});
                                                            }}
                                                            value={qty}
                                                            ref={this.qtyAdjust}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Final ({selectedItem.unit_name})</label>
                                                        <p>{parseInt(empty(qty)?0:qty) + parseInt(empty(currentStockQty)?0:currentStockQty)}</p>
                                                    </div>

                                                    <h2>Stok Buruk</h2>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Stok Buruk Saat Ini ({selectedItem.unit_name})</label>
                                                        <Input
                                                            type="text"
                                                            disabled={true}
                                                            value={currentBadStockQty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Penyesuaian ({selectedItem.unit_name})</label>
                                                        <InputNumber
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({bad_qty: e});
                                                            }}
                                                            value={bad_qty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Final ({selectedItem.unit_name})</label>
                                                        <p>{parseInt(empty(bad_qty)?0:bad_qty) + parseInt(empty(currentBadStockQty)?0:currentBadStockQty)}</p>
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {!empty(adjustmentType) && adjustmentType === INV_ADJ_TYPE_DECREASE && (
                                                <React.Fragment>
                                                    <h2>Stok Baik</h2>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Stok Baik Saat Ini ({selectedItem.unit_name})</label>
                                                        <Input
                                                            type="text"
                                                            disabled={true}
                                                            value={currentStockQty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Pengurangan ({selectedItem.unit_name})</label>
                                                        <InputNumber
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({qty: e});
                                                            }}
                                                            value={qty}
                                                            ref={this.qtyAdjust}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Final ({selectedItem.unit_name})</label>
                                                        <p>{parseInt(empty(currentStockQty)?0:currentStockQty) - parseInt(empty(qty)?0:qty)}</p>
                                                    </div>

                                                    <h2>Stok Buruk</h2>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Stok Buruk Saat Ini ({selectedItem.unit_name})</label>
                                                        <Input
                                                            type="text"
                                                            disabled={true}
                                                            value={currentBadStockQty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Pengurangan ({selectedItem.unit_name})</label>
                                                        <InputNumber
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({bad_qty: e});
                                                            }}
                                                            value={bad_qty}
                                                        />
                                                    </div>
                                                    <div className={styles.formItem}>
                                                        <label>Qty Final ({selectedItem.unit_name})</label>
                                                        <p>{parseInt(empty(currentBadStockQty)?0:currentBadStockQty) - parseInt(empty(bad_qty)?0:bad_qty)}</p>
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {!empty(adjustmentType) && (
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    block={true}
                                                    htmlType="submit"
                                                    style={{marginTop:30, marginBottom: 100}}
                                                >
                                                    Sesuaikan Stok
                                                </Button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    addLoyaltyTrx: loyaltyOperations.addLoyaltyTrx,
    addLocalLoyaltyTrx: loyaltyOperations.addLocalLoyaltyTrx,
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryStockAdjust);
