import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Icon, Input, message, Row, Spin, Table } from "antd";
import Highlighter from "react-highlight-words";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./InventoryHistory.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import ErrorHandler from "../shared/classes/ErrorHandler";
import Synchronizer from "../shared/classes/Synchronizer";
import { posOperations } from "../state/ducks/pos";
import { connect } from "react-redux";

class InventoryStockList extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            stocks: []
        };

        this.state = { ...this.initialState };
    }

    componentDidMount() {
        this.loadStocks();
    }

    loadStocks = () => {
        this.setState({ formLoading: true });

        Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            true,
            () => {
                apiCall(
                    endpoint.GET_INVENTORY_STOCK_LIST,
                    "get",
                    null,
                    res => {
                        this.setState({
                            stocks: res.data
                        });
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                    },
                    res => {
                        this.setState({
                            formLoading: false
                        });
                    }
                );
            }
        ).catch(err => {
            message.error("Gagal melakukan proses sinkronisasi, silakan mencoba kembali dengan refresh halaman ini.");
            this.setState({ formLoading: false });
        });
    };

    customFilterProps = (dataIndex, dataLabel) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataLabel}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }}/>
        ),
        onFilter: (value, record) =>
            (record[dataIndex] || "")
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={empty(text) ? "" : text.toString()}
            />
        )
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {
        const {
            formLoading, stocks
        } = this.state;

        const stockColumns = [
            {
                title: "Tipe Barang",
                dataIndex: "item_type",
                key: "item_type",
                sorter: (a, b) => a.item_type?.toLowerCase().localeCompare(b.item_type?.toLowerCase())
            },
            {
                title: "Kategori",
                dataIndex: "category_name",
                key: "category_name",
                sorter: (a, b) => a.category_name?.toLowerCase().localeCompare(b.category_name?.toLowerCase()),
                ...this.customFilterProps("category_name", "Kategori")
            },
            {
                title: "Nama Barang",
                dataIndex: "item_name",
                key: "item_name",
                sorter: (a, b) => a.item_name?.toLowerCase().localeCompare(b.item_name?.toLowerCase()),
                ...this.customFilterProps("item_name", "Nama Barang")
            },
            {
                title: "Varian",
                dataIndex: "variant_name",
                key: "variant_name",
                sorter: (a, b) => a.variant_name?.toLowerCase().localeCompare(b.variant_name?.toLowerCase()),
                ...this.customFilterProps("variant_name", "Varian")
            },
            {
                title: "Qty Baik",
                dataIndex: "qty",
                key: "qty",
                sorter: (a, b) => a.qty - b.qty
            },
            {
                title: "Qty Rusak",
                dataIndex: "drv_bad_qty",
                key: "drv_bad_qty",
                sorter: (a, b) => a.drv_bad_qty - b.drv_bad_qty
            },
            {
                title: "Unit",
                dataIndex: "unit_name",
                key: "unit_name",
                sorter: (a, b) => a.unit_name?.length - b.unit_name?.length
            }
        ];

        const pageTitle = (
            <React.Fragment>
                <Icon type="gold"/>
                &nbsp;&nbsp; Daftar Stok
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={18} sm={24}>
                        <Spin spinning={formLoading}>
                            <div className={styles.formItem}
                                 style={{
                                     overflow: 'auto'
                                 }}
                            >
                                <Table rowKey="trx_id" columns={stockColumns} dataSource={stocks} pagination={false}/>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

const mapStateToProps = state => {
    return {
        posState: state.posState
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryStockList);