import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { authOperations } from "../state/ducks/auth";
import { apiCall, promiseTimeout } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import { Alert, Button, Card, Col, Collapse, Form, Input, message, Modal, Row, Spin, Table } from "antd";
import { number_format, processDecimalInput } from "../shared/helpers/stringHelper";
import { empty } from "../shared/helpers/generalHelper";
import styles from "./ShiftManagement.module.scss";
import uuid from "uuid/v4";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../shared/utils/constants";
import PosPriceCalculator from "../shared/classes/PosPriceCalculator";

class ShiftManagement extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            startingCashAmount: 0,
            startingCashAmountText: "0",
            endingCashAmount: 0,
            endingCashAmountText: "0",
            newExpenseTitle: "",
            newExpenseAmount: 0,
            isPrint: false,
            selectedShiftHistory: null
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
    }

    renderPaymentMethodCards = paymentMethods => {
        const { posTrx, activeShift } = this.props;
        let total = 0;
        const elem = paymentMethods.filter(row => {
            return row.payment_method_name !== "Cash";
        }).map(row => {
            const completedTrx = posTrx.filter(
                trx =>
                    trx.trx_status_id === 3 &&
                    !empty(trx.payments.find(payment =>
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )) &&
                    moment(trx.trx_local_end_time).isBetween(activeShift.shift_start, moment().format(MOMENT_SQL_DATETIME_FORMAT))
            );

            const paymentReducer = (total, payment) => {
                return total + payment.payment_amount;
            };
            const completedTrxCount = completedTrx.length;
            const completedTrxReducer = (total, trx) => {
                return total + trx.payments.filter(payment => (
                    payment.payment_method.table_name === row.table_name &&
                    payment.payment_method.table_id === row.table_id
                )).reduce(paymentReducer, 0);
            };
            const completedTrxTotal = completedTrx.reduce(
                completedTrxReducer,
                0
            );
            total += completedTrxTotal;

            return (
                <tr key={`CardTrxSummary${row.payment_method_name}`}>
                    <td>{row.payment_method_name} ({completedTrxCount} transaksi)</td>
                    <td style={{ textAlign: "right" }}>{number_format(completedTrxTotal, 0)} {this.currencyCode}</td>
                </tr>
            );
        });

        return { elem, total };
    };

    renderPaymentMethodCash = paymentMethods => {
        const { posTrx, posConfig, activeShift } = this.props;
        let total = 0;
        const elem = paymentMethods.filter(row => {
            return row.payment_method_name === "Cash";
        }).map(row => {
            const completedTrx = posTrx.filter(
                trx => trx.trx_status_id === 3 &&
                    !empty(trx.payments.find(payment =>
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )) &&
                    moment(trx.trx_local_end_time).isBetween(activeShift.shift_start, moment().format(MOMENT_SQL_DATETIME_FORMAT))
            );

            const paymentReducer = (total, payment) => {
                return total + payment.payment_amount;
            };
            const completedTrxCount = completedTrx.length;
            const completedTrxReducer = (total, trx) => {
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    trx, posConfig, this.currencyCode
                ).grandTotal;
                const reducer = (accumulator, currentValue) =>
                    accumulator + currentValue.payment_amount;
                const totalPayment = trx.payments.reduce(reducer, 0);
                const change = Math.round(grandTotal) - parseInt(totalPayment);

                return total + change + trx.payments.filter(payment => (
                    payment.payment_method.table_name === row.table_name &&
                    payment.payment_method.table_id === row.table_id
                )).reduce(paymentReducer, 0);
            };
            const completedTrxTotal = completedTrx.reduce(
                completedTrxReducer,
                0
            );
            total += completedTrxTotal;

            return (
                <tr key={`CardTrxSummary${row.payment_method_name}`}>
                    <td>{row.payment_method_name} ({completedTrxCount} transaksi)</td>
                    <td style={{ textAlign: "right" }}>{number_format(completedTrxTotal, 0)} {this.currencyCode}</td>
                </tr>
            );
        });

        return { elem, total };
    };

    getTrxDrvByPaymentMethod = paymentMethods => {
        const { posTrx, posConfig, activeShift } = this.props;
        let total_amount = 0;
        let total_trx_count = 0;
        const drvPaymentMethods = paymentMethods.map(row => {
            const completedTrx = posTrx.filter(
                trx => trx.trx_status_id === 3 &&
                    !empty(trx.payments.find(payment =>
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )) &&
                    moment(trx.trx_local_end_time).isBetween(activeShift.shift_start, moment().format(MOMENT_SQL_DATETIME_FORMAT))
            );

            const paymentReducer = (total, payment) => {
                return total + payment.payment_amount;
            };
            const completedTrxCount = completedTrx.length;
            const completedTrxReducer = (total, trx) => {
                let change = 0;
                if (row.payment_method_name === "Cash") {
                    const grandTotal = PosPriceCalculator.calculateGrandTotal(
                        trx, posConfig, this.currencyCode
                    ).grandTotal;
                    const reducer = (accumulator, currentValue) =>
                        accumulator + currentValue.payment_amount;
                    const totalPayment = trx.payments.reduce(reducer, 0);
                    change = Math.round(grandTotal) - parseInt(totalPayment);
                }

                return total + change + trx.payments.filter(payment => (
                    payment.payment_method.table_name === row.table_name &&
                    payment.payment_method.table_id === row.table_id
                )).reduce(paymentReducer, 0);
            };
            const completedTrxTotal = completedTrx.reduce(
                completedTrxReducer,
                0
            );
            total_amount += completedTrxTotal;
            total_trx_count += completedTrxCount;

            return {
                payment_method_name: row.payment_method_name,
                trx_count: completedTrxCount,
                trx_amount: completedTrxTotal
            };
        });

        return { drvPaymentMethods, total_amount, total_trx_count };
    };

    onStartNewShift = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { activeShift, posConfig } = this.props;
        const { startingCashAmount } = this.state;

        if (!empty(activeShift)) {
            message.error(
                "Tidak dapat memulai shift baru karena masih ada shift yang aktif."
            );
            return;
        }

        /*if (empty(startingCashAmount)) {
            message.error("Uang cash awal harus diisi!");
            return;
        }*/

        const newShiftData = {
            local_shift_id: uuid(),
            shift_start: moment().format(MOMENT_SQL_DATETIME_FORMAT),
            shift_end: null,
            business_id: posConfig.business_id,
            business_user_id: posConfig.business_user_id,
            business_outlet_id: posConfig.business_outlet_id,
            outlet_id: posConfig.outlet_id,
            cash_start: startingCashAmount ?? 0,
            cash_end_actual: null,
            cashier_name: posConfig.business_user_firstname,
            outlet_name: posConfig.outlet_name,
            expenses: []
        };
        this.props.setActiveShiftData(newShiftData);
        message.success("Shift baru berhasil dimulai.");
    };

    onEndShift = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { activeShift, posConfig } = this.props;
        const { endingCashAmount } = this.state;

        // if (empty(endingCashAmount)) {
        //     message.error("Uang cash akhir aktual harus diisi!");
        //     return;
        // }

        Modal.confirm({
            title: "Apakah Anda yakin ingin mengakhiri shift?",
            content: "Shift akan diakhiri dan perhitungan cash akan diulang dari awal.",
            onOk: () => {
                let formData = {
                    ...activeShift,
                    cash_end_actual: empty(endingCashAmount) ? 0 : endingCashAmount,
                    shift_end: moment().format(MOMENT_SQL_DATETIME_FORMAT),
                    trxs: this.getTrxDrvByPaymentMethod(posConfig.payment_method),
                    isSynced: false
                };
                promiseTimeout(
                    10000,
                    new Promise((resolve, reject) => {
                        return apiCall(
                            endpoint.POST_POS_SHIFT,
                            "post",
                            {
                                shifts: [{
                                    ...formData
                                }]
                            },
                            response => {
                                resolve(response);
                            },
                            response => {
                                reject(response);
                            }
                        );
                    })
                )
                    .then(result => {
                        // API call success
                        if (!empty(result.data)) {
                            result.data.map(res => {
                                if (res.status === "success") {
                                    this.props.endShift({
                                        ...formData,
                                        isSynced: true,
                                        pos_shift_id: res.pos_shift_id
                                    });
                                }
                                return res;
                            });
                            this.setState(this.initialState);
                            message.success("Shift berhasil diakhiri.");
                        }
                    })
                    .catch(error => {
                        if (error === "timeout") {
                            // Network is not available
                            console.log("Sync failed, no network available.");
                        } else {
                            // API throwing error object
                            console.log(
                                "Sync failed, error: " + JSON.stringify(error)
                            );
                        }

                        message.error("Gagal melakukan sinkronisasi, silakan cek jaringan internet dan coba lagi.");
                    });
            },
            onCancel: () => {
                console.log("End shift cancelled");
            }
        });
    };

    renderShiftHistory = (shiftHistory) => {
        return (
            <Collapse accordion>
                {
                    shiftHistory.map(row => {
                        const readableShiftStart = moment(row.shift_start, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT);
                        const readableShiftEnd = moment(row.shift_end, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT);
                        const headerText = `${readableShiftStart} - ${readableShiftEnd}`;
                        const cashPaymentMethod = row.trxs.drvPaymentMethods.find(row2 => row2.payment_method_name === "Cash");
                        const otherPaymentMethod = row.trxs.drvPaymentMethods.filter(row2 => row2.payment_method_name !== "Cash");
                        const totalExpensesReducer = (expenses, row) => {
                            return {
                                expenseAmount: expenses.expenseAmount + row.expenseAmount
                            };
                        };
                        const totalExpenses = empty(row.expenses) ? 0 : row.expenses.reduce(totalExpensesReducer).expenseAmount;
                        const expensesDataSource = row.expenses;
                        const expensesColumns = [
                            {
                                title: "Nama Pengeluaran",
                                dataIndex: "expenseTitle",
                                key: "expenseTitle"
                            },
                            {
                                title: "Jumlah",
                                dataIndex: "expenseAmount",
                                key: "expenseAmount",
                                render: (text, record, index) => {
                                    return number_format(text);
                                }
                            }
                        ];

                        return (
                            <Collapse.Panel header={headerText} key={row.local_shift_id}>
                                <table className={styles.shiftHistoryTable}>
                                    <tbody>
                                    <tr>
                                        <td>Kasir</td>
                                        <td style={{ width: 10 }}>:</td>
                                        <td>{row.cashier_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Outlet</td>
                                        <td>:</td>
                                        <td>{row.outlet_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Shift Dimulai</td>
                                        <td>:</td>
                                        <td>{readableShiftStart}</td>
                                    </tr>
                                    <tr>
                                        <td>Shift Selesai</td>
                                        <td>:</td>
                                        <td>{readableShiftEnd}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <h2>Pemasukan</h2>

                                <h4>Cash</h4>
                                <table className={styles.shiftHistoryTable}>
                                    <tbody>
                                    <tr>
                                        <td>Cash Awal</td>
                                        <td style={{ width: 10 }}>:</td>
                                        <td style={{ textAlign: "right" }}>{number_format(row.cash_start)} {this.currencyCode}</td>
                                    </tr>
                                    <tr>
                                        <td>Cash dari Penjualan</td>
                                        <td>:</td>
                                        <td style={{ textAlign: "right" }}>
                                            {number_format(cashPaymentMethod.trx_amount)} {this.currencyCode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Pengeluaran</td>
                                        <td>:</td>
                                        <td style={{ textAlign: "right" }}>
                                            {number_format(totalExpenses)} {this.currencyCode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cash Akhir Seharusnya</td>
                                        <td>:</td>
                                        <td style={{ textAlign: "right" }}>
                                            {number_format(row.cash_start + cashPaymentMethod.trx_amount - totalExpenses)} {this.currencyCode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cash Aktual</td>
                                        <td>:</td>
                                        <td style={{ textAlign: "right" }}>
                                            {number_format(row.cash_end_actual)} {this.currencyCode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Jumlah Transaksi</td>
                                        <td>:</td>
                                        <td style={{ textAlign: "right" }}>
                                            {number_format(cashPaymentMethod.trx_count)} Transaksi
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                {otherPaymentMethod.map(row2 => {
                                    if(row2.trx_amount <= 0 && row2.trx_count <= 0){
                                        return []
                                    }else{
                                        return (
                                            <React.Fragment key={row2.payment_method_name + row2.trx_amount}>
                                                <h4>{row2.payment_method_name}</h4>
                                                <table className={styles.shiftHistoryTable}>
                                                    <tbody>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>:</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            {number_format(row2.trx_amount)} {this.currencyCode}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jumlah Transaksi</td>
                                                        <td>:</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            {number_format(row2.trx_count)} Transaksi
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </React.Fragment>
                                        );
                                    }
                                })}

                                <h4>Total seluruh transaksi = {number_format(row.trxs.total_amount)} {this.currencyCode}</h4>

                                <br/><br/>
                                <h2>Pengeluaran</h2>
                                <Table rowKey="key"
                                       dataSource={expensesDataSource}
                                       columns={expensesColumns}
                                       pagination={false}
                                       locale={{
                                           emptyText: "Belum ada pengeluaran"
                                       }}
                                />

                                <div style={{
                                    textAlign: "center",
                                    margin: "20px 0"
                                }}>
                                    <Button type={"primary"} onClick={() => {
                                        this.setState({
                                            selectedShiftHistory: row,
                                            isPrint: true
                                        });
                                    }}>Print Preview</Button>
                                </div>

                            </Collapse.Panel>
                        );
                    })
                }
            </Collapse>
        );
    };

    renderPrintShiftHistory = (shiftHistory) => {
        const readableShiftStart = moment(shiftHistory.shift_start, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT);
        const readableShiftEnd = moment(shiftHistory.shift_end, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT);
        // const headerText = `${readableShiftStart} - ${readableShiftEnd}`;
        const cashPaymentMethod = shiftHistory.trxs.drvPaymentMethods.find(shiftHistory2 => shiftHistory2.payment_method_name === "Cash");
        const otherPaymentMethod = shiftHistory.trxs.drvPaymentMethods.filter(shiftHistory2 => shiftHistory2.payment_method_name !== "Cash");
        const totalExpensesReducer = (expenses, shiftHistory) => {
            return {
                expenseAmount: expenses.expenseAmount + shiftHistory.expenseAmount
            };
        };
        const totalExpenses = empty(shiftHistory.expenses) ? 0 : shiftHistory.expenses.reduce(totalExpensesReducer).expenseAmount;
        const expensesDataSource = shiftHistory.expenses;
        const expensesColumns = [
            {
                title: "Nama Pengeluaran",
                dataIndex: "expenseTitle",
                key: "expenseTitle"
            },
            {
                title: "Jumlah",
                dataIndex: "expenseAmount",
                key: "expenseAmount",
                render: (text, record, index) => {
                    return number_format(text);
                }
            }
        ];

        return (
            <>
                <div className={styles.printHistoryButtons}>
                    <Button type={"primary"} onClick={() => {
                        window.print();
                    }}>Print</Button>
                    &nbsp;
                    <Button type={"danger"} onClick={() => {
                        this.setState({
                            selectedShiftHistory: null,
                            isPrint: false
                        });
                    }}>Close Preview</Button>
                </div>

                <div className={styles.printHistory}>
                    <h2>Shift History</h2>
                    <table className={styles.shiftHistoryTable}>
                        <tbody>
                        <tr>
                            <td>Kasir</td>
                            <td style={{ width: 10 }}>:</td>
                            <td>{shiftHistory.cashier_name}</td>
                        </tr>
                        <tr>
                            <td>Outlet</td>
                            <td>:</td>
                            <td>{shiftHistory.outlet_name}</td>
                        </tr>
                        <tr>
                            <td>Shift Dimulai</td>
                            <td>:</td>
                            <td>{readableShiftStart}</td>
                        </tr>
                        <tr>
                            <td>Shift Selesai</td>
                            <td>:</td>
                            <td>{readableShiftEnd}</td>
                        </tr>
                        </tbody>
                    </table>

                    <h2>Pemasukan</h2>

                    <h4>Cash</h4>
                    <table className={styles.shiftHistoryTable}>
                        <tbody>
                        <tr>
                            <td>Cash Awal</td>
                            <td style={{ width: 10 }}>:</td>
                            <td style={{ textAlign: "right" }}>{number_format(shiftHistory.cash_start)} {this.currencyCode}</td>
                        </tr>
                        <tr>
                            <td>Cash dari Penjualan</td>
                            <td>:</td>
                            <td style={{ textAlign: "right" }}>
                                {number_format(cashPaymentMethod.trx_amount)} {this.currencyCode}
                            </td>
                        </tr>
                        <tr>
                            <td>Pengeluaran</td>
                            <td>:</td>
                            <td style={{ textAlign: "right" }}>
                                {number_format(totalExpenses)} {this.currencyCode}
                            </td>
                        </tr>
                        <tr>
                            <td>Cash Akhir Seharusnya</td>
                            <td>:</td>
                            <td style={{ textAlign: "right" }}>
                                {number_format(shiftHistory.cash_start + cashPaymentMethod.trx_amount - totalExpenses)} {this.currencyCode}
                            </td>
                        </tr>
                        <tr>
                            <td>Cash Aktual</td>
                            <td>:</td>
                            <td style={{ textAlign: "right" }}>
                                {number_format(shiftHistory.cash_end_actual)} {this.currencyCode}
                            </td>
                        </tr>
                        <tr>
                            <td>Jumlah Transaksi</td>
                            <td>:</td>
                            <td style={{ textAlign: "right" }}>
                                {number_format(cashPaymentMethod.trx_count)} Transaksi
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {otherPaymentMethod.map(shiftHistory2 => {
                        if(shiftHistory2.trx_amount <= 0 && shiftHistory2.trx_count <= 0){
                            return [];
                        }else {
                            return (
                                <React.Fragment key={shiftHistory2.payment_method_name + shiftHistory2.trx_amount}>
                                    <h4>{shiftHistory2.payment_method_name}</h4>
                                    <table className={styles.shiftHistoryTable}>
                                        <tbody>
                                        <tr>
                                            <td>Total</td>
                                            <td>:</td>
                                            <td style={{ textAlign: "right" }}>
                                                {number_format(shiftHistory2.trx_amount)} {this.currencyCode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jumlah Transaksi</td>
                                            <td>:</td>
                                            <td style={{ textAlign: "right" }}>
                                                {number_format(shiftHistory2.trx_count)} Transaksi
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            );
                        }
                    })}

                    <h4>Total seluruh transaksi = {number_format(shiftHistory.trxs.total_amount)} {this.currencyCode}</h4>

                    <br/><br/>
                    <h2>Pengeluaran</h2>
                    <Table shiftHistoryKey="key"
                           dataSource={expensesDataSource}
                           columns={expensesColumns}
                           pagination={false}
                           locale={{
                               emptyText: "Belum ada pengeluaran"
                           }}
                    />
                </div>

                <div className={styles.printHistoryButtons}>
                    <Button type={"primary"} onClick={() => {
                        window.print();
                    }}>Print</Button>
                    &nbsp;
                    <Button type={"danger"} onClick={() => {
                        this.setState({
                            selectedShiftHistory: null,
                            isPrint: false
                        });
                    }}>Close Preview</Button>
                </div>
            </>


        );
    };

    addNewExpense = () => {
        const { newExpenseTitle, newExpenseAmount } = this.state;
        let isValid = true;

        if (empty(newExpenseTitle)) {
            isValid = false;
            message.error("Nama pengeluaran harus diisi");
        }

        if (empty(newExpenseAmount)) {
            isValid = false;
            message.error("Jumlah pengeluaran harus diisi");
        }

        if (isValid) {
            const expenses = this.props.activeShift.expenses.concat({
                key: uuid(),
                expenseTitle: newExpenseTitle,
                expenseAmount: newExpenseAmount
            });

            this.props.setActiveShiftData({ expenses });
            message.success("Pengeluaran berhasil ditambahkan");

            this.setState({
                newExpenseTitle: "",
                newExpenseAmount: 0
            });
        }
    };

    deleteExpense = (key) => {
        Modal.confirm({
            title: "Apakah Anda yakin ingin menghapus pengeluaran?",
            // content: "Shift akan diakhiri dan perhitungan cash akan diulang dari awal.",
            onOk: () => {
                if (!empty(key)) {
                    const expenses = this.props.activeShift.expenses.filter(row => row.key !== key);
                    this.props.setActiveShiftData({ expenses });
                    message.success("Pengeluaran berhasil dihapus");
                } else {
                    message.error("Gagal menghapus pengeluaran");
                }
            },
            onCancel: () => {
            }
        });
    };

    render() {
        const { posConfig, activeShift, shiftHistory } = this.props;
        const { formLoading, startingCashAmountText, endingCashAmountText, newExpenseTitle, newExpenseAmount } = this.state;
        const expensesDataSource = empty(activeShift) ? [] : activeShift.expenses;
        const totalExpensesReducer = (expenses, row) => {
            return {
                expenseAmount: expenses.expenseAmount + row.expenseAmount
            };
        };
        const totalExpenses = empty(activeShift) || empty(activeShift.expenses) ? 0 : activeShift.expenses.reduce(totalExpensesReducer).expenseAmount;
        const expensesColumns = [
            {
                title: "Nama Pengeluaran",
                dataIndex: "expenseTitle",
                key: "expenseTitle"
            },
            {
                title: "Jumlah",
                dataIndex: "expenseAmount",
                key: "expenseAmount",
                render: (text, record, index) => {
                    return number_format(text);
                }
            },
            {
                title: "Action",
                dataIndex: "key",
                key: "key",
                render: (text, record, index) => {
                    return (
                        <Button onClick={() => {
                            this.deleteExpense(record.key);
                        }}
                                type={"danger"}
                                size={"small"}
                        >
                            Delete
                        </Button>
                    );
                }
            }
        ];

        return (
            <React.Fragment>
                {!this.state.isPrint && (
                    <Row gutter={16}>
                        <Col md={12}>
                            <Card
                                title="Shift Berjalan"
                                bordered={false}
                                style={{ marginBottom: 16 }}
                            >
                                <Spin spinning={formLoading}>
                                    {empty(activeShift) && (
                                        <React.Fragment>
                                            Belum ada shift yang aktif saat ini.
                                            <br/>
                                            <br/>
                                            {posConfig.pos_shift_enforced === 1 && (
                                                <Alert message="Shift harus dimulai terlebih dahulu sebelum dapat mulai beraktivitas" type="error"/>
                                            )}
                                            <br/>
                                            <Form onSubmit={this.onStartNewShift}>
                                                <div className={styles.formItem}>
                                                    <label>Uang Cash Awal</label>
                                                    <Input
                                                        defaultValue={0}
                                                        type="text"
                                                        addonBefore={this.currencyCode}
                                                        size="large"
                                                        onChange={e => {
                                                            const inputCallback = (value, valueText) => {
                                                                this.setState({
                                                                    startingCashAmount: value,
                                                                    startingCashAmountText: valueText
                                                                });
                                                            };
                                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                        }}
                                                        value={startingCashAmountText}
                                                    />
                                                </div>

                                                <div className={styles.formItem}>
                                                    <Button
                                                        htmlType="submit"
                                                        type="primary"
                                                    >
                                                        Mulai Shift Baru
                                                    </Button>
                                                </div>
                                            </Form>
                                        </React.Fragment>
                                    )}

                                    {!empty(activeShift) && (
                                        <React.Fragment>
                                            <h3>Total Transaksi</h3>
                                            <p>Sejak {moment(activeShift.shift_start).format(MOMENT_READABLE_DATETIME_FORMAT)}</p>
                                            <table className={styles.activeShiftTable}>
                                                <tbody>
                                                {this.renderPaymentMethodCards(posConfig.payment_method).elem}
                                                {this.renderPaymentMethodCash(posConfig.payment_method).elem}

                                                <tr>
                                                    <td><strong>TOTAL</strong></td>
                                                    <td style={{ textAlign: "right" }}>{number_format(this.renderPaymentMethodCards(posConfig.payment_method).total + this.renderPaymentMethodCash(posConfig.payment_method).total)} {this.currencyCode}</td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}>&nbsp;</td>
                                                </tr>

                                                <tr>
                                                    <td>Cash Awal</td>
                                                    <td style={{ textAlign: "right" }}>{number_format(activeShift.cash_start)} {this.currencyCode}</td>
                                                </tr>

                                                <tr>
                                                    <td>Cash dari Penjualan</td>
                                                    <td style={{ textAlign: "right" }}>{number_format(this.renderPaymentMethodCash(posConfig.payment_method).total)} {this.currencyCode}</td>
                                                </tr>

                                                <tr>
                                                    <td>Pengeluaran</td>
                                                    <td style={{ textAlign: "right" }}>{number_format(totalExpenses)} {this.currencyCode}</td>
                                                </tr>

                                                <tr>
                                                    <td>Cash Akhir Seharusnya</td>
                                                    <td style={{ textAlign: "right" }}>{number_format(activeShift.cash_start + this.renderPaymentMethodCash(posConfig.payment_method).total - totalExpenses)} {this.currencyCode}</td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <br/>
                                            <br/>

                                            <h3>Akhiri Shift</h3>
                                            <Form onSubmit={this.onEndShift}>
                                                <div className={styles.formItem}>
                                                    <label>Uang Cash Akhir Aktual</label>
                                                    <Input
                                                        defaultValue={0}
                                                        type="text"
                                                        addonBefore={this.currencyCode}
                                                        size="large"
                                                        onChange={e => {
                                                            const inputCallback = (value, valueText) => {
                                                                this.setState({
                                                                    endingCashAmount: value,
                                                                    endingCashAmountText: valueText
                                                                });
                                                            };
                                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                        }}
                                                        value={endingCashAmountText}
                                                    />
                                                </div>

                                                <div className={styles.formItem}>
                                                    <Button htmlType="submit" type="primary">Akhiri Shift Sekarang</Button>
                                                </div>
                                            </Form>
                                        </React.Fragment>
                                    )}
                                </Spin>
                            </Card>
                        </Col>
                        <Col md={12}>

                            {!empty(activeShift) && (
                                <Card
                                    title="Pengeluaran"
                                    bordered={false}
                                    style={{ marginBottom: 16 }}
                                >
                                    <Table rowKey="key"
                                           dataSource={expensesDataSource}
                                           columns={expensesColumns}
                                           pagination={false}
                                           locale={{
                                               emptyText: "Belum ada pengeluaran"
                                           }}
                                    />

                                    <br/><br/>

                                    <h2>Buat Pengeluaran Baru</h2>

                                    <div className={styles.formItem}>
                                        <h4>
                                            Nama Pengeluaran
                                        </h4>
                                        <Input
                                            size="large"
                                            style={{
                                                width: 400,
                                                maxWidth: "100%"
                                            }}
                                            onChange={e => {
                                                this.setState({
                                                    newExpenseTitle: e.target.value
                                                });
                                            }}
                                            value={newExpenseTitle}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <h4>Jumlah Pengeluaran ({this.currencyCode})</h4>
                                        <Input
                                            defaultValue={0}
                                            type="text"
                                            addonBefore={this.currencyCode}
                                            size="large"
                                            style={{
                                                width: 400,
                                                maxWidth: "100%"
                                            }}
                                            onChange={e => {
                                                const val = parseInt(e.target.value.replace(/\D/g, ""));
                                                this.setState({
                                                    newExpenseAmount: val
                                                });
                                            }}
                                            value={number_format(newExpenseAmount, 0)}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <Button
                                            htmlType="button"
                                            disabled={
                                                empty(newExpenseTitle) ||
                                                empty(newExpenseAmount)
                                            }
                                            type="primary"
                                            onClick={this.addNewExpense}
                                        >
                                            Tambahkan
                                        </Button>
                                    </div>
                                </Card>
                            )}

                            <Card
                                title="Riwayat Shift"
                                bordered={false}
                                style={{ marginBottom: 16 }}
                            >
                                {empty(shiftHistory) && `Tidak ada shift sebelumnya`}
                                {!empty(shiftHistory) && this.renderShiftHistory(shiftHistory)}
                            </Card>
                        </Col>
                    </Row>
                )}

                {
                    this.state.isPrint &&
                    !empty(this.state.selectedShiftHistory) &&
                    this.renderPrintShiftHistory(this.state.selectedShiftHistory)
                }

            </React.Fragment>
            /*<div className={styles.wrapper}>

                Welcome to GD Business Cashier Application
                <br/><br/>

            </div>*/
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        posTrx: state.posState.posTrx,
        posConfig: state.authState.userData,
        activeShift: state.authState.shiftManagement.active,
        shiftHistory: state.authState.shiftManagement.history,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    setUserData: authOperations.setUserData,
    setActiveShiftData: authOperations.setActiveShiftData,
    endShift: authOperations.endShift
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftManagement);
