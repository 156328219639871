export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const SET_CASHIER_CONFIGS = 'SET_CASHIER_CONFIGS';
export const MODIFY_CASHIER_CONFIGS = 'MODIFY_CASHIER_CONFIGS';

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER';
export const SET_APP_STATES = 'SET_APP_STATES';
export const RESET_APP_STATES = 'RESET_APP_STATES';

export const SET_ACTIVE_SHIFT_DATA = 'SET_ACTIVE_SHIFT_DATA';
export const END_SHIFT = 'END_SHIFT';