import React, { Component } from "react";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import { apiCall } from "../shared/helpers/networkHelper";
import { GET_REPORT_PRODUCT_CONSUMPTION } from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { Col, Empty, Row, Spin, Table } from "antd";
import { number_format } from "../shared/helpers/stringHelper";
import { connect } from "react-redux";

class ReportProductConsumptionPrint extends Component {

    constructor(props) {
        super(props);

        const url = new URL(window.location.href);

        this.state = {
            loading: false,
            consumptions: null,
            reportDateFrom: url.searchParams.get('start_date'),
            reportDateTo: url.searchParams.get('end_date'),
            cashiers: []
        };
    }

    componentDidMount() {
        const { reportDateFrom, reportDateTo } = this.state;
        const cashiers = JSON.parse(localStorage.getItem('sign_history'));

        this.loaderShow();
        apiCall(
            GET_REPORT_PRODUCT_CONSUMPTION + `?start_date=${reportDateFrom}&end_date=${reportDateTo}`,
            "get",
            null,
            res => {
                this.setState({
                    consumptions: res.data,
                    cashiers: cashiers
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }, res => {
                this.loaderHide();
            }
        );
    }

    loaderShow = () => {
        this.setState({loading: true});
    };

    loaderHide = () => {
        this.setState({loading: false});
    };

    renderConsumptionTable = (consumptions) => {
        let mappedConsumptions = consumptions.map((consumption, index) => {
            return {
                no: index+1,
                ...consumption
            }
        });
        mappedConsumptions = mappedConsumptions.concat({
            ...mappedConsumptions[0],
            fake: true
        });

        const columns = [
            {
                title: "No",
                dataIndex: "no",
                key: "no",
                render: (value, row, index) => {
                    if(index === (mappedConsumptions.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },{
                title: "Tipe Transaksi",
                dataIndex: "trx_type",
                key: "trx_type",
                render: (value, row, index) => {
                    if(index === (mappedConsumptions.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },{
                title: "Item",
                dataIndex: "item_name",
                key: "item_name",
                render: (value, row, index) => {
                    if(index === (mappedConsumptions.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Qty",
                dataIndex: "qty",
                key: "qty",
                render: (value, row, index) => {
                    if(index === (mappedConsumptions.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(currentValue.qty) + accumulator;
                        return <strong>x{number_format(mappedConsumptions.filter(consumption => !consumption.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `x${number_format(value, 0)}`;
                }
            }
        ];

        return (
            <div style={{ margin: "50px 0" }}>
                <Table size="small"
                       tableLayout="auto"
                       rowKey="item_name"
                       columns={columns}
                       dataSource={mappedConsumptions}
                       style={{maxWidth: 800}}
                       pagination={false}/>
            </div>
        );
    };

    render() {
        const { loading, consumptions, reportDateFrom, reportDateTo} = this.state;

        return (
            <Spin spinning={loading}>
            <Row>
                <Col>
                    <div style={{ padding: 10 }}>
                        <h1>Konsumsi Produk</h1>
                        <table>
                            <tbody>
                            <tr>
                                <td>Outlet</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.brand_name} - {this.props.posConfig.outlet_name}</td>
                            </tr>
                            <tr>
                                <td>Kasir</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.cashier_name}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)} - {moment(reportDateTo, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                            </tr>
                            <tr>
                                <td>Waktu Pembuatan Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment().format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                            </tr>
                            </tbody>
                        </table>

                        {empty(consumptions) && <Empty style={{maxWidth: 500, margin: '100px 0'}} />}

                        {!empty(consumptions) && this.renderConsumptionTable(consumptions)}
                        {/*<div style={{marginTop: 50, marginLeft: 50}}>
                            Dikerjakan oleh,
                            <br/><br/><br/><br/><br/>
                            {cashiers.map(cashier => {
                                return <React.Fragment>
                                    ( {cashier.cashier_name} )<br/>
                                </React.Fragment>;
                            })}
                        </div>*/}
                    </div>
                </Col>
            </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

export default connect(mapStateToProps)(ReportProductConsumptionPrint);
