import React, { Component } from "react";
import { Button, Card, Col, DatePicker, Form, Icon, message, Row, Spin } from "antd";
import { empty } from "../shared/helpers/generalHelper";
import { MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import moment from "moment";
import styles from "./ReportCustomerBirthday.module.scss";
import Synchronizer from "../shared/classes/Synchronizer";
import { connect } from "react-redux";
import { posOperations } from "../state/ducks/pos";
import ErrorHandler from "../shared/classes/ErrorHandler";

class ReportCustomerBirthday extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            formLoadingMessage: '',
            reportDateFrom: moment().format(MOMENT_SQL_DATE_FORMAT),
            reportDateTo: moment().format(MOMENT_SQL_DATE_FORMAT)
        };
        this.state = { ...this.initialState };
    }

    showLoader = (tip = '') => {
        this.setState({
            formLoading: true,
            formLoadingMessage: tip
        })
    };

    hideLoader = () => {
        this.setState({
            formLoading: false,
            formLoadingMessage: ''
        })
    };

    onPickReportDate = (dates, dateStrings) => {
        this.setState({
            reportDateFrom: moment(dates[0]).format(MOMENT_SQL_DATE_FORMAT),
            reportDateTo: moment(dates[1]).format(MOMENT_SQL_DATE_FORMAT),
        });
    };

    printCustomerBirthday = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(empty(this.state.reportDateFrom) || empty(this.state.reportDateTo)){
            message.error('Tanggal harus dipilih');
            return;
        }

        this.showLoader('Synchronizing transactions..');
        Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            false,
            () => {
                window.open(`/report/customer-birthday/print?start_date=${this.state.reportDateFrom}&end_date=${this.state.reportDateTo}`, '_blank', 'rel=noopener noreferrer');
                this.hideLoader();
            }
        ).catch(err => {
            ErrorHandler.handleGeneralError(err);
            this.hideLoader();
        });
    };

    render() {
        const { formLoading, formLoadingMessage, reportDateFrom, reportDateTo } = this.state;

        const pageTitle = (
            <React.Fragment>
                <Icon type="book"/>
                &nbsp;&nbsp; Ulang Tahun Pelanggan
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={12} sm={24}>
                        <Spin spinning={formLoading} tip={formLoadingMessage}>
                            <Form onSubmit={this.printCustomerBirthday}>
                                <div className={styles.formItem}>
                                    <label>Pilih tanggal laporan</label>
                                    <DatePicker.RangePicker
                                        value={[moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT), moment(reportDateTo, MOMENT_SQL_DATE_FORMAT)]}
                                        onChange={this.onPickReportDate}
                                        format="DD MMM YYYY"
                                    />
                                </div>
                                <div className={styles.formItem}>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                </div>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData,
        posState: state.posState,
        configs: state.authState.configs
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportCustomerBirthday);