import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Avatar, List, message } from "antd";
import { authOperations } from '../state/ducks/auth';
import styles from './ChooseOutlet.module.scss';
import imgGDCashierLogo from '../assets/images/gdcashier-logo-long.png';
import imgGDCashierLogo2x from '../assets/images/gdcashier-logo-long@2x.png';
import { PRIMARY } from '../shared/utils/colors';
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";

class ChooseOutlet extends Component {
    componentDidMount() {
        if (!this.props.authenticated) {
            this.props.history.push(`/sign-in`);
        }
    }

    handleClick = e => {
        this.props.history.push(e.key);
    };

    setOutlet = outletData => {
        /*const newUserData = {
            ...this.props.userData,
            outlet_id: outletData.outlet_id,
            business_outlet_id: outletData.business_outlet_id,
            outlet_name: outletData.outlet_name
        };
        this.props.setUserData(newUserData);*/

        apiCall(
            endpoint.GET_CASHIER_CONFIG + `?outlet_id=${outletData.outlet_id}`,
            'get',
            null,
            response => {
                this.props.setUserData(response.data);
                message.success('Config successfully loaded.');
                this.props.history.push(`/`);
            },
            response => {
                message.error(response.data.error.message);
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <div className={styles.wrapper}>
                    <img
                        className={styles.logo}
                        alt="GD Cashier Logo"
                        src={imgGDCashierLogo}
                        srcSet={`${imgGDCashierLogo} 1x, ${imgGDCashierLogo2x} 2x`}
                    />
                    <h3 className={styles.pageTitle}>Choose Outlet</h3>
                    <div className={styles.listWrapper}>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.props.userData.business_outlets}
                            bordered
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                style={{
                                                    backgroundColor: PRIMARY
                                                }}
                                                icon="home"
                                                size="large"
                                            />
                                        }
                                        title={
                                            <button
                                                onClick={() => {
                                                    this.setOutlet(item);
                                                }}
                                                className={styles.outletName}
                                            >
                                                {item.outlet_name}
                                            </button>
                                        }
                                        description={item.address}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.authState.authenticated,
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    setUserData: authOperations.setUserData
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseOutlet));
