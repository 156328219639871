import React, { Component } from 'react';
import { connect } from 'react-redux';
import { empty } from '../helpers/generalHelper';
import Synchronizer from "../classes/Synchronizer";
import { apiCall } from "../helpers/networkHelper";
import * as endpoint from "../utils/endpoints";
import { message } from "antd";
import { authOperations } from "../../state/ducks/auth";

export default ChildComponent => {
    class ComposedComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                mounted: false
            };
        }

        componentDidMount() {
            this.setState({mounted: true});
            if (!this.props.authenticated) {
                window.location = '/sign-in';
            } else {
                if (empty(this.props.userData.outlet_id)) {
                    this.props.history.push(`/choose-outlet`);
                }else if(this.props.userData.pos_shift_enforced === 1 && this.props.userData.auth_role_accesses?.CASHIER_SYSTEM === 1 && empty(this.props.activeShift && window.location.pathname !== '/shift-management')){
                    this.props.history.push(`/shift-management`);
                }
            }

            if(localStorage.getItem('pending_sign_out') === '1'){
                if(!this.props.states.globalLoading){
                    this.props.showGlobalLoader('Melakukan proses sign out..');
                }
                setTimeout(this.doSignOut, 5000);
            }
        }

        /*componentDidUpdate(prevProps, prevState, snapshot) {
            setTimeout(() => {
                if(empty(localStorage.getItem('client_key')) && empty(localStorage.getItem('login_token')) && localStorage.getItem('signed_out') === '1'){
                    localStorage.clear();
                    window.location = '/';
                }
            }, 3000);
        }*/

        doSignOut = async () => {
            const that = this;
            await Synchronizer.syncPosTrxs(
                that.props.posState,
                that.props.setTrxProps,
                false,
                () => {
                    apiCall(
                        endpoint.GET_AUTH_SIGNOUT,
                        'post',
                        {
                            configs: that.props.configs
                        },
                        response => {
                            message.success('Proses sign out sukses!');
                            localStorage.clear();
                            setTimeout(() => {
                                window.location = '/';
                            }, 1000);
                        },
                        response => {
                            message.loading('Gagal melakukan proses sign out, sedang mencoba kembali..');
                            setTimeout(that.doSignOut, 5000);
                        }
                    );
                }
            ).catch(err => {
                message.loading('Gagal melakukan proses sign out, sedang mencoba kembali..');
                setTimeout(that.doSignOut, 5000);
            });
        };

        render() {
            return (
                <React.Fragment>
                    {this.state.mounted && <ChildComponent {...this.props} />}
                </React.Fragment>
            );
        }
    }

    const mapStateToProps = state => {
        return {
            authenticated: state.authState.authenticated,
            userData: state.authState.userData,
            posState: state.posState,
            states: state.authState.states,
            configs: state.authState.configs,
            activeShift: state.authState.shiftManagement.active,
        };
    };

    const mapDispatchToProps = {
        showGlobalLoader: authOperations.showGlobalLoader,
        hideGlobalLoader: authOperations.hideGlobalLoader
    };

    return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};
