import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Avatar, Empty, Button, message, Input } from "antd";
import { posOperations } from '../../state/ducks/pos';
import * as endpoint from '../../shared/utils/endpoints';
import { apiCall } from '../../shared/helpers/networkHelper';
import { empty } from '../../shared/helpers/generalHelper';
import ErrorHandler from "../../shared/classes/ErrorHandler";
import PosPriceCalculator from "../../shared/classes/PosPriceCalculator";
import { number_format } from "../../shared/helpers/stringHelper";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";
import styles from "./SavedTrxModal.module.scss";

class SavedTrxModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.initialState = {
            trxs: [],
            filterKeyword: ''
        };
        this.state = {...this.initialState};
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        this.loadTrxs();
    }

    loadTrxs = () => {
        apiCall(
            endpoint.GET_ARCHIVED_TRXS,
            'get',
            null,
            response => {
                this.setState({
                    trxs: response.data
                });
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    downloadTrx = trx => {
        apiCall(
            endpoint.GET_ARCHIVED_TRX + trx.local_trx_id,
            'get',
            null,
            response => {
                this.props.addTrx(trx);
                message.success('Transaksi telah berhasil didownload.');

                this.setState({
                    ...this.initialState
                });
                this.loadTrxs();
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    handleModalOk = () => {
        this.props.close();
    };

    handleModalCancel = () => {
        this.props.close();
    };

    renderSavedTrxs = (trxs) => {
        const {posConfig} = this.props;

        if(empty(trxs)){
            return '';
        }

        return trxs
            .map((trx) => {
                const customerName = empty(trx.customer)
                    ? 'GUEST'
                    : empty(trx.customer.user_name)
                        ? 'GUEST'
                        : trx.customer.user_name;
                const customerIdentifierString = empty(trx.customer)
                    ? 'No Contact Information'
                    : empty(trx.customer.user_identifier_string)
                        ? 'No Contact Information'
                        : trx.customer.user_identifier_string;
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    trx,
                    posConfig,
                    this.currencyCode
                ).grandTotal;
                let totalQty = 0;
                if (!empty(trx.details)) {
                    trx.details.map(detail => {
                        totalQty += detail.qty;
                        return detail;
                    });
                }

                return (
                    <div
                        className={styles.trxItem}
                        key={`SavedTrx${trx.local_trx_id}`}
                    >
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <Avatar
                                        icon="user"
                                        size="large"
                                        className={styles.trxItemAvatar}
                                    />
                                </td>
                                <td>
                                    <div className={styles.trxInfo}>
                                        [{trx.local_trx_id.toUpperCase().substr(-6)}] - {trx.trx_name}
                                    </div>
                                    <div className={styles.trxCustomerName}>
                                        {customerName}
                                    </div>
                                    <div className={styles.trxInfo}>
                                        {customerIdentifierString}
                                    </div>
                                    <div className={styles.trxInfo}>
                                        {totalQty} Produk, Total {`${this.currencyCode} `}
                                        {number_format(grandTotal, 0)}
                                    </div>
                                    <div className={styles.trxInfo}>
                                        Dibuat{' '}
                                        {moment(
                                            trx.trx_local_start_time
                                        ).format(
                                            MOMENT_READABLE_DATETIME_FORMAT
                                        )}{' '}
                                        (
                                        {moment(
                                            trx.trx_local_start_time
                                        ).fromNow()}
                                        )
                                    </div>

                                    {!empty(trx.trx_label) &&
                                    empty(trx.customer) && (
                                        <div
                                            className={styles.trxLabel}
                                        >
                                            {trx.trx_label}
                                        </div>
                                    )}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={styles.trxAction}>
                            <Button type="primary"
                                    shape="circle"
                                    icon="download"
                                    title="Download"
                                    style={{marginRight: 5}}
                                    onClick={() => {
                                        const that = this;
                                        Modal.confirm({
                                            title: 'Apakah anda ingin mengambil transaksi ini?',
                                            content: '',
                                            onOk() {
                                                that.downloadTrx(trx);
                                            },
                                            onCancel() {
                                            },
                                        });
                                    }}
                            />
                        </div>
                    </div>
                );
            });
    };



    render() {
        const {trxs} = this.state;
        const {posConfig} = this.props;
        const filteredTrx = trxs.filter(trx => {
            const customerName = empty(trx.customer)
                ? 'GUEST'
                : empty(trx.customer.user_name)
                    ? 'GUEST'
                    : String(trx.customer.user_name);
            const customerIdentifierString = String(empty(trx.customer)
                ? 'No Contact Information'
                : empty(trx.customer.user_identifier_string)
                    ? 'No Contact Information'
                    : trx.customer.user_identifier_string);
            const grandTotal = PosPriceCalculator.calculateGrandTotal(
                trx,
                posConfig,
                this.currencyCode
            ).grandTotal;
            let totalQty = 0;
            if (!empty(trx.details)) {
                trx.details.map(detail => {
                    totalQty += detail.qty;
                    return detail;
                });
            }
            const totalString = `${totalQty} Produk, Total ${this.currencyCode} ${number_format(
                grandTotal,
                0
            )}`;
            const timeString = `${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)} (${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).fromNow()})`;
            return (customerName
                    .toLowerCase()
                    .includes(this.state.filterKeyword.toLowerCase()) ||
                customerIdentifierString
                    .toLowerCase()
                    .includes(this.state.filterKeyword.toLowerCase()) ||
                trx.local_trx_id
                    .toLowerCase()
                    .includes(this.state.filterKeyword.toLowerCase()) ||
                totalString
                    .toLowerCase()
                    .includes(this.state.filterKeyword.toLowerCase()) ||
                timeString
                    .toLowerCase()
                    .includes(this.state.filterKeyword.toLowerCase()) ||
                trx.trx_label
                    .toLowerCase()
                    .includes(this.state.filterKeyword.toLowerCase())
            );
        });

        return (
            <Modal
                title="Transaksi Tersimpan"
                visible={this.props.modalOpened}
                onOk={this.handleModalOk}
                onCancel={this.handleModalCancel}
                footer={null}
            >
                {!empty(trxs) && (
                    <div className={styles.trxListSearch}>
                        <Input.Search
                            placeholder="Cari transaksi.."
                            onChange={e => {
                                this.setState({
                                    filterKeyword:
                                    e.target.value
                                });
                            }}
                            value={this.state.filterKeyword}
                        />
                    </div>
                )}

                {!empty(filteredTrx) && (
                    <React.Fragment>
                        <div className={styles.trxList}>
                            {this.renderSavedTrxs(filteredTrx)}
                        </div>
                    </React.Fragment>
                )}

                {empty(filteredTrx) && (
                    <div>
                        {empty(this.state.filterKeyword) && (
                            <Empty style={{marginTop: 50, marginBottom: 50}} description="Belum ada transaksi tersimpan" />
                        )}
                        {!empty(this.state.filterKeyword) && (
                            <Empty style={{marginTop: 50, marginBottom: 50}} description="Transaksi tersimpan tidak ditemukan" />
                        )}
                    </div>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setTrxStates: posOperations.setTrxStates,
    setTrxProps: posOperations.setTrxProps,
    addTrx: posOperations.addTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedTrxModal);
