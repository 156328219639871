import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import "react-html5-camera-photo/build/css/index.css";
import {
    Card,
    Form,
    Input,
    Button,
    Row,
    Col,
    Spin,
    Icon,
    Select,
    message,
    Table,
    InputNumber,
    Modal
} from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./InventoryTransferOut.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import connect from "react-redux/es/connect/connect";
import uuid from "uuid/v4";
import ErrorHandler from "../shared/classes/ErrorHandler";

class InventoryTransferOut extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            local_trx_id: uuid(),
            source_inv_sloc_id: this.props.posConfig.storage_locations.length === 1 ? this.props.posConfig.storage_locations[0].inv_sloc_id : null,
            target_inv_sloc_id: null,
            fetchingItems: false,
            fetchedItems: [],
            selectedItem: null,
            currentStockQty: null,
            qty: 0,
            transferItems: [],
            notes: null,
            formLoading: false,
            itemSku: ''
        };

        this.state = { ...this.initialState };
        this.qtyOut = React.createRef();
        this.itemSku = React.createRef();
        this.fetchItem = debounce(this.fetchItem, 500);
    }

    componentDidMount() {
        this.fetchItem("");
    }

    fetchItem = (value) => {
        this.setState({ fetchedItems: [], fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?item_name=${value}`,
            "get",
            null,
            (res) => {
                this.setState({ fetchedItems: res.data, fetchingItems: false });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    fetchItemWithSku = () => {
        this.setState({ fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?sku=${this.state.itemSku}`,
            "get",
            null,
            (res) => {
                if(empty(res.data)){
                    message.error('Barang tidak ditemukan');
                }else{
                    this.setState({
                        selectedItem: res.data[0],
                        itemSku: '',
                        fetchingItems: false
                    }, ()=>{
                        this.loadSelectedItemDetails();
                        this.qtyOut.current.focus();
                    });
                }
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    onChangeItemSKU = e => {
        this.setState({
            itemSku: e.target.value
        });
    };

    handleSkuEnterPressed = e => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            this.onSubmitSKU();
        }
    };

    onSubmitSKU = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        let isValidForm = true;

        if(empty(this.state.itemSku)){
            isValidForm = false;
            message.error('SKU produk harus diisi');
        }

        if(isValidForm){
            this.fetchItemWithSku();
        }
    };

    loadSelectedItemDetails = () => {
        const { selectedItem, source_inv_sloc_id } = this.state;
        this.setState({
            formLoading: true
        });
        apiCall(
            endpoint.GET_INVENTORY_CHECK_ITEM_STOCK + `?pos_item_variant_id=${selectedItem.pos_item_variant_id}&inv_sloc_id=${source_inv_sloc_id}`,
            "get",
            null,
            (res) => {
                this.setState({ currentStockQty: res.data.qty });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
                this.setState({selectedItem:null});
            },
            () => {
                this.setState({
                    formLoading: false
                });
            }
        );
    };

    addItem = () => {
        const { transferItems, selectedItem, currentStockQty, qty } = this.state;
        if (!empty(transferItems.find(transferItem => transferItem.key === selectedItem.pos_item_variant_id))) {
            message.error("Barang ini sudah ada dalam daftar transfer, silakan hapus terlebih dahulu untuk mengganti jumlah qty transfer.");
        } else if (qty === 0) {
            message.error("Qty barang tidak boleh nol.");
        } /*else if (qty > currentStockQty) {
            message.error("Qty tidak boleh lebih besar dari stok saat ini.");
        }*/ else {
            this.setState({
                transferItems: transferItems.concat({
                    key: selectedItem.pos_item_variant_id,
                    item_name: selectedItem.item_name,
                    qty_now: currentStockQty,
                    qty: qty,
                    unit_name: selectedItem.unit_name,
                    inv_unit_id: selectedItem.inv_unit_id
                }),
                currentStockQty: null,
                qty: 0,
                selectedItem: null
            }, ()=>{
                if(this.props.posConfig.pos_item_search_by_sku === 1){
                    this.itemSku.current.focus();
                }
            });
        }
    };

    deleteItem = (key) => {
        const that = this;
        Modal.confirm({
            title: "Hapus Barang Transfer",
            content: "Apakah Anda yakin ingin menghapus?",
            onOk() {
                that.setState({
                    transferItems: that.state.transferItems.filter(transferItem => transferItem.key !== key)
                });
            },
            onCancel() {
            }
        });
    };

    onFormSubmit = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            local_trx_id,
            source_inv_sloc_id,
            target_inv_sloc_id,
            notes,
            transferItems
        } = this.state;

        if (empty(source_inv_sloc_id)) {
            message.error("Lokasi stok asal harus dipilih");
        } else if (empty(target_inv_sloc_id)) {
            message.error("Lokasi stok tujuan harus dipilih");
        } else if (source_inv_sloc_id === target_inv_sloc_id) {
            message.error("Lokasi stok asal dan tujuan tidak boleh sama");
        } else if (empty(transferItems)) {
            message.error("Barang harus dipilih minimal satu");
        } else {
            this.setState({ formLoading: true });
            apiCall(
                endpoint.POST_INVENTORY_TRANSFER_OUT,
                "post",
                {
                    local_trx_id: local_trx_id,
                    source_inv_sloc_id: source_inv_sloc_id,
                    target_inv_sloc_id: target_inv_sloc_id,
                    notes: notes,
                    items: transferItems.map(transferItem => {
                        return {
                            uuid: uuid(),
                            pos_item_variant_id: transferItem.key,
                            inv_unit_id: transferItem.inv_unit_id,
                            qty: transferItem.qty
                        };
                    })
                },
                (res) => {
                    message.success("Barang berhasil ditransfer keluar.");
                    this.setState({
                        ...this.initialState,
                        local_trx_id: uuid()
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }, () => {
                    this.setState({ formLoading: false });
                }
            );
        }
    };

    render() {
        const {
            selectedItem,
            source_inv_sloc_id,
            target_inv_sloc_id,
            fetchingItems,
            fetchedItems,
            transferItems,
            currentStockQty,
            qty,
            notes,
            formLoading
        } = this.state;
        const { posConfig } = this.props;

        const itemColumns = [
            {
                title: "Nama Barang",
                dataIndex: "item_name",
                key: "item_name"
            },
            {
                title: "Qty Saat Ini",
                dataIndex: "qty_now",
                key: "qty_now"
            },
            {
                title: "Qty Transfer",
                dataIndex: "qty",
                key: "qty"
            },
            {
                title: "Unit",
                dataIndex: "unit_name",
                key: "unit_name"
            },
            {
                title: "Action",
                key: "action",
                render: (text, record) => (
                    <span>
                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    this.deleteItem(record.key);
                                }}>Delete</Button>
                    </span>
                )
            }
        ];

        const pageTitle = (
            <React.Fragment>
                <Icon type="gold"/>
                &nbsp;&nbsp; Inventory - Transfer Keluar
            </React.Fragment>
        );
        return (
            <Card title={pageTitle} bordered={false}>
                <Form onSubmit={this.onFormSubmit}>
                    <Row gutter={48}>
                        <Col xxl={10} sm={24}>
                            <Spin spinning={formLoading}>
                                <div className={styles.formItem}>
                                    <label>Lokasi Stok Asal</label>
                                    {!empty(posConfig.storage_locations) && (
                                        <Select
                                            showSearch
                                            placeholder="Pilih Lokasi Stok Asal"
                                            optionFilterProp="children"
                                            value={source_inv_sloc_id}
                                            onChange={e => {
                                                this.setState({ source_inv_sloc_id: e });
                                            }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {posConfig.storage_locations.map(row => {
                                                return <Select.Option key={`StorageLocation${row.inv_sloc_id}`} value={row.inv_sloc_id}>{row.location_name}</Select.Option>;
                                            })}

                                        </Select>
                                    )}
                                </div>

                                <div className={styles.formItem}>
                                    <label>Lokasi Stok Tujuan</label>
                                    {!empty(posConfig.all_storage_locations) && (
                                        <Select
                                            showSearch
                                            placeholder="Pilih Lokasi Stok Tujuan"
                                            optionFilterProp="children"
                                            value={target_inv_sloc_id}
                                            onChange={e => {
                                                this.setState({ target_inv_sloc_id: e });
                                            }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {posConfig.all_storage_locations
                                                .filter(row => {
                                                    return row.inv_sloc_id !== (posConfig.storage_locations.length === 1 ? posConfig.storage_locations[0].inv_sloc_id : null);
                                                })
                                                .map(row => {
                                                    return <Select.Option key={`StorageLocation${row.inv_sloc_id}`} value={row.inv_sloc_id}>{row.location_name}</Select.Option>;
                                                })
                                            }

                                        </Select>
                                    )}
                                </div>

                                <div className={styles.formItem}>
                                    <label>Catatan / Keterangan</label>
                                    <Input.TextArea
                                        type="text"
                                        rows={5}
                                        onChange={e => {
                                            this.setState({ notes: e.target.value });
                                        }}
                                        value={notes}
                                    />
                                </div>

                                <h2>Detail Barang Transfer</h2>

                                <div className={styles.formItem}>
                                    <Table columns={itemColumns} dataSource={transferItems} pagination={false}/>
                                </div>

                                <div className={styles.formItem}>
                                    <label>Barang</label>
                                    {posConfig.pos_item_search_by_sku === 1 && (
                                        <table style={{width: '100%'}}>
                                            <tbody>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    <Input
                                                        placeholder="SKU produk"
                                                        onChange={
                                                            this.onChangeItemSKU
                                                        }
                                                        onKeyDown={this.handleSkuEnterPressed}
                                                        value={
                                                            this.state.itemSku
                                                        }
                                                        addonBefore={<Icon type="barcode" />}
                                                        ref={this.itemSku}
                                                        autoFocus
                                                    />
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    <Select
                                                        showSearch={true}
                                                        placeholder="Pilih Barang"
                                                        notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                        filterOption={false}
                                                        onSearch={this.fetchItem}
                                                        onChange={e => {
                                                            this.setState({
                                                                selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                            }, this.loadSelectedItemDetails);
                                                        }}
                                                        style={{ width: "100%" }}
                                                        value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                                    >
                                                        {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                                    </Select>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )}

                                    {posConfig.pos_item_search_by_sku !== 1 && (
                                        <Select
                                            showSearch={true}
                                            placeholder="Pilih Barang"
                                            notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                            filterOption={false}
                                            onSearch={this.fetchItem}
                                            onChange={e => {
                                                this.setState({
                                                    selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                }, this.loadSelectedItemDetails);
                                            }}
                                            style={{ width: "100%" }}
                                            value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                        >
                                            {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                        </Select>
                                    )}
                                </div>

                                {!empty(selectedItem) && (
                                    <React.Fragment>
                                        <div className={styles.formItem}>
                                            <label>Stok Saat Ini ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={currentStockQty}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Qty Barang Keluar ({selectedItem.unit_name})</label>
                                            <InputNumber
                                                type="text"
                                                style={{ width: "100%" }}
                                                onChange={e => {
                                                    this.setState({ qty: e });
                                                }}
                                                value={qty}
                                                ref={this.qtyOut}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}

                                <div className={styles.formItem}>
                                    <Button
                                        type="primary"
                                        size="default"
                                        block={false}
                                        htmlType="button"
                                        disabled={empty(selectedItem)}
                                        onClick={this.addItem}
                                    >
                                        Masukkan ke dalam Daftar
                                    </Button>
                                </div>

                                <div style={{ marginTop: 50 }}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        block={true}
                                        htmlType="submit"
                                    >
                                        Transfer Keluar
                                    </Button>
                                </div>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryTransferOut);
