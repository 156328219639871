import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Divider, Icon, Input, InputNumber, message, Modal, Select } from "antd";
import { posOperations } from "../../state/ducks/pos";
import { empty } from "../../shared/helpers/generalHelper";
import styles from "../PosItemModal/PosItemModal.module.scss";
import { number_format, processDecimalInput } from "../../shared/helpers/stringHelper";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR } from "../../shared/utils/constants";

class PosCustomItemModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        item: PropTypes.object,
        trxDetail: PropTypes.object,
        closable: PropTypes.bool,
        onCancel: PropTypes.func
    };

    static defaultProps = {
        item: null,
        trxDetail: null,
        closable: true,
        onCancel: null
    };

    constructor(props) {
        super(props);

        const { currentTrx } = this.props;

        if (empty(this.props.trxDetail)) {
            this.initialState = {
                modalItem: this.props.item,
                modalItemQty: 1,
                modalItemQtyText: "1",
                modalItemVariantId: 1,
                modalItemSalesTypeId: currentTrx.pos_salesType_id,
                modalItemDiscountType: "percentage",
                modalItemDiscountPercent: 0,
                modalItemDiscountValue: 0,
                modalItemNotes: "",
                modalItemCommissionWorker: null,
                modalItemCommissionWorkers: [],
                multiCommissionWorkerPerson: null,
                multiCommissionWorkerPercent: 100,
                modalItemCommissionWorkersPackage: [],
                multiCommissionWorkerPackage: [],
                modalItemServiceArea: null,
                modalItemPackageDetails: [],
                modalItemModifiers: [],
                modalItemPrice: 0,
                modalItemPriceText: '0',
                modalItemName: ''
            };
        } else {
            const { trxDetail } = this.props;
            this.initialState = {
                modalItem: this.props.item,
                modalItemQty: trxDetail.qty,
                modalItemQtyText: `${trxDetail.qty}`,
                modalItemVariantId: trxDetail.pos_item_variant_id,
                modalItemSalesTypeId: null,
                modalItemDiscountType: trxDetail.custom_discount_type,
                modalItemDiscountPercent: trxDetail.custom_discount_value,
                modalItemDiscountValue: trxDetail.custom_discount_value,
                modalItemNotes: trxDetail.notes,
                modalItemCommissionWorker: trxDetail.commission_worker,
                modalItemCommissionWorkers: trxDetail.commission_workers,
                modalItemServiceArea: trxDetail.service_area,
                modalItemPackageDetails: trxDetail.pos_item_package_details,
                modalItemModifiers: trxDetail.pos_item_pos_item_modifiers,
                modalItemPrice: trxDetail.itemMeta.pos_item_variants[0].variant_price,
                modalItemPriceText: trxDetail.itemMeta.pos_item_variants[0].variant_price,
                modalItemName: trxDetail.itemMeta.item_name
            };
        }

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;

        this.qtyInput = null;
        this.setQtyInputRef = element => {
            this.qtyInput = element;
        };
        this.focusQtyInput = () => {
            // Focus the text input using the raw DOM API
            if (this.qtyInput) {
                this.qtyInput.focus();
                this.qtyInput.input.setSelectionRange(0, 100);
            }
        };
    }

    componentDidMount() {
        setTimeout(this.focusQtyInput, 300);
    }

    setComponentState = newState => {
        this.setState(newState);
    };

    decreaseQty = () => {
        const { modalItemQty } = this.state;
        const { posConfig } = this.props;
        let newQty;

        if (modalItemQty > 1) {
            newQty = modalItemQty - 1;
        } else {
            newQty = 1;
        }

        const inputCallback = (value, valueText) => {
            this.setState({
                modalItemQty: value,
                modalItemQtyText: valueText
            });
        };
        newQty = number_format(newQty, posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);
        processDecimalInput(newQty, inputCallback, this.decimalSeparator, this.thousandSeparator);
    };

    increaseQty = () => {
        const { modalItemQty, modalItemQtyMax } = this.state;
        const { posConfig } = this.props;
        let newQty = parseFloat(modalItemQty) + 1;
        newQty = number_format(newQty, posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);

        if (!empty(modalItemQtyMax) && modalItemQty > modalItemQtyMax) {
            message.error(`Qty produk tidak dapat melebihi ${modalItemQtyMax}`);
        } else {
            const inputCallback = (value, valueText) => {
                this.setState({
                    modalItemQty: value,
                    modalItemQtyText: valueText
                });
            };
            processDecimalInput(newQty, inputCallback, this.decimalSeparator, this.thousandSeparator);
        }
    };

    handleItemModalCancel = () => {
        this.props.close();
        this.setState({ ...this.initialState });
    };

    handleItemModalOk = () => {
        let validForm = true;
        const modalItem = this.state.modalItem;
        const {
            posConfig,
            currentLocalTrxId,
            currentTrx,
            trxDetail
        } = this.props;
        const {
            modalItemName,
            modalItemPrice,
            modalItemQty,
            modalItemNotes,
            modalItemCommissionWorker,
            modalItemCommissionWorkers,
            modalItemServiceArea
        } = this.state;

        const mappedModalItem = {
            ...modalItem,
            item_name: modalItemName,
            pos_item_variants: [
                {
                    ...modalItem.pos_item_variants[0],
                    variant_price: modalItemPrice
                }
            ]
        };

        if(empty(modalItemQty) || modalItemQty <= 0){
            validForm = false;
            message.error(`Qty produk minimal 1 buah.`);
        }

        if (validForm) {
            const pos_salesType_id = currentTrx.pos_salesType_id;
            let pos_sales_type = null;
            if (!empty(pos_salesType_id)) {
                pos_sales_type = posConfig.sales_type.find(
                    sales_type =>
                        sales_type.pos_salesType_id === pos_salesType_id
                );
            }

            if (empty(this.props.trxDetail)) {
                this.props.addTrxDetail(
                    currentLocalTrxId,
                    currentTrx.details,
                    mappedModalItem,
                    {
                        is_package: 0,
                        pos_item_variant_id: 1,
                        package_pos_item_id: 0,
                        qty: modalItemQty,
                        custom_discount_type: "percentage",
                        custom_discount_value: 0,
                        notes: modalItemNotes,
                        discounts: [],
                        pos_sales_type: pos_sales_type,
                        commission_worker: modalItemCommissionWorker,
                        commission_workers: modalItemCommissionWorkers,
                        service_area: modalItemServiceArea,
                        business_commission_worker_id: empty(
                            modalItemCommissionWorker
                        )
                            ? null
                            : modalItemCommissionWorker.business_commission_worker_id,
                        pos_item_package_details: [],
                        pos_item_pos_item_modifiers: []
                    }
                );
            } else {
                this.props.editTrxDetail(
                    currentLocalTrxId,
                    trxDetail.local_trx_detail_id,
                    {
                        ...trxDetail,
                        itemMeta: {...mappedModalItem},
                        is_package: 0,
                        pos_item_variant_id: 1,
                        package_pos_item_id: 0,
                        qty: modalItemQty,
                        custom_discount_type: "percentage",
                        custom_discount_value: 0,
                        notes: modalItemNotes,
                        discounts: [],
                        pos_sales_type: pos_sales_type,
                        commission_worker: modalItemCommissionWorker,
                        commission_workers: modalItemCommissionWorkers,
                        service_area: modalItemServiceArea,
                        business_commission_worker_id: empty(
                            modalItemCommissionWorker
                        )
                            ? null
                            : modalItemCommissionWorker.business_commission_worker_id,
                        pos_item_package_details: [],
                        pos_item_pos_item_modifiers: []
                    }
                );
                message.success("Item successfully edited!");
            }

            this.props.setTrxProps(this.props.currentLocalTrxId, {
                is_synced: false
            });

            this.setState({ ...this.initialState });
            this.props.close();
        }
    };

    onSubmitForm = e => {
        e.preventDefault();
        e.stopPropagation();

        this.handleItemModalOk();
    };

    renderMultiCommissionWorkers = () => {
        const { posConfig } = this.props;
        const { modalItemCommissionWorkers, multiCommissionWorkerPerson, multiCommissionWorkerPercent } = this.state;
        const remainingCommissionWorkers = posConfig.commission_worker.filter(
            commission_worker => empty(modalItemCommissionWorkers.find(row => row.commission_worker.business_commission_worker_id === commission_worker.business_commission_worker_id))
        );
        const reducer = (accumulator, currentValue) =>
            parseInt(currentValue.percentage) + accumulator;
        const totalCommission = modalItemCommissionWorkers.reduce(reducer, 0);
        const commissionRemainder = 100 - parseInt(totalCommission);

        return (
            <React.Fragment>
                <div style={{ marginBottom: 10 }}>
                    {empty(modalItemCommissionWorkers) && (
                        <span>
                            Belum ada worker dipilih, silakan tambahkan.
                        </span>
                    )}

                    {!empty(modalItemCommissionWorkers) &&
                    modalItemCommissionWorkers.map(row => {
                        return (
                            <div key={`modalItemCommissionWorker${row.commission_worker.business_commission_worker_id}`} className={styles.commissionWorkerPerson}>
                                [{row.percentage}%]{" "}
                                {row.commission_worker.firstname}{" "}
                                {row.commission_worker.lastname}{" "}
                                <small
                                    className={
                                        styles.deleteCommissionWorkerBtn
                                    }
                                    onClick={() => {
                                        this.setState({
                                            modalItemCommissionWorkers: modalItemCommissionWorkers.filter(
                                                worker =>
                                                    worker.commission_worker
                                                        .business_commission_worker_id !==
                                                    row.commission_worker
                                                        .business_commission_worker_id
                                            )
                                        });
                                    }}
                                >
                                    hapus
                                </small>
                            </div>
                        );
                    })}

                    <div>Sisa komisi: {commissionRemainder}%</div>
                </div>
                {!empty(remainingCommissionWorkers) && (
                    <table className={styles.workerTable}>
                        <tbody>
                        <tr>
                            <td>
                                <Select
                                    showSearch={true}
                                    optionFilterProp="children"
                                    style={{
                                        width: "100%"
                                    }}
                                    onChange={(val, opt) => {
                                        this.setComponentState({
                                            multiCommissionWorkerPerson: posConfig.commission_worker.find(
                                                commission_worker =>
                                                    commission_worker.business_commission_worker_id ===
                                                    val
                                            )
                                        });
                                    }}
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .join("")
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Pilih worker"
                                    value={empty(this.state.multiCommissionWorkerPerson) ? null : this.state.multiCommissionWorkerPerson.business_commission_worker_id}
                                >
                                    {remainingCommissionWorkers.map(
                                        (commissionWorker, index) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={
                                                        commissionWorker.business_commission_worker_id
                                                    }
                                                >
                                                    {commissionWorker.firstname}{" "}
                                                    {commissionWorker.lastname}
                                                </Select.Option>
                                            );
                                        }
                                    )}
                                </Select>
                            </td>
                            <td>
                                <InputNumber
                                    min={0}
                                    defaultValue={commissionRemainder}
                                    formatter={value =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                        )
                                    }
                                    parser={value =>
                                        value.replace(/\s?|(,*)/g, "")
                                    }
                                    style={{ width: "70%" }}
                                    onChange={e => {
                                        this.setComponentState({
                                            multiCommissionWorkerPercent: e
                                        });
                                    }}
                                    value={
                                        empty(multiCommissionWorkerPercent) ? commissionRemainder : multiCommissionWorkerPercent
                                    }
                                />{" "}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Button
                                    htmlType="button"
                                    style={{
                                        marginTop: 10
                                    }}
                                    type="primary"
                                    onClick={() => {
                                        if(empty(multiCommissionWorkerPerson)){
                                            message.error('Worker harus dipilih!');
                                        }else if((empty(multiCommissionWorkerPercent) && multiCommissionWorkerPercent !== 0) || multiCommissionWorkerPercent < 0){
                                            message.error('Persentase tidak boleh kosong!');
                                        }else if(multiCommissionWorkerPercent > commissionRemainder){
                                            message.error('Persentase tidak boleh melebihi sisa komisi!');
                                        }else{
                                            this.setState({
                                                modalItemCommissionWorkers: modalItemCommissionWorkers.concat(
                                                    {
                                                        commission_worker: this
                                                            .state
                                                            .multiCommissionWorkerPerson,
                                                        percentage: this.state
                                                            .multiCommissionWorkerPercent
                                                    }
                                                ),
                                                multiCommissionWorkerPerson: null,
                                                multiCommissionWorkerPercent: 0
                                            }, () => {
                                                const afterTotalCommission = this.state.modalItemCommissionWorkers.reduce(reducer, 0);
                                                const afterCommissionRemainder = 100 - parseInt(afterTotalCommission);

                                                this.setState({multiCommissionWorkerPercent: afterCommissionRemainder});
                                            });
                                        }
                                    }}
                                >
                                    Tambahkan worker
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </React.Fragment>
        );
    };

    renderAreaCategories = (areaCategories, areas) => {
        return areaCategories.map(row => {
            const filteredAreas = areas.filter(row2 => row2.pos_areaCategory_id === row.pos_areaCategory_id);
            if(!empty(filteredAreas)){
                return (
                    <Select.OptGroup label={row.areaCategory_name} key={`AreaCategory${row.pos_areaCategory_id}`}>
                        {this.renderAreas(filteredAreas)}
                    </Select.OptGroup>
                );
            }else{
                return '';
            }
        });
    };

    renderAreas = (areas) => {
        return areas.map(row => {
            return (
                <Select.Option value={row.pos_area_id} key={`Area${row.pos_area_id}`}>
                    {row.area_name}
                </Select.Option>
            );
        });
    };

    onChangeServiceArea = (value, option) => {
        const { posConfig } = this.props;
        const filteredValue = posConfig.area.find(row => row.pos_area_id === value);

        this.setState({
            modalItemServiceArea: filteredValue
        });
    };

    renderItemServiceArea = () => {
        const { posConfig } = this.props;
        const { modalItemServiceArea } = this.state;

        let defaultAreaValue = {};
        if(!empty(modalItemServiceArea)){
            defaultAreaValue = {
                defaultValue: modalItemServiceArea.pos_area_id
            };
        }
        const areaCategories = posConfig.area_category.concat([{
            pos_areaCategory_id: 0,
            areaCategory_name: 'Lainnya'
        }]);
        return (
            <div style={{ marginBottom: 15 }}>
                <h4 style={{ marginTop: 15 }}>Service Area</h4>
                <Select
                    {...defaultAreaValue}
                    style={{ width: '100%' }}
                    placeholder="Pilih service area"
                    optionFilterProp="children"
                    showSearch={true}
                    allowClear={true}
                    onChange={this.onChangeServiceArea}
                >
                    {this.renderAreaCategories(areaCategories, posConfig.area)}
                </Select>
            </div>
        )
    };

    render() {
        const { posConfig, onCancel, closable } = this.props;
        const {
            modalItemQtyText,
            modalItemName,
            modalItemPriceText,
            modalItemNotes,
            modalItemCommissionWorker
        } = this.state;
        const modalItem = this.props.item;

        return (
            <Modal
                title="Produk Custom"
                visible={this.props.modalOpened}
                closable={closable}
                maskClosable={closable}
                onOk={this.handleItemModalOk}
                onCancel={
                    empty(onCancel) ? this.handleItemModalCancel : onCancel
                }
                cancelText="Cancel"
                okText="OK"
            >
                <form onSubmit={this.onSubmitForm} method="post">
                    {!empty(modalItem) && (
                        <div>
                            <div className={styles.qtyWrapper}>
                                <button
                                    type="button"
                                    className={styles.qtyDecrease}
                                    onClick={this.decreaseQty}
                                >
                                    <Icon
                                        type="minus-square"
                                        style={{ fontSize: 18 }}
                                    />
                                </button>

                                <Input
                                    defaultValue={0}
                                    type="text"
                                    size="large"
                                    style={{ width: 100 }}
                                    className={styles.qtyInput}
                                    ref={this.setQtyInputRef}
                                    onChange={e => {
                                        const inputCallback = (value, valueText) => {
                                            this.setState({
                                                modalItemQty: value,
                                                modalItemQtyText: valueText
                                            });
                                        };
                                        processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                    }}
                                    value={modalItemQtyText}
                                />

                                {/*<InputNumber
                                    value={modalItemQty}
                                    formatter={value =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                        )
                                    }
                                    parser={value =>
                                        value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    className={styles.qtyInput}
                                    size="large"
                                    ref={this.setQtyInputRef}
                                    onChange={val => {
                                        this.setComponentState({
                                            modalItemQty: Math.floor(val * 100) / 100
                                        });
                                    }}
                                />*/}

                                <button
                                    type="button"
                                    className={styles.qtyIncrease}
                                    onClick={this.increaseQty}
                                >
                                    <Icon
                                        type="plus-square"
                                        style={{ fontSize: 18 }}
                                    />
                                </button>
                            </div>
                            <Divider/>

                            <h4 style={{ marginTop: 15 }}>Nama Produk</h4>
                            <Input
                                onChange={e => {
                                    this.setComponentState({
                                        modalItemName: e.target.value
                                    });
                                }}
                                value={modalItemName}
                            />

                            <h4 style={{ marginTop: 15 }}>Harga</h4>
                            <Input
                                defaultValue={0}
                                type="text"
                                addonBefore={this.currencyCode}
                                size="large"
                                onChange={e => {
                                    const inputCallback = (value, valueText) => {
                                        this.setState({
                                            modalItemPrice: value,
                                            modalItemPriceText: valueText
                                        });
                                    };
                                    processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                }}
                                value={modalItemPriceText}
                            />

                            <h4 style={{ marginTop: 15 }}>Notes</h4>
                            <Input.TextArea
                                autoSize
                                placeholder="Catatan / Keterangan Tambahan"
                                onChange={e => {
                                    this.setComponentState({
                                        modalItemNotes: e.target.value
                                    });
                                }}
                                value={modalItemNotes}
                            />

                            {posConfig.is_pos_commission_scheme === 1 &&
                            posConfig.is_pos_multicommission_scheme === 0 &&
                            modalItem.is_package === 0 &&
                            !empty(posConfig.commission_worker) && (
                                <React.Fragment>
                                    <h4 style={{ marginTop: 15 }}>
                                        Worker
                                    </h4>
                                    {empty(modalItemCommissionWorker) && (
                                        <Select
                                            showSearch={true}
                                            optionFilterProp="children"
                                            style={{
                                                width: "100%"
                                            }}
                                            onChange={(val, opt) => {
                                                this.setComponentState({
                                                    modalItemCommissionWorker: posConfig.commission_worker.find(
                                                        commission_worker =>
                                                            commission_worker.business_commission_worker_id ===
                                                            val
                                                    )
                                                });
                                            }}
                                            allowClear={true}
                                            filterOption={(input, option) =>
                                                option.props.children
                                                    .join("")
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            placeholder="Pilih worker"
                                        >
                                            {posConfig.commission_worker.map(
                                                (
                                                    commissionWorker,
                                                    index
                                                ) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={
                                                                commissionWorker.business_commission_worker_id
                                                            }
                                                        >
                                                            {
                                                                commissionWorker.firstname
                                                            }{" "}
                                                            {
                                                                commissionWorker.lastname
                                                            }
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    )}

                                    {!empty(modalItemCommissionWorker) && (
                                        <Select
                                            showSearch={true}
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            onChange={(val, opt) => {
                                                this.setComponentState({
                                                    modalItemCommissionWorker: posConfig.commission_worker.find(
                                                        commission_worker =>
                                                            commission_worker.business_commission_worker_id ===
                                                            val
                                                    )
                                                });
                                            }}
                                            allowClear={true}
                                            defaultValue={
                                                modalItemCommissionWorker.business_commission_worker_id
                                            }
                                            filterOption={(input, option) =>
                                                option.props.children
                                                    .join("")
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            placeholder="Select a person"
                                        >
                                            {posConfig.commission_worker.map(
                                                (
                                                    commissionWorker,
                                                    index
                                                ) => {
                                                    return (
                                                        <Select.Option
                                                            value={
                                                                commissionWorker.business_commission_worker_id
                                                            }
                                                            key={index}
                                                        >
                                                            {
                                                                commissionWorker.firstname
                                                            }{" "}
                                                            {
                                                                commissionWorker.lastname
                                                            }
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    )}
                                </React.Fragment>
                            )}

                            {posConfig.is_pos_commission_scheme === 1 &&
                            posConfig.is_pos_multicommission_scheme === 1 &&
                            modalItem.is_package === 0 &&
                            !empty(posConfig.commission_worker) && (
                                <React.Fragment>
                                    <h4 style={{ marginTop: 15 }}>
                                        Worker
                                    </h4>
                                    {this.renderMultiCommissionWorkers()}
                                </React.Fragment>
                            )}

                            {
                                posConfig.is_service_area_scheme === 1 &&
                                posConfig.service_area_type === 'item' &&
                                modalItem.is_package === 0 &&
                                this.renderItemServiceArea()
                            }

                            <br/><br/><br/>

                            <input
                                type="submit"
                                style={{
                                    position: "absolute",
                                    left: -9999,
                                    width: 1,
                                    height: 1
                                }}
                                tabIndex={-1}
                            />
                        </div>
                    )}
                </form>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    return {
        posItems: state.posState.posItems,
        posItemCategories: state.posState.posItemCategories,
        currentLocalTrxId,
        currentTrx: state.posState.posTrx.find(
            trx => trx.local_trx_id === currentLocalTrxId
        ),
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    addTrxDetail: posOperations.addTrxDetail,
    editTrxDetail: posOperations.editTrxDetail,
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosCustomItemModal);
