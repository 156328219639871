import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from "antd";
import { posOperations } from '../../state/ducks/pos';
import { empty } from '../../shared/helpers/generalHelper';
import styles from './PosTrxAreaPanel.module.scss';

class PosTrxAreaPanel extends Component {
    onChangeServiceArea = (value, option) => {
        const { currentLocalTrxId, posConfig } = this.props;
        const filteredValue = posConfig.area.find(row => row.pos_area_id === value);

        this.props.setTrxProps(currentLocalTrxId, {
            service_area: filteredValue
        });
    };

    renderAreaCategories = (areaCategories, areas) => {
        return areaCategories.map(row => {
            const filteredAreas = areas.filter(row2 => row2.pos_areaCategory_id === row.pos_areaCategory_id);
            if(!empty(filteredAreas)){
                return (
                    <Select.OptGroup label={row.areaCategory_name} key={`AreaCategory${row.pos_areaCategory_id}`}>
                        {this.renderAreas(filteredAreas)}
                    </Select.OptGroup>
                );
            }else{
                return '';
            }
        });
    };

    renderAreas = (areas) => {
        return areas.map(row => {

            const areaUsed = this.props.posTrxs
                .filter(trx => (empty(trx.trx_status_id) || trx.trx_status_id === 2) && trx.local_trx_id !== this.props.currentTrx.local_trx_id)
                .some(trx => {
                    if(empty(trx.service_area)){
                        return false
                    }else{
                        return trx.service_area.pos_area_id === row.pos_area_id;
                    }
                });
            return (
                <Select.Option value={row.pos_area_id} key={`Area${row.pos_area_id}`}>
                    {row.area_name} {areaUsed && `(OCCUPIED)`}
                </Select.Option>
            );
        });
    };

    render() {
        const { currentTrx, posConfig } = this.props;
        let defaultAreaValue = {};
        if(!empty(currentTrx.service_area)){
            defaultAreaValue = {
                defaultValue: currentTrx.service_area.pos_area_id
            };
        }
        const areaCategories = posConfig.area_category.concat([{
            pos_areaCategory_id: 0,
            areaCategory_name: 'Lainnya'
        }]);
        return (
            <div className={styles.wrapper}>
                <span>Service Area</span>
                <Select
                    {...defaultAreaValue}
                    style={{ width: '50%', float: 'right' }}
                    placeholder="Pilih service area"
                    optionFilterProp="children"
                    showSearch={true}
                    allowClear={true}
                    onChange={this.onChangeServiceArea}
                >
                    {this.renderAreaCategories(areaCategories, posConfig.area)}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentLocalTrxId: state.posState.posVariables.currentLocalTrxId,
        currentTrx: state.posState.posTrx.find(
            trx =>
                trx.local_trx_id ===
                state.posState.posVariables.currentLocalTrxId
        ),
        posTrxs: state.posState.posTrx,
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosTrxAreaPanel);
