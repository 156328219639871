import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Empty } from "antd";
import { PosPriceList } from "../modules";
import "./TrxHistoryPrint.module.scss";
import { empty } from '../shared/helpers/generalHelper';
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import {connect} from "react-redux";

class TrxHistoryPrint extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        local_trx_id: PropTypes.string
    };

    static defaultProps = {
        local_trx_id: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            trx: {}
        };
    }

    componentDidMount() {
        let local_trx_id = this.props.match.params.local_trx_id;
        if(empty(local_trx_id)){
            local_trx_id = this.props.local_trx_id;
        }

        apiCall(
            endpoint.GET_TRX_HISTORY_DETAIL(local_trx_id),
            "get",
            null,
            res => {
                this.setState(
                    { trx: res.data },
                    () => {
                        setTimeout(this.printPage, 500);
                    });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    }

    printPage = () => {
        if(this.props.cashierConfig.displayMode === 'DESKTOP') {
            window.print();
            setTimeout(window.close, 0);
        }
    };

    render() {
        const { trx } = this.state;

        return (
            <Row>
                <Col span={24}>
                    {empty(trx) && <div style={{marginTop: '30vh'}}><Empty/></div>}
                    {!empty(trx) && (
                        <PosPriceList
                            trx={trx}
                            posConfig={trx.cashier_configs}
                            isPrint={true}
                            copy={true}
                            mobilePrintable={true}
                            mobilePrintCloseable={true}
                        />
                    )}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        cashierConfig: state.authState.configs
    };
};

export default connect(mapStateToProps)(TrxHistoryPrint);