import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import {
    Card,
    Form,
    Input,
    Button,
    message,
    Row,
    Col,
    Spin,
    Modal,
    Avatar,
    Icon
} from 'antd';
import { apiCall, promiseTimeout } from '../shared/helpers/networkHelper';
import * as endpoint from '../shared/utils/endpoints';
import styles from './RedeemTrxQr.module.scss';
import { empty } from '../shared/helpers/generalHelper';
import connect from 'react-redux/es/connect/connect';
import { loyaltyOperations } from '../state/ducks/loyalty';
import { GREEN } from '../shared/utils/colors';
import { NORMAL_TIMEOUT_MS } from "../shared/utils/constants";

const FormItem = Form.Item;

class RedeemTrxQr extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            coupon_code: '',
            coupon: null,
            purchase_no: null,
            submitCouponQrCodeLoading: false,
            couponRedeemConfirmLoading: false,
            saveRedeemLoading: false
        };

        this.state = { ...this.initialState };
    }

    onSubmitCouponQrCode = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.coupon_code)) {
            message.error('Redeem code harus diisi!');
            return;
        }

        this.setSubmitCouponQrCodeLoading(true);

        promiseTimeout(
            NORMAL_TIMEOUT_MS,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.POST_COUPON_PREVIEW,
                    'post',
                    {
                        qr_code: this.state.coupon_code
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState(
                    {
                        step: 2,
                        coupon: result.data
                    },
                    () => {
                        this.setSubmitCouponQrCodeLoading(false);
                    }
                );
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setSubmitCouponQrCodeLoading(false);
            });
    };

    setSubmitCouponQrCodeLoading = state => {
        this.setState({
            submitCouponQrCodeLoading: state
        });
    };

    handleCouponCodeQrScan = data => {
        if (data) {
            this.setState({ coupon_code: data }, () => {
                this.onSubmitCouponQrCode(0);
            });
        }
    };

    handleCouponCodeQrError = err => {
        console.error(err);
    };

    onRedeemConfirm = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.coupon)) {
            message.error('Tidak ada barang yang ditemukan!');
            return;
        }

        this.setState({ couponRedeemConfirmLoading: true });

        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.POST_DO_REDEEM_REWARD,
                    'post',
                    {
                        scancode: this.state.coupon.scancode
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState(
                    {
                        step: 3
                    },
                    () => {
                        this.setState({ couponRedeemConfirmLoading: false });
                    }
                );
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ couponRedeemConfirmLoading: false });
            });
    };

    onCancelRedeemConfirm = () => {
        this.setState({
            step: 1,
            coupon_code: '',
            coupon: null
        });
    };

    handleSaveRedeemOk = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.purchase_no)) {
            message.error('Purchase No. harus diisi!');
            return;
        }

        this.setState({ saveRedeemLoading: true });

        promiseTimeout(
            NORMAL_TIMEOUT_MS,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.POST_SAVE_REDEEM_TRX,
                    'post',
                    {
                        scancode: this.state.coupon.scancode,
                        pos_trx_code: this.state.purchase_no
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState(
                    {
                        step: 4
                    },
                    () => {
                        this.setState({ saveRedeemLoading: false });
                        Modal.success({
                            title: 'Transaction Success!',
                            content:
                                'Purchase no. telah disimpan, transaksi redeem telah berhasil dilakukan.',
                            onOk: () => {
                                this.setState({ ...this.initialState });
                            }
                        });
                    }
                );
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ saveRedeemLoading: false });
            });
    };

    handleSaveRedeemCancel = () => {
        this.setState({ ...this.initialState });
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const resetCustomerBtnLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const {
            step,
            coupon_code,
            coupon,
            purchase_no,
            submitCouponQrCodeLoading,
            couponRedeemConfirmLoading,
            saveRedeemLoading
        } = this.state;

        const pageTitle = (
            <React.Fragment>
                <Icon type="gift" />
                &nbsp;&nbsp; Penggunaan Kupon - Melalui Kode QR
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col sm={12} xs={24}>
                        {(step === 1 || empty(coupon)) && (
                            <React.Fragment>
                                <Spin spinning={submitCouponQrCodeLoading}>
                                    <Form onSubmit={this.onSubmitCouponQrCode}>
                                        <FormItem
                                            {...formItemLayout}
                                            label="Scan QR Code"
                                        >
                                            <QrReader
                                                delay={3000}
                                                onScan={
                                                    this.handleCouponCodeQrScan
                                                }
                                                onError={
                                                    this.handleCouponCodeQrError
                                                }
                                                style={{
                                                    width: 300,
                                                    maxWidth: '100%',
                                                    marginBottom: 10,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            />

                                            <Input
                                                onChange={e => {
                                                    this.setState({
                                                        coupon_code:
                                                            e.target.value
                                                    });
                                                }}
                                                placeholder="Kode Kupon"
                                                value={coupon_code}
                                                disabled={coupon !== null}
                                                size="large"
                                            />
                                        </FormItem>

                                        <FormItem {...resetCustomerBtnLayout}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                block={true}
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </FormItem>
                                    </Form>
                                </Spin>
                            </React.Fragment>
                        )}

                        {step === 2 && !empty(coupon) && (
                            <React.Fragment>
                                <Spin spinning={couponRedeemConfirmLoading}>
                                    <Form onSubmit={this.onRedeemConfirm}>
                                        <table className={styles.previewTable}>
                                            <tbody>
                                                <tr>
                                                    <td>Customer</td>
                                                    <td>
                                                        {coupon.user_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>ID</td>
                                                    <td>
                                                        {coupon.user_code}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div
                                                            className={
                                                                styles.previewItem
                                                            }
                                                        >
                                                            {coupon.details.map(
                                                                (
                                                                    detail,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <React.Fragment
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    detail.coupon_image
                                                                                }
                                                                                alt="Redeemed Coupon"
                                                                            />
                                                                            <br />
                                                                            1 x{' '}
                                                                            {
                                                                                detail.coupon_name
                                                                            }
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <FormItem>
                                            <Button
                                                type="primary"
                                                size="large"
                                                block={true}
                                                htmlType="submit"
                                            >
                                                Redeem Now
                                            </Button>
                                        </FormItem>
                                    </Form>
                                </Spin>
                            </React.Fragment>
                        )}

                        {step === 3 && !empty(coupon) && (
                            <React.Fragment>
                                <Spin spinning={saveRedeemLoading}>
                                    <Modal
                                        title="Redeem Success"
                                        visible={
                                            step === 3 && !empty(coupon)
                                        }
                                        onOk={this.handleSaveRedeemOk}
                                        confirmLoading={saveRedeemLoading}
                                        onCancel={this.handleSaveRedeemCancel}
                                        closable={false}
                                        footer=""
                                        okButtonProps={{
                                            type: 'submit'
                                        }}
                                    >
                                        <Form
                                            onSubmit={this.handleSaveRedeemOk}
                                        >
                                            <p
                                                className={
                                                    styles.successMessage
                                                }
                                            >
                                                <Avatar
                                                    style={{
                                                        backgroundColor: GREEN
                                                    }}
                                                    size={56}
                                                    icon="check"
                                                />
                                                <br />
                                                <br />
                                                {coupon.user_name} telah
                                                berhasil menukarkan{' '}
                                                {
                                                    coupon.details[0]
                                                        .item_name
                                                }{' '}
                                                ({coupon.scancode}) dengan{' '}
                                                {
                                                    coupon.details[0]
                                                        .drv_total_point
                                                }{' '}
                                                {
                                                    coupon.details[0]
                                                        .reward_name
                                                }
                                            </p>

                                            <FormItem
                                                {...formItemLayout}
                                                label="Purchase No."
                                            >
                                                <Input
                                                    onChange={e => {
                                                        this.setState({
                                                            purchase_no:
                                                                e.target.value
                                                        });
                                                    }}
                                                    placeholder="Purchase No."
                                                    value={purchase_no}
                                                    disabled={saveRedeemLoading}
                                                    size="large"
                                                />
                                            </FormItem>

                                            <FormItem>
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    block={true}
                                                    htmlType="submit"
                                                >
                                                    Redeem Now
                                                </Button>
                                            </FormItem>
                                        </Form>
                                    </Modal>
                                </Spin>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    setLoyaltyStates: loyaltyOperations.setLoyaltyStates
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedeemTrxQr);
