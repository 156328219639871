import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Divider, Icon, Input, message, Modal, Row } from "antd";
import { posOperations } from "../../state/ducks/pos";
import { PosItem } from "../../shared/components";
import { empty } from "../../shared/helpers/generalHelper";
import styles from "./PosItemList.module.scss";
import PosItemModal from "../PosItemModal/PosItemModal";
import PosCustomItemModal from "../PosCustomItemModal/PosCustomItemModal";
import { TRX_TYPE_COUPON_SALES, TRX_TYPE_NORMAL } from "../../shared/utils/constants";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const Search = Input.Search;

class PosItemList extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        listType: PropTypes.string
    };

    static defaultProps = {
        listType: 'NORMAL'
    };

    constructor(props) {
        super(props);

        this.state = {
            itemFilterKeyword: '',
            itemSku: '',
            modalItemOpened: false,
            modalItem: null,
            modalItemQty: 1,
            modalItemVariantId: 0,
            modalItemSalesTypeId: 0,
            modalItemDiscountType: 'percentage',
            modalItemDiscountPercent: 0,
            modalItemDiscountValue: 0,
            modalItemNotes: '',
            modalItemCommissionWorker: null,
            modalCustomItemOpened: false,
            modalCustomItem: this.emptyCustomItem,
            skuQrOpened: false
        };

        this.emptyCustomItem = {
            pos_item_id: 1,
            is_custom: 1,
            item_name: '',
            item_image: '',
            pos_item_category_id: 0,
            category_name: '',
            is_package: 0,
            no_commission: 0,
            pos_item_variants: [
                {
                    pos_item_variant_id: 1,
                    pos_item_id: 1,
                    variant_name: '',
                    variant_price: 0,
                    sku: '',
                    commission_percentage: 0,
                    commission_value: 0,
                    variant_cost: 0
                }
            ],
            pos_item_discounts: [],
            pos_item_package_details: [],
            pos_item_pos_item_modifiers: []
        };
    }

    setComponentState = newState => {
        this.setState(newState);
    };

    onChangeItemFilterKeyword = e => {
        this.setState({
            itemFilterKeyword: e.target.value
        });
    };

    onChangeItemSKU = e => {
        this.setState({
            itemSku: e.target.value
        });
    };

    onSubmitSKU = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        const {posItems, posConfig, currentLocalTrxId, currentTrx} = this.props;
        let isValidForm = true;

        if(empty(this.state.itemSku)){
            isValidForm = false;
            message.error('SKU produk harus diisi');
        }

        const skuItem = posItems.find(item => {
            return item.pos_item_variants.find(variant => variant.sku === this.state.itemSku);
        });

        if(empty(skuItem)){
            isValidForm = false;
            message.error('Produk tidak ditemukan');
            this.setState({itemSku: ''});
        }

        if(isValidForm){
            const skuItemVariant = skuItem.pos_item_variants.find(variant => variant.sku === this.state.itemSku);
            const skuItemVariantId = skuItemVariant.pos_item_variant_id;
            const pos_salesType_id =
                posConfig.sales_type.length === 1
                    ? posConfig.sales_type[0].pos_salesType_id
                    : currentTrx.pos_salesType_id;
            let pos_sales_type = null;
            if (!empty(pos_salesType_id)) {
                pos_sales_type = posConfig.sales_type.find(
                    sales_type =>
                        sales_type.pos_salesType_id === pos_salesType_id
                );
            }

            this.props.addTrxDetail(
                currentLocalTrxId,
                currentTrx.details,
                skuItem,
                {
                    is_custom: 0,
                    is_package: skuItem.is_package,
                    pos_item_variant_id: skuItemVariantId,
                    package_pos_item_id: 0,
                    qty: 1,
                    custom_discount_type: 'percentage',
                    custom_discount_value: 0,
                    notes: '',
                    secret_notes: '',
                    coupon_notes: '',
                    discounts: skuItem.pos_item_discounts,
                    pos_sales_type: pos_sales_type,
                    commission_worker: null,
                    commission_workers: [],
                    business_commission_worker_id: null,
                    pos_item_package_details: skuItem.pos_item_package_details,
                    pos_item_pos_item_modifiers: [],
                    service_area: null,
                    redeem: null,
                    coupon: null
                }
            );

            this.setState({itemSku: ''});
        }
    };

    // ------------------
    // START - Custom item modal function
    openCustomItemModal = () => {
        this.setState({
            modalCustomItemOpened: true,
            modalCustomItem: this.emptyCustomItem
        });
    };
    // END - Custom item modal functions
    // ------------------

    renderPosItemCategories = (categories, posItems) => {
        return categories
            .filter(
                category =>
                    !empty(
                        this.renderPosItemsByCategory(
                            posItems,
                            category.pos_item_category_id
                        )
                    )
            )
            .map(category => {
                return (
                    <React.Fragment
                        key={`posItemCategory${category.pos_item_category_id}`}
                    >
                        <h1 className={styles.categoryName}>
                            {category.category_name}
                        </h1>
                        {this.renderPosItemsByCategory(
                            posItems,
                            category.pos_item_category_id
                        )}
                    </React.Fragment>
                );
            });
    };

    renderPosItemsByCategory = (posItems, pos_item_category_id) => {
        const nonHiddenPosItems = posItems.filter(item => empty(item.is_hidden) || item.is_hidden === 0);
        return nonHiddenPosItems
            .filter(item =>
                item.pos_item_category_id === pos_item_category_id
                    ? empty(this.state.itemFilterKeyword)
                        ? true
                        : item.item_name
                              .toLowerCase()
                              .includes(
                                  this.state.itemFilterKeyword.toLowerCase()
                              ) || (item.category_name || '')
                        .toLowerCase()
                        .includes(
                            this.state.itemFilterKeyword.toLowerCase()
                        )
                    : false
            )
            .map(item => {
                return (
                    <PosItem
                        key={`posItem${item.pos_item_id}`}
                        posItemId={item.pos_item_id}
                        itemName={item.item_name}
                        itemImage={item.item_image}
                        onClick={() => {
                            this.setComponentState({
                                modalItemOpened: true,
                                modalItem: item
                            });
                        }}
                    />
                );
            });
    };

    renderCouponsByCategory = (coupons, pos_item_category_id) => {
        return coupons
            .filter(item =>
                item.coupon_category_id === pos_item_category_id &&
                (
                    empty(this.state.itemFilterKeyword) ||
                    item.coupon_name
                        .toLowerCase()
                        .includes(this.state.itemFilterKeyword.toLowerCase()) ||
                    (item.coupon_category_name || '')
                        .toLowerCase()
                        .includes(
                            this.state.itemFilterKeyword.toLowerCase()
                        )
                )
            )
            .filter(item => item.coupon_type === 'manual')
            .map((item) => {
                const mappedCouponData = {
                    coupon_meta: item,
                    pos_item_id: 0,
                    coupon_category_id: item.coupon_category_id,
                    item_name: item.coupon_name,
                    item_image: item.coupon_image,
                    pos_item_category_id: 0,
                    category_name: '',
                    is_package: 0,
                    no_commission: 0,
                    pos_item_variants: [
                        {
                            pos_item_variant_id: 1,
                            pos_item_id: 0,
                            variant_name: '',
                            variant_price: item.coupon_price,
                            sku: '',
                            commission_percentage: 0,
                            commission_value: 0,
                            variant_cost: 0
                        }
                    ],
                    pos_item_discounts: [],
                    pos_item_package_details: [],
                    pos_item_pos_item_modifiers: []
                };
                return (
                    <PosItem
                        key={`coupon${item.coupon_id}`}
                        posItemId={0}
                        itemName={item.coupon_name}
                        itemImage={item.coupon_image}
                        onClick={() => {
                            this.setComponentState({
                                modalItemOpened: true,
                                modalItem: mappedCouponData
                            });
                        }}
                    />
                );
            });
    };

    renderCouponCategories = (categories, coupons) => {
        return categories
            .filter(
                category =>
                    !empty(
                        this.renderCouponsByCategory(
                            coupons,
                            category.pos_item_category_id
                        )
                    )
            )
            .map(category => {
                return (
                    <React.Fragment
                        key={`posItemCategory${category.pos_item_category_id}`}
                    >
                        <h2 className={styles.categoryName}>
                            {category.category_name}
                        </h2>
                        {this.renderCouponsByCategory(
                            coupons,
                            category.pos_item_category_id
                        )}
                    </React.Fragment>
                );
            });
    };

    renderCouponWithoutCategory = (coupons) => {
        let filteredCoupons = coupons
            .filter(item =>
                empty(item.coupon_category_id)
                    ? empty(this.state.itemFilterKeyword)
                    ? true
                    : item.coupon_name
                        .toLowerCase()
                        .includes(this.state.itemFilterKeyword.toLowerCase())
                    : false
            )
            .filter(item => item.coupon_type === 'manual');
        if(!empty(filteredCoupons)){
            const renderedCoupons = filteredCoupons.map((item) => {
                const mappedCouponData = {
                    coupon_meta: item,
                    pos_item_id: 0,
                    coupon_category_id: item.coupon_category_id,
                    item_name: item.coupon_name,
                    item_image: item.coupon_image,
                    pos_item_category_id: 0,
                    category_name: '',
                    is_package: 0,
                    no_commission: 0,
                    pos_item_variants: [
                        {
                            pos_item_variant_id: 1,
                            pos_item_id: 0,
                            variant_name: '',
                            variant_price: item.coupon_price,
                            sku: '',
                            commission_percentage: 0,
                            commission_value: 0,
                            variant_cost: 0
                        }
                    ],
                    pos_item_discounts: [],
                    pos_item_package_details: [],
                    pos_item_pos_item_modifiers: []
                };
                return (
                    <PosItem
                        key={`coupon${item.coupon_id}`}
                        posItemId={0}
                        itemName={item.coupon_name}
                        itemImage={item.coupon_image}
                        onClick={() => {
                            this.setComponentState({
                                modalItemOpened: true,
                                modalItem: mappedCouponData
                            });
                        }}
                    />
                );
            });

            return (
                <React.Fragment>
                    <h2 className={styles.categoryName}>
                        Lainnya
                    </h2>
                    {renderedCoupons}
                </React.Fragment>
            );
        }

    };

    renderCouponGroupsByCategory = (couponGroups, pos_item_category_id) => {
        return couponGroups
            .filter(item =>
                item.coupon_category_id === pos_item_category_id
                    ? empty(this.state.itemFilterKeyword)
                        ? true
                        : item.coupon_group_name
                            .toLowerCase()
                            .includes(this.state.itemFilterKeyword.toLowerCase()) ||
                        (item.coupon_category_name || '')
                            .toLowerCase()
                            .includes(
                                this.state.itemFilterKeyword.toLowerCase()
                            )
                    : false
            )
            .filter(item => item.coupon_type === 'manual')
            .map((item) => {
                const mappedCouponGroupData = {
                    coupon_meta: item,
                    pos_item_id: 0,
                    item_name: item.coupon_group_name,
                    item_image: item.coupon_group_image,
                    pos_item_category_id: 0,
                    category_name: '',
                    is_package: 0,
                    no_commission: 0,
                    pos_item_variants: [
                        {
                            pos_item_variant_id: 1,
                            pos_item_id: 0,
                            variant_name: '',
                            variant_price: item.coupon_group_price,
                            sku: '',
                            commission_percentage: 0,
                            commission_value: 0,
                            variant_cost: 0
                        }
                    ],
                    pos_item_discounts: [],
                    pos_item_package_details: [],
                    pos_item_pos_item_modifiers: []
                };
                return (
                    <PosItem
                        key={`coupon${item.coupon_group_id}`}
                        posItemId={0}
                        itemName={item.coupon_group_name}
                        itemImage={item.coupon_group_image}
                        onClick={() => {
                            this.setComponentState({
                                modalItemOpened: true,
                                modalItem: mappedCouponGroupData
                            });
                        }}
                    />
                );
            });
    };

    renderCouponGroupCategories = (categories, couponGroups) => {
        return categories
            .filter(
                category =>
                    !empty(
                        this.renderCouponGroupsByCategory(
                            couponGroups,
                            category.pos_item_category_id
                        )
                    )
            )
            .map(category => {
                return (
                    <React.Fragment
                        key={`posItemCategory${category.pos_item_category_id}`}
                    >
                        <h2 className={styles.categoryName}>
                            {category.category_name}
                        </h2>
                        {this.renderCouponGroupsByCategory(
                            couponGroups,
                            category.pos_item_category_id
                        )}
                    </React.Fragment>
                );
            });
    };

    renderCouponGroupWithoutCategory = (couponGroups) => {
        let filteredCouponGroups = couponGroups
            .filter(item =>
                empty(item.coupon_category_id)
                    ? empty(this.state.itemFilterKeyword)
                    ? true
                    : item.coupon_group_name
                        .toLowerCase()
                        .includes(this.state.itemFilterKeyword.toLowerCase())
                    : false
            )
            .filter(item => item.coupon_type === 'manual');
        if(!empty(filteredCouponGroups)){
            const renderedCouponGroups = filteredCouponGroups.map((item) => {
                const mappedCouponGroupData = {
                    coupon_meta: item,
                    pos_item_id: 0,
                    item_name: item.coupon_group_name,
                    item_image: item.coupon_group_image,
                    pos_item_category_id: 0,
                    category_name: '',
                    is_package: 0,
                    no_commission: 0,
                    pos_item_variants: [
                        {
                            pos_item_variant_id: 1,
                            pos_item_id: 0,
                            variant_name: '',
                            variant_price: item.coupon_group_price,
                            sku: '',
                            commission_percentage: 0,
                            commission_value: 0,
                            variant_cost: 0
                        }
                    ],
                    pos_item_discounts: [],
                    pos_item_package_details: [],
                    pos_item_pos_item_modifiers: []
                };
                return (
                    <PosItem
                        key={`coupon${item.coupon_group_id}`}
                        posItemId={0}
                        itemName={item.coupon_group_name}
                        itemImage={item.coupon_group_image}
                        onClick={() => {
                            this.setComponentState({
                                modalItemOpened: true,
                                modalItem: mappedCouponGroupData
                            });
                        }}
                    />
                );
            });

            return (
                <React.Fragment>
                    <h2 className={styles.categoryName}>
                        Lainnya
                    </h2>
                    {renderedCouponGroups}
                </React.Fragment>
            );
        }

    };

    navigateToHome = () => {
        this.props.history.push('/');
    };

    handleRedeemCodeQrScan = data => {
        if (data) {
            this.setState({
                itemSku: data,
                skuQrOpened: false
            }, () => {
                this.onSubmitSKU();
            });
        }
    };

    handleRedeemCodeQrError = err => {
        message.error(err);
    };

    toggleSkuQrModal = () => {
        this.setState({
            skuQrOpened: !this.state.skuQrOpened
        });
    };

    render() {
        const { posItems, posItemCategories, currentTrx, coupons, coupon_groups, posConfig } = this.props;
        const {
            modalItemOpened,
            modalItem,
            modalCustomItemOpened,
            modalCustomItem,
            skuQrOpened
        } = this.state;

        const filteredPosItems = posItems.filter((item) => {
            let shouldBeFiltered = false;

            if(!empty(item.authorized_user_groups)){
                let authorized_user_groups_ids = item.authorized_user_groups.split(',');
                authorized_user_groups_ids = authorized_user_groups_ids.map(row => parseInt(row));

                if(!authorized_user_groups_ids.includes(posConfig.business_user_group_id)){
                    shouldBeFiltered = true;
                }
            }

            if(!empty(item.authorized_business_memberships)){
                if(empty(currentTrx.customer)){
                    shouldBeFiltered = true;
                }else{
                    const customer_membership_name = currentTrx.customer.membership_name;
                    let hasRequiredMembership = false;

                    if(!empty(customer_membership_name)){
                        const authorized_business_memberships_array = item.authorized_business_memberships.split(',');
                        authorized_business_memberships_array.forEach(authorized_business_membership_id => {
                            const allowed_membership_id = parseInt(authorized_business_membership_id);
                            const allowed_membership = posConfig.business_memberships?.find(row => row.business_membership_id === allowed_membership_id);
                            if(!empty(allowed_membership)){
                                if(allowed_membership.membership_name === customer_membership_name){
                                    hasRequiredMembership = true;
                                }
                            }
                        });
                    }

                    if(!hasRequiredMembership){
                        shouldBeFiltered = true;
                    }
                }
            }

            return !shouldBeFiltered;
        });

        const filteredCoupons = coupons.filter((coupon) => {
            let shouldBeFiltered = false;

            if(!empty(coupon.authorized_user_groups)){
                let authorized_user_groups_ids = coupon.authorized_user_groups.split(',');
                authorized_user_groups_ids = authorized_user_groups_ids.map(row => parseInt(row));

                if(!authorized_user_groups_ids.includes(posConfig.business_user_group_id)){
                    shouldBeFiltered = true;
                }
            }

            if(!empty(coupon.authorized_business_memberships)){
                if(empty(currentTrx.customer)){
                    shouldBeFiltered = true;
                }else{
                    const customer_membership_name = currentTrx.customer.membership_name;
                    let hasRequiredMembership = false;

                    if(!empty(customer_membership_name)){
                        const authorized_business_memberships_array = coupon.authorized_business_memberships.split(',');
                        authorized_business_memberships_array.forEach(authorized_business_membership_id => {
                            const allowed_membership_id = parseInt(authorized_business_membership_id);
                            const allowed_membership = posConfig.business_memberships?.find(row => row.business_membership_id === allowed_membership_id);
                            if(!empty(allowed_membership)){
                                if(allowed_membership.membership_name === customer_membership_name){
                                    hasRequiredMembership = true;
                                }
                            }
                        });
                    }

                    if(!hasRequiredMembership){
                        shouldBeFiltered = true;
                    }
                }
            }

            return !shouldBeFiltered;
        });

        const filteredCouponGroups = coupon_groups.filter((coupon_group) => {
            let shouldBeFiltered = false;

            if(!empty(coupon_group.authorized_user_groups)){
                let authorized_user_groups_ids = coupon_group.authorized_user_groups.split(',');
                authorized_user_groups_ids = authorized_user_groups_ids.map(row => parseInt(row));

                if(!authorized_user_groups_ids.includes(posConfig.business_user_group_id)){
                    shouldBeFiltered = true;
                }
            }

            if(!empty(coupon_group.authorized_business_memberships)){
                if(empty(currentTrx.customer)){
                    shouldBeFiltered = true;
                }else{
                    const customer_membership_name = currentTrx.customer.membership_name;
                    let hasRequiredMembership = false;

                    if(!empty(customer_membership_name)){
                        const authorized_business_memberships_array = coupon_group.authorized_business_memberships.split(',');
                        authorized_business_memberships_array.forEach(authorized_business_membership_id => {
                            const allowed_membership_id = parseInt(authorized_business_membership_id);
                            const allowed_membership = posConfig.business_memberships?.find(row => row.business_membership_id === allowed_membership_id);
                            if(!empty(allowed_membership)){
                                if(allowed_membership.membership_name === customer_membership_name){
                                    hasRequiredMembership = true;
                                }
                            }
                        });
                    }

                    if(!hasRequiredMembership){
                        shouldBeFiltered = true;
                    }
                }
            }

            return !shouldBeFiltered;
        });

        return (
            <div className={styles.outerWrapper}>
                <Row gutter={20}>
                    <Col span={24}>
                        <div className={styles.itemSearch}>
                            {posConfig.pos_item_search_by_sku === 1 && skuQrOpened && (
                                <Modal title="Scan SKU"
                                       visible={true}
                                       footer={null}
                                       onOk={() => {}}
                                       onCancel={() => {
                                           this.setState({
                                               skuQrOpened: false
                                           });
                                       }}>

                                    <div style={{textAlign: 'center'}}>
                                    <BarcodeScannerComponent
                                        width={300}
                                        height={300}
                                        onUpdate={(err, result) => {
                                            if (result) {
                                                this.handleRedeemCodeQrScan(result.text);
                                            }
                                        }}
                                    />
                                    </div>
                                </Modal>
                            )}
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        {posConfig.pos_item_search_by_sku === 1 && (
                                            <React.Fragment>
                                                <td style={{ width: '50%' }}>
                                                    <form onSubmit={this.onSubmitSKU}>
                                                        <Input
                                                            placeholder="SKU produk"
                                                            onChange={
                                                                this.onChangeItemSKU
                                                            }
                                                            value={
                                                                this.state.itemSku
                                                            }
                                                            addonBefore={<Icon type="barcode" style={{cursor: 'pointer'}} onClick={this.toggleSkuQrModal} />}
                                                            autoFocus={this.props.cashierConfig.displayMode !== "TABLET"}
                                                        />
                                                        <input type="submit"
                                                               tabIndex={-1}
                                                               style={{
                                                                   position: 'absolute',
                                                                   left: '-9999px',
                                                                   width: '1px',
                                                                   height: '1px'
                                                               }}
                                                        />
                                                    </form>
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    <Search
                                                        placeholder="Cari produk.."
                                                        onChange={
                                                            this
                                                                .onChangeItemFilterKeyword
                                                        }
                                                        value={
                                                            this.state.itemFilterKeyword
                                                        }
                                                    />
                                                </td>
                                            </React.Fragment>
                                        )}

                                        {posConfig.pos_item_search_by_sku !== 1 && (
                                            <td style={{ width: '100%' }}>
                                                <Search
                                                    placeholder="Cari produk.."
                                                    onChange={
                                                        this
                                                            .onChangeItemFilterKeyword
                                                    }
                                                    value={
                                                        this.state.itemFilterKeyword
                                                    }
                                                    autoFocus={this.props.cashierConfig.displayMode !== "TABLET"}
                                                />
                                            </td>
                                        )}

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {posConfig.is_custom_item === 1 && (
                            <div
                                className={styles.addCustomItemBtn}
                                onClick={this.openCustomItemModal}
                            >
                                <Icon type="plus" /> Tambahkan Produk Custom
                            </div>
                        )}
                        <Divider style={{ margin: 0 }} />
                        <div className={styles.itemList}>
                            {currentTrx.trx_type === TRX_TYPE_NORMAL && this.renderPosItemCategories(
                                posItemCategories,
                                filteredPosItems
                            )}
                            {currentTrx.trx_type === TRX_TYPE_NORMAL && this.renderPosItemCategories(
                                [
                                    {
                                        pos_item_category_id: 0,
                                        category_name: 'Lainnya'
                                    }
                                ],
                                filteredPosItems
                            )}

                            {currentTrx.trx_type === TRX_TYPE_COUPON_SALES &&
                                !empty(filteredCoupons) &&
                                (posConfig.coupon_first_show_order === 'COUPON') && (
                                <React.Fragment>
                                    <h1 className={styles.categoryName}>
                                        Kupon Satuan
                                    </h1>
                                    {this.renderCouponCategories(posItemCategories, filteredCoupons)}
                                    {this.renderCouponWithoutCategory(filteredCoupons)}
                                </React.Fragment>
                            )}

                            {currentTrx.trx_type === TRX_TYPE_COUPON_SALES && !empty(coupon_groups) && (
                                <React.Fragment>
                                    <h1 className={styles.categoryName}
                                        style={{
                                            marginTop:empty(filteredCoupons)?0:(posConfig.coupon_first_show_order === 'COUPON')?50:0
                                        }}>
                                        Kupon Paket
                                    </h1>
                                    {this.renderCouponGroupCategories(posItemCategories, filteredCouponGroups)}
                                    {this.renderCouponGroupWithoutCategory(filteredCouponGroups)}
                                </React.Fragment>
                            )}

                            {currentTrx.trx_type === TRX_TYPE_COUPON_SALES &&
                            !empty(filteredCoupons) &&
                            (posConfig.coupon_first_show_order === 'COUPON_GROUP') && (
                                <React.Fragment>
                                    <h1 className={styles.categoryName}
                                        style={{
                                            marginTop:empty(filteredCouponGroups)?0:(posConfig.coupon_first_show_order === 'COUPON_GROUP')?50:0
                                        }}>
                                        Kupon Satuan
                                    </h1>
                                    {this.renderCouponCategories(posItemCategories, filteredCoupons)}
                                    {this.renderCouponWithoutCategory(filteredCoupons)}
                                </React.Fragment>
                            )}
                        </div>
                    </Col>
                </Row>

                {modalItemOpened && !empty(modalItem) && currentTrx.trx_type !== TRX_TYPE_COUPON_SALES && (
                    <PosItemModal
                        modalOpened={this.state.modalItemOpened}
                        item={this.state.modalItem}
                        trxDetail={null}
                        close={() => {
                            this.setComponentState({
                                modalItemOpened: false,
                                modalItem: null
                            });
                        }}
                    />
                )}
                {modalItemOpened && !empty(modalItem) && currentTrx.trx_type === TRX_TYPE_COUPON_SALES && (
                    <PosItemModal
                        modalOpened={this.state.modalItemOpened}
                        item={this.state.modalItem}
                        itemType="coupon"
                        trxDetail={null}
                        close={() => {
                            this.setComponentState({
                                modalItemOpened: false,
                                modalItem: null
                            });
                        }}
                    />
                )}

                {modalCustomItemOpened && !empty(modalCustomItem) && (
                    <PosCustomItemModal
                        modalOpened={modalCustomItemOpened}
                        item={this.emptyCustomItem}
                        trxDetail={null}
                        close={() => {
                            this.setComponentState({
                                modalCustomItemOpened: false,
                                modalCustomItem: this.emptyCustomItem
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    return {
        posItems: state.authState.userData.pos_items,
        posItemCategories: state.authState.userData.pos_item_categories,
        coupons: state.authState.userData.coupons,
        coupon_groups: state.authState.userData.coupon_groups,
        currentLocalTrxId,
        currentTrx: state.posState.posTrx.find(
            trx => trx.local_trx_id === currentLocalTrxId
        ),
        posConfig: state.authState.userData,
        cashierConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    storeItems: posOperations.storeItems,
    storeItemCategories: posOperations.storeItemCategories,
    storeCommissionWorkers: posOperations.storeCommissionWorkers,
    addTrxDetail: posOperations.addTrxDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosItemList);
