import React, { Component } from "react";
import {
    Card,
    message,
    Row,
    Col,
    Spin,
    Icon,
    Button,
    DatePicker,
    Form,
    Radio
} from "antd";
import { empty } from "../shared/helpers/generalHelper";
import { MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import moment from "moment";
import styles from './ReportPosAllTrx.module.scss';
import Synchronizer from "../shared/classes/Synchronizer";
import { posOperations } from "../state/ducks/pos";
import { connect } from "react-redux";
import ErrorHandler from "../shared/classes/ErrorHandler";

class ReportPosAllTrx extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            formLoadingMessage: '',
            reportDateFrom: moment().format(MOMENT_SQL_DATE_FORMAT),
            reportDateTo: moment().format(MOMENT_SQL_DATE_FORMAT),
            reportType: null,
            itemNature: ''
        };
        this.state = { ...this.initialState };
    }

    showLoader = (tip = '') => {
        this.setState({
            formLoading: true,
            formLoadingMessage: tip
        })
    };

    hideLoader = () => {
        this.setState({
            formLoading: false,
            formLoadingMessage: ''
        })
    };

    onChangeReportType = e => {
        if(e.target.value === 'by-transaction-detail' && empty(this.state.itemNature)){
            this.setState({
                reportType: e.target.value,
                itemNature: 'SERVICE'
            });
        }else{
            this.setState({
                reportType: e.target.value
            });
        }
    };

    onChangeItemNature = e => {
        this.setState({itemNature: e.target.value})
    };

    onPickReportDate = (dates, dateStrings) => {
        this.setState({
            reportDateFrom: moment(dates[0]).format(MOMENT_SQL_DATE_FORMAT),
            reportDateTo: moment(dates[1]).format(MOMENT_SQL_DATE_FORMAT),
        });
    };

    handleSubmit = (e) => {
        // if(e){
        //     e.preventDefault();
        //     e.stopPropagation();
        // }

        if(empty(this.state.reportType)){
            message.error('Jenis laporan harus dipilih');
            return;
        }

        if(empty(this.state.reportDateFrom) || empty(this.state.reportDateTo)){
            message.error('Tanggal harus dipilih');
            return;
        }

        this.showLoader('Synchronizing transactions..');
        Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            false,
        () => {
                window.open(`/report/pos-all-trx/print?start_date=${this.state.reportDateFrom}&end_date=${this.state.reportDateTo}&report_type=${this.state.reportType}&item_nature=${this.state.itemNature}`, '_blank', 'rel=noopener noreferrer');
                this.hideLoader();
            }
        ).catch(err => {
            ErrorHandler.handleGeneralError(err);
            this.hideLoader();
        });
        this.hideLoader();
    };

    render() {
        const { formLoading, formLoadingMessage, reportDateFrom, reportDateTo, itemNature, reportType } = this.state;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        const pageTitle = (
            <React.Fragment>
                <Icon type="book"/>
                &nbsp;&nbsp; Penjualan
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={12} sm={24}>
                        <Spin spinning={formLoading} tip={formLoadingMessage}>
                            <Form onSubmit={this.handleSubmit}>
                                <div className={styles.formItem}>
                                    <label>Pilih jenis laporan</label>
                                    <Radio.Group onChange={this.onChangeReportType} value={reportType}>
                                        <Radio style={radioStyle} value="by-transaction">
                                            Penjualan harian
                                        </Radio>
                                        <Radio style={radioStyle} value="by-transaction-detail">
                                            Detail penjualan
                                        </Radio>
                                        <Radio style={radioStyle} value="by-product">
                                            Berdasarkan service / retail
                                        </Radio>
                                    </Radio.Group>
                                </div>
                                <div className={styles.formItem}>
                                    <label>Jenis Barang</label>
                                    <Radio.Group onChange={this.onChangeItemNature} value={itemNature}>
                                        {reportType !== 'by-transaction-detail' && (
                                            <Radio style={radioStyle} value={``}>
                                                Service & Retail
                                            </Radio>
                                        )}
                                        <Radio style={radioStyle} value={`SERVICE`}>
                                            Service
                                        </Radio>
                                        <Radio style={radioStyle} value={`RETAIL`}>
                                            Retail
                                        </Radio>
                                    </Radio.Group>
                                </div>
                                <div className={styles.formItem}>
                                    <label>Pilih tanggal laporan</label>
                                    <DatePicker.RangePicker
                                        value={[moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT), moment(reportDateTo, MOMENT_SQL_DATE_FORMAT)]}
                                        onChange={this.onPickReportDate}
                                        format="DD MMM YYYY"
                                    />
                                </div>
                                <div className={styles.formItem}>
                                    <Button htmlType="button" onClick={this.handleSubmit} type="primary">Submit</Button>
                                </div>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData,
        posState: state.posState,
        configs: state.authState.configs
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportPosAllTrx);