import React, { useState } from "react";
import { Input, message, Modal } from "antd";
import PropTypes from "prop-types";
import { apiCall } from "../helpers/networkHelper";
import * as endpoint from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import { empty } from "../helpers/generalHelper";
import { MOMENT_SQL_DATETIME_FORMAT } from "../utils/constants";
import moment from "moment";

const DeleteTrxPasswordModal = props => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(null);
    const [password, setPassword] = useState(null);

    const deleteTrx = () => {
        if(empty(password)){
            message.error('Password harus diisi!');
            return;
        }

        if(empty(reason)){
            message.error('Alasan penghapusan harus diisi!');
            return;
        }

        setLoading(true);
        apiCall(
            endpoint.POST_PIN_VALIDATION,
            "post",
            {
                password
            },
            res => {
                props.deleteAction({
                    ...props.trx,
                    deleteTime: moment().format(MOMENT_SQL_DATETIME_FORMAT),
                    deleteReason: reason,
                    actionType: 'DELETE_TRX'
                });

                props.onClose();
            },
            res => {
                ErrorHandler.handleGeneralError(res);
            },
            res => {
                setLoading(false);
            }
        );
    };

    return (
        <Modal
            title={`Otorisasi Hapus Transaksi`}
            visible={props.opened}
            onCancel={props.onClose}
            onOk={deleteTrx}
            closable={true}
            confirmLoading={loading}
        >
            <h4 style={{marginTop:10}}>Password {password?.split('').map(val => '*').join('')}</h4>
            <Input
                value={password}
                style={{color: 'white'}}
                onChange={e => {
                    setPassword(e.target.value);
                }}
                type="text"
                autoComplete="off"
            />

            <h4 style={{marginTop:10}}>Alasan Penghapusan</h4>
            <Input
                value={reason}
                onChange={e => {
                    setReason(e.target.value);
                }}
                placeholder="Alasan Penghapusan"
            />

        </Modal>
    );
};

DeleteTrxPasswordModal.propTypes = {
    localTrxId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
};

DeleteTrxPasswordModal.defaultProps = {
};

export default DeleteTrxPasswordModal;
