import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { loyaltyOperations } from "../state/ducks/loyalty";
import { authOperations } from "../state/ducks/auth";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import { Alert, Button, Card, Col, Icon, Input, message, Modal, Radio, Row, Spin } from "antd";
import PosPriceCalculator from "../shared/classes/PosPriceCalculator";
import { number_format } from "../shared/helpers/stringHelper";
import { empty } from "../shared/helpers/generalHelper";
import { posOperations } from "../state/ducks/pos";
import { APP_VERSION, BUILD_NUMBER, DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT, PAYMENT_TYPE_CREDIT, PAYMENT_TYPE_DEPOSIT } from "../shared/utils/constants";
import styles from "./Home.module.scss";
import axios from "axios";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { GREEN, RED } from "../shared/utils/colors";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formLoading: false,
            userEmail: "",
            userPassword: "",
            changeCashierModalOpened: false
        };

        this.currencyCode = this.props.userData.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    reloadConfig = e => {
        const signedInAt = this.props.userData.signed_in_at;


        this.props.showGlobalLoader("Memperbarui konfigurasi..");
        apiCall(
            endpoint.GET_CASHIER_CONFIG,
            "get",
            null,
            response => {
                this.props.setUserData({
                    ...response.data,
                    signed_in_at: signedInAt
                });
                message.success("Konfigurasi berhasil diperbarui");
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            },
            res => {
                this.props.hideGlobalLoader();
            }
        );
    };

    recalculatePosTrx = () => {
        const { posTrx, userData } = this.props;
        const brokenTrx = posTrx.filter(trx => trx.trx_status_id === 3 && (empty(trx.drv_grand_total)));
        if (!empty(brokenTrx)) {
            brokenTrx.map(trx => {
                this.props.setTrxProps(trx.local_trx_id, {
                    ...trx,
                    ...PosPriceCalculator.generateTrxDrvValues(
                        trx,
                        userData,
                        this.currencyCode
                    )
                });

                return trx;
            });
        }
    };

    renderPaymentMethodCards = paymentMethods => {
        const { posTrx, userData } = this.props;
        return paymentMethods.map(row => {
            const completedTrx = posTrx.filter(
                trx => {
                    return !empty(trx.payments.find(payment => (
                        trx.trx_status_id === 3 &&
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )));
                }
            );

            const paymentReducer = (total, payment) => {
                return total + payment.payment_amount;
            };
            const completedTrxCount = completedTrx.length;
            const completedTrxReducer = (total, trx) => {
                let change = 0;
                if (row.payment_method_name === "Cash") {
                    const grandTotal = PosPriceCalculator.calculateGrandTotal(
                        trx, userData, this.currencyCode
                    ).grandTotal;
                    const reducer = (accumulator, currentValue) =>
                        accumulator + currentValue.payment_amount;
                    const totalPayment = trx.payments.reduce(reducer, 0);
                    change = Math.round(grandTotal) - parseInt(totalPayment);
                }

                return total + change +
                    trx.payments.filter(payment => (
                        trx.trx_status_id === 3 &&
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )).reduce(paymentReducer, 0);
            };
            const completedTrxTotal = completedTrx.reduce(
                completedTrxReducer,
                0
            );

            const paymentChargeReducer = (total, payment) => {
                return total + payment.payment_charge;
            };
            const TrxPaymentChargeReducer = (total, trx) => {
                return total + trx.payments.filter(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_method.table_name === row.table_name &&
                    payment.payment_method.table_id === row.table_id
                )).reduce(paymentChargeReducer, 0);
            };
            const paymentChargeTotal = completedTrx.reduce(TrxPaymentChargeReducer, 0);

            return (
                <Card
                    key={`CardTrxSummary${row.payment_method_name}`}
                    title={`${row.payment_method_name}`}
                    bordered={false}
                    className={styles.flexCardsChild}
                >
                    <span style={{ fontSize: 32 }}>{completedTrxCount}</span>{" "}
                    Transaksi
                    <br/>
                    <span style={{ fontSize: 32 }}>
                        {number_format(completedTrxTotal, 0)}
                    </span>{" "}
                    {this.currencyCode}
                    <br/>
                    {row.is_charge_to_customer === 1 && (
                        <>
                            <span style={{ fontSize: 32 }}>
                                {number_format(paymentChargeTotal, 0)}
                            </span> {this.currencyCode} Extra Charges
                        </>
                    )}
                </Card>
            );
        });
    };

    renderPaymentMethodDeposit = () => {
        const { posTrx } = this.props;
        const completedTrx = posTrx.filter(
            trx => {
                return !empty(trx.payments.find(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_DEPOSIT
                )));
            }
        );

        const paymentReducer = (total, payment) => {
            return total + payment.payment_amount;
        };
        const completedTrxCount = completedTrx.length;
        const completedTrxReducer = (total, trx) => {
            return total +
                trx.payments.filter(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_DEPOSIT
                )).reduce(paymentReducer, 0);
        };
        const completedTrxTotal = completedTrx.reduce(
            completedTrxReducer,
            0
        );

        return (
            <Card
                title={<span style={{ color: GREEN }}>Deposit</span>}
                bordered={false}
                className={styles.flexCardsChild}
            >
                <span style={{ fontSize: 32 }}>{completedTrxCount}</span>{" "}
                Transaksi
                <br/>
                <span style={{ fontSize: 32 }}>
                            {number_format(completedTrxTotal, 0)}
                        </span>{" "}
                {this.currencyCode}
                <br/>
            </Card>
        );
    };

    renderPaymentMethodCredit = () => {
        const { posTrx } = this.props;
        const completedTrx = posTrx.filter(
            trx => {
                return !empty(trx.payments.find(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_CREDIT
                )));
            }
        );

        const paymentReducer = (total, payment) => {
            return total + payment.payment_amount;
        };
        const completedTrxCount = completedTrx.length;
        const completedTrxReducer = (total, trx) => {
            return total +
                trx.payments.filter(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_CREDIT
                )).reduce(paymentReducer, 0);
        };
        const completedTrxTotal = completedTrx.reduce(
            completedTrxReducer,
            0
        );

        return (
            <Card
                title={<span style={{ color: RED }}>Piutang</span>}
                bordered={false}
                className={styles.flexCardsChild}
            >
                <span style={{ fontSize: 32 }}>{completedTrxCount}</span>{" "}
                Transaksi
                <br/>
                <span style={{ fontSize: 32 }}>
                            {number_format(completedTrxTotal, 0)}
                        </span>{" "}
                {this.currencyCode}
                <br/>
            </Card>
        );
    };

    printSettlement = (settlementType) => {
        // const settlementData = {
        //     posTrxs: this.props.posTrx,
        //     posConfig: this.props.userData,
        //     configs: this.props.configs,
        //     signedInAt: localStorage.getItem("signed_in_at")
        // };
        // localStorage.setItem("settlementData", JSON.stringify(settlementData));
        if (settlementType === "today") {
            window.open("/settlement/today", "_blank", "rel=noopener noreferrer");
        } else if (settlementType === "today-by-outlet") {
            window.open("/settlement/today-by-outlet", "_blank", "rel=noopener noreferrer");
        } else {
            window.open("/settlement/signed-in", "_blank", "rel=noopener noreferrer");
        }

    };

    softSignOut = (cb) => {
        apiCall(
            endpoint.GET_AUTH_SIGNOUT + '?settlement=0',
            "post",
            {
                configs: this.props.configs
            },
            response => {
                cb(response);
                /*message.success(resMessage);
                message.success('Proses sign out sukses!');
                setTimeout(() => {
                    hide();
                    localStorage.clear();
                    window.location = '/';
                }, 800);*/
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    showFormLoader = () => {
        this.setState({ formLoading: true });
    };

    hideFormLoader = () => {
        this.setState({ formLoading: false });
    };

    onSubmitSignIn = e => {
        e.preventDefault();
        e.stopPropagation();

        const {
            userEmail,
            userPassword
        } = this.state;
        let formValid = true;

        if (empty(userEmail)) {
            formValid = false;
            message.error("Email harus diisi!");
        }

        if (empty(userPassword)) {
            formValid = false;
            message.error("Password harus diisi!");
        }

        if (formValid) {
            this.showFormLoader();

            const formData = {
                email: userEmail,
                password: userPassword,
                subscription_user_type_status: "cashier"
            };
            const that = this;

            apiCall(
                endpoint.POST_AUTH_SIGNIN,
                "POST",
                formData,
                response => {
                    that.softSignOut((signOutRes) => {
                        localStorage.setItem(
                            "client_key",
                            response.data.client_key
                        );
                        localStorage.setItem(
                            "login_token",
                            response.data.login_token
                        );
                        localStorage.setItem("signed_in_at", moment().format(MOMENT_SQL_DATETIME_FORMAT));

                        const cashierCredentials = {
                            business_user_id: response.data.business_user_id,
                            cashier_name: response.data.cashier_name
                        };
                        if (empty(localStorage.getItem("sign_history"))) {
                            const sign_history = [cashierCredentials];
                            localStorage.setItem("sign_history", JSON.stringify(sign_history));
                        } else {
                            let sign_history = JSON.parse(localStorage.getItem("sign_history"));
                            if (!sign_history.find(row => row.business_user_id === cashierCredentials.business_user_id)) {
                                sign_history = sign_history.concat(cashierCredentials);
                                localStorage.setItem("sign_history", JSON.stringify(sign_history));
                            }
                        }

                        axios.defaults.headers.common["Authorization"] =
                            response.data.client_key;
                        axios.defaults.headers.common["APP-Token"] =
                            response.data.login_token;
                        axios.defaults.headers.common["Authorization-Type"] =
                            "business_user";
                        axios.defaults.headers.common["Platform"] = "web";

                        this.props.setUserData(response.data);
                        this.props.setCashierConfigs(response.data.configs);

                        this.hideFormLoader();
                        this.setState({
                            userEmail: "",
                            userPassword: "",
                            changeCashierModalOpened: false
                        });

                        message.success("Pergantian akun kasir berhasil dilakukan");
                    });
                },
                response => {
                    this.hideFormLoader();
                    ErrorHandler.handleGeneralError(response);
                }
            );
        }
    };

    render() {
        const { posTrx, userData } = this.props;
        const { formLoading, userEmail, userPassword, changeCashierModalOpened } = this.state;

        const pendingTrx = posTrx.filter(trx => trx.trx_status_id === 2);
        const pendingTrxCount = pendingTrx.length;
        const pendingTrxReducer = (total, trx) =>
            total +
            PosPriceCalculator.calculateGrandTotal(trx, userData, this.currencyCode).grandTotal;
        const pendingTrxTotal = pendingTrx.reduce(pendingTrxReducer, 0);

        const completedTrx = posTrx.filter(trx => trx.trx_status_id === 3);
        const completedTrxCount = completedTrx.length;
        const completedTrxReducer = (total, trx) =>
            total +
            PosPriceCalculator.calculateGrandTotal(trx, userData, this.currencyCode).grandTotal;
        const completedTrxTotal = completedTrx.reduce(completedTrxReducer, 0);

        const paymentChargeReducer = (total, payment) => {
            return total + payment.payment_charge;
        };
        const TrxPaymentChargeReducer = (total, trx) => {
            return total + trx.payments.reduce(paymentChargeReducer, 0);
        };
        const paymentChargeTotal = completedTrx.reduce(TrxPaymentChargeReducer, 0);

        let showSignOutAlert = false;
        if(!empty(userData.signed_in_at)){
            const timeDiff = moment().diff(moment(userData.signed_in_at, MOMENT_SQL_DATETIME_FORMAT), 'hours');
            console.log(userData.signed_in_at);
            if(timeDiff > 48){
                showSignOutAlert = true;
            }
        }

        // let showAlert = false;
        // if(!empty(userData.signed_in_at)){
        //     const timeDiff = moment().diff(moment(userData.signed_in_at, MOMENT_SQL_DATETIME_FORMAT), 'hours');
        //     console.log(userData.signed_in_at);
        //     if(timeDiff > 48){
        //         showSignOutAlert = true;
        //     }
        // }

        return (
            <Spin spinning={formLoading}>

                {showSignOutAlert && (
                    <Row>
                        <Col>
                            <Alert
                                message="Sign Out Alert!"
                                description="Sistem belum melakukan Sign Out lebih dari 2 hari, segera lakukan sign out dan sign in kembali untuk mencegah terjadinya kehilangan data karena memori terlalu penuh."
                                type="error"
                                showIcon
                            />
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col>
                        <h2 className={styles.sectionTitle}>Pengaturan</h2>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={8} md={12}>
                        <Card
                            title="Konfigurasi Sistem"
                            bordered={false}
                            style={{ marginBottom: 16 }}
                        >
                            <div style={{ margin: "0 0 20px 0", textAlign: "left" }}>
                                Version {APP_VERSION} Build {BUILD_NUMBER}
                            </div>

                            Konfigurasi berhasil diperbarui pada
                            <br/>
                            <span
                                style={{
                                    fontWeight: 500,
                                    display: "inline-block",
                                    marginTop: 10
                                }}
                            >
                                {moment(
                                    this.props.userData.retrieve_time
                                ).format("D MMM YYYY - HH:mm")}
                            </span>
                            <br/>
                            <br/>
                            <Button
                                onClick={this.reloadConfig}
                                htmlType="button"
                                type="primary"
                            >
                                Perbarui Konfigurasi
                            </Button>

                            &nbsp;

                            <Button
                                type="danger"
                                htmlType="button"
                                className={styles.submitBtn}
                                onClick={() => {
                                    this.setState({ changeCashierModalOpened: !changeCashierModalOpened });
                                }}
                            >
                                Ganti Akun Kasir
                            </Button>

                            <Modal
                                title="Ganti Akun Kasir"
                                visible={changeCashierModalOpened}
                                okButtonProps={{ style: { display: "none" } }}
                                onCancel={() => {
                                    this.setState({ changeCashierModalOpened: !changeCashierModalOpened });
                                }}
                            >
                                <Spin spinning={formLoading}>
                                    <form onSubmit={this.onSubmitSignIn}>
                                        <Input
                                            className={styles.inputText}
                                            prefix={
                                                <Icon
                                                    type="mail"
                                                    style={{
                                                        color: "rgba(0,0,0,.25)"
                                                    }}
                                                />
                                            }
                                            type="email"
                                            onChange={e => {
                                                this.setState({ userEmail: e.target.value });
                                            }}
                                            value={userEmail}
                                            placeholder="Email"
                                        />
                                        <Input
                                            className={styles.inputText}
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{
                                                        color: "rgba(0,0,0,.25)"
                                                    }}
                                                />
                                            }
                                            type="password"
                                            onChange={e => {
                                                this.setState({ userPassword: e.target.value });
                                            }}
                                            value={userPassword}
                                            placeholder="Password"
                                        />

                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={styles.submitBtn}
                                            block
                                        >
                                            Ganti Akun
                                        </Button>
                                    </form>
                                </Spin>
                            </Modal>
                            {/*<Button
                                style={{marginLeft: 10}}
                                onClick={this.recalculatePosTrx}
                                htmlType="button"
                                type="primary"
                            >
                                Refresh
                            </Button>*/}
                        </Card>
                    </Col>

                    {userData.auth_role_accesses?.CASHIER_SYSTEM === 1 && (
                        <>
                            <Col lg={8} md={12}>
                                <Card
                                    title="Perangkat"
                                    bordered={false}
                                    style={{ marginBottom: 16 }}
                                >
                                    <div className={styles.configItem}>
                                        <h4>Mode Tampilan</h4>
                                        <Radio.Group defaultValue={!empty(this.props.configs.displayMode) ? this.props.configs.displayMode : "DESKTOP"}
                                                     onChange={e => {
                                                         this.props.setCashierConfigs({
                                                             ...this.props.configs,
                                                             displayMode: e.target.value
                                                         });
                                                     }}
                                                     buttonStyle="solid">
                                            <Radio.Button value="DESKTOP">Desktop</Radio.Button>
                                            <Radio.Button value="TABLET">Tablet</Radio.Button>
                                            {/*<Radio.Button value="PHONE">Phone</Radio.Button>*/}
                                        </Radio.Group>
                                    </div>

                                    <div className={styles.configItem}>
                                        <h4>Ukuran Kertas Printer</h4>
                                        <Radio.Group defaultValue={!empty(this.props.configs.printerPaperSize) ? this.props.configs.printerPaperSize : "8cm"}
                                                     onChange={e => {
                                                         this.props.setCashierConfigs({
                                                             ...this.props.configs,
                                                             printerPaperSize: e.target.value
                                                         });
                                                     }}
                                                     buttonStyle="solid">
                                            <Radio.Button value="8cm">8cm</Radio.Button>
                                            <Radio.Button value="6cm">6cm</Radio.Button>
                                        </Radio.Group>
                                    </div>

                                    {/*<div className={styles.configItem}>
                                <h4>Penanda Desimal</h4>
                                <Radio.Group defaultValue={!empty(this.props.configs.decimalSeparator) ? this.props.configs.decimalSeparator : DEFAULT_DECIMAL_SEPARATOR}
                                             onChange={e => {
                                                 if(e.target.value === ','){
                                                     this.props.setCashierConfigs({
                                                         ...this.props.configs,
                                                         decimalSeparator: e.target.value,
                                                         thousandSeparator: '.'
                                                     });
                                                 }else{
                                                     this.props.setCashierConfigs({
                                                         ...this.props.configs,
                                                         decimalSeparator: e.target.value,
                                                         thousandSeparator: ','
                                                     });
                                                 }

                                             }}
                                             buttonStyle="solid">
                                    <Radio.Button value=".">. (titik)</Radio.Button>
                                    <Radio.Button value=",">, (koma)</Radio.Button>
                                </Radio.Group>
                            </div>*/}
                                </Card>
                            </Col>

                            <Col lg={8} md={12}>
                                <Card
                                    title="Closing"
                                    bordered={false}
                                    style={{ marginBottom: 16 }}
                                >
                                    <div style={{ margin: "0 0 20px 0", textAlign: "left" }}>
                                        Signed in at {moment(localStorage.getItem("signed_in_at"), MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                    </div>
                                    <Button style={{ marginBottom: 5 }} htmlType="button" type="primary" onClick={() => {
                                        this.printSettlement("signed-in");
                                    }}>Settlement by User Sign In</Button>
                                    &nbsp;&nbsp;
                                    <Button style={{ marginBottom: 5 }} htmlType="button" type="primary" onClick={() => {
                                        this.printSettlement("today");
                                    }}>Settlement by Today (User)</Button>
                                    &nbsp;&nbsp;
                                    <Button style={{ marginBottom: 5 }} htmlType="button" type="primary" onClick={() => {
                                        this.printSettlement("today-by-outlet");
                                    }}>Settlement by Today (Outlet)</Button>
                                </Card>
                            </Col>
                        </>
                    )}
                </Row>

                {userData.auth_role_accesses?.CASHIER_SYSTEM === 1 && (
                    <>
                        <Row>
                            <Col>
                                <h2 className={styles.sectionTitle}>Ringkasan Transaksi Keseluruhan</h2>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={8} md={12}>
                                <Card
                                    title="Transaksi Selesai"
                                    bordered={false}
                                    style={{ marginBottom: 16 }}
                                >
                                    <span style={{ fontSize: 32 }}>
                                        {completedTrxCount}
                                    </span>{" "}
                                    Transaksi
                                    <br/>
                                    <span style={{ fontSize: 32 }}>
                                        {number_format(completedTrxTotal, 0)}
                                    </span>{" "}
                                    {this.currencyCode}
                                    <br/>
                                    <span style={{ fontSize: 32 }}>
                                        {number_format(paymentChargeTotal, 0)}
                                    </span>{" "}
                                    {this.currencyCode} Extra Charges

                                </Card>
                            </Col>

                            <Col lg={8} md={12}>
                                <Card
                                    title="Transaksi Tertunda"
                                    bordered={false}
                                    style={{ marginBottom: 16 }}
                                >
                                    <span style={{ fontSize: 32 }}>
                                        {pendingTrxCount}
                                    </span>{" "}
                                    Transaksi
                                    <br/>
                                    <span style={{ fontSize: 32 }}>
                                        {number_format(pendingTrxTotal, 0)}
                                    </span>{" "}
                                    {this.currencyCode}
                                    <br/>
                                </Card>

                            </Col>
                        </Row>

                        {userData.is_getpos === 1 && (
                        <>
                            <Row>
                                <Col>
                                    <h2 className={styles.sectionTitle}>Ringkasan Metode Pembayaran</h2>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <div className={styles.flexCardsWrapper}>
                                        {userData.is_getpos === 1 && userData.subscription_rules.deposit && this.renderPaymentMethodDeposit()}
                                        {userData.is_getpos === 1 && userData.pos_account_receivable === 1 && this.renderPaymentMethodCredit()}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <div className={styles.flexCardsWrapper}>
                                        {userData.is_getpos === 1 && !empty(userData.payment_method) && this.renderPaymentMethodCards(userData.payment_method)}
                                    </div>
                                </Col>
                            </Row>
                        </>
                        )}
                    </>
                )}
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        posTrx: state.posState.posTrx,
        userData: state.authState.userData,
        configs: state.authState.configs
    };
};

const mapDispatchToProps = {
    addLoyaltyTrx: loyaltyOperations.addLoyaltyTrx,
    addLocalLoyaltyTrx: loyaltyOperations.addLocalLoyaltyTrx,
    setUserData: authOperations.setUserData,
    setTrxProps: posOperations.setTrxProps,
    setCashierConfigs: authOperations.setCashierConfigs,
    showGlobalLoader: authOperations.showGlobalLoader,
    hideGlobalLoader: authOperations.hideGlobalLoader
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
