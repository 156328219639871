import moment from 'moment';
import {
    MOMENT_SQL_DATE_FORMAT,
    MOMENT_SQL_DATETIME_FORMAT
} from '../utils/constants';

export const today = () => {
    return moment().format(MOMENT_SQL_DATE_FORMAT);
};

export const now = () => {
    return moment().format(MOMENT_SQL_DATETIME_FORMAT);
};

export const toMySqlDate = DateObj => {
    return [
        DateObj.getFullYear(),
        DateObj.getMonth() + 1,
        DateObj.getDate()
    ].join('-');
};
