import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Avatar, Button, Col, DatePicker, Divider, Icon, Input, message, Modal, Radio, Row, Select, Spin } from "antd";
import { PosCompletedTrxList, PosCustomerPanel, PosDiscountModal, PosItemList, PosPendingTrxList, PosPriceList, PosTrxNotesModal } from "../modules";
import { posOperations } from "../state/ducks/pos";
import { empty } from "../shared/helpers/generalHelper";
import * as endpoint from "../shared/utils/endpoints";
import { GET_POS_TRX_STOCKOUT, REST_APPOINTMENT } from "../shared/utils/endpoints";
import { apiCall, asyncApiCall, promiseTimeout } from "../shared/helpers/networkHelper";
import { GREEN, PRIMARY, RED } from "../shared/utils/colors";
import { now } from "../shared/helpers/dateHelper";
import styles from "./PosSales.module.scss";
import PosPriceCalculator from "../shared/classes/PosPriceCalculator";
import { number_format, processDecimalInput } from "../shared/helpers/stringHelper";
import PosRedeemModal from "../modules/PosRedeemModal/PosRedeemModal";
import PosCouponModal from "../modules/PosCouponModal/PosCouponModal";
import PosItemModal from "../modules/PosItemModal/PosItemModal";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT, MOMENT_SQL_TIME_FORMAT_MINUTE, PAYMENT_TYPE_DEPOSIT, TRX_TYPE_ACCOUNT_RECEIVABLE, TRX_TYPE_COUPON_SALES, TRX_TYPE_MEMBERSHIP_SALES, TRX_TYPE_NORMAL, TRX_TYPE_TOP_UP_DEPOSIT } from "../shared/utils/constants";
import PosHeader from "../shared/components/PosHeader/PosHeader";
import PosPayments from "../modules/PosPayments/PosPayments";
import queryString from "qs";
import PosTrxAreaPanel from "../modules/PosTrxAreaPanel/PosTrxAreaPanel";
import moment from "moment";
import SavedTrxModal from "../modules/SavedTrxModal/SavedTrxModal";
import ErrorHandler from "../shared/classes/ErrorHandler";
import PosReceipt from "./PosReceipt";
import CaptureNfc from "../shared/components/CaptureNfc";
import TrxStockOutModal from "../shared/components/TrxStockOutModal";
import DeleteTrxPasswordModal from "../shared/components/DeleteTrxPasswordModal";

class PosSales extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            defaultCustomerModalOpened: false,
            savedTrxModalOpened: false,
            discountModalOpened: false,
            stockOutModalOpened: false,
            trxNotesModalOpened: false,
            redeemModalOpened: false,
            redeemItemModalOpened: false,
            redeemItemModalItem: null,
            redeemItem: null,
            couponModalOpened: false,
            couponItemModalOpened: false,
            couponItemModalItem: null,
            couponItem: null,
            step: 1,
            pageTitle: "GD Business - Point of Sales",
            formLoading: false,
            pendingTrxFilterKeyword: "",
            completedTrxFilterKeyword: "",
            scanNfcOpened: false,

            deleteTrxModalOpened: false,
            deleteTrxLocalTrxId: null,
            deleteTrx: null
        };
        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        const parsedQueryString = queryString.parse(window.location.search, { ignoreQueryPrefix: true });

        if (parsedQueryString.next) {
            this.setState({
                step: 2
            });
        }

        /*if (!empty(this.props.currentTrx)) {
            if (this.props.currentTrx.trx_status_id === 1) {
                this.setState({
                    step: 2
                });
            }
        }*/
    }

    setFormLoading = state => {
        this.setState({
            formLoading: state
        });
    };

    onClickNewTrxBtn = (pos_salesType_id, transactionName = "") => {
        this.props.setNewLocalTrx({
            trx_type: TRX_TYPE_NORMAL,
            pos_salesType_id,
            trx_name: transactionName
        });

        if (!empty(transactionName)) {
            this.setState({
                step: 2,
                pageTitle: transactionName
            });
        } else {
            this.setState({
                step: 2
            });
        }
    };

    newTopUpDepositTrx = () => {
        const transactionName = "Top Up Deposit";
        this.props.setNewLocalTrx({
            trx_type: TRX_TYPE_TOP_UP_DEPOSIT,
            trx_name: transactionName
        });
        this.setState({
            step: 2,
            pageTitle: transactionName
        });
    };

    newAccountReceivableTrx = () => {
        const transactionName = "Pembayaran Piutang";
        this.props.setNewLocalTrx({
            trx_type: TRX_TYPE_ACCOUNT_RECEIVABLE,
            trx_name: transactionName
        });
        this.setState({
            step: 2,
            pageTitle: transactionName
        });
    };

    newCouponSalesTrx = () => {
        const transactionName = "Kirim/Jual Kupon";
        this.props.setNewLocalTrx({
            trx_type: TRX_TYPE_COUPON_SALES,
            trx_name: transactionName
        });
        this.setState({
            step: 2,
            pageTitle: transactionName
        });
    };

    newMembershipSalesTrx = () => {
        const transactionName = "Jual Membership";
        this.props.setNewLocalTrx({
            trx_type: TRX_TYPE_MEMBERSHIP_SALES,
            trx_name: transactionName
        });
        this.setState({
            step: 2,
            pageTitle: transactionName
        });
    };

    onPendingTrxClicked = transactionName => {
        if (!empty(transactionName)) {
            this.setState({
                step: 2,
                pageTitle: transactionName
            });
        } else {
            this.goToStep(2);
        }
    };

    // REDEEM
    openRedeemModal = () => {
        if (empty(this.props.currentTrx.customer)) {
            message.error("Customer harus dipilih terlebih dahulu!");
        } else {
            if (!empty(this.props.currentTrx.redeem)) {
                Modal.confirm({
                    title: "Item Redeem Telah Diset Sebelumnya",
                    content: "Apakah Anda mau menghapusnya?",
                    onOk: this.removeRedeem
                });
            } else {
                this.setState({
                    redeemModalOpened: true
                });
            }
        }
    };

    closeRedeemModal = (redeem_item = null) => {
        if (!empty(redeem_item)) {
            const defaultPosItem = this.props.posConfig.pos_items.find(
                item => item.pos_item_id === redeem_item.pos_item_id
            );
            let modifiedVariantPosItem = null;
            if (redeem_item.is_all_variant === 1) {
                modifiedVariantPosItem = defaultPosItem;
            } else {
                modifiedVariantPosItem = {
                    ...defaultPosItem,
                    pos_item_variants: redeem_item.pos_item_variants
                };
            }

            this.setState({
                redeemItemModalOpened: true,
                redeemItemModalItem: modifiedVariantPosItem,
                redeemItem: redeem_item
            });
        } else {
            this.setState({
                redeemModalOpened: false
            });
        }
    };

    removeRedeem = () => {
        this.props.setTrxProps(this.props.currentLocalTrxId, {
            redeem: null,
            details: this.props.currentTrx.details.filter(
                row => empty(row.redeem)
            )
        });
    };

    // COUPON
    openCouponModal = () => {
        if (empty(this.props.currentTrx.customer)) {
            message.error("Customer harus dipilih terlebih dahulu!");
        } else {
            if (!empty(this.props.currentTrx.coupon)) {
                Modal.confirm({
                    title: "Kupon Telah Diset Sebelumnya",
                    content: "Apakah Anda mau menghapusnya?",
                    onOk: this.removeCoupon
                });
            } else {
                this.setState({
                    couponModalOpened: true
                });
            }
        }
    };

    closeCouponModal = (coupon_item = null) => {
        if (!empty(coupon_item)) {
            const defaultPosItem = this.props.posConfig.pos_items.find(
                item => item.pos_item_id === coupon_item.pos_item_id
            );
            let modifiedVariantPosItem = null;
            if (coupon_item.is_all_variant === 1) {
                modifiedVariantPosItem = defaultPosItem;
            } else {
                modifiedVariantPosItem = {
                    ...defaultPosItem,
                    pos_item_variants: coupon_item.pos_item_variants
                };
            }

            this.setState({
                couponItemModalOpened: true,
                couponItemModalItem: modifiedVariantPosItem,
                couponItem: coupon_item
            });
        } else {
            this.setState({
                couponModalOpened: false
            });
        }
    };

    onChangeCouponCode = e => {
        this.props.setTrxProps(this.props.currentLocalTrxId, {
            coupon: {
                ...this.props.currentTrx.coupon,
                coupon_code: e.target.value
            }
        });
    };

    removeCoupon = () => {
        this.props.setTrxProps(this.props.currentLocalTrxId, {
            coupon: null,
            details: this.props.currentTrx.details.filter(
                row => empty(row.coupon)
            )
        });
    };

    onChangeTrxLabel = e => {
        this.props.setTrxProps(this.props.currentLocalTrxId, {
            trx_label: e.target.value
        });
    };

    archiveCurrentTrx = () => {
        const that = this;
        Modal.confirm({
            title: "Simpan Transaksi",
            content: "Apakah Anda yakin?",
            onOk: () => {
                apiCall(
                    endpoint.POST_ARCHIVE_TRX,
                    "post",
                    {
                        trx: that.props.posTrx.find(row => row.local_trx_id === that.props.currentLocalTrxId)
                    },
                    response => {
                        message.success("Transaksi telah berhasil disimpan.");
                        that.goToStep(1);
                        that.props.deleteTrx(that.props.currentLocalTrxId);
                    },
                    response => {
                        ErrorHandler.handleGeneralError(response);
                    }
                );
            }
        });
    };

    deleteCurrentTrx = () => {
        const that = this;
        Modal.confirm({
            title: "Hapus Transaksi",
            content: "Apakah Anda yakin?",
            onOk: () => {
                // console.log(that.props.posConfig.pos_cashier_delete_by_pin);
                if (that.props.posConfig.pos_cashier_delete_by_pin === 1) {
                    that.setState({
                        deleteTrxModalOpened: true,
                        deleteTrxLocalTrxId: that.props.currentLocalTrxId,
                        deleteTrx: that.props.currentTrx
                    });
                } else {
                    that.deleteTrxAction(that.props.currentTrx);
                }
            }
        });
    };

    deleteTrxAction = trx => {
        this.props.deleteTrx(trx.local_trx_id, trx);
        this.goToStep(1);
    }

    goToStep = step => {
        if (step === 1) {
            this.setState({
                step,
                pageTitle: this.initialState.pageTitle
            });
        } else {
            this.setState({ step });
        }

    };

    navigateToHome = () => {
        this.props.history.push("/");
    };

    continueToPayment = () => {
        let err = false;

        if (this.props.currentTrx.trx_type === TRX_TYPE_NORMAL) {
            if (empty(this.props.currentTrx.details)) {
                err = true;
                message.error("Pilih minimal satu produk untuk melanjutkan.");
            }
        }

        if (this.props.currentTrx.trx_type === TRX_TYPE_COUPON_SALES) {
            if (empty(this.props.currentTrx.customer)) {
                err = true;
                message.error("Customer harus dipilih untuk pembelian kupon.");
            }

            if (empty(this.props.currentTrx.details)) {
                err = true;
                message.error("Pilih minimal satu kupon untuk melanjutkan.");
            }
        }

        if (this.props.currentTrx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT) {
            if (empty(this.props.currentTrx.customer)) {
                err = true;
                message.error("Customer harus dipilih untuk melakukan top up.");
            }

            if (empty(this.props.currentTrx.details)) {
                err = true;
                message.error("Nominal top up harus diisi.");
            } else {
                const topUpNominal = this.props.currentTrx.details[0].itemMeta.pos_item_variants[0].variant_price;
                if (topUpNominal <= 0) {
                    err = true;
                    message.error("Nominal top up harus diisi.");
                }

                if (topUpNominal > parseInt(this.props.posConfig.business_wallet.max_balance)) {
                    err = true;
                    message.error(
                        `Total top up tidak boleh melebihi ${this.currencyCode} ${number_format(
                            this.props.posConfig.business_wallet.max_balance
                        )}!`
                    );
                }
            }
        }

        if (this.props.currentTrx.trx_type === TRX_TYPE_ACCOUNT_RECEIVABLE) {
            if (empty(this.props.currentTrx.customer)) {
                err = true;
                message.error("Customer harus dipilih untuk melakukan pembayaran piutang.");
            }

            if (empty(this.props.currentTrx.account_receivable)) {
                err = true;
                message.error("Transaksi harus dipilih untuk melakukan pembayaran piutang.");
            }

            if (empty(this.props.currentTrx.details)) {
                err = true;
                message.error("Nominal pembayaran piutang harus diisi.");
            } else {
                const creditNominal = this.props.currentTrx.details[0].itemMeta.pos_item_variants[0].variant_price;
                if (creditNominal <= 0) {
                    err = true;
                    message.error("Nominal pembayaran piutang harus diisi.");
                }

                if (!empty(this.props.currentTrx.account_receivable) && creditNominal > this.props.currentTrx.account_receivable.drv_balance) {
                    err = true;
                    message.error("Nominal pembayaran piutang tidak boleh melebihi sisa piutang transaksi terpilih.");
                }
            }
        }

        if (this.props.currentTrx.trx_type === TRX_TYPE_MEMBERSHIP_SALES) {
            if (empty(this.props.currentTrx.customer)) {
                err = true;
                message.error("Customer harus ditentukan untuk melakukan aktivasi membership.");
            }

            if (empty(this.props.currentTrx.details)) {
                err = true;
                message.error("Membership harus dipilih.");
            }
        }

        if (!err) {
            this.goToStep(3);
        }
    };

    fillDetailDrvs = trx => {
        const details = trx.details;
        return details.map(detail => {
            let drv_discount_type = "";

            if (trx.trx_type !== TRX_TYPE_TOP_UP_DEPOSIT) {
                const itemDisc = detail.discounts;
                const customDisc = {
                    custom_discount_type: detail.custom_discount_type,
                    custom_discount_value: detail.custom_discount_value
                };

                if (empty(drv_discount_type) && !empty(trx.coupon)) {
                    if (
                        (trx.coupon.coupon_item.pos_link_type === "item" &&
                            !empty(detail.coupon) &&
                            trx.coupon.coupon_item.pos_item_id ===
                            detail.coupon.pos_item_id) ||
                        (trx.coupon.coupon_item.pos_link_type ===
                            "item_category" &&
                            trx.coupon.coupon_item.pos_item_category_id ===
                            detail.itemMeta.pos_item_category_id)
                    ) {
                        drv_discount_type = "coupon";
                    }
                } else if (empty(drv_discount_type) && !empty(trx.redeem)) {
                    if (
                        (trx.redeem.redeem_item.pos_link_type === "item" &&
                            !empty(detail.redeem) &&
                            trx.redeem.redeem_item.pos_item_id ===
                            detail.redeem.pos_item_id) ||
                        (trx.redeem.redeem_item.pos_link_type ===
                            "item_category" &&
                            trx.redeem.redeem_item.pos_item_category_id ===
                            detail.itemMeta.pos_item_category_id)
                    ) {
                        drv_discount_type = "redeem";
                    }
                } else if (
                    empty(drv_discount_type) &&
                    !empty(customDisc.custom_discount_value)
                ) {
                    drv_discount_type = "custom";
                } else if (empty(drv_discount_type) && !empty(itemDisc)) {
                    drv_discount_type = "defined";
                }
            }

            return {
                ...detail,
                item_discount_type: drv_discount_type
            };
        });
    };

    generateReceiptData = (trx, config) => {

    };

    onSubmitTransaction = async (retry = 2) => {
        const { posConfig, currentTrx } = this.props;
        let isValid = true;
        let mustOnline = false;
        const grand_total = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            this.currencyCode
        ).grandTotal;
        const paymentReducer = (accumulator, currentValue) =>
            accumulator + currentValue.payment_amount;
        const totalPayment = currentTrx.payments.reduce(paymentReducer, 0);
        const unpaidRemainder =
            Math.round(grand_total) - parseInt(totalPayment);

        if (unpaidRemainder > 0) {
            isValid = false;
            message.error("Pembayaran harus dilunasi terlebih dahulu!");
        }

        if (!empty(currentTrx.redeem)) {
            if (currentTrx.redeem.redeem_type === "sms") {
                if (empty(currentTrx.redeem.redeem_code)) {
                    isValid = false;
                    message.error("Kode redeem harus diisi!");
                }
            }

            mustOnline = true;
        }

        if (!empty(currentTrx.coupon)) {
            mustOnline = true;
        }

        if (posConfig.check_stock_availability_on_trx === 1){
            mustOnline = true;
        }

        if (
            !empty(
                currentTrx.payments.find(
                    row => row.payment_type === PAYMENT_TYPE_DEPOSIT
                )
            )
        ) {
            if(posConfig.use_wallet_security_code === 1 &&
                empty(currentTrx.receh_code)){
                isValid = false;
                message.error("Kode Receh harus diisi!");
            }
        }

        if (
            currentTrx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT ||
            currentTrx.trx_type === TRX_TYPE_COUPON_SALES ||
            currentTrx.trx_type === TRX_TYPE_ACCOUNT_RECEIVABLE ||
            currentTrx.trx_type === TRX_TYPE_MEMBERSHIP_SALES
        ) {
            mustOnline = true;
        }

        if (
            !empty(
                currentTrx.payments.find(
                    row => row.payment_type === PAYMENT_TYPE_DEPOSIT
                )
            )
        ) {
            mustOnline = true;
        }

        if (
            !empty(
                currentTrx.details.find(
                    row => row.itemMeta.is_stock_out_required === 1
                )
            )
        ) {
            mustOnline = true;

            await asyncApiCall(
                GET_POS_TRX_STOCKOUT + currentTrx.local_trx_id,
                'get',
                null,
                res => {
                    if(empty(res.data.stock_outs)){
                        isValid = false;
                        message.error("Ada item yang belum dikeluarkan stoknya.");
                    }
                },
                err => {
                    isValid = false;
                    ErrorHandler.handleGeneralError(err);
                }
            )
        }

        // if (posConfig.check_stock_availability_on_trx === 1){
        //     let pos_item_variant_ids = [];
        //     currentTrx.details.map( row => {
        //         pos_item_variant_ids = [...pos_item_variant_ids, row.pos_item_variant_id];
        //         return row;
        //     });
        //
        //     let formData = {
        //         pos_item_variant_ids,
        //         inv_sloc_id: posConfig.storage_locations.length === 1 ? posConfig.storage_locations[0].inv_sloc_id : null
        //     }
        //
        //     await asyncApiCall(
        //         POST_CHECK_STOCKS,
        //         'post',
        //         formData,
        //         res => {
        //             if(!empty(res.data)){
        //                 res.data.map(row => {
        //                     currentTrx.details.map( detail => {
        //                         if(row.pos_item_variant_id === detail.pos_item_variant_id){
        //                             if(row.qty < detail.qty){
        //                                 isValid = false;
        //                                 message.error('Stock item ' + detail.itemMeta.item_name + ' tidak mencukupi');
        //                             }
        //                         }
        //                         return detail;
        //                     });
        //                     return row;
        //                 });
        //             }
        //         },
        //         err => {
        //             isValid = false;
        //             ErrorHandler.handleGeneralError(err);
        //         }
        //     )
        // }

        if (posConfig.is_user_id_required === 1) {
            if (empty(currentTrx.customer)) {
                isValid = false;
                message.error("Data customer harus diisi!");
            } else {
                if (currentTrx.customer.is_signed_up !== 1) {
                    if (currentTrx.customer.user_identifier_type !== "phone" &&
                        currentTrx.customer.user_identifier_type !== "email") {
                        isValid = false;
                        message.error("Data customer baru harus berupa No. HP atau email!");
                    } else {
                        if (empty(currentTrx.customer.user_identifier_string)) {
                            isValid = false;
                            message.error("No. HP atau email customer harus diisi!");
                        }
                    }
                }
            }
        }

        let filteredCommissionWorker = posConfig.commission_worker;

        if (
            posConfig.is_pos_commission_scheme === 1 &&
            posConfig.pos_item_requiring_worker === 1 &&
            !empty(filteredCommissionWorker) &&
            currentTrx.trx_type === TRX_TYPE_NORMAL
        ) {
            currentTrx.details.map(detail => {

                let workerExist = posConfig.commission_worker;
                if (!empty(detail.itemMeta.pos_item_commission_worker_groups)) {
                    workerExist = filteredCommissionWorker.filter(worker => {
                        let eligibleWorker = false;
                        detail.itemMeta.pos_item_commission_worker_groups.map(workerGroup => {
                            if (eligibleWorker === false) {
                                eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                            }
                            return workerGroup;
                        });

                        return eligibleWorker;
                    });
                }

                if(detail.itemMeta.no_commission !== 1 && !empty(workerExist)) {
                    if (posConfig.is_pos_multicommission_scheme === 0) {
                        if (detail.is_package === 0) {
                            if (empty(detail.commission_worker)) {
                                if (isValid) {
                                    message.error("Ada barang / service yang belum memiliki pekerja");
                                    isValid = false;
                                }
                            }
                        } else {
                            const hasEmptyWorker = detail.pos_item_package_details.some(packageDetail => empty(packageDetail.business_commission_worker) && packageDetail.no_commission === 0);
                            if (hasEmptyWorker && isValid) {
                                message.error("Ada barang / service yang belum memiliki pekerja");
                                isValid = false;
                            }
                        }
                    } else {
                        if (detail.is_package === 0) {
                            if (empty(detail.commission_workers)) {
                                if (isValid) {
                                    message.error("Ada barang / service yang belum memiliki pekerja");
                                    isValid = false;
                                }
                            }
                        } else {
                            const hasEmptyWorker = detail.pos_item_package_details.some(packageDetail => empty(packageDetail.commission_workers) && packageDetail.no_commission === 0);

                            if (hasEmptyWorker && isValid) {
                                message.error("Ada barang / service yang belum memiliki pekerja");
                                isValid = false;
                            }
                        }
                    }
                }

                return detail;
            });
        }

        if (isValid) {
            this.setFormLoading(true);

            let drv_global_discount_type = "";
            if (
                empty(drv_global_discount_type) &&
                !empty(currentTrx.coupon) &&
                currentTrx.coupon.coupon_item.pos_link_type === "discount"
            ) {
                drv_global_discount_type = "coupon";
            }
            if (
                empty(drv_global_discount_type) &&
                !empty(currentTrx.redeem) &&
                currentTrx.redeem.redeem_item.pos_link_type === "discount"
            ) {
                drv_global_discount_type = "redeem";
            }
            if (
                empty(drv_global_discount_type) &&
                !empty(currentTrx.customer) &&
                !empty(currentTrx.customer.discount_level) &&
                !empty(currentTrx.customer.discount_level.reward_value) &&
                currentTrx.customer.discount_level.reward_value > 0
            ) {
                drv_global_discount_type = "member";
            }
            if (
                empty(drv_global_discount_type) &&
                !empty(currentTrx.discounts)
            ) {
                currentTrx.discounts.map(discount => {
                    if (discount.discount_type === "custom") {
                        drv_global_discount_type = "custom";
                    }
                    return discount;
                });
            }
            if (
                empty(drv_global_discount_type) &&
                !empty(currentTrx.discounts)
            ) {
                currentTrx.discounts.map(discount => {
                    if (discount.discount_type === "basic") {
                        drv_global_discount_type = "defined";
                    }
                    return discount;
                });
            }

            let formattedTrxData = {
                ...currentTrx,
                trx_local_end_time: now(),
                is_synced: false,
                trx_time: now(),
                local_shift_id: this.props.activeShift?.local_shift_id,
                trx_discount_type: drv_global_discount_type,
                details: this.fillDetailDrvs(currentTrx),
                ...PosPriceCalculator.generateTrxDrvValues(
                    currentTrx,
                    posConfig,
                    this.currencyCode
                ),
                nfc_code: this.state.nfc_code,
                cashier_configs: {
                    business_user_id: posConfig.business_user_id,
                    subscription_id: posConfig.subscription_id,
                    business_id: posConfig.business_id,
                    business_slug: posConfig.business_slug,
                    business_name: posConfig.business_name,
                    business_outlet_id: posConfig.business_outlet_id,
                    outlet_id: posConfig.outlet_id,
                    outlet_name: posConfig.outlet_name,
                    outlet_address: posConfig.outlet_address,
                    outlet_phone: posConfig.outlet_phone,
                    brand_id: posConfig.brand_id,
                    brand_name: posConfig.brand_name,
                    brand_logo: posConfig.brand_logo,
                    business_user_email: posConfig.business_user_email,
                    business_user_group_id: posConfig.business_user_group_id,
                    business_user_firstname: posConfig.business_user_firstname,
                    business_user_lastname: posConfig.business_user_lastname,
                    cashier_name: posConfig.cashier_name,
                    is_verified: posConfig.is_verified,

                    loyalty: posConfig.loyalty,
                    pos: posConfig.pos,
                    wallet: posConfig.wallet,
                    coupon: posConfig.coupon,
                    is_api_integrated: posConfig.is_api_integrated,
                    is_getpos: posConfig.is_getpos,
                    is_old_migration: posConfig.is_old_migration,
                    is_loyalty_reference: posConfig.is_loyalty_reference,
                    is_birthday_scheme: posConfig.is_birthday_scheme,
                    is_referral_scheme: posConfig.is_referral_scheme,
                    is_redeem_blocker_scheme:
                    posConfig.is_redeem_blocker_scheme,
                    is_pos_commission_scheme:
                    posConfig.is_pos_commission_scheme,
                    is_pos_multicommission_scheme:
                    posConfig.is_pos_multicommission_scheme,
                    pos_commission_base: posConfig.pos_commission_base,
                    custom_item_commission_percentage: posConfig.custom_item_commission_percentage,
                    is_service_area_scheme: posConfig.is_service_area_scheme,
                    is_pos_counter: posConfig.is_pos_counter,
                    use_wallet: posConfig.use_wallet,
                    use_wallet_security_code:
                    posConfig.use_wallet_security_code,
                    business_wallet: posConfig.business_wallet,
                    use_coupon: posConfig.use_coupon,
                    cashier_indirect_coupon_redeem:
                    posConfig.cashier_indirect_coupon_redeem,
                    is_trx_upload: posConfig.is_trx_upload,
                    is_loyalty_configured: posConfig.is_loyalty_configured,
                    is_point_activated: posConfig.is_point_activated,
                    is_discount_activated: posConfig.is_discount_activated,
                    is_commission_using_package_price: posConfig.is_commission_using_package_price,
                    has_membership_activation:
                    posConfig.has_membership_activation,
                    has_automatic_membership:
                    posConfig.has_automatic_membership,
                    pos_commission_after_discount:
                    posConfig.pos_commission_after_discount,
                    loyalty_commission_after_discount:
                    posConfig.loyalty_commission_after_discount,
                    same_outlet_coupon_redeem: posConfig.same_outlet_coupon_redeem,
                    pos_account_receivable: posConfig.pos_account_receivable,
                    is_commission_using_coupon_selling_price: posConfig.is_commission_using_coupon_selling_price,
                    is_commission_using_variant_cost: posConfig.is_commission_using_variant_cost,
                    is_coupon_get_point: posConfig.is_coupon_get_point,
                    is_pos_commission_seller: posConfig.is_pos_commission_seller,
                    is_pos_commission_multi_seller: posConfig.is_pos_commission_multi_seller,
                    currency_code: posConfig.currency_code,
                    receipt_template: posConfig.receipt_template,
                    receipt_footer: posConfig.receipt_footer,
                    pos_print_worker: posConfig.pos_print_worker,
                    pos_print_initial: posConfig.pos_print_initial,
                    pos_print_logo: posConfig.pos_print_logo,
                    pos_print_address: posConfig.pos_print_address,
                    pos_print_user_identifier: posConfig.pos_print_user_identifier,
                    print_deposit_balance_on_receipt: posConfig.print_deposit_balance_on_receipt,
                    pos_print_receipt_order_number_bottom: posConfig.pos_print_receipt_order_number_bottom,
                    pos_print_order_detail: posConfig.pos_print_order_detail,
                    coupon_start_date_type: posConfig.coupon_start_date_type,
                    wallet_use_nfc: posConfig.wallet_use_nfc,
                    coupon_use_nfc: posConfig.coupon_use_nfc,
                    direct_reward_redeem: posConfig.direct_reward_redeem,


                    tax: posConfig.tax,
                    service_charge: posConfig.service_charge,
                    additional_charge: posConfig.additional_charge,
                    subscription_rules: posConfig.subscription_rules,
                    retrieve_time: posConfig.retrieve_time
                }
            };

            let lastOrderNo = 1;
            this.props.posTrx.map(trx => {
                if (trx.order_no >= lastOrderNo) {
                    lastOrderNo = trx.order_no + 1;
                }
                return trx;
            });

            const formData = {
                is_offline: 0,
                trxs: [
                    {
                        ...formattedTrxData,
                        trx_status_id: 3,
                        order_no: lastOrderNo
                    }
                ]
            };

            if (mustOnline) {
                apiCall(
                    endpoint.POST_POS_TRX,
                    "post",
                    formData,
                    result => {
                        this.onTrxSuccess(result.data[0], mustOnline, formattedTrxData);
                    },
                    error => {
                        if(error.status === 303){
                            ErrorHandler.handleGeneralError(error);
                        }
                        this.onTrxFailed(error, mustOnline, formattedTrxData, retry);
                    });
            } else {
                promiseTimeout(
                    3000,
                    new Promise((resolve, reject) => {
                        return apiCall(
                            endpoint.POST_POS_TRX,
                            "post",
                            formData,
                            response => {
                                resolve(response);
                            },
                            response => {
                                if(response.status === 303){
                                    ErrorHandler.handleGeneralError(response);
                                }
                                reject(response);
                            }
                        );
                    })
                )
                    .then(result => {
                        this.onTrxSuccess(result.data[0], mustOnline, formattedTrxData);
                    })
                    .catch(error => {
                        this.onTrxFailed(error, mustOnline, formattedTrxData, retry);
                    });
            }
        }
    };

    onTrxSuccess = (res, mustOnline, formattedTrxData) => {
        let lastOrderNo = 1;
        this.props.posTrx.map(trx => {
            if (trx.order_no >= lastOrderNo) {
                lastOrderNo = trx.order_no + 1;
            }
            return trx;
        });

        if (!empty(res.pos_sales_trx_head_id) && res.status === "success") {
            this.props.setTrxProps(this.props.currentLocalTrxId, {
                ...formattedTrxData,
                deposit_balance: res.deposit_balance,
                top_up_deposit_rewards: res.top_up_deposit_rewards,
                pos_sales_trx_head_id: res.pos_sales_trx_head_id,
                trx_id: res.trx_id,
                created_at: res.created_at,
                sync_token: res.sync_token,
                sync_time: res.sync_time,
                trx_status_id: 3,
                isSynced: true,
                order_no: lastOrderNo
            });

            this.setFormLoading(false);
            Modal.success({
                title: "Transaksi Sukses!",
                content: "Transaksi telah berhasil dilakukan secara online.",
                onOk: () => {
                    if (this.props.cashierConfig.displayMode === "TABLET" && this.props.posConfig.pos_print_digital_invoice !== 1) {
                        window.location = "pos/receipt/" + this.props.currentTrx.local_trx_id + "/view";
                        // window.open('pos/receipt/' + this.props.currentTrx.local_trx_id + '/view', '_blank', 'rel=noopener noreferrer');
                    } else {
                        this.setState({
                            step: 4
                        });
                    }
                }
            });
        } else {
            this.setFormLoading(false);
            if (!mustOnline) {
                this.props.setTrxProps(this.props.currentLocalTrxId, {
                    ...formattedTrxData,
                    trx_status_id: 3,
                    order_no: lastOrderNo
                });

                Modal.success({
                    title: "Transaksi Sukses!",
                    content:
                        "Transaksi telah berhasil dilakukan secara offline.",
                    onOk: () => {
                        // this.props.setNewLocalTrx();
                        if (this.props.cashierConfig.displayMode === "TABLET" && this.props.posConfig.pos_print_digital_invoice !== 1) {
                            window.location = "pos/receipt/" + this.props.currentTrx.local_trx_id + "/view";
                            // window.open('pos/receipt/' + this.props.currentTrx.local_trx_id + '/view', '_blank', 'rel=noopener noreferrer');
                        } else {
                            this.setState({
                                step: 4
                            });
                        }
                    }
                });
            } else {
                message.error("Transaksi gagal, mohon coba kembali.");
            }
        }
    };

    onTrxFailed = (err, mustOnline, formattedTrxData, retry) => {
        let lastOrderNo = 1;
        this.props.posTrx.map(trx => {
            if (trx.order_no >= lastOrderNo) {
                lastOrderNo = trx.order_no + 1;
            }
            return trx;
        });

        this.setFormLoading(false);
        if (!mustOnline) {
            this.props.setTrxProps(this.props.currentLocalTrxId, {
                ...formattedTrxData,
                trx_status_id: 3,
                order_no: lastOrderNo
            });

            Modal.success({
                title: "Transaksi Sukses!",
                content: "Transaksi telah berhasil dilakukan secara offline.",
                onOk: () => {
                    if (this.props.cashierConfig.displayMode === "TABLET" && this.props.posConfig.pos_print_digital_invoice !== 1) {
                        window.location = "pos/receipt/" + this.props.currentTrx.local_trx_id + "/view";
                        // window.open('pos/receipt/' + this.props.currentTrx.local_trx_id + '/view', '_blank', 'rel=noopener noreferrer');
                    } else {
                        this.setState({
                            step: 4
                        });
                    }
                }
            });
        } else {
            if (err === "timeout") {
                if (retry > 0) {
                    this.onSubmitTransaction(retry - 1);
                } else {
                    message.error(
                        "Transaksi gagal karena jariangan internet buruk, mohon coba kembali beberapa saat lagi."
                    );
                }
            } else if (typeof err === "string") {
                message.error(err);
            }

            if (err && err.data && err.data.error && err.data.error.message) {
                message.error(err.data.error.message);
            } else if (err && err.data) {
                message.error("Transaksi gagal, mohon coba kembali.");
            }
        }
    };

    // RENDER FUNCTIONS
    renderNewTrxButtons = sales_types => {
        return sales_types?.map(row => {
            return (
                <li
                    key={`NewTrxButton-${row.pos_salesType_id}`}
                    className={styles.newTrxBtn}
                    onClick={() => {
                        this.onClickNewTrxBtn(row.pos_salesType_id, row.pos_salesType_name);
                    }}
                >
                    <Icon
                        className={styles.newTrxIcon}
                        type="shopping-cart"
                        theme="outlined"
                    />
                    {row.pos_salesType_name}
                </li>
            );
        });
    };

    onSendSmsReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            this.setFormLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "phone",
                    phone
                },
                result => {
                    message.success("SMS Berhasil dikirimkan");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setFormLoading(false);
                });
        }
    };

    onSendWhatsAppReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            let parsedPhone = phone;
            if (phone[0] !== "0" && phone.substring(0, 1) !== "62") {
                parsedPhone = "62" + phone;
            }
            if (phone[0] === "0") {
                parsedPhone = "62" + phone.substring(1);
            }

            this.setFormLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "text",
                    phone
                },
                result => {
                    const waText = `phone=${parsedPhone}&text=${result.data.message}`;
                    const waURL = "https://api.whatsapp.com/send?" + waText;
                    window.open(waURL, "_blank");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setFormLoading(false);
                });
        }
    };

    onSendWhatsAppDesktopReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            let parsedPhone = phone;
            if (phone[0] !== "0" && phone.substring(0, 1) !== "62") {
                parsedPhone = "62" + phone;
            }
            if (phone[0] === "0") {
                parsedPhone = "62" + phone.substring(1);
            }

            this.setFormLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "text",
                    phone
                },
                result => {
                    const waText = `phone=${parsedPhone}&text=${result.data.message}`;
                    const waURL = "whatsapp://send?" + waText;
                    window.open(waURL, "_blank");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setFormLoading(false);
                });
        }
    };

    onSendEmailReceipt = (local_trx_id, email) => {
        if (empty(email)) {
            message.error("Email is required");
        } else {
            this.setFormLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "email",
                    email
                },
                result => {
                    message.success("Email Berhasil dikirimkan");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setFormLoading(false);
                });
        }
    };

    onCancelAppointment = () => {
        if(window.confirm("Apakah Anda yakin ingin keluar tanpa menyimpan transaksi?")){
            this.props.history.push("/appointment");
        }
    };

    onSaveAppointment = () => {
        const posTrx = this.props.currentTrx;
        const currentAppointment = this.props.appointments.find(appointment => appointment.local_appointment_id === posTrx.local_appointment_id);

        if(!empty(currentAppointment)){
            this.setFormLoading(true);

            let user_code = currentAppointment.user_code;
            let user_name = currentAppointment.user_name;
            if(!empty(posTrx.customer)){
                const user = posTrx.customer;
                user_code = user.user_code;
                user_name = user.user_name;
            }

            const formData = {
                local_appointment_id: currentAppointment.local_appointment_id,
                user_code: user_code,
                user_name: user_name,
                pos_salesType_id: currentAppointment.pos_salesType_id,
                business_commission_worker_id: currentAppointment.business_commission_worker_id,
                appointment_date: currentAppointment.appointment_date,
                start_time: moment(currentAppointment.start_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_SQL_TIME_FORMAT_MINUTE),
                end_time: moment(currentAppointment.end_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_SQL_TIME_FORMAT_MINUTE),
                appointment_notes: currentAppointment.appointment_notes,
                pos_local_trx_id: posTrx.local_trx_id,
                pos_trx: posTrx
            };

            apiCall(
                REST_APPOINTMENT,
                "put",
                formData,
                res => {
                    Promise.all([
                        this.props.setAppointmentProps(currentAppointment.local_appointment_id, res.data.appointment),
                        this.props.switchCurrentTrx(null)
                    ]).then(() => {
                        message.success('Detail sales appointment berhasil disimpan.');
                        this.props.history.push("/appointment");
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.setFormLoading(false);
                }
            );
        }
    };

    convertAppointment = () => {
        const that = this;
        const local_trx_id = this.props.currentLocalTrxId;
        Modal.confirm({
            title: "Appointment Checkout",
            content: "Apakah Anda yakin ingin mengubah appointment ini menjadi penjualan?",
            onOk: () => {
                that.props.setTrxProps(local_trx_id, {
                    trx_status_id: 2
                });
            },
            onCancel: () => {
            }
        });
    };

    render() {
        const {
            step,
            formLoading,
            pageTitle,
            pendingTrxFilterKeyword,
            stockOutModalOpened
        } = this.state;
        const { currentLocalTrxId, currentTrx, posConfig, cashierConfig } = this.props;
        const newTrxButtons = this.renderNewTrxButtons(posConfig.sales_type);

        let unpaidRemainder = 0;
        let priceListItemEditable = false;
        if (!empty(currentTrx)) {
            if (!empty(currentTrx.payments)) {
                const grand_total = PosPriceCalculator.calculateGrandTotal(
                    this.props.currentTrx,
                    posConfig,
                    this.currencyCode
                ).grandTotal;
                const reducer = (accumulator, currentValue) =>
                    accumulator + currentValue.payment_amount;
                const totalPayment = currentTrx.payments.reduce(reducer, 0);
                unpaidRemainder = Math.round(grand_total) - parseInt(totalPayment);
            }

            priceListItemEditable = currentTrx.trx_type === TRX_TYPE_NORMAL || currentTrx.trx_type === TRX_TYPE_COUPON_SALES;
        }

        const couponTrxAllowed = posConfig.subscription_rules && posConfig.subscription_rules.coupon && posConfig.auth_role_accesses.COUPON_SALES === 1;
        const depositTrxAllowed = posConfig.subscription_rules && posConfig.subscription_rules.deposit && posConfig.auth_role_accesses.TOP_UP_DEPOSIT === 1;
        const accountReceivableTrxAllowed = posConfig.pos_account_receivable === 1 && posConfig.auth_role_accesses.ACCOUNT_RECEIVABLE_PAYMENT === 1;
        const membershipTrxAllowed = !empty(posConfig.business_memberships?.find(row => parseInt(row.price) > 0)) && posConfig.auth_role_accesses.MEMBERSHIP_SALES === 1;
        const nonFreeMemberships = posConfig.business_memberships?.filter(row => parseInt(row.price) > 0);

        let receiptPhone = "";
        if (!empty(currentTrx)) {
            if (!empty(currentTrx.customer)) {
                if (currentTrx.customer.user_identifier_type === "phone") {
                    receiptPhone = currentTrx.customer.user_identifier_string;
                }
            }
        }

        let receiptMail = "";
        if (!empty(currentTrx)) {
            if (!empty(currentTrx.customer)) {
                if (!empty(currentTrx.customer.user_email_notification)) {
                    receiptMail = currentTrx.customer.user_email_notification;
                }
            }
        }

        const verticalRadioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        return (
            <Spin spinning={formLoading}>
                {step === 1 && (
                    <React.Fragment>
                        <Row>
                            <Col span={24}>
                                <PosHeader
                                    pageTitle={pageTitle}
                                    backBtnAction={this.navigateToHome}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6} lg={24}>
                                <div className={styles.trxListPanel}>
                                    <h2
                                        style={{
                                            borderBottom:
                                                "1px solid rgba(0,0,0,0.1)",
                                            margin: 0,
                                            padding: "30px 0"
                                        }}
                                    >
                                        Transaksi Baru
                                    </h2>
                                    <ul className={styles.newTrxList}>
                                        {newTrxButtons}

                                        {couponTrxAllowed && (
                                            <li
                                                className={styles.newTrxBtn}
                                                onClick={
                                                    this.newCouponSalesTrx
                                                }
                                            >
                                                <Icon
                                                    className={
                                                        styles.newTrxIcon
                                                    }
                                                    type="idcard"
                                                    theme="outlined"
                                                />
                                                Kirim/Jual Kupon
                                            </li>
                                        )}

                                        {depositTrxAllowed && (
                                            <li
                                                className={styles.newTrxBtn}
                                                onClick={
                                                    this.newTopUpDepositTrx
                                                }
                                            >
                                                <Icon
                                                    className={
                                                        styles.newTrxIcon
                                                    }
                                                    type="wallet"
                                                    theme="outlined"
                                                />
                                                Top Up Deposit
                                            </li>
                                        )}

                                        {membershipTrxAllowed && (
                                            <li
                                                className={styles.newTrxBtn}
                                                onClick={
                                                    this.newMembershipSalesTrx
                                                }
                                            >
                                                <Icon
                                                    className={
                                                        styles.newTrxIcon
                                                    }
                                                    type="user-add"
                                                    theme="outlined"
                                                />
                                                Jual Membership
                                            </li>
                                        )}

                                        {accountReceivableTrxAllowed && (
                                            <li
                                                className={styles.newTrxBtn}
                                                onClick={
                                                    this.newAccountReceivableTrx
                                                }
                                            >
                                                <Icon
                                                    className={
                                                        styles.newTrxIcon
                                                    }
                                                    type="audit"
                                                    theme="outlined"
                                                />
                                                Pembayaran Piutang
                                            </li>
                                        )}

                                    </ul>
                                </div>
                            </Col>
                            <Col xl={9} lg={12} sm={24} className={styles.middlePanel}>
                                <div className={styles.trxListPanel}>
                                    <h2>
                                        Transaksi Tertunda
                                        <Button type="primary"
                                                icon="file-protect"
                                                size="default"
                                                shape="round"
                                                onClick={() => {
                                                    this.setState({
                                                        savedTrxModalOpened: true
                                                    });
                                                }}
                                                style={{
                                                    float: "right",
                                                    top: -5,
                                                    right: 15
                                                }}>
                                            Tersimpan
                                        </Button>
                                    </h2>
                                    <div className={styles.trxListSearch}>
                                        <Input.Search
                                            placeholder="Cari transaksi.."
                                            onChange={e => {
                                                this.setState({
                                                    pendingTrxFilterKeyword:
                                                    e.target.value
                                                });
                                            }}
                                            value={pendingTrxFilterKeyword}
                                        />
                                    </div>
                                    <PosPendingTrxList
                                        filterKeyword={pendingTrxFilterKeyword}
                                        onItemClickedCallback={
                                            this.onPendingTrxClicked
                                        }
                                    />
                                    {this.state.savedTrxModalOpened && (
                                        <SavedTrxModal
                                            modalOpened={this.state.savedTrxModalOpened}
                                            close={() => {
                                                this.setState({
                                                    savedTrxModalOpened: false
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </Col>
                            <Col xl={9} lg={12} sm={24}>
                                <div className={styles.trxListPanel}>
                                    <h2>Transaksi Selesai</h2>
                                    <div className={styles.trxListSearch}>
                                        <Input.Search
                                            placeholder="Cari transaksi.."
                                            onChange={e => {
                                                this.setState({
                                                    completedTrxFilterKeyword:
                                                    e.target.value
                                                });
                                            }}
                                            value={
                                                this.state
                                                    .completedTrxFilterKeyword
                                            }
                                        />
                                    </div>
                                    <PosCompletedTrxList
                                        filterKeyword={
                                            this.state.completedTrxFilterKeyword
                                        }
                                        onItemClickedCallback={() => {
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {!empty(this.props.currentLocalTrxId) && step === 2 && (
                    <React.Fragment>
                        <Row>
                            <Col span={24}>
                                {this.props.currentTrx.trx_status_id === 1 && (
                                    <PosHeader
                                        pageTitle="Appointment Sales"
                                        // backBtnAction={this.navigateToHome}
                                    />
                                )}
                                {this.props.currentTrx.trx_status_id > 1 && (
                                    <PosHeader
                                        pageTitle={pageTitle}
                                        menus={
                                            <React.Fragment>
                                                {this.props.posConfig?.auth_role_accesses?.SAVE_TRANSACTION === 1 && (
                                                    <div style={{
                                                        cursor: "pointer",
                                                        display: "inline-block"
                                                    }}
                                                         onClick={this.archiveCurrentTrx}>
                                                        <Icon
                                                            style={{
                                                                fontSize: "1.25em"
                                                            }}
                                                            type="file-protect"
                                                            theme="outlined"
                                                            title="Simpan Transaksi"
                                                        /> Simpan
                                                    </div>
                                                )}
                                                <div style={{
                                                    cursor: "pointer",
                                                    marginLeft: 20,
                                                    display: "inline-block"
                                                }}
                                                     onClick={this.deleteCurrentTrx}
                                                >
                                                    <Icon
                                                        style={{
                                                            fontSize: "1.25em"
                                                        }}
                                                        type="delete"
                                                        theme="outlined"
                                                        title="Hapus Transaksi"
                                                    /> Hapus
                                                </div>
                                            </React.Fragment>
                                        }
                                        backBtnAction={() => {
                                            this.goToStep(1);
                                        }}
                                    />
                                )}

                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                {(currentTrx.trx_type === TRX_TYPE_NORMAL ||
                                    currentTrx.trx_type ===
                                    TRX_TYPE_COUPON_SALES) && (
                                    <PosItemList/>
                                )}

                                {currentTrx.trx_type ===
                                TRX_TYPE_TOP_UP_DEPOSIT && (
                                    <div className={styles.topUpDepositForm}>
                                        <h2>Top Up Deposit</h2>

                                        {empty(currentTrx.customer) && (
                                            <React.Fragment>
                                                <div
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Silakan pilih customer
                                                    terlebih dahulu
                                                    (Pastikan internet
                                                    aktif)
                                                </div>
                                                {!empty(currentTrx.customer) &&
                                                empty(
                                                    currentTrx.customer
                                                        .user_identifier_string
                                                ) && (
                                                    <div
                                                        style={{
                                                            textAlign:
                                                                "center"
                                                        }}
                                                    >
                                                        Silakan pilih
                                                        customer terlebih
                                                        dahulu (Pastikan
                                                        internet aktif)
                                                    </div>
                                                )}
                                                {!empty(currentTrx.customer) &&
                                                !empty(
                                                    currentTrx.customer
                                                        .user_identifier_string
                                                ) && (
                                                    <div
                                                        style={{
                                                            textAlign:
                                                                "center"
                                                        }}
                                                    >
                                                        Customer tidak
                                                        terdaftar sebagai
                                                        member, silakan
                                                        pilih customer
                                                        lainnya
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {!empty(currentTrx.customer) && (
                                            <React.Fragment>
                                                <div>
                                                    <h4
                                                        style={{
                                                            marginTop: 15
                                                        }}
                                                    >
                                                        Nominal Top Up
                                                    </h4>
                                                    <Input
                                                        defaultValue={0}
                                                        type="text"
                                                        addonBefore={this.currencyCode}
                                                        size="large"
                                                        onChange={e => {
                                                            const inputCallback = (value, valueText) => {
                                                                const itemMeta = {
                                                                    pos_item_id: 1,
                                                                    item_name:
                                                                        "Top Up Deposit",
                                                                    item_image: "",
                                                                    pos_item_category_id: 0,
                                                                    category_name:
                                                                        "",
                                                                    is_package: 0,
                                                                    no_commission: 0,
                                                                    pos_item_variants: [
                                                                        {
                                                                            pos_item_variant_id: 1,
                                                                            pos_item_id: 1,
                                                                            variant_name:
                                                                                "",
                                                                            variant_price: value,
                                                                            variant_price_text: valueText,
                                                                            sku: "",
                                                                            commission_percentage: 0,
                                                                            commission_value: 0,
                                                                            variant_cost: 0
                                                                        }
                                                                    ],
                                                                    pos_item_discounts: [],
                                                                    pos_item_package_details: [],
                                                                    pos_item_pos_item_modifiers: []
                                                                };
                                                                const itemData = {
                                                                    is_package: 0,
                                                                    pos_item_variant_id: 1,
                                                                    package_pos_item_id: 0,
                                                                    qty: 1,
                                                                    custom_discount_type:
                                                                        "",
                                                                    custom_discount_value: 0,
                                                                    notes: "",
                                                                    discounts: [],
                                                                    pos_sales_type:
                                                                        "",
                                                                    commission_worker: null,
                                                                    business_commission_worker_id: null,
                                                                    pos_item_package_details: null,
                                                                    pos_item_pos_item_modifiers: null,
                                                                    item_sellers: []
                                                                };

                                                                if (
                                                                    empty(
                                                                        currentTrx.details
                                                                    )
                                                                ) {
                                                                    this.props.addTrxDetail(
                                                                        currentLocalTrxId,
                                                                        [],
                                                                        itemMeta,
                                                                        itemData
                                                                    );
                                                                } else {
                                                                    this.props.editTrxDetail(
                                                                        currentLocalTrxId,
                                                                        currentTrx
                                                                            .details[0]
                                                                            .local_trx_detail_id,
                                                                        {
                                                                            ...currentTrx
                                                                                .details[0],
                                                                            itemMeta: {
                                                                                ...itemMeta
                                                                            }
                                                                        }
                                                                    );
                                                                }
                                                            };
                                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                        }}
                                                        value={
                                                            empty(
                                                                this.props
                                                                    .currentTrx
                                                                    .details
                                                            )
                                                                ? 0
                                                                : this.props
                                                                .currentTrx
                                                                .details[0]
                                                                .itemMeta
                                                                .pos_item_variants[0]
                                                                .variant_price_text || 0
                                                        }
                                                    />
                                                </div>

                                                {posConfig.is_pos_commission_seller === 1
                                                && posConfig.is_pos_commission_seller_top_up_deposit === 1
                                                && !empty(currentTrx.details) && (
                                                    <React.Fragment>
                                                        <h4 style={{ marginTop: 15 }}>
                                                            Seller
                                                        </h4>

                                                        {posConfig.is_pos_commission_multi_seller === 0 && (
                                                            <Select
                                                                style={{ width: "100%" }}
                                                                placeholder="Pilih seller"
                                                                defaultValue={(!empty(currentTrx.details[0].item_sellers) && currentTrx.details[0].item_sellers.length === 1) ? currentTrx.details[0].item_sellers[0].business_commission_worker_id : null}
                                                                onChange={(e) => {
                                                                    const chosenSellers = posConfig.commission_worker.filter(seller => {
                                                                        return seller.business_commission_worker_id === e;
                                                                    }).map(seller => {
                                                                        return {
                                                                            ...seller,
                                                                            percentage: 100,
                                                                            pos_item_commission_workers: []
                                                                        };
                                                                    });
                                                                    this.props.editTrxDetail(
                                                                        currentLocalTrxId,
                                                                        currentTrx
                                                                            .details[0]
                                                                            .local_trx_detail_id,
                                                                        {
                                                                            ...currentTrx
                                                                                .details[0],
                                                                            item_sellers: chosenSellers
                                                                        }
                                                                    );
                                                                }}
                                                                showSearch={true}
                                                                allowClear={true}
                                                                optionLabelProp="label"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {posConfig.commission_worker.map(row => (
                                                                    <Select.Option key={row.business_commission_worker_id}
                                                                                   value={row.business_commission_worker_id}
                                                                                   label={`${row.firstname} ${row.lastname}`}>
                                                                        {`${row.firstname} ${row.lastname}`}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}

                                                        {posConfig.is_pos_commission_multi_seller === 1 && (
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: "100%" }}
                                                                placeholder="Pilih seller"
                                                                defaultValue={currentTrx.details[0].item_sellers.map(row => row.business_commission_worker_id)}
                                                                onChange={(e) => {
                                                                    const chosenSellers = posConfig.commission_worker.filter(seller => {
                                                                        return e.find(row => seller.business_commission_worker_id === row);
                                                                    }).map((seller, index, sellerArray) => {
                                                                        let percentage = 100;
                                                                        if(posConfig.is_pos_commission_auto_split_multi_seller === 1){
                                                                            percentage = 100 / sellerArray.length;
                                                                        }

                                                                        return {
                                                                            ...seller,
                                                                            percentage,
                                                                            pos_item_commission_workers: []
                                                                        };
                                                                    });
                                                                    this.props.editTrxDetail(
                                                                        currentLocalTrxId,
                                                                        currentTrx
                                                                            .details[0]
                                                                            .local_trx_detail_id,
                                                                        {
                                                                            ...currentTrx
                                                                                .details[0],
                                                                            item_sellers: chosenSellers
                                                                        }
                                                                    );
                                                                }}
                                                                optionLabelProp="label"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {posConfig.commission_worker.map(row => (
                                                                    <Select.Option key={row.business_commission_worker_id}
                                                                                   value={row.business_commission_worker_id}
                                                                                   label={`${row.firstname} ${row.lastname}`}>
                                                                        {`${row.firstname} ${row.lastname}`}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}

                                {currentTrx.trx_type ===
                                TRX_TYPE_ACCOUNT_RECEIVABLE && (
                                    <div className={styles.topUpDepositForm}>
                                        <h2>Pembayaran Piutang</h2>

                                        {(empty(currentTrx.customer) ||
                                            empty(
                                                currentTrx.customer
                                            )) && (
                                            <React.Fragment>
                                                {empty(currentTrx.customer) && (
                                                    <div
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        Silakan pilih customer
                                                        terlebih dahulu
                                                        (Pastikan internet
                                                        aktif)
                                                    </div>
                                                )}
                                                {!empty(currentTrx.customer) &&
                                                empty(
                                                    currentTrx.customer
                                                        .user_identifier_string
                                                ) && (
                                                    <div
                                                        style={{
                                                            textAlign:
                                                                "center"
                                                        }}
                                                    >
                                                        Silakan pilih
                                                        customer terlebih
                                                        dahulu (Pastikan
                                                        internet aktif)
                                                    </div>
                                                )}
                                                {!empty(currentTrx.customer) &&
                                                !empty(
                                                    currentTrx.customer
                                                        .user_identifier_string
                                                ) && (
                                                    <div
                                                        style={{
                                                            textAlign:
                                                                "center"
                                                        }}
                                                    >
                                                        Customer tidak
                                                        terdaftar sebagai
                                                        member, silakan
                                                        pilih customer
                                                        lainnya
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {!empty(currentTrx.customer) && (
                                            <div>
                                                <h4
                                                    style={{
                                                        marginTop: 15
                                                    }}
                                                >
                                                    Nominal Pembayaran Piutang
                                                </h4>
                                                <Input
                                                    defaultValue={0}
                                                    type="text"
                                                    addonBefore={this.currencyCode}
                                                    size="large"
                                                    onChange={e => {
                                                        const inputCallback = (value, valueText) => {
                                                            const itemMeta = {
                                                                pos_item_id: 1,
                                                                item_name:
                                                                    "Pembayaran Piutang",
                                                                item_image: "",
                                                                pos_item_category_id: 0,
                                                                category_name:
                                                                    "",
                                                                is_package: 0,
                                                                no_commission: 0,
                                                                pos_item_variants: [
                                                                    {
                                                                        pos_item_variant_id: 1,
                                                                        pos_item_id: 1,
                                                                        variant_name:
                                                                            "",
                                                                        variant_price: value,
                                                                        variant_price_text: valueText,
                                                                        sku: "",
                                                                        commission_percentage: 0,
                                                                        commission_value: 0,
                                                                        variant_cost: 0
                                                                    }
                                                                ],
                                                                pos_item_discounts: [],
                                                                pos_item_package_details: [],
                                                                pos_item_pos_item_modifiers: []
                                                            };
                                                            const itemData = {
                                                                is_package: 0,
                                                                pos_item_variant_id: 1,
                                                                package_pos_item_id: 0,
                                                                qty: 1,
                                                                custom_discount_type:
                                                                    "",
                                                                custom_discount_value: 0,
                                                                notes: "",
                                                                discounts: [],
                                                                pos_sales_type:
                                                                    "",
                                                                commission_worker: null,
                                                                business_commission_worker_id: null,
                                                                pos_item_package_details: null,
                                                                pos_item_pos_item_modifiers: null
                                                            };

                                                            if (
                                                                empty(
                                                                    currentTrx.details
                                                                )
                                                            ) {
                                                                this.props.addTrxDetail(
                                                                    currentLocalTrxId,
                                                                    [],
                                                                    itemMeta,
                                                                    itemData
                                                                );
                                                            } else {
                                                                this.props.editTrxDetail(
                                                                    currentLocalTrxId,
                                                                    currentTrx
                                                                        .details[0]
                                                                        .local_trx_detail_id,
                                                                    {
                                                                        ...currentTrx
                                                                            .details[0],
                                                                        itemMeta: {
                                                                            ...itemMeta
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        };
                                                        processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                    }}
                                                    value={
                                                        empty(
                                                            this.props
                                                                .currentTrx
                                                                .details
                                                        )
                                                            ? 0
                                                            : this.props
                                                            .currentTrx
                                                            .details[0]
                                                            .itemMeta
                                                            .pos_item_variants[0]
                                                            .variant_price_text || 0
                                                    }
                                                />
                                            </div>
                                        )}

                                        {!empty(currentTrx.customer) &&
                                        !empty(currentTrx.customer.account_receivables) && (
                                            <React.Fragment>
                                                <h4
                                                    style={{
                                                        marginTop: 15
                                                    }}
                                                >
                                                    Pilih Transaksi Piutang
                                                </h4>
                                                <Radio.Group onChange={e => {
                                                    this.props.setTrxProps(currentLocalTrxId, {
                                                        account_receivable: currentTrx.customer.account_receivables.find(
                                                            row => row.account_receivable_id === e.target.value
                                                        )
                                                    });
                                                }}
                                                             value={
                                                                 empty(currentTrx.account_receivable)
                                                                     ? 0
                                                                     : currentTrx.account_receivable.account_receivable_id
                                                             }>
                                                    {currentTrx.customer.account_receivables.map(row => (
                                                        <Radio key={`AccountReceivable${row.account_receivable_id}`}
                                                               style={verticalRadioStyle}
                                                               value={row.account_receivable_id}>
                                                            {this.currencyCode} {number_format(row.drv_balance)} (Transaksi {moment(row.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}) {!empty(row.payment_method_reference) && ` - Ref: ${row.payment_method_reference}`}
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            </React.Fragment>

                                        )}

                                        {!empty(currentTrx.customer) &&
                                        empty(currentTrx.customer.account_receivables) && (
                                            <React.Fragment>
                                                <h4
                                                    style={{
                                                        marginTop: 15
                                                    }}
                                                >
                                                    Nominal Pembayaran Piutang
                                                </h4>
                                                <div>Tidak ada transaksi piutang untuk customer ini</div>
                                            </React.Fragment>

                                        )}
                                    </div>
                                )}

                                {currentTrx.trx_type ===
                                TRX_TYPE_MEMBERSHIP_SALES && (
                                    <div className={styles.topUpDepositForm}>
                                        <h2>Aktifkan / Perpanjang Membership</h2>

                                        {empty(currentTrx.customer) && (
                                            <React.Fragment>
                                                {empty(currentTrx.customer) && (
                                                    <div
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        Silakan tentukan customer
                                                        terlebih dahulu
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {!empty(currentTrx.customer) && (
                                            <React.Fragment>
                                                <div style={{
                                                    textAlign: "center"
                                                }}>
                                                    <h4
                                                        style={{
                                                            marginTop: 15
                                                        }}
                                                    >
                                                        Pilih membership
                                                    </h4>
                                                    {nonFreeMemberships.map(row => {
                                                        return (
                                                            <div className={styles.membershipItem}
                                                                 key={`Membership${row.business_membership_id}`}
                                                                 onClick={() => {
                                                                     const itemMeta = {
                                                                         pos_item_id: 1,
                                                                         item_name:
                                                                         row.membership_name,
                                                                         item_image: "",
                                                                         pos_item_category_id: 0,
                                                                         category_name:
                                                                             "",
                                                                         is_package: 0,
                                                                         no_commission: 0,
                                                                         pos_item_variants: [
                                                                             {
                                                                                 pos_item_variant_id: 1,
                                                                                 pos_item_id: 1,
                                                                                 variant_name:
                                                                                     "",
                                                                                 variant_price: parseInt(row.price),
                                                                                 sku: "",
                                                                                 commission_percentage: 0,
                                                                                 commission_value: 0,
                                                                                 variant_cost: 0
                                                                             }
                                                                         ],
                                                                         pos_item_discounts: [],
                                                                         pos_item_package_details: [],
                                                                         pos_item_pos_item_modifiers: []
                                                                     };
                                                                     const itemData = {
                                                                         is_package: 0,
                                                                         pos_item_variant_id: 1,
                                                                         package_pos_item_id: 0,
                                                                         qty: 1,
                                                                         custom_discount_type:
                                                                             "",
                                                                         custom_discount_value: 0,
                                                                         notes: "",
                                                                         discounts: [],
                                                                         pos_sales_type:
                                                                             "",
                                                                         commission_worker: null,
                                                                         business_commission_worker_id: null,
                                                                         pos_item_package_details: null,
                                                                         pos_item_pos_item_modifiers: null,
                                                                         item_sellers: []
                                                                     };

                                                                     if (
                                                                         empty(
                                                                             currentTrx.details
                                                                         )
                                                                     ) {
                                                                         this.props.addTrxDetail(
                                                                             currentLocalTrxId,
                                                                             [],
                                                                             itemMeta,
                                                                             itemData
                                                                         );
                                                                     } else {
                                                                         this.props.editTrxDetail(
                                                                             currentLocalTrxId,
                                                                             currentTrx
                                                                                 .details[0]
                                                                                 .local_trx_detail_id,
                                                                             {
                                                                                 ...currentTrx
                                                                                     .details[0],
                                                                                 itemMeta: {
                                                                                     ...itemMeta
                                                                                 }
                                                                             }
                                                                         );
                                                                     }

                                                                     this.props.setTrxProps(currentLocalTrxId, {
                                                                         membership_item: row
                                                                     });
                                                                 }}
                                                            >
                                                                <img src={row.membership_image} alt={row.membership_name}/>
                                                                <div className={styles.membershipName}>{row.membership_name}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                {posConfig.is_pos_commission_seller === 1
                                                && posConfig.is_pos_commission_seller_membership_sales === 1
                                                && !empty(currentTrx.details) && (
                                                    <React.Fragment>
                                                        <h4 style={{ marginTop: 15 }}>
                                                            Seller
                                                        </h4>

                                                        {posConfig.is_pos_commission_multi_seller === 0 && (
                                                            <Select
                                                                style={{ width: "100%" }}
                                                                placeholder="Pilih seller"
                                                                defaultValue={(!empty(currentTrx.details[0].item_sellers) && currentTrx.details[0].item_sellers.length === 1) ? currentTrx.details[0].item_sellers[0].business_commission_worker_id : null}
                                                                onChange={(e) => {
                                                                    const chosenSellers = posConfig.commission_worker.filter(seller => {
                                                                        return seller.business_commission_worker_id === e;
                                                                    });
                                                                    this.props.editTrxDetail(
                                                                        currentLocalTrxId,
                                                                        currentTrx
                                                                            .details[0]
                                                                            .local_trx_detail_id,
                                                                        {
                                                                            ...currentTrx
                                                                                .details[0],
                                                                            item_sellers: chosenSellers
                                                                        }
                                                                    );
                                                                }}
                                                                optionLabelProp="label"
                                                            >
                                                                {posConfig.commission_worker.map(row => (
                                                                    <Select.Option key={row.business_commission_worker_id}
                                                                                   value={row.business_commission_worker_id}
                                                                                   label={`${row.firstname} ${row.lastname}`}>
                                                                        {`${row.firstname} ${row.lastname}`}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}

                                                        {posConfig.is_pos_commission_multi_seller === 1 && (
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: "100%" }}
                                                                placeholder="Pilih seller"
                                                                defaultValue={currentTrx.details[0].item_sellers.map(row => row.business_commission_worker_id)}
                                                                onChange={(e) => {
                                                                    const chosenSellers = posConfig.commission_worker.filter(seller => {
                                                                        return e.find(row => seller.business_commission_worker_id === row);
                                                                    });
                                                                    this.props.editTrxDetail(
                                                                        currentLocalTrxId,
                                                                        currentTrx
                                                                            .details[0]
                                                                            .local_trx_detail_id,
                                                                        {
                                                                            ...currentTrx
                                                                                .details[0],
                                                                            item_sellers: chosenSellers
                                                                        }
                                                                    );
                                                                }}
                                                                optionLabelProp="label"
                                                            >
                                                                {posConfig.commission_worker.map(row => (
                                                                    <Select.Option key={row.business_commission_worker_id}
                                                                                   value={row.business_commission_worker_id}
                                                                                   label={`${row.firstname} ${row.lastname}`}>
                                                                        {`${row.firstname} ${row.lastname}`}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}


                            </Col>
                            <Col span={8}>
                                <div className={styles.rightPanel}>
                                    <div className={styles.trxLabel}>
                                        <Input
                                            value={
                                                this.props.currentTrx.trx_label
                                            }
                                            onChange={this.onChangeTrxLabel}
                                            placeholder="Label Transaksi"
                                        />
                                    </div>

                                    {this.props.posConfig.is_document_date_editable === 1 && (
                                        <div className={styles.trxLabel}>
                                            <DatePicker showTime
                                                        placeholder="Pilih Tanggal"
                                                        style={{ width: "100%" }}
                                                        defaultValue={empty(this.props.currentTrx.document_date) ? moment() : moment(this.props.currentTrx.document_date, MOMENT_SQL_DATETIME_FORMAT)}
                                                        format="YYYY-MM-DD HH:mm"
                                                        onChange={(date, dateString) => {
                                                            this.props.setTrxProps(this.props.currentLocalTrxId, {
                                                                document_date: moment(date).format(MOMENT_SQL_DATETIME_FORMAT)
                                                            });
                                                        }}/>
                                        </div>
                                    )}

                                    <PosCustomerPanel
                                        modalOpened={posConfig.is_user_id_required === 1 && !currentTrx.customerModalOpened && empty(currentTrx.customer)}
                                        disableFutureAutoOpenModal={() => {
                                            this.props.setTrxProps(currentLocalTrxId, {
                                                customerModalOpened: true
                                            });
                                        }}
                                    />
                                    <Divider style={{ margin: 0 }}/>

                                    {
                                        currentTrx.trx_type === TRX_TYPE_NORMAL &&
                                        posConfig.is_service_area_scheme === 1 &&
                                        posConfig.service_area_type === "transaction" && (
                                            <React.Fragment>
                                                <PosTrxAreaPanel/>
                                                <Divider style={{ margin: 0 }}/>
                                            </React.Fragment>
                                        )}

                                    <PosPriceList
                                        trx={this.props.currentTrx}
                                        posConfig={posConfig}
                                        itemEditable={priceListItemEditable}
                                        showSecretNotes={true}
                                    />
                                    <div className={styles.actionArea}>
                                        {!empty(
                                            this.props.currentTrx.redeem
                                        ) && (
                                            <div
                                                className={
                                                    styles.redeemItemBanner
                                                }
                                            >
                                                <Icon type="gift"/>{" "}
                                                {
                                                    this.props.currentTrx.redeem
                                                        .redeem_item.item_name
                                                }{" "}
                                                <button
                                                    onClick={this.removeRedeem}
                                                    className={
                                                        styles.deleteRedeemItemBtn
                                                    }
                                                >
                                                    hapus
                                                </button>
                                            </div>
                                        )}

                                        {!empty(
                                            this.props.currentTrx.coupon
                                        ) && (
                                            <div
                                                className={
                                                    styles.redeemItemBanner
                                                }
                                            >
                                                <Icon type="idcard"/>{" "}
                                                {
                                                    this.props.currentTrx.coupon
                                                        .coupon_item.coupon_name
                                                }{" "}
                                                <button
                                                    onClick={this.removeCoupon}
                                                    className={
                                                        styles.deleteRedeemItemBtn
                                                    }
                                                >
                                                    hapus
                                                </button>
                                            </div>
                                        )}

                                        {/*<div className={styles.action}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: RED
                                                }}
                                                onClick={this.resetCurrentTrx}
                                                size={56}
                                                icon="delete"
                                            />
                                            <span>Reset</span>
                                        </div>*/}

                                        {stockOutModalOpened && !empty(currentLocalTrxId) && (
                                            <TrxStockOutModal localTrxId={currentLocalTrxId}
                                                              opened={stockOutModalOpened}
                                                              onClose={() => {
                                                                  this.setState({
                                                                      stockOutModalOpened: false,
                                                                      stockOutLocalTrxId: null
                                                                  });
                                                              }}
                                            />
                                        )}

                                        {posConfig.auth_role_accesses.POS_SHOW_STOCK_OUT === 1 && (
                                            <>
                                            <div
                                                className={styles.action}
                                                onClick={() => {
                                                    this.setState({
                                                        stockOutModalOpened: true
                                                    });
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        backgroundColor: PRIMARY
                                                    }}
                                                    className={styles.actionIcon}
                                                    icon="gold"
                                                />
                                                <span>Stok Keluar</span>
                                            </div>
                                            </>
                                        )}

                                        <div
                                            className={styles.action}
                                            onClick={() => {
                                                this.setState({
                                                    trxNotesModalOpened: true
                                                });
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    backgroundColor: PRIMARY
                                                }}
                                                className={styles.actionIcon}
                                                icon="form"
                                            />
                                            <span>Notes</span>
                                        </div>

                                        {this.props.currentTrx.trx_status_id === 1 && (
                                            <>
                                                <div
                                                    className={styles.action}
                                                    onClick={this.onCancelAppointment}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: RED
                                                        }}
                                                        className={styles.actionIcon}
                                                        icon="stop"
                                                    />
                                                    <span>Batal</span>
                                                </div>

                                                <div
                                                    className={styles.action}
                                                    onClick={this.onSaveAppointment}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: GREEN
                                                        }}
                                                        className={styles.actionIcon}
                                                        icon="check"
                                                    />
                                                    <span>Simpan</span>
                                                </div>

                                                <div
                                                    className={styles.action}
                                                    onClick={this.convertAppointment}
                                                    style={{
                                                        float: "right",
                                                        marginRight: 20
                                                    }}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: GREEN
                                                        }}
                                                        className={styles.actionIcon}
                                                        icon="swap"
                                                    />
                                                    <span>Checkout</span>
                                                </div>
                                            </>
                                        )}

                                        {this.props.currentTrx.trx_status_id > 1 && (
                                            <>
                                                {
                                                    posConfig.auth_role_accesses.DISCOUNT_TRANSACTION === 1 &&
                                                    posConfig.pos_discount_on_transaction === 1 && (
                                                    <div
                                                        className={styles.action}
                                                        onClick={() => {
                                                            this.setState({
                                                                discountModalOpened: true
                                                            });
                                                        }}
                                                    >
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: PRIMARY
                                                            }}
                                                            className={styles.actionIcon}
                                                            icon="tags"
                                                        />
                                                        <span>Discount</span>
                                                    </div>
                                                )}

                                                {currentTrx.trx_type === TRX_TYPE_NORMAL && (
                                                    <div
                                                        className={styles.action}
                                                        onClick={this.openRedeemModal}
                                                    >
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: PRIMARY
                                                            }}
                                                            className={styles.actionIcon}
                                                            icon="gift"
                                                        />
                                                        <span>Redeem</span>
                                                    </div>
                                                )}

                                                {currentTrx.trx_type === TRX_TYPE_NORMAL &&
                                                posConfig.subscription_rules &&
                                                posConfig.subscription_rules
                                                    .coupon && (
                                                    <div
                                                        className={styles.action}
                                                        onClick={
                                                            this.openCouponModal
                                                        }
                                                    >
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: PRIMARY
                                                            }}
                                                            className={styles.actionIcon}
                                                            icon="idcard"
                                                        />
                                                        <span>Kupon</span>
                                                    </div>
                                                )}

                                                <div
                                                    className={styles.action}
                                                    onClick={this.continueToPayment}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: GREEN
                                                        }}
                                                        className={styles.actionIcon}
                                                        icon="check"
                                                    />
                                                    <span>Continue</span>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </div>

                                <PosDiscountModal
                                    modalOpened={this.state.discountModalOpened}
                                    close={() => {
                                        this.setState({
                                            discountModalOpened: false
                                        });
                                    }}
                                />
                                <PosTrxNotesModal
                                    modalOpened={this.state.trxNotesModalOpened}
                                    close={() => {
                                        this.setState({
                                            trxNotesModalOpened: false
                                        });
                                    }}
                                />

                                {this.state.redeemModalOpened && (
                                    <PosRedeemModal
                                        modalOpened={this.state.redeemModalOpened}
                                        close={this.closeRedeemModal}
                                    />
                                )}
                                {this.state.redeemItemModalOpened &&
                                !empty(this.state.redeemItemModalItem) && (
                                    <PosItemModal
                                        modalOpened={
                                            this.state.redeemItemModalOpened
                                        }
                                        item={
                                            this.state.redeemItemModalItem
                                        }
                                        redeem={this.state.redeemItem}
                                        trx_detail={null}
                                        closable={false}
                                        onCancel={() => {
                                            const that = this;
                                            Modal.confirm({
                                                title: "Apakah Anda ingin membatalkan proses redeem?",
                                                content: "Proses redeem akan dibatalkan",
                                                onOk: () => {
                                                    this.setState({
                                                        redeemModalOpened: false,
                                                        redeemItemModalOpened: false,
                                                        redeemItemModalItem: null,
                                                        redeemItem: null
                                                    }, () => {
                                                        that.removeRedeem();
                                                    });
                                                },
                                                onCancel: () => {
                                                }
                                            });
                                        }}
                                        close={() => {
                                            this.setState({
                                                redeemModalOpened: false,
                                                redeemItemModalOpened: false,
                                                redeemItemModalItem: null
                                            });
                                        }}
                                    />
                                )}

                                {this.state.couponModalOpened && (
                                    <PosCouponModal
                                        modalOpened={
                                            this.state.couponModalOpened
                                        }
                                        close={this.closeCouponModal}
                                    />
                                )}

                                {this.state.couponItemModalOpened &&
                                !empty(this.state.couponItemModalItem) && (
                                    <PosItemModal
                                        modalOpened={
                                            this.state.couponItemModalOpened
                                        }
                                        item={
                                            this.state.couponItemModalItem
                                        }
                                        coupon={this.state.couponItem}
                                        trx_detail={null}
                                        closable={false}
                                        onCancel={() => {
                                            const that = this;
                                            Modal.confirm({
                                                title: "Apakah Anda ingin membatalkan pemakaian kupon?",
                                                content: "Pemakaian kupon akan dibatalkan",
                                                onOk: () => {
                                                    this.setState({
                                                        couponModalOpened: false,
                                                        couponItemModalOpened: false,
                                                        couponItemModalItem: null,
                                                        couponItem: null
                                                    }, () => {
                                                        that.removeCoupon();
                                                    });
                                                },
                                                onCancel: () => {
                                                }
                                            });
                                        }}
                                        close={() => {
                                            this.setState({
                                                couponModalOpened: false,
                                                couponItemModalOpened: false,
                                                couponItemModalItem: null
                                            });
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {!empty(this.props.currentLocalTrxId) && step === 3 && (
                    <React.Fragment>
                        <Row>
                            <Col span={24}>
                                <PosHeader
                                    pageTitle={pageTitle}
                                    backBtnAction={() => {
                                        this.goToStep(2);
                                    }}
                                    menus={
                                        <React.Fragment>
                                            <div style={{
                                                cursor: "pointer",
                                                display: "inline-block"
                                            }}
                                                 onClick={this.archiveCurrentTrx}>
                                                <Icon
                                                    style={{
                                                        fontSize: "1.25em"
                                                    }}
                                                    type="file-protect"
                                                    theme="outlined"
                                                    title="Simpan Transaksi"
                                                /> Simpan
                                            </div>
                                            <div style={{
                                                cursor: "pointer",
                                                marginLeft: 20,
                                                display: "inline-block"
                                            }}
                                                 onClick={this.deleteCurrentTrx}
                                            >
                                                <Icon
                                                    style={{
                                                        fontSize: "1.25em"
                                                    }}
                                                    type="delete"
                                                    theme="outlined"
                                                    title="Hapus Transaksi"
                                                /> Hapus
                                            </div>
                                        </React.Fragment>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <div className={styles.leftPanel}>
                                    <PosCustomerPanel/>
                                    <Divider style={{ margin: 0 }}/>
                                    <PosPriceList
                                        trx={this.props.currentTrx}
                                        posConfig={posConfig}
                                        itemEditable={priceListItemEditable}
                                        showSecretNotes={true}
                                    />
                                    <Divider style={{ margin: 0 }}/>
                                    <div style={{ padding: 15 }}>
                                        Notes:
                                        <br/>
                                        {empty(
                                            this.props.currentTrx.trx_notes
                                        )
                                            ? "-"
                                            : this.props.currentTrx
                                                .trx_notes}
                                    </div>
                                </div>
                            </Col>
                            <Col span={16}>
                                <div className={styles.paymentWrapper}>
                                    <PosPayments/>

                                    <div className={styles.actionAreaWide}>
                                        <div className={styles.action}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: RED
                                                }}
                                                onClick={() => {
                                                    this.goToStep(2);
                                                }}
                                                size={56}
                                                icon="left"
                                            />
                                            <span>Kembali</span>
                                        </div>
                                        <div className={styles.action} style={{ textAlign: "center" }}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: PRIMARY,
                                                    margin: "auto"
                                                }}
                                                onClick={() => {
                                                    // if (this.props.cashierConfig.displayMode === "DESKTOP") {
                                                    //     window.open("pos/receipt/" + this.props.currentLocalTrxId + "/order-preview", "_blank", "rel=noopener noreferrer");
                                                    // } else {
                                                        this.setState({
                                                            step: 6
                                                        });
                                                    // }
                                                }}
                                                size={56}
                                                icon="printer"
                                            />
                                            <span>Order List</span>
                                        </div>

                                        <div className={styles.action} style={{ textAlign: "center" }}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: PRIMARY,
                                                    margin: "auto"
                                                }}
                                                onClick={() => {
                                                    // if (this.props.cashierConfig.displayMode === "DESKTOP") {
                                                    //     window.open("pos/receipt/" + this.props.currentLocalTrxId + "/order-preview", "_blank", "rel=noopener noreferrer");
                                                    // } else {
                                                        this.setState({
                                                            step: 7
                                                        });
                                                    // }
                                                }}
                                                size={56}
                                                icon="printer"
                                            />
                                            <span>Unprinted Order</span>
                                        </div>

                                        {posConfig.auth_role_accesses.PRE_SETTLEMENT === 1 && (
                                        <div className={styles.action} style={{ textAlign: "center" }}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: PRIMARY,
                                                    margin: "auto"
                                                }}
                                                onClick={() => {
                                                    // if (this.props.cashierConfig.displayMode === "DESKTOP") {
                                                    //     window.open("pos/receipt/" + this.props.currentLocalTrxId + "/pre-settlement", "_blank", "rel=noopener noreferrer");
                                                    // } else {
                                                        this.setState({
                                                            step: 5
                                                        });
                                                    // }
                                                }}
                                                size={56}
                                                icon="printer"
                                            />
                                            <span>Pre-Settlement</span>
                                        </div>
                                        )}
                                        <div
                                            className={styles.action}
                                            onClick={() => {
                                                if(
                                                    (posConfig.coupon_use_nfc === 1 && !empty(currentTrx.coupon)) ||
                                                    (posConfig.wallet_use_nfc === 1 && currentTrx.payments.some(row => row.payment_type === PAYMENT_TYPE_DEPOSIT))
                                                ){
                                                    this.setState({
                                                        scanNfcOpened: true
                                                    });
                                                }else{
                                                    this.onSubmitTransaction();
                                                }
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    backgroundColor: GREEN
                                                }}
                                                size={56}
                                                icon="check"
                                            />
                                            <span>Selesai</span>
                                        </div>

                                        <CaptureNfc opened={this.state.scanNfcOpened}
                                                    onClose={() => {
                                                        this.setState({
                                                            scanNfcOpened: false
                                                        });
                                                    }}
                                                    onScan={(nfc_code) => {
                                                        this.setState({
                                                            nfc_code,
                                                            scanNfcOpened: false
                                                        }, () => {
                                                            this.onSubmitTransaction();
                                                        });
                                                    }} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {!empty(this.props.currentLocalTrxId) && step === 4 && (
                    <React.Fragment>
                        {/*<Spin spinning={formLoading}>
                                <Row className={styles["no-print"]}>
                                    <Col span={24}>
                                        {this.renderHeader(() => {
                                            this.goToStep(1);
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8} offset={8}>
                                        <div className={styles.receiptPanel}>
                                            <PosCustomerPanel />
                                            <Divider style={{ margin: 0 }} />
                                            <PosPriceList />
                                            <Divider style={{ margin: 0 }} />
                                            <div style={{ padding: 15 }}>
                                                Notes:
                                                <br />
                                                {empty(
                                                    this.props.currentTrx
                                                        .trx_notes
                                                )
                                                    ? '-'
                                                    : this.props.currentTrx
                                                        .trx_notes}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Spin>*/}
                        {/*<div className={styles.receiptPanel}>
                                <PosCustomerPanel/>
                                <Divider style={{ margin: 0 }}/>
                                <PosPriceList isPrint={true}/>
                                <Divider style={{ margin: 0 }}/>
                                <div style={{ padding: 15 }}>
                                    Notes:
                                    <br/>
                                    {empty(
                                        this.props.currentTrx
                                            .trx_notes
                                    )
                                        ? "-"
                                        : this.props.currentTrx
                                            .trx_notes}
                                </div>
                            </div>*/}
                        <Row>
                            <Col span={24}>
                                <div className={styles.finalStepWrapper}>
                                    {unpaidRemainder < 0 && (
                                        <React.Fragment>
                                            <div className={styles.changeLabel}>
                                                Uang Kembalian
                                            </div>
                                            <div className={styles.changeValue}>
                                                {this.currencyCode}{" "}
                                                {number_format(
                                                    -1 * unpaidRemainder
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {this.props.currentTrx.payment_method
                                        .is_payment_amount_required !== 1 && (
                                        <React.Fragment>
                                            <div className={styles.changeValue}>
                                                <Icon
                                                    type="check-circle"
                                                    style={{
                                                        fontSize: 100,
                                                        color: GREEN,
                                                        marginBottom: 30
                                                    }}
                                                />
                                                <br/>
                                                Transaksi berhasil dilakukan!
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {/*{this.props.cashierConfig.displayMode === "DESKTOP" && (
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            icon="printer"
                                            size="large"
                                            style={{ margin: 10 }}
                                            onClick={() => {
                                                window.open("pos/receipt/" + this.props.currentTrx.local_trx_id + "/print", "_blank", "rel=noopener noreferrer");
                                            }}
                                        >
                                            Cetak Struk
                                        </Button>
                                    )}*/}

                                    <Button
                                        type="primary"
                                        htmlType="button"
                                        icon="solution"
                                        size="large"
                                        style={{ margin: 10 }}
                                        onClick={() => {
                                            // if(this.props.cashierConfig.displayMode === "DESKTOP"){
                                            //     window.open("pos/receipt/" + this.props.currentTrx.local_trx_id + "/view", "_blank", "rel=noopener noreferrer");
                                            // }else{
                                                window.location.href = "pos/receipt/" + this.props.currentTrx.local_trx_id + "/view";
                                            // }
                                        }}
                                    >
                                        Lihat Struk
                                    </Button>

                                    {posConfig.pos_print_digital_invoice === 1 && (
                                        <React.Fragment>
                                            <br/>

                                            {posConfig.is_sms_receipt === 1 && (
                                                <React.Fragment>
                                                    <Input.Search
                                                        placeholder="No. HP"
                                                        enterButton="SMS"
                                                        style={{ margin: 10 }}
                                                        defaultValue={receiptPhone}
                                                        onSearch={val => {
                                                            this.onSendSmsReceipt(currentLocalTrxId, val);
                                                        }}
                                                    />

                                                    <br/>
                                                </React.Fragment>
                                            )}

                                            {cashierConfig.displayMode === "DESKTOP" && (
                                                <>
                                                    <Input.Search
                                                        placeholder="No. HP"
                                                        enterButton="WA Web"
                                                        style={{ margin: 10 }}
                                                        defaultValue={receiptPhone}
                                                        onSearch={val => {
                                                            this.onSendWhatsAppReceipt(currentLocalTrxId, val);
                                                        }}
                                                    />

                                                    <br/>
                                                </>
                                            )}

                                            <Input.Search
                                                placeholder="No. HP"
                                                enterButton="WA App"
                                                style={{ margin: 10 }}
                                                defaultValue={receiptPhone}
                                                onSearch={val => {
                                                    this.onSendWhatsAppDesktopReceipt(currentLocalTrxId, val);
                                                }}
                                            />

                                            <br/>

                                            <Input.Search
                                                placeholder="Email Address"
                                                enterButton="e-Mail"
                                                style={{ margin: 10 }}
                                                defaultValue={receiptMail}
                                                onSearch={val => {
                                                    this.onSendEmailReceipt(currentLocalTrxId, val);
                                                }}
                                            />

                                            <br/>
                                        </React.Fragment>
                                    )}

                                    <Button
                                        type="danger"
                                        htmlType="button"
                                        icon="profile"
                                        size="large"
                                        style={{ margin: 10 }}
                                        onClick={() => {
                                            this.goToStep(1);
                                        }}
                                    >
                                        Kembali ke Daftar Transaksi
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {!empty(this.props.currentLocalTrxId) && step === 5 && (
                    <PosReceipt local_trx_id={this.props.currentLocalTrxId}
                                modifier="pre-settlement"
                                onCloseMobile={() => {
                                    this.setState({ step: 3 });
                                }}
                    />
                )}

                {!empty(this.props.currentLocalTrxId) && step === 6 && (
                    <PosReceipt local_trx_id={this.props.currentLocalTrxId}
                                modifier="order-preview"
                                onCloseMobile={() => {
                                    this.setState({ step: 3 });
                                }}
                                onPrint={() => {
                                    this.props.markTrxDetailsAsPrinted(this.props.currentLocalTrxId);
                                }}
                                showWorker={true}
                                showSeller={true}
                    />
                )}

                {!empty(this.props.currentLocalTrxId) && step === 7 && (
                    <PosReceipt local_trx_id={this.props.currentLocalTrxId}
                                modifier="order-preview-unprinted"
                                onCloseMobile={() => {
                                    this.setState({ step: 3 });
                                }}
                                onPrint={() => {
                                    this.props.markTrxDetailsAsPrinted(this.props.currentLocalTrxId);
                                }}
                                showWorker={true}
                                showSeller={true}
                    />
                )}

                {this.state.deleteTrxModalOpened && !empty(this.state.deleteTrxLocalTrxId) && (
                    <DeleteTrxPasswordModal
                        localTrxId={this.state.deleteTrxLocalTrxId}
                        trx={this.state.deleteTrx}
                        opened={this.state.deleteTrxModalOpened}
                        onClose={() => {
                            this.setState({
                                deleteTrxModalOpened: false,
                                deleteTrxLocalTrxId: null
                            });
                        }}
                        deleteAction={trx => {
                            this.deleteTrxAction(trx);
                        }}
                    />
                )}
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    let currentTrx = {};
    if (!empty(currentLocalTrxId)) {
        currentTrx = state.posState.posTrx.find(
            trx => trx.local_trx_id === currentLocalTrxId
        );
    }

    return {
        currentLocalTrxId,
        currentTrx,
        posTrx: state.posState.posTrx,
        appointments: state.posState.appointments,
        posConfig: state.authState.userData,
        cashierConfig: state.authState.configs,
        activeShift: state.authState.shiftManagement.active
    };
};

const mapDispatchToProps = {
    setPosConfig: posOperations.setPosConfig,
    switchCurrentTrx: posOperations.switchCurrentTrx,
    setNewLocalTrx: posOperations.setNewLocalTrx,
    setTrxProps: posOperations.setTrxProps,
    resetTrx: posOperations.resetTrx,
    deleteTrx: posOperations.deleteTrx,
    addTrxDetail: posOperations.addTrxDetail,
    editTrxDetail: posOperations.editTrxDetail,
    markTrxDetailsAsPrinted: posOperations.markTrxDetailsAsPrinted,
    setAppointmentProps: posOperations.setAppointmentProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosSales);
