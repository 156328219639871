import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authOperations } from '../state/ducks/auth';
import { apiCall } from '../shared/helpers/networkHelper';
import * as endpoint from '../shared/utils/endpoints';
import { Card, Col, Collapse, Icon, message, Row } from 'antd';

class Help extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    componentDidMount() {
        if (!('business_user_faqs' in this.props.userData)) {
            apiCall(
                endpoint.GET_CASHIER_HELP,
                'get',
                null,
                response => {
                    this.props.setUserData({
                        ...this.props.userData,
                        business_user_faqs: response.data
                    });
                    message.success('Help content loaded!');
                },
                response => {
                    message.error(response.data.error.message);
                }
            );
        }
    }

    renderHelpContent = helps => {
        return helps.map((help, index) => {
            return (
                <Collapse.Panel header={help.question} key={index}>
                    <div dangerouslySetInnerHTML={{ __html: help.answer }} />
                </Collapse.Panel>
            );
        });
    };

    render() {
        const pageTitle = (
            <React.Fragment>
                <Icon type="question" />
                &nbsp;&nbsp; Bantuan Penggunaan
            </React.Fragment>
        );

        return (
            <Card
                title={pageTitle}
                bordered={false}
                loading={!('business_user_faqs' in this.props.userData)}
            >
                <Row gutter={48}>
                    <Col xs={24}>
                        <Collapse bordered={false}>
                            {this.renderHelpContent(
                                this.props.userData.business_user_faqs
                            )}
                        </Collapse>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    setUserData: authOperations.setUserData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Help);
