import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    message,
    Modal,
    Radio,
    Input,
    Spin,
    Form,
    Button,
    Card,
    Avatar,
    List
} from 'antd';
import { posOperations } from '../../state/ducks/pos';
import * as endpoint from '../../shared/utils/endpoints';
import { apiCall, promiseTimeout } from '../../shared/helpers/networkHelper';
import { empty } from '../../shared/helpers/generalHelper';
import QrReader from 'react-qr-reader';
import styles from './PosRedeemModal.module.scss';
import ErrorHandler from "../../shared/classes/ErrorHandler";
import moment from "moment";
import { MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";

class PosRedeemModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            redeemType: 'qr',
            redeem_code: '',
            qr_redeem_data: null,
            redeem_item: null,
            formLoading: false,
            agentTrxs: [],
            agent_trx: null
        };

        this.state = { ...this.initialState };
    }

    showModalLoading = () => {
        this.setState({ formLoading: true });
    };

    hideModalLoading = () => {
        this.setState({ formLoading: false });
    };

    handleModalOk = () => {
        let validForm = true;

        if (empty(this.state.redeem_item)) {
            validForm = false;
            message.error('Redeem tidak valid.');
        }

        if (validForm) {
            this.props.setTrxProps(this.props.currentLocalTrxId, {
                redeem: {
                    redeem_type: this.state.redeemType,
                    redeem_code: this.state.redeem_code,
                    redeem_item: this.state.redeem_item,
                    qr_redeem_data: this.state.qr_redeem_data
                }
            });

            message.success('Redeem berhasil diaplikasikan');
            if (this.state.redeem_item.pos_link_type === 'item') {
                this.props.close(this.state.redeem_item);
            } else {
                this.props.close();
            }

            this.setState({ ...this.initialState });
        }
    };

    handleModalCancel = () => {
        this.props.close();
    };

    onChangeRedeemType = e => {
        this.setState({
            ...this.initialState,
            redeemType: e.target.value
        }, ()=>{
            if(e.target.value === 'agent'){
                this.loadAgentTrxs();
            }
        });
    };

    handleRedeemCodeQrScan = data => {
        if (data) {
            this.setState({ redeem_code: data }, () => {
                this.onSubmitQrCode(0);
            });
        }
    };

    handleRedeemCodeQrError = err => {
        console.error(err);
    };

    onSubmitQrCode = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.redeem_code)) {
            message.error('Kode redeem harus diisi!');
            return;
        }

        this.showModalLoading();

        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.GET_REDEEM_REWARD_PREVIEW +
                        `?scancode=${this.state.redeem_code}`,
                    'get',
                    null,
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState(
                    {
                        step: 2,
                        redeem_item:
                            result.data.details[0].reward_redemption_item,
                        qr_redeem_data: result.data
                    },
                    () => {
                        this.hideModalLoading();
                    }
                );
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.hideModalLoading();
            });
    };

    onRedeemItemChosen = redeem_item => {
        if (empty(this.props.currentTrx.customer.user_identifier_string)) {
            message.error('Nomor HP harus diisi!');
            return;
        }

        this.showModalLoading();

        apiCall(
            endpoint.POST_SEND_SMS_REDEEM,
            'post',
            {
                user_identifier_string: this.props.currentTrx.customer
                    .user_identifier_string,
                reward_redemption_item_id:
                redeem_item.reward_redemption_item_id
            },
            result => {
                this.setState({
                    step: 2,
                    formLoading: false,
                    redeem_item: {
                        reward_redeem_sms_id: result.data.reward_redeem_sms_id,
                        ...redeem_item
                    }
                });
            },
            error => {
                ErrorHandler.handleGeneralError(error)
                this.hideModalLoading();
            }
        );
    };

    onRedeemItemChosenDirect = redeem_item => {
        if (empty(this.props.currentTrx.customer.user_identifier_string)) {
            message.error('Nomor HP harus diisi!');
            return;
        }

        this.setState({
            step: 2,
            redeem_item: {
                ...redeem_item
            }
        });
    };

    loadAgentTrxs = () => {
        apiCall(
            endpoint.GET_AGENT_REWARD_REDEEM_TRXS + `?user_code=${this.props.currentTrx.customer.user_code}`,
            'get',
            null,
            response => {
                this.setState({
                    agentTrxs: response.data
                });
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    onAgentTrxChosen = agent_trx => {
        this.setState(
            {
                step: 2,
                redeem_item: {
                    ...agent_trx.details[0].reward_redemption_item,
                    trx_id: agent_trx.details[0].trx_id
                },
                qr_redeem_data: agent_trx
            },
            this.handleModalOk
        );
    };

    render() {
        const {
            step,
            redeemType,
            redeem_code,
            redeem_item,
            qr_redeem_data,
            formLoading,
            agentTrxs
        } = this.state;
        const {posConfig} = this.props;
        const RadioGroup = Radio.Group;
        const RadioButton = Radio.Button;

        return (
            <Modal
                title="Redeem"
                visible={this.props.modalOpened}
                onOk={this.handleModalOk}
                destroyOnClose={true}
                onCancel={this.handleModalCancel}
                cancelText="Cancel"
                okText="Apply"
            >
                <Spin spinning={formLoading}>
                    <div className={styles.formGroup}>
                        <h4 style={{ marginTop: 0 }}>Redeem Type</h4>
                        <RadioGroup
                            defaultValue="qr"
                            buttonStyle="solid"
                            onChange={this.onChangeRedeemType}
                            disabled={!empty(qr_redeem_data)}
                        >
                            <RadioButton value="qr">QR Code</RadioButton>

                            {posConfig.subscription_rules.sms_redeem && (
                                <RadioButton value="sms">SMS</RadioButton>
                            )}

                            {posConfig.direct_reward_redeem === 1 && (
                                <RadioButton value="direct">Direct</RadioButton>
                            )}

                            <RadioButton value="agent">Agent</RadioButton>
                        </RadioGroup>
                    </div>


                    {redeemType === 'qr' && step === 1 && (
                        <React.Fragment>
                            <Form onSubmit={this.onSubmitQrCode}>
                                <div className={styles.formGroup}>
                                    <h4>Scan QR Code</h4>
                                    <QrReader
                                        delay={3000}
                                        onScan={this.handleRedeemCodeQrScan}
                                        onError={this.handleRedeemCodeQrError}
                                        style={{
                                            width: 300,
                                            maxWidth: '100%',
                                            marginBottom: 10,
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    />

                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                redeem_code: e.target.value
                                            });
                                        }}
                                        placeholder="Redeem Code"
                                        value={redeem_code}
                                        disabled={redeem_item !== null}
                                        size="large"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        block={true}
                                        htmlType="submit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </React.Fragment>
                    )}

                    {redeemType === 'qr' && step === 2 && (
                        <React.Fragment>
                            <table className={styles.previewTable}>
                                <tbody>
                                    <tr>
                                        <td>Customer</td>
                                        <td>{qr_redeem_data.user_name}</td>
                                    </tr>
                                    <tr>
                                        <td>ID</td>
                                        <td>{qr_redeem_data.user_code}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className={styles.previewItem}>
                                                {qr_redeem_data.details.map(
                                                    (detail, index) => {
                                                        return (
                                                            <React.Fragment
                                                                key={index}
                                                            >
                                                                <img
                                                                    src={
                                                                        detail.image_url
                                                                    }
                                                                    alt="Redeemed Item"
                                                                />
                                                                <br />
                                                                {
                                                                    detail.qty
                                                                }x{' '}
                                                                {
                                                                    detail.item_name
                                                                }
                                                                <br />
                                                                {
                                                                    detail.drv_total_point
                                                                }{' '}
                                                                {
                                                                    detail.reward_name
                                                                }
                                                            </React.Fragment>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}

                    {redeemType === 'sms' && step === 1 && (
                        <React.Fragment>
                            <h2>Pilih Barang</h2>
                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 3,
                                    lg: 3,
                                    xl: 3,
                                    xxl: 3
                                }}
                                dataSource={
                                    this.props.posConfig.reward_redemption_items
                                }
                                renderItem={item => (
                                    <List.Item
                                        key={item.reward_redemption_item_id}
                                    >
                                        <Card
                                            onClick={() => {
                                                this.onRedeemItemChosen(item);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div
                                                className={styles.redeemItemDiv}
                                            >
                                                <h3>{item.item_name}</h3>
                                                <Avatar
                                                    size={80}
                                                    shape="square"
                                                    src={item.image_url}
                                                />
                                                <p>{`${item.point_amount} ${
                                                    item.reward_name
                                                }`}</p>
                                            </div>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </React.Fragment>
                    )}

                    {redeemType === 'sms' && step === 2 && (
                        <React.Fragment>
                            <h2>
                                SMS telah berhasil dikirimkan, silakan lanjutkan
                                transaksi.
                            </h2>
                        </React.Fragment>
                    )}

                    {redeemType === 'direct' && step === 1 && (
                        <React.Fragment>
                            <h2>Pilih Barang</h2>
                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 3,
                                    lg: 3,
                                    xl: 3,
                                    xxl: 3
                                }}
                                dataSource={
                                    this.props.posConfig.reward_redemption_items
                                }
                                renderItem={item => (
                                    <List.Item
                                        key={item.reward_redemption_item_id}
                                    >
                                        <Card
                                            onClick={() => {
                                                this.onRedeemItemChosenDirect(item);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div
                                                className={styles.redeemItemDiv}
                                            >
                                                <h3>{item.item_name}</h3>
                                                <Avatar
                                                    size={80}
                                                    shape="square"
                                                    src={item.image_url}
                                                />
                                                <p>{`${item.point_amount} ${
                                                    item.reward_name
                                                }`}</p>
                                            </div>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </React.Fragment>
                    )}

                    {redeemType === 'direct' && step === 2 && (
                        <React.Fragment>
                            <h2>
                                Hadiah telah terpilih untuk diredeem, silakan lanjutkan transaksi.
                            </h2>
                        </React.Fragment>
                    )}

                    {redeemType === 'agent' && step === 1 && (
                        <React.Fragment>
                            <h2>Pilih Transaksi</h2>
                            <List
                                grid={{
                                    gutter: 16,
                                    xxl: 1
                                }}
                                dataSource={agentTrxs}
                                renderItem={item => (
                                    <List.Item
                                        key={item.details[0].trx_id}
                                    >
                                        <Card
                                            onClick={() => {
                                                this.onAgentTrxChosen(item);
                                            }}
                                            hoverable={true}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div
                                                style={{textAlign: 'center'}}
                                            >
                                                <h3>{item.pos_trx_code}</h3>
                                                <Avatar
                                                    size={100}
                                                    shape="square"
                                                    src={item.details[0].image_url}
                                                />
                                                <div style={{
                                                    lineHeight: '1.5em',
                                                    marginTop: 20
                                                }}>
                                                    {item.details[0].item_name}
                                                    <br/>
                                                    Pada {moment(item.details[0].created_at, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                                    <br/>
                                                    Oleh {item.agent_code}
                                                </div>
                                            </div>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </React.Fragment>
                    )}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    const currentTrx = state.posState.posTrx.find(
        trx => trx.local_trx_id === currentLocalTrxId
    );
    return {
        currentLocalTrxId,
        currentTrx,
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps,
    addTrxDetail: posOperations.addTrxDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosRedeemModal);
