import React, { Component } from 'react';
import { Button, Drawer, Layout } from 'antd';
import SideMenu from '../../modules/SideMenu/SideMenu';
import { authOperations } from '../../state/ducks/auth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './MainTemplate.module.scss';
import moment from 'moment';

const { Header, Footer, Sider, Content } = Layout;

const MainTemplate = ChildComponent => {
    class ComposedComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                drawerVisible: false,
                isMobile: false
            };
        }

        openDrawer = () => {
            this.setState({ drawerVisible: true });
        };

        closeDrawer = () => {
            this.setState({ drawerVisible: false });
        };

        render() {
            return (
                <Layout>
                    <Sider
                        theme="light"
                        width={300}
                        breakpoint="xl"
                        collapsible={true}
                        collapsedWidth={0}
                        trigger={null}
                        className={styles.sider}
                    >
                        <SideMenu />
                    </Sider>
                    <Layout>
                        <Drawer
                            placement="left"
                            width={300}
                            closable={false}
                            onClose={this.closeDrawer}
                            visible={this.state.drawerVisible}
                            className={styles.menuDrawer}
                        >
                            <SideMenu />
                        </Drawer>
                        <Header className={styles.header} theme="light">
                            <div className={styles.nonMobile}>
                                {this.props.userData.brand_name} -{' '}
                                {this.props.userData.outlet_name}
                            </div>
                            <div className={styles.headerRight}>
                                {this.props.userData.business_user_firstname}
                                <Button
                                    onClick={this.openDrawer}
                                    icon="bars"
                                    className={styles.mobileMenuToggle}
                                />
                            </div>
                        </Header>
                        <Content
                            style={{
                                padding: 15
                            }}
                        >
                            <ChildComponent {...this.props} />
                        </Content>
                        <Footer className={styles.footer}>
                            &copy;{moment().format('Y')} PT Inovasi Teknologi
                            Kreatif, All Rights Reserved. <a href={'/backdoor'}>Bd.</a>
                        </Footer>
                    </Layout>
                </Layout>
            );
        }
    }

    return ComposedComponent;
};

const mapStateToProps = state => {
    return {
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    removeUserData: authOperations.removeUserData
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    MainTemplate
);
