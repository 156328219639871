import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Divider, Icon, message } from "antd";
import uuid from "uuid/v4";
import { posOperations } from "../../state/ducks/pos";
import { empty } from "../../shared/helpers/generalHelper";
import { nl2brReact, number_format } from "../../shared/helpers/stringHelper";
import styles from "./PosPriceListPublic.module.scss";
import PosItemModal from "../PosItemModal/PosItemModal";
import PosPriceCalculator from "../../shared/classes/PosPriceCalculator";
import PropTypes from "prop-types";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT, PAYMENT_TYPE_DEPOSIT, RECEIPT_TEMPLATE_DEFAULT, RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END, TRX_TYPE_COUPON_SALES, TRX_TYPE_NORMAL, TRX_TYPE_TOP_UP_DEPOSIT } from "../../shared/utils/constants";
import PosCustomItemModal from "../PosCustomItemModal/PosCustomItemModal";

class PosPriceListPublic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalItemOpened: false,
            modalItem: null,
            modalItemTrxDetail: null,
            modalCustomItemOpened: false,
            modalCustomItem: null,
            modalCustomItemTrxDetail: null,
            printSize: !empty(this.props.configs.printerPaperSize) ? this.props.configs.printerPaperSize : "8cm"
        };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    static propTypes = {
        trx: PropTypes.object,
        posConfig: PropTypes.object,
        itemEditable: PropTypes.bool,
        preSettlement: PropTypes.bool,
        orderPreview: PropTypes.bool,
        copy: PropTypes.bool,
        isLegit: PropTypes.bool,
        showSecretNotes: PropTypes.bool,
        style: PropTypes.object
    };

    static defaultProps = {
        itemEditable: true,
        preSettlement: false,
        orderPreview: false,
        copy: false,
        isLegit: false,
        showSecretNotes: false,
        style: {}
    };

    setComponentState = newState => {
        this.setState(newState);
    };

    removeItem = local_trx_detail_id => {
        this.props.deleteTrxDetail(
            this.props.trx.local_trx_id,
            local_trx_detail_id
        );
        message.success("Item successfully removed");
    };

    onDeleteGlobalDiscount = local_trx_id => {
        this.props.setTrxProps(local_trx_id, {
            discounts: []
        });
    };

    onDeleteMemberDiscount = local_trx_id => {
        const { trx } = this.props;
        this.props.setTrxProps(local_trx_id, {
            customer: {
                ...trx.customer,
                discount_level: 0
            }
        });
    };

    renderTrxDetails = (trx, isInventoryItem = null) => {
        const trxDetails = trx.details;
        const { posConfig } = this.props;
        const currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;

        return trxDetails
            .filter(row => {
                if (isInventoryItem === null) {
                    return row;
                } else {
                    if (isInventoryItem) {
                        return row.itemMeta.item_nature === "RETAIL";
                    } else {
                        return row.itemMeta.item_nature !== "RETAIL";
                    }
                }
            })
            .map((detail, index) => {
                const pos_item_variant = detail.itemMeta.pos_item_variants.find(
                    variant =>
                        variant.pos_item_variant_id === detail.pos_item_variant_id
                );
                const formattedItemPrice =
                    number_format(parseInt(pos_item_variant.variant_price), 0) + ` ${currencyCode}`;
                const itemNotes = detail.notes;
                const secretNotes = detail.secret_notes;
                const itemCommissionWorker = detail.commission_worker;
                const itemCommissionWorkers = detail.commission_workers;
                const isPackage = detail.is_package;
                const packageDetails = detail.pos_item_package_details;
                const itemDisc = detail.discounts;
                const customDisc = {
                    custom_discount_type: detail.custom_discount_type,
                    custom_discount_value: detail.custom_discount_value
                };
                let modifierPrice = 0;
                if (!empty(detail.pos_item_pos_item_modifiers)) {
                    detail.pos_item_pos_item_modifiers.map(modifier => {
                        modifier.pos_item_modifier_options.map(option => {
                            modifierPrice += parseInt(option.option_price);
                            return option;
                        });
                        return modifier;
                    });
                }
                const item_price_with_modifier = parseInt(pos_item_variant.variant_price) + modifierPrice;
                const calculatedSubTotal = PosPriceCalculator.calculateItemPrice(
                    trx,
                    detail,
                    this.currencyCode
                ).totalPriceValue;
                let formattedSubTotal =
                    number_format(calculatedSubTotal, 0) + ` ${currencyCode}`;

                if (posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END) {
                    formattedSubTotal = number_format(PosPriceCalculator.calculateGrossItemPrice(trx, detail).totalPriceValue, 0) + ` ${currencyCode}`;
                }

                let couponDisc = "";
                if (!empty(trx.coupon)) {
                    if (
                        (trx.coupon.coupon_item.pos_link_type === "item" &&
                            trx.coupon.coupon_item.pos_item_id ===
                            detail.itemMeta.pos_item_id) ||
                        (trx.coupon.coupon_item.pos_link_type === "item_category" &&
                            trx.coupon.coupon_item.pos_item_category_id ===
                            detail.itemMeta.pos_item_category_id)
                    ) {
                        const couponDiscTotal = PosPriceCalculator.calculateCouponItem(
                            detail,
                            trx,
                            currencyCode
                        );
                        if (couponDiscTotal.totalDisc > 0) {
                            couponDisc = couponDiscTotal.formattedDisc;
                        }
                    }
                }

                let redeemDisc = "";
                if (!empty(trx.redeem)) {
                    if (
                        (trx.redeem.redeem_item.pos_link_type === "item" &&
                            trx.redeem.redeem_item.pos_item_id ===
                            detail.itemMeta.pos_item_id) ||
                        (trx.redeem.redeem_item.pos_link_type === "item_category" &&
                            trx.redeem.redeem_item.pos_item_category_id ===
                            detail.itemMeta.pos_item_category_id)
                    ) {
                        const redeemDiscTotal = PosPriceCalculator.calculateRedeemItem(
                            detail,
                            trx,
                            currencyCode
                        );
                        if (redeemDiscTotal.totalDisc > 0) {
                            redeemDisc = redeemDiscTotal.formattedDisc;
                        }
                    }
                }

                let drv_discount_type = "";
                if (empty(drv_discount_type) && !empty(trx.coupon)) {
                    if (
                        (trx.coupon.coupon_item.pos_link_type === "item" &&
                            trx.coupon.coupon_item.pos_item_id ===
                            detail.itemMeta.pos_item_id) ||
                        (trx.coupon.coupon_item.pos_link_type === "item_category" &&
                            trx.coupon.coupon_item.pos_item_category_id ===
                            detail.itemMeta.pos_item_category_id)
                    ) {
                        drv_discount_type = "coupon";
                    }
                }
                if (empty(drv_discount_type) && !empty(trx.redeem)) {
                    if (
                        (trx.redeem.redeem_item.pos_link_type === "item" &&
                            trx.redeem.redeem_item.pos_item_id ===
                            detail.itemMeta.pos_item_id) ||
                        (trx.redeem.redeem_item.pos_link_type === "item_category" &&
                            trx.redeem.redeem_item.pos_item_category_id ===
                            detail.itemMeta.pos_item_category_id)
                    ) {
                        drv_discount_type = "redeem";
                    }
                }
                if (
                    empty(drv_discount_type) &&
                    !empty(customDisc.custom_discount_value)
                ) {
                    drv_discount_type = "custom";
                }
                if (empty(drv_discount_type) && !empty(itemDisc)) {
                    drv_discount_type = "defined";
                }

                if (!this.props.isPrint) {
                    return (
                        <React.Fragment key={index}>
                            <tr className={styles.itemRow}>
                                <td
                                    className={styles.itemName}
                                    onClick={() => {
                                        if (this.props.itemEditable) {
                                            if (detail.itemMeta.is_custom === 1) {
                                                this.setComponentState({
                                                    modalCustomItemOpened: true,
                                                    modalCustomItem: detail.itemMeta,
                                                    modalCustomItemTrxDetail: detail
                                                });
                                            } else {
                                                this.setComponentState({
                                                    modalItemOpened: true,
                                                    modalItem: detail.itemMeta,
                                                    modalItemTrxDetail: detail
                                                });
                                            }
                                        }
                                    }}
                                >
                                    {detail.itemMeta.item_name}
                                    {!empty(trx.account_receivable) ? (
                                        <React.Fragment>
                                            <br/>
                                            Transaksi {trx.account_receivable.local_trx_id.toUpperCase().substr(-6).toUpperCase().substr(-6)}<br/>
                                            {trx.account_receivable.trx_id}<br/>
                                            {moment(trx.account_receivable.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                        </React.Fragment>
                                    ) : ""}
                                </td>
                                <td
                                    className={styles.itemQty}
                                    onClick={() => {
                                        if (this.props.itemEditable) {
                                            if (detail.itemMeta.is_custom === 1) {
                                                this.setComponentState({
                                                    modalCustomItemOpened: true,
                                                    modalCustomItem: detail.itemMeta,
                                                    modalCustomItemTrxDetail: detail
                                                });
                                            } else {
                                                this.setComponentState({
                                                    modalItemOpened: true,
                                                    modalItem: detail.itemMeta,
                                                    modalItemTrxDetail: detail
                                                });
                                            }
                                        }
                                    }}
                                >
                                    x{number_format(detail.qty, 0)}
                                </td>
                                <td
                                    className={styles.itemSubTotal}
                                    onClick={() => {
                                        if (this.props.itemEditable) {
                                            if (detail.itemMeta.is_custom === 1) {
                                                this.setComponentState({
                                                    modalCustomItemOpened: true,
                                                    modalCustomItem: detail.itemMeta,
                                                    modalCustomItemTrxDetail: detail
                                                });
                                            } else {
                                                this.setComponentState({
                                                    modalItemOpened: true,
                                                    modalItem: detail.itemMeta,
                                                    modalItemTrxDetail: detail
                                                });
                                            }
                                        }
                                    }}
                                >
                                    {formattedSubTotal}
                                </td>
                                <td className={styles.itemRemove}>
                                    <Icon
                                        onClick={() => {
                                            this.removeItem(
                                                detail.local_trx_detail_id
                                            );
                                        }}
                                        type="close-circle"
                                        theme="outlined"
                                    />
                                </td>
                            </tr>
                            {this.renderItemSub(
                                uuid(),
                                `${pos_item_variant.variant_name} @`,
                                formattedItemPrice
                            )}
                            {!empty(detail.pos_item_pos_item_modifiers) && detail.pos_item_pos_item_modifiers.map(modifier => {
                                return modifier.pos_item_modifier_options.map(option => {
                                    return this.renderItemSub(
                                        uuid(),
                                        `${modifier.modifier_name}: ${option.option_name} @`,
                                        number_format(option.option_price, 0) + ` ${currencyCode}`
                                    );
                                });
                            })}
                            {drv_discount_type === "coupon" &&
                            this.renderItemSub(
                                uuid(),
                                `Coupon Disc.`,
                                couponDisc
                            )}
                            {drv_discount_type === "redeem" &&
                            this.renderItemSub(
                                uuid(),
                                `Redeem Disc.`,
                                redeemDisc
                            )}
                            {drv_discount_type === "custom" &&
                            this.renderCustomDisc(
                                item_price_with_modifier,
                                customDisc,
                                itemDisc
                            )}
                            {drv_discount_type === "defined" &&
                            this.renderDiscByItem(
                                item_price_with_modifier,
                                itemDisc
                            )}
                            {!empty(itemNotes) &&
                            this.renderItemSub(uuid(), `Notes `, itemNotes)}
                            {!empty(secretNotes) && this.props.showSecretNotes &&
                            this.renderItemSub(uuid(), `S. Notes`, secretNotes)}
                            {!empty(itemCommissionWorker) &&
                            this.renderItemSub(
                                uuid(),
                                `Handled by `,
                                itemCommissionWorker.firstname +
                                (empty(itemCommissionWorker.lastname)
                                    ? ""
                                    : " " + itemCommissionWorker.lastname)
                            )}
                            {!empty(itemCommissionWorkers) &&
                            itemCommissionWorkers.map(row => {
                                return this.renderItemSub(
                                    uuid(),
                                    `Handled by `,
                                    row.commission_worker.firstname +
                                    (empty(row.commission_worker.lastname)
                                        ? ""
                                        : " " + row.commission_worker.lastname) + " - " + row.percentage + "%"
                                );
                            })}
                            {!empty(detail.service_area) && this.renderItemSub(
                                uuid(),
                                `Service Area `,
                                detail.service_area.area_name
                            )}
                            {isPackage === 1 &&
                            !empty(packageDetails) &&
                            packageDetails.map(packageDetail => {
                                let itemString = "--- ";
                                itemString += `x${packageDetail.qty} ${
                                    packageDetail.item_name
                                }`;
                                if (
                                    !empty(
                                        packageDetail.pos_item_package_detail_variants
                                    ) && packageDetail.pos_item_package_detail_variants.length > 1
                                ) {
                                    const chosen_variant = packageDetail.pos_item_package_detail_variants.find(
                                        variant => variant.is_chosen === 1
                                    );
                                    if (!empty(chosen_variant)) {
                                        itemString += ` (${
                                            chosen_variant.variant_name
                                        })`;
                                    }
                                }

                                if (
                                    !empty(
                                        packageDetail.business_commission_worker
                                    )
                                ) {
                                    itemString += ` (By ${
                                        packageDetail.business_commission_worker.firstname
                                    } ${packageDetail.business_commission_worker.lastname})`;
                                }

                                if (
                                    !empty(
                                        packageDetail.commission_workers
                                    )
                                ) {
                                    packageDetail.commission_workers.map(row => {
                                        itemString += ` (By ${
                                            row.commission_worker.firstname
                                        } ${row.commission_worker.lastname} - ${row.percentage}%)`;
                                        return row;
                                    });
                                }

                                if (!empty(packageDetail.service_area)) {
                                    itemString += ` (Service Area - ${packageDetail.service_area.area_name})`;
                                }

                                return this.renderItemSub(
                                    uuid(),
                                    itemString,
                                    ""
                                );
                            })}
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment key={index}>
                            {this.state.printSize === "6cm" && (
                                <React.Fragment>
                                    <tr className={styles.itemRow}>
                                        <td className={styles.itemQty6cm}>
                                            x{number_format(detail.qty, 0)}
                                        </td>
                                        {!this.props.orderPreview && (
                                            <td className={styles.itemSubTotal6cm} colSpan={2}>
                                                {formattedSubTotal}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className={styles.itemName6cm} colSpan={3}>
                                            {detail.itemMeta.item_name}

                                            {!empty(trx.account_receivable) ? (
                                                <React.Fragment>
                                                    <br/>
                                                    Transaksi {trx.account_receivable.local_trx_id.toUpperCase().substr(-6)}<br/>
                                                    {trx.account_receivable.trx_id}<br/>
                                                    {moment(trx.account_receivable.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                                </React.Fragment>
                                            ) : ""}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}

                            {this.state.printSize === "8cm" && (
                                <tr className={styles.itemRow}>
                                    <td className={styles.itemName}>
                                        {detail.itemMeta.item_name}
                                        {!empty(trx.account_receivable) ? (
                                            <React.Fragment>
                                                <br/>
                                                Transaksi {trx.account_receivable.local_trx_id.toUpperCase().substr(-6)}<br/>
                                                {trx.account_receivable.trx_id}<br/>
                                                {moment(trx.account_receivable.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                            </React.Fragment>
                                        ) : ""}
                                    </td>
                                    {!this.props.orderPreview && (
                                        <React.Fragment>
                                            <td className={styles.itemQty}>
                                                x{number_format(detail.qty, 0)}
                                            </td>
                                            <td className={styles.itemSubTotal}>
                                                {formattedSubTotal}
                                            </td>
                                        </React.Fragment>
                                    )}
                                    {this.props.orderPreview && (
                                        <React.Fragment>
                                            <td className={styles.itemSubTotal} colSpan={2}>
                                                x{number_format(detail.qty, 0)}
                                            </td>
                                        </React.Fragment>
                                    )}

                                </tr>
                            )}

                            {!this.props.orderPreview && (
                                <React.Fragment>
                                    {this.renderItemSub(
                                        uuid(),
                                        `${pos_item_variant.variant_name} @`,
                                        formattedItemPrice
                                    )}
                                    {!empty(detail.pos_item_pos_item_modifiers) && detail.pos_item_pos_item_modifiers.map(modifier => {
                                        return modifier.pos_item_modifier_options.map(option => {
                                            return this.renderItemSub(
                                                uuid(),
                                                `${modifier.modifier_name}: ${option.option_name} @`,
                                                number_format(option.option_price, 0) + ` ${currencyCode}`
                                            );
                                        });
                                    })}
                                    {drv_discount_type === "coupon" &&
                                    this.renderItemSub(
                                        uuid(),
                                        `Coupon Disc.`,
                                        couponDisc
                                    )}
                                    {drv_discount_type === "redeem" &&
                                    this.renderItemSub(
                                        uuid(),
                                        `Redeem Disc.`,
                                        redeemDisc
                                    )}
                                    {drv_discount_type === "custom" &&
                                    this.renderCustomDisc(
                                        item_price_with_modifier,
                                        customDisc,
                                        itemDisc
                                    )}
                                    {drv_discount_type === "defined" &&
                                    this.renderDiscByItem(
                                        item_price_with_modifier,
                                        itemDisc
                                    )}
                                </React.Fragment>
                            )}


                            {!empty(itemNotes) &&
                            this.renderItemSub(uuid(), `Notes `, itemNotes)}
                            {!empty(secretNotes) && this.props.showSecretNotes &&
                            this.renderItemSub(uuid(), `S. Notes`, secretNotes)}

                            {(this.props.showWorker || posConfig.pos_print_worker === 1) && !empty(itemCommissionWorker) &&
                            this.renderItemSub(
                                uuid(),
                                `Handled by `,
                                posConfig.pos_print_initial === 1
                                    ? itemCommissionWorker.initial
                                    : itemCommissionWorker.firstname +
                                    (empty(itemCommissionWorker.lastname)
                                        ? ""
                                        : " " + itemCommissionWorker.lastname)
                            )}
                            {(this.props.showWorker || posConfig.pos_print_worker === 1) && !empty(itemCommissionWorkers) &&
                            itemCommissionWorkers.map(row => {
                                return this.renderItemSub(
                                    uuid(),
                                    `Handled by `,
                                    posConfig.pos_print_initial === 1
                                        ? row.commission_worker.initial
                                        : row.commission_worker.firstname +
                                        (empty(row.commission_worker.lastname)
                                            ? ""
                                            : " " + row.commission_worker.lastname) + " - " + row.percentage + "%"
                                );
                            })}
                            {!empty(detail.service_area) && this.renderItemSub(
                                uuid(),
                                `Service Area `,
                                detail.service_area.area_name
                            )}

                            {isPackage === 1 &&
                            !empty(packageDetails) &&
                            packageDetails.map(packageDetail => {
                                let itemString = "--- ";
                                itemString += `x${packageDetail.qty} ${
                                    packageDetail.item_name
                                }`;
                                if (
                                    !empty(
                                        packageDetail.pos_item_package_detail_variants
                                    )
                                ) {
                                    const chosen_variant = packageDetail.pos_item_package_detail_variants.find(
                                        variant => variant.is_chosen === 1
                                    );
                                    if (!empty(chosen_variant)) {
                                        itemString += ` (${
                                            chosen_variant.variant_name
                                        })`;
                                    }
                                }

                                if (
                                    !empty(
                                        packageDetail.business_commission_worker
                                    ) &&
                                    (this.props.showWorker || posConfig.pos_print_worker === 1)
                                ) {
                                    itemString += posConfig.pos_print_initial === 1
                                        ? ` (By ${packageDetail.business_commission_worker.commission_worker.initial})`
                                        : ` (By ${
                                            packageDetail.business_commission_worker.firstname
                                        } ${packageDetail.business_commission_worker.lastname})`;
                                }

                                if (
                                    !empty(
                                        packageDetail.commission_workers
                                    ) &&
                                    (this.props.showWorker || posConfig.pos_print_worker === 1)
                                ) {
                                    packageDetail.commission_workers.map(row => {
                                        itemString += posConfig.pos_print_initial === 1
                                            ? ` (By ${row.commission_worker.initial})`
                                            : ` (By ${
                                                row.commission_worker.firstname
                                            } ${row.commission_worker.lastname} - ${row.percentage}%)`;
                                        return row;
                                    });
                                }

                                if (!empty(packageDetail.service_area)) {
                                    itemString += ` (Service Area - ${packageDetail.service_area.area_name})`;
                                }

                                return this.renderItemSub(
                                    uuid(),
                                    itemString,
                                    ""
                                );
                            })}
                        </React.Fragment>
                    );
                }
            });
    };

    renderItemSub = (index, label, nominal) => {
        if (!empty(label)) {
            return (
                <tr className={styles.itemSubRow} key={index}>
                    <td colSpan={3} className={styles.itemSub}>
                        {label} {!empty(nominal) ? `(${nominal})` : ""}
                    </td>
                </tr>
            );
        }
    };

    renderDiscByItem = (itemPrice, itemDisc) => {
        return itemDisc.map((disc, index) => {
            const calculatedDisc = PosPriceCalculator.calculateDiscByItem(
                itemPrice,
                disc.deduction_type,
                disc.deduction_value,
                this.currencyCode
            );
            return this.renderItemSub(
                index,
                "Discount",
                calculatedDisc.formattedDisc
            );
        });
    };

    renderCustomDisc = (itemPrice, customDisc, itemDisc, currencyCode) => {
        const calculatedDisc = PosPriceCalculator.calculateCustomDisc(
            itemPrice,
            customDisc,
            itemDisc,
            currencyCode
        );
        let discountString = "C. Discount";
        if (customDisc.custom_discount_type === "percentage") {
            discountString += ` ${customDisc.custom_discount_value}%`;
        }
        return this.renderItemSub(
            uuid(),
            discountString,
            calculatedDisc.formattedDisc
        );
    };

    render() {
        const { trx, posConfig, configs, isPrint, style, preSettlement, copy } = this.props;
        const { modalItemOpened, modalItem, modalItemTrxDetail, modalCustomItemOpened, modalCustomItem, modalCustomItemTrxDetail } = this.state;
        const subTotal = PosPriceCalculator.calculateSubTotal(trx, this.currencyCode);
        const globalDiscount = PosPriceCalculator.calculateGlobalDiscount(trx, subTotal.subTotal, this.currencyCode);
        const subTotalAfterGlobalDiscount =
            subTotal.subTotal - globalDiscount.totalDiscountValue;
        const serviceCharge = PosPriceCalculator.calculateServiceCharge(
            trx,
            posConfig.service_charge,
            this.currencyCode
        );
        const additionalCharge = PosPriceCalculator.calculateAdditionalCharge(
            trx,
            posConfig.additional_charge,
            this.currencyCode
        );
        let tax = 0;
        if (trx.trx_type === TRX_TYPE_NORMAL || trx.trx_type === TRX_TYPE_COUPON_SALES) {
            tax = PosPriceCalculator.calculateTax(
                subTotalAfterGlobalDiscount,
                serviceCharge.serviceChargeValue,
                additionalCharge.additionalChargeValue,
                posConfig.tax,
                this.currencyCode
            );
        } else {
            tax = PosPriceCalculator.calculateTax(
                subTotalAfterGlobalDiscount,
                serviceCharge.serviceChargeValue,
                additionalCharge.additionalChargeValue,
                null,
                this.currencyCode
            );
        }
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            trx,
            posConfig,
            this.currencyCode
        );
        let customerName = "GUEST";

        const reducer = (accumulator, currentValue) => parseInt(currentValue.payment_amount) + accumulator;
        const totalPayment = trx.payments.reduce(reducer, 0);

        const grossTotalService = PosPriceCalculator.calculateGrossTotal(trx.details, "SERVICE", this.currencyCode).grossTotal;
        const grossTotalRetail = PosPriceCalculator.calculateGrossTotal(trx.details, "RETAIL", this.currencyCode).grossTotal;
        const grossTotal = PosPriceCalculator.calculateGrossTotal(trx.details, null, this.currencyCode).grossTotal;

        const itemDiscountPriceReducer = (total, detail) =>
            total +
            PosPriceCalculator.calculateItemPrice(trx, detail, this.currencyCode).totalDiscountValue;
        const totalItemDiscount = trx.details.reduce(itemDiscountPriceReducer, 0);

        const totalDiscount = totalItemDiscount + globalDiscount.totalDiscountValue;
        const currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;


        let drv_global_discount_type = "";
        if (
            empty(drv_global_discount_type) &&
            !empty(trx.coupon) &&
            trx.coupon.coupon_item.pos_link_type === "discount"
        ) {
            drv_global_discount_type = "coupon";
        }
        if (
            empty(drv_global_discount_type) &&
            !empty(trx.redeem) &&
            trx.redeem.redeem_item.pos_link_type === "discount"
        ) {
            drv_global_discount_type = "redeem";
        }
        if (
            empty(drv_global_discount_type) &&
            !empty(trx.customer) &&
            !empty(trx.customer.discount_level) &&
            !empty(trx.customer.discount_level.reward_value) &&
            trx.customer.discount_level.reward_value > 0
        ) {
            drv_global_discount_type = "member";
        }
        if (empty(drv_global_discount_type) && !empty(trx.discounts)) {
            trx.discounts.map(discount => {
                if (discount.discount_type === "custom") {
                    drv_global_discount_type = "custom";
                }
                return discount;
            });
        }
        if (empty(drv_global_discount_type) && !empty(trx.discounts)) {
            trx.discounts.map(discount => {
                if (discount.discount_type === "basic") {
                    drv_global_discount_type = "defined";
                }
                return discount;
            });
        }

        if (!empty(trx.customer)) {
            if ("user_name" in trx.customer) {
                customerName = trx.customer.user_name;
            }
        }

        let couponLabel = "";
        if (!empty(trx.coupon)) {
            if (trx.coupon.coupon_item.pos_link_type === "discount") {
                couponLabel = ` (Coupon ${
                    trx.coupon.coupon_item.discount_percentage
                }% off)`;
            }
        }

        let redeemLabel = "";
        if (!empty(trx.redeem)) {
            if (trx.redeem.redeem_item.pos_link_type === "discount") {
                redeemLabel = ` (Redeem ${
                    trx.redeem.redeem_item.discount_percentage
                }% off)`;
            }
        }

        if (!isPrint) {
            return (
                <div className={styles.outerWrapper} style={style} ref={el => (this.container = el)}>
                    {!empty(trx.details) && (
                        <table className={styles.itemListTable}>
                            <tbody>
                            {trx.trx_type === TRX_TYPE_NORMAL &&
                            posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END &&
                            (<React.Fragment>
                                {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") && (
                                    <React.Fragment>
                                        <tr>
                                            <td>
                                                <strong>SERVICES</strong>
                                            </td>
                                        </tr>
                                        {this.renderTrxDetails(trx, false)}
                                    </React.Fragment>
                                )}

                                {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") &&
                                trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                    <tr>
                                        <td colSpan={4}>
                                            <Divider style={{ margin: "10px 0" }}/>
                                        </td>
                                    </tr>
                                )}

                                {trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                    <React.Fragment>
                                        <tr>
                                            <td>
                                                <strong>ITEMS</strong>
                                            </td>
                                        </tr>
                                        {this.renderTrxDetails(trx, true)}
                                    </React.Fragment>
                                )}
                            </React.Fragment>)}

                            {(trx.trx_type !== TRX_TYPE_NORMAL ||
                                (
                                    trx.trx_type === TRX_TYPE_NORMAL &&
                                    posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT
                                )) && (this.renderTrxDetails(trx))}

                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <Divider style={{ margin: "10px 0" }}/>
                                </td>
                            </tr>

                            {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "coupon" && (
                                <tr>
                                    <td colSpan={2}>
                                        Discount {couponLabel}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "redeem" && (
                                <tr>
                                    <td colSpan={2}>
                                        Discount {redeemLabel}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "member" && (
                                <tr>
                                    <td colSpan={2}>
                                        Member Discount -{" "}
                                        {
                                            trx.customer.discount_level
                                                .reward_value
                                        }
                                        %&nbsp;&nbsp;
                                        <button
                                            onClick={() =>
                                                this.onDeleteMemberDiscount(
                                                    trx.local_trx_id
                                                )
                                            }
                                            className={
                                                styles.linkBtn
                                            }
                                        >
                                            hapus
                                        </button>
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {drv_global_discount_type === "custom" && (
                                <tr>
                                    <td colSpan={2}>
                                        C. Discount&nbsp;&nbsp;
                                        {!empty(trx.discounts) && (
                                            <React.Fragment>
                                                <button
                                                    onClick={() =>
                                                        this.onDeleteGlobalDiscount(
                                                            trx.local_trx_id
                                                        )
                                                    }
                                                    className={
                                                        styles.linkBtn
                                                    }
                                                >
                                                    hapus
                                                </button>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {drv_global_discount_type === "defined" && (
                                <tr>
                                    <td colSpan={2}>
                                        Discount&nbsp;&nbsp;
                                        {!empty(trx.discounts) && (
                                            <React.Fragment>
                                                <button
                                                    onClick={() =>
                                                        this.onDeleteGlobalDiscount(
                                                            trx.local_trx_id
                                                        )
                                                    }
                                                    className={
                                                        styles.linkBtn
                                                    }
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                >
                                                    hapus
                                                </button>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {!empty(serviceCharge.serviceChargeValue) && (
                                <tr>
                                    <td>Service Charge</td>
                                    <td>&nbsp;</td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            serviceCharge.formattedServiceCharge
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {!empty(
                                additionalCharge.additionalChargeValue
                            ) && (
                                <tr>
                                    <td>Additional Charge</td>
                                    <td>&nbsp;</td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            additionalCharge.formattedAdditionalCharge
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {!empty(tax.taxValue) && (
                                <tr>
                                    <td>
                                        Tax ({tax.taxPercent}
                                        %)
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={styles.itemSubTotal}>
                                        {tax.formattedTax}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            <tr>
                                <td style={{ fontWeight: 600 }}>
                                    TOTAL
                                </td>
                                <td>&nbsp;</td>
                                <td
                                    className={styles.itemSubTotal}
                                    style={{ fontWeight: 600 }}
                                >
                                    {grandTotal.formattedGrandTotal}
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            </tfoot>
                        </table>
                    )}

                    {empty(trx.details) && <div>Belum ada produk dipilih..</div>}

                    {modalItemOpened &&
                    !empty(modalItem) &&
                    !empty(modalItemTrxDetail) &&
                    trx.trx_type !== TRX_TYPE_COUPON_SALES && (
                        <PosItemModal
                            modalOpened={modalItemOpened}
                            item={modalItem}
                            trxDetail={modalItemTrxDetail}
                            close={() => {
                                this.setComponentState({
                                    modalItemOpened: false,
                                    modalItem: null,
                                    modalItemTrxDetail: null
                                });
                            }}
                        />
                    )
                    }

                    {modalItemOpened &&
                    !empty(modalItem) &&
                    !empty(modalItemTrxDetail) &&
                    trx.trx_type === TRX_TYPE_COUPON_SALES && (
                        <PosItemModal
                            modalOpened={modalItemOpened}
                            item={modalItem}
                            itemType="coupon"
                            trxDetail={modalItemTrxDetail}
                            close={() => {
                                this.setComponentState({
                                    modalItemOpened: false,
                                    modalItem: null,
                                    modalItemTrxDetail: null
                                });
                            }}
                        />
                    )
                    }

                    {modalCustomItemOpened &&
                    !empty(modalCustomItem) &&
                    !empty(modalCustomItemTrxDetail) && (
                        <PosCustomItemModal
                            modalOpened={modalCustomItemOpened}
                            item={modalCustomItem}
                            trxDetail={modalCustomItemTrxDetail}
                            close={() => {
                                this.setComponentState({
                                    modalCustomItemOpened: false,
                                    modalCustomItem: null,
                                    modalCustomItemTrxDetail: null
                                });
                            }}
                        />
                    )
                    }
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm}`}>
                        {!empty(trx.details) && (
                            <React.Fragment>
                                <table className={styles.headerTable}>
                                    <tbody>
                                    {!this.props.orderPreview && (
                                        <React.Fragment>
                                            {(empty(posConfig.brand_logo) || posConfig.pos_print_logo !== 1) && (
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className={styles.brandName}
                                                    >
                                                        {posConfig.brand_name}
                                                    </td>
                                                </tr>
                                            )}

                                            {!empty(posConfig.brand_logo) && posConfig.pos_print_logo === 1 && (
                                                <React.Fragment>
                                                    <tr>
                                                        <td
                                                            colSpan={2}
                                                            className={styles.brandName}
                                                        >
                                                            <img src={posConfig.brand_logo}
                                                                 className={styles.brandLogo} alt={posConfig.brand_name} />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            colSpan={2}
                                                            className={styles.outletName}
                                                        >
                                                            {posConfig.brand_name}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}

                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    className={styles.outletName}
                                                >
                                                    {posConfig.outlet_name}
                                                </td>
                                            </tr>

                                            {posConfig.pos_print_address === 1 && (
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className={styles.outletAddress}
                                                    >
                                                        {posConfig.outlet_address}
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    className={styles.outletAddress}
                                                >
                                                    {posConfig.outlet_phone}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>&nbsp;</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    <tr>
                                        <td>Customer</td>
                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                            {customerName}
                                        </td>
                                    </tr>

                                    {posConfig.pos_print_user_identifier === 1 && !empty(trx.customer) && (
                                        <tr>
                                            <td>Customer ID</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.customer.user_identifier_string}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>Cashier</td>
                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                            {posConfig.cashier_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Receipt Number</td>
                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                            {trx.local_trx_id
                                                .slice(-6)
                                                .toUpperCase()}
                                        </td>
                                    </tr>
                                    {!empty(trx.order_no) && (
                                        <tr>
                                            <td>Order No</td>
                                            <td className={styles.alignRight}>
                                                {trx.order_no}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>
                                            {moment(
                                                empty(trx.document_date) ? trx.trx_local_end_time : trx.document_date,
                                                MOMENT_SQL_DATETIME_FORMAT
                                            ).format("D MMM YYYY")}
                                        </td>
                                        <td className={styles.alignRight}>
                                            {moment(
                                                empty(trx.document_date) ? trx.trx_local_end_time : trx.document_date,
                                                MOMENT_SQL_DATETIME_FORMAT
                                            ).format("HH:mm")}
                                        </td>
                                    </tr>

                                    {!empty(trx.service_area) && (
                                        <tr>
                                            <td>Area</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.service_area.area_name}{!empty(trx.service_area.areaCategory_name) && ` - ${trx.service_area.areaCategory_name}`}
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>

                                <div className={styles.receiptDivider}/>

                                <table className={styles.itemListTable}>
                                    <tbody>
                                    {trx.trx_type === TRX_TYPE_NORMAL &&
                                    posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END &&
                                    (<React.Fragment>
                                        {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") && (
                                            <React.Fragment>
                                                <tr>
                                                    <td>
                                                        <strong>SERVICES</strong>
                                                    </td>
                                                </tr>
                                                {this.renderTrxDetails(trx, false)}
                                            </React.Fragment>
                                        )}

                                        {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") &&
                                        trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        {trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                            <React.Fragment>
                                                <tr>
                                                    <td>
                                                        <strong>ITEMS</strong>
                                                    </td>
                                                </tr>
                                                {this.renderTrxDetails(trx, true)}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>)}

                                    {(trx.trx_type !== TRX_TYPE_NORMAL ||
                                        (
                                            trx.trx_type === TRX_TYPE_NORMAL &&
                                            posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT
                                        )) && (this.renderTrxDetails(trx))}

                                    {copy && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div style={{
                                                        textAlign: "center",
                                                        fontSize: 18,
                                                        padding: "7px 0"
                                                    }}>
                                                        THIS IS A COPY
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {!this.props.orderPreview && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>

                                            {posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT && (
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {subTotal.formattedSubTotal}
                                                    </td>
                                                </tr>
                                            )}

                                            {posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END && (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>Total Service</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(grossTotalService, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Retail</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(grossTotalRetail, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Subtotal</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(grossTotal, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(totalDiscount, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}

                                            {posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT && (
                                                <React.Fragment>
                                                    {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "coupon" && (
                                                        <tr>
                                                            <td>
                                                                Discount {couponLabel}
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "redeem" && (
                                                        <tr>
                                                            <td>
                                                                Discount {redeemLabel}
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "member" && (
                                                        <tr>
                                                            <td>
                                                                Member Discount -{" "}
                                                                {
                                                                    trx.customer.discount_level
                                                                        .reward_value
                                                                }
                                                                %
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {drv_global_discount_type === "custom" && (
                                                        <tr>
                                                            <td>
                                                                C. Discount
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {drv_global_discount_type === "defined" && (
                                                        <tr>
                                                            <td>
                                                                Discount
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {!empty(
                                                serviceCharge.serviceChargeValue
                                            ) && (
                                                <tr>
                                                    <td>Service Charge</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {
                                                            serviceCharge.formattedServiceCharge
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                            {!empty(
                                                additionalCharge.additionalChargeValue
                                            ) && (
                                                <tr>
                                                    <td>Additional Charge</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {
                                                            additionalCharge.formattedAdditionalCharge
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                            {!empty(tax.taxValue) && (
                                                <tr>
                                                    <td>
                                                        Tax ({tax.taxPercent}
                                                        %)
                                                    </td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {tax.formattedTax}
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ fontWeight: 600 }}>
                                                    TOTAL
                                                </td>
                                                <td
                                                    colSpan={2}
                                                    className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    {grandTotal.formattedGrandTotal}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}


                                    {!preSettlement && !this.props.orderPreview && (
                                        <React.Fragment>
                                            {trx.payments.map(row => {
                                                return (
                                                    <tr key={`PaymentMethod${row.payment_method
                                                        .payment_method_name}`}>
                                                        <td>
                                                            {
                                                                row.payment_method
                                                                    .payment_method_name
                                                            }
                                                        </td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {`${number_format(
                                                                row.payment_amount,
                                                                0
                                                            )} ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                );
                                            })}


                                            {!empty(trx.payments.find(row => row.payment_method.payment_method_name === "Cash")) && (
                                                <tr>
                                                    <td>Change</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {number_format(
                                                            totalPayment -
                                                            trx.drv_grand_total,
                                                            0
                                                        )}{" "}
                                                        {currencyCode}
                                                    </td>
                                                </tr>
                                            )}

                                            {posConfig.print_deposit_balance_on_receipt === 1 &&
                                            this.props.isLegit &&
                                            (trx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT ||
                                                (trx.trx_type === TRX_TYPE_NORMAL &&
                                                    !empty(trx.payments.find(row => row.payment_type === PAYMENT_TYPE_DEPOSIT))
                                                )
                                            ) &&
                                            (trx.deposit_balance !== undefined && trx.deposit_balance !== null) &&
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={3}>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Deposit</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(trx.deposit_balance)}{" "}
                                                            {currencyCode}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                            }
                                        </React.Fragment>
                                    )}
                                    </tbody>
                                </table>

                                {!empty(trx.trx_notes) && (
                                    <React.Fragment>
                                        <div className={styles.receiptDivider}/>
                                        <div>
                                            Notes
                                            <br/>
                                            {nl2brReact(trx.trx_notes)}
                                        </div>
                                    </React.Fragment>
                                )}

                                {preSettlement && (
                                    <React.Fragment>
                                        <div className={styles.receiptDivider}/>
                                        <div style={{
                                            textAlign: "center",
                                            fontSize: 18,
                                            padding: "7px 0",
                                            lineHeight: "1em"
                                        }}>
                                            PRE-SETTLEMENT<br/>
                                            <span style={{
                                                fontSize: 12
                                            }}>
                                                {moment().format(MOMENT_READABLE_DATETIME_FORMAT)}
                                            </span>
                                        </div>
                                        <div className={styles.receiptDivider}/>
                                    </React.Fragment>
                                )}

                                {!empty(trx.order_no) &&
                                posConfig.pos_print_receipt_order_number_bottom === 1 &&
                                (
                                    <div style={{ textAlign: "center", marginTop: 30 }}>
                                        <div>Order No</div>
                                        <div style={{
                                            fontSize: 50,
                                            lineHeight: "60px"
                                            // fontWeight: 'bold'
                                        }}>
                                            {trx.order_no}
                                        </div>
                                    </div>
                                )}

                                {!empty(posConfig.receipt_footer) && !this.props.orderPreview && (
                                    <div style={{
                                        textAlign: "center",
                                        marginTop: 30,
                                        lineHeight: "1.3em"
                                    }}>
                                        {nl2brReact(posConfig.receipt_footer)}
                                    </div>
                                )}

                                <br/>
                            </React.Fragment>
                        )}

                        {empty(trx.details) && <div>No items yet</div>}
                    </div>

                    {posConfig.pos_print_order_detail === 1 && this.props.isLegit && (
                        <div className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${styles.pageBreak}`}>
                            {!empty(trx.details) && (
                                <React.Fragment>
                                    <table className={styles.headerTable}>
                                        <tbody>
                                        <tr>
                                            <td>Customer</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {customerName}
                                            </td>
                                        </tr>

                                        {posConfig.pos_print_user_identifier === 1 && !empty(trx.customer) && (
                                            <tr>
                                                <td>Customer ID</td>
                                                <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                    {trx.customer.user_identifier_string}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td>Cashier</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {posConfig.cashier_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Receipt Number</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.local_trx_id
                                                    .slice(-6)
                                                    .toUpperCase()}
                                            </td>
                                        </tr>
                                        {!empty(trx.order_no) && (
                                            <tr>
                                                <td>Order No</td>
                                                <td className={styles.alignRight}>
                                                    {trx.order_no}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td>
                                                {moment(
                                                    empty(trx.document_date) ? trx.trx_local_end_time : trx.document_date,
                                                    MOMENT_SQL_DATETIME_FORMAT
                                                ).format("D MMM YYYY")}
                                            </td>
                                            <td className={styles.alignRight}>
                                                {moment(
                                                    empty(trx.document_date) ? trx.trx_local_end_time : trx.document_date,
                                                    MOMENT_SQL_DATETIME_FORMAT
                                                ).format("HH:mm")}
                                            </td>
                                        </tr>

                                        {!empty(trx.service_area) && (
                                            <tr>
                                                <td>Area</td>
                                                <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                    {trx.service_area.area_name}{!empty(trx.service_area.areaCategory_name) && ` - ${trx.service_area.areaCategory_name}`}
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>

                                    <div className={styles.receiptDivider}/>

                                    <table className={styles.itemListTable}>
                                        <tbody>
                                        {trx.trx_type === TRX_TYPE_NORMAL &&
                                        posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END &&
                                        (<React.Fragment>
                                            {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") && (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <strong>SERVICES</strong>
                                                        </td>
                                                    </tr>
                                                    {this.renderTrxDetails(trx, false)}
                                                </React.Fragment>
                                            )}

                                            {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") &&
                                            trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div
                                                            className={
                                                                styles.receiptDivider
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            )}

                                            {trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <strong>ITEMS</strong>
                                                        </td>
                                                    </tr>
                                                    {this.renderTrxDetails(trx, true)}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>)}

                                        {(trx.trx_type !== TRX_TYPE_NORMAL ||
                                            (
                                                trx.trx_type === TRX_TYPE_NORMAL &&
                                                posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT
                                            )) && (this.renderTrxDetails(trx))}

                                        {copy && (
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div
                                                            className={
                                                                styles.receiptDivider
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div style={{
                                                            textAlign: "center",
                                                            fontSize: 18,
                                                            padding: "7px 0"
                                                        }}>
                                                            THIS IS A COPY
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                        </tbody>
                                    </table>

                                    {!empty(trx.trx_notes) && (
                                        <React.Fragment>
                                            <div className={styles.receiptDivider}/>
                                            <div>
                                                Notes
                                                <br/>
                                                {nl2brReact(trx.trx_notes)}
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {preSettlement && (
                                        <React.Fragment>
                                            <div className={styles.receiptDivider}/>
                                            <div style={{
                                                textAlign: "center",
                                                fontSize: 18,
                                                padding: "7px 0",
                                                lineHeight: "1em"
                                            }}>
                                                PRE-SETTLEMENT<br/>
                                                <span style={{
                                                    fontSize: 12
                                                }}>
                                            {moment().format(MOMENT_READABLE_DATETIME_FORMAT)}
                                        </span>
                                            </div>
                                            <div className={styles.receiptDivider}/>
                                        </React.Fragment>
                                    )}

                                    {!empty(trx.order_no) &&
                                    posConfig.pos_print_receipt_order_number_bottom === 1 &&
                                    (
                                        <div style={{ textAlign: "center", marginTop: 30 }}>
                                            <div>Order No</div>
                                            <div style={{
                                                fontSize: 50,
                                                lineHeight: "60px"
                                                // fontWeight: 'bold'
                                            }}>
                                                {trx.order_no}
                                            </div>
                                        </div>
                                    )}

                                    <br/>
                                </React.Fragment>
                            )}
                        </div>
                    )}

                    {configs.displayMode === "TABLET" && this.props.isLegit && (
                        <div className={styles.tabletActionButtons}>
                            <Button type={"primary"}
                                    size={"large"}
                                    onClick={() => {
                                        window.print();
                                    }}
                                    style={{ marginRight: 10 }}
                            >
                                Print Struk
                            </Button>

                            <Button type={"danger"}
                                    size={"large"}
                                    onClick={() => {
                                        window.location = "/pos";
                                    }}
                            >
                                Kembali
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        configs: state.authState.configs
    };
};

const mapDispatchToProps = {
    deleteTrxDetail: posOperations.deleteTrxDetail,
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosPriceListPublic);
