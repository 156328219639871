import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";

class Purgatory extends Component {
    componentDidMount() {
        localStorage.clear();
        setTimeout(() => {
            this.props.signOut();
            window.location.href = '/';
        }, 1000);
    }

    render() {
        return (
            <Spin spinning={true} tip="Clearing Storage.." />
        );
    }
}

const signOut = () => {
    return dispatch => {
        dispatch({
            type: 'USER_SIGNOUT',
        });
    };
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    signOut
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Purgatory);
