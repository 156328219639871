import React, { useEffect, useState } from "react";
import { Icon, Modal } from "antd";
import PropTypes from "prop-types";

const CaptureNfc = props => {
    const [nfcCode, setNfcCode] = useState('');

    useEffect(() => {
        const keypressListener = e => {
            if(props.opened){
                let code = e.key;
                console.log(nfcCode + code);
                setNfcCode(nfcCode + code);

                if (code === 'Enter') {
                    scan(nfcCode);
                    setNfcCode('');
                }
            }
        }

        window.addEventListener('keypress', keypressListener);

        return () => {
            window.removeEventListener('keypress', keypressListener);
        }
    });

    const scan = (code) => {
        // if(isNaN(code)){
        //     message.error('Invalid NFC Card!');
        //     return;
        // }

        // if(code.length < 10){
            // message.error('Invalid NFC Card!');
            // return;
        // }

        if(code.length >= 10){
            props.onScan(code);
        }
    }

    return (
        <Modal
            title="NFC Card Reader"
            footer={null}
            visible={props.opened}
            onCancel={props.onClose}
        >
            <div style={{
                textAlign: 'center',
                fontSize: 30,
                padding: '30px 0'
            }}>
                <Icon type="credit-card"
                      style={{
                          fontSize: 60
                      }}
                      theme="filled"
                />
                <br/>
                Scan kartu sekarang..
            </div>
        </Modal>
    );
};

CaptureNfc.propTypes = {
    onScan: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired
};

CaptureNfc.defaultProps = {
};

export default CaptureNfc;
