import React, { Component } from "react";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import { apiCall } from "../shared/helpers/networkHelper";
import { GET_REPORT_POS_ALL_TRX, GET_REPORT_POS_ALL_TRX_DETAIL, GET_REPORT_POS_GROUP_BY_ITEM } from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { Col, Empty, Row, Spin, Table } from "antd";
import { number_format } from "../shared/helpers/stringHelper";
import { connect } from "react-redux";

class ReportPosAllTrxPrint extends Component {

    constructor(props) {
        super(props);

        const url = new URL(window.location.href);

        this.state = {
            loading: false,
            colDef: null,
            trxs: null,
            reportDateFrom: url.searchParams.get("start_date"),
            reportDateTo: url.searchParams.get("end_date"),
            reportType: url.searchParams.get("report_type"),
            itemNature: url.searchParams.get("item_nature")
        };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        const { reportDateFrom, reportDateTo, itemNature, reportType } = this.state;

        this.loaderShow();

        if (reportType === "by-transaction") {
            apiCall(
                GET_REPORT_POS_ALL_TRX + `?start_date=${reportDateFrom}&end_date=${reportDateTo}&item_nature=${itemNature}`,
                "get",
                null,
                res => {
                    this.setState({
                        colDef: res.data.col_def,
                        trxs: res.data.payload
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                }, res => {
                    this.loaderHide();
                }
            );
        }

        if (reportType === "by-transaction-detail") {
            apiCall(
                GET_REPORT_POS_ALL_TRX_DETAIL + `?start_date=${reportDateFrom}&end_date=${reportDateTo}&item_nature=${itemNature}`,
                "get",
                null,
                res => {
                    this.setState({
                        colDef: res.data.col_def,
                        trxs: res.data.payload
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                }, res => {
                    this.loaderHide();
                }
            );
        }

        if (reportType === "by-product") {
            apiCall(
                GET_REPORT_POS_GROUP_BY_ITEM + `?start_date=${reportDateFrom}&end_date=${reportDateTo}&item_nature=${itemNature}`,
                "get",
                null,
                res => {
                    this.setState({
                        trxs: res.data
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                }, res => {
                    this.loaderHide();
                }
            );
        }
    }

    loaderShow = () => {
        this.setState({ loading: true });
    };

    loaderHide = () => {
        this.setState({ loading: false });
    };

    render() {
        const { loading, colDef, trxs, reportDateFrom, reportDateTo, reportType, itemNature } = this.state;

        let mappedColDef = colDef;
        let mappedTrxs = trxs;

        if (!empty(mappedTrxs)) {
            mappedTrxs = mappedTrxs.concat({
                ...mappedTrxs[0],
                fake: true
            });
        }

        if ((reportType === "by-transaction" || reportType === "by-transaction-detail") && !empty(mappedColDef)) {
            mappedColDef = mappedColDef.map(col => {
                if (col.type === "currency") {
                    return {
                        ...col,
                        dataIndex: col.key,
                        render: (value, row, index) => {
                            if (index === (mappedTrxs.length - 1)) {
                                const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue[col.key]) || 0) + accumulator;
                                return <strong>{this.currencyCode} {number_format(mappedTrxs.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                            }
                            return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                        }
                    };
                } else {
                    return {
                        ...col,
                        dataIndex: col.key,
                        render: (value, row, index) => {
                            if (index === (mappedTrxs.length - 1)) {
                                return ``;
                            }
                            return value;
                        }
                    };
                }
            });
        }

        if (reportType === "by-product" && !empty(trxs)) {
            mappedTrxs = mappedTrxs.map((trx, index) => {
                return {
                    no: index + 1,
                    ...trx
                };
            });

            mappedColDef = [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    render: (value, row, index) => {
                        if (index === (mappedTrxs.length - 1)) {
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Item Name",
                    dataIndex: "item_name",
                    key: "item_name",
                    render: (value, row, index) => {
                        if (index === (mappedTrxs.length - 1)) {
                            return ``;
                        }
                        return value + (!empty(row.variant_name) ? ` - ${row.variant_name}` : ``);
                    }
                },
                {
                    title: "Category",
                    dataIndex: "category_name",
                    key: "category_name",
                    render: (value, row, index) => {
                        if (index === (mappedTrxs.length - 1)) {
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Qty Terjual",
                    dataIndex: "qty",
                    key: "qty",
                    render: (value, row, index) => {
                        if (index === (mappedTrxs.length - 1)) {
                            const sumReducer = (accumulator, currentValue) => Math.floor(parseInt(currentValue.qty)) + accumulator;
                            return <strong>x{number_format(mappedTrxs.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `x${number_format(Math.floor(value), 0)}`;
                    }
                }
            ];
        }

        return (
            <Spin spinning={loading} tip="Loading..">
                <Row>
                    <Col>
                        <div style={{ padding: 10 }}>
                            {reportType === 'by-transaction' && (
                                <h1>Laporan Penjualan - Harian{empty(itemNature) ? ` (SERVICE & RETAIL)` : ` (${itemNature})`}</h1>
                            )}

                            {reportType === 'by-transaction-detail' && (
                                <h1>Laporan Penjualan - Detail{empty(itemNature) ? ` (SERVICE & RETAIL)` : ` (${itemNature})`}</h1>
                            )}

                            {reportType === 'by-product' && (
                                <h1>Laporan Penjualan - Berdasarkan {empty(itemNature) ? `SERVICE & RETAIL` : `${itemNature}`}</h1>
                            )}

                            <table>
                                <tbody>
                                <tr>
                                    <td>Outlet</td>
                                    <td style={{ width: 15, textAlign: "center" }}>:</td>
                                    <td>{this.props.posConfig.brand_name} - {this.props.posConfig.outlet_name}</td>
                                </tr>
                                <tr>
                                    <td>Kasir</td>
                                    <td style={{ width: 15, textAlign: "center" }}>:</td>
                                    <td>{this.props.posConfig.cashier_name}</td>
                                </tr>
                                <tr>
                                    <td>Jenis Produk</td>
                                    <td style={{width: 15, textAlign: 'center'}}>:</td>
                                    <td>{empty(itemNature)?`SERVICE & RETAIL`:itemNature}</td>
                                </tr>
                                <tr>
                                    <td>Tanggal Laporan</td>
                                    <td style={{ width: 15, textAlign: "center" }}>:</td>
                                    <td>{moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)} - {moment(reportDateTo, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td>Waktu Pembuatan Laporan</td>
                                    <td style={{ width: 15, textAlign: "center" }}>:</td>
                                    <td>{moment().format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                                </tr>
                                </tbody>
                            </table>

                            {reportType === 'by-transaction' && empty(mappedColDef) && <Empty style={{ maxWidth: 500, margin: "100px 0" }}/>}
                            {reportType === 'by-transaction' && !empty(mappedColDef) && (
                                <div style={{ margin: "50px 0" }}>
                                    <Table size="small" tableLayout="auto" rowKey="pos_sales_trx_head_id" columns={mappedColDef} dataSource={mappedTrxs} pagination={false}/>
                                </div>
                            )}

                            {reportType === 'by-transaction-detail' && empty(mappedColDef) && <Empty style={{ maxWidth: 500, margin: "100px 0" }}/>}
                            {reportType === 'by-transaction-detail' && !empty(mappedColDef) && (
                                <div style={{ margin: "50px 0" }}>
                                    <Table size="small" tableLayout="auto" rowKey="trx_id" columns={mappedColDef} dataSource={mappedTrxs} pagination={false}/>
                                </div>
                            )}

                            {reportType === 'by-product' && empty(trxs) && <Empty style={{ maxWidth: 500, margin: "100px 0" }}/>}
                            {reportType === 'by-product' && !empty(trxs) && (
                                <div style={{ margin: "50px 0" }}>
                                    <Table size="small" tableLayout="auto" rowKey="no" columns={mappedColDef} dataSource={mappedTrxs} pagination={false}/>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

export default connect(mapStateToProps)(ReportPosAllTrxPrint);