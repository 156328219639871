import { IMAGE_BASE_URL } from "../utils/constants";
import {empty} from "./generalHelper";
import { createInitials } from "./stringHelper";

export const prepImageUrl = (imageUrl, fallbackImage = '') => {
    if(!empty(imageUrl)){
        if (imageUrl.startsWith("http")) {
            return imageUrl;
        } else {
            return IMAGE_BASE_URL + imageUrl;
        }
    }else{
        if(!empty(fallbackImage)){
            return fallbackImage;
        }else{
            return false;
        }
    }
};

export const generateInitialDummyImageUrl = (str, size='200x200') => {
    const initials = createInitials(str);
    return `https://dummyimage.com/${size}/2fb1e5/ffffff.png&text=${initials}`;
};