import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message, Modal, Input } from 'antd';
import { posOperations } from '../../state/ducks/pos';

class PosTrxNotesModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            trx_notes: this.props.trx_notes
        };
    }

    onChangeNotes = e => {
        this.setState({
            trx_notes: e.target.value
        });
    };

    handleModalOk = () => {
        this.props.setTrxProps(this.props.currentLocalTrxId, {
            trx_notes: this.state.trx_notes
        });
        message.success('Transaction notes successfully saved!');
        this.props.close();
    };

    handleModalCancel = () => {
        this.props.close();
    };

    render() {
        const { trx_notes } = this.state;

        return (
            <Modal
                title="Transaction Notes"
                visible={this.props.modalOpened}
                onOk={this.handleModalOk}
                onCancel={this.handleModalCancel}
                cancelText="Cancel"
                okText="Save"
            >
                <h4 style={{ marginTop: 0 }}>Notes</h4>
                <Input.TextArea
                    autosize={{ minRows: 3 }}
                    placeholder="Catatan / Keterangan Transaksi"
                    onChange={this.onChangeNotes}
                    value={trx_notes}
                />
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    const currentTrx = state.posState.posTrx.find(
        trx => trx.local_trx_id === currentLocalTrxId
    );
    return {
        currentLocalTrxId,
        currentTrx,
        trx_notes: currentTrx.trx_notes
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosTrxNotesModal);
