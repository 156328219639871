import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    message,
    Modal,
    Input,
    Spin,
    Form,
    Button,
    Card,
    Empty,
    Radio,
    List
} from "antd";
import { posOperations } from '../../state/ducks/pos';
import * as endpoint from '../../shared/utils/endpoints';
import { apiCall } from '../../shared/helpers/networkHelper';
import { empty } from '../../shared/helpers/generalHelper';
import QrReader from 'react-qr-reader';
import styles from './PosCouponModal.module.scss';
import { MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";
import moment from "moment";
import ErrorHandler from "../../shared/classes/ErrorHandler";

class PosCouponModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            coupon_code: '',
            qr_coupon_data: null,
            coupon_item: null,
            customer_coupons: [],
            coupon_filter: '',
            couponTrxFilter: '',
            formLoading: false,
            redeemType: this.props.posConfig.cashier_indirect_coupon_redeem === 1 ? 'qr' : 'browse'
        };

        this.state = { ...this.initialState };

        // this.changeCouponFilter = debounce(this.changeCouponFilter, 500);
    }

    componentDidMount() {
        if(empty(this.state.customer_coupons) && !empty(this.props.currentTrx.customer) && !empty(this.props.currentTrx.customer.user_code)){
            this.loadUserCoupons();
        }
    }

    loadUserCoupons = () => {
        this.showModalLoading();

        apiCall(
            endpoint.POST_USER_COUPONS,
            'post',
            {
                user_code: this.props.currentTrx.customer.user_code
            },
            result => {
                this.setState(
                    {
                        customer_coupons: result.data.details
                    },
                    () => {
                        this.hideModalLoading();
                    }
                );
            },
            error => {
                ErrorHandler.handleGeneralError(error);
                this.hideModalLoading();
            }
        );
    };

    showModalLoading = () => {
        this.setState({ formLoading: true });
    };

    hideModalLoading = () => {
        this.setState({ formLoading: false });
    };

    handleModalOk = () => {
        let validForm = true;
        const { currentTrx } = this.props;
        const { coupon_item, coupon_code, qr_coupon_data } = this.state;

        if (empty(coupon_item)) {
            validForm = false;
            message.error('Kupon tidak valid.');
        }

        if (coupon_item.pos_link_type === 'item_category') {
            if(coupon_item.item_category_max_qty > 0){
                let total_qty = 0;
                currentTrx.details.forEach(detail => {
                    if(detail.itemMeta.pos_item_category_id === coupon_item.pos_item_category_id){
                        total_qty += detail.qty;
                    }
                });

                if(total_qty > coupon_item.item_category_max_qty){
                    validForm = false;
                    message.error('Jumlah barang yang memiliki potongan kategori kupon sudah melebihi jumlah yang diperbolehkan.');
                }
            }
        }

        if (validForm) {
            this.props.setTrxProps(this.props.currentLocalTrxId, {
                coupon: {
                    coupon_code: coupon_code,
                    coupon_item: coupon_item,
                    qr_coupon_data: qr_coupon_data
                }
            });
            message.success('Kupon berhasil diaplikasikan');

            if (coupon_item.pos_link_type === 'item') {
                this.props.close(coupon_item);
            } else {
                this.props.close();
            }

            this.setState({ ...this.initialState });
        }
    };

    handleModalCancel = () => {
        this.props.close();
    };

    handleCouponCodeQrScan = data => {
        if (data) {
            this.setState({ coupon_code: data }, () => {
                this.onSubmitQrCode(0);
            });
        }
    };

    handleCouponCodeQrError = err => {
        console.error(err);
    };

    onSubmitQrCode = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.coupon_code)) {
            message.error('Kode kupon harus diisi!');
            return;
        }

        this.showModalLoading();

        apiCall(
            endpoint.POST_COUPON_PREVIEW,
            'post',
            {
                qr_code: this.state.coupon_code
            },
            result => {
                this.setState(
                    {
                        step: 2,
                        coupon_item:
                            result.data.details[0].coupon_detail,
                        qr_coupon_data: result.data.details[0].coupon_detail
                    },
                    () => {
                        this.hideModalLoading();
                    }
                );
            },
            error => {
                ErrorHandler.handleGeneralError(error);
                this.hideModalLoading();
            }
        );
    };

    onCouponItemChosen = coupon_item => {
        this.showModalLoading();

        this.setState({
            step: 2,
            formLoading: false,
            coupon_code: coupon_item.coupon_detail.coupon_code,
            coupon_item: coupon_item.coupon_detail,
            qr_coupon_data: coupon_item.coupon_detail
        });
    };

    onChangeRedeemType = e => {
        this.setState({
            ...this.initialState,
            redeemType: e.target.value
        }, ()=>{
            if(e.target.value === 'agent'){
                this.loadAgentTrxs();
            }else if(e.target.value === 'browse'){
                this.loadUserCoupons();
            }
        });
    };

    loadAgentTrxs = () => {
        apiCall(
            endpoint.GET_AGENT_COUPON_REDEEM_TRXS + `?user_code=${this.props.currentTrx.customer.user_code}`,
            'get',
            null,
            response => {
                this.setState({
                    agentTrxs: response.data
                });
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    onAgentTrxChosen = agent_trx => {
        this.setState(
            {
                step: 2,
                formLoading: false,
                coupon_code: agent_trx.coupon_detail.coupon_code,
                coupon_item: agent_trx.coupon_detail,
                qr_coupon_data: agent_trx.coupon_detail
            },
            this.handleModalOk
        );
    };

    changeCouponFilter = e => {
        this.setState({coupon_filter: e.target.value});
    };

    render() {
        const {
            step,
            coupon_code,
            coupon_item,
            qr_coupon_data,
            coupon_filter,
            couponTrxFilter,
            customer_coupons,
            formLoading,
            redeemType,
            agentTrxs
        } = this.state;

        const{ posConfig } = this.props;

        return (
            <Modal
                title="Penggunaan Kupon"
                visible={this.props.modalOpened}
                onOk={this.handleModalOk}
                destroyOnClose={true}
                onCancel={this.handleModalCancel}
                cancelText="Cancel"
                okText="Apply"
                width={posConfig.cashier_indirect_coupon_redeem === 0 ? '75%' : 500}
            >
                <Spin spinning={formLoading}>
                    <div className={styles.formGroup}>
                        <h4 style={{ marginTop: 0 }}>Coupon Type</h4>
                        <Radio.Group
                            defaultValue={posConfig.cashier_indirect_coupon_redeem === 1 ? 'qr' : 'browse'}
                            buttonStyle="solid"
                            onChange={this.onChangeRedeemType}
                            disabled={!empty(qr_coupon_data)}
                        >
                            {posConfig.cashier_indirect_coupon_redeem === 0 && (
                                <Radio.Button value="browse">Browse</Radio.Button>
                            )}
                            {posConfig.cashier_indirect_coupon_redeem === 1 && (
                                <Radio.Button value="qr">QR</Radio.Button>
                            )}
                            <Radio.Button value="agent">Agent</Radio.Button>
                        </Radio.Group>
                    </div>

                    {posConfig.cashier_indirect_coupon_redeem === 1 && redeemType === 'qr' && step === 1 && (
                        <React.Fragment>
                            <Form onSubmit={this.onSubmitQrCode}>
                                <div className={styles.formGroup}>
                                    <h4>Scan QR Code</h4>
                                    <QrReader
                                        delay={3000}
                                        onScan={this.handleCouponCodeQrScan}
                                        onError={this.handleCouponCodeQrError}
                                        style={{
                                            width: 300,
                                            maxWidth: '100%',
                                            marginBottom: 10,
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    />

                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                coupon_code: e.target.value
                                            });
                                        }}
                                        placeholder="Coupon Code"
                                        value={coupon_code}
                                        disabled={coupon_item !== null}
                                        size="large"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        block={true}
                                        htmlType="submit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </React.Fragment>
                    )}

                    {posConfig.cashier_indirect_coupon_redeem === 1 && redeemType === 'qr' && step === 2 && (
                        <React.Fragment>
                            <table className={styles.previewTable}>
                                <tbody>
                                    {/*<tr>*/}
                                        {/*<td>Customer</td>*/}
                                        {/*<td>{qr_coupon_data.user_name}</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                        {/*<td>ID</td>*/}
                                        {/*<td>{qr_coupon_data.user_code}</td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td colSpan={2}>
                                            <div className={styles.previewItem}>
                                                <img
                                                    src={
                                                        qr_coupon_data.coupon_image
                                                    }
                                                    alt="Kupon yang akan dipakai"
                                                />
                                                <br />
                                                {qr_coupon_data.coupon_name}
                                                {!empty(qr_coupon_data.coupon_notes) && (
                                                    <React.Fragment>
                                                        <br/>
                                                        {qr_coupon_data.coupon_notes}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}

                    {posConfig.cashier_indirect_coupon_redeem === 0 && redeemType === 'browse' && step === 1 && (
                        <React.Fragment>
                            <h2>Pilih Kupon Customer</h2>
                            {empty(customer_coupons) && (
                                <Empty/>
                            )}

                            {!empty(customer_coupons) && (
                                <React.Fragment>
                                    <Input.Search type="text"
                                                  onChange={this.changeCouponFilter}
                                                  value={coupon_filter}
                                                  placeholder="Cari kupon.."
                                                  style={{
                                                      width: '500px',
                                                      maxWidth: '100%',
                                                      marginBottom: '20px'
                                                  }}
                                    />
                                    <div className={styles.couponList}>
                                    {
                                        customer_coupons.filter(item => {
                                            if(!empty(coupon_filter)){
                                                return item.coupon_name.toLowerCase().includes(coupon_filter.toLowerCase()) ||
                                                    item.coupon_detail.coupon_notes.toLowerCase().includes(coupon_filter.toLowerCase());
                                            }else{
                                                return item;
                                            }
                                        }).map(item => (
                                            <Card
                                                key={`Coupon${item.coupon_user_id}`}
                                                onClick={() => {
                                                    this.onCouponItemChosen(item);
                                                }}
                                                className={styles.couponListCard}
                                            >
                                                <div
                                                    className={styles.couponItemDiv}
                                                    style={{textAlign: 'center'}}
                                                >
                                                    <h3>{item.coupon_name}</h3>
                                                    <img src={item.coupon_image} alt="Coupon" />
                                                    {!empty(item.coupon_detail.coupon_notes) && (
                                                        <p>{item.coupon_detail.coupon_notes}</p>
                                                    )}
                                                    <p>Outlet: {item.coupon_detail.outlet_name}</p>
                                                    <p>Sampai {moment(item.expired_date, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</p>
                                                </div>
                                            </Card>
                                        ))
                                    }
                                    </div>
                                </React.Fragment>
                            )}

                        </React.Fragment>
                    )}

                    {posConfig.cashier_indirect_coupon_redeem === 0 && redeemType === 'browse' && step === 2 && (
                        <React.Fragment>
                            <h2>
                                Kupon berhasil diaplikasikan, silakan lanjutkan
                                transaksi.
                            </h2>
                        </React.Fragment>
                    )}

                    {redeemType === 'agent' && step === 1 && (
                        <React.Fragment>
                            <h2>Pilih Transaksi</h2>
                            <Input.Search type="text"
                                          onChange={e=>{this.setState({couponTrxFilter: e.target.value})}}
                                          value={couponTrxFilter}
                                          placeholder="Cari transaksi.."
                                          style={{
                                              width: '500px',
                                              maxWidth: '100%',
                                              marginBottom: '20px'
                                          }}
                            />
                            <List
                                grid={{
                                    gutter: 16,
                                    xxl: 1
                                }}
                                dataSource={empty(agentTrxs)?[]:agentTrxs.filter(row => {
                                    if(!empty(couponTrxFilter)){
                                        return String(row.pos_trx_code).toLowerCase().includes(String(couponTrxFilter).toLowerCase()) ||
                                            String(row.coupon_detail.coupon_name).toLowerCase().includes(String(couponTrxFilter).toLowerCase()) ||
                                            String(moment(row.coupon_detail.created_at, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)).toLowerCase().includes(String(couponTrxFilter).toLowerCase()) ||
                                            String(row.agent_code).toLowerCase().includes(String(couponTrxFilter).toLowerCase()) ||
                                            String(row.coupon_detail.coupon_notes).toLowerCase().includes(String(couponTrxFilter).toLowerCase()) ||
                                            String(row.coupon_detail.trx_id).toLowerCase().includes(String(couponTrxFilter).toLowerCase());
                                    }else{
                                        return row;
                                    }
                                })}
                                renderItem={item => (
                                    <List.Item
                                        key={item.coupon_detail.trx_id}
                                    >
                                        <Card
                                            onClick={() => {
                                                this.onAgentTrxChosen(item);
                                            }}
                                            hoverable={true}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div
                                                style={{textAlign: 'center'}}
                                            >
                                                <h3>{item.pos_trx_code}</h3>
                                                <img style={{maxWidth: '100%'}} src={item.coupon_detail.coupon_image} alt="Coupon" />
                                                <div style={{
                                                    lineHeight: '1.5em',
                                                    marginTop: 20
                                                }}>
                                                    {item.coupon_detail.coupon_name}
                                                    <br/>
                                                    Pada {moment(item.coupon_detail.created_at, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                                    <br/>
                                                    Agent: {item.agent_code}
                                                    {!empty(item.coupon_detail.coupon_notes) && (
                                                        <React.Fragment>
                                                            <br/>
                                                            {item.coupon_detail.coupon_notes}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </React.Fragment>
                    )}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    const currentTrx = state.posState.posTrx.find(
        trx => trx.local_trx_id === currentLocalTrxId
    );
    return {
        currentLocalTrxId,
        currentTrx,
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps,
    addTrxDetail: posOperations.addTrxDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosCouponModal);
