export const STORE_ITEMS = 'STORE_ITEMS';
export const STORE_ITEM_CATEGORIES = 'STORE_ITEM_CATEGORIES';
export const STORE_DISCOUNTS = 'STORE_DISCOUNTS';
export const STORE_COMMISSION_WORKERS = 'STORE_COMMISSION_WORKERS';

export const SET_NEW_LOCAL_TRX = 'SET_NEW_LOCAL_TRX';
export const ADD_TRX = 'ADD_TRX';
export const SWITCH_CURRENT_TRX = 'SWITCH_CURRENT_TRX';
export const RESET_TRX = 'RESET_TRX';
export const DELETE_TRX = 'DELETE_TRX';
export const DELETE_TRX_LOG = 'DELETE_TRX_LOG';
export const SET_TRX_CUSTOMER = 'SET_TRX_CUSTOMER';
export const SET_TRX_PROPS = 'SET_TRX_PROPS';
export const SET_TRX_STATES = 'SET_TRX_STATES';
export const ADD_TRX_GLOBAL_DISC = 'ADD_TRX_GLOBAL_DISC';
export const OVERWRITE_TRXS = 'OVERWRITE_TRXS';

export const POPULATE_APPOINTMENTS = 'POPULATE_APPOINTMENTS';
export const SET_NEW_APPOINTMENT = 'SET_NEW_APPOINTMENT';
export const EDIT_APPOINTMENT = 'EDIT_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';

export const ADD_NEW_TRX_DETAIL = 'ADD_NEW_TRX_DETAIL';
export const ADD_EXISTING_TRX_DETAIL = 'ADD_EXISTING_TRX_DETAIL';
export const EDIT_TRX_DETAIL = 'EDIT_TRX_DETAIL';
export const DELETE_TRX_DETAIL = 'DELETE_TRX_DETAIL';
export const MARK_TRX_DETAILS_AS_PRINTED = 'MARK_TRX_DETAILS_AS_PRINTED';
export const MODIFY_QTY_LOG = 'MODIFY_QTY_LOG';

export const SET_POS_CONFIG = 'SET_POS_CONFIG';
export const SET_POS_VARIABLES = 'SET_POS_VARIABLES';
