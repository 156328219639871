import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Icon, Input, message, Spin } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import { authOperations } from "../state/ducks/auth";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./AuthSignIn.module.scss";
import axios from "axios";
import imgGDCashierLogo from "../assets/images/gdcashier-logo-long.png";
import imgGDCashierLogo2x from "../assets/images/gdcashier-logo-long@2x.png";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { APP_VERSION, BUILD_NUMBER, MOMENT_SQL_DATETIME_FORMAT } from "../shared/utils/constants";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { posOperations } from "../state/ducks/pos";

class AuthSignIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            pageLoadingMessage: '',
            page: "sign-in",
            userEmail: "",
            userPassword: ""
        };
    }

    componentDidMount() {
        if (this.props.authenticated) {
            this.props.history.push(`/`);
        }

        if(localStorage.getItem('pending_sign_in') === '1'){
            this.showPageLoader('Melakukan proses sign-in..');
            this.onContinueConfirmSignIn(localStorage.getItem('login_token'));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.authenticated) {
            this.props.history.push(`/`);
        }
    }

    showPageLoader = (message='') => {
        this.setState({
            pageLoading: true,
            pageLoadingMessage: message
        });
    };

    hidePageLoader = () => {
        this.setState({
            pageLoading: false,
            pageLoadingMessage: ''
        });
    };

    onChangeInputTextUserEmail = e => {
        this.setState({
            userEmail: e.target.value
        });
    };

    onChangeInputTextUserPassword = e => {
        this.setState({
            userPassword: e.target.value
        });
    };

    onConfirmSignIn = (login_token, successCallback = () => {}, errorCallback = () => {}, retry = 3) => {
        apiCall(
            endpoint.POST_AUTH_SIGNIN_CONFIRM,
            "POST",
            {
                login_token
            },
            response => {
                successCallback();
            },
            response => {
                message.warning('Sign in gagal, mencoba kembali..');
                setTimeout(() => {
                    this.onConfirmSignIn(login_token, successCallback, errorCallback);
                }, 5000)
            }
        );
    };

    onContinueConfirmSignIn = (login_token) => {
        const {userData} = this.props;
        this.onConfirmSignIn(login_token,
            () => {
            const cashierCredentials = {
                business_user_id: userData.business_user_id,
                cashier_name: userData.cashier_name
            };
            if(empty(localStorage.getItem('sign_history'))){
                const sign_history = [cashierCredentials];
                localStorage.setItem('sign_history', JSON.stringify(sign_history));
            }else{
                let sign_history = JSON.parse(localStorage.getItem('sign_history'));
                if(!sign_history.find(row => row.business_user_id === cashierCredentials.business_user_id)){
                    sign_history = sign_history.concat(cashierCredentials);
                    localStorage.setItem('sign_history', JSON.stringify(sign_history));
                }
            }

            axios.defaults.headers.common["Authorization"] =
                localStorage.getItem('client_key');
            axios.defaults.headers.common["APP-Token"] =
                localStorage.getItem('login_token');
            axios.defaults.headers.common["Authorization-Type"] =
                "business_user";
            axios.defaults.headers.common["Platform"] = "web";

            localStorage.removeItem('pending_sign_in');

            this.props.signIn();
            this.hidePageLoader();

            if (empty(userData.outlet_id)) {
                this.props.history.push(`/choose-outlet`);
            } else {
                this.props.history.push(`/`);
            }
        },
            () => {
                message.error('Gagal sign-in, sedang mencoba kembali..');
            }, 3);
    };

    onSubmitSignIn = e => {
        e.preventDefault();
        e.stopPropagation();

        const {
            userEmail,
            userPassword
        } = this.state;
        let formValid = true;

        if (empty(userEmail)) {
            formValid = false;
            message.error("Email harus diisi!");
        }

        if (empty(userPassword)) {
            formValid = false;
            message.error("Password harus diisi!");
        }

        if(!navigator.onLine){
            formValid = false;
            message.error('Tidak ada koneksi internet! Silakan cek dan coba kembali');
        }

        if (formValid) {
            this.showPageLoader('Melakukan proses sign-in..');

            const formData = {
                email: userEmail,
                password: userPassword,
                subscription_user_type_status: "cashier"
            };

            apiCall(
                endpoint.POST_AUTH_SIGNIN,
                "POST",
                formData,
                response => {
                    localStorage.setItem("login_token", response.data.login_token);
                    localStorage.setItem("client_key", response.data.client_key);
                    localStorage.setItem("signed_in_at", moment().format(MOMENT_SQL_DATETIME_FORMAT));

                    localStorage.setItem("pending_sign_in", '1');

                    this.props.setUserData({
                        ...response.data,
                        signed_in_at: moment().format(MOMENT_SQL_DATETIME_FORMAT)
                    });
                    this.props.setCashierConfigs(response.data.configs);

                    this.onConfirmSignIn(response.data.login_token, () => {
                        const cashierCredentials = {
                            business_user_id: response.data.business_user_id,
                            cashier_name: response.data.cashier_name
                        };
                        if(empty(localStorage.getItem('sign_history'))){
                            const sign_history = [cashierCredentials];
                            localStorage.setItem('sign_history', JSON.stringify(sign_history));
                        }else{
                            let sign_history = JSON.parse(localStorage.getItem('sign_history'));
                            if(!sign_history.find(row => row.business_user_id === cashierCredentials.business_user_id)){
                                sign_history = sign_history.concat(cashierCredentials);
                                localStorage.setItem('sign_history', JSON.stringify(sign_history));
                            }
                        }

                        axios.defaults.headers.common["Authorization"] =
                            response.data.client_key;
                        axios.defaults.headers.common["APP-Token"] =
                            response.data.login_token;
                        axios.defaults.headers.common["Authorization-Type"] =
                            "business_user";
                        axios.defaults.headers.common["Platform"] = "web";
                        axios.defaults.headers.common["App-Version"] = APP_VERSION;
                        axios.defaults.headers.common["Build-Number"] = BUILD_NUMBER;

                        localStorage.removeItem('pending_sign_in');
                        localStorage.removeItem('hasSignedOut');
                        localStorage.removeItem('signed_out');

                        this.props.signIn();

                        apiCall(
                            endpoint.REST_APPOINTMENT,
                            "get",
                            null,
                            result => {
                                if(!empty(result.data.customer_appointments)){
                                    const appointments = result.data.customer_appointments;
                                    this.props.populateAppointments(appointments);

                                    appointments.map(appointment => {
                                        if(!empty(appointment.pos_trx)){
                                            this.props.addTrx(appointment.pos_trx);
                                        }
                                        return appointment;
                                    });
                                }

                                if (empty(response.data.outlet_id)) {
                                    this.props.history.push(`/choose-outlet`);
                                } else {
                                    this.props.history.push(`/`);
                                }
                            },
                            error => {
                                ErrorHandler.handleGeneralError(error);
                            },
                            res => {
                                this.hidePageLoader();
                            }
                        );



                    }, () => {
                        message.error('Gagal sign-in, sedang mencoba kembali..');
                    }, 3);
                },
                response => {
                    this.hidePageLoader();
                    ErrorHandler.handleGeneralError(response);
                }
            );
        }
    };

    onSubmitForgotPassword = e => {
        e.preventDefault();
        e.stopPropagation();

        const { userEmail } = this.state;
        let formValid = true;

        if (empty(userEmail)) {
            formValid = false;
            message.error("Email harus diisi!");
        }

        if (formValid) {
            this.showPageLoader('Mohon menunggu..');

            const formData = {
                email: userEmail,
                subscription_user_type_status: "cashier"
            };

            apiCall(
                endpoint.POST_AUTH_FORGOT_PASSWORD,
                "POST",
                formData,
                response => {
                    message.success(response.data.message);
                    this.setState({
                        pageLoading: false,
                        page: "sign-in"
                    });
                },
                response => {
                    this.hidePageLoader();
                    ErrorHandler.handleGeneralError(response);
                }
            );
        }
    };

    render() {
        const {
            pageLoading,
            pageLoadingMessage,
            page,
            userEmail,
            userPassword
        } = this.state;

        return (
            <Spin spinning={pageLoading} tip={pageLoadingMessage}>
                <div className={styles.versioning}>
                    Version {APP_VERSION} Build {BUILD_NUMBER}
                </div>
                <div className={styles.wrapper}>
                    <img
                        className={styles.logo}
                        alt="GD Cashier Logo"
                        src={imgGDCashierLogo}
                        srcSet={`${imgGDCashierLogo} 1x, ${imgGDCashierLogo2x} 2x`}
                    />

                    <Card className={styles.containerCard}>
                        {page === "sign-in" && (
                            <form onSubmit={this.onSubmitSignIn}>
                                <h3 className={styles.pageTitle}>
                                    Sign In
                                </h3>
                                <Input
                                    className={styles.inputText}
                                    prefix={
                                        <Icon
                                            type="mail"
                                            style={{
                                                color: "rgba(0,0,0,.25)"
                                            }}
                                        />
                                    }
                                    type="email"
                                    onChange={
                                        this.onChangeInputTextUserEmail
                                    }
                                    value={userEmail}
                                    placeholder="Email"
                                />
                                <Input
                                    className={styles.inputText}
                                    prefix={
                                        <Icon
                                            type="lock"
                                            style={{
                                                color: "rgba(0,0,0,.25)"
                                            }}
                                        />
                                    }
                                    type="password"
                                    onChange={
                                        this.onChangeInputTextUserPassword
                                    }
                                    value={userPassword}
                                    placeholder="Password"
                                />
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.submitBtn}
                                    block
                                >
                                    Sign In
                                </Button>
                                <div className={styles.forgotPassword}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                page: "forgot-password"
                                            });
                                        }}
                                    >
                                        Forgot password?
                                    </button>
                                </div>
                            </form>
                        )}

                        {page === "forgot-password" && (
                            <form onSubmit={this.onSubmitForgotPassword}>
                                <h3 className={styles.pageTitle}>
                                    Reset Password
                                </h3>
                                <Input
                                    className={styles.inputText}
                                    prefix={
                                        <Icon
                                            type="mail"
                                            style={{
                                                color: "rgba(0,0,0,.25)"
                                            }}
                                        />
                                    }
                                    type="email"
                                    onChange={
                                        this.onChangeInputTextUserEmail
                                    }
                                    value={userEmail}
                                    placeholder="Email"
                                />
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.submitBtn}
                                    block
                                >
                                    Send Reset Link to Email
                                </Button>
                                <div className={styles.backToSignIn}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                page: "sign-in"
                                            });
                                        }}
                                    >
                                        Back to Sign In
                                    </button>
                                </div>
                            </form>
                        )}
                    </Card>
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.authState.authenticated,
        userData: state.authState.userData,
        states: state.authState.states,
        posTrxs: state.posState.posTrx
    };
};

const mapDispatchToProps = {
    signIn: authOperations.signIn,
    setUserData: authOperations.setUserData,
    setCashierConfigs: authOperations.setCashierConfigs,
    populateAppointments: posOperations.populateAppointments,
    addTrx: posOperations.addTrx
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthSignIn));
