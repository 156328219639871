import React, { useState } from "react";
import { connect } from "react-redux";
import { authOperations } from "../state/ducks/auth";
import { posOperations } from "../state/ducks/pos";
import { message } from "antd";
import { empty } from "../shared/helpers/generalHelper";


const Backdoor = (props) => {
    const [page, setPage] = useState('login');
    const [password, setPassword] = useState('');
    const [localStorageItemName, setLocalStorageItemName] = useState('');
    const [localStorageItemValue, setLocalStorageItemValue] = useState('');
    const [posTrxsString, setPosTrxsString] = useState(JSON.stringify(props.posTrx));

    const login = e => {
        e.preventDefault();
        e.stopPropagation();

        if(password === 'backdoornihyee'){
            setPage('bd');
        }else{
            message.error('Ngarep.com');
        }
    }

    const restoreToken = () => {
        localStorage.setItem("login_token", props.userData?.login_token);
        localStorage.setItem("client_key", props.userData?.client_key);
        localStorage.setItem("signed_in_at", props.userData?.signed_in_at);

        message.success('Token has been restored, please refresh the page');
    }

    const addStorageItem = () => {
        if(empty(localStorageItemName)){
            message.error('Local Storage Item Name is Required!');
            return;
        }

        if(window.confirm('Are you sure?')){
            localStorage.setItem(localStorageItemName, localStorageItemValue);
            message.success('Value has been added / edited');
            setLocalStorageItemName('');
            setLocalStorageItemValue('');
        }
    }

    const removeStorageItem = () => {
        if(empty(localStorageItemName)){
            message.error('Local Storage Item Name is Required!');
            return;
        }

        if(window.confirm('Are you sure?')){
            localStorage.removeItem(localStorageItemName);
            message.success('Local Storage Item has been removed');
            setLocalStorageItemName('');
            setLocalStorageItemValue('');
        }
    }

    const overwriteTrxs = () => {
        if(window.confirm('Are you sure?')){
            props.overwriteTrxs(JSON.parse(posTrxsString));
        }
    }

    if(page === 'login'){
        return <div style={{padding: 50}}>
            <form action={'#'} onSubmit={login}>
                <input type={"password"}
                       onChange={e => setPassword(e.target.value)}
                       placeholder={"Who is this?"}
                />
                <button type={"submit"}>Submit</button>
            </form>

            <br/><br/><br/>
            <a href={'/'}>Back to App</a>
            <br/><br/><br/>
        </div>;
    }

    if(page === 'bd'){
        return <div style={{padding: 50}}>

            <strong>ALL STATE</strong><br/>
            <textarea style={{width: '80%'}} rows={15} value={JSON.stringify(props.allState)} readOnly={true} /><br/>
            <br/>

            <strong>POS TRXS</strong><br/>
            <textarea style={{width: '80%'}} rows={15} value={JSON.stringify(props.posTrx)} readOnly={true} /><br/>
            <br/>

            <strong>USER CONFIG</strong><br/>
            <textarea style={{width: '80%'}} rows={15} value={JSON.stringify(props.userData)} readOnly={true} /><br/>
            <br/>

            <strong>AUTH TOKEN</strong><br/>
            <button style={{cursor: 'pointer'}} type={"button"} onClick={restoreToken}>Restore Token</button><br/>
            <br/>

            <strong>CHANGE / ADD LOCAL STORAGE VALUES</strong><br/>
            <input type={"text"}
                   onChange={e => setLocalStorageItemName(e.target.value)}
                   value={localStorageItemName}
                   placeholder={"LocalStorage Item Key"}
            />
            <input type={"text"}
                   onChange={e => setLocalStorageItemValue(e.target.value)}
                   value={localStorageItemValue}
                   placeholder={"Value"}
            />
            <button style={{cursor: 'pointer'}} type={"button"} onClick={addStorageItem}>Change / Add Value</button>
            <button style={{cursor: 'pointer'}} type={"button"} onClick={removeStorageItem}>Remove Item</button><br/>

            <br/>
            <strong>MODIFY POS TRX STATE</strong><br/>
            <textarea style={{width: '80%'}} rows={15} value={posTrxsString} onChange={e => { setPosTrxsString(e.target.value) }} /><br/>
            <button style={{cursor: 'pointer'}} type={"button"} onClick={overwriteTrxs}>Change Value</button>

            <br/><br/><br/>
            <a href={'/'}>Back to App</a>
            <br/><br/><br/>
        </div>
    }

}

const mapStateToProps = state => {
    return {
        allState: state,
        posTrx: state.posState.posTrx,
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    setUserData: authOperations.setUserData,
    setTrxProps: posOperations.setTrxProps,
    overwriteTrxs: posOperations.overwriteTrxs
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Backdoor);