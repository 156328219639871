import { combineReducers } from 'redux';
import * as types from './types';
import { now } from "../../../shared/helpers/dateHelper";
import { empty } from "../../../shared/helpers/generalHelper";

/* State shape
state: {
    settings: {
    },
    posItems: [{
        pos_item_id: 0,
        pos_item_category_id: 0,
        category_name: '',
        item_name: '',
        item_price: 0.00,
        item_desc: '',
        item_image: '',
        variants: [{
            sku: '',
            variant_name: '',
            variant_price: '',
            commission_type: '',
            commission_value: 0.00
        }],
        modifiers: [{
            modifier_name: '',
            modifier_type: '',
            options: [{
                option_name: '',
                option_price: 0.00,
                commission_type: '',
                commission_value: 0.00
            }]
        }],
        discount: {
            discount_type: '',
            discount_name: '',
            deduction_type: '',
            deduction_value: 0,
            promo_id: 0,
            req_pos_item_id: 0,
            req_item_min_qty: 0,
            req_pos_payment_method_id: 0,
            req_payment_method_min_value: 0,
            xy_base: '',
            x_pos_item_id: 0,
            x_pos_item_category_id: 0,
            x_min_qty: 0,
            y_pos_item_id: 0,
            y_qty: 0,
            start_date: '',
            end_date: '',
            availability_type: '',
            is_all_day: 0,
            available_days: [{
                day_id: 0,
                open_hour: '',
                close_hour: ''
            }],
            special_dates: [{
                day: '',
                start_time: '',
                end_time: ''
            }]
        },
        is_commission_active: 0
    }],
    posItemCategories: [{
        pos_item_category_id: 0,
        category_name: ''
    }]
}
*/

const posItemsInitialState = [];
const posItems = (state = posItemsInitialState, action) => {
    switch (action.type) {
        case types.STORE_ITEMS:
            return [...action.payload.items];

        default:
            return state;
    }
};

const posItemCategoriesInitialState = [];
const posItemCategories = (state = posItemCategoriesInitialState, action) => {
    switch (action.type) {
        case types.STORE_ITEM_CATEGORIES:
            return [...action.payload.itemCategories];

        default:
            return state;
    }
};

const posDiscountsInitialState = [];
const posDiscounts = (state = posDiscountsInitialState, action) => {
    switch (action.type) {
        case types.STORE_DISCOUNTS:
            return [...action.payload.discounts];

        default:
            return state;
    }
};

const posCommissionWorkersInitialState = [];
const posCommissionWorkers = (
    state = posCommissionWorkersInitialState,
    action
) => {
    switch (action.type) {
        case types.STORE_COMMISSION_WORKERS:
            return [...action.payload.commission_worker];

        default:
            return state;
    }
};

const appointmentInitialState = [];
const appointments = (state = [], action) => {
    switch (action.type) {
        case types.POPULATE_APPOINTMENTS:
            return state.concat([...action.payload]);

        case types.SET_NEW_APPOINTMENT:
            return state.concat({
                ...appointmentInitialState,
                ...action.payload
            });

        case types.EDIT_APPOINTMENT:
            return state.map(appointment => {
                if (appointment.local_appointment_id === action.payload.local_appointment_id) {
                    return {
                        ...appointment,
                        ...action.payload.newProps
                    };
                } else {
                    return appointment;
                }
            });

        case types.DELETE_APPOINTMENT:
            return state.filter(
                appointment => appointment.local_appointment_id !== action.payload.local_appointment_id
            );

        default:
            return state;
    }
};

const posTrxInitialState = {
    pos_sales_trx_head_id: '',
    local_trx_id: '',
    trx_id: '',
    trx_status_id: 2,
    sync_token: '',
    sync_time: '',
    is_synced: false,
    customer: null,
    trx_label: '',
    trx_notes: '',
    details: [],
    discounts: [],
    drv_gross_total: 0,
    drv_subtotal: 0,
    drv_total_discount_nominal: 0,
    drv_total_service_charge: 0,
    drv_total_additional_charge: 0,
    drv_grand_total: 0,
    payments: [],
    payment_method: [],
    payment_amount: 0,
    payment_ref: '',
    trx_local_start_time: '',
    redeem: null,
    states: {
        globalDiscountType: 'promo',
        globalDiscountPercent: 0,
        globalDiscountNominal: 0,
        globalDiscountItemId: ''
    }
};
const posTrx = (state = [], action) => {
    switch (action.type) {
        case types.SET_NEW_LOCAL_TRX:
            return state.concat({
                ...posTrxInitialState,
                ...action.payload
            });

        case types.ADD_TRX:
            return state.concat({
                ...action.payload
            });

        case types.ADD_NEW_TRX_DETAIL:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        details: trx.details.concat({
                            local_trx_detail_id:
                                action.payload.local_trx_detail_id,
                            itemMeta: action.payload.itemMeta,
                            ...action.payload.itemData
                        }),
                        payments: []
                    };
                } else {
                    return trx;
                }
            });

        case types.ADD_EXISTING_TRX_DETAIL:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        details: trx.details.map(detail => {
                            if (
                                detail.local_trx_detail_id ===
                                action.payload.local_trx_detail_id
                            ) {
                                return {
                                    ...detail,
                                    qty:
                                        detail.qty + action.payload.itemData.qty
                                };
                            } else {
                                return detail;
                            }
                        }),
                        payments: []
                    };
                } else {
                    return trx;
                }
            });

        case types.EDIT_TRX_DETAIL:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        details: trx.details.map(detail => {
                            if (
                                detail.local_trx_detail_id ===
                                action.payload.local_trx_detail_id
                            ) {
                                return action.payload.detail;
                            } else {
                                return detail;
                            }
                        }),
                        payments: []
                    };
                } else {
                    return trx;
                }
            });

        case types.MARK_TRX_DETAILS_AS_PRINTED:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        details: trx.details.map(detail => {
                            return {
                                ...detail,
                                last_printed_at: now()
                            };
                        })
                    };
                } else {
                    return trx;
                }
            });

        case types.MODIFY_QTY_LOG:
            return state.map(trx => {
            if (trx.local_trx_id === action.payload.local_trx_id) {
                let deletedDetails = trx.deleted_details || [];
                const deletedDetail = action.payload.deletedDetail;
                if(!empty(deletedDetail)){
                    deletedDetails = deletedDetails.concat({
                        ...deletedDetail,
                        actionType: action.payload.actionType
                    });
                }

                return {
                    ...trx,
                    deleted_details: deletedDetails
                };
            } else {
                return trx;
            }
        });

        case types.DELETE_TRX_DETAIL:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    let deletedDetails = trx.deleted_details || [];
                    const deletedDetail = action.payload.deletedDetail;
                    if(!empty(deletedDetail)){
                        deletedDetails = deletedDetails.concat({
                            ...deletedDetail,
                            actionType: action.payload.actionType
                        });
                    }

                    return {
                        ...trx,
                        details: trx.details.filter(
                            detail =>
                                detail.local_trx_detail_id !==
                                action.payload.local_trx_detail_id
                        ),
                        deleted_details: deletedDetails,
                        payments: []
                    };
                } else {
                    return trx;
                }
            });

        case types.ADD_TRX_GLOBAL_DISC:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        discounts: trx.discounts
                            .filter(
                                discount =>
                                    discount.discount_type !== 'basic' &&
                                    discount.discount_type !== 'custom'
                            )
                            .concat(action.payload.discountItem),
                        payments: []
                    }
                }else{
                    return trx;
                }
            });

        case types.SET_TRX_CUSTOMER:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    if (action.payload.customerData === null) {
                        return {
                            ...trx,
                            customer: null
                        };
                    } else {
                        return {
                            ...trx,
                            customer: { ...action.payload.customerData }
                        };
                    }
                }else{
                    return trx;
                }
            });

        case types.SET_TRX_PROPS:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        ...action.payload.newProps
                    };
                } else {
                    return trx;
                }
            });

        case types.SET_TRX_STATES:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...trx,
                        states: {
                            ...trx.states,
                            ...action.payload.newStates
                        }
                    };
                } else {
                    return trx;
                }
            });

        case types.RESET_TRX:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.local_trx_id) {
                    return {
                        ...posTrxInitialState,
                        local_trx_id: trx.local_trx_id,
                        trx_local_start_time: trx.trx_local_start_time
                    };
                } else {
                    return trx;
                }
            });

        case types.DELETE_TRX:
            return state.filter(
                trx => trx.local_trx_id !== action.payload.local_trx_id
            );

        case types.OVERWRITE_TRXS:
            return action.payload.trxs;

        default:
            return state;
    }
};

const deletedTrxsInitialState = [];
const deletedTrxs = (state = deletedTrxsInitialState, action) => {
    switch (action.type) {
        case types.DELETE_TRX_LOG:
            return state.concat(action.payload.trx);

        default:
            return state;
    }
};

const posVariablesInitialState = {
    currentLocalTrxId: null
};
const posVariables = (state = posVariablesInitialState, action) => {
    switch (action.type) {
        case types.SET_NEW_LOCAL_TRX:
            return {
                ...state,
                currentLocalTrxId: action.payload.local_trx_id
            };

        case types.SWITCH_CURRENT_TRX:
            return {
                ...state,
                currentLocalTrxId: action.payload.local_trx_id
            };

        case types.DELETE_TRX:
            return {
                ...state,
                currentLocalTrxId: null
            };

        case types.SET_POS_VARIABLES:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};

const posConfigInitialState = {
    isLoaded: false,
    business_slug: '',
    brand_name: '',
    brand_logo: '',
    tax: null,
    service_charge: [],
    additional_charge: [],
    payment_method_global: [],
    payment_method: [],
    sales_type: [],
    commission_worker: [],
    retrieve_time: ''
};
const posConfig = (state = posConfigInitialState, action) => {
    switch (action.type) {
        case types.SET_POS_CONFIG:
            return {
                ...state,
                ...action.payload,
                isLoaded: true
            };

        default:
            return state;
    }
};

const posReducer = combineReducers({
    posItems,
    posItemCategories,
    posDiscounts,
    posCommissionWorkers,
    appointments,
    posTrx,
    deletedTrxs,
    posVariables,
    posConfig
});

export default posReducer;
