import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Checkbox, DatePicker, Divider, Icon, Input, InputNumber, message, Modal, Radio, Select, Typography } from "antd";
import { posOperations } from "../../state/ducks/pos";
import { empty } from "../../shared/helpers/generalHelper";
import styles from "../PosItemModal/PosItemModal.module.scss";
import { number_format, processDecimalInput } from "../../shared/helpers/stringHelper";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT, MOMENT_SQL_TIME_FORMAT_MINUTE, TRX_TYPE_NORMAL, TRX_TYPE_COUPON_SALES } from "../../shared/utils/constants";
import moment from "moment";
import { GREEN, ORANGE, RED } from "../../shared/utils/colors";
import { now } from "../../shared/helpers/dateHelper";
import { apiCall } from "../../shared/helpers/networkHelper";
import { POST_POS_TRX } from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import * as endpoint from "../../shared/utils/endpoints";

class PosItemModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        item: PropTypes.object,
        itemType: PropTypes.string,
        trxDetail: PropTypes.object,
        closable: PropTypes.bool,
        onCancel: PropTypes.func,
        redeem: PropTypes.object,
        coupon: PropTypes.object,
        postEditMode: PropTypes.bool,
        postEditSessionId: PropTypes.string,
        postEditOnSubmit: PropTypes.func
    };

    static defaultProps = {
        item: null,
        itemType: "normal",
        trxDetail: null,
        closable: true,
        onCancel: null,
        redeem: null,
        coupon: null,
        postEditMode: false,
        postEditSessionId: "",
        postEditOnSubmit: () => {
        }
    };

    constructor(props) {
        super(props);

        const { posConfig, item } = this.props;

        let currentTrx = this.props.currentTrx;
        if (empty(currentTrx)) {
            currentTrx = this.props.trx;
        }

        if (empty(this.props.trxDetail)) {
            this.initialState = {
                formLoading: false,
                modalItem: item,
                modalItemQty: 1,
                modalItemQtyText: "1",
                modalItemQtyMax: 0,
                modalItemVariantId: 0,
                modalItemDefinedDiscounts: [],
                modalItemSalesTypeId: empty(currentTrx.pos_salesType_id) ? posConfig.sales_type[0].pos_salesType_id : currentTrx.pos_salesType_id,
                modalItemDiscountType: "percentage",
                modalItemDiscountPercent: 0,
                modalItemDiscountValue: 0,
                modalItemDiscountValueText: "0",
                modalItemNotes: "",
                modalItemSecretNotes: "",
                modalCouponActivationDate: moment().format(MOMENT_SQL_DATE_FORMAT),
                modalCouponNotes: "",
                modalItemPackageDetails: this.props.item.pos_item_package_details.map((detail, index) => {
                    return {
                        ...detail,
                        detailIndex: index,
                        commission_worker: null,
                        commission_workers: [],
                        pos_item_package_detail_variants:
                            detail.is_all_variant === 1
                                ? detail.all_pos_item_package_detail_variants.map(
                                    (variant, index) => {
                                        if (index === 0) {
                                            return {
                                                ...variant,
                                                is_chosen: 1
                                            };
                                        } else {
                                            return {
                                                ...variant,
                                                is_chosen: 0
                                            };
                                        }
                                    }
                                )
                                : detail.pos_item_package_detail_variants.map(
                                    (variant, index) => {
                                        if (index === 0) {
                                            return {
                                                ...variant,
                                                is_chosen: 1
                                            };
                                        } else {
                                            return {
                                                ...variant,
                                                is_chosen: 0
                                            };
                                        }
                                    }
                                )
                    };
                }),
                modalItemCommissionWorker: null,
                modalItemCommissionWorkers: [],
                multiCommissionWorkerPerson: null,
                multiCommissionWorkerPercent: 100,
                modalItemCommissionWorkersPackage: [],
                multiCommissionWorkerPackage: [],
                itemSellers: [],
                modalItemModifiers: [],
                modalItemServiceArea: null,
                redeem: this.props.redeem,
                coupon: this.props.coupon,
                customPriceEditMode: false,
                customPriceValue: 0,
                modifyQtyModalOpened: false,
                modifyQtyModalLoading: false,
                modifyQtyPassword: "",
                modifyQtyReason: ""
            };
        } else {
            const { trxDetail } = this.props;

            const reducer = (accumulator, currentValue) =>
                parseInt(currentValue.percentage) + accumulator;
            const totalCommission = trxDetail?.commission_workers?.reduce(reducer, 0);
            const commissionRemainder = 100 - parseInt(totalCommission);

            this.initialState = {
                formLoading: false,
                modalItem: item,
                initialModalItemQty: trxDetail.qty,
                modalItemQty: trxDetail.qty,
                modalItemQtyText: `${trxDetail.qty}`,
                modalItemQtyMax: 0,
                modalItemVariantId: trxDetail.pos_item_variant_id,
                modalItemSalesTypeId: null,
                modalItemDefinedDiscounts: trxDetail.pos_item_discounts,
                modalItemDiscountType: trxDetail.custom_discount_type,
                modalItemDiscountPercent: trxDetail.custom_discount_value,
                modalItemDiscountValue: trxDetail.custom_discount_value,
                modalItemDiscountValueText: trxDetail.custom_discount_value,
                modalItemNotes: trxDetail.notes,
                modalItemSecretNotes: trxDetail.secret_notes,
                modalCouponActivationDate: trxDetail.coupon_activation_date,
                modalCouponNotes: trxDetail.coupon_notes,
                modalItemPackageDetails: trxDetail.pos_item_package_details,
                modalItemCommissionWorker: trxDetail.commission_worker,
                modalItemCommissionWorkers: trxDetail.commission_workers,
                multiCommissionWorkerPerson: null,
                multiCommissionWorkerPercent: commissionRemainder,
                modalItemCommissionWorkersPackage: [],
                multiCommissionWorkerPackage: [],
                itemSellers: trxDetail.item_sellers,
                modalItemModifiers: trxDetail.pos_item_pos_item_modifiers,
                modalItemServiceArea: trxDetail.service_area,
                redeem: trxDetail.redeem,
                coupon: trxDetail.coupon,
                customPriceEditMode: false,
                customPriceValue: 0,
                modifyQtyModalOpened: false,
                modifyQtyModalLoading: false,
                modifyQtyPassword: "",
                modifyQtyReason: "",
                modifyQtyPassed: false
            };
        }

        this.state = { ...this.initialState };
        this.currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;

        this.customPriceInput = React.createRef();

        // HANDLE INITIAL CURSOR FOCUS
        this.qtyInput = null;
        this.setQtyInputRef = element => {
            this.qtyInput = element;
        };
        this.focusQtyInput = () => {
            // Focus the text input using the raw DOM API
            if (this.qtyInput) {
                this.qtyInput.focus();
                this.qtyInput.input.setSelectionRange(0, 100);
            }
        };
    }

    packageDetailStateMapper = pos_item_package_details => {
        pos_item_package_details.map(detail => {
            return {
                ...detail,
                business_commission_worker: null,
                business_commission_workers: [],
                pos_item_package_detail_variants:
                    detail.is_all_variant === 1
                        ? detail.all_pos_item_package_detail_variants.map(
                            (variant, index) => {
                                if (index === 0) {
                                    return {
                                        ...variant,
                                        is_chosen: 1
                                    };
                                } else {
                                    return {
                                        ...variant,
                                        is_chosen: 0
                                    };
                                }
                            }
                        )
                        : detail.pos_item_package_detail_variants.map(
                            (variant, index) => {
                                if (index === 0) {
                                    return {
                                        ...variant,
                                        is_chosen: 1
                                    };
                                } else {
                                    return {
                                        ...variant,
                                        is_chosen: 0
                                    };
                                }
                            }
                        )
            };
        });
    };

    componentDidMount() {
        if (this.props.appConfig.displayMode === "DESKTOP" || empty(this.props.appConfig.displayMode)) {
            setTimeout(this.focusQtyInput, 300);
        }

        if (!empty(this.state.redeem)) {
            this.setState({
                modalItemQtyMax: this.state.redeem.item_max_qty
            });
        }

        if (!empty(this.state.coupon)) {
            this.setState({
                modalItemQtyMax: this.state.coupon.item_max_qty
            });
        }
    }

    validateModifyQty = () => {
        if (empty(this.state.modifyQtyPassword)) {
            message.error("Password harus diisi!");
            return;
        }

        if (empty(this.state.modifyQtyReason)) {
            message.error("Alasan pengurangan qty harus diisi!");
            return;
        }

        this.setState({ modifyQtyModalLoading: true });
        apiCall(
            endpoint.POST_PIN_VALIDATION,
            "post",
            {
                password: this.state.modifyQtyPassword
            },
            res => {
                this.props.logModifyTrxDetail(
                    this.props.currentLocalTrxId,
                    this.props.trxDetail.local_trx_detail_id,
                    {
                        ...this.props.trxDetail,
                        initial_qty: this.state.initialModalItemQty,
                        modified_qty: this.state.modalItemQty
                    },
                    "MODIFY_QTY"
                );

                this.setState({
                    modifyQtyPassed: true
                }, () => {
                    this.handleItemModalOk();
                });
            },
            res => {
                ErrorHandler.handleGeneralError(res);
            },
            res => {
                this.setState({ modifyQtyModalLoading: false });
            }
        );
    };

    openModifyQtyModal = () => {
        this.setState({
            modifyQtyModalOpened: true
        });
    };

    closeModifyQtyModal = () => {
        this.setState({
            modifyQtyModalOpened: false,
            modifyQtyModalLoading: false,
            modifyQtyPassword: "",
            modifyQtyReason: ""
        });
    };

    decreaseQty = () => {
        const { modalItemQty } = this.state;
        const { posConfig } = this.props;
        let newQty;

        if (modalItemQty > 1) {
            newQty = modalItemQty - 1;
        } else {
            newQty = 1;
        }

        const inputCallback = (value, valueText) => {
            this.setState({
                modalItemQty: value,
                modalItemQtyText: valueText
            });
        };
        newQty = number_format(newQty, posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);
        processDecimalInput(newQty, inputCallback, this.decimalSeparator, this.thousandSeparator);
    };

    increaseQty = () => {
        const { modalItemQty, modalItemQtyMax } = this.state;
        const { posConfig } = this.props;
        let newQty = parseFloat(modalItemQty) + 1;
        newQty = number_format(newQty, posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);

        if (!empty(modalItemQtyMax) && modalItemQty > modalItemQtyMax) {
            message.error(`Qty produk tidak dapat melebihi ${modalItemQtyMax}`);
        } else {
            const inputCallback = (value, valueText) => {
                this.setState({
                    modalItemQty: value,
                    modalItemQtyText: valueText
                });
            };
            processDecimalInput(newQty, inputCallback, this.decimalSeparator, this.thousandSeparator);
        }
    };

    setComponentState = newState => {
        this.setState(newState);
    };

    onSubmitForm = e => {
        e.preventDefault();
        e.stopPropagation();

        this.handleItemModalOk();
    };

    handleItemModalCancel = () => {
        this.props.close();
        this.setState({ ...this.initialState });
    };

    handleItemModalOk = () => {
        let validForm = true;

        const {
            posConfig,
            currentLocalTrxId,
            trxDetail
        } = this.props;

        let currentTrx = this.props.currentTrx;
        if (empty(currentTrx)) {
            currentTrx = this.props.trx;
        }

        const {
            modalItem,
            modalItemVariantId,
            modalItemSalesTypeId,
            modalItemQty,
            initialModalItemQty,
            modalItemQtyMax,
            modalItemDefinedDiscounts,
            modalItemDiscountType,
            modalItemDiscountPercent,
            modalItemDiscountValue,
            modalItemNotes,
            modalItemSecretNotes,
            modalCouponActivationDate,
            modalCouponNotes,
            modalItemCommissionWorker,
            modalItemCommissionWorkers,
            itemSellers,
            modalItemPackageDetails,
            modalItemModifiers,
            modalItemServiceArea,
            redeem,
            coupon,
            modifyQtyPassed
        } = this.state;
        const variantId = empty(modalItemVariantId)
            ? modalItem.pos_item_variants.length === 1
                ? modalItem.pos_item_variants[0].pos_item_variant_id
                : modalItemVariantId
            : modalItemVariantId;
        const itemVariant = modalItem.pos_item_variants.find(
            variant => variant.pos_item_variant_id === variantId
        );

        let filteredCommissionWorker = posConfig.commission_worker;
        if (!empty(modalItem.pos_item_commission_worker_groups)) {
            filteredCommissionWorker = filteredCommissionWorker.filter(worker => {
                let eligibleWorker = false;
                modalItem.pos_item_commission_worker_groups.map(workerGroup => {
                    if (eligibleWorker === false) {
                        eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                    }
                    return workerGroup;
                });

                return eligibleWorker;
            });
        }

        if (empty(modalItemQty) || modalItemQty <= 0 || isNaN(modalItemQty)) {
            validForm = false;
            message.error(`Qty produk minimal 1 buah.`);
        }

        if (currentTrx.trx_type === TRX_TYPE_NORMAL) {
            if (empty(variantId)) {
                validForm = false;
                message.error("Silakan pilih varian produk terlebih dahulu.");
            } else {
                if (modalItemDiscountType === "percentage") {
                    if (isNaN(modalItemDiscountPercent)) {
                        validForm = false;
                        message.error(
                            `Diskon harus berupa angka.`
                        );
                    }

                    if (modalItemDiscountPercent > modalItem.max_custom_discount) {
                        validForm = false;
                        message.error(
                            `Diskon maksimal ${modalItem.max_custom_discount}% untuk item ini.`
                        );
                    }
                }

                if (modalItemDiscountType === "value") {
                    if (isNaN(modalItemDiscountValue)) {
                        validForm = false;
                        message.error(
                            `Diskon harus berupa angka.`
                        );
                    }

                    if (modalItemDiscountValue > itemVariant.variant_price) {
                        validForm = false;
                        message.error(
                            "Nominal diskon tidak boleh melebihi harga barang."
                        );
                    }

                    if ((modalItemDiscountValue / itemVariant.variant_price * 100) > modalItem.max_custom_discount) {
                        validForm = false;
                        message.error(
                            `Diskon maksimal ${modalItem.max_custom_discount}% untuk item ini.`
                        );
                    }
                }
            }
        }

        if (currentTrx.trx_type === TRX_TYPE_COUPON_SALES) {
            if (modalItemDiscountType === "percentage") {
                if (modalItemDiscountPercent > modalItem.coupon_meta.max_custom_discount) {
                    validForm = false;
                    message.error(
                        `Diskon maksimal ${modalItem.coupon_meta.max_custom_discount}% untuk kupon ini.`
                    );
                }
            }

            if (modalItemDiscountType === "value") {
                if (modalItemDiscountValue > itemVariant.variant_price) {
                    validForm = false;
                    message.error(
                        "Nominal diskon tidak boleh melebihi harga kupon."
                    );
                }

                if ((modalItemDiscountValue / itemVariant.variant_price * 100) > modalItem.coupon_meta.max_custom_discount) {
                    validForm = false;
                    message.error(
                        `Diskon maksimal ${modalItem.coupon_meta.max_custom_discount}% untuk kupon ini.`
                    );
                }
            }
        }

        if (!empty(modalItemQtyMax) && modalItemQty > modalItemQtyMax) {
            validForm = false;
            message.error(`Qty produk tidak dapat melebihi ${modalItemQtyMax}`);
        }

        if (
            posConfig.is_pos_commission_scheme === 1 &&
            !empty(filteredCommissionWorker) &&
            currentTrx.trx_type === TRX_TYPE_NORMAL
        ) {
            if (posConfig.is_pos_multicommission_scheme === 0) {
                if (modalItem.is_package === 0) {
                    if (empty(modalItemCommissionWorker) && modalItem.no_commission === 0) {
                        if (!window.confirm("Anda belum memilih pekerja, apakah Anda yakin ingin melanjutkan?")) {
                            validForm = false;
                        }
                    }
                } else {
                    const hasEmptyWorker = modalItemPackageDetails.some(detail => empty(detail.business_commission_worker) && detail.no_commission === 0);
                    if (hasEmptyWorker) {
                        if (!window.confirm("Ada barang / service yang belum memiliki pekerja, apakah Anda yakin ingin melanjutkan?")) {
                            validForm = false;
                        }
                    }
                }
            } else {
                if (modalItem.is_package === 0) {
                    if (empty(modalItemCommissionWorkers) && modalItem.no_commission === 0) {
                        if (!window.confirm("Anda belum memilih pekerja, apakah Anda yakin ingin melanjutkan?")) {
                            validForm = false;
                        }
                    }
                } else {
                    const hasEmptyWorker = modalItemPackageDetails.some(detail => empty(detail.commission_workers) && detail.no_commission === 0);
                    if (hasEmptyWorker) {
                        if (!window.confirm("Ada barang / service yang belum memiliki pekerja, apakah Anda yakin ingin melanjutkan?")) {
                            validForm = false;
                        }
                    }
                }
            }
        }

        if (!empty(modalItemDefinedDiscounts)) {
            const posItemDiscount = modalItemDefinedDiscounts[0];
            if (modalItemQty < posItemDiscount.req_item_min_qty) {
                validForm = false;
                message.error(`Qty produk minimal ${posItemDiscount.req_item_min_qty} untuk menggunakan diskon ini.`);
            }
        }

        if (!empty(currentTrx.coupon)){
            const coupon = currentTrx.coupon.coupon_item;

            if (coupon.pos_link_type === 'item_category') {
                if(coupon.item_category_max_qty > 0){
                    let total_qty = 0;
                    currentTrx.details.forEach(detail => {
                        if(detail.itemMeta.pos_item_category_id === coupon.pos_item_category_id){
                            total_qty += detail.qty;
                        }
                    });

                    let next_qty = 0;
                    if (empty(this.props.trxDetail)) {
                        next_qty = total_qty + modalItemQty;
                    }else{
                        next_qty = total_qty - initialModalItemQty + modalItemQty;
                    }

                    if((next_qty) > coupon.item_category_max_qty){
                        validForm = false;
                        message.error('Jumlah barang yang memiliki potongan kategori kupon sudah melebihi jumlah yang diperbolehkan.');
                    }
                }
            }
        }

        // let modifier_total_price = 0;
        // if(!empty(modalItemModifiers)){
        //     modalItemModifiers.map(modifier => {
        //         return modifier.pos_item_modifier_options.map(options => {
        //             return modifier_total_price += options.option_price;
        //         });
        //     });
        // }

        // if(itemVariant.variant_price + modifier_total_price < 0){
        //     validForm = false;
        //     message.error(`Modifier tidak boleh minus lebih dari harga barang.`);
        // }

        if (validForm) {
            const pos_item_variant_id =
                modalItem.pos_item_variants.length === 1
                    ? modalItem.pos_item_variants[0].pos_item_variant_id
                    : modalItemVariantId;
            const pos_salesType_id =
                posConfig.sales_type.length === 1
                    ? posConfig.sales_type[0].pos_salesType_id
                    : modalItemSalesTypeId;
            let pos_sales_type = null;
            if (!empty(pos_salesType_id)) {
                pos_sales_type = posConfig.sales_type.find(
                    sales_type =>
                        sales_type.pos_salesType_id === pos_salesType_id
                );
            }

            if (!this.props.postEditMode) {
                if (empty(this.props.trxDetail)) {
                    this.props.addTrxDetail(
                        currentLocalTrxId,
                        currentTrx.details,
                        modalItem,
                        {
                            is_custom: 0,
                            is_package: modalItem.is_package,
                            pos_item_variant_id: pos_item_variant_id,
                            package_pos_item_id: 0,
                            qty: modalItemQty,
                            pos_item_discounts: modalItemDefinedDiscounts,
                            custom_discount_type: modalItemDiscountType,
                            custom_discount_value:
                                modalItemDiscountType === "percentage"
                                    ? modalItemDiscountPercent
                                    : modalItemDiscountValue,
                            notes: modalItemNotes,
                            secret_notes: modalItemSecretNotes,
                            coupon_activation_date: modalCouponActivationDate,
                            coupon_notes: modalCouponNotes,
                            pos_sales_type: pos_sales_type,
                            commission_worker: modalItemCommissionWorker,
                            commission_workers: modalItemCommissionWorkers,
                            business_commission_worker_id: empty(
                                modalItemCommissionWorker
                            )
                                ? null
                                : modalItemCommissionWorker.business_commission_worker_id,
                            item_sellers: itemSellers,
                            pos_item_package_details: modalItemPackageDetails,
                            pos_item_pos_item_modifiers: modalItemModifiers,
                            service_area: modalItemServiceArea,
                            redeem,
                            coupon,
                            local_created_at: now(),
                            last_printed_at: null
                        }
                    );
                } else {
                    if (posConfig.pos_cashier_delete_by_pin === 1 && initialModalItemQty !== modalItemQty && modifyQtyPassed === false) {
                        this.openModifyQtyModal();
                        return;
                    }

                    this.props.editTrxDetail(
                        currentLocalTrxId,
                        trxDetail.local_trx_detail_id,
                        {
                            ...trxDetail,
                            itemMeta: modalItem,
                            is_custom: 0,
                            is_package: modalItem.is_package,
                            pos_item_variant_id: pos_item_variant_id,
                            package_pos_item_id: 0,
                            qty: modalItemQty,
                            pos_item_discounts: modalItemDefinedDiscounts,
                            custom_discount_type: modalItemDiscountType,
                            custom_discount_value:
                                modalItemDiscountType === "percentage"
                                    ? modalItemDiscountPercent
                                    : modalItemDiscountValue,
                            notes: modalItemNotes,
                            secret_notes: modalItemSecretNotes,
                            coupon_activation_date: modalCouponActivationDate,
                            coupon_notes: modalCouponNotes,
                            pos_sales_type: pos_sales_type,
                            commission_worker: modalItemCommissionWorker,
                            commission_workers: modalItemCommissionWorkers,
                            business_commission_worker_id: empty(
                                modalItemCommissionWorker
                            )
                                ? null
                                : modalItemCommissionWorker.business_commission_worker_id,
                            item_sellers: itemSellers,
                            pos_item_package_details: modalItemPackageDetails,
                            pos_item_pos_item_modifiers: modalItemModifiers,
                            service_area: modalItemServiceArea,
                            redeem,
                            coupon,
                            last_printed_at: (this.props.trxDetail.qty !== modalItemQty ? null : this.props.trxDetail?.last_printed_at)
                        }
                    );
                    message.success("Item successfully edited!");
                }

                this.props.setTrxProps(this.props.currentLocalTrxId, {
                    is_synced: false
                });

                this.setState({ ...this.initialState });

                this.closeModifyQtyModal();
                this.props.close();
            } else {
                this.setState({ formLoading: true });
                const trx = this.props.trx;
                const form_data = {
                    edit_session_id: this.props.postEditSessionId,
                    input_data: {
                        ...trx,
                        details: trx.details.map(row => {
                            if (row.local_trx_detail_id === trxDetail.local_trx_detail_id) {
                                return {
                                    ...row,
                                    edit_session_id: this.props.postEditSessionId,
                                    commission_worker: modalItemCommissionWorker,
                                    commission_workers: modalItemCommissionWorkers,
                                    business_commission_worker_id: empty(
                                        modalItemCommissionWorker
                                    )
                                        ? null
                                        : modalItemCommissionWorker.business_commission_worker_id,
                                    pos_item_package_details: modalItemPackageDetails
                                };
                            } else {
                                return row;
                            }
                        })
                    }
                };

                apiCall(
                    POST_POS_TRX,
                    "put",
                    form_data,
                    res => {
                        message.success("Item successfully edited");
                        window.location.reload();
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                    },
                    res => {
                        this.setState({ formLoading: false });
                    }
                );
            }
        }
    };

    // PACKAGE ITEM FUNCTIONS
    renderPackageItems = (pos_item_package_details) => {
        const RadioGroup = Radio.Group;
        const RadioButton = Radio.Button;
        const { itemType } = this.props;

        let currentTrx = this.props.currentTrx;
        if (empty(currentTrx)) {
            currentTrx = this.props.trx;
        }

        if (empty(pos_item_package_details)) {
            return "";
        } else {
            return pos_item_package_details.map((package_detail, index) => {
                const item = this.props.posConfig.pos_items.find(item => item.pos_item_id === package_detail.pos_item_id);
                let commissionWorkers = this.props.posConfig.commission_worker;
                if (!empty(item?.pos_item_commission_worker_groups)) {
                    commissionWorkers = commissionWorkers.filter(worker => {
                        let eligibleWorker = false;
                        item.pos_item_commission_worker_groups.map(workerGroup => {
                            if (eligibleWorker === false) {
                                eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                            }
                            return workerGroup;
                        });

                        return eligibleWorker;
                    });
                }

                return (
                    <div className={styles.packageItemListItem} key={index}>
                        <h4 style={{ marginTop: 15 }}>
                            x{package_detail.qty} - {package_detail.item_name}{" "}
                            {package_detail.is_all_variant === 0 &&
                                package_detail.pos_item_package_detail_variants
                                    .length === 1 &&
                                package_detail
                                    .pos_item_package_detail_variants[0]
                                    .variant_name}
                        </h4>

                        {package_detail.pos_item_package_detail_variants
                            .length > 1 && (
                            <React.Fragment>
                                <RadioGroup
                                    defaultValue={
                                        package_detail.pos_item_package_detail_variants.find(
                                            variant => variant.is_chosen === 1
                                        ).pos_item_variant_id
                                    }
                                    buttonStyle="solid"
                                    onChange={e => {
                                        this.setComponentState({
                                            modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                                                (detail) => {
                                                    if (
                                                        package_detail.pos_item_id ===
                                                        detail.pos_item_id && detail.detailIndex === index
                                                    ) {
                                                        return {
                                                            ...detail,
                                                            pos_item_package_detail_variants: package_detail.pos_item_package_detail_variants.map(
                                                                variant => {
                                                                    if (
                                                                        variant.pos_item_variant_id ===
                                                                        e.target
                                                                            .value
                                                                    ) {
                                                                        return {
                                                                            ...variant,
                                                                            is_chosen: 1
                                                                        };
                                                                    } else {
                                                                        return {
                                                                            ...variant,
                                                                            is_chosen: 0
                                                                        };
                                                                    }
                                                                }
                                                            )
                                                        };
                                                    } else {
                                                        return detail;
                                                    }
                                                }
                                            )
                                        });
                                    }}
                                >
                                    {package_detail.pos_item_package_detail_variants.map(
                                        variant => {
                                            return (
                                                <RadioButton
                                                    value={
                                                        variant.pos_item_variant_id
                                                    }
                                                    key={
                                                        variant.pos_item_variant_id
                                                    }
                                                >
                                                    {variant.variant_name}
                                                </RadioButton>
                                            );
                                        }
                                    )}
                                </RadioGroup>
                            </React.Fragment>
                        )}

                        {itemType === "normal" &&
                            this.props.posConfig.is_pos_commission_scheme === 1 &&
                            this.props.posConfig.is_pos_multicommission_scheme === 0 &&
                            !empty(commissionWorkers) && (
                                <React.Fragment>
                                    {empty(
                                        this.state.modalItemPackageDetails.find(
                                            detail =>
                                                detail.pos_item_id ===
                                                package_detail.pos_item_id
                                                && detail.detailIndex === index
                                        ).business_commission_worker
                                    ) && (
                                        <Select
                                            showSearch={true}
                                            optionFilterProp="children"
                                            style={{
                                                width: "100%",
                                                marginTop: 5
                                            }}
                                            allowClear={true}
                                            onChange={(val, opt) => {
                                                this.onSingleCommissionWorkerSelectedForPackage(val, package_detail.pos_item_id, index);
                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.props.children
                                                    .join("")
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            placeholder="Pilih worker"
                                        >
                                            {commissionWorkers.map(
                                                (commissionWorker, index2) => {
                                                    const { workerStatus, workerStyle } = this.processWorkerStatus(commissionWorker);

                                                    return (
                                                        <Select.Option
                                                            key={index2}
                                                            value={
                                                                commissionWorker.business_commission_worker_id
                                                            }
                                                        >
                                                        <span style={workerStyle}>
                                                            {
                                                                commissionWorker.firstname
                                                            }{" "}
                                                            {
                                                                commissionWorker.lastname
                                                            }
                                                            {workerStatus}
                                                        </span>
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    )}

                                    {!empty(
                                        this.state.modalItemPackageDetails.find(
                                            detail =>
                                                detail.pos_item_id ===
                                                package_detail.pos_item_id
                                                && detail.detailIndex === index
                                        ).business_commission_worker
                                    ) && (
                                        <Select
                                            showSearch={true}
                                            optionFilterProp="children"
                                            style={{
                                                width: "100%",
                                                marginTop: 5
                                            }}
                                            allowClear={true}
                                            onChange={(val, opt) => {
                                                this.onSingleCommissionWorkerSelectedForPackage(val, package_detail.pos_item_id, index);
                                            }}
                                            defaultValue={
                                                package_detail
                                                    .business_commission_worker
                                                    .business_commission_worker_id
                                            }
                                            filterOption={(input, option) =>
                                                option.props.children.props.children
                                                    .join("")
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            placeholder="Pilih worker"
                                        >
                                            {commissionWorkers.map(
                                                (commissionWorker, index2) => {
                                                    const { workerStatus, workerStyle } = this.processWorkerStatus(commissionWorker);
                                                    return (
                                                        <Select.Option
                                                            value={
                                                                commissionWorker.business_commission_worker_id
                                                            }
                                                            key={index2}
                                                        >
                                                        <span style={workerStyle}>
                                                        {
                                                            commissionWorker.firstname
                                                        }{" "}
                                                            {
                                                                commissionWorker.lastname
                                                            }
                                                            {workerStatus}
                                                        </span>
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    )}
                                </React.Fragment>
                            )}

                        {itemType === "normal" &&
                            this.props.posConfig.is_pos_commission_scheme === 1 &&
                            this.props.posConfig.is_pos_multicommission_scheme === 1 &&
                            !empty(commissionWorkers) &&
                            this.renderMultiCommissionWorkersForPackageItem(package_detail.pos_item_id, index)}

                        {
                            currentTrx.trx_type === TRX_TYPE_NORMAL &&
                            this.props.posConfig.is_service_area_scheme === 1 &&
                            this.props.posConfig.service_area_type === "item" &&
                            this.renderItemServiceAreaForPackage(package_detail.pos_item_id)
                        }

                    </div>
                );
            });
        }
    };

    // MODIFIER FUNCTIONS
    onSingleTypeModifierOptionSelected = e => {
        const modalItem = this.props.item;
        const modifierMeta = modalItem.pos_item_pos_item_modifiers.find(
            row =>
                row.pos_item_modifier_id === e.target.value.pos_item_modifier_id
        );

        const stateModifier = this.state.modalItemModifiers.find(
            row =>
                row.pos_item_modifier_id === e.target.value.pos_item_modifier_id
        );
        if (empty(stateModifier)) {
            this.setState({
                modalItemModifiers: this.state.modalItemModifiers.concat({
                    ...modifierMeta,
                    pos_item_modifier_options: [{ ...e.target.value }]
                })
            });
        } else {
            const stateOption = stateModifier.pos_item_modifier_options.find(
                row =>
                    row.posItemModifier_option_id ===
                    e.target.value.posItemModifier_option_id
            );
            if (empty(stateOption)) {
                this.setState({
                    modalItemModifiers: this.state.modalItemModifiers.map(
                        row => {
                            if (
                                row.pos_item_modifier_id ===
                                e.target.value.pos_item_modifier_id
                            ) {
                                return {
                                    ...row,
                                    pos_item_modifier_options: [
                                        { ...e.target.value }
                                    ]
                                };
                            } else {
                                return row;
                            }
                        }
                    )
                });
            }
        }
    };

    onMultipleTypeModifierOptionSelected = e => {
        const modalItem = this.props.item;
        const modifierMeta = modalItem.pos_item_pos_item_modifiers.find(
            row =>
                row.pos_item_modifier_id === e.target.value.pos_item_modifier_id
        );

        if (e.target.checked) {
            const stateModifier = this.state.modalItemModifiers.find(
                row =>
                    row.pos_item_modifier_id ===
                    e.target.value.pos_item_modifier_id
            );
            if (empty(stateModifier)) {
                this.setState({
                    modalItemModifiers: this.state.modalItemModifiers.concat({
                        ...modifierMeta,
                        pos_item_modifier_options: [{ ...e.target.value }]
                    })
                });
            } else {
                const stateOption = stateModifier.pos_item_modifier_options.find(
                    row =>
                        row.posItemModifier_option_id ===
                        e.target.value.posItemModifier_option_id
                );
                if (empty(stateOption)) {
                    this.setState({
                        modalItemModifiers: this.state.modalItemModifiers.map(
                            row => {
                                if (
                                    row.pos_item_modifier_id ===
                                    e.target.value.pos_item_modifier_id
                                ) {
                                    return {
                                        ...row,
                                        pos_item_modifier_options: row.pos_item_modifier_options.concat(
                                            { ...e.target.value }
                                        )
                                    };
                                } else {
                                    return row;
                                }
                            }
                        )
                    });
                }
            }
        } else {
            const stateModifier = this.state.modalItemModifiers.find(
                row =>
                    row.pos_item_modifier_id ===
                    e.target.value.pos_item_modifier_id
            );
            if (!empty(stateModifier)) {
                const stateOption = stateModifier.pos_item_modifier_options.find(
                    row =>
                        row.posItemModifier_option_id ===
                        e.target.value.posItemModifier_option_id
                );
                if (!empty(stateOption)) {
                    if (stateModifier.pos_item_modifier_options.length > 1) {
                        this.setState({
                            modalItemModifiers: this.state.modalItemModifiers.map(
                                row => {
                                    if (
                                        row.pos_item_modifier_id ===
                                        e.target.value.pos_item_modifier_id
                                    ) {
                                        return {
                                            ...row,
                                            pos_item_modifier_options: row.pos_item_modifier_options.filter(
                                                row2 =>
                                                    row2.posItemModifier_option_id !==
                                                    e.target.value
                                                        .posItemModifier_option_id
                                            )
                                        };
                                    } else {
                                        return row;
                                    }
                                }
                            )
                        });
                    } else {
                        this.setState({
                            modalItemModifiers: this.state.modalItemModifiers.filter(
                                row =>
                                    row.pos_item_modifier_id !==
                                    e.target.value.pos_item_modifier_id
                            )
                        });
                    }
                }
            }
        }
    };

    renderModifierOptions = modifier => {
        const { modalItemModifiers } = this.state;

        if (modifier.modifier_type === "SINGLE") {
            return (
                <Radio.Group
                    buttonStyle="solid"
                    onChange={this.onSingleTypeModifierOptionSelected}
                >
                    {modifier.pos_item_modifier_options.map(option => {
                        let checked = false;
                        if (!empty(modalItemModifiers)) {
                            const stateModifier = modalItemModifiers.find(
                                row =>
                                    row.pos_item_modifier_id ===
                                    option.pos_item_modifier_id
                            );
                            if (!empty(stateModifier)) {
                                if (
                                    !empty(
                                        stateModifier.pos_item_modifier_options
                                    )
                                ) {
                                    checked = !empty(
                                        stateModifier.pos_item_modifier_options.find(
                                            row =>
                                                row.posItemModifier_option_id ===
                                                option.posItemModifier_option_id
                                        )
                                    );
                                }
                            }
                        }
                        return (
                            <Radio.Button
                                key={
                                    "modifierOption" +
                                    option.posItemModifier_option_id
                                }
                                value={option}
                                checked={checked}
                            >
                                {option.option_name}
                                {option.option_price > 0
                                    ? ` (${this.currencyCode} ${number_format(
                                        option.option_price
                                    )})`
                                    : ""}
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
            );
        } else {
            return modifier.pos_item_modifier_options.map(option => {
                let checked = false;
                if (!empty(modalItemModifiers)) {
                    const stateModifier = modalItemModifiers.find(
                        row =>
                            row.pos_item_modifier_id ===
                            option.pos_item_modifier_id
                    );
                    if (!empty(stateModifier)) {
                        if (!empty(stateModifier.pos_item_modifier_options)) {
                            checked = !empty(
                                stateModifier.pos_item_modifier_options.find(
                                    row =>
                                        row.posItemModifier_option_id ===
                                        option.posItemModifier_option_id
                                )
                            );
                        }
                    }
                }
                return (
                    <Checkbox
                        onChange={this.onMultipleTypeModifierOptionSelected}
                        value={option}
                        checked={checked}
                        style={{ display: "block", marginLeft: 0, lineHeight: "2.5em" }}
                        key={
                            "modifierOption" + option.posItemModifier_option_id
                        }
                    >
                        {option.option_name +
                            (option.option_price > 0
                                ? ` (${this.currencyCode} ${number_format(option.option_price)})`
                                : "")}
                    </Checkbox>
                );
            });
        }
    };

    renderModifiers = posItemModifiers => {
        return posItemModifiers.map(modifier => {
            return (
                <div
                    key={"modifier" + modifier.posItem_posItemModifier_id}
                    style={{ marginBottom: 10 }}
                >
                    <h5 style={{ marginTop: 5 }}>{modifier.modifier_name}</h5>
                    {this.renderModifierOptions(modifier)}
                </div>
            );
        });
    };

    // COMMISSION WORKER FUNCTIONS
    onSingleCommissionWorkerSelectedForPackage = (val, pos_item_id, detailIndex) => {
        const item = this.props.posConfig.pos_items.find(item => item.pos_item_id === pos_item_id);
        let commissionWorkers = this.props.posConfig.commission_worker;
        if (!empty(item.pos_item_commission_worker_groups)) {
            commissionWorkers = commissionWorkers.filter(worker => {
                let eligibleWorker = false;
                item.pos_item_commission_worker_groups.map(workerGroup => {
                    if (eligibleWorker === false) {
                        eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                    }
                    return workerGroup;
                });

                return eligibleWorker;
            });
        }

        this.setComponentState({
            modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                detail => {
                    if (
                        pos_item_id ===
                        detail.pos_item_id && detail.detailIndex === detailIndex
                    ) {
                        let commissionWorkerFiltered = commissionWorkers.find(
                            commission_worker =>
                                commission_worker.business_commission_worker_id ===
                                val
                        );
                        if (!empty(commissionWorkerFiltered)) {
                            if (!empty(commissionWorkerFiltered.pos_item_commission_workers)) {
                                // const commissionWorkerItemFiltered = commissionWorkerFiltered.pos_item_commission_workers.filter(
                                //     row => row.pos_item_id === detail.pos_item_id
                                // );
                                commissionWorkerFiltered = {
                                    ...commissionWorkerFiltered,
                                    pos_item_commission_workers: []
                                };
                            }
                        }
                        return {
                            ...detail,
                            business_commission_worker: commissionWorkerFiltered
                        };
                    } else {
                        return detail;
                    }
                }
            )
        });
    };

    onSingleCommissionWorkerSelected = (val, commissionWorkers) => {
        let commissionWorkerFiltered = commissionWorkers.find(
            commission_worker =>
                commission_worker.business_commission_worker_id ===
                val
        );
        if (!empty(commissionWorkerFiltered)) {
            if (!empty(commissionWorkerFiltered.pos_item_commission_workers)) {
                // const commissionWorkerItemFiltered = commissionWorkerFiltered.pos_item_commission_workers.filter(
                //     row => row.pos_item_id === this.state.modalItem.pos_item_id
                // );
                commissionWorkerFiltered = {
                    ...commissionWorkerFiltered,
                    pos_item_commission_workers: []
                };
            }
        }
        this.setComponentState({
            modalItemCommissionWorker: commissionWorkerFiltered
        });
    };

    onMultipleCommissionWorkerPersonSelected = (val, commissionWorkers) => {
        let commissionWorkerFiltered = commissionWorkers.find(
            commission_worker =>
                commission_worker.business_commission_worker_id ===
                val
        );
        if (!empty(commissionWorkerFiltered)) {
            if (!empty(commissionWorkerFiltered.pos_item_commission_workers)) {
                // const commissionWorkerItemFiltered = commissionWorkerFiltered.pos_item_commission_workers.filter(
                //     row => row.pos_item_id === this.state.modalItem.pos_item_id
                // );
                commissionWorkerFiltered = {
                    ...commissionWorkerFiltered,
                    pos_item_commission_workers: []
                };
            }
        }
        this.setComponentState({
            multiCommissionWorkerPerson: commissionWorkerFiltered
        });
    };

    onMultipleCommissionWorkerPersonSelectedForPackage = (val, pos_item_id, detailIndex) => {
        const { modalItemPackageDetails } = this.state;
        const currentModalItemCommissionWorkersPackage = modalItemPackageDetails.find(
            detail => pos_item_id === detail.pos_item_id && detail.detailIndex === detailIndex
        ).commission_workers;
        const reducer = (accumulator, currentValue) =>
            parseInt(currentValue.percentage) + accumulator;
        const totalCommission = currentModalItemCommissionWorkersPackage.reduce(reducer, 0);
        const commissionRemainder = 100 - parseInt(totalCommission);

        const item = this.props.posConfig.pos_items.find(item => item.pos_item_id === pos_item_id);
        let commissionWorkers = this.props.posConfig.commission_worker;
        if (!empty(item.pos_item_commission_worker_groups)) {
            commissionWorkers = commissionWorkers.filter(worker => {
                let eligibleWorker = false;
                item.pos_item_commission_worker_groups.map(workerGroup => {
                    if (eligibleWorker === false) {
                        eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                    }
                    return workerGroup;
                });

                return eligibleWorker;
            });
        }

        let commissionWorkerFiltered = commissionWorkers.find(
            commission_worker =>
                commission_worker.business_commission_worker_id ===
                val
        );
        if (!empty(commissionWorkerFiltered)) {
            if (!empty(commissionWorkerFiltered.pos_item_commission_workers)) {
                // const commissionWorkerItemFiltered = commissionWorkerFiltered.pos_item_commission_workers.filter(
                //     row => row.pos_item_id === pos_item_id
                // );
                commissionWorkerFiltered = {
                    ...commissionWorkerFiltered,
                    pos_item_commission_workers: []
                };
            }
        }

        if (empty(this.state.multiCommissionWorkerPackage.find(
            row => row.pos_item_id === pos_item_id && row.detailIndex === detailIndex
        ))) {
            this.setComponentState({
                multiCommissionWorkerPackage: this.state.multiCommissionWorkerPackage.concat({
                    pos_item_id,
                    detailIndex: detailIndex,
                    commission_worker_person: commissionWorkerFiltered,
                    commission_worker_percent: commissionRemainder
                })
            });
        } else {
            this.setComponentState({
                multiCommissionWorkerPackage: this.state.multiCommissionWorkerPackage.map(row => {
                    if (row.pos_item_id === pos_item_id && row.detailIndex === detailIndex) {
                        return {
                            ...row,
                            commission_worker_person: commissionWorkerFiltered,
                            commission_worker_percent: commissionRemainder
                        };
                    } else {
                        return row;
                    }
                })
            });
        }
    };

    renderMultiCommissionWorkers = (commissionWorkers) => {
        const { modalItemCommissionWorkers, multiCommissionWorkerPerson, multiCommissionWorkerPercent } = this.state;
        const { is_pos_auto_multicommission, is_pos_auto_split_multicommission } = this.props.posConfig;
        const remainingCommissionWorkers = commissionWorkers.filter(
            commission_worker => empty(modalItemCommissionWorkers.find(row => row.commission_worker.business_commission_worker_id === commission_worker.business_commission_worker_id))
        );
        const reducer = (accumulator, currentValue) =>
            parseInt(currentValue.percentage) + accumulator;
        const totalCommission = modalItemCommissionWorkers.reduce(reducer, 0);
        const commissionRemainder = 100 - parseInt(totalCommission);
        const autoCommission = is_pos_auto_multicommission === 1;
        const onAddWorker = () => {
            if (!autoCommission) {
                if (empty(multiCommissionWorkerPerson)) {
                    message.error("Worker harus dipilih!");
                } else if (this.props.posConfig.pos_item_requiring_worker === 1 && (empty(multiCommissionWorkerPercent) || multiCommissionWorkerPercent < 0)) {
                    message.error("Persentase tidak boleh kosong!");
                } else if (is_pos_auto_split_multicommission === 1 && multiCommissionWorkerPercent > commissionRemainder) {
                    message.error("Persentase tidak boleh melebihi sisa komisi!");
                } else {
                    this.setState({
                        modalItemCommissionWorkers: modalItemCommissionWorkers.concat(
                            {
                                commission_worker: multiCommissionWorkerPerson,
                                percentage: multiCommissionWorkerPercent
                            }
                        ),
                        multiCommissionWorkerPerson: null,
                        multiCommissionWorkerPercent: 0
                    }, () => {
                        const afterTotalCommission = this.state.modalItemCommissionWorkers.reduce(reducer, 0);
                        let afterCommissionRemainder;
                        if (is_pos_auto_split_multicommission !== 1) {
                            afterCommissionRemainder = 100;
                        } else {
                            afterCommissionRemainder = 100 - parseInt(afterTotalCommission);
                        }


                        this.setState({ multiCommissionWorkerPercent: afterCommissionRemainder });
                    });
                }
            } else {
                if (empty(multiCommissionWorkerPerson)) {
                    message.error("Worker harus dipilih!");
                } else {
                    let multiCommissionWorkers = modalItemCommissionWorkers.concat(
                        {
                            commission_worker: this.state
                                .multiCommissionWorkerPerson,
                            percentage: this.state
                                .multiCommissionWorkerPercent
                        }
                    );
                    const workerCount = multiCommissionWorkers.length;
                    let fairPercentage = Math.floor(100 / workerCount);

                    if (is_pos_auto_split_multicommission !== 1) {
                        fairPercentage = 100;
                    }

                    multiCommissionWorkers = multiCommissionWorkers.map(worker => {
                        return {
                            ...worker,
                            percentage: fairPercentage
                        };
                    });

                    this.setState({
                        modalItemCommissionWorkers: multiCommissionWorkers,
                        multiCommissionWorkerPerson: null,
                        multiCommissionWorkerPercent: 0
                    });
                }
            }
        };

        return (
            <React.Fragment>
                <div style={{ marginBottom: 10 }}>
                    {empty(modalItemCommissionWorkers) && (
                        <span>
                            Belum ada worker dipilih, silakan tambahkan.
                        </span>
                    )}

                    {!empty(modalItemCommissionWorkers) &&
                        modalItemCommissionWorkers.map(row => {
                            return (
                                <div key={`modalItemCommissionWorker${row.commission_worker.business_commission_worker_id}`} className={styles.commissionWorkerPerson}>
                                    [{row.percentage}%]{" "}
                                    {row.commission_worker.firstname}{" "}
                                    {row.commission_worker.lastname}{" "}
                                    <small
                                        className={
                                            styles.deleteCommissionWorkerBtn
                                        }
                                        onClick={() => {
                                            if (!autoCommission) {
                                                this.setState({
                                                    modalItemCommissionWorkers: modalItemCommissionWorkers.filter(
                                                        worker =>
                                                            worker.commission_worker
                                                                .business_commission_worker_id !==
                                                            row.commission_worker
                                                                .business_commission_worker_id
                                                    )
                                                }, () => {
                                                    const afterTotalCommission = this.state.modalItemCommissionWorkers.reduce(reducer, 0);
                                                    const afterCommissionRemainder = 100 - parseInt(afterTotalCommission);

                                                    this.setState({ multiCommissionWorkerPercent: afterCommissionRemainder });
                                                });
                                            } else {
                                                const filteredWorker = modalItemCommissionWorkers.filter(
                                                    worker =>
                                                        worker.commission_worker
                                                            .business_commission_worker_id !==
                                                        row.commission_worker
                                                            .business_commission_worker_id
                                                );
                                                const workerCount = filteredWorker.length;
                                                let fairPercentage = Math.floor(100 / workerCount);

                                                if (is_pos_auto_split_multicommission !== 1) {
                                                    fairPercentage = 100;
                                                }

                                                this.setState({
                                                    modalItemCommissionWorkers: filteredWorker.map(worker => {
                                                        return {
                                                            ...worker,
                                                            percentage: fairPercentage
                                                        };
                                                    })
                                                });
                                            }
                                        }}
                                    >
                                        hapus
                                    </small>
                                </div>
                            );
                        })}

                    {!autoCommission && is_pos_auto_split_multicommission === 1 && (
                        <div>Sisa komisi: {commissionRemainder}%</div>
                    )}
                </div>
                {!empty(remainingCommissionWorkers) && (
                    <table className={styles.workerTable}>
                        <tbody>
                        <tr>
                            <td>
                                <Select
                                    showSearch={true}
                                    optionFilterProp="children"
                                    style={{
                                        width: "100%"
                                    }}
                                    onChange={(val, opt) => {
                                        this.onMultipleCommissionWorkerPersonSelected(val, commissionWorkers);
                                    }}
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children.props.children
                                            .join("")
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Pilih worker"
                                    value={empty(this.state.multiCommissionWorkerPerson) ? null : this.state.multiCommissionWorkerPerson.business_commission_worker_id}
                                >
                                    {remainingCommissionWorkers.map(
                                        (commissionWorker, index) => {
                                            const { workerStatus, workerStyle } = this.processWorkerStatus(commissionWorker);
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={
                                                        commissionWorker.business_commission_worker_id
                                                    }
                                                >
                                                    <span style={workerStyle}>
                                                    {commissionWorker.firstname}{" "}
                                                        {commissionWorker.lastname}
                                                        {workerStatus}
                                                    </span>
                                                </Select.Option>
                                            );
                                        }
                                    )}
                                </Select>
                            </td>
                            {!autoCommission && (
                                <td>
                                    <InputNumber
                                        min={0}
                                        defaultValue={commissionRemainder}
                                        formatter={value =>
                                            `${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                        }
                                        parser={value =>
                                            value.replace(/\s?|(,*)/g, "")
                                        }
                                        style={{ width: "70%" }}
                                        onChange={e => {
                                            this.setComponentState({
                                                multiCommissionWorkerPercent: e
                                            });
                                        }}
                                        value={
                                            multiCommissionWorkerPercent
                                        }
                                    />{" "}
                                    %
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Button
                                    htmlType="button"
                                    style={{
                                        marginTop: 10
                                    }}
                                    type="primary"
                                    onClick={onAddWorker}
                                >
                                    Tambahkan worker
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </React.Fragment>
        );
    };

    renderMultiCommissionWorkersForPackageItem = (pos_item_id, detailIndex) => {
        const { multiCommissionWorkerPackage } = this.state;
        const { is_pos_auto_multicommission, is_pos_auto_split_multicommission } = this.props.posConfig;
        const currentModalItemCommissionWorkersPackage = this.state.modalItemPackageDetails.find(
            detail => pos_item_id === detail.pos_item_id && detail.detailIndex === detailIndex
        ).commission_workers;
        const currentMultiCommissionWorkerPackage = multiCommissionWorkerPackage.find(row => row.pos_item_id === pos_item_id && row.detailIndex === detailIndex);

        const item = this.props.posConfig.pos_items.find(item => item.pos_item_id === pos_item_id);
        let commissionWorkers = this.props.posConfig.commission_worker;
        console.log(item);
        if (!empty(item.pos_item_commission_worker_groups)) {
            commissionWorkers = commissionWorkers.filter(worker => {
                let eligibleWorker = false;
                item.pos_item_commission_worker_groups.map(workerGroup => {
                    if (eligibleWorker === false) {
                        eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                    }
                    return workerGroup;
                });

                return eligibleWorker;
            });
        }

        const remainingCommissionWorkers = commissionWorkers.filter(
            commission_worker => empty(currentModalItemCommissionWorkersPackage) ? true : empty(currentModalItemCommissionWorkersPackage.find(row => row.commission_worker.business_commission_worker_id === commission_worker.business_commission_worker_id))
        );
        const reducer = (accumulator, currentValue) =>
            parseInt(currentValue.percentage) + accumulator;
        const totalCommission = currentModalItemCommissionWorkersPackage.reduce(reducer, 0);
        const commissionRemainder = 100 - parseInt(totalCommission);
        const autoCommission = is_pos_auto_multicommission === 1;
        const onAddWorker = () => {
            if (!autoCommission) {
                if (empty(currentMultiCommissionWorkerPackage?.commission_worker_person)) {
                    message.error("Worker harus dipilih!");
                } else if (this.props.posConfig.pos_item_requiring_worker === 1 && (empty(currentMultiCommissionWorkerPackage?.commission_worker_percent) || currentMultiCommissionWorkerPackage?.commission_worker_percent < 0)) {
                    message.error("Persentase tidak boleh kosong!");
                } else if (currentMultiCommissionWorkerPackage?.commission_worker_percent > commissionRemainder) {
                    message.error("Persentase tidak boleh melebihi sisa komisi!");
                } else {
                    this.setState({
                        modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                            detail => {
                                if (
                                    pos_item_id ===
                                    detail.pos_item_id
                                    && detail.detailIndex === detailIndex
                                ) {
                                    return {
                                        ...detail,
                                        commission_workers: detail.commission_workers.concat(
                                            {
                                                commission_worker: currentMultiCommissionWorkerPackage?.commission_worker_person,
                                                percentage: currentMultiCommissionWorkerPackage?.commission_worker_percent
                                            }
                                        )
                                    };
                                } else {
                                    return detail;
                                }
                            }
                        ),
                        multiCommissionWorkerPackage: multiCommissionWorkerPackage.map(row => {
                            if (row.pos_item_id === pos_item_id && row.detailIndex === detailIndex) {
                                return {
                                    ...row,
                                    commission_worker_person: null,
                                    commission_worker_percent: 0
                                };
                            } else {
                                return row;
                            }
                        })
                    }, () => {
                        const afterCurrentModalItemCommissionWorkersPackage = this.state.modalItemPackageDetails.find(
                            detail => pos_item_id === detail.pos_item_id && detail.detailIndex === detailIndex
                        ).commission_workers;
                        const afterTotalCommission = afterCurrentModalItemCommissionWorkersPackage.reduce(reducer, 0);
                        const afterCommissionRemainder = 100 - parseInt(afterTotalCommission);

                        this.setComponentState({
                            multiCommissionWorkerPackage: multiCommissionWorkerPackage.map(row => {
                                if (row.pos_item_id === pos_item_id && row.detailIndex === detailIndex) {
                                    return {
                                        ...row,
                                        commission_worker_person: null,
                                        commission_worker_percent: afterCommissionRemainder
                                    };
                                } else {
                                    return row;
                                }
                            })
                        });
                    });
                }
            } else {
                if (empty(currentMultiCommissionWorkerPackage?.commission_worker_person)) {
                    message.error("Worker harus dipilih!");
                } else {
                    this.setState({
                        modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                            detail => {
                                let multiCommissionWorkers = detail.commission_workers.concat(
                                    {
                                        commission_worker: currentMultiCommissionWorkerPackage?.commission_worker_person,
                                        percentage: currentMultiCommissionWorkerPackage?.commission_worker_percent
                                    }
                                );
                                const workerCount = multiCommissionWorkers.length;
                                let fairPercentage = Math.floor(100 / workerCount);

                                if (is_pos_auto_split_multicommission !== 1) {
                                    fairPercentage = 100;
                                }

                                multiCommissionWorkers = multiCommissionWorkers.map(worker => {
                                    return {
                                        ...worker,
                                        percentage: fairPercentage
                                    };
                                });

                                if (
                                    pos_item_id ===
                                    detail.pos_item_id
                                ) {
                                    return {
                                        ...detail,
                                        commission_workers: multiCommissionWorkers
                                    };
                                } else {
                                    return detail;
                                }
                            }
                        ),
                        multiCommissionWorkerPackage: multiCommissionWorkerPackage.map(row => {
                            if (row.pos_item_id === pos_item_id && row.detailIndex === detailIndex) {
                                return {
                                    ...row,
                                    commission_worker_person: null,
                                    commission_worker_percent: 0
                                };
                            } else {
                                return row;
                            }
                        })
                    });
                }
            }
        };

        return (
            <React.Fragment>
                <div style={{ marginBottom: 10 }}>
                    {empty(currentModalItemCommissionWorkersPackage) && (
                        <span>
                            Belum ada worker dipilih, silakan tambahkan.
                        </span>
                    )}

                    {!empty(currentModalItemCommissionWorkersPackage) &&
                        currentModalItemCommissionWorkersPackage.map(row => {
                            return (
                                <div key={`modalItemCommissionPackage${pos_item_id}Worker${row.commission_worker.business_commission_worker_id}`} className={styles.commissionWorkerPerson}>
                                    [{row.percentage}%]{" "}
                                    {row.commission_worker.firstname}{" "}
                                    {row.commission_worker.lastname}{" "}
                                    <small
                                        className={
                                            styles.deleteCommissionWorkerBtn
                                        }
                                        onClick={() => {
                                            if (!autoCommission) {
                                                this.setState({
                                                    modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                                                        detail => {
                                                            if (
                                                                pos_item_id ===
                                                                detail.pos_item_id && detail.detailIndex === detailIndex
                                                            ) {
                                                                return {
                                                                    ...detail,
                                                                    commission_workers: detail.commission_workers.filter(
                                                                        worker =>
                                                                            worker.commission_worker
                                                                                .business_commission_worker_id !==
                                                                            row.commission_worker
                                                                                .business_commission_worker_id
                                                                    )
                                                                };
                                                            } else {
                                                                return detail;
                                                            }
                                                        }
                                                    )
                                                });
                                            } else {
                                                this.setState({
                                                    modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                                                        detail => {
                                                            let filteredMultiCommissionWorkers = detail.commission_workers.filter(
                                                                worker =>
                                                                    worker.commission_worker
                                                                        .business_commission_worker_id !==
                                                                    row.commission_worker
                                                                        .business_commission_worker_id
                                                            );
                                                            const workerCount = filteredMultiCommissionWorkers.length;
                                                            let fairPercentage = Math.floor(100 / workerCount);

                                                            if (is_pos_auto_split_multicommission !== 1) {
                                                                fairPercentage = 100;
                                                            }

                                                            filteredMultiCommissionWorkers = filteredMultiCommissionWorkers.map(worker => {
                                                                return {
                                                                    ...worker,
                                                                    percentage: fairPercentage
                                                                };
                                                            });

                                                            if (
                                                                pos_item_id ===
                                                                detail.pos_item_id && detail.detailIndex === detailIndex
                                                            ) {
                                                                return {
                                                                    ...detail,
                                                                    commission_workers: filteredMultiCommissionWorkers
                                                                };
                                                            } else {
                                                                return detail;
                                                            }
                                                        }
                                                    ),
                                                    multiCommissionWorkerPackage: multiCommissionWorkerPackage.map(row => {
                                                        if (row.pos_item_id === pos_item_id && row.detailIndex === detailIndex) {
                                                            return {
                                                                ...row,
                                                                commission_worker_person: null,
                                                                commission_worker_percent: 0
                                                            };
                                                        } else {
                                                            return row;
                                                        }
                                                    })
                                                });
                                            }
                                        }}
                                    >
                                        hapus
                                    </small>
                                </div>
                            );
                        })}

                    {!autoCommission && (
                        <div>Sisa komisi: {commissionRemainder}%</div>
                    )}
                </div>
                {!empty(remainingCommissionWorkers) && (
                    <table className={styles.workerTable}>
                        <tbody>
                        <tr>
                            <td>
                                <Select
                                    showSearch={true}
                                    optionFilterProp="children"
                                    style={{
                                        width: "100%"
                                    }}
                                    onChange={(val, opt) => {
                                        this.onMultipleCommissionWorkerPersonSelectedForPackage(val, pos_item_id, detailIndex);
                                    }}
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children.props.children
                                            .join("")
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Pilih worker"
                                    value={empty(currentMultiCommissionWorkerPackage) ? null : empty(currentMultiCommissionWorkerPackage.commission_worker_person) ? null : currentMultiCommissionWorkerPackage.commission_worker_person.business_commission_worker_id}
                                >
                                    {remainingCommissionWorkers.map(
                                        (commissionWorker, index) => {
                                            const { workerStatus, workerStyle } = this.processWorkerStatus(commissionWorker);
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={
                                                        commissionWorker.business_commission_worker_id
                                                    }
                                                >
                                                    <span style={workerStyle}>
                                                    {commissionWorker.firstname}{" "}
                                                        {commissionWorker.lastname}
                                                        {workerStatus}
                                                    </span>
                                                </Select.Option>
                                            );
                                        }
                                    )}
                                </Select>
                            </td>
                            {!autoCommission && (
                                <td>
                                    <InputNumber
                                        min={0}
                                        defaultValue={commissionRemainder}
                                        formatter={value =>
                                            `${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                        }
                                        parser={value =>
                                            value.replace(/\s?|(,*)/g, "")
                                        }
                                        style={{ width: "70%" }}
                                        onChange={e => {
                                            if (empty(multiCommissionWorkerPackage.find(row => row.pos_item_id === pos_item_id && row.detailIndex === detailIndex))) {
                                                this.setComponentState({
                                                    multiCommissionWorkerPackage: multiCommissionWorkerPackage.concat({
                                                        pos_item_id,
                                                        detailIndex: detailIndex,
                                                        commission_worker_percent: e
                                                    })
                                                });
                                            } else {
                                                this.setComponentState({
                                                    multiCommissionWorkerPackage: multiCommissionWorkerPackage.map(row => {
                                                        if (row.pos_item_id === pos_item_id && row.detailIndex === detailIndex) {
                                                            return {
                                                                ...row,
                                                                commission_worker_percent: e
                                                            };
                                                        } else {
                                                            return row;
                                                        }
                                                    })
                                                });
                                            }
                                        }}
                                        value={
                                            empty(currentMultiCommissionWorkerPackage) ? commissionRemainder : currentMultiCommissionWorkerPackage.commission_worker_percent
                                        }
                                    />{" "}
                                    %
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Button
                                    htmlType="button"
                                    style={{
                                        marginTop: 10
                                    }}
                                    type="primary"
                                    onClick={onAddWorker}
                                >
                                    Tambahkan worker
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </React.Fragment>
        );
    };

    // SERVICE AREA FUNCTIONS
    renderAreaCategories = (areaCategories, areas) => {
        return areaCategories.map(row => {
            const filteredAreas = areas.filter(row2 => row2.pos_areaCategory_id === row.pos_areaCategory_id);
            if (!empty(filteredAreas)) {
                return (
                    <Select.OptGroup label={row.areaCategory_name} key={`AreaCategory${row.pos_areaCategory_id}`}>
                        {this.renderAreas(filteredAreas)}
                    </Select.OptGroup>
                );
            } else {
                return "";
            }
        });
    };

    renderAreas = (areas) => {
        let currentTrx = this.props.currentTrx;
        if (empty(currentTrx)) {
            currentTrx = this.props.trx;
        }

        return areas.map(row => {
            const areaUsed = this.props.posTrxs
                .filter(trx => (empty(trx.trx_status_id) || trx.trx_status_id === 2) && trx.local_trx_id !== currentTrx.local_trx_id)
                .some(trx => {
                    return trx.details.find(detail => {
                        if (detail.is_package !== 1) {
                            if (empty(detail.service_area)) {
                                return false;
                            } else {
                                return detail.service_area.pos_area_id === row.pos_area_id;
                            }
                        } else {
                            return detail.pos_item_package_details.some(package_detail => {
                                if (empty(package_detail.service_area)) {
                                    return false;
                                } else {
                                    return package_detail.service_area.pos_area_id === row.pos_area_id;
                                }
                            });
                        }
                    });
                });
            return (
                <Select.Option value={row.pos_area_id} key={`Area${row.pos_area_id}`}>
                    {row.area_name} {areaUsed && `(OCCUPIED)`}
                </Select.Option>
            );
        });
    };

    onChangeServiceArea = (value, option) => {
        const { posConfig } = this.props;
        const filteredValue = posConfig.area.find(row => row.pos_area_id === value);

        this.setState({
            modalItemServiceArea: filteredValue
        });
    };

    onChangeServiceAreaForPackage = (val, pos_item_id) => {
        const { posConfig } = this.props;
        const filteredValue = posConfig.area.find(row => row.pos_area_id === val);

        this.setComponentState({
            modalItemPackageDetails: this.state.modalItemPackageDetails.map(
                detail => {
                    if (pos_item_id === detail.pos_item_id) {
                        return {
                            ...detail,
                            service_area: filteredValue
                        };
                    } else {
                        return detail;
                    }
                }
            )
        });
    };

    renderItemServiceArea = () => {
        const { posConfig } = this.props;
        const { modalItemServiceArea } = this.state;

        let defaultAreaValue = {};
        if (!empty(modalItemServiceArea)) {
            defaultAreaValue = {
                defaultValue: modalItemServiceArea.pos_area_id
            };
        }
        const areaCategories = posConfig.area_category.concat([{
            pos_areaCategory_id: 0,
            areaCategory_name: "Lainnya"
        }]);
        return (
            <div style={{ marginBottom: 15 }}>
                <h4 style={{ marginTop: 15 }}>Service Area</h4>
                <Select
                    {...defaultAreaValue}
                    style={{ width: "100%" }}
                    placeholder="Pilih service area"
                    optionFilterProp="children"
                    showSearch={true}
                    allowClear={true}
                    onChange={this.onChangeServiceArea}
                >
                    {this.renderAreaCategories(areaCategories, posConfig.area)}
                </Select>
            </div>
        );
    };

    renderItemServiceAreaForPackage = (pos_item_id) => {
        const { posConfig } = this.props;
        const currentModalItemPackageDetailServiceArea = this.state.modalItemPackageDetails.find(
            detail => pos_item_id === detail.pos_item_id
        ).service_area;

        let defaultAreaValue = {};
        if (!empty(currentModalItemPackageDetailServiceArea)) {
            defaultAreaValue = {
                defaultValue: currentModalItemPackageDetailServiceArea.pos_area_id
            };
        }
        const areaCategories = posConfig.area_category.concat([{
            pos_areaCategory_id: 0,
            areaCategory_name: "Lainnya"
        }]);
        return (
            <div style={{ marginTop: 10 }}>
                <Select
                    {...defaultAreaValue}
                    style={{ width: "100%" }}
                    placeholder="Pilih service area"
                    optionFilterProp="children"
                    showSearch={true}
                    allowClear={true}
                    onChange={(value, option) => {
                        this.onChangeServiceAreaForPackage(value, pos_item_id);
                    }}
                >
                    {this.renderAreaCategories(areaCategories, posConfig.area)}
                </Select>
            </div>
        );
    };

    showCustomPriceInput = (initialValue = 0) => {
        const formattedInitialValue = number_format(parseInt(initialValue), 0);
        this.setState({
            customPriceEditMode: true,
            customPriceValue: !empty(initialValue) ? formattedInitialValue : this.state.customPriceValue
        }, () => {
            this.customPriceInput.current.select();
        });
    };

    hideCustomPriceInput = () => {
        this.setState({
            customPriceEditMode: false,
            customPriceValue: 0
        });
    };

    onChangeCustomPrice = (e) => {
        const val = parseInt(e.target.value.replace(/\./g, ""));
        this.setState({
            customPriceValue: number_format(val, 0)
        });
    };

    onKeyDownCustomPrice = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            this.onSubmitCustomPrice();
        }
    };

    onSubmitCustomPrice = () => {
        const { modalItem, modalItemVariantId, customPriceValue } = this.state;
        const parsedCustomPriceValue = parseInt(customPriceValue.replace(/\./g, ""));

        if (parsedCustomPriceValue <= 0) {
            message.error("Harga tidak boleh nol");
            return;
        }

        if (modalItem.pos_item_variants.length === 1) {
            if (parsedCustomPriceValue === parseInt(modalItem.pos_item_variants[0].variant_price)) {
                this.hideCustomPriceInput();
                return;
            }

            if (!empty(parseInt(modalItem.pos_item_variants[0].original_price))) {
                if (parsedCustomPriceValue === parseInt(modalItem.pos_item_variants[0].original_price)) {
                    this.resetCustomPrice();
                    return;
                }
            } else {
                if (parsedCustomPriceValue === parseInt(modalItem.pos_item_variants[0].variant_price)) {
                    this.resetCustomPrice();
                    return;
                }
            }
        }

        if (modalItem.pos_item_variants.length > 1 && !empty(modalItemVariantId)) {
            const selectedVariant = modalItem.pos_item_variants.find(row => row.pos_item_variant_id === modalItemVariantId);
            if (parsedCustomPriceValue === parseInt(selectedVariant.variant_price)) {
                this.hideCustomPriceInput();
                return;
            }

            if (!empty(parseInt(selectedVariant.original_price))) {
                if (parsedCustomPriceValue === parseInt(selectedVariant.original_price)) {
                    this.resetCustomPrice();
                    return;
                }
            } else {
                if (parsedCustomPriceValue === parseInt(selectedVariant.variant_price)) {
                    this.resetCustomPrice();
                    return;
                }
            }
        }

        this.setState({
            modalItem: {
                ...modalItem,
                pos_item_variants: modalItem.pos_item_variants.map(row => {
                    if (modalItem.pos_item_variants.length === 1) {
                        return {
                            ...row,
                            custom_price: true,
                            original_price: row.variant_price,
                            variant_price: parsedCustomPriceValue
                        };
                    } else if (modalItem.pos_item_variants.length > 1 && !empty(modalItemVariantId)) {
                        if (row.pos_item_variant_id === modalItemVariantId) {
                            return {
                                ...row,
                                custom_price: true,
                                original_price: row.variant_price,
                                variant_price: parsedCustomPriceValue
                            };
                        } else {
                            return {
                                ...row,
                                custom_price: false,
                                variant_price: row.custom_price ? row.original_price : row.variant_price,
                                original_price: row.variant_price
                            };
                        }
                    } else {
                        return {
                            ...row,
                            custom_price: false,
                            variant_price: row.custom_price ? row.original_price : row.variant_price,
                            original_price: row.variant_price
                        };
                    }
                })
            }
        }, this.hideCustomPriceInput);
    };

    resetCustomPrice = () => {
        const { modalItem } = this.state;

        this.setState({
            customPriceEditMode: false,
            customPriceValue: 0,
            modalItem: {
                ...modalItem,
                pos_item_variants: modalItem.pos_item_variants.map(row => {
                    return {
                        ...row,
                        custom_price: false,
                        variant_price: row.custom_price ? row.original_price : row.variant_price,
                        original_price: row.variant_price
                    };
                })
            }
        }, this.hideCustomPriceInput);
    };

    processWorkerStatus = (commissionWorker) => {
        const appointments = this.props.appointments;
        const pendingTrxs = this.props.posTrxs.filter(trx => trx.trx_status_id === 2);
        const currentMoment = moment();
        let workerStatus = " (Available)";
        let workerStyle = {
            color: GREEN
        };

        if (!empty(appointments)) {
            appointments.map(appointment => {
                const startTimeMoment = moment(appointment.start_time, MOMENT_SQL_DATETIME_FORMAT);

                if (
                    commissionWorker.business_commission_worker_id === appointment.business_commission_worker_id
                    && currentMoment.isBefore(startTimeMoment)
                ) {
                    workerStatus = ` (Booked - ${startTimeMoment.format(MOMENT_SQL_TIME_FORMAT_MINUTE)})`;
                    workerStyle = {
                        color: ORANGE
                    };
                }

                return appointment;
            });
        }

        if (!empty(pendingTrxs)) {
            pendingTrxs.map(trx => {
                trx.details.map(detail => {
                    let workerExists = false;

                    if (detail.business_commission_worker_id === commissionWorker.business_commission_worker_id) {
                        workerExists = true;
                    }

                    if (
                        !empty(detail.commission_worker)
                        && detail.commission_worker.business_commission_worker_id === commissionWorker.business_commission_worker_id
                    ) {
                        workerExists = true;
                    }

                    if (!empty(detail.commission_workers)) {
                        detail.commission_workers.map(worker => {
                            if (worker.commission_worker.business_commission_worker_id === commissionWorker.business_commission_worker_id) {
                                workerExists = true;
                            }

                            return worker;
                        });
                    }

                    if (!empty(detail.pos_item_package_details)) {
                        detail.pos_item_package_details.map(packageDetail => {
                            if (
                                !empty(packageDetail.business_commission_worker)
                                && packageDetail.business_commission_worker.business_commission_worker_id === commissionWorker.business_commission_worker_id
                            ) {
                                workerExists = true;
                            }

                            if (!empty(packageDetail.commission_workers)) {
                                packageDetail.commission_workers.map(worker => {
                                    if (worker.commission_worker.business_commission_worker_id === commissionWorker.business_commission_worker_id) {
                                        workerExists = true;
                                    }

                                    return worker;
                                });
                            }

                            return packageDetail;
                        });
                    }

                    if (workerExists) {
                        workerStatus = " (Working)";
                        workerStyle = {
                            color: RED
                        };
                    }

                    return detail;
                });

                return trx;
            });
        }

        return { workerStatus, workerStyle };
    };

    render() {
        const { posConfig, onCancel, closable, itemType } = this.props;
        let currentTrx = this.props.currentTrx;
        if (empty(currentTrx)) {
            currentTrx = this.props.trx;
        }

        const {
            formLoading,
            modalItem,
            modalItemQtyText,
            modalItemVariantId,
            modalItemDefinedDiscounts,
            modalItemDiscountType,
            modalItemDiscountPercent,
            modalItemDiscountValueText,
            modalItemNotes,
            modalItemSecretNotes,
            modalCouponActivationDate,
            modalCouponNotes,
            modalItemCommissionWorker,
            itemSellers,
            modalItemPackageDetails,
            customPriceEditMode,
            customPriceValue,
            modifyQtyModalOpened,
            modifyQtyModalLoading,
            modifyQtyPassword,
            modifyQtyReason
        } = this.state;
        const RadioGroup = Radio.Group;
        const RadioButton = Radio.Button;
        const currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;

        let defaultCouponActivationDate = modalCouponActivationDate;
        if (!empty(currentTrx.customer) && itemType === "coupon") {
            if (!empty(currentTrx.customer.active_coupons)) {
                currentTrx.customer.active_coupons.map(row => {
                    if (!empty(modalItem.coupon_meta.coupon_id)) {
                        if (row.coupon_id === modalItem.coupon_meta.coupon_id) {
                            defaultCouponActivationDate = moment(row.expired_date, MOMENT_SQL_DATE_FORMAT).add(1, "days").format(MOMENT_SQL_DATE_FORMAT);
                        }
                    }
                    return row;
                });
            }
        }

        let filteredCommissionWorker = posConfig.commission_worker;
        if (!empty(modalItem.pos_item_commission_worker_groups)) {
            filteredCommissionWorker = filteredCommissionWorker.filter(worker => {
                let eligibleWorker = false;
                modalItem.pos_item_commission_worker_groups.map(workerGroup => {
                    if (eligibleWorker === false) {
                        eligibleWorker = workerGroup.business_commission_worker_group_id === worker.business_commission_worker_group_id;
                    }
                    return workerGroup;
                });

                return eligibleWorker;
            });
        }

        let filteredSeller = [];
        posConfig.commission_worker.map(row => {
            filteredSeller.push(
                <Select.Option key={row.business_commission_worker_id}
                               value={row.business_commission_worker_id}
                               label={`${row.firstname} ${row.lastname}`}
                >
                    {`${row.firstname} ${row.lastname}`}
                </Select.Option>
            );
            return row;
        });

        const discountAvailabilityFilter = (discounts) => {
            return discounts.filter(discount => {
                const currentDay = moment().format(MOMENT_SQL_DATE_FORMAT);
                const currentMoment = moment();
                const startTime = discount.start_date + " 00:00:01";
                const startTimeMoment = moment(startTime, MOMENT_SQL_DATETIME_FORMAT);
                const endTime = discount.end_date + " 23:59:59";
                const endTimeMoment = moment(endTime, MOMENT_SQL_DATETIME_FORMAT);
                let isActive = false;
                let isAvailable = false;

                if (currentMoment.isBetween(startTimeMoment, endTimeMoment, null, "[]")) {
                    isActive = true;
                }

                if(discount.availability_type === "all_day"){
                    isAvailable = true;
                }

                if (discount.availability_type === "special_day") {
                    discount.pos_item_discount_days.forEach(discountDay => {
                        const currentDayOfWeek = currentMoment.day();
                        const openHour = currentDay + " " + discountDay.open_hour;
                        const openHourMoment = moment(openHour, MOMENT_SQL_DATETIME_FORMAT);
                        const closeHour = currentDay + " " + discountDay.close_hour;
                        const closeHourMoment = moment(closeHour, MOMENT_SQL_DATETIME_FORMAT);

                        if (currentDayOfWeek === discountDay.day_id && currentMoment.isBetween(openHourMoment, closeHourMoment, null, "[]")) {
                            isAvailable = true;
                        }
                    });
                }

                if (discount.availability_type === "special_date") {
                    discount.pos_item_discount_special_dates.forEach(discountDate => {
                        const currentDateOfMonth = currentMoment.date();
                        const openHour = currentDay + " " + discountDate.start_time;
                        const openHourMoment = moment(openHour, MOMENT_SQL_DATETIME_FORMAT);
                        const closeHour = currentDay + " " + discountDate.end_time;
                        const closeHourMoment = moment(closeHour, MOMENT_SQL_DATETIME_FORMAT);

                        if (discountDate.day === currentDateOfMonth && currentMoment.isBetween(openHourMoment, closeHourMoment, null, "[]")) {
                            isAvailable = true;
                        }
                    });
                }

                let shouldBeFiltered = false;

                if(!empty(discount.authorized_user_groups)){
                    let authorized_user_groups_ids = discount.authorized_user_groups.split(',');

                    authorized_user_groups_ids = authorized_user_groups_ids.map(row => parseInt(row));

                    if(!authorized_user_groups_ids.includes(posConfig.business_user_group_id)){
                        shouldBeFiltered = true;
                    }
                }

                if(!empty(discount.authorized_business_memberships)){
                    if(empty(currentTrx.customer)){
                        shouldBeFiltered = true;
                    }else{
                        const customer_membership_name = currentTrx.customer.membership_name;
                        let hasRequiredMembership = false;

                        if(!empty(customer_membership_name)){
                            const authorized_business_memberships_array = discount.authorized_business_memberships.split(',');
                            authorized_business_memberships_array.forEach(authorized_business_membership_id => {
                                const allowed_membership_id = parseInt(authorized_business_membership_id);
                                const allowed_membership = posConfig.business_memberships?.find(row => row.business_membership_id === allowed_membership_id);
                                if(!empty(allowed_membership)){
                                    if(allowed_membership.membership_name === customer_membership_name){
                                        hasRequiredMembership = true;
                                    }
                                }
                            });
                        }

                        if(!hasRequiredMembership){
                            shouldBeFiltered = true;
                        }
                    }
                }

                return isActive && isAvailable && !shouldBeFiltered;
            });
        };

        let eligibleItemDiscounts = [];
        posConfig.pos_item_discounts.forEach(pos_item_discount => {
            if (pos_item_discount.discount_type === "req-item") {
                if (pos_item_discount.req_pos_item_id !== 0 && pos_item_discount.req_pos_item_id === modalItem.pos_item_id) {
                    eligibleItemDiscounts = eligibleItemDiscounts.concat(pos_item_discount);
                }

                if (pos_item_discount.pos_item_category_id !== 0 && pos_item_discount.pos_item_category_id === modalItem.pos_item_category_id) {
                    eligibleItemDiscounts = eligibleItemDiscounts.concat(pos_item_discount);
                }

                if (currentTrx.trx_type === TRX_TYPE_COUPON_SALES) {
                    if (pos_item_discount.pos_item_category_id !== 0 && pos_item_discount.pos_item_category_id === modalItem.coupon_meta?.coupon_category_id) {
                        eligibleItemDiscounts = eligibleItemDiscounts.concat(pos_item_discount);
                    }

                    if (
                        pos_item_discount.coupon_id !== 0
                        && modalItem.coupon_meta?.coupon_group_id === 0
                        && pos_item_discount.coupon_id === modalItem.coupon_meta?.coupon_id
                    ) {
                        eligibleItemDiscounts = eligibleItemDiscounts.concat(pos_item_discount);
                    }

                    if (
                        pos_item_discount.coupon_group_id !== 0
                        && pos_item_discount.coupon_group_id === modalItem.coupon_meta?.coupon_group_id
                    ) {
                        eligibleItemDiscounts = eligibleItemDiscounts.concat(pos_item_discount);
                    }
                }

                eligibleItemDiscounts = discountAvailabilityFilter(eligibleItemDiscounts);
            }
        });

        return (
            <Modal
                title={empty(modalItem) ? "" : modalItem.item_name}
                visible={this.props.modalOpened}
                closable={closable}
                maskClosable={closable}
                onOk={this.handleItemModalOk}
                onCancel={
                    empty(onCancel) ? this.handleItemModalCancel : onCancel
                }
                cancelText="Cancel"
                okText="OK"
                confirmLoading={formLoading}
            >
                {posConfig.pos_cashier_delete_by_pin === 1 && (
                    <Modal
                        title={`Otorisasi Pengubahan Qty Item`}
                        visible={modifyQtyModalOpened}
                        onCancel={this.closeModifyQtyModal}
                        onOk={this.validateModifyQty}
                        closable={true}
                        confirmLoading={modifyQtyModalLoading}
                    >
                        <h4 style={{ marginTop: 10 }}>Password {modifyQtyPassword?.split("").map(val => "*").join("")}</h4>
                        <Input
                            value={modifyQtyPassword}
                            style={{ color: "white" }}
                            onChange={e => {
                                this.setState({
                                    modifyQtyPassword: e.target.value
                                });
                            }}
                            type="text"
                            autoComplete="off"
                        />

                        <h4 style={{ marginTop: 10 }}>Alasan Pengurangan Qty</h4>
                        <Input
                            value={modifyQtyReason}
                            onChange={e => {
                                this.setState({
                                    modifyQtyReason: e.target.value
                                });
                            }}
                            placeholder="Alasan Pengubahan Qty"
                        />

                    </Modal>
                )}


                <form onSubmit={this.onSubmitForm} method="post">
                    {!empty(modalItem) && (
                        <div>
                            {!this.props.postEditMode && (
                                <>
                                    <div className={styles.qtyWrapper}>
                                        <button
                                            type="button"
                                            className={styles.qtyDecrease}
                                            onClick={this.decreaseQty}
                                        >
                                            <Icon
                                                type="minus-square"
                                                style={{ fontSize: 18 }}
                                            />
                                        </button>

                                        <Input
                                            defaultValue={0}
                                            type="text"
                                            size="large"
                                            style={{ width: 100 }}
                                            className={styles.qtyInput}
                                            ref={this.setQtyInputRef}
                                            onChange={e => {
                                                const inputCallback = (value, valueText) => {
                                                    this.setState({
                                                        modalItemQty: value,
                                                        modalItemQtyText: valueText
                                                    });
                                                };
                                                processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                            }}
                                            value={modalItemQtyText}
                                        />

                                        <button
                                            type="button"
                                            className={styles.qtyIncrease}
                                            onClick={this.increaseQty}
                                        >
                                            <Icon
                                                type="plus-square"
                                                style={{ fontSize: 18 }}
                                            />
                                        </button>
                                    </div>

                                    <Divider/>

                                    {modalItem.pos_item_variants.length > 1 && (
                                        <div style={{ marginBottom: 15 }}>
                                            <h4 style={{ marginTop: 0 }}>
                                                Pilih Varian Produk
                                            </h4>
                                            <RadioGroup
                                                defaultValue={modalItemVariantId}
                                                buttonStyle="solid"
                                                onChange={e => {
                                                    this.setComponentState({
                                                        modalItemVariantId:
                                                        e.target.value
                                                    });
                                                }}
                                            >
                                                {modalItem.pos_item_variants.map(
                                                    variant => {
                                                        return (
                                                            <RadioButton
                                                                value={
                                                                    variant.pos_item_variant_id
                                                                }
                                                                key={
                                                                    variant.pos_item_variant_id
                                                                }
                                                            >
                                                                <strong>
                                                                    {
                                                                        variant.variant_name
                                                                    }
                                                                </strong>{" "}
                                                                ({this.currencyCode}{" "}
                                                                {number_format(
                                                                    variant.variant_price,
                                                                    0
                                                                )}
                                                                )
                                                            </RadioButton>
                                                        );
                                                    }
                                                )}
                                            </RadioGroup>
                                        </div>
                                    )}

                                    {(modalItem.pos_item_variants.length === 1 || (modalItem.pos_item_variants.length > 1 && !empty(modalItemVariantId))) && (
                                        <div style={{ marginBottom: 15 }}>
                                            <h4 style={{ marginTop: 0 }}>Harga</h4>

                                            {!customPriceEditMode && (
                                                <React.Fragment>
                                                    {this.currencyCode}{" "}
                                                    {modalItem.pos_item_variants.length === 1 && number_format(
                                                        modalItem.pos_item_variants[0].variant_price, 0
                                                    )}

                                                    {modalItem.pos_item_variants.length > 1 && !empty(modalItemVariantId) && number_format(
                                                        modalItem.pos_item_variants.find(row => row.pos_item_variant_id === modalItemVariantId).variant_price, 0
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {
                                                (
                                                    modalItem.is_custom_price === 1 ||
                                                    modalItem.coupon_meta?.is_custom_price === 1
                                                )
                                                && (
                                                    <React.Fragment>
                                                        {!customPriceEditMode && (
                                                            <Icon type="edit"
                                                                  style={{ marginLeft: 5, cursor: "pointer" }}
                                                                  onClick={() => {
                                                                      if (modalItem.pos_item_variants.length === 1) {
                                                                          this.showCustomPriceInput(modalItem.pos_item_variants[0].variant_price);
                                                                      }

                                                                      if (modalItem.pos_item_variants.length > 1 && !empty(modalItemVariantId)) {
                                                                          this.showCustomPriceInput(modalItem.pos_item_variants.find(row => row.pos_item_variant_id === modalItemVariantId).variant_price);
                                                                      }
                                                                  }}
                                                            />)}

                                                        {customPriceEditMode && (
                                                            <React.Fragment>
                                                                <Input
                                                                    min={0}
                                                                    defaultValue={0}
                                                                    addonBefore={this.currencyCode}
                                                                    style={{ width: "100%", marginTop: 10 }}
                                                                    onChange={this.onChangeCustomPrice}
                                                                    onKeyDown={this.onKeyDownCustomPrice}
                                                                    value={customPriceValue}
                                                                    ref={this.customPriceInput}
                                                                />
                                                                <Button type="primary"
                                                                        size={"small"}
                                                                        htmlType={"button"}
                                                                        style={{
                                                                            marginTop: 8
                                                                        }}
                                                                        onClick={this.onSubmitCustomPrice}
                                                                >
                                                                    Ubah Harga
                                                                </Button>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}

                                            {!customPriceEditMode && modalItem.pos_item_variants.length === 1 && modalItem.pos_item_variants[0].custom_price && (
                                                <React.Fragment>
                                                    <br/>
                                                    <Typography.Text type="danger">
                                                        Harga telah diubah dari harga asli - {this.currencyCode} {number_format(modalItem.pos_item_variants[0].original_price, 0)}
                                                    </Typography.Text>
                                                    <br/>
                                                    <Button type="danger"
                                                            size={"small"}
                                                            htmlType={"button"}
                                                            style={{
                                                                marginTop: 7
                                                            }}
                                                            onClick={this.resetCustomPrice}
                                                    >
                                                        Kembalikan ke Harga Asli
                                                    </Button>
                                                </React.Fragment>
                                            )}

                                            {!customPriceEditMode && modalItem.pos_item_variants.length > 1 && modalItem.pos_item_variants.find(row => row.pos_item_variant_id === modalItemVariantId).custom_price && (
                                                <React.Fragment>
                                                    <br/>
                                                    <Typography.Text type="danger">
                                                        Harga telah diubah dari harga asli - {this.currencyCode} {number_format(modalItem.pos_item_variants.find(row => row.pos_item_variant_id === modalItemVariantId).original_price, 0)}
                                                    </Typography.Text>
                                                    <br/>
                                                    <Button type="danger"
                                                            size={"small"}
                                                            htmlType={"button"}
                                                            style={{
                                                                marginTop: 7
                                                            }}
                                                            onClick={this.resetCustomPrice}
                                                    >
                                                        Kembalikan ke Harga Asli
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}

                                    {!empty(modalItem.pos_item_pos_item_modifiers) && (
                                        <div style={{ marginBottom: 15 }}>
                                            <h4 style={{ marginTop: 0 }}>Modifiers</h4>
                                            {this.renderModifiers(
                                                modalItem.pos_item_pos_item_modifiers
                                            )}
                                        </div>
                                    )}

                                    {!empty(eligibleItemDiscounts) && (
                                        <React.Fragment>
                                            <h4 style={{ marginTop: 15 }}>Item Discounts</h4>
                                            <RadioGroup
                                                defaultValue={empty(modalItemDefinedDiscounts) ? "" : modalItemDefinedDiscounts[0].pos_item_discount_id}
                                                buttonStyle="solid"
                                                onChange={e => {
                                                    this.setComponentState({
                                                        modalItemDefinedDiscounts: empty(e.target.value) ? [] : posConfig.pos_item_discounts.filter(pos_item_discount => pos_item_discount.pos_item_discount_id === e.target.value)
                                                    });
                                                }}
                                            >
                                                <RadioButton value={``}>
                                                    None
                                                </RadioButton>
                                                {eligibleItemDiscounts.map(itemDiscount => (
                                                    <RadioButton value={itemDiscount.pos_item_discount_id}>
                                                        {itemDiscount.discount_name}
                                                    </RadioButton>
                                                ))}

                                            </RadioGroup>

                                            {!empty(modalItemDefinedDiscounts) && modalItemDefinedDiscounts.map(itemDiscount => (
                                                <div style={{
                                                    marginTop: 10
                                                }}>
                                                    Disc {number_format(itemDiscount.deduction_value)}{itemDiscount.deduction_type === "percentage" ? "%" : ` ${currencyCode}`}{itemDiscount.deduction_type === "percentage" && itemDiscount.max_deduction_value > 0 ? `, Max. ${number_format(itemDiscount.max_deduction_value)} ${currencyCode}.` : ""}
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    )}

                                    {
                                        posConfig.auth_role_accesses.DISCOUNT_ITEM === 1 &&
                                        empty(modalItemDefinedDiscounts) &&
                                        (
                                            (modalItem.is_custom_discount === 1 && currentTrx.trx_type === TRX_TYPE_NORMAL) ||
                                            (currentTrx.trx_type === TRX_TYPE_COUPON_SALES && modalItem.coupon_meta?.is_custom_discount === 1)
                                        ) && (
                                            <React.Fragment>
                                                <h4 style={{ marginTop: 15 }}>Custom Discount</h4>
                                                <RadioGroup
                                                    defaultValue={modalItemDiscountType}
                                                    buttonStyle="solid"
                                                    onChange={e => {
                                                        this.setComponentState({
                                                            modalItemDiscountType: e.target.value
                                                        });
                                                    }}
                                                >
                                                    <RadioButton value="percentage">
                                                        Persentase (%)
                                                    </RadioButton>
                                                    <RadioButton value="value">
                                                        Nominal ({currencyCode})
                                                    </RadioButton>
                                                </RadioGroup>
                                                <br/>
                                                {modalItemDiscountType === "percentage" && (
                                                    <InputNumber
                                                        min={0}
                                                        max={100}
                                                        defaultValue={0}
                                                        step={0.1}
                                                        precision={2}
                                                        placeholder="%"
                                                        style={{ width: "100%", marginTop: 10 }}
                                                        onChange={e => {
                                                            this.setComponentState({
                                                                modalItemDiscountPercent: e
                                                            });
                                                        }}
                                                        value={modalItemDiscountPercent}
                                                    />
                                                )}
                                                {modalItemDiscountType === "value" && (
                                                    <Input
                                                        defaultValue={0}
                                                        type="text"
                                                        addonBefore={this.currencyCode}
                                                        size="large"
                                                        style={{ width: "100%", marginTop: 10 }}
                                                        onChange={e => {
                                                            const inputCallback = (value, valueText) => {
                                                                this.setState({
                                                                    modalItemDiscountValue: value,
                                                                    modalItemDiscountValueText: valueText
                                                                });
                                                            };
                                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                        }}
                                                        value={modalItemDiscountValueText}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}

                                    {itemType === "coupon" && (
                                        <React.Fragment>
                                            <div className={styles.formItem}>
                                                <h4>Coupon Notes</h4>
                                                <Input.TextArea
                                                    autoSize
                                                    placeholder="Catatan Kupon"
                                                    onChange={e => {
                                                        this.setComponentState({
                                                            modalCouponNotes: e.target.value
                                                        });
                                                    }}
                                                    value={modalCouponNotes}
                                                />
                                            </div>

                                            {modalItem.coupon_meta
                                                && (
                                                    modalItem.coupon_meta.coupon_start_date_type === "MANUAL_INPUT"
                                                    || (
                                                        modalItem.coupon_meta.coupon_group_id !== 0
                                                        && modalItem.coupon_meta.coupons.find(c => c.coupon_start_date_type === "MANUAL_INPUT")
                                                    )
                                                )
                                                && (
                                                    <div className={styles.formItem}>
                                                        <h4>Coupon Start Date</h4>
                                                        {modalItem.coupon_meta.coupon_group_id !== 0
                                                            && modalItem.coupon_meta.coupons.find(c => c.coupon_start_date_type === "MANUAL_INPUT")
                                                            && (
                                                                <p>
                                                                    This date is applied for {modalItem.coupon_meta.coupons.filter(c => c.coupon_start_date_type === "MANUAL_INPUT").map(c => c.coupon_name).join(", ")}
                                                                </p>
                                                            )}

                                                        <DatePicker
                                                            format={MOMENT_SQL_DATE_FORMAT}
                                                            defaultValue={moment(defaultCouponActivationDate, MOMENT_SQL_DATE_FORMAT)}
                                                            onChange={(date, dateString) => {
                                                                this.setState({
                                                                    modalCouponActivationDate: dateString
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                        </React.Fragment>
                                    )}

                                    <h4 style={{ marginTop: 15 }}>Catatan</h4>
                                    <Input.TextArea
                                        autoSize
                                        placeholder="Catatan / Keterangan Tambahan"
                                        onChange={e => {
                                            this.setComponentState({
                                                modalItemNotes: e.target.value
                                            });
                                        }}
                                        value={modalItemNotes}
                                    />

                                    {posConfig.secret_notes === 1 && (
                                        <React.Fragment>
                                            <h4 style={{ marginTop: 15 }}>Catatan Tersembunyi</h4>
                                            <Input.TextArea
                                                autoSize
                                                placeholder="Catatan / Keterangan Tambahan"
                                                onChange={e => {
                                                    this.setComponentState({
                                                        modalItemSecretNotes: e.target.value
                                                    });
                                                }}
                                                value={modalItemSecretNotes}
                                            />
                                        </React.Fragment>
                                    )}
                                </>
                            )}

                            {itemType === "normal" &&
                                posConfig.is_pos_commission_scheme === 1 &&
                                posConfig.is_pos_multicommission_scheme === 0 &&
                                modalItem.is_package === 0 &&
                                !empty(filteredCommissionWorker) && (
                                    <React.Fragment>
                                        <h4 style={{ marginTop: 15 }}>
                                            Worker
                                        </h4>
                                        {empty(modalItemCommissionWorker) && (
                                            <Select
                                                showSearch={true}
                                                optionFilterProp="children"
                                                style={{
                                                    width: "100%"
                                                }}
                                                onChange={(val, opt) => {
                                                    this.onSingleCommissionWorkerSelected(val, filteredCommissionWorker);
                                                }}
                                                allowClear={true}
                                                filterOption={(input, option) =>
                                                    option.props.children.props.children
                                                        .join("")
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                placeholder="Pilih worker"
                                            >
                                                {filteredCommissionWorker.map(
                                                    (
                                                        commissionWorker,
                                                        index
                                                    ) => {
                                                        const { workerStatus, workerStyle } = this.processWorkerStatus(commissionWorker);
                                                        return (
                                                            <Select.Option
                                                                key={index}
                                                                value={
                                                                    commissionWorker.business_commission_worker_id
                                                                }
                                                            >
                                                            <span style={workerStyle}>
                                                            {
                                                                commissionWorker.firstname
                                                            }{" "}
                                                                {
                                                                    commissionWorker.lastname
                                                                }
                                                                {workerStatus}
                                                            </span>
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        )}

                                        {!empty(modalItemCommissionWorker) && (
                                            <Select
                                                showSearch={true}
                                                optionFilterProp="children"
                                                style={{ width: "100%" }}
                                                onChange={(val, opt) => {
                                                    this.onSingleCommissionWorkerSelected(val, filteredCommissionWorker);
                                                }}
                                                allowClear={true}
                                                defaultValue={
                                                    modalItemCommissionWorker.business_commission_worker_id
                                                }
                                                filterOption={(input, option) =>
                                                    option.props.children.props.children
                                                        .join("")
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                placeholder="Pilih worker"
                                            >
                                                {filteredCommissionWorker.map(
                                                    (
                                                        commissionWorker,
                                                        index
                                                    ) => {
                                                        const { workerStatus, workerStyle } = this.processWorkerStatus(commissionWorker);
                                                        return (
                                                            <Select.Option
                                                                value={
                                                                    commissionWorker.business_commission_worker_id
                                                                }
                                                                key={index}
                                                            >
                                                            <span style={workerStyle}>
                                                            {
                                                                commissionWorker.firstname
                                                            }{" "}
                                                                {
                                                                    commissionWorker.lastname
                                                                }
                                                                {workerStatus}
                                                            </span>
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    </React.Fragment>
                                )}

                            {itemType === "normal" &&
                                posConfig.is_pos_commission_scheme === 1 &&
                                posConfig.is_pos_multicommission_scheme === 1 &&
                                modalItem.is_package === 0 &&
                                !empty(filteredCommissionWorker) && (
                                    <React.Fragment>
                                        <h4 style={{ marginTop: 15 }}>
                                            Worker
                                        </h4>
                                        {this.renderMultiCommissionWorkers(filteredCommissionWorker)}
                                    </React.Fragment>
                                )}

                            {!this.props.postEditMode && (
                                <>

                                    {posConfig.is_pos_commission_seller === 1 &&
                                        (
                                            (itemType === "normal" && posConfig.is_pos_commission_seller_general_sales === 1)
                                            || (itemType === "coupon" && posConfig.is_pos_commission_seller_coupon_sales === 1)
                                        )
                                        && (
                                            <React.Fragment>
                                                <h4 style={{ marginTop: 15 }}>
                                                    Seller
                                                </h4>

                                                {posConfig.is_pos_commission_multi_seller === 0 && (
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        placeholder="Pilih seller"
                                                        defaultValue={(!empty(itemSellers) && itemSellers.length === 1) ? itemSellers[0].business_commission_worker_id : null}
                                                        onChange={(e) => {
                                                            const chosenSellers = posConfig.commission_worker.filter(seller => {
                                                                return seller.business_commission_worker_id === e;
                                                            }).map(seller => {
                                                                return {
                                                                    ...seller,
                                                                    percentage: 100,
                                                                    pos_item_commission_workers: [] //seller.pos_item_commission_workers.filter(item => item.pos_item_id === modalItem.pos_item_id)
                                                                };
                                                            });
                                                            this.setState({
                                                                itemSellers: chosenSellers
                                                            });
                                                        }}
                                                        showSearch={true}
                                                        allowClear={true}
                                                        optionLabelProp="label"
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {posConfig.commission_worker.map(row => (
                                                            <Select.Option key={row.business_commission_worker_id}
                                                                           value={row.business_commission_worker_id}
                                                                           label={`${row.firstname} ${row.lastname}`}>
                                                                {`${row.firstname} ${row.lastname}`}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}

                                                {posConfig.is_pos_commission_multi_seller === 1 && (
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: "100%" }}
                                                        placeholder="Pilih seller"
                                                        defaultValue={itemSellers.map(row => row.business_commission_worker_id)}
                                                        onChange={(e) => {
                                                            const chosenSellers = posConfig.commission_worker.filter(seller => {
                                                                return e.find(row => seller.business_commission_worker_id === row);
                                                            }).map((seller, index, sellerArray) => {
                                                                let percentage = 100;
                                                                if (posConfig.is_pos_commission_auto_split_multi_seller === 1) {
                                                                    percentage = 100 / sellerArray.length;
                                                                }

                                                                return {
                                                                    ...seller,
                                                                    percentage,
                                                                    pos_item_commission_workers: [] //seller.pos_item_commission_workers.filter(item => item.pos_item_id === modalItem.pos_item_id)
                                                                };
                                                            });
                                                            this.setState({
                                                                itemSellers: chosenSellers
                                                            });
                                                        }}
                                                        optionLabelProp="label"
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {filteredSeller}
                                                    </Select>
                                                )}
                                            </React.Fragment>
                                        )}


                                    {
                                        currentTrx.trx_type === TRX_TYPE_NORMAL &&
                                        this.props.posConfig.is_service_area_scheme === 1 &&
                                        this.props.posConfig.service_area_type === "item" &&
                                        modalItem.is_package === 0 &&
                                        this.renderItemServiceArea()
                                    }

                                </>
                            )}

                            {modalItem.is_package === 1 && (
                                <React.Fragment>
                                    <Divider/>
                                    <h3>Package Items</h3>
                                    {this.renderPackageItems(
                                        modalItemPackageDetails
                                    )}
                                </React.Fragment>
                            )}

                            <br/>
                            <br/>
                            <br/>

                            <input
                                type="submit"
                                style={{
                                    position: "absolute",
                                    left: -9999,
                                    width: 1,
                                    height: 1
                                }}
                                tabIndex={-1}
                            />
                        </div>
                    )}
                </form>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    return {
        posItems: state.posState.posItems,
        posItemCategories: state.posState.posItemCategories,
        currentLocalTrxId,
        currentTrx: state.posState.posTrx.find(
            trx => trx.local_trx_id === currentLocalTrxId
        ),
        posTrxs: state.posState.posTrx,
        appointments: state.posState.appointments,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    addTrxDetail: posOperations.addTrxDetail,
    editTrxDetail: posOperations.editTrxDetail,
    logModifyTrxDetail: posOperations.logModifyTrxDetail,
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosItemModal);
