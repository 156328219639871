import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { PosPriceList } from '../modules';
import './PosReceipt.module.scss';
import connect from 'react-redux/es/connect/connect';
import { empty } from "../shared/helpers/generalHelper";

class PosReceipt extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        local_trx_id: PropTypes.string,
        modifier: PropTypes.string,
        onCloseMobile: PropTypes.func,
        onPrint: PropTypes.func,
        showSeller: PropTypes.bool,
        showWorker: PropTypes.bool
    };

    static defaultProps = {
        local_trx_id: null,
        modifier: null,
        onCloseMobile: ()=>{},
        onPrint: ()=>{},
        showSeller: null,
        showWorker: null
    };

    constructor(props) {
        super(props);

        const local_trx_id = this.props.match?.params?.local_trx_id || this.props.local_trx_id;
        const trx = this.props.trxs.find(
            trx => trx.local_trx_id === local_trx_id
        );
        const modifier = this.props.match?.params?.modifier || this.props.modifier;
        const posConfig = trx?.cashier_configs || this.props.posConfig;

        this.state = {
            local_trx_id,
            trx,
            modifier,
            posConfig
        };
    }

    componentDidMount() {
        if(this.props.cashierConfig.displayMode === 'DESKTOP' || empty(this.props.cashierConfig.displayMode)) {
            if (this.state.modifier === 'print' || this.state.modifier === 'pre-settlement' || this.state.modifier === 'order-preview') {
                setTimeout(this.printPage, 500);
            }
        }
    }

    printPage = () => {
        window.print();
        setTimeout(window.close, 0);
    };

    render() {
        const { trx, modifier, posConfig } = this.state;


        if (modifier === 'pre-settlement') {
            return (
                <Row>
                    <Col span={24}>
                        <PosPriceList
                            trx={trx}
                            posConfig={posConfig}
                            isPrint={true}
                            preSettlement={true}
                            mobilePrintable={true}
                            mobilePrintCloseable={true}
                            onCloseMobile={this.props.onCloseMobile}
                            showSeller={this.props.showSeller}
                            showWorker={this.props.showWorker}
                        />
                    </Col>
                </Row>
            );
        } else if (modifier === 'order-preview') {
            return (
                <Row>
                    <Col span={24}>
                        <PosPriceList
                            trx={trx}
                            posConfig={posConfig}
                            isPrint={true}
                            orderPreview={true}
                            orderPreviewUnprinted={false}
                            mobilePrintable={true}
                            mobilePrintCloseable={true}
                            onCloseMobile={this.props.onCloseMobile}
                            onPrint={this.props.onPrint}
                            showSeller={this.props.showSeller}
                            showWorker={this.props.showWorker}
                        />
                    </Col>
                </Row>
            );
        } else if (modifier === 'order-preview-unprinted') {
            return (
                <Row>
                    <Col span={24}>
                        <PosPriceList
                            trx={trx}
                            posConfig={posConfig}
                            isPrint={true}
                            orderPreview={true}
                            orderPreviewUnprinted={true}
                            mobilePrintable={true}
                            mobilePrintCloseable={true}
                            onCloseMobile={this.props.onCloseMobile}
                            onPrint={this.props.onPrint}
                            showSeller={this.props.showSeller}
                            showWorker={this.props.showWorker}
                        />
                    </Col>
                </Row>
            );
        } else if (modifier === 'completed') {
            return (
                <Row>
                    <Col span={24}>
                        <PosPriceList
                            trx={trx}
                            posConfig={posConfig}
                            isPrint={true}
                            copy={true}
                            mobilePrintable={true}
                            mobilePrintCloseable={false}
                            showSeller={this.props.showSeller}
                            showWorker={this.props.showWorker}
                        />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row>
                    <Col span={24}>
                        <PosPriceList
                            trx={trx}
                            posConfig={posConfig}
                            isPrint={true}
                            isLegit={true}
                            preSettlement={false}
                            mobilePrintable={true}
                            showSeller={this.props.showSeller}
                            showWorker={this.props.showWorker}
                        />
                    </Col>
                </Row>
            );
        }


    }
}

const mapStateToProps = state => {
    return {
        trxs: state.posState.posTrx,
        cashierConfig: state.authState.configs
    };
};

export default connect(mapStateToProps)(PosReceipt);
