import React, { Component } from "react";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import { apiCall } from "../shared/helpers/networkHelper";
import { GET_REPORT_CUSTOM_OMZET_RECAPITULATION } from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { Col, Empty, Row, Spin, Table } from "antd";
import { number_format } from "../shared/helpers/stringHelper";
import { connect } from "react-redux";

class ReportCustomOmzetRecapPrint extends Component {

    constructor(props) {
        super(props);

        const url = new URL(window.location.href);

        this.state = {
            loading: false,
            recaps: null,
            reportDateFrom: url.searchParams.get('start_date'),
            reportDateTo: url.searchParams.get('end_date'),
            cashiers: [],
            totalOmzet: 0,
            totalFee: 0
        };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        const { reportDateFrom, reportDateTo } = this.state;
        const cashiers = JSON.parse(localStorage.getItem('sign_history'));

        this.loaderShow();
        apiCall(
            GET_REPORT_CUSTOM_OMZET_RECAPITULATION + `?start_date=${reportDateFrom}&end_date=${reportDateTo}`,
            "get",
            null,
            res => {
                const recaps = res.data;

                const omzetReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.drv_omzet)) + accumulator;
                const totalOmzet = recaps.filter(trx => !trx.fake).reduce(omzetReducer, 0);

                const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.total_fee)) + accumulator;
                const totalFee = recaps.filter(trx => !trx.fake).reduce(sumReducer, 0);

                this.setState({
                    recaps: res.data,
                    cashiers: cashiers,
                    totalOmzet,
                    totalFee
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }, res => {
                this.loaderHide();
            }
        );
    }

    loaderShow = () => {
        this.setState({loading: true});
    };

    loaderHide = () => {
        this.setState({loading: false});
    };

    renderRecapTable = (recaps) => {
        let mappedRecaps = recaps.map((recap, index) => {
            return {
                no: index+1,
                ...recap
            }
        });
        mappedRecaps = mappedRecaps.concat({
            ...mappedRecaps[0],
            fake: true
        });

        const columns = [
            {
                title: "No",
                dataIndex: "no",
                key: "no",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Tanggal Transaksi",
                dataIndex: "trx_date",
                key: "trx_date",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Service",
                dataIndex: "service_total_commission",
                key: "service_total_commission",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.service_total_commission)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Retail",
                dataIndex: "retail_total_commission",
                key: "retail_total_commission",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.retail_total_commission)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Omzet",
                dataIndex: "drv_omzet",
                key: "drv_omzet",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.drv_omzet)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Cost",
                dataIndex: "drv_variant_cost",
                key: "drv_variant_cost",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.drv_variant_cost)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Royalty 9%",
                dataIndex: "royalty_fee",
                key: "royalty_fee",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.royalty_fee)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "MKT Fee 1%",
                dataIndex: "marketing_fee",
                key: "marketing_fee",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.marketing_fee)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Total Fee",
                dataIndex: "total_fee",
                key: "total_fee",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.total_fee)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Net",
                dataIndex: "net_value",
                key: "total_fee",
                render: (value, row, index) => {
                    if(index === (mappedRecaps.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.net_value)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedRecaps.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            }
        ];

        return (
            <div style={{ margin: "50px 0" }}>
                <Table size="small"
                       tableLayout="auto"
                       rowKey="trx_date"
                       columns={columns}
                       dataSource={mappedRecaps}
                       style={{maxWidth: '100%'}}
                       pagination={false}/>


            </div>
        );
    };

    render() {
        const { loading, recaps, reportDateFrom, reportDateTo, totalOmzet, totalFee} = this.state;

        return (
            <Spin spinning={loading}>
            <Row>
                <Col>
                    <div style={{ padding: 10 }}>
                        <h1>Rekapitulasi Omset</h1>
                        <table>
                            <tbody>
                            <tr>
                                <td>Outlet</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.brand_name} - {this.props.posConfig.outlet_name}</td>
                            </tr>
                            <tr>
                                <td>Kasir</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.cashier_name}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)} - {moment(reportDateTo, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                            </tr>
                            <tr>
                                <td>Waktu Pembuatan Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment().format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                            </tr>
                            </tbody>
                        </table>

                        {empty(recaps) && <Empty style={{maxWidth: 500, margin: '100px 0'}} />}

                        {!empty(recaps) && this.renderRecapTable(recaps)}
                        {!empty(recaps) && <div style={{margin: '20px 0 100px 0'}}>
                            Total Omzet untuk periode tanggal {reportDateFrom} s/d {reportDateTo} sebesar <strong>{this.currencyCode} {number_format(Math.floor(totalOmzet), 0)}</strong><br/>
                            Royalty Fee = 9%<br/>
                            Marketing Fee = 1%<br/>
                            Total Fee yang harus dibayarkan untuk periode tanggal {reportDateFrom} s/d {reportDateTo} sebesar <strong>{this.currencyCode} {number_format(Math.floor(totalFee), 0)}</strong>
                        </div>}
                    </div>
                </Col>
            </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

export default connect(mapStateToProps)(ReportCustomOmzetRecapPrint);
