import { combineReducers } from 'redux';
import * as types from './types';

/* State shape
state: {
    loyalty_trx: [{
        loyalty_trx_head_id: 0,
        local_trx_id: '',
    }],
    loyalty_state: {
        redeem_trx_step: 1
    }
}
*/

const loyaltyTrxInitialState = [];
const loyaltyTrx = (state = loyaltyTrxInitialState, action) => {
    switch (action.type) {
        case types.SYNC_TRX_LOYALTY:
            return state.map(trx => {
                if (trx.local_trx_id === action.payload.localTrxId) {
                    let itemIndex = trx.details
                        .map(obj => {
                            return obj.pos_item_variant_id;
                        })
                        .indexOf(action.payload.variantId);
                    if (itemIndex >= 0) {
                        trx.details[itemIndex].qty += action.payload.qty;
                    } else {
                        const itemObj = {
                            pos_item_id: action.payload.itemId,
                            pos_item_variant_id: action.payload.variantId,
                            pos_item_variant_name: action.payload.variantName,
                            qty: action.payload.qty
                        };
                        trx.details.push(itemObj);
                    }
                }

                return trx;
            });

        case types.ADD_TRX_LOYALTY:
            return [...state, action.payload];

        case types.ADD_LOCAL_TRX_LOYALTY:
            return [
                ...state,
                {
                    ...action.payload,
                    loyalty_trx_head_id: null
                }
            ];

        default:
            return state;
    }
};

const loyaltyStateInitialState = {
    redeem_trx_step: 1
};
const loyaltyState = (state = loyaltyStateInitialState, action) => {
    switch (action.type) {
        case types.SET_LOYALTY_STATES:
            return { ...action.payload };

        default:
            return state;
    }
};

const loyaltyReducer = combineReducers({
    loyaltyTrx,
    loyaltyState
});

export default loyaltyReducer;
