import React, { Component } from "react";
import { connect } from "react-redux";
import QrReader from "react-qr-reader";
import { Avatar, Button, DatePicker, Divider, Icon, Input, List, message, Modal, Radio, Spin, Typography } from "antd";
import * as endpoint from "../../shared/utils/endpoints";
import { posOperations } from "../../state/ducks/pos";
import { apiCall, promiseTimeout } from "../../shared/helpers/networkHelper";
import { empty } from "../../shared/helpers/generalHelper";
import { validEmail, validPhone } from "../../shared/helpers/stringHelper";
import styles from "./PosCustomerPanel.module.scss";
import moment from "moment";
import { CUSTOMER_SEARCH_TIMEOUT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";
import ErrorHandler from "../../shared/classes/ErrorHandler";

class PosCustomerPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpened: this.props.modalOpened || false,
            customerJournalModalOpened: false,
            searchUserLoading: false,
            user_identifier_string: "",
            user_identifier_type: "phone",
            user: null,
            userSearchResult: [],
            journalModalOpened: false,
            journalLoading: false,
            journals: null
        };
    }

    componentDidMount() {
        const { currentTrx } = this.props;
        if (!empty(currentTrx.customer)) {
            this.setCurrentUserStateToMatchProps(currentTrx.customer);
        }

        if (this.props.modalOpened) {
            this.props.disableFutureAutoOpenModal();
        }
    }

    setCurrentUserStateToMatchProps = userProps => {
        this.setState({
            user: { ...userProps },
            user_identifier_string: userProps.user_identifier_string,
            user_identifier_type: userProps.user_identifier_type
        });
    };

    openCustomerModal = () => {
        this.setState({
            modalOpened: true
        });
    };

    handleCustomerModalOk = () => {
        const { currentLocalTrxId } = this.props;

        if (!empty(this.state.user_identifier_string) && !empty(this.state.user)) {
            this.props.setTrxCustomer(currentLocalTrxId, {
                ...this.state.user,
                user_identifier_string: this.state.user_identifier_string,
                user_identifier_type: this.state.user_identifier_type
            });
        } else {
            this.props.setTrxCustomer(currentLocalTrxId, null);
            this.setState({ user_identifier_string: null });
        }

        this.setState({
            modalOpened: false
        });

    };

    handleCustomerModalCancel = () => {
        const { currentLocalTrxId } = this.props;

        if (!empty(this.state.user_identifier_string) && !empty(this.state.user)) {
            this.props.setTrxCustomer(currentLocalTrxId, {
                ...this.state.user,
                user_identifier_string: this.state.user_identifier_string,
                user_identifier_type: this.state.user_identifier_type
            });
        } else {
            this.props.setTrxCustomer(currentLocalTrxId, null);
            this.setState({ user_identifier_string: null });
        }

        this.setState({
            modalOpened: false
        });
    };

    onChangeUserIdentifierType = e => {
        this.setState({
            user_identifier_type: e.target.value,
            user_identifier_string: ""
        });
    };

    onChangeUserIdentifierString = e => {
        this.setState({
            user_identifier_string: e.target.value
        });
    };

    setSearchUserLoading = state => {
        this.setState({
            searchUserLoading: state
        });
    };

    handleQrScan = data => {
        if (data) {
            this.setState({
                user_identifier_string: data
            }, this.onSearchCustomer);
        }
    };

    handleQrError = err => {
        console.error(err);
    };

    onSearchCustomer = e => {
        const { userData } = this.props;

        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Customer harus dipilih.");
            return;
        }

        if (this.state.user_identifier_type === "phone") {
            if (!validPhone(this.state.user_identifier_string)) {
                message.error("Mohon masukkan nomor handphone yang valid.");
                return;
            }
        }

        if (this.state.user_identifier_type === "email") {
            if (!validEmail(this.state.user_identifier_string)) {
                message.error("Mohon masukkan alamat email yang valid.");
                return;
            }
        }

        this.setSearchUserLoading(true);
        if (navigator.onLine) {
            promiseTimeout(
                CUSTOMER_SEARCH_TIMEOUT,
                new Promise((resolve, reject) => {
                    return apiCall(
                        endpoint.GET_CUSTOMER_INFO + "?retrieve_coupon=1",
                        "post",
                        {
                            user_identifier_type: this.state
                                .user_identifier_type,
                            user_identifier_string: this.state
                                .user_identifier_string
                        },
                        response => {
                            resolve(response);
                        },
                        response => {
                            reject(response);
                        }
                    );
                })
            )
                .then(result => {
                    // API call success
                    if(userData.pos_trx_no_user_edit === 1){
                        if(result.data.user_code === null){
                            message.error("Data customer tidak ditemukan");
                            this.setSearchUserLoading(false);
                            return;
                        }
                    }

                    this.setState(
                        {
                            user: result.data
                        },
                        () => {
                            this.setSearchUserLoading(false);
                        }
                    );
                })
                .catch(error => {
                    if (error === "timeout") {
                        // Network is not available
                        if (
                            this.state.user_identifier_type === "qr" ||
                            this.state.user_identifier_type === "reference"
                        ) {
                            message.error(
                                "Koneksi internet dibutuhkan untuk pencarian customer, mohon coba kembali"
                            );
                        } else {
                            message.warning(
                                "Koneksi internet tidak stabil, data customer akan disinkronisasi otomatis ketika koneksi telah pulih kembali"
                            );
                        }
                    } else {
                        // API throwing error object
                        message.error(error.data.error.message);
                    }

                    if (
                        this.state.user_identifier_type !== "qr" &&
                        this.state.user_identifier_type !== "reference"
                    ) {
                        this.setEmptyCustomer();
                    }
                    this.setSearchUserLoading(false);
                });
        } else {
            this.setEmptyCustomer();
            this.setSearchUserLoading(false);
            message.warning(
                "No internet connection, customer data will be synchronized later."
            );
        }
    };

    onSearchCustomerByKeyword = () => {
        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Customer harus dipilih.");
            return;
        }

        if (this.state.user_identifier_type === "search") {
            if (empty(this.state.user_identifier_string)) {
                message.error("Kata kunci pencarian harus diisi.");
                return;
            }
        }

        this.setSearchUserLoading(true);
        if (navigator.onLine) {
            promiseTimeout(
                10000,
                new Promise((resolve, reject) => {
                    return apiCall(
                        endpoint.SEARCH_CUSTOMER + `?keyword=${this.state.user_identifier_string}`,
                        "get",
                        null,
                        response => {
                            resolve(response);
                        },
                        response => {
                            reject(response);
                        }
                    );
                })
            )
                .then(result => {
                    // API call success
                    this.setState(
                        {
                            userSearchResult: result.data.users
                        },
                        () => {
                            this.setSearchUserLoading(false);
                        }
                    );
                })
                .catch(error => {
                    if (error === "timeout") {
                        // Network is not available
                        message.error(
                            "Tidak ada koneksi internet. Mohon coba kembali."
                        );
                    } else {
                        // API throwing error object
                        message.error(error.data.error.message);
                    }

                    this.setSearchUserLoading(false);
                });
        } else {
            // this.setEmptyCustomer();
            this.setSearchUserLoading(false);
            message.error(
                "Tidak ada koneksi internet. Mohon coba kembali."
            );
        }
    };

    selectSearchedCustomer = user => {
        let user_identifier_type = "";
        let user_identifier_string = "";

        if (!empty(user.user_email)) {
            user_identifier_type = "email";
            user_identifier_string = user.user_email;
        }

        if (!empty(user.user_phone)) {
            user_identifier_type = "phone";
            user_identifier_string = user.user_phone;
        }

        this.setState({
            user: { ...user },
            user_identifier_type,
            user_identifier_string
        });
    };

    // CUSTOMER DATA
    onChangeUserName = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_name: e.target.value
            }
        });
    };

    onDatePickerSelected = (momentDate, DateString) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    user_birth_date: DateString
                }
            }
        );
    };

    onChangeUserGender = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_gender: e.target.value
            }
        });
    };

    onChangeUserNotes = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_notes: e.target.value
            }
        });
    };

    onChangeUserEmailNotification = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_email_notification: e.target.value
            }
        });
    };

    resetCustomer = () => {
        this.setState({
            user_identifier_string: "",
            user_identifier_type: "phone",
            user: null
        });
    };

    setEmptyCustomer = () => {
        this.setState({
            user: {
                user_code: null,
                user_name: "",
                user_gender: "",
                user_birth_date: "",
                user_notes: "",
                user_email_notification: "",
                retrieve_time: ""
            }
        });
    };

    openCustomerJournal = () => {
        this.setState({ journalModalOpened: true, journalLoading: true });

        apiCall(
            endpoint.GET_CUSTOMER_ACTIVITIES + `?user_identifier_type=${this.state.user_identifier_type}&user_identifier_string=${this.state.user_identifier_string}`,
            "get",
            null,
            result => {
                this.setState({
                    journals: result.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setState({ journalLoading: false });
            }
        );

    };

    closeJournalModal = () => {
        this.setState({
            journalModalOpened: false
        });
    };

    render() {
        const { currentTrx, userData } = this.props;
        const {
            modalOpened,
            user_identifier_string,
            user_identifier_type,
            searchUserLoading,
            user,
            journalModalOpened,
            journalLoading,
            journals
        } = this.state;
        const currentCustomerName = empty(user)
            ? "New Customer"
            : empty(user.user_name)
                ? "New Customer"
                : user.user_name;
        const currentMembershipName = empty(user)
            ? ""
            : empty(user.membership_name)
                ? ""
                : ` (${user.membership_name})`;
        const currentCustomerContact = empty(user_identifier_string)
            ? "No information"
            : user_identifier_string;
        const dateFormat = "YYYY-MM-DD";
        let birthDateDefaultValue = {};
        if (user && !empty(user.user_birth_date)) {
            birthDateDefaultValue.defaultValue = moment(user.user_birth_date);
        }
        return (
            <React.Fragment>
                <div
                    className={styles.currentCustomer}
                    onClick={this.openCustomerModal}
                >
                    <Avatar
                        icon="user"
                        size="large"
                        className={styles.currentCustomerAvatar}
                    />
                    <Icon
                        type="edit"
                        className={styles.currentCustomerEditBtn}
                    />
                    <div className={styles.currentCustomerName}>
                        {currentCustomerName}{currentMembershipName} {user !== null && !empty(user.account_receivables) && <Typography.Text type="danger">$$</Typography.Text>}
                    </div>
                    {moment(user?.user_birth_date, MOMENT_SQL_DATE_FORMAT).format('MM-DD') === moment().format('MM-DD') && (
                        <div>
                            <span><span role={"img"} aria-label={`Happy Birthday`}>&#127874;</span> Selamat Ulang Tahun</span>
                        </div>
                    )}
                    <div className={styles.currentCustomerContact}>
                        {currentCustomerContact}
                    </div>
                </div>

                {userData.customer_journal === 1 && user !== null && (
                    <div><Button type={"link"} onClick={this.openCustomerJournal}>Lihat Catatan Jurnal Pelanggan</Button></div>
                )}

                {!empty(currentTrx) && (
                    <>
                        <Modal
                            title="Set Customer"
                            visible={modalOpened}
                            onOk={this.handleCustomerModalOk}
                            onCancel={this.handleCustomerModalCancel}
                            cancelButtonProps={{ style: { display: "none" } }}
                            okText="OK"
                        >
                            <div>
                                <Spin spinning={searchUserLoading}>
                                    <div className={styles.formItem}>
                                        <h4>Cari Customer Berdasarkan</h4>
                                        <Radio.Group
                                            defaultValue="phone"
                                            buttonStyle="solid"
                                            disabled={user !== null}
                                            value={user_identifier_type}
                                            onChange={
                                                this.onChangeUserIdentifierType
                                            }
                                        >
                                            <Radio.Button value="phone">
                                                Phone
                                            </Radio.Button>
                                            <Radio.Button value="qr">
                                                QR Code
                                            </Radio.Button>
                                            <Radio.Button value="email">
                                                Email
                                            </Radio.Button>
                                            <Radio.Button value="reference">
                                                Reference
                                            </Radio.Button>
                                            <Radio.Button value="search">
                                                Nama
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>

                                    {user_identifier_type === "phone" && (
                                        <div className={styles.formItem}>
                                            <h4>Phone Number</h4>
                                            <Input.Search
                                                addonBefore="+62"
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                                type="tel"
                                            />
                                        </div>
                                    )}

                                    {user_identifier_type === "qr" && (
                                        <div className={styles.formItem}>
                                            <h4>Scan QR Code</h4>
                                            {user === null && (
                                                <QrReader
                                                    onScan={this.handleQrScan}
                                                    onError={this.handleQrError}
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: 10
                                                    }}
                                                />
                                            )}
                                            <Input.Search
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                            />
                                        </div>
                                    )}

                                    {user_identifier_type === "email" && (
                                        <div className={styles.formItem}>
                                            <h4>Email</h4>
                                            <Input.Search
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                                type="email"
                                            />
                                        </div>
                                    )}

                                    {user_identifier_type === "reference" && (
                                        <div className={styles.formItem}>
                                            <h4>Reference</h4>
                                            <Input.Search
                                                onSearch={this.onSearchCustomer}
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                            />
                                        </div>
                                    )}

                                    {user_identifier_type === "search" && (
                                        <div className={styles.formItem}>
                                            <h4>Nama Customer</h4>
                                            <Input.Search
                                                onSearch={this.onSearchCustomerByKeyword}
                                                onChange={
                                                    this.onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                            />

                                            {!empty(this.state.userSearchResult) && user === null && (
                                                <List
                                                    style={{ marginTop: 10 }}
                                                    bordered
                                                    dataSource={this.state.userSearchResult}
                                                    renderItem={item => (<List.Item>
                                                        <div style={{ cursor: "pointer", display: "block", width: "100%" }} onClick={() => {
                                                            this.selectSearchedCustomer(item);
                                                        }}>
                                                            <h4>{item.user_name}{!empty(item.user_code) && ` (${item.user_code})`} {!empty(item.account_receivables) && <Typography.Text type="danger">$$</Typography.Text>}</h4>
                                                            <p>
                                                                {!empty(item.user_ref) && (
                                                                    <React.Fragment>
                                                                        Ref: {item.user_ref}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                {!empty(item.user_phone) && (
                                                                    <React.Fragment>
                                                                        {item.user_phone}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                {!empty(item.user_email) && (
                                                                    <React.Fragment>
                                                                        {item.user_email}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                {!empty(item.user_notes) && (
                                                                    <React.Fragment>
                                                                        {item.user_notes}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </List.Item>)}
                                                />
                                            )}

                                        </div>

                                    )}
                                </Spin>

                                <Divider/>

                                {user !== null && (
                                    <div className={styles.formItem}>
                                        <Button
                                            type="danger"
                                            size="large"
                                            block={true}
                                            onClick={this.resetCustomer}
                                            htmlType="button"
                                        >
                                            Change Customer
                                        </Button>
                                    </div>
                                )}

                                <h4>
                                    Customer Data{" "}
                                    {user !== null
                                        ? user.user_code === null
                                            ? "(New Customer)"
                                            : ""
                                        : ""}
                                </h4>
                                {user === null && `No customer chosen`}
                                {user !== null && (
                                    <React.Fragment>
                                        {!empty(user.user_ref) && (
                                            <div className={styles.formItem}>
                                                <label>User Ref: {user.user_ref}</label>
                                            </div>
                                        )}

                                        <div className={styles.formItem}>
                                            <label>Nama</label>
                                            <Input
                                                type="text"
                                                onChange={this.onChangeUserName}
                                                value={user.user_name}
                                                disabled={userData.pos_trx_no_user_edit === 1}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Tanggal Lahir</label>
                                            <DatePicker
                                                {...birthDateDefaultValue}
                                                format={dateFormat}
                                                onChange={
                                                    this.onDatePickerSelected
                                                }
                                                showToday={true}
                                                disabled={userData.pos_trx_no_user_edit === 1}
                                                allowClear={true}/>
                                            <p>
                                                {moment(user.user_birth_date, MOMENT_SQL_DATE_FORMAT).format('MM-DD') === moment().format('MM-DD') && (
                                                    <span><span role={"img"} aria-label={`Happy Birthday`}>&#127874;</span> Selamat Ulang Tahun</span>
                                                )}
                                            </p>
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Jenis Kelamin</label>
                                            <Radio.Group
                                                defaultValue=""
                                                buttonStyle="solid"
                                                value={user.user_gender}
                                                onChange={this.onChangeUserGender}
                                                disabled={userData.pos_trx_no_user_edit === 1}
                                            >
                                                <Radio.Button value="Male">
                                                    Laki-laki
                                                </Radio.Button>
                                                <Radio.Button value="Female">
                                                    Perempuan
                                                </Radio.Button>
                                            </Radio.Group>
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Catatan</label>
                                            <Input.TextArea
                                                type="text"
                                                onChange={this.onChangeUserNotes}
                                                rows={4}
                                                value={user.user_notes}
                                                disabled={userData.pos_trx_no_user_edit === 1}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Notification Email</label>
                                            <Input
                                                type="text"
                                                onChange={this.onChangeUserEmailNotification}
                                                value={user.user_email_notification}
                                                disabled={userData.pos_trx_no_user_edit === 1}
                                            />
                                        </div>

                                    </React.Fragment>
                                )}
                            </div>
                        </Modal>

                        <Modal
                            title="Customer Journals"
                            visible={journalModalOpened}
                            onOk={this.closeJournalModal}
                            onCancel={this.closeJournalModal}
                            cancelButtonProps={{ style: { display: "none" } }}
                            okText="Close"
                        >
                            <div>
                                <Spin spinning={journalLoading}>
                                    <table className={styles.journalTable}>
                                        {journals?.activities?.filter(row => {
                                            const today = moment().format('YYYY-MM-DD');
                                            const created_at = moment(row.created_at, MOMENT_SQL_DATETIME_FORMAT).format('YYYY-MM-DD');

                                            return !empty(row.notes_for_cashier) && today === created_at;
                                        })
                                            .map(row => {
                                                return <tr>
                                                    <td>{moment(row.created_at, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                                                    <td>{row.notes_for_cashier}</td>
                                                </tr>;
                                            })
                                        }
                                    </table>
                                </Spin>
                            </div>
                        </Modal>
                    </>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData,
        currentLocalTrxId: state.posState.posVariables.currentLocalTrxId,
        currentTrx: state.posState.posTrx.find(
            trx =>
                trx.local_trx_id ===
                state.posState.posVariables.currentLocalTrxId
        )
    };
};

const mapDispatchToProps = {
    setTrxCustomer: posOperations.setTrxCustomer
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosCustomerPanel);
