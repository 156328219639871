import actions from './actions';

const signIn = () => actions.signIn();
const signOut = () => actions.signOut();

const setUserData = userData => actions.setUserData(userData);
const removeUserData = () => actions.removeUserData();
const setCashierConfigs = configs => actions.setCashierConfigs(configs);

const showGlobalLoader = tip => actions.showGlobalLoader(tip);
const hideGlobalLoader = () => actions.hideGlobalLoader();
const resetAppStates = () => actions.resetAppStates();
const setAppStates = appStates => actions.setAppStates(appStates);

const setActiveShiftData = shiftData => actions.setActiveShiftData(shiftData);
const endShift = endedShiftData => actions.endShift(endedShiftData);

export default {
    signIn,
    signOut,
    setUserData,
    removeUserData,
    setCashierConfigs,
    showGlobalLoader,
    hideGlobalLoader,
    setAppStates,
    resetAppStates,
    setActiveShiftData,
    endShift
};
