import { combineReducers } from 'redux';
import * as types from './types';

/* State shape
state: {
    authenticated: false,
    userData: {
        brand_id: 0
        brand_logo: ""
        brand_name: ""
        business_id: 0
        business_name: ""
        business_user_email: ""
        business_user_firstname: ""
        business_user_group_id: 0
        business_user_id: 0
        business_user_lastname: ""
        client_key: ""
        has_automatic_membership: 0
        has_membership_activation: 0
        is_api_integrated: 0
        is_birthday_scheme: 0
        is_discount_activated: 0
        is_getpos: 0
        is_loyalty_configured: 0
        is_loyalty_reference: 0
        is_old_migration: 0
        is_point_activated: 0
        is_pos_commission_scheme: 0
        is_pos_counter: 0
        is_redeem_blocker_scheme: 0
        is_referral_scheme: 0
        is_verified: 0
        is_wizard: 0
        login_token: ""
        outlet_name: ""
        service_percentage: 0
        subscription_id: 0
        tax_percentage: 0
    }
}
*/

const authenticatedInitialState = false;
const authenticated = (state = authenticatedInitialState, action) => {
    switch (action.type) {
        case types.SIGN_IN:
            return true;

        case types.SIGN_OUT:
            return false;

        default:
            return state;
    }
};

const userDataInitialState = {};
const userData = (state = userDataInitialState, action) => {
    switch (action.type) {
        case types.SET_USER_DATA:
            return { ...action.payload.userData };

        case types.REMOVE_USER_DATA:
            return {};

        default:
            return state;
    }
};

const configsInitialState = {
    isLoaded: false,
    printerPaperSize: "8cm",
    globalLoading: false,
    globalLoadingMessage: '',
    displayMode: 'DESKTOP',
    decimalSeparator: '.',
    thousandSeparator: ','
};
const configs = (state = configsInitialState, action) => {
    switch (action.type) {
        case types.SET_CASHIER_CONFIGS:
            return { ...state, ...action.payload.configs };

        case types.MODIFY_CASHIER_CONFIGS:
            return { ...state, ...action.payload.configs };

        default:
            return state;
    }
};

const statesInitialState = {
    globalLoading: false,
    globalLoadingMessage: '',
    pendingSignOut: false
};
const states = (state = statesInitialState, action) => {
    switch (action.type) {
        case types.SHOW_GLOBAL_LOADER:
            return {
                ...state,
                globalLoading: true,
                globalLoadingMessage: action.payload.tip
            };

        case types.HIDE_GLOBAL_LOADER:
            return {
                ...state,
                globalLoading: false,
                globalLoadingMessage: ''
            };

        case types.SET_APP_STATES:
            return {
                ...state,
                ...action.payload.appStates
            };

        case types.RESET_APP_STATES:
            return {
                ...state,
                globalLoading: false,
                globalLoadingMessage: ''
            };

        default:
            return state;
    }
};

const shiftManagementInitialState = {
    active: null,
    history: []
};
const shiftManagement = (state = shiftManagementInitialState, action) => {
    switch (action.type) {
        case types.SET_ACTIVE_SHIFT_DATA:
            return {
                ...state,
                active: { ...state.active, ...action.payload.shiftData }
            };

        case types.END_SHIFT:
            return {
                ...state,
                active: null,
                history: [{ ...action.payload.endedShiftData }, ...state.history]
            };

        default:
            return state;
    }
};

const authReducer = combineReducers({
    authenticated,
    userData,
    configs,
    states,
    shiftManagement
});

export default authReducer;
