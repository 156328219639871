import React, { Component } from 'react';
import { Route, Router, Switch, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MainTemplate from './shared/hoc/MainTemplate';
import RequireAuth from './shared/hoc/RequireAuth';
import Home from './pages/Home';
import AuthSignIn from './pages/AuthSignIn';
import LoyaltyTrx from './pages/LoyaltyTrx';
import PosSales from './pages/PosSales';
import ChooseOutlet from './pages/ChooseOutlet';
import PosReceipt from './pages/PosReceipt';
import RedeemTrxQr from './pages/RedeemTrxQr';
import MemberInfo from './pages/MemberInfo';
import RedeemTrxSms from './pages/RedeemTrxSms';
import Help from './pages/Help';
import MemberActivation from './pages/MemberActivation';
import ShiftManagement from './pages/ShiftManagement';
import CouponRedeemTrxQr from "./pages/CouponRedeemTrxQr";
import InventoryStockIn from "./pages/InventoryStockIn";
import InventoryStockOut from "./pages/InventoryStockOut";
import InventoryStockAdjust from "./pages/InventoryStockAdjust";
import InventoryTransferOut from "./pages/InventoryTransferOut";
import InventoryTransferIn from "./pages/InventoryTransferIn";
import TrxHistory from "./pages/TrxHistory";
import SettlementSummary from "./modules/SettlementSummary/SettlementSummary";
import TrxHistoryPrint from "./pages/TrxHistoryPrint";
import AccountReceivableList from "./pages/AccountReceivableList";
import InventoryStockList from "./pages/InventoryStockList";
import ReportWorkerCommissionPrint from "./pages/ReportWorkerCommissionPrint";
import ReportWorkerCommission from "./pages/ReportWorkerCommission";
import ReportPosAllTrx from "./pages/ReportPosAllTrx";
import ReportPosAllTrxPrint from "./pages/ReportPosAllTrxPrint";
import ReportProductConsumptionPrint from "./pages/ReportProductConsumptionPrint";
import ReportProductConsumption from "./pages/ReportProductConsumption";
import { Spin } from "antd";
import { connect } from "react-redux";
import CustomerJournal from "./pages/CustomerJournal";
import Appointment from "./pages/Appointment";
import PosReceiptPublic from "./pages/PosReceiptPublic";
import Purgatory from "./pages/Purgatory";
import ReportCoupon from "./pages/ReportCoupon";
import ReportCouponPrint from "./pages/ReportCouponPrint";
import ReportCustomOmzetRecap from "./pages/ReportCustomOmzetRecap";
import ReportCustomOmzetRecapPrint from "./pages/ReportCustomOmzetRecapPrint";
import AppointmentSales from "./pages/AppointmentSales";
import InventoryHistory from "./pages/InventoryHistory";
import TrxHistoryEdit from "./pages/TrxHistoryEdit";
import Backdoor from "./pages/Backdoor";
import ReportCustomerBirthdayPrint from "./pages/ReportCustomerBirthdayPrint";
import ReportCustomerBirthday from "./pages/ReportCustomerBirthday";
import ReportTreatmentReminderPrint from "./pages/ReportTreatmentReminderPrint";
import ReportTreatmentReminder from "./pages/ReportTreatmentReminder";
import OrganizationList from "./pages/emr/OrganizationList";
import OrganizationForm from "./pages/emr/OrganizationForm";
import LocationForm from "./pages/emr/LocationForm";
import LocationList from "./pages/emr/LocationList";
import OrganizationInitiate from "./pages/emr/OrganizationInitiate";

const browserHistory = createBrowserHistory();

class Routes extends Component {
    render() {
        const { globalLoading, globalLoadingMessage } = this.props.states;

        return (
            <Spin spinning={globalLoading} tip={globalLoadingMessage}>
                <Router history={browserHistory}>
                    <Switch>
                        <Route path="/sign-in" component={AuthSignIn} />
                        <Route path="/choose-outlet" component={ChooseOutlet} />
                        <Route
                            path="/loyalty-transaction"
                            component={withRouter(RequireAuth(MainTemplate(LoyaltyTrx)))}
                        />
                        <Route
                            path="/redeem-qr-transaction"
                            component={withRouter(RequireAuth(MainTemplate(RedeemTrxQr)))}
                        />
                        <Route
                            path="/redeem-sms-transaction"
                            component={withRouter(RequireAuth(MainTemplate(RedeemTrxSms)))}
                        />
                        <Route
                            path="/coupon-use-qr"
                            component={withRouter(RequireAuth(MainTemplate(CouponRedeemTrxQr)))}
                        />
                        <Route
                            path="/member-info/:user_identifier_type/:user_identifier_string"
                            component={withRouter(RequireAuth(MainTemplate(MemberInfo)))}
                        />
                        <Route
                            path="/member-info"
                            component={withRouter(RequireAuth(MainTemplate(MemberInfo)))}
                        />
                        <Route
                            path="/member-activation"
                            component={withRouter(RequireAuth(MainTemplate(MemberActivation)))}
                        />
                        <Route
                            path="/customer-journal/:user_identifier_type/:user_identifier_string"
                            component={withRouter(RequireAuth(MainTemplate(CustomerJournal)))}
                        />
                        <Route
                            path="/customer-journal"
                            component={withRouter(RequireAuth(MainTemplate(CustomerJournal)))}
                        />
                        <Route
                            path="/shift-management"
                            component={withRouter(RequireAuth(MainTemplate(ShiftManagement)))}
                        />
                        <Route
                            path="/inventory/stock-in"
                            component={withRouter(RequireAuth(MainTemplate(InventoryStockIn)))}
                        />
                        <Route
                            path="/inventory/stock-out"
                            component={withRouter(RequireAuth(MainTemplate(InventoryStockOut)))}
                        />
                        <Route
                            path="/inventory/stock"
                            component={withRouter(RequireAuth(MainTemplate(InventoryStockList)))}
                        />
                        <Route
                            path="/inventory/transfer-in"
                            component={withRouter(RequireAuth(MainTemplate(InventoryTransferIn)))}
                        />
                        <Route
                            path="/inventory/transfer-out"
                            component={withRouter(RequireAuth(MainTemplate(InventoryTransferOut)))}
                        />
                        <Route
                            path="/inventory/stock-adjustment"
                            component={withRouter(RequireAuth(MainTemplate(InventoryStockAdjust)))}
                        />
                        <Route
                            path="/inventory/history"
                            component={withRouter(RequireAuth(MainTemplate(InventoryHistory)))}
                        />

                        <Route
                            path="/pos/receipt/:local_trx_id/:modifier"
                            component={withRouter(RequireAuth(PosReceipt))}
                        />
                        <Route path="/pos" component={withRouter(RequireAuth(PosSales))} />

                        {/*<Route*/}
                        {/*    path="/receipt/:receipt_token"*/}
                        {/*    component={withRouter(RequireAuth(PosReceipt))}*/}
                        {/*/>*/}
                        <Route
                            path="/receipt/:receipt_token"
                            component={withRouter(PosReceiptPublic)}
                        />

                        <Route path="/trx-edit/:local_trx_id" component={withRouter(RequireAuth(MainTemplate(TrxHistoryEdit)))} />
                        <Route path="/trx-history/:local_trx_id" component={withRouter(RequireAuth(TrxHistoryPrint))} />
                        <Route path="/trx-history" component={withRouter(RequireAuth(MainTemplate(TrxHistory)))} />

                        <Route path="/account-receivable" component={withRouter(RequireAuth(MainTemplate(AccountReceivableList)))} />
                        <Route
                            path="/settlement/:settlement_type"
                            component={withRouter(RequireAuth(SettlementSummary))}
                        />

                        <Route
                            path="/report/worker-commission/print"
                            component={withRouter(RequireAuth(ReportWorkerCommissionPrint))}
                        />
                        <Route
                            path="/report/worker-commission"
                            component={withRouter(RequireAuth(MainTemplate(ReportWorkerCommission)))}
                        />
                        <Route
                            path="/report/pos-all-trx/print"
                            component={withRouter(RequireAuth(ReportPosAllTrxPrint))}
                        />
                        <Route
                            path="/report/pos-all-trx"
                            component={withRouter(RequireAuth(MainTemplate(ReportPosAllTrx)))}
                        />
                        <Route
                            path="/report/product-consumption/print"
                            component={withRouter(RequireAuth(ReportProductConsumptionPrint))}
                        />
                        <Route
                            path="/report/product-consumption"
                            component={withRouter(RequireAuth(MainTemplate(ReportProductConsumption)))}
                        />
                        <Route
                            path="/report/coupon/print"
                            component={withRouter(RequireAuth(ReportCouponPrint))}
                        />
                        <Route
                            path="/report/coupon"
                            component={withRouter(RequireAuth(MainTemplate(ReportCoupon)))}
                        />
                        <Route
                            path="/report/custom/omzet-recap/print"
                            component={withRouter(RequireAuth(ReportCustomOmzetRecapPrint))}
                        />
                        <Route
                            path="/report/custom/omzet-recap"
                            component={withRouter(RequireAuth(MainTemplate(ReportCustomOmzetRecap)))}
                        />
                        <Route
                            path="/report/customer-birthday/print"
                            component={withRouter(RequireAuth(ReportCustomerBirthdayPrint))}
                        />
                        <Route
                            path="/report/customer-birthday"
                            component={withRouter(RequireAuth(MainTemplate(ReportCustomerBirthday)))}
                        />
                        <Route
                            path="/report/treatment-reminder/print"
                            component={withRouter(RequireAuth(ReportTreatmentReminderPrint))}
                        />
                        <Route
                            path="/report/treatment-reminder"
                            component={withRouter(RequireAuth(MainTemplate(ReportTreatmentReminder)))}
                        />

                        <Route
                            path="/appointment/:appointment_id"
                            component={withRouter(RequireAuth(AppointmentSales))}
                        />

                        <Route
                            path="/appointment"
                            component={withRouter(RequireAuth(MainTemplate(Appointment)))}
                        />

                        <Route
                            path="/emr/organization/initialization"
                            component={withRouter(RequireAuth(MainTemplate(OrganizationInitiate)))}
                        />

                        <Route
                            path="/emr/organization/:organization_id"
                            component={withRouter(RequireAuth(MainTemplate(OrganizationForm)))}
                        />

                        <Route
                            path="/emr/organization"
                            component={withRouter(RequireAuth(MainTemplate(OrganizationList)))}
                        />

                        <Route
                            path="/emr/location/:location_id"
                            component={withRouter(RequireAuth(MainTemplate(LocationForm)))}
                        />

                        <Route
                            path="/emr/location"
                            component={withRouter(RequireAuth(MainTemplate(LocationList)))}
                        />

                        <Route
                            path="/help"
                            component={withRouter(RequireAuth(MainTemplate(Help)))}
                        />

                        <Route
                            path="/purgatory"
                            component={Purgatory}
                        />

                        <Route
                            path="/backdoor"
                            component={Backdoor}
                        />

                        <Route
                            path="/"
                            component={withRouter(RequireAuth(MainTemplate(Home)))}
                        />
                    </Switch>
                </Router>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        states: state.authState.states
    };
};

export default connect(mapStateToProps)(Routes);