import * as types from './types';

export const signIn = () => {
    return dispatch => {
        dispatch({
            type: types.SIGN_IN,
            payload: {}
        });
    };
};

export const signOut = () => {
    return dispatch => {
        dispatch({
            type: types.SIGN_OUT,
            payload: {}
        });
    };
};

export const setUserData = userData => {
    return dispatch => {
        dispatch({
            type: types.SET_USER_DATA,
            payload: { userData }
        });
    };
};

export const removeUserData = () => {
    localStorage.removeItem('client_key');
    localStorage.removeItem('login_token');
    localStorage.removeItem('state');

    return dispatch => {
        dispatch({
            type: types.REMOVE_USER_DATA
        });
    };
};

export const setCashierConfigs = configs => {
    return dispatch => {
        dispatch({
            type: types.SET_CASHIER_CONFIGS,
            payload: { configs }
        });
    };
};

export const showGlobalLoader = tip => {
    return dispatch => {
        dispatch({
            type: types.SHOW_GLOBAL_LOADER,
            payload: { tip }
        });
    };
};

export const hideGlobalLoader = () => {
    return dispatch => {
        dispatch({
            type: types.HIDE_GLOBAL_LOADER,
            payload: {}
        });
    };
};

export const resetAppStates = () => {
    return dispatch => {
        dispatch({
            type: types.RESET_APP_STATES,
            payload: {}
        });
    };
};

export const setAppStates = appStates => {
    return dispatch => {
        dispatch({
            type: types.SET_APP_STATES,
            payload: { appStates }
        });
    };
};

export const setActiveShiftData = shiftData => {
    return dispatch => {
        dispatch({
            type: types.SET_ACTIVE_SHIFT_DATA,
            payload: { shiftData }
        });
    };
};

export const endShift = endedShiftData => {
    return dispatch => {
        dispatch({
            type: types.END_SHIFT,
            payload: { endedShiftData }
        });
    };
};

export default {
    signIn,
    signOut,
    setUserData,
    removeUserData,
    setCashierConfigs,
    showGlobalLoader,
    hideGlobalLoader,
    setAppStates,
    resetAppStates,
    setActiveShiftData,
    endShift
};
