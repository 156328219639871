import {Component} from 'react';
import { empty } from '../helpers/generalHelper';
import { message } from 'antd';
import configureStore from "./../../state/store";
import { loadState, saveState } from "./../helpers/stateHelper";
import { authOperations } from "./../../state/ducks/auth";
import { initMessageListener } from "redux-state-sync";

export default class ErrorHandler extends Component{
    static handleGeneralError = errorResponse => {
        if(!empty(errorResponse)){

            if(errorResponse.status === 303){
                console.log("Should Sign Out!");

                const initial_data = loadState(); //window.REDUX_INITIAL_DATA
                const reduxStore = configureStore(initial_data);

                initMessageListener(reduxStore);

                reduxStore.subscribe(() => {
                    saveState(reduxStore.getState());
                });

                reduxStore.dispatch(authOperations.resetAppStates());

                message.success("Proses sign out sukses!");
                localStorage.clear();
                localStorage.setItem("signed_out", "1");
                setTimeout(() => {
                    reduxStore.dispatch(authOperations.signOut());
                    window.location = "/";
                }, 3000);
            }

            if(errorResponse.status !== 400){
                message.error(`Error ${errorResponse.status}: ${errorResponse.statusText}`);
            }

            if(!empty(errorResponse.data)){
                if(!empty(errorResponse.data.error)){
                    if(!empty(errorResponse.data.error.message)){
                        message.error(errorResponse.data.error.message);
                    }
                }
            }
        }
    };
}