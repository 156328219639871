import React, { Component } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Icon, message, Radio, Row, Spin } from "antd";
import { empty } from "../shared/helpers/generalHelper";
import { MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import moment from "moment";
import styles from "./ReportWorkerCommission.module.scss";
import Synchronizer from "../shared/classes/Synchronizer";
import { posOperations } from "../state/ducks/pos";
import { connect } from "react-redux";
import ErrorHandler from "../shared/classes/ErrorHandler";

class ReportWorkerCommission extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            formLoadingMessage: '',
            reportDateFrom: moment().format(MOMENT_SQL_DATE_FORMAT),
            reportDateTo: moment().format(MOMENT_SQL_DATE_FORMAT),
            reportType: null,
            itemNature: '',
            commissionWorkers: [],
            defaultSelectedCommissionWorkers: [],
            selectedCommissionWorkers: [],
            commissionWorkersIndeterminate: false,
            commissionWorkersCheckAll: true
        };
        this.state = { ...this.initialState };
    }

    componentDidMount() {
        this.setState({
            commissionWorkers: this.props.userData.commission_worker.map(row => {
                let workerName = row.firstname;
                if(!empty(row.lastname)) workerName += ` ${row.lastname}`;
                if(!empty(row.initial)) workerName += ` (${row.initial})`;
                return {
                    label: workerName,
                    value: row.business_commission_worker_id
                };
            }),
            selectedCommissionWorkers: this.props.userData.commission_worker.map(row => row.business_commission_worker_id),
            defaultSelectedCommissionWorkers: this.props.userData.commission_worker.map(row => row.business_commission_worker_id)
        })
    }

    showLoader = (tip = '') => {
        this.setState({
            formLoading: true,
            formLoadingMessage: tip
        })
    };

    hideLoader = () => {
        this.setState({
            formLoading: false,
            formLoadingMessage: ''
        })
    };

    onPickReportDate = (dates, dateStrings) => {
        this.setState({
            reportDateFrom: moment(dates[0]).format(MOMENT_SQL_DATE_FORMAT),
            reportDateTo: moment(dates[1]).format(MOMENT_SQL_DATE_FORMAT),
        });
    };

    onChangeReportType = e => {
        this.setState({
            reportType: e.target.value
        });
    };

    onChangeItemNature = e => {
        this.setState({
            itemNature: e.target.value
        });
    };

    printWorkerCommission = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if(empty(this.state.reportDateFrom) || empty(this.state.reportDateTo)){
            message.error('Tanggal harus dipilih');
            return;
        }

        if(empty(this.state.reportType)){
            message.error('Jenis laporan harus dipilih');
            return;
        }

        if(this.state.reportType === 'by-product' && empty(this.state.itemNature)){
            message.error('Jenis produk harus dipilih');
            return;
        }

        if(empty(this.state.selectedCommissionWorkers)){
            message.error('Pekerja harus dipilih minimal satu orang');
            return;
        }

        this.showLoader('Synchronizing transactions..');
        Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            false,
            () => {
                let reportUrl = `/report/worker-commission/print?start_date=${this.state.reportDateFrom}&end_date=${this.state.reportDateTo}&report_type=${this.state.reportType}`;
                if(this.state.reportType === 'by-product'){
                    reportUrl += `&item_nature=${this.state.itemNature}`;
                }
                reportUrl += `&commission_workers=${this.state.selectedCommissionWorkers.join(',')}`;

                window.open(reportUrl, '_blank', 'rel=noopener noreferrer');
                this.hideLoader();
            }
        ).catch(err => {
            ErrorHandler.handleGeneralError(err);
            this.hideLoader();
        });
    };

    onChangeCommissionWorkerCheckbox = selectedCommissionWorkers => {
        this.setState({
            selectedCommissionWorkers,
            commissionWorkersIndeterminate: !!selectedCommissionWorkers.length && selectedCommissionWorkers.length < this.state.commissionWorkers.length,
            commissionWorkersCheckAll: selectedCommissionWorkers.length === this.state.commissionWorkers.length,
        });
    };

    onCheckAllCommissionWorkersChange = e => {
        this.setState({
            selectedCommissionWorkers: e.target.checked ? this.state.defaultSelectedCommissionWorkers : [],
            commissionWorkersIndeterminate: false,
            commissionWorkersCheckAll: e.target.checked,
        });
    };

    render() {
        const {
            formLoading,
            formLoadingMessage,
            reportDateFrom,
            reportDateTo,
            reportType,
            itemNature,
            commissionWorkers,
            selectedCommissionWorkers,
            commissionWorkersIndeterminate,
            commissionWorkersCheckAll
        } = this.state;

        const {userData} = this.props;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        const checkboxStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft: 0
        };

        const pageTitle = (
            <React.Fragment>
                <Icon type="book"/>
                &nbsp;&nbsp; Aktivitas Pekerja
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={12} sm={24}>
                        <Spin spinning={formLoading} tip={formLoadingMessage}>
                            <Form onSubmit={this.printWorkerCommission}>
                                <div className={styles.formItem}>
                                    <label>Pilih jenis laporan</label>
                                    <Radio.Group onChange={this.onChangeReportType} value={reportType}>
                                        {userData.auth_role_accesses.WORKER_ACTIVITY_SUMMARY === 1 && (
                                            <Radio style={radioStyle} value="summary">
                                                Ringkasan
                                            </Radio>
                                        )}
                                        {userData.auth_role_accesses.WORKER_ACTIVITY_ITEM_NATURE === 1 && (
                                            <Radio style={radioStyle} value="by-product">
                                                Berdasarkan service / retail
                                            </Radio>
                                        )}
                                        {userData.auth_role_accesses.WORKER_ACTIVITY_TRX === 1 && (
                                            <Radio style={radioStyle} value="by-transaction">
                                                Berdasarkan transaksi
                                            </Radio>
                                        )}
                                        {userData.auth_role_accesses.WORKER_ACTIVITY_TRX_SIMPLE === 1 && (
                                            <Radio style={radioStyle} value="by-simple">
                                                Simple Format
                                            </Radio>
                                        )}
                                    </Radio.Group>
                                </div>

                                {reportType === 'by-product' && (
                                    <div className={styles.formItem}>
                                        <label>Pilih jenis produk</label>
                                        <Radio.Group onChange={this.onChangeItemNature} value={itemNature}>
                                            {/*<Radio style={radioStyle} value={``}>
                                                Service & Retail
                                            </Radio>*/}
                                            <Radio style={radioStyle} value={`SERVICE`}>
                                                Service
                                            </Radio>
                                            <Radio style={radioStyle} value={`RETAIL`}>
                                                Retail
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                )}

                                <div className={styles.formItem}>
                                    <label>Pilih tanggal laporan</label>
                                    <DatePicker.RangePicker
                                        value={[moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT), moment(reportDateTo, MOMENT_SQL_DATE_FORMAT)]}
                                        onChange={this.onPickReportDate}
                                        format="DD MMM YYYY"
                                    />
                                </div>

                                <div className={styles.formItem}>
                                    <label>Pilih Pekerja</label>
                                    <div style={{ borderBottom: '1px solid #E9E9E9', paddingBottom: 10, marginBottom: 5 }}>
                                        <Checkbox
                                            indeterminate={commissionWorkersIndeterminate}
                                            onChange={this.onCheckAllCommissionWorkersChange}
                                            checked={commissionWorkersCheckAll}
                                        >
                                            Semua Pekerja
                                        </Checkbox>
                                    </div>
                                    <Checkbox.Group
                                        value={selectedCommissionWorkers}
                                        onChange={this.onChangeCommissionWorkerCheckbox}
                                    >
                                        {commissionWorkers.map(row => <Checkbox key={row.value} value={row.value} style={checkboxStyle}>{row.label}</Checkbox>)}
                                    </Checkbox.Group>
                                </div>

                                <div className={styles.formItem}>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                </div>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData,
        posState: state.posState,
        configs: state.authState.configs
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportWorkerCommission);