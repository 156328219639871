import React, { useEffect, useState } from "react";
import { Button, Divider, Empty, Icon, Modal } from "antd";
import PropTypes from "prop-types";
import { apiCall } from "../helpers/networkHelper";
import * as endpoint from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import { empty } from "../helpers/generalHelper";
import styles from "./TrxStockOutModal.module.scss";
import { Link } from "react-router-dom";

const TrxStockOutModal = props => {
    const [stockOutData, setStockOutData] = useState(null);

    useEffect(() => {
        apiCall(
            endpoint.GET_POS_TRX_STOCKOUT + props.localTrxId,
            "get",
            null,
            res => {
                setStockOutData(res.data);
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    }, [props.localTrxId]);

    return (
        <Modal
            title="Stok Barang Keluar"
            footer={null}
            visible={props.opened}
            onCancel={props.onClose}
        >
            <div style={{textAlign: 'center', marginBottom: 20}}>
                <Link to="/inventory/stock-out"><Button type="primary" htmlType="button"><Icon type="plus" /> Buat Transaksi Stok Keluar</Button></Link>
            </div>
            <div className={styles.sectionWrapper}>
                <h2>Sales</h2>
                {!empty(stockOutData?.sales) && stockOutData?.sales.map((row, index) => (
                    <div key={`StockOutViewSales${index}`}>{row.qty} {row.unit_name} {row.item_name} {!empty(row.variant_name)?` - ${row.variant_name}`:''}</div>
                ))}
                {empty(stockOutData?.sales) && (
                    <Empty />
                )}
            </div>

            <Divider />

            <div className={styles.sectionWrapper}>
                <h2>Consumptions</h2>
                {!empty(stockOutData?.consumptions) && stockOutData?.consumptions.map((row, index) => (
                    <div key={`StockOutViewConsumptions${index}`}>{row.qty} {row.unit_name} {row.item_name} {!empty(row.variant_name)?` - ${row.variant_name}`:''}</div>
                ))}
                {empty(stockOutData?.consumptions) && (
                    <Empty />
                )}
            </div>

            <Divider />

            <div className={styles.sectionWrapper}>
                <h2>Ingredients</h2>
                {!empty(stockOutData?.ingredients) && stockOutData?.ingredients.map((row, index) => (
                    <div key={`StockOutViewIngredients${index}`}>{row.qty} {row.unit_name} {row.item_name} {!empty(row.variant_name)?` - ${row.variant_name}`:''}</div>
                ))}
                {empty(stockOutData?.ingredients) && (
                    <Empty />
                )}
            </div>

        </Modal>
    );
};

TrxStockOutModal.propTypes = {
    localTrxId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired
};

TrxStockOutModal.defaultProps = {
};

export default TrxStockOutModal;
