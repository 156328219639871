import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Form,
    Input,
    Radio,
    Button,
    message,
    Row,
    Col,
    Spin,
    Modal,
    Avatar,
    List,
    Divider,
    Icon
} from 'antd';
import { apiCall, promiseTimeout } from '../shared/helpers/networkHelper';
import * as endpoint from '../shared/utils/endpoints';
import styles from './RedeemTrxSms.module.scss';
import { empty } from '../shared/helpers/generalHelper';
import { connect } from 'react-redux';
import { loyaltyOperations } from '../state/ducks/loyalty';
import { GREEN } from '../shared/utils/colors';
import DatePicker from 'react-mobile-datepicker';
import { MOBILE_DATEPICKER_MONTH_LONG_MAP } from '../shared/utils/constants';

const FormItem = Form.Item;

class RedeemTrxSms extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            redeem_item: null,
            user_identifier_string: '',
            user: null,
            isDatePickerOpened: false,
            sendSmsLoading: false,
            redeem_code: '',
            submitRedeemCodeLoading: false,
            purchase_no: null,
            saveRedeemLoading: false,
            finalSuccessMessage: ''
        };

        this.state = { ...this.initialState };
    }

    componentDidMount() {
        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.GET_REDEEMABLES,
                    'get',
                    null,
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.props.setLoyaltyStates({
                    ...this.props.loyaltyStates,
                    redeem_items: result.data
                });
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
            });
    }

    onRedeemItemChosen = redeem_item => {
        this.setState({
            step: 2,
            redeem_item
        });
    };

    onSearchCustomer = e => {
        if (empty(this.state.user_identifier_string)) {
            message.error('Nomor HP harus diisi!');
            return;
        }

        this.setState({ sendSmsLoading: true });
        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.GET_MEMBER_INFO,
                    'post',
                    {
                        user_identifier_type: 'phone',
                        user_identifier_string: this.state
                            .user_identifier_string
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState({
                    user: result.data,
                    sendSmsLoading: false
                });
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ sendSmsLoading: false });
            });
    };

    onDatePickerSelected = dateInput => {
        const parsedDate = [
            dateInput.getFullYear(),
            dateInput.getMonth() + 1,
            dateInput.getDate()
        ].join('-');
        this.setState(
            {
                user: {
                    ...this.state.user,
                    user_birth_date: parsedDate
                }
            },
            () => {
                this.toggleDatePicker();
            }
        );
    };

    toggleDatePicker = () => {
        this.setState({
            isDatePickerOpened: !this.state.isDatePickerOpened
        });
    };

    onSendSms = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.user_identifier_string)) {
            message.error('Nomor HP harus diisi!');
            return;
        }

        this.setState({ sendSmsLoading: true });

        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.POST_SEND_SMS_REDEEM,
                    'post',
                    {
                        user_identifier_string: this.state
                            .user_identifier_string,
                        reward_redemption_item_id: this.state.redeem_item
                            .reward_redemption_item_id,
                        user_name: !empty(this.state.user)
                            ? this.state.user.user_name
                            : '',
                        user_gender: !empty(this.state.user)
                            ? this.state.user.user_gender
                            : '',
                        user_birth_date: !empty(this.state.user)
                            ? this.state.user.user_birth_date
                            : '',
                        user_notes: !empty(this.state.user)
                            ? this.state.user.user_notes
                            : '',
                        is_edit_user: !empty(this.state.user) ? 1 : 0
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState(
                    {
                        step: 3,
                        sendSmsLoading: false
                    },
                    () => {
                        message.success(result.data.message);
                    }
                );
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ sendSmsLoading: false });
            });
    };

    onSubmitRedeemCode = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.redeem_code)) {
            message.error('Redeem code harus diisi!');
            return;
        }

        this.setState({ submitRedeemCodeLoading: true });
        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.POST_EXECUTE_SMS_REDEEM,
                    'post',
                    {
                        scancode: this.state.redeem_code
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState({
                    step: 4,
                    submitRedeemCodeLoading: false,
                    finalSuccessMessage: result.data.message
                });
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ submitRedeemCodeLoading: false });
            });
    };

    handleSaveRedeemOk = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.purchase_no)) {
            message.error('Purchase No. harus diisi!');
            return;
        }

        this.setState({ saveRedeemLoading: true });

        promiseTimeout(
            5000,
            new Promise((resolve, reject) => {
                return apiCall(
                    endpoint.POST_SAVE_SMS_REDEEM,
                    'post',
                    {
                        scancode: this.state.redeem_code,
                        pos_trx_code: this.state.purchase_no
                    },
                    response => {
                        resolve(response);
                    },
                    response => {
                        reject(response);
                    }
                );
            })
        )
            .then(result => {
                // API call success
                this.setState(
                    {
                        step: 4
                    },
                    () => {
                        this.setState({ saveRedeemLoading: false });
                        Modal.success({
                            title: 'Transaction Success!',
                            content:
                                'Purchase no. telah disimpan, transaksi redeem telah berhasil dilakukan.',
                            onOk: () => {
                                this.setState({ ...this.initialState });
                            }
                        });
                    }
                );
            })
            .catch(error => {
                if (error === 'timeout') {
                    // Network is not available
                    message.error(
                        'Telah terjadi kesalahan koneksi, silakan coba lagi.'
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ saveRedeemLoading: false });
            });
    };

    handleSaveRedeemCancel = () => {
        this.setState({ ...this.initialState });
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const resetCustomerBtnLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const {
            step,
            redeem_item,
            user_identifier_string,
            user,
            isDatePickerOpened,
            sendSmsLoading,
            redeem_code,
            submitRedeemCodeLoading,
            purchase_no,
            saveRedeemLoading,
            finalSuccessMessage
        } = this.state;

        const pageTitle = (
            <React.Fragment>
                <Icon type="gift" />
                &nbsp;&nbsp; Penukaran Poin - Melalui SMS
            </React.Fragment>
        );

        return (
            <Card
                title={pageTitle}
                bordered={false}
                loading={empty(this.props.loyaltyState.redeem_items)}
            >
                <Row gutter={48}>
                    <Col sm={12} xs={24}>
                        {(step === 1 || empty(redeem_item)) && (
                            <React.Fragment>
                                <h2>Pilih Barang</h2>
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 6,
                                        xxl: 3
                                    }}
                                    dataSource={
                                        this.props.loyaltyState.redeem_items
                                    }
                                    renderItem={item => (
                                        <List.Item
                                            key={item.reward_redemption_item_id}
                                        >
                                            <Card
                                                onClick={() => {
                                                    this.onRedeemItemChosen(
                                                        item
                                                    );
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div
                                                    className={
                                                        styles.redeemItemDiv
                                                    }
                                                >
                                                    <h3>{item.item_name}</h3>
                                                    <Avatar
                                                        size={80}
                                                        shape="square"
                                                        src={item.image_url}
                                                    />
                                                    <p>{`${item.point_amount} ${
                                                        item.reward_name
                                                    }`}</p>
                                                </div>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </React.Fragment>
                        )}

                        {step === 2 && !empty(redeem_item) && (
                            <React.Fragment>
                                <Spin spinning={sendSmsLoading}>
                                    <Form onSubmit={this.onSendSms}>
                                        <FormItem
                                            {...formItemLayout}
                                            label="Redeem Item"
                                        >
                                            {redeem_item.item_name} (
                                            {`${redeem_item.point_amount} ${
                                                redeem_item.reward_name
                                            }`}
                                            )
                                        </FormItem>

                                        <FormItem
                                            {...formItemLayout}
                                            label="Phone Number"
                                        >
                                            <Input.Search
                                                addonBefore="+62"
                                                onSearch={this.onSearchCustomer}
                                                onChange={e => {
                                                    this.setState({
                                                        user_identifier_string:
                                                            e.target.value
                                                    });
                                                }}
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Find"
                                                size="large"
                                                type="tel"
                                            />
                                        </FormItem>

                                        {user !== null && (
                                            <React.Fragment>
                                                <Divider />

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Customer Name"
                                                >
                                                    <Input
                                                        type="text"
                                                        onChange={e => {
                                                            this.setState({
                                                                user: {
                                                                    ...user,
                                                                    user_name:
                                                                        e.target
                                                                            .value
                                                                }
                                                            });
                                                        }}
                                                        value={user.user_name}
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Date of Birth"
                                                >
                                                    <Input
                                                        type="text"
                                                        onChange={e => {
                                                            this.setState({
                                                                user: {
                                                                    ...user,
                                                                    user_birth_date:
                                                                        e.target
                                                                            .value
                                                                }
                                                            });
                                                        }}
                                                        onFocus={
                                                            this
                                                                .toggleDatePicker
                                                        }
                                                        readOnly={true}
                                                        value={
                                                            user.user_birth_date
                                                        }
                                                    />
                                                    <DatePicker
                                                        theme="ios"
                                                        dateFormat={[
                                                            'DD',
                                                            [
                                                                'MM',
                                                                month =>
                                                                    MOBILE_DATEPICKER_MONTH_LONG_MAP[
                                                                        month
                                                                    ]
                                                            ],
                                                            'YYYY'
                                                        ]}
                                                        showFormat="DD-MM-YYYY"
                                                        confirmText="APPLY"
                                                        cancelText="CANCEL"
                                                        max={new Date()}
                                                        min={
                                                            new Date(1930, 0, 1)
                                                        }
                                                        // value={new Date(user.user_birth_date)}
                                                        isOpen={
                                                            isDatePickerOpened
                                                        }
                                                        onSelect={
                                                            this
                                                                .onDatePickerSelected
                                                        }
                                                        onCancel={
                                                            this
                                                                .toggleDatePicker
                                                        }
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Gender"
                                                >
                                                    <Radio.Group
                                                        defaultValue=""
                                                        buttonStyle="solid"
                                                        value={user.user_gender}
                                                        onChange={e => {
                                                            this.setState({
                                                                user: {
                                                                    ...user,
                                                                    user_gender:
                                                                        e.target
                                                                            .value
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Radio.Button value={1}>
                                                            Laki-laki
                                                        </Radio.Button>
                                                        <Radio.Button value={2}>
                                                            Perempuan
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </FormItem>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Notes"
                                                >
                                                    <Input.TextArea
                                                        type="text"
                                                        onChange={e => {
                                                            this.setState({
                                                                user: {
                                                                    ...user,
                                                                    user_notes:
                                                                        e.target
                                                                            .value
                                                                }
                                                            });
                                                        }}
                                                        rows={4}
                                                        value={user.user_notes}
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    {...resetCustomerBtnLayout}
                                                >
                                                    <Button
                                                        type="danger"
                                                        size="large"
                                                        block={true}
                                                        onClick={() => {
                                                            this.setState({
                                                                user_identifier_string:
                                                                    '',
                                                                user: null
                                                            });
                                                        }}
                                                        htmlType="button"
                                                    >
                                                        Change Customer
                                                    </Button>
                                                </FormItem>
                                            </React.Fragment>
                                        )}

                                        <FormItem {...resetCustomerBtnLayout}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                block={true}
                                                htmlType="submit"
                                            >
                                                Send SMS
                                            </Button>
                                        </FormItem>
                                    </Form>
                                </Spin>
                            </React.Fragment>
                        )}

                        {step === 3 && !empty(redeem_item) && (
                            <React.Fragment>
                                <Spin spinning={submitRedeemCodeLoading}>
                                    <Form onSubmit={this.onSubmitRedeemCode}>
                                        <FormItem
                                            {...formItemLayout}
                                            label="Redeem Item"
                                        >
                                            {redeem_item.item_name} (
                                            {`${redeem_item.point_amount} ${
                                                redeem_item.reward_name
                                            }`}
                                            )
                                        </FormItem>

                                        <FormItem
                                            {...formItemLayout}
                                            label="Nomor HP"
                                        >
                                            {user_identifier_string}
                                        </FormItem>

                                        <FormItem
                                            {...formItemLayout}
                                            label="Redeem Code dari SMS"
                                        >
                                            <Input
                                                onChange={e => {
                                                    this.setState({
                                                        redeem_code:
                                                            e.target.value
                                                    });
                                                }}
                                                value={redeem_code}
                                                size="large"
                                            />
                                        </FormItem>

                                        <FormItem {...resetCustomerBtnLayout}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                block={true}
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </FormItem>
                                    </Form>
                                </Spin>
                            </React.Fragment>
                        )}

                        {step === 4 && !empty(redeem_item) && (
                            <React.Fragment>
                                <Spin spinning={saveRedeemLoading}>
                                    <Modal
                                        title="Redeem Success"
                                        visible={
                                            step === 4 && !empty(redeem_item)
                                        }
                                        onOk={this.handleSaveRedeemOk}
                                        confirmLoading={saveRedeemLoading}
                                        onCancel={this.handleSaveRedeemCancel}
                                        closable={false}
                                        footer=""
                                        okButtonProps={{
                                            type: 'submit'
                                        }}
                                    >
                                        <Form
                                            onSubmit={this.handleSaveRedeemOk}
                                        >
                                            <p
                                                className={
                                                    styles.successMessage
                                                }
                                            >
                                                <Avatar
                                                    style={{
                                                        backgroundColor: GREEN
                                                    }}
                                                    size={56}
                                                    icon="check"
                                                />
                                                <br />
                                                <br />
                                                {finalSuccessMessage}
                                            </p>

                                            <FormItem
                                                {...formItemLayout}
                                                label="Purchase No."
                                            >
                                                <Input
                                                    onChange={e => {
                                                        this.setState({
                                                            purchase_no:
                                                                e.target.value
                                                        });
                                                    }}
                                                    placeholder="Purchase No."
                                                    value={purchase_no}
                                                    disabled={saveRedeemLoading}
                                                    size="large"
                                                />
                                            </FormItem>

                                            <FormItem>
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    block={true}
                                                    htmlType="submit"
                                                >
                                                    Simpan
                                                </Button>
                                            </FormItem>
                                        </Form>
                                    </Modal>
                                </Spin>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        loyaltyState: state.loyaltyState.loyaltyState,
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    setLoyaltyStates: loyaltyOperations.setLoyaltyStates
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedeemTrxSms);
