import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Col, Empty, Icon, Row, Spin } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import { empty } from "../shared/helpers/generalHelper";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../shared/utils/constants";
import connect from "react-redux/es/connect/connect";
import { PosPriceList } from "../modules";
import ErrorHandler from "../shared/classes/ErrorHandler";
import uuid from "uuid/v4";
import moment from "moment";

class TrxHistoryEdit extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            trx: null,
            edit_session_id: uuid()
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        let local_trx_id = this.props.match.params.local_trx_id;
        if(empty(local_trx_id)){
            local_trx_id = this.props.local_trx_id;
        }

        this.loadTrx(local_trx_id);
    }

    loadTrx = local_trx_id => {
        apiCall(
            endpoint.GET_TRX_HISTORY_DETAIL(local_trx_id),
            "get",
            null,
            res => {
                this.setState({ trx: res.data });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    render() {
        const {
            formLoading, trx
        } = this.state;

        const pageTitle = (
            <React.Fragment>
                <Icon type="database"/>
                &nbsp;&nbsp; Edit Transaksi #{trx?.local_trx_id?.slice(-6).toUpperCase()} / {moment(trx?.trx_local_end_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false} style={{minHeight: '100vh'}}>
                <Row gutter={48}>
                    <Col xxl={12} sm={12}>
                        <Spin spinning={formLoading}>

                            {!empty(trx) && (
                                <Row>
                                    <Col span={24}>
                                        {empty(trx) && <div style={{marginTop: '30vh'}}><Empty/></div>}
                                        {!empty(trx) && (
                                            <>
                                                <h3>
                                                    Klik pada nama item untuk melakukan perubahan
                                                </h3>
                                                <PosPriceList
                                                    trx={trx}
                                                    posConfig={trx.cashier_configs}
                                                    isPrint={false}
                                                    postEditMode={true}
                                                    postEditOnSubmit={() => {}}
                                                    postEditSessionId={this.state.edit_session_id}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            )}

                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrxHistoryEdit);