import React, { Component } from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";
import { Button, Card, Col, DatePicker, Divider, Form, Icon, Input, message, Modal, Radio, Row, Spin } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./MemberActivation.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import connect from "react-redux/es/connect/connect";
import { loyaltyOperations } from "../state/ducks/loyalty";
import { validEmail, validPhone } from "../shared/helpers/stringHelper";
import moment from "moment";
import ErrorHandler from "../shared/classes/ErrorHandler";

const FormItem = Form.Item;

class MemberActivation extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            business_membership_id: this.props.userData.business_memberships[0]
                .business_membership_id,
            business_membership: null,
            pos_trx_code: "",
            user_identifier_type: "phone",
            user_identifier_string: "",
            user: null,
            searchUserLoading: false,
            isDatePickerOpened: false,
            formLoading: false
        };

        this.state = { ...this.initialState };
    }

    onSearchCustomer = e => {
        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Please fill out the user contact.");
            return;
        }

        if (this.state.user_identifier_type === "phone") {
            if (!validPhone(this.state.user_identifier_string)) {
                message.error("Please input a valid phone number.");
                return;
            }
        }

        if (this.state.user_identifier_type === "email") {
            if (!validEmail(this.state.user_identifier_string)) {
                message.error("Please input a valid email.");
                return;
            }
        }

        this.setSearchUserLoading(true);
        if (navigator.onLine) {
            apiCall(
                endpoint.POST_USER_INFO,
                "post",
                {
                    transaction_type: "membership_activation",
                    user_identifier_type: this.state
                        .user_identifier_type,
                    user_identifier_string: this.state
                        .user_identifier_string
                },
                response => {
                    this.setState({ user: response.data });
                },
                error => {
                    if (error === "timeout") {
                        // Network is not available
                        if (this.state.user_identifier_type === "qr") {
                            message.error(
                                "Koneksi internet dibutuhkan untuk pencarian customer, mohon coba kembali"
                            );
                        } else {
                            message.warning(
                                "Koneksi internet tidak stabil, data customer akan disinkronisasi otomatis ketika koneksi telah pulih kembali"
                            );
                        }
                    } else {
                        // API throwing error object
                        message.error(error.data.error.message);
                    }

                    if (this.state.user_identifier_type !== "qr") {
                        this.setEmptyCustomer();
                    }
                },
                res => {
                    this.setSearchUserLoading(false);
                }
            );
        } else {
            this.setEmptyCustomer();
            this.setSearchUserLoading(false);
            message.warning(
                "No internet connection, customer data will be synchronized later."
            );
        }
    };

    setSearchUserLoading = state => {
        this.setState({
            searchUserLoading: state
        });
    };

    handleQrScan = data => {
        if (data) {
            this.setState(
                { user_identifier_string: data },
                this.onSearchCustomer
            );
        }
    };

    handleQrError = err => {
        console.error(err);
    };

    // CUSTOMER DATA
    onChangeUserName = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_name: e.target.value
            }
        });
    };

    onChangeUserDateOfBirth = (momentDate, DateString) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    user_birth_date: DateString
                }
            }
        );
    };

    onChangeUserGender = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_gender: e.target.value
            }
        });
    };

    onChangeUserNotes = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_notes: e.target.value
            }
        });
    };

    resetCustomer = () => {
        this.setState({
            user: null
        });
    };

    setEmptyCustomer = () => {
        this.setState({
            user: {
                user_code: null,
                user_name: "",
                user_gender: "",
                user_birth_date: "",
                user_notes: "",
                retrieve_time: ""
            }
        });
    };

    onDatePickerSelected = dateInput => {
        const parsedDate = [
            dateInput.getFullYear(),
            dateInput.getMonth() + 1,
            dateInput.getDate()
        ].join("-");
        this.setState(
            {
                user: {
                    ...this.state.user,
                    user_birth_date: parsedDate
                }
            },
            () => {
                this.toggleDatePicker();
            }
        );
    };

    toggleDatePicker = () => {
        this.setState({
            isDatePickerOpened: !this.state.isDatePickerOpened
        });
    };

    onSubmitForm = e => {
        e.preventDefault();
        e.stopPropagation();
        const stateData = { ...this.state };

        if (empty(stateData.business_membership_id)) {
            message.error("Jenis member harus pilih!");
            return;
        }

        if (!empty(stateData.business_membership)) {
            if (
                stateData.business_membership.price > 0 &&
                stateData.business_membership.is_automatic_activation !== 1
            ) {
                if (empty(stateData.pos_trx_code)) {
                    message.error("Purchase No. harus diisi!");
                    return;
                }
            }
        }

        if (
            empty(stateData.user_identifier_type) ||
            empty(stateData.user_identifier_string)
        ) {
            message.error("Customer harus dipilih!!");
            return;
        }

        this.setState({ formLoading: true });

        apiCall(
            endpoint.POST_MEMBER_ACTIVATION,
            "post",
            {
                user_identifier_string:
                stateData.user_identifier_string,
                user_identifier_type: stateData.user_identifier_type,
                pos_trx_code: stateData.pos_trx_code,
                business_membership_id:
                stateData.business_membership_id,
                user_name: !empty(stateData.user)
                    ? stateData.user.user_name
                    : "",
                user_gender: !empty(stateData.user)
                    ? stateData.user.user_gender
                    : "",
                user_birth_date: !empty(stateData.user)
                    ? stateData.user.user_birth_date
                    : "",
                user_notes: !empty(stateData.user)
                    ? stateData.user.user_notes
                    : "",
                is_edit_user: !empty(stateData.user) ? 1 : 0
            },
            res => {
                this.setState(
                    {
                        step: 4
                    },
                    () => {
                        this.setState({ saveRedeemLoading: false });
                        Modal.success({
                            title: "Transaction Success!",
                            content: res.data.message,
                            onOk: () => {
                                this.setState({ ...this.initialState });
                            }
                        });
                    }
                );
            },
            err => {
                ErrorHandler.handleGeneralError(err);
                this.setState({ formLoading: false });
            }
        );
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const resetCustomerBtnLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 8,
                    offset: 4
                }
            }
        };
        const {
            business_membership_id,
            business_membership,
            pos_trx_code,
            user_identifier_type,
            user_identifier_string,
            user,
            searchUserLoading,
            formLoading
        } = this.state;
        const { business_memberships } = this.props.userData;

        const dateFormat = "YYYY-MM-DD";
        let birthDateDefaultValue = {};
        if (user && !empty(user.user_birth_date)) {
            birthDateDefaultValue.defaultValue = moment(user.user_birth_date);
        }

        const pageTitle = (
            <React.Fragment>
                <Icon type="user-add"/>
                &nbsp;&nbsp; Aktivasi Member
            </React.Fragment>
        );

        return (
            <Spin spinning={formLoading}>
                <Card title={pageTitle} bordered={false}>
                    <Row gutter={48}>
                        <Col sm={12} xs={24}>
                            <FormItem
                                {...formItemLayout}
                                label="Membership"
                            >
                                <Radio.Group
                                    onChange={e => {
                                        this.setState({
                                            business_membership_id:
                                            e.target.value
                                        });
                                    }}
                                    value={business_membership_id}
                                >
                                    {business_memberships.map(
                                        (membership, index) => {
                                            return (
                                                <Radio
                                                    key={index}
                                                    className={
                                                        styles.membershipRadio
                                                    }
                                                    value={
                                                        membership.business_membership_id
                                                    }
                                                >
                                                    {
                                                        membership.membership_name
                                                    }
                                                </Radio>
                                            );
                                        }
                                    )}
                                </Radio.Group>
                            </FormItem>

                            {!empty(business_membership) &&
                            business_membership.price > 0 &&
                            business_membership.is_automatic_activation !==
                            1 && (
                                <FormItem
                                    {...formItemLayout}
                                    label="Purchase No."
                                >
                                    <Input
                                        type="text"
                                        onChange={e => {
                                            this.setState({
                                                pos_trx_code:
                                                e.target.value
                                            });
                                        }}
                                        value={pos_trx_code}
                                    />
                                </FormItem>
                            )}

                            <Spin spinning={searchUserLoading}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Cari Customer Berdasarkan"
                                >
                                    <Radio.Group
                                        defaultValue="phone"
                                        buttonStyle="solid"
                                        disabled={user !== null}
                                        value={user_identifier_type}
                                        onChange={e => {
                                            this.setState({
                                                user_identifier_type:
                                                e.target.value
                                            });
                                        }}
                                    >
                                        <Radio.Button value="phone">
                                            Phone
                                        </Radio.Button>
                                        <Radio.Button value="qr">
                                            QR Code
                                        </Radio.Button>
                                        <Radio.Button value="email">
                                            Email
                                        </Radio.Button>
                                    </Radio.Group>
                                </FormItem>

                                {user_identifier_type === "phone" && (
                                    <FormItem
                                        {...formItemLayout}
                                        label="Phone Number"
                                    >
                                        <Input.Search
                                            addonBefore="+62"
                                            onSearch={this.onSearchCustomer}
                                            onChange={e => {
                                                this.setState({
                                                    user_identifier_string:
                                                    e.target.value
                                                });
                                            }}
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            enterButton="Check"
                                            size="large"
                                            type="tel"
                                        />
                                    </FormItem>
                                )}

                                {user_identifier_type === "qr" && (
                                    <FormItem
                                        {...formItemLayout}
                                        label="Scan QR Code"
                                    >
                                        <QrReader
                                            onScan={this.handleQrScan}
                                            onError={this.handleQrError}
                                            style={{
                                                width: 300,
                                                maxWidth: "100%",
                                                marginBottom: 10,
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                            }}
                                        />

                                        <Input.Search
                                            onSearch={this.onSearchCustomer}
                                            onChange={e => {
                                                this.setState({
                                                    user_identifier_string:
                                                    e.target.value
                                                });
                                            }}
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            enterButton="Check"
                                            size="large"
                                        />
                                    </FormItem>
                                )}

                                {user_identifier_type === "email" && (
                                    <FormItem
                                        {...formItemLayout}
                                        label="Email"
                                    >
                                        <Input.Search
                                            onSearch={this.onSearchCustomer}
                                            onChange={e => {
                                                this.setState({
                                                    user_identifier_string:
                                                    e.target.value
                                                });
                                            }}
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            enterButton="Check"
                                            size="large"
                                            type="email"
                                        />
                                    </FormItem>
                                )}
                            </Spin>

                            {user !== null && (
                                <FormItem {...resetCustomerBtnLayout}>
                                    <Button
                                        type="danger"
                                        size="large"
                                        block={true}
                                        onClick={this.resetCustomer}
                                        htmlType="button"
                                    >
                                        Change Customer
                                    </Button>
                                </FormItem>
                            )}
                        </Col>

                        <Col md={8} sm={12} xs={24}>
                            <h4>
                                Customer Data{" "}
                                {user !== null
                                    ? user.user_code === null
                                        ? "(New Customer)"
                                        : ""
                                    : ""}
                            </h4>
                            {user === null && `No customer chosen`}
                            {user !== null && (
                                <React.Fragment>
                                    <div className={styles.formGroup}>
                                        <label>Customer Name</label>
                                        <Input
                                            type="text"
                                            onChange={this.onChangeUserName}
                                            value={user.user_name}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Date of Birth</label>
                                        <DatePicker
                                            {...birthDateDefaultValue}
                                            format={dateFormat}
                                            onChange={
                                                this.onChangeUserDateOfBirth
                                            }
                                            showToday={true}
                                            allowClear={true}/>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Gender</label>
                                        <Radio.Group
                                            defaultValue=""
                                            buttonStyle="solid"
                                            value={user.user_gender}
                                            onChange={
                                                this.onChangeUserGender
                                            }
                                        >
                                            <Radio.Button value={1}>
                                                Laki-laki
                                            </Radio.Button>
                                            <Radio.Button value={2}>
                                                Perempuan
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>Notes</label>
                                        <Input.TextArea
                                            type="text"
                                            onChange={
                                                this.onChangeUserNotes
                                            }
                                            rows={4}
                                            value={user.user_notes}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>

                    <Divider/>

                    <Row>
                        <FormItem {...tailFormItemLayout}>
                            <Button
                                type="primary"
                                size="large"
                                block={true}
                                htmlType="submit"
                                onClick={this.onSubmitForm}
                            >
                                Submit
                            </Button>
                        </FormItem>
                    </Row>
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        userData: state.authState.userData
    };
};

const mapDispatchToProps = {
    addLoyaltyTrx: loyaltyOperations.addLoyaltyTrx,
    addLocalLoyaltyTrx: loyaltyOperations.addLocalLoyaltyTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberActivation);
