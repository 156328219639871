import React, { Component } from "react";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import { apiCall } from "../shared/helpers/networkHelper";
import { GET_REPORT_WORKER_COMMISSIONS, GET_REPORT_WORKER_COMMISSIONS_BY_TRX, GET_REPORT_WORKER_COMMISSIONS_SUMMARY } from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { Col, Empty, Row, Spin, Table } from "antd";
import { number_format } from "../shared/helpers/stringHelper";
import { connect } from "react-redux";
import styles from './ReportWorkerCommissionPrint.module.scss';

class ReportWorkerCommissionPrint extends Component {

    constructor(props) {
        super(props);

        const url = new URL(window.location.href);

        this.state = {
            loading: false,
            workers: null,
            reportDateFrom: url.searchParams.get('start_date'),
            reportDateTo: url.searchParams.get('end_date'),
            reportType: url.searchParams.get('report_type'),
            itemNature: url.searchParams.get('item_nature'),
            commissionWorkers: url.searchParams.get('commission_workers').split(',')
        };
        this.currencyCode = this.props.userData.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        const { reportDateFrom, reportDateTo, reportType, itemNature, commissionWorkers } = this.state;

        this.loaderShow();

        if(reportType === 'summary'){
            apiCall(
                GET_REPORT_WORKER_COMMISSIONS_SUMMARY + `?start_date=${reportDateFrom}&end_date=${reportDateTo}`,
                "get",
                null,
                res => {
                    this.setState({
                        workers: res.data.filter(row => {
                            return commissionWorkers.find(commissionWorker => {
                                return parseInt(commissionWorker) === row.business_commission_worker_id
                            });
                        })
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                }, res => {
                    this.loaderHide();
                }
            );
        }

        if(reportType === 'by-product'){
            apiCall(
                GET_REPORT_WORKER_COMMISSIONS + `?start_date=${reportDateFrom}&end_date=${reportDateTo}&item_nature=${itemNature}`,
                "get",
                null,
                res => {
                    this.setState({
                        workers: res.data.filter(row => {
                            return commissionWorkers.find(commissionWorker => {
                                return parseInt(commissionWorker) === row.business_commission_worker_id
                            });
                        })
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                }, res => {
                    this.loaderHide();
                }
            );
        }

        if(reportType === 'by-transaction' || reportType === 'by-simple'){
            apiCall(
                GET_REPORT_WORKER_COMMISSIONS_BY_TRX + `?start_date=${reportDateFrom}&end_date=${reportDateTo}`,
                "get",
                null,
                res => {
                    this.setState({
                        workers: res.data.filter(row => {
                            return commissionWorkers.find(commissionWorker => {
                                return parseInt(commissionWorker) === row.business_commission_worker_id
                            });
                        })
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                }, res => {
                    this.loaderHide();
                }
            );
        }
    }

    loaderShow = () => {
        this.setState({loading: true});
    };

    loaderHide = () => {
        this.setState({loading: false});
    };

    renderWorkerCommissionsSummary = (workers) => {
        let mappedWorkers = workers.map((worker, index) => {
            return {
                no: index + 1,
                worker_name: worker.firstname + ' ' + worker.lastname,
                retail_total_commission: worker?.commissions[0]?.retail_total_commission,
                service_total_commission: worker?.commissions[0]?.service_total_commission,
                commission_value: worker?.commissions[0]?.commission_value
            };
        });

        mappedWorkers = mappedWorkers.concat({
            ...mappedWorkers[0],
            fake: true
        });

        let columns = [
            {
                title: "No",
                dataIndex: "no",
                key: "no",
                render: (value, row, index) => {
                    if(index === (mappedWorkers.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Worker",
                dataIndex: "worker_name",
                key: "worker_name",
                render: (value, row, index) => {
                    if(index === (mappedWorkers.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Retail Net Value",
                dataIndex: "retail_total_commission",
                key: "retail_total_commission",
                render: (value, row, index) => {
                    if(index === (mappedWorkers.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.retail_total_commission)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedWorkers.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            },
            {
                title: "Service Net Value",
                dataIndex: "service_total_commission",
                key: "service_total_commission",
                render: (value, row, index) => {
                    if(index === (mappedWorkers.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.service_total_commission)) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedWorkers.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            }
        ];

        if(this.props.userData.auth_role_accesses.WORKER_ACTIVITY_SUMMARY_COMMISSION_VALUE === 1){
            columns = columns.concat({
                title: "Total Commission",
                dataIndex: "commission_value",
                className: styles.tdCommissionBaseValue,
                key: "commission_value",
                render: (value, row, index) => {
                    if(index === (mappedWorkers.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(parseFloat(currentValue.commission_value))) + accumulator;
                        return <strong>{this.currencyCode} {number_format(mappedWorkers.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                }
            });
        }

        return (
            <div style={{ margin: "50px 0" }}>
                <Table  size="small" tableLayout="auto" style={{maxWidth: 600}} rowKey="no" columns={columns} dataSource={mappedWorkers} pagination={false}/>
            </div>
        );
    };

    renderWorkerCommissionsByTrx = (workers) => {
        return workers.map(worker => {
            const data = worker.commissions;

            let mappedWorkerCommissions = data.map((workerCommission, index) => {
                return {
                    no: index+1,
                    ...workerCommission
                }
            });
            mappedWorkerCommissions = mappedWorkerCommissions.concat({
                ...mappedWorkerCommissions[0],
                fake: true
            });

            let columns = [
                {
                    title: "No",
                    dataIndex: "no",
                    className: styles.tdNo,
                    key: "no",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "ID Transaksi",
                    dataIndex: "trx_id",
                    className: styles.tdItemName,
                    key: "trx_id",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Waktu Transaksi",
                    dataIndex: "trx_time",
                    className: styles.tdItemName,
                    key: "trx_time",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Nama Pelanggan",
                    dataIndex: "user_name",
                    className: styles.tdItemName,
                    key: "user_name",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Item",
                    dataIndex: "item_name",
                    className: styles.tdItemName,
                    key: "item_name",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Qty",
                    dataIndex: "qty",
                    className: styles.tdQty,
                    key: "qty",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(currentValue.qty) + accumulator;
                            return <strong>x{number_format(mappedWorkerCommissions.filter(workerCommission => !workerCommission.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `x${number_format(value, 2)}`;
                    }
                },
                {
                    title: "Gross Sales",
                    dataIndex: "gross_total",
                    className: styles.tdGrossTotal,
                    key: "gross_total",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.gross_total)) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Discount Value",
                    dataIndex: "discount_value",
                    className: styles.tdDiscountValue,
                    key: "discount_value",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.discount_value)) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Net Value",
                    dataIndex: "total_commission",
                    className: styles.tdTotalCommission,
                    key: "total_commission",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.total_commission)) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Commission Base Value",
                    dataIndex: "commission_base_value",
                    className: styles.tdCommissionBaseValue,
                    key: "commission_base_value",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(parseFloat(currentValue.commission_base_value))) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                }
            ];

            if(this.props.userData.auth_role_accesses.WORKER_ACTIVITY_TRX_COMMISSION_VALUE === 1){
                columns = columns.concat({
                    title: "Total Commission",
                    dataIndex: "commission_value",
                    className: styles.tdCommissionBaseValue,
                    key: "commission_value",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(parseFloat(currentValue.commission_value))) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                });
            }

            return (
                <div style={{ margin: "50px 0" }}>
                    <h2>{`${worker.firstname} ${worker.lastname}`}</h2>
                    <Table size="small" tableLayout="auto" rowKey={row => row.trx_id + row.pos_item_id + row.no} columns={columns} dataSource={mappedWorkerCommissions} pagination={false}/>
                </div>
            );
        });
    };

    renderWorkerCommissionsByProduct = (workers) => {
        return workers.map(worker => {
            const data = worker.commissions;

            let mappedWorkerCommissions = data.map((workerCommission, index) => {
                return {
                    no: index+1,
                    ...workerCommission
                }
            });
            mappedWorkerCommissions = mappedWorkerCommissions.concat({
                ...mappedWorkerCommissions[0],
                fake: true
            });

            let columns = [
                {
                    title: "No",
                    dataIndex: "no",
                    className: styles.tdNo,
                    key: "no",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Item",
                    dataIndex: "item_name",
                    className: styles.tdItemName,
                    key: "item_name",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Price",
                    dataIndex: "variant_price",
                    className: styles.tdVariantPrice,
                    key: "variant_price",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Qty",
                    dataIndex: "qty",
                    className: styles.tdQty,
                    key: "qty",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(currentValue.qty) + accumulator;
                            return <strong>x{number_format(mappedWorkerCommissions.filter(workerCommission => !workerCommission.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `x${number_format(value, 2)}`;
                    }
                },
                {
                    title: "Gross Sales",
                    dataIndex: "gross_total",
                    className: styles.tdGrossTotal,
                    key: "gross_total",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.gross_total)) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Discount Value",
                    dataIndex: "discount_value",
                    className: styles.tdDiscountValue,
                    key: "discount_value",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.discount_value)) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Net Value",
                    dataIndex: "total_commission",
                    className: styles.tdTotalCommission,
                    key: "total_commission",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(currentValue.total_commission)) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                },
                {
                    title: "Commission Base Value",
                    dataIndex: "commission_base_value",
                    className: styles.tdCommissionBaseValue,
                    key: "commission_base_value",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(parseFloat(currentValue.commission_base_value))) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                }
            ];

            if(this.props.userData.auth_role_accesses.WORKER_ACTIVITY_ITEM_NATURE_COMMISSION_VALUE === 1){
                columns = columns.concat({
                    title: "Total Commission",
                    dataIndex: "commission_value",
                    className: styles.tdCommissionBaseValue,
                    key: "commission_value",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(Math.floor(parseFloat(currentValue.commission_value))) + accumulator;
                            return <strong>{this.currencyCode} {number_format(mappedWorkerCommissions.filter(trx => !trx.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0)}`;
                    }
                });
            }

            return (
                <div style={{ margin: "50px 0" }}>
                    <h2>{`${worker.firstname} ${worker.lastname}`}</h2>
                    <Table size="small" tableLayout="auto" rowKey={row => worker.business_commission_worker_id + '-' + row.pos_item_id + '-' + row.no} columns={columns} dataSource={mappedWorkerCommissions} pagination={false}/>
                </div>
            );
        });
    };

    renderWorkerCommissionsBySimple = (workers) => {
        return workers.map(worker => {
            const data = worker.commissions;

            let mappedWorkerCommissions = data.map((workerCommission, index) => {
                return {
                    no: index+1,
                    ...workerCommission
                }
            });
            mappedWorkerCommissions = mappedWorkerCommissions.concat({
                ...mappedWorkerCommissions[0],
                fake: true
            });

            let columns = [
                {
                    title: "No",
                    dataIndex: "no",
                    className: styles.tdNo,
                    key: "no",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "ID Transaksi",
                    dataIndex: "trx_id",
                    className: styles.tdItemName,
                    key: "trx_id",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Nama Pelanggan",
                    dataIndex: "user_name",
                    className: styles.tdItemName,
                    key: "user_name",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Item",
                    dataIndex: "item_name",
                    className: styles.tdItemName,
                    key: "item_name",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            return ``;
                        }
                        return value;
                    }
                },
                {
                    title: "Qty",
                    dataIndex: "qty",
                    className: styles.tdQty,
                    key: "qty",
                    render: (value, row, index) => {
                        if(index === (mappedWorkerCommissions.length - 1)){
                            const sumReducer = (accumulator, currentValue) => parseInt(currentValue.qty) + accumulator;
                            return <strong>x{number_format(mappedWorkerCommissions.filter(workerCommission => !workerCommission.fake).reduce(sumReducer, 0), 0)}</strong>;
                        }
                        return `x${number_format(value, 2)}`;
                    }
                }
            ];

            return (
                <div style={{ margin: "50px 0" }}>
                    <h2>{`${worker.firstname} ${worker.lastname}`}</h2>
                    <Table size="small" tableLayout="auto" rowKey={row => row.trx_id + row.pos_item_id + row.no} columns={columns} dataSource={mappedWorkerCommissions} pagination={false}/>
                </div>
            );
        });
    };

    render() {
        const { loading, workers, reportDateFrom, reportDateTo, reportType, itemNature } = this.state;

        return (
            <Spin spinning={loading}>
            <Row>
                <Col>
                    <div style={{ padding: 10 }}>
                        <h1>Riwayat Komisi Pekerja</h1>

                        <table>
                            <tbody>
                            <tr>
                                <td>Outlet</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.userData.brand_name} - {this.props.userData.outlet_name}</td>
                            </tr>
                            <tr>
                                <td>Kasir</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.userData.cashier_name}</td>
                            </tr>
                            {!empty(itemNature) && (
                                <tr>
                                    <td>Jenis Produk</td>
                                    <td style={{width: 15, textAlign: 'center'}}>:</td>
                                    <td>{itemNature}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Tanggal Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)} - {moment(reportDateTo, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                            </tr>
                            <tr>
                                <td>Waktu Pembuatan Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment().format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                            </tr>
                            </tbody>
                        </table>

                        {empty(workers) && <Empty style={{maxWidth: 500, margin: '100px 0'}} />}
                        {!empty(workers) && reportType==='summary'  && this.renderWorkerCommissionsSummary(workers)}
                        {!empty(workers) && reportType==='by-product'  && this.renderWorkerCommissionsByProduct(workers)}
                        {!empty(workers) && reportType==='by-transaction'  && this.renderWorkerCommissionsByTrx(workers)}
                        {!empty(workers) && reportType==='by-simple'  && this.renderWorkerCommissionsBySimple(workers)}
                    </div>
                </Col>
            </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData
    };
};

export default connect(mapStateToProps)(ReportWorkerCommissionPrint);