import React, { Component } from "react";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import { apiCall } from "../shared/helpers/networkHelper";
import { GET_REPORT_TREATMENT_REMINDER } from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { Col, Empty, Row, Spin, Table } from "antd";
import { connect } from "react-redux";

class ReportTreatmentReminderPrint extends Component {

    constructor(props) {
        super(props);

        const url = new URL(window.location.href);

        this.state = {
            loading: false,
            reminders: null,
            reportDateFrom: url.searchParams.get('start_date'),
            reportDateTo: url.searchParams.get('end_date'),
        };
    }

    componentDidMount() {
        const { reportDateFrom, reportDateTo } = this.state;

        this.loaderShow();
        apiCall(
            GET_REPORT_TREATMENT_REMINDER + `?start_date=${reportDateFrom}&end_date=${reportDateTo}`,
            "get",
            null,
            res => {
                this.setState({
                    reminders: res.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }, res => {
                this.loaderHide();
            }
        );
    }

    loaderShow = () => {
        this.setState({loading: true});
    };

    loaderHide = () => {
        this.setState({loading: false});
    };

    renderReminderTable = (reminders) => {
        let mappedReminders = reminders.map((reminder, index) => {
            return {
                no: index+1,
                ...reminder
            }
        });

        const columns = [
            {
                title: "No",
                dataIndex: "no",
                key: "no",
                render: (value, row, index) => {
                    return value;
                }
            },{
                title: "Phone Number",
                dataIndex: "user_phone",
                key: "user_phone",
                render: (value, row, index) => {
                    return `0${value}`;
                }
            },
            {
                title: "Customer Name",
                dataIndex: "user_name",
                key: "user_name",
                render: (value, row, index) => {
                    return value;
                }
            },
            {
                title: "Treatment",
                dataIndex: "treatment_name",
                key: "treatment_name",
                render: (value, row, index) => {
                    return value;
                }
            },
            {
                title: "Treatment Date",
                dataIndex: "trx_date",
                key: "trx_date",
                render: (value, row, index) => {
                    return value;
                }
            },
            {
                title: "Expected Return Date",
                dataIndex: "expected_return_date",
                key: "expected_return_date",
                render: (value, row, index) => {
                    return value;
                }
            },
            {
                title: "",
                dataIndex: "user_phone",
                key: "user_phone",
                render: (value, row, index) => {
                    return (<a target="_blank" rel="noopener noreferrer" href={`https://wa.me/62${value}`}>Open WhatsApp</a>);
                }
            }
        ];

        return (
            <div style={{ margin: "50px 0" }}>
                <Table size="small"
                       tableLayout="auto"
                       rowKey="item_name"
                       columns={columns}
                       dataSource={mappedReminders}
                       style={{maxWidth: 800}}
                       pagination={false}/>
            </div>
        );
    };

    render() {
        const { loading, reminders, reportDateFrom, reportDateTo} = this.state;

        return (
            <Spin spinning={loading}>
            <Row>
                <Col>
                    <div style={{ padding: 10 }}>
                        <h1>Pengingat Treatment</h1>
                        <table>
                            <tbody>
                            <tr>
                                <td>Outlet</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.brand_name} - {this.props.posConfig.outlet_name}</td>
                            </tr>
                            <tr>
                                <td>Kasir</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.cashier_name}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)} - {moment(reportDateTo, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                            </tr>
                            <tr>
                                <td>Waktu Pembuatan Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment().format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                            </tr>
                            </tbody>
                        </table>

                        {empty(reminders) && <Empty style={{maxWidth: 500, margin: '100px 0'}} />}

                        {!empty(reminders) && this.renderReminderTable(reminders)}
                    </div>
                </Col>
            </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

export default connect(mapStateToProps)(ReportTreatmentReminderPrint);
