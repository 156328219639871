import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, Empty, Input, message, Modal, Popconfirm, Spin } from "antd";
import { posOperations } from '../../state/ducks/pos';
import { empty } from '../../shared/helpers/generalHelper';
import styles from './PosCompletedTrxList.module.scss';
import { number_format } from '../../shared/helpers/stringHelper';
import moment from 'moment';
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";
import PosPriceCalculator from '../../shared/classes/PosPriceCalculator';
import { apiCall } from '../../shared/helpers/networkHelper';
import * as endpoint from '../../shared/utils/endpoints';
import TrxStockOutModal from "../../shared/components/TrxStockOutModal";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import Synchronizer from "../../shared/classes/Synchronizer";

class PosCompletedTrxList extends Component {
    static propTypes = {
        onItemClickedCallback: PropTypes.func.isRequired,
        filterKeyword: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.initialState = {
            showLoader: false,
            showVoidPasswordModal: false,
            voidPassword: '',
            voidReason: '',
            voidTrx: null
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
    }

    onListItemClicked = local_trx_id => {
        /*this.props.switchCurrentTrx(local_trx_id);
        this.props.onItemClickedCallback();*/
        // if(this.props.appConfig.displayMode === "DESKTOP"){
        //     window.open('pos/receipt/' + local_trx_id + '/completed');
        // }else{
            window.location.href = 'pos/receipt/' + local_trx_id + '/completed';
        // }
    };

    voidTransaction = async () => {
        this.setState({ showLoader: true });

        await Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            true,
            () => {
                const local_trx_id = this.state.voidTrx.local_trx_id;
                const trx = this.props.posTrx.find(row => row.local_trx_id === local_trx_id);
                const copiedTrx = { ...trx };

                apiCall(
                    endpoint.POST_POS_TRX_VOID +
                    '/' +
                    trx.pos_sales_trx_head_id,
                    'post',
                    {
                        password: this.state.voidPassword,
                        reason: this.state.voidReason
                    },
                    res => {
                        message.success('Transaksi berhasil divoid');
                        this.props.setTrxProps(trx.local_trx_id, {
                            ...copiedTrx,
                            trx_status_id: 4
                        });
                        this.setState({ ...this.initialState });
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                    },
                    res => {
                        this.setState({ showLoader: false });
                    }
                );
            }
        ).catch(err => {
            message.error("Gagal melakukan proses sinkronisasi, silakan mencoba kembali dengan refresh halaman ini.");
            this.setState({ showLoader: false });
        });


    };

    renderPosCompletedTrx = posTrx => {
        return posTrx
            .reverse()
            .map((trx, index) => {
                const customerName = empty(trx.customer)
                    ? 'GUEST'
                    : empty(trx.customer.user_name)
                    ? 'GUEST'
                    : trx.customer.user_name;
                const customerIdentifierString = empty(trx.customer)
                    ? 'No Information'
                    : empty(trx.customer.user_identifier_string)
                    ? 'No Information'
                    : trx.customer.user_identifier_string;
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    trx,
                    this.props.posConfig,
                    this.currencyCode
                ).grandTotal;
                let totalQty = 0;
                if (!empty(trx.details)) {
                    trx.details.map(detail => {
                        totalQty += detail.qty;
                        return detail;
                    });
                }

                return (
                    <div className={styles.trxItem} key={index}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Avatar
                                            icon="user"
                                            size="large"
                                            className={styles.trxItemAvatar}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {trx.trx_status_id === 4 && (
                                            <div className={styles.voidLabel}>
                                                [VOID]
                                            </div>
                                        )}
                                        <div
                                            className={styles.trxInfo}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id
                                                );
                                            }}
                                        >
                                            {!empty(trx.order_no) && <div>{`Order No.     ${trx.order_no}`}</div>}
                                            [{trx.local_trx_id.toUpperCase().substr(-6)}] - {trx.trx_name}
                                        </div>
                                        <div
                                            className={styles.trxCustomerName}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id
                                                );
                                            }}
                                        >
                                            {customerName}
                                        </div>
                                        <div
                                            className={styles.trxInfo}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id
                                                );
                                            }}
                                        >
                                            {customerIdentifierString}
                                        </div>
                                        <div
                                            className={styles.trxInfo}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id
                                                );
                                            }}
                                        >
                                            {number_format(totalQty, this.props.posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator)} Produk, Total {this.currencyCode}{' '}
                                            {number_format(grandTotal, 0)}
                                        </div>
                                        <div
                                            className={styles.trxInfo}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id
                                                );
                                            }}
                                        >
                                            Selesai{' '}
                                            {moment(
                                                trx.trx_local_end_time, MOMENT_SQL_DATETIME_FORMAT
                                            ).format(
                                                MOMENT_READABLE_DATETIME_FORMAT
                                            )}{' '}
                                            (
                                            {moment(
                                                trx.trx_local_end_time, MOMENT_SQL_DATETIME_FORMAT
                                            ).fromNow()}
                                            )
                                        </div>
                                        {this.props.posConfig.is_document_date_editable === 1 && (
                                            <div
                                                className={styles.trxInfo}
                                                onClick={() => {
                                                    this.onListItemClicked(
                                                        trx.local_trx_id
                                                    );
                                                }}
                                            >
                                                Doc. Date{' '}
                                                {moment(
                                                    trx.document_date, MOMENT_SQL_DATETIME_FORMAT
                                                ).format(
                                                    MOMENT_READABLE_DATETIME_FORMAT
                                                )}{' '}
                                                (
                                                {moment(
                                                    trx.document_date, MOMENT_SQL_DATETIME_FORMAT
                                                ).fromNow()}
                                                )
                                            </div>
                                        )}
                                        <div className={styles.trxInfo}>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    this.onListItemClicked(
                                                        trx.local_trx_id
                                                    );
                                                }}
                                                className={styles.viewTrxBtn}
                                            >
                                                Lihat Transaksi
                                            </button>
                                            {trx.trx_status_id !== 4 && this.props.posConfig.is_void_allowed === 1 && (
                                                <React.Fragment>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <Popconfirm
                                                        title="Apakah Anda yakin ingin void transaksi ini?"
                                                        onConfirm={() => {
                                                            this.setState({
                                                                showVoidPasswordModal: true,
                                                                voidTrx: trx
                                                            });
                                                        }}
                                                        okText="Ya"
                                                        cancelText="Tidak"
                                                    >
                                                        <button
                                                            type="button"
                                                            className={
                                                                styles.voidBtn
                                                            }
                                                        >
                                                            Void
                                                        </button>
                                                    </Popconfirm>
                                                </React.Fragment>
                                            )}

                                            {this.props.posConfig.auth_role_accesses.POS_SHOW_STOCK_OUT === 1 && (
                                            <>
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            stockOutModalOpened: true,
                                                            stockOutLocalTrxId: trx.local_trx_id
                                                        });
                                                    }}
                                                    className={styles.viewTrxBtn}
                                                >
                                                    Stok Keluar
                                                </button>
                                            </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            });
    };

    render() {
        const { posTrx } = this.props;
        const filteredPosTrx = posTrx.filter(trx => {
            const customerName = String(
                empty(trx.customer)
                    ? 'GUEST'
                    : empty(trx.customer.user_name)
                    ? 'GUEST'
                    : trx.customer.user_name
            );
            const customerIdentifierString = String(
                empty(trx.customer)
                    ? 'No Contact Information'
                    : empty(trx.customer.user_identifier_string)
                    ? 'No Contact Information'
                    : trx.customer.user_identifier_string
            );
            const grandTotal = PosPriceCalculator.calculateGrandTotal(
                trx,
                this.props.posConfig,
                this.currencyCode
            ).grandTotal;
            let totalQty = 0;
            if (!empty(trx.details)) {
                trx.details.map(detail => {
                    totalQty += detail.qty;
                    return detail;
                });
            }
            const totalQtyString = number_format(totalQty, this.props.posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);
            const totalString = `${totalQtyString} Produk, Total ${this.currencyCode} ${number_format(
                grandTotal,
                0
            )}`;
            const timeString = `${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).format(
                MOMENT_READABLE_DATETIME_FORMAT
            )} (${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).fromNow()})`;
            return (
                (trx.trx_status_id === 3 || trx.trx_status_id === 4) &&
                (
                    String(customerName).toLowerCase().includes(String(this.props.filterKeyword).toLowerCase()) ||
                    String(customerIdentifierString).toLowerCase().includes(String(this.props.filterKeyword).toLowerCase()) ||
                    String(trx.local_trx_id).toLowerCase().includes(String(this.props.filterKeyword).toLowerCase()) ||
                    String(totalString).toLowerCase().includes(String(this.props.filterKeyword).toLowerCase()) ||
                    String(timeString).toLowerCase().includes(String(this.props.filterKeyword).toLowerCase()) ||
                    String(trx.trx_label).toLowerCase().includes(String(this.props.filterKeyword).toLowerCase())
                )
            );
        });

        return (
            <div className={styles.trxList}>
                <Spin spinning={this.state.showLoader}>
                    {!empty(filteredPosTrx) && this.renderPosCompletedTrx(filteredPosTrx)}
                    {empty(filteredPosTrx) && (
                        <div className={styles.emptyCompletedTrx}>
                            {empty(this.props.filterKeyword) &&(
                                <Empty style={{marginTop: 50}} description="Belum ada transaksi" />
                            )}
                            {!empty(this.props.filterKeyword) && (
                                <Empty style={{marginTop: 50}} description="Transaksi tidak ditemukan" />
                            )}
                        </div>
                    )}

                    <Modal
                        title="Void Transaksi"
                        visible={this.state.showVoidPasswordModal}
                        onOk={this.voidTransaction}
                        onCancel={() => {
                            this.setState({ ...this.initialState });
                        }}
                        closable={true}
                        confirmLoading={this.state.showLoader}
                    >
                        <h4 style={{marginTop:10}}>Password Kasir {this.state.voidPassword?.split('').map(val => '*').join('')}</h4>
                        <Input
                            style={{color: 'white'}}
                            value={this.state.voidPassword}
                            onChange={e => {
                                this.setState({ voidPassword: e.target.value });
                            }}/*
                            onKeyDown={e => {
                                this.setState({ voidPassword: this.state.voidPassword + e.key }, () => {
                                    console.log(this.state.voidPassword);
                                });
                            }}*/
                            type="text"
                            autoComplete="off"
                        />

                        <h4 style={{marginTop:10}}>Alasan Pembatalan</h4>
                        <Input
                            value={this.state.voidReason}
                            onChange={e => {
                                this.setState({ voidReason: e.target.value });
                            }}
                            placeholder="Alasan Pembatalan"
                        />
                    </Modal>

                    {this.state.stockOutModalOpened && !empty(this.state.stockOutLocalTrxId) && (
                        <TrxStockOutModal localTrxId={this.state.stockOutLocalTrxId}
                                          opened={this.state.stockOutModalOpened}
                                          onClose={() => {
                                              this.setState({
                                                  stockOutModalOpened: false,
                                                  stockOutLocalTrxId: null
                                              });
                                          }}
                        />
                    )}
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        posTrx: state.posState.posTrx,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs,
        posState: state.posState
    };
};

const mapDispatchToProps = {
    switchCurrentTrx: posOperations.switchCurrentTrx,
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosCompletedTrxList);
