import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Avatar, Button, Empty, message, Modal } from "antd";
import { posOperations } from "../../state/ducks/pos";
import { empty } from "../../shared/helpers/generalHelper";
import styles from "./PosPendingTrxList.module.scss";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";
import { number_format } from "../../shared/helpers/stringHelper";
import PosPriceCalculator from "../../shared/classes/PosPriceCalculator";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import TrxStockOutModal from "../../shared/components/TrxStockOutModal";
import DeleteTrxPasswordModal from "../../shared/components/DeleteTrxPasswordModal";

class PosPendingTrxList extends Component {
    static propTypes = {
        onItemClickedCallback: PropTypes.func.isRequired,
        filterKeyword: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            stockOutModalOpened: false,
            stockOutLocalTrxId: null,
            deleteTrxModalOpened: false,
            deleteTrxLocalTrxId: null,
            deleteTrx: null
        };

        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
    }

    onListItemClicked = (local_trx_id, trx_name) => {
        this.props.switchCurrentTrx(local_trx_id);
        this.props.onItemClickedCallback(trx_name);
    };

    saveTrx = (local_trx_id) => {
        apiCall(
            endpoint.POST_ARCHIVE_TRX,
            "post",
            {
                trx: this.props.posTrx.find(row => row.local_trx_id === local_trx_id)
            },
            response => {
                message.success("Transaksi telah berhasil disimpan.");
                this.props.deleteTrx(local_trx_id);
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    deleteTrx = trx => {
        const that = this;
        Modal.confirm({
            title: "Apakah anda ingin menghapus transaksi ini?",
            content: "",
            onOk() {
                if (that.props.posConfig.pos_cashier_delete_by_pin === 1) {
                    that.setState({
                        deleteTrxModalOpened: true,
                        deleteTrxLocalTrxId: trx.local_trx_id,
                        deleteTrx: trx
                    });
                } else {
                    that.deleteTrxAction(trx);
                }
            },
            onCancel() {
            }
        });
    };

    deleteTrxAction = trx => {
        this.props.deleteTrx(trx.local_trx_id, trx);
        message.success("Transaksi telah berhasil dihapus.");
    };

    renderPosPendingTrx = posTrx => {
        return posTrx
            .reverse()
            .map((trx, index) => {
                const customerName = String(
                    empty(trx.customer)
                        ? "GUEST"
                        : empty(trx.customer.user_name)
                        ? "GUEST"
                        : trx.customer.user_name
                );
                const customerIdentifierString = String(
                    empty(trx.customer)
                        ? "No Contact Information"
                        : empty(trx.customer.user_identifier_string)
                        ? "No Contact Information"
                        : trx.customer.user_identifier_string
                );
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    trx,
                    this.props.posConfig,
                    this.currencyCode
                ).grandTotal;
                let totalQty = 0;
                if (!empty(trx.details)) {
                    trx.details.map(detail => {
                        totalQty += detail.qty;
                        return detail;
                    });
                }
                const area = !empty(trx.service_area) ? trx.service_area.area_name : null;

                return (
                    <div
                        className={styles.trxItem}
                        key={index}
                    >
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <Avatar
                                        icon="user"
                                        size="large"
                                        className={styles.trxItemAvatar}
                                        onClick={() => {
                                            this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                        }}
                                    />
                                </td>
                                <td>
                                    <div
                                        className={styles.trxInfo}
                                        onClick={() => {
                                            this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                        }}
                                    >
                                        [{trx.local_trx_id.toUpperCase().substr(-6)}] - {trx.trx_name}
                                    </div>

                                    <div
                                        className={styles.trxCustomerName}
                                        onClick={() => {
                                            this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                        }}
                                    >
                                        {customerName}
                                    </div>
                                    <div
                                        className={styles.trxInfo}
                                        onClick={() => {
                                            this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                        }}
                                    >
                                        {customerIdentifierString}
                                    </div>
                                    <div
                                        className={styles.trxInfo}
                                        onClick={() => {
                                            this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                        }}
                                    >
                                        {number_format(totalQty, this.props.posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator)} Produk, Total {this.currencyCode}{" "}
                                        {number_format(grandTotal, 0)}
                                    </div>
                                    <div
                                        className={styles.trxInfo}
                                        onClick={() => {
                                            this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                        }}
                                    >
                                        Dibuat{" "}
                                        {moment(
                                            trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT
                                        ).format(
                                            MOMENT_READABLE_DATETIME_FORMAT
                                        )}{" "}
                                        (
                                        {moment(
                                            trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT
                                        ).fromNow()}
                                        )
                                    </div>

                                    {this.props.posConfig.is_document_date_editable === 1 && (
                                        <div
                                            className={styles.trxInfo}
                                            onClick={() => {
                                                this.onListItemClicked(
                                                    trx.local_trx_id, trx.trx_name
                                                );
                                            }}
                                        >
                                            Doc. Date{" "}
                                            {moment(
                                                trx.document_date, MOMENT_SQL_DATETIME_FORMAT
                                            ).format(
                                                MOMENT_READABLE_DATETIME_FORMAT
                                            )}{" "}
                                            (
                                            {moment(
                                                trx.document_date, MOMENT_SQL_DATETIME_FORMAT
                                            ).fromNow()}
                                            )
                                        </div>
                                    )}

                                    {!empty(trx.trx_label) &&
                                    empty(trx.customer) && (
                                        <div
                                            className={styles.trxLabel}
                                            onClick={() => {
                                                this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                            }}
                                        >
                                            {trx.trx_label}
                                        </div>
                                    )}

                                    {!empty(area) && (
                                        <div className={styles.trxInfo}
                                             onClick={() => {
                                                 this.onListItemClicked(trx.local_trx_id, trx.trx_name);
                                             }}
                                        >
                                            <strong>{area}</strong>
                                        </div>
                                    )}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={styles.trxAction}>
                            {this.props.posConfig.auth_role_accesses.POS_SHOW_STOCK_OUT === 1 && (
                                <>
                                    <Button type="primary"
                                            shape="circle"
                                            icon="gold"
                                            onClick={() => {
                                                this.setState({
                                                    stockOutModalOpened: true,
                                                    stockOutLocalTrxId: trx.local_trx_id
                                                });
                                            }}
                                    />
                                </>
                            )}

                            {this.props.posConfig?.auth_role_accesses?.SAVE_TRANSACTION === 1 && (
                                <Button type="primary"
                                        shape="circle"
                                        icon="file-protect"
                                        style={{ marginLeft: 10 }}
                                        onClick={() => {
                                            const that = this;
                                            Modal.confirm({
                                                title: "Apakah anda ingin menyimpan transaksi ini?",
                                                content: "",
                                                onOk() {
                                                    that.saveTrx(trx.local_trx_id);
                                                },
                                                onCancel() {
                                                }
                                            });
                                        }}
                                />
                            )}

                            <Button type="danger"
                                    shape="circle"
                                    icon="delete"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.deleteTrx(trx);
                                    }}
                            />
                        </div>
                    </div>
                );
            });
    };

    render() {
        const { posTrx } = this.props;
        const filteredPosTrx = posTrx.filter(trx => {
            const customerName = String(
                empty(trx.customer)
                    ? "GUEST"
                    : empty(trx.customer.user_name)
                    ? "GUEST"
                    : trx.customer.user_name
            );
            const customerIdentifierString = String(
                empty(trx.customer)
                    ? "No Contact Information"
                    : empty(trx.customer.user_identifier_string)
                    ? "No Contact Information"
                    : trx.customer.user_identifier_string
            );
            const grandTotal = PosPriceCalculator.calculateGrandTotal(
                trx,
                this.props.posConfig,
                this.currencyCode
            ).grandTotal;
            let totalQty = 0;
            if (!empty(trx.details)) {
                trx.details.map(detail => {
                    totalQty += detail.qty;
                    return detail;
                });
            }
            const totalQtyString = number_format(totalQty, this.props.posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);
            const totalString = `${totalQtyString} Produk, Total ${this.currencyCode} ${number_format(
                grandTotal,
                0
            )}`;
            const timeString = `${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).format(
                MOMENT_READABLE_DATETIME_FORMAT
            )} (${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).fromNow()})`;
            const area = !empty(trx.service_area) ? trx.service_area.area_name : "";

            return (
                trx.trx_status_id === 2 &&
                (
                    String(customerName).toLowerCase().includes(this.props.filterKeyword.toLowerCase()) ||
                    String(customerIdentifierString).toLowerCase().includes(this.props.filterKeyword.toLowerCase()) ||
                    String(trx.local_trx_id).toLowerCase().includes(this.props.filterKeyword.toLowerCase()) ||
                    String(totalString).toLowerCase().includes(this.props.filterKeyword.toLowerCase()) ||
                    String(timeString).toLowerCase().includes(this.props.filterKeyword.toLowerCase()) ||
                    String(trx.trx_label).toLowerCase().includes(this.props.filterKeyword.toLowerCase()) ||
                    String(area).toLowerCase().includes(this.props.filterKeyword.toLowerCase())
                )
            );
        });

        return (
            <div className={styles.trxList}>
                {!empty(filteredPosTrx) && this.renderPosPendingTrx(filteredPosTrx)}
                {empty(filteredPosTrx) && (
                    <div className={styles.emptyPendingTrx}>
                        {empty(this.props.filterKeyword) && (
                            <Empty style={{ marginTop: 50 }} description="Belum ada transaksi"/>
                        )}
                        {!empty(this.props.filterKeyword) && (
                            <Empty style={{ marginTop: 50 }} description="Transaksi tidak ditemukan"/>
                        )}
                    </div>
                )}

                {this.state.stockOutModalOpened && !empty(this.state.stockOutLocalTrxId) && (
                    <TrxStockOutModal localTrxId={this.state.stockOutLocalTrxId}
                                      opened={this.state.stockOutModalOpened}
                                      onClose={() => {
                                          this.setState({
                                              stockOutModalOpened: false,
                                              stockOutLocalTrxId: null
                                          });
                                      }}
                    />
                )}

                {this.state.deleteTrxModalOpened && !empty(this.state.deleteTrxLocalTrxId) && (
                    <DeleteTrxPasswordModal
                        localTrxId={this.state.deleteTrxLocalTrxId}
                        trx={this.state.deleteTrx}
                        opened={this.state.deleteTrxModalOpened}
                        onClose={() => {
                            this.setState({
                                deleteTrxModalOpened: false,
                                deleteTrxLocalTrxId: null
                            });
                        }}
                        deleteAction={trx => {
                            this.deleteTrxAction(trx);
                        }}
                    />
                )}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        posTrx: state.posState.posTrx,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    switchCurrentTrx: posOperations.switchCurrentTrx,
    deleteTrx: posOperations.deleteTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosPendingTrxList);
