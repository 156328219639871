export const GET_CASHIER_CONFIG = 'cashier/config';
export const GET_CASHIER_HELP = 'cashier/help';

export const GET_POS_CONFIG = 'cashier/pos/config';
export const GET_POS_ITEMS = 'cashier/pos/item';
export const GET_POS_ITEM_CATEGORIES = 'cashier/pos/item-category';
export const GET_POS_DISCOUNTS = 'cashier/pos/discount';
export const GET_POS_COMMISSION_WORKERS = 'cashier/pos/commission-worker';
export const GET_CUSTOMER_INFO = 'cashier/pos/user';
export const SEARCH_CUSTOMER = 'cashier/pos/user-search';
export const POST_USER_REF_CODE_GENERATE = 'cashier/user/reference-code-generate';
export const POST_SYNC_SATUSEHAT_PATIENT_ID = 'cashier/sync-patient-id';
export const GET_SATUSEHAT_ICD_10 = 'cashier/satu-sehat-icd-10-search';

export const GET_REDEEM_REWARD_PREVIEW = 'cashier/reward/preview';
export const POST_DO_REDEEM_REWARD = 'cashier/reward/redeem';
export const POST_SAVE_REDEEM_TRX = 'cashier/reward/save-redeem';
export const GET_REDEEMABLES = 'cashier/reward-redemption-item';
export const POST_SEND_SMS_REDEEM = 'cashier/sms-redeem-reward';
export const POST_RESEND_SMS_REDEEM = 'cashier/sms-redeem-reward-resend';
export const POST_EXECUTE_SMS_REDEEM = 'cashier/validate-sms-redeem-reward';
export const POST_SAVE_SMS_REDEEM = 'cashier/save-sms-redeem-reward';
export const GET_AGENT_REWARD_REDEEM_TRXS = 'cashier/pos/reward-agent-redeem/transaction/list';

export const POST_COUPON_PREVIEW = 'cashier/coupon/redeem/preview';
export const POST_DO_COUPON_REDEEM_REWARD = 'cashier/reward/redeem';
export const POST_USER_COUPONS = 'cashier/coupon/direct-redeem/preview';
export const GET_AGENT_COUPON_REDEEM_TRXS = 'cashier/pos/coupon-agent-redeem/transaction/list';

export const GET_TRX_HISTORY = 'cashier/pos-trx-history';
export const GET_TRX_HISTORY_DETAIL = local_trx_id => { return `cashier/pos-trx-history/${local_trx_id}`; };
export const GET_SETTLEMENT_DATA = 'cashier/pos/settlement';

export const GET_REPORT_WORKER_COMMISSIONS = 'cashier/pos-sales-trx-commission';
export const GET_REPORT_WORKER_COMMISSIONS_BY_TRX = 'cashier/pos-sales-trx-commission-by-trx';
export const GET_REPORT_WORKER_COMMISSIONS_SUMMARY = 'cashier/pos-sales-trx-commission-summary';
export const GET_REPORT_POS_ALL_TRX = 'cashier/pos-trx';
export const GET_REPORT_POS_ALL_TRX_DETAIL = 'cashier/pos-trx-detail';
export const GET_REPORT_POS_GROUP_BY_ITEM = 'cashier/pos-sales-item-summary';
export const GET_REPORT_PRODUCT_CONSUMPTION = 'cashier/product-consumption';
export const GET_REPORT_COUPON_EXPIRED = 'cashier/coupon-expired';
export const GET_REPORT_CUSTOM_OMZET_RECAPITULATION = 'cashier/omzet-recapitulation';
export const GET_REPORT_CUSTOMER_BIRTHDAY = 'cashier/customer-birthday';
export const GET_REPORT_TREATMENT_REMINDER = 'cashier/treatment-reminder';

export const GET_ACCOUNT_RECEIVABLE_LIST = 'cashier/account-receivable';

export const POST_POS_SHIFT = 'cashier/pos-shift';

export const POST_POS_TRX = 'cashier/pos-trx';
export const GET_ARCHIVED_TRXS = 'cashier/pos-sales-trx-archive';
export const GET_ARCHIVED_TRX = 'cashier/pos-sales-trx-archive/';
export const POST_ARCHIVE_TRX = 'cashier/pos-sales-trx-archive';
export const POST_LOYALTY_TRX = 'cashier/loyalty-trx';
export const POST_POS_TRX_VOID = 'cashier/pos-trx/void';
export const POST_POS_RECEIPT_SEND = 'cashier/pos/receipt-send';
export const POST_POS_RECEIPT_RETRIEVE = 'cashier/pos/receipt-view';
export const GET_POS_TRX_STOCKOUT = 'cashier/inventory/inventory-out/';
export const POST_PIN_VALIDATION = 'cashier/pos/pin-validation';
export const POST_CHECK_STOCKS = 'cashier/inventory/current-stocks';
export const POST_USER_INFO = 'cashier/user/info';
export const GET_MEMBER_INFO = 'cashier/membership/info';
export const GET_MEMBER_SEARCH = 'cashier/pos/member-search';
export const POST_EDIT_MEMBER_INFO = 'cashier/user/edit';
export const POST_EDIT_JOURNAL_NOTES = 'cashier/user/edit-journal-notes'
export const POST_MEMBER_ACTIVATION = 'cashier/membership/activation';
export const GET_MEMBER_TRX_HISTORY = 'cashier/member-trx-history';
export const GET_MEMBER_ITEM_HISTORY = 'cashier/member-item-trx-history';
export const POST_NEW_NFC_SECURITY_CODE = 'cashier/user/new-nfc-security-code';
export const POST_SET_NFC_SECURITY_CODE = 'cashier/user/nfc-security-code';
export const POST_TEST_NFC_SECURITY_CODE = 'cashier/user/check-nfc-security-code';

export const GET_CUSTOMER_ACTIVITIES = 'cashier/customer-activity';
export const POST_NEW_CUSTOMER_ACTIVITIES = 'cashier/customer-activity';
export const PUT_EDIT_CUSTOMER_ACTIVITIES = 'cashier/customer-activity';
export const DELETE_DELETE_CUSTOMER_ACTIVITIES = 'cashier/customer-activity';
export const GET_POS_AREAS = 'cashier/pos-area';

export const GET_INVENTORY_STOCK_LIST = 'cashier/inventory/outlet-stock';
export const GET_INVENTORY_SEARCH_ITEM = 'cashier/inventory/search-item';
export const GET_INVENTORY_SUPPLIERS = 'cashier/inv-vendor';
export const GET_INVENTORY_CHECK_ITEM_STOCK = 'cashier/inventory/current-stock';
export const POST_INVENTORY_IN_FROM_SUPPLIER = 'cashier/inventory/inventory-in';
export const POST_INVENTORY_OUT = 'cashier/inventory/inventory-out';
export const POST_INVENTORY_ADJUSTMENT = 'cashier/inventory/inventory-adjustment';
export const POST_INVENTORY_TRANSFER_OUT = 'cashier/inventory/transfer-out';
export const POST_INVENTORY_TRANSFER_IN = 'cashier/inventory/transfer-in';

export const REST_APPOINTMENT = 'cashier/appointment';

export const POST_AUTH_SIGNIN = 'cashier/pos/auth/sign-in';
export const POST_AUTH_SIGNIN_CONFIRM = 'cashier/pos/auth/sign-in-confirmation';
export const GET_AUTH_SIGNOUT = 'cashier/pos/auth/sign-out';
export const POST_AUTH_FORGOT_PASSWORD = 'cashier/auth/forgot-password';


export const ERM_ORGANIZATION = 'cashier/satu-sehat-organization';
export const ERM_ORGANIZATION_INIT = 'cashier/satu-sehat-organization-init';