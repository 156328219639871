import React, { Component } from "react";
import QrReader from "react-qr-reader";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Affix, Button, Card, Col, DatePicker, Empty, Form, Icon, Input, List, message, Modal, Radio, Row, Select, Spin, Table, Tabs } from "antd";
import { apiCall, promiseTimeout } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./MemberInfo.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT, PAYMENT_TYPE_CREDIT, PAYMENT_TYPE_DEPOSIT, TRX_TYPE_ACCOUNT_RECEIVABLE, TRX_TYPE_COUPON_SALES, TRX_TYPE_TOP_UP_DEPOSIT } from "../shared/utils/constants";
import connect from "react-redux/es/connect/connect";
import { loyaltyOperations } from "../state/ducks/loyalty";
import { nl2brReact, number_format, validEmail, validPhone } from "../shared/helpers/stringHelper";
import moment from "moment";
import { posOperations } from "../state/ducks/pos";
import { PosPriceList } from "../modules";
import { today } from "../shared/helpers/dateHelper";
import ErrorHandler from "../shared/classes/ErrorHandler";
import CaptureNfc from "../shared/components/CaptureNfc";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { POST_SYNC_SATUSEHAT_PATIENT_ID } from "../shared/utils/endpoints";

const FormItem = Form.Item;

class MemberInfo extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            user_identifier_type: this.props.match?.params?.user_identifier_type || "phone",
            user_identifier_string: this.props.match?.params?.user_identifier_string || "",
            user: null,
            user_photo_data: null,
            takePhotoModalOpened: false,
            searchUserLoading: false,
            isDatePickerOpened: false,
            editUserLoading: false,
            userSearchResult: [],
            userTrxLoading: false,
            userTrxDate: "",
            userTrxs: [],
            userTrxSelected: null,
            userTrxTypeFilter: "all",
            userItemTrxLoading: false,
            userItemTrxDate: "",
            userItemTrxs: [],
            userItemTrxSelected: null,
            userItemTrxTypeFilter: "all",
            coupon_filter: "",
            nfcLoading: false,
            nfc_code: null,
            newNfcModalOpened: false,
            newNfcScanModalOpened: false,
            nfcRefId: null,
            nfcModalOpened: false,
            nfcScanModalOpened: false,
            test_nfc_code: null,
            testNfcModalOpened: false,
            cashierPassword: "",
            journalModalOpened: false,
            journalLoading: false,
            journals: null,
            refCodeGenerateDisabled: true,
            sendReceiptLoading: false,
            receiptPhone: "",
            receiptMail: ""
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.configs.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
    }

    componentDidMount() {
        if (!empty(this.props.match?.params?.user_identifier_type) && !empty(this.props.match?.params?.user_identifier_string)) {
            this.onSearchCustomer();
        }

        window.scrollTo(0, 0);
    }

    onChangeUserIdentifierType = e => {
        this.setState({
            user_identifier_type: e.target.value,
            user_identifier_string: ""
        });
    };

    onChangeUserIdentifierString = e => {
        this.setState({
            user_identifier_string: e.target.value
        });
    };

    onSearchCustomer = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Please fill out the user contact.");
            return;
        }

        if (this.state.user_identifier_type === "phone") {
            if (!validPhone(this.state.user_identifier_string)) {
                message.error("Please input a valid phone number.");
                return;
            }
        }

        if (this.state.user_identifier_type === "email") {
            if (!validEmail(this.state.user_identifier_string)) {
                message.error("Please input a valid email.");
                return;
            }
        }

        this.setSearchUserLoading(true);

        apiCall(
            endpoint.GET_MEMBER_INFO,
            "post",
            {
                user_identifier_type: this.state.user_identifier_type,
                user_identifier_string: this.state
                    .user_identifier_string
            },
            res => {
                this.setState(
                    {
                        step: 2,
                        user: res.data,
                        refCodeGenerateDisabled: !empty(res.data.user_ref)
                    },
                    () => {
                        this.setSearchUserLoading(false);
                        if (this.props.configs.subscription_rules.customer_trx_history) {
                            this.loadCustomerTrxs(false);
                            this.loadCustomerItemTrxs(false);
                        }
                    }
                );
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setSearchUserLoading(false);
            }
        );
    };

    setSearchUserLoading = state => {
        this.setState({
            searchUserLoading: state
        });
    };

    onSearchCustomerByKeyword = (val, e) => {
        e.stopPropagation();
        e.preventDefault();

        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Customer harus dipilih.");
            return;
        }

        if (this.state.user_identifier_type === "search") {
            if (empty(this.state.user_identifier_string)) {
                message.error("Kata kunci pencarian harus diisi.");
                return;
            }
        }

        this.setSearchUserLoading(true);
        if (navigator.onLine) {
            promiseTimeout(
                10000,
                new Promise((resolve, reject) => {
                    return apiCall(
                        endpoint.GET_MEMBER_SEARCH + `?keyword=${this.state.user_identifier_string}`,
                        "get",
                        null,
                        response => {
                            resolve(response);
                        },
                        response => {
                            reject(response);
                        }
                    );
                })
            )
                .then(result => {
                    // API call success
                    this.setState(
                        {
                            userSearchResult: result.data.members
                        },
                        () => {
                            this.setSearchUserLoading(false);
                        }
                    );
                })
                .catch(error => {
                    if (error === "timeout") {
                        // Network is not available
                        message.error(
                            "Tidak ada koneksi internet. Mohon coba kembali."
                        );
                    } else {
                        // API throwing error object
                        message.error(error.data.error.message);
                    }

                    this.setSearchUserLoading(false);
                });
        } else {
            // this.setEmptyCustomer();
            this.setSearchUserLoading(false);
            message.error(
                "Tidak ada koneksi internet. Mohon coba kembali."
            );
        }
    };

    selectSearchedCustomer = user => {
        let user_identifier_type = "";
        let user_identifier_string = "";

        if (!empty(user.user_email)) {
            user_identifier_type = "email";
            user_identifier_string = user.user_email;
        }

        if (!empty(user.user_phone)) {
            user_identifier_type = "phone";
            user_identifier_string = user.user_phone;
        }

        this.setState({
            step: 2,
            user: { ...user },
            user_identifier_type,
            user_identifier_string,
            refCodeGenerateDisabled: !empty(user.user_ref)
        }, () => {
            if (this.props.configs.subscription_rules.customer_trx_history) {
                this.loadCustomerTrxs(false);
                this.loadCustomerItemTrxs(false);
            }
        });
    };

    handleQrScan = data => {
        if (data) {
            this.setState({ user_identifier_string: data }, () => {
                this.onSearchCustomer();
            });
        }
    };

    handleQrError = err => {
        console.error(err);
    };

    // CUSTOMER DATA
    onChangeUserName = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_name: e.target.value
            }
        });
    };

    onChangeUserNik = e => {
        this.setState({
            user: {
                ...this.state.user,
                nik: e.target.value
            }
        });
    };

    onChangeUserDateOfBirth = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_birth_date: e.target.value
            }
        });
    };

    onChangeUserGender = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_gender: e.target.value
            }
        });
    };

    onChangeUserNotes = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_notes: e.target.value
            }
        });
    };

    onChangeReferenceCode = e => {
        this.setState({
            user: {
                ...this.state.user,
                reference_code: e.target.value
            }
        });
    };

    onChangeUserNotificationEmail = e => {
        this.setState({
            user: {
                ...this.state.user,
                user_email_notification: e.target.value
            }
        });
    };

    onDatePickerSelected = (momentDate, DateString) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    user_birth_date: DateString
                }
            }
        );
    };

    onTakePhoto = (dataUri) => {
        // Do stuff with the photo...
        this.setState({
            user_photo_data: dataUri,
            takePhotoModalOpened: false
        });
    };

    onResetPhoto = () => {
        this.setState({
            user_photo_data: null,
            user: {
                ...this.state.user,
                user_photo: null
            }
        });
    };

    onCameraError = (error) => {
        message.error(`Camera error: ${error}`);
    };

    onChangeUploadPhoto = e => {
        const files = e.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.setState({
                user_photo_data: reader.result
            });
        };
        reader.onerror = err => {
            ErrorHandler.handleGeneralError(err);
        };
    };

    onEditMemberDataBtnClicked = () => {
        this.setState({
            step: 3
        });
    };

    onEditNikBtnClicked = () => {
        this.setState({
            step: 5
        });
    };

    onEditMemberData = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { user, user_photo_data } = this.state;

        this.setState({ editUserLoading: true });
        apiCall(
            endpoint.POST_EDIT_MEMBER_INFO,
            "post",
            {
                user_id: user.user_id,
                user_name: user.user_name,
                nik: user.nik,
                user_gender: user.user_gender,
                user_birth_date: user.user_birth_date,
                user_notes: user.user_notes,
                reference_code: user.reference_code,
                user_email_notification: user.user_email_notification,
                user_photo: !empty(user_photo_data) ? user_photo_data : user.user_photo,
                is_edit_user: 1,
                journal_notes: user.journal_notes
            },
            response => {
                Modal.success({
                    title: "Edit Member Data Success!",
                    content: "Data member telah berhasil diubah.",
                    onOk: () => {
                        // this.setState({ ...this.initialState });
                        this.setState({
                            step: 2,
                            editUserLoading: false
                        });
                    }
                });
            },
            error => {
                if (error === "timeout") {
                    // Network is not available
                    message.error(
                        "Telah terjadi kesalahan koneksi, silakan coba lagi."
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ editUserLoading: false });
            }
        );
    };

    newTopUpTrx = (user) => {
        this.props.setNewLocalTrx({
            customer: {
                ...user,
                user_gender: user.user_gender === 1 ? "Male" : "Female",
                user_identifier_type: this.state.user_identifier_type,
                user_identifier_string: this.state.user_identifier_string
            },
            trx_type: TRX_TYPE_TOP_UP_DEPOSIT
        });

        this.props.history.push("/pos?next=true");
    };

    loadCustomerTrxs = (useDate = true) => {
        if (empty(this.state.user.user_id)) {
            message.error("Mohon pilih pelanggan terlebih dahulu.");
            return;
        }

        if (empty(this.state.userTrxDate) && useDate) {
            message.error("Mohon pilih tanggal transaksi terlebih dahulu.");
            return;
        }

        this.setState({ userTrxLoading: true });
        apiCall(
            endpoint.GET_MEMBER_TRX_HISTORY + `?user_code=${this.state.user.user_id}&start_date=${this.state.userTrxDate}&end_date=${today()}`,
            "get",
            null,
            res => {
                this.setState({
                    userTrxs: res.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({
                    userTrxLoading: false,
                    userTrxSelected: null
                });
            }
        );
    };

    loadCustomerItemTrxs = (useDate = true) => {
        if (empty(this.state.user.user_id)) {
            message.error("Mohon pilih pelanggan terlebih dahulu.");
            return;
        }

        if (empty(this.state.userItemTrxDate) && useDate) {
            message.error("Mohon pilih tanggal transaksi terlebih dahulu.");
            return;
        }

        this.setState({ userItemTrxLoading: true });
        apiCall(
            endpoint.GET_MEMBER_ITEM_HISTORY + `?user_code=${this.state.user.user_id}&start_date=${this.state.userItemTrxDate}&end_date=${today()}`,
            "get",
            null,
            res => {
                this.setState({
                    userItemTrxs: res.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({
                    userItemTrxLoading: false,
                    userItemTrxSelected: null
                });
            }
        );
    };

    onSubmitNewNFC = () => {
        const { nfc_code, cashierPassword, nfcRefId, user } = this.state;

        if (empty(nfc_code) || empty(cashierPassword)) {
            message.error("Invalid NFC Card!");
            return;
        }

        if (this.props.configs.nfc_with_ref_id === 1 && empty(nfcRefId)) {
            message.error("Ref ID harus diisi");
            return;
        }


        this.setState({ nfcLoading: true });

        let formData = {
            user_id: user.user_id,
            nfc_security_code: nfc_code,
            password: cashierPassword
        };

        if (this.props.configs.nfc_with_ref_id === 1) {
            formData.reference_code = nfcRefId;
        }

        apiCall(
            endpoint.POST_NEW_NFC_SECURITY_CODE,
            "post",
            formData,
            res => {
                message.success("User NFC has been successfully set.");
                this.setState({
                    nfc_code: null,
                    nfcRefId: null,
                    newNfcModalOpened: false,
                    newNfcScanModalOpened: false,
                    cashierPassword: ""
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({ nfcLoading: false });
            }
        );
    };

    onSubmitNFC = () => {
        const { nfc_code, cashierPassword, user, nfcRefId } = this.state;

        if (empty(nfc_code) || empty(cashierPassword)) {
            message.error("Invalid NFC Card!");
            return;
        }

        this.setState({ nfcLoading: true });

        let formData = {
            user_id: user.user_id,
            nfc_security_code: nfc_code,
            password: cashierPassword
        };

        if (this.props.configs.nfc_with_ref_id === 1) {
            formData.reference_code = nfcRefId;
        }

        apiCall(
            endpoint.POST_SET_NFC_SECURITY_CODE,
            "post",
            formData,
            res => {
                message.success("User NFC has been successfully set.");
                this.setState({
                    nfc_code: null,
                    nfcRefId: null,
                    nfcModalOpened: false,
                    nfcScanModalOpened: false,
                    cashierPassword: ""
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({ nfcLoading: false });
            }
        );
    };

    onSubmitTestNFC = () => {
        const { test_nfc_code, user } = this.state;

        if (empty(test_nfc_code)) {
            message.error("Invalid NFC Card!");
            return;
        }

        apiCall(
            endpoint.POST_TEST_NFC_SECURITY_CODE,
            "post",
            {
                user_id: user.user_id,
                nfc_security_code: test_nfc_code
            },
            res => {
                message.success("NFC card is valid.");
                this.setState({
                    test_nfc_code: null
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({ testNfcModalOpened: false });
            }
        );
    };

    customFilterProps = (dataIndex, dataLabel, customRender = null) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataLabel}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text, row) => {
            let modifiedText = String(text) || "";
            if (!empty(customRender)) {
                modifiedText = customRender(text, row);
            }
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={modifiedText}
                />
            );
        }
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    openCustomerJournal = (trx_datetime) => {
        this.setState({ journalModalOpened: true, journalLoading: true });

        const trx_date = moment(trx_datetime, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_SQL_DATE_FORMAT);

        apiCall(
            endpoint.GET_CUSTOMER_ACTIVITIES + `?user_identifier_type=${this.state.user_identifier_type}&user_identifier_string=${this.state.user_identifier_string}&start_date=${trx_date}&end_date=${trx_date}`,
            "get",
            null,
            result => {
                this.setState({
                    journals: result.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setState({ journalLoading: false });
            }
        );

    };

    closeJournalModal = () => {
        this.setState({
            journalModalOpened: false
        });
    };

    generateRefCode = (user_id) => {
        this.setState({ refCodeGenerateDisabled: true });

        apiCall(
            endpoint.POST_USER_REF_CODE_GENERATE,
            "post",
            { user_id },
            result => {
                this.setState({
                    user: {
                        ...this.state.user,
                        user_ref: result.data.reference_code,
                        reference_code: result.data.reference_code,
                        refCodeGenerateDisabled: true
                    }
                });
                message.success("Kode user berhasil di generate");
            },
            err => {
                ErrorHandler.handleGeneralError(err);
                this.setState({ refCodeGenerateDisabled: false });
            },
            () => {
            }
        );
    };

    syncSatuSehatPatientId = () => {
        const form_data = {
            user_identifier_string: this.state.user_identifier_string,
            user_identifier_type: this.state.user_identifier_type
        };

        apiCall(
            endpoint.POST_SYNC_SATUSEHAT_PATIENT_ID,
            "post",
            form_data,
            result => {
                message.success("Sync ID Satu Sehat berhasil");
                this.onSearchCustomer();
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
            }
        );
    };

    setSendReceiptLoading = state => {
        this.setState({
            sendReceiptLoading: state
        });
    };

    onSendSmsReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "phone",
                    phone
                },
                result => {
                    message.success("SMS Berhasil dikirimkan");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    onSendWhatsAppReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            let parsedPhone = phone;
            if (phone[0] !== "0" && phone.substring(0, 1) !== "62") {
                parsedPhone = "62" + phone;
            }
            if (phone[0] === "0") {
                parsedPhone = "62" + phone.substring(1);
            }

            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "text",
                    phone
                },
                result => {
                    const waText = `phone=${parsedPhone}&text=${result.data.message}`;
                    const waURL = "https://api.whatsapp.com/send?" + waText;
                    window.open(waURL, "_blank");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    onSendWhatsAppDesktopReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            let parsedPhone = phone;
            if (phone[0] !== "0" && phone.substring(0, 1) !== "62") {
                parsedPhone = "62" + phone;
            }
            if (phone[0] === "0") {
                parsedPhone = "62" + phone.substring(1);
            }

            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "text",
                    phone
                },
                result => {
                    const waText = `phone=${parsedPhone}&text=${result.data.message}`;
                    const waURL = "whatsapp://send?" + waText;
                    window.open(waURL, "_blank");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    onSendEmailReceipt = (local_trx_id, email) => {
        if (empty(email)) {
            message.error("Email is required");
        } else {
            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "email",
                    email
                },
                result => {
                    message.success("Email Berhasil dikirimkan");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const resetCustomerBtnLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const {
            step,
            user_identifier_type,
            user_identifier_string,
            user,
            user_photo_data,
            coupon_filter,
            searchUserLoading,
            editUserLoading,
            userTrxs,
            userTrxTypeFilter,
            userItemTrxs,
            userItemTrxTypeFilter,
            journalModalOpened,
            journalLoading,
            journals,
            refCodeGenerateDisabled,
            sendReceiptLoading
        } = this.state;
        const { configs, appConfig } = this.props;
        const dateFormat = "YYYY-MM-DD";
        let birthDateDefaultValue = {};
        if (user && !empty(user.user_birth_date)) {
            birthDateDefaultValue.defaultValue = moment(user.user_birth_date);
        }

        let filteredUserTrxs = userTrxs;
        switch (userTrxTypeFilter) {
            case "redeem":
                filteredUserTrxs = userTrxs.filter(trx => !empty(trx.redeem));
                break;
            case "coupon-sell":
                filteredUserTrxs = userTrxs.filter(trx => trx.trx_type === TRX_TYPE_COUPON_SALES);
                break;
            case "coupon-use":
                filteredUserTrxs = userTrxs.filter(trx => !empty(trx.coupon));
                break;
            case "deposit-topup":
                filteredUserTrxs = userTrxs.filter(trx => trx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT);
                break;
            case "deposit-use":
                filteredUserTrxs = userTrxs.filter(trx => trx.payments.some(payment => payment.payment_type === PAYMENT_TYPE_DEPOSIT));
                break;
            case "piutang":
                filteredUserTrxs = userTrxs.filter(trx => trx.payments.some(payment => payment.payment_type === PAYMENT_TYPE_CREDIT));
                break;
            case "piutang-pembayaran":
                filteredUserTrxs = userTrxs.filter(trx => trx.trx_type === TRX_TYPE_ACCOUNT_RECEIVABLE);
                break;
            default:
                break;
        }

        let filteredUserItemTrxs = userItemTrxs;
        switch (userItemTrxTypeFilter) {
            case "redeem":
                filteredUserItemTrxs = userItemTrxs.filter(trx => !empty(trx.redeem));
                break;
            case "coupon-sell":
                filteredUserItemTrxs = userItemTrxs.filter(trx => trx.trx_type === TRX_TYPE_COUPON_SALES);
                break;
            case "coupon-use":
                filteredUserItemTrxs = userItemTrxs.filter(trx => !empty(trx.coupon));
                break;
            case "deposit-topup":
                filteredUserItemTrxs = userItemTrxs.filter(trx => trx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT);
                break;
            case "deposit-use":
                filteredUserItemTrxs = userItemTrxs.filter(trx => trx.payments.some(payment => payment.payment_type === PAYMENT_TYPE_DEPOSIT));
                break;
            case "piutang":
                filteredUserItemTrxs = userItemTrxs.filter(trx => trx.payments.some(payment => payment.payment_type === PAYMENT_TYPE_CREDIT));
                break;
            case "piutang-pembayaran":
                filteredUserItemTrxs = userItemTrxs.filter(trx => trx.trx_type === TRX_TYPE_ACCOUNT_RECEIVABLE);
                break;
            default:
                break;
        }

        let mappedItemTrx = [];
        filteredUserItemTrxs.map(trx => {
            trx.item_trx_history_pos_sales_trx_details.map(detail => {
                mappedItemTrx = mappedItemTrx.concat({
                    pos_sales_trx_detail_id: detail.pos_sales_trx_detail_id,
                    pos_sales_trx_head_id: detail.pos_sales_trx_head_id,
                    local_trx_id: trx.local_trx_id,
                    trx_local_end_time: trx.trx_local_end_time,
                    document_date: trx.document_date,
                    customer_name: trx.name,
                    customer_phone: trx.drv_primary_phone,
                    customer_ref: trx.reference_code,
                    item_package: detail.package_name,
                    item: detail.item_name,
                    notes: detail.item_notes,
                    secret_notes: detail.secret_notes,
                    workers: detail.worker_name,
                    sellers: detail.seller_name,
                    price: detail.variant_price
                });

                return detail;
            });

            return trx;
        });

        let itemTrxColumns = [
            {
                title: "#",
                dataIndex: "local_trx_id",
                key: "local_trx_id",
                sorter: (a, b) => ("" + a.local_trx_id.toUpperCase().substr(-6)).localeCompare(b.local_trx_id.toUpperCase().substr(-6)),
                ...this.customFilterProps("local_trx_id", "Trx ID", (data) => {
                    return data.toUpperCase().substr(-6);
                })
            },
            {
                title: "Trx Date",
                dataIndex: "trx_local_end_time",
                key: "trx_local_end_time",
                sorter: (a, b) => new Date(a.trx_local_end_time) - new Date(b.trx_local_end_time),
                ...this.customFilterProps("trx_local_end_time", "Trx Date")
            }
        ];

        if (configs.is_document_date_editable === 1) {
            itemTrxColumns = [
                ...itemTrxColumns,
                {
                    title: "Doc Date",
                    dataIndex: "document_date",
                    key: "document_date",
                    sorter: (a, b) => new Date(a.document_date) - new Date(b.document_date),
                    ...this.customFilterProps("document_date", "Doc Date")
                }
            ];
        }

        itemTrxColumns = [
            ...itemTrxColumns,
            {
                title: "Customer",
                dataIndex: "customer_name",
                key: "customer_name",
                sorter: (a, b) => ("" + a.customer_name).localeCompare(b.customer_name),
                ...this.customFilterProps("customer_name", "Customer Name")
            },
            {
                title: "Phone",
                dataIndex: "customer_phone",
                key: "customer_phone",
                sorter: (a, b) => ("" + a.customer_phone).localeCompare(b.customer_phone),
                ...this.customFilterProps("customer_phone", "Phone")
            },
            {
                title: "Ref",
                dataIndex: "customer_ref",
                key: "customer_ref",
                sorter: (a, b) => ("" + a.customer_ref).localeCompare(b.customer_ref),
                ...this.customFilterProps("customer_ref", "Ref")
            },
            {
                title: "Package",
                dataIndex: "item_package",
                key: "item_package",
                sorter: (a, b) => ("" + a.item_package).localeCompare(b.item_package),
                ...this.customFilterProps("item_package", "Package")
            },
            {
                title: "Item",
                dataIndex: "item",
                key: "item",
                sorter: (a, b) => ("" + a.item).localeCompare(b.item),
                ...this.customFilterProps("item", "Item")
            },
            {
                title: "Notes",
                dataIndex: "notes",
                key: "notes",
                sorter: (a, b) => ("" + a.notes).localeCompare(b.notes),
                ...this.customFilterProps("notes", "Notes")
            },
            {
                title: "Secret Notes",
                dataIndex: "secret_notes",
                key: "secret_notes",
                sorter: (a, b) => ("" + a.secret_notes).localeCompare(b.secret_notes),
                ...this.customFilterProps("secret_notes", "Secret Notes")
            },
            {
                title: "Worker",
                dataIndex: "workers",
                key: "workers",
                sorter: (a, b) => ("" + a.workers).localeCompare(b.workers),
                ...this.customFilterProps("workers", "Worker")
            },
            {
                title: "Seller",
                dataIndex: "sellers",
                key: "sellers",
                sorter: (a, b) => ("" + a.sellers).localeCompare(b.sellers),
                ...this.customFilterProps("sellers", "Seller")
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
                sorter: (a, b) => a.price - b.price,
                ...this.customFilterProps("price", "Price", (data, row) => {
                    return number_format(data, 0) + ` IDR`;
                })
            },
            {
                title: "Journal Pelanggan",
                dataIndex: "pos_sales_trx_detail_id",
                key: "pos_sales_trx_detail_id",
                render: (data, row) => {
                    return <Button type={"primary"} onClick={() => {
                        this.openCustomerJournal(row.trx_local_end_time);
                    }}>Lihat</Button>;
                }
            }
        ];

        const couponData = empty(user) ?
            [] : empty(user.active_coupons) ?
                [] : user.active_coupons.filter(row => {
                    if (!empty(coupon_filter)) {
                        return row.coupon_name
                            .toLowerCase()
                            .includes(coupon_filter.toLowerCase());
                    } else {
                        return row;
                    }
                });
        /*.map(item => {
            return {
                qty: item.qty,
                coupon_image: item.coupon_image,
                coupon_name: item.coupon_name,
                expired_date: item.expired_date,

            };
            {/!*<Card
                key={`Coupon${item.coupon_user_id}`}
                className={styles.couponListCard}
            >
                <div
                    className={styles.couponItemDiv}
                    style={{textAlign: 'center'}}
                >
                    <h3>{item.coupon_name}</h3>
                    <img src={item.coupon_image} alt="Coupon" />
                    <p>Sampai {moment(item.expired_date, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</p>
                    {!empty(item.coupon_notes) && (
                        <p>{item.coupon_notes}</p>
                    )}
                </div>
            </Card>*!/}
        });*/

        const couponColumns = [
            {
                title: "Gambar",
                dataIndex: "coupon_image",
                key: "coupon_image",
                render: (data, row) => {
                    return <img style={{ maxWidth: 100 }} src={data} alt="Coupon"/>;
                }
            },
            {
                title: "Nama Kupon",
                dataIndex: "coupon_name",
                key: "coupon_name"
            },
            {
                title: "Qty",
                dataIndex: "qty",
                key: "qty",
                render: (data, row) => {
                    return `x${data}`;
                }
            },
            {
                title: "Berlaku Sampai",
                dataIndex: "expired_date",
                key: "expired_date",
                render: (data, row) => {
                    return moment(data, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT);
                }
            },
            {
                title: "Outlet",
                dataIndex: "outlet_name",
                key: "outlet_name"
            },
            {
                title: "Catatan",
                dataIndex: "coupon_notes",
                key: "coupon_notes"
            }
        ];

        const pageTitle = (
            <React.Fragment>
                <Icon type="idcard"/>
                &nbsp;&nbsp; Informasi Member
            </React.Fragment>
        );
        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>

                    {step === 1 && (
                        <Col xxl={12} sm={24}>
                            <Spin spinning={searchUserLoading}>
                                <Form onSubmit={this.onSearchCustomer}>
                                    <FormItem
                                        {...formItemLayout}
                                        label="Cari Customer Berdasarkan"
                                    >
                                        <Radio.Group
                                            defaultValue="phone"
                                            buttonStyle="solid"
                                            disabled={user !== null}
                                            value={user_identifier_type}
                                            onChange={
                                                this.onChangeUserIdentifierType
                                            }
                                        >
                                            <Radio.Button value="phone">
                                                Phone
                                            </Radio.Button>
                                            <Radio.Button value="qr">
                                                QR Code
                                            </Radio.Button>
                                            <Radio.Button value="email">
                                                Email
                                            </Radio.Button>
                                            <Radio.Button value="reference">
                                                Reference
                                            </Radio.Button>
                                            <Radio.Button value="search">
                                                Nama
                                            </Radio.Button>
                                            <Radio.Button value="nik">
                                                NIK
                                            </Radio.Button>
                                        </Radio.Group>
                                    </FormItem>

                                    {user_identifier_type === "phone" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Phone Number"
                                        >
                                            <Input
                                                addonBefore="+62"
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                                type="tel"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "qr" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Scan QR Code"
                                        >
                                            <QrReader
                                                onScan={this.handleQrScan}
                                                onError={this.handleQrError}
                                                style={{
                                                    width: 300,
                                                    maxWidth: "100%",
                                                    marginBottom: 10
                                                }}
                                            />

                                            <Input
                                                placeholder="QR Code"
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "email" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Email"
                                        >
                                            <Input
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                                type="email"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "reference" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Reference"
                                        >
                                            <Input
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "search" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Nama Customer"
                                        >
                                            <Input.Search
                                                onSearch={this.onSearchCustomerByKeyword}
                                                onChange={
                                                    this.onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                            />

                                            {!empty(this.state.userSearchResult) && user === null && (
                                                <List
                                                    style={{ marginTop: 10 }}
                                                    bordered
                                                    dataSource={this.state.userSearchResult}
                                                    renderItem={item => (<List.Item>
                                                        <div style={{ cursor: "pointer", display: "block", width: "100%" }} onClick={() => {
                                                            this.selectSearchedCustomer(item);
                                                        }}>
                                                            <h4>{item.user_name}{!empty(item.user_id) && ` (${item.user_id})`}</h4>
                                                            <p>
                                                                {!empty(item.user_ref) && (
                                                                    <React.Fragment>
                                                                        Ref: {item.user_ref}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                {!empty(item.user_phone) && (
                                                                    <React.Fragment>
                                                                        {item.user_phone}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                {!empty(item.user_email) && (
                                                                    <React.Fragment>
                                                                        {item.user_email}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                {!empty(item.user_notes) && (
                                                                    <React.Fragment>
                                                                        {item.user_notes}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </List.Item>)}
                                                />
                                            )}

                                        </FormItem>

                                    )}

                                    {user_identifier_type === "nik" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="NIK"
                                        >
                                            <Input
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    <FormItem {...resetCustomerBtnLayout}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            block={true}
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </FormItem>
                                </Form>
                            </Spin>
                        </Col>
                    )}

                    <Col xxl={24} sm={24}>
                        {!empty(user) && (
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Data Pelanggan" key="1">
                                    <Col xxl={12} sm={24}>
                                        {step === 2 && user !== null && (
                                            <React.Fragment>
                                                <table className={styles.previewTable}>
                                                    <tbody>
                                                    <tr>
                                                        <td>Foto</td>
                                                        <td>
                                                            {!empty(user.user_photo) && (
                                                                <img style={{ maxWidth: 300, width: "100%" }} src={user.user_photo} alt="Customer"/>
                                                            )}
                                                            {empty(user.user_photo) && `Belum ada foto`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>User ID</td>
                                                        <td>{user.user_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ref Code</td>
                                                        <td>
                                                            {user.reference_code}
                                                            <Button style={{ float: "right" }}
                                                                    onClick={() => {
                                                                        this.generateRefCode(user.user_id);
                                                                    }}
                                                                    htmlType="button"
                                                                    type="primary"
                                                                    disabled={refCodeGenerateDisabled}
                                                                    size="small">Generate</Button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nama</td>
                                                        <td>{user.user_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NIK</td>
                                                        <td>{user.nik}</td>
                                                    </tr>
                                                    {configs.rme_satu_sehat === 1 && (
                                                        <tr>
                                                            <td>ID Satu Sehat</td>
                                                            <td>
                                                                {user.satusehat_ihs_no}
                                                                {empty(user.satusehat_ihs_no) && (
                                                                    <Button style={{ float: "right" }}
                                                                            onClick={() => {
                                                                                this.syncSatuSehatPatientId();
                                                                            }}
                                                                            htmlType="button"
                                                                            type="primary"
                                                                            size="small">Sync</Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>Jenis Kelamin</td>
                                                        <td>{user.user_gender === 1 ? "Laki-laki" : "Perempuan"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tanggal Lahir</td>
                                                        <td>
                                                            {moment(user.user_birth_date, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}
                                                            {moment(user.user_birth_date, MOMENT_SQL_DATE_FORMAT).format("MM-DD") === moment().format("MM-DD") && (
                                                                <span style={{ float: "right" }}><span role={"img"} aria-label={`Happy Birthday`}>&#127874;</span> Selamat Ulang Tahun</span>
                                                            )}

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>No. HP</td>
                                                        <td>{!empty(user.user_phone) ? <>{user.user_phone}
                                                            <Button style={{ float: "right" }}
                                                                    onClick={
                                                                        () => {
                                                                            const waText = `phone=${user.user_phone}&text=`;
                                                                            const waURL = "whatsapp://send?" + waText;
                                                                            window.open(waURL, "_blank");
                                                                        }
                                                                    }
                                                                    htmlType="button"
                                                                    type="primary"
                                                                    size="small">Send WhatsApp</Button>
                                                        </> : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Catatan / Keterangan</td>
                                                        <td>{user.user_notes}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Notification Email</td>
                                                        <td>{user.user_email_notification}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Membership Name</td>
                                                        <td>{user.membership_name} ({user.valid_until})</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount Level</td>
                                                        <td>
                                                            {
                                                                user.discount_level
                                                                    .reward_value
                                                            }
                                                            %
                                                        </td>
                                                    </tr>

                                                    {!empty(user.points) && (
                                                        <tr>
                                                            <td>Number of Points</td>
                                                            <td>
                                                                {user.points[0].drv_point}{" "}
                                                                {user.points[0].reward_name}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {!empty(user.account_receivable_drv_balance) && (
                                                        <tr>
                                                            <td>Total Piutang</td>
                                                            <td>
                                                                {number_format(user.account_receivable_drv_balance, 0, this.decimalSeparator, this.thousandSeparator)}{" "}
                                                                {this.currencyCode}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {configs.subscription_rules.deposit && (
                                                        <tr>
                                                            <td>Deposit Balance</td>
                                                            <td>{this.currencyCode} {number_format(empty(user.wallets) ? 0 : user.wallets[0].drv_amount)} <Button onClick={() => {
                                                                this.newTopUpTrx(user);
                                                            }} style={{ float: "right" }} htmlType="button" type="primary" size="small">Top Up</Button></td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>Transaksi Terakhir</td>
                                                        <td>
                                                            {moment(
                                                                user.last_trx,
                                                                "YYYY-MM-DD HH:mm:ss"
                                                            ).format(
                                                                "dddd, D MMM YYYY - HH:mm"
                                                            )}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>NFC Security Code</td>
                                                        <td>
                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    newNfcModalOpened: true
                                                                });
                                                            }}
                                                                    htmlType="button"
                                                                    type="primary"
                                                                    size="small"
                                                            >Daftar Baru</Button>

                                                            &nbsp;&nbsp;

                                                            <Modal
                                                                title="Daftar Baru NFC Security Code"
                                                                visible={this.state.newNfcModalOpened}
                                                                footer={null}
                                                                onCancel={() => {
                                                                    this.setState({
                                                                        newNfcModalOpened: false,
                                                                        cashierPassword: null,
                                                                        nfc_code: null,
                                                                        nfcRefId: null
                                                                    });
                                                                }}
                                                            >
                                                                <Spin spinning={this.state.nfcLoading} tip="Loading..">

                                                                    <h4 style={{ marginTop: 10 }}>Password</h4>
                                                                    <Input
                                                                        value={this.state.cashierPassword}
                                                                        onChange={e => {
                                                                            this.setState({ cashierPassword: e.target.value });
                                                                        }}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                    />

                                                                    {this.props.configs.nfc_with_ref_id === 1 && (
                                                                        <>
                                                                            <h4 style={{ marginTop: 20 }}>Ref ID</h4>
                                                                            <Input
                                                                                value={this.state.nfcRefId}
                                                                                onChange={e => {
                                                                                    this.setState({ nfcRefId: e.target.value });
                                                                                }}
                                                                                type="text"
                                                                                autoComplete="off"
                                                                            />
                                                                        </>
                                                                    )}

                                                                    {this.state.newNfcScanModalOpened && (
                                                                        <CaptureNfc opened={this.state.newNfcScanModalOpened}
                                                                                    onClose={() => {
                                                                                        this.setState({
                                                                                            newNfcScanModalOpened: false
                                                                                        });
                                                                                    }}
                                                                                    onScan={(nfc_code) => {
                                                                                        this.setState({
                                                                                            nfc_code,
                                                                                            newNfcScanModalOpened: false
                                                                                        }, () => {
                                                                                            this.onSubmitNewNFC();
                                                                                        });
                                                                                    }}/>
                                                                    )}


                                                                    <br/><br/>

                                                                    <Button onClick={() => {
                                                                        this.setState({
                                                                            newNfcScanModalOpened: true
                                                                        });
                                                                    }}
                                                                            htmlType="button"
                                                                            type="primary"
                                                                    >Scan Kartu</Button>

                                                                </Spin>
                                                            </Modal>

                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    nfcModalOpened: true
                                                                });
                                                            }}
                                                                    htmlType="button"
                                                                    type="primary"
                                                                    size="small"
                                                            >Ubah</Button>

                                                            <Modal
                                                                title="Atur NFC Security Code"
                                                                visible={this.state.nfcModalOpened}
                                                                footer={null}
                                                                onCancel={() => {
                                                                    this.setState({
                                                                        nfcModalOpened: false,
                                                                        cashierPassword: null,
                                                                        nfc_code: null
                                                                    });
                                                                }}
                                                            >
                                                                <Spin spinning={this.state.nfcLoading} tip="Loading..">

                                                                    <h4 style={{ marginTop: 10 }}>Password</h4>
                                                                    <Input
                                                                        value={this.state.cashierPassword}
                                                                        onChange={e => {
                                                                            this.setState({ cashierPassword: e.target.value });
                                                                        }}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                    />

                                                                    {this.props.configs.nfc_with_ref_id === 1 && (
                                                                        <>
                                                                            <h4 style={{ marginTop: 20 }}>Ref ID</h4>
                                                                            <Input
                                                                                value={this.state.nfcRefId}
                                                                                onChange={e => {
                                                                                    this.setState({ nfcRefId: e.target.value });
                                                                                }}
                                                                                type="text"
                                                                                autoComplete="off"
                                                                            />
                                                                        </>
                                                                    )}

                                                                    {this.state.nfcScanModalOpened && (
                                                                        <CaptureNfc opened={this.state.nfcScanModalOpened}
                                                                                    onClose={() => {
                                                                                        this.setState({
                                                                                            nfcScanModalOpened: false
                                                                                        });
                                                                                    }}
                                                                                    onScan={(nfc_code) => {
                                                                                        this.setState({
                                                                                            nfc_code,
                                                                                            nfcScanModalOpened: false
                                                                                        }, () => {
                                                                                            this.onSubmitNFC();
                                                                                        });
                                                                                    }}/>
                                                                    )}


                                                                    <br/><br/>

                                                                    <Button onClick={() => {
                                                                        this.setState({
                                                                            nfcScanModalOpened: true
                                                                        });
                                                                    }}
                                                                            htmlType="button"
                                                                            type="primary"
                                                                    >Scan Kartu</Button>

                                                                </Spin>
                                                            </Modal>

                                                            &nbsp;&nbsp;

                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    testNfcModalOpened: true
                                                                });
                                                            }}
                                                                    htmlType="button"
                                                                    type="primary"
                                                                    size="small"
                                                            >Test</Button>

                                                            {this.state.testNfcModalOpened && (
                                                                <CaptureNfc opened={this.state.testNfcModalOpened}
                                                                            onClose={() => {
                                                                                this.setState({
                                                                                    testNfcModalOpened: false
                                                                                });
                                                                            }}
                                                                            onScan={(test_nfc_code) => {
                                                                                this.setState({
                                                                                    test_nfc_code,
                                                                                    testNfcModalOpened: false
                                                                                }, () => {
                                                                                    this.onSubmitTestNFC();
                                                                                });
                                                                            }}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>

                                                    {configs.auth_role_accesses.CUSTOMER_JOURNAL === 1 &&
                                                        <tr>
                                                            <td>Jurnal Pelanggan</td>
                                                            <td>
                                                                <Link to={`/customer-journal/${this.state.user_identifier_type}/${this.state.user_identifier_string}`}>
                                                                    <Button htmlType="button"
                                                                            type="primary"
                                                                            size="small"
                                                                    >Lihat Jurnal</Button>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    }

                                                    {user.is_birthday === 1 && (
                                                        <tr>
                                                            <td colSpan={2}>
                                                                TODAY IS HIS/HER BIRTHDAY!
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>

                                                <div>
                                                    <Button
                                                        type="danger"
                                                        size="default"
                                                        htmlType="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.initialState
                                                            });
                                                        }}
                                                    >
                                                        Back
                                                    </Button>

                                                    {configs.auth_role_accesses.CUSTOMER_INFORMATION_EDIT === 1 && (
                                                        <>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                type="primary"
                                                                size="default"
                                                                htmlType="button"
                                                                onClick={
                                                                    this.onEditMemberDataBtnClicked
                                                                }
                                                            >
                                                                Edit Member Data
                                                            </Button>
                                                        </>
                                                    )}

                                                    &nbsp;&nbsp;
                                                    <Button
                                                        type="primary"
                                                        size="default"
                                                        htmlType="button"
                                                        onClick={
                                                            this.onEditNikBtnClicked
                                                        }
                                                    >
                                                        Edit NIK
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                        )}

                                        {step === 5 && user !== null && (
                                            <React.Fragment>
                                                <Spin spinning={editUserLoading}>
                                                    <Form onSubmit={this.onEditMemberData}>
                                                        <div className={styles.formGroup}>
                                                            <label>Nama</label>
                                                            <Input
                                                                type="text"
                                                                onChange={this.onChangeUserName}
                                                                value={user.user_name}
                                                                readOnly={true}
                                                            />
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>NIK</label>
                                                            <Input
                                                                type="text"
                                                                onChange={this.onChangeUserNik}
                                                                value={user.nik}
                                                            />
                                                        </div>

                                                        <div style={{ marginTop: 30, marginBottom: 50 }}>
                                                            <Button
                                                                type="danger"
                                                                htmlType="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        step: 2
                                                                    });
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                            >
                                                                Simpan
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Spin>
                                            </React.Fragment>
                                        )}

                                        {step === 3 && user !== null && (
                                            <React.Fragment>
                                                <Spin spinning={editUserLoading}>
                                                    <Form onSubmit={this.onEditMemberData}>
                                                        <div className={styles.formGroup}>
                                                            <label>Foto Customer</label>

                                                            {(!empty(user.user_photo) || !empty(user_photo_data)) && (
                                                                <div>
                                                                    <img style={{ maxWidth: 300, width: "100%" }} src={empty(user_photo_data) ? user.user_photo : user_photo_data} alt="Customer"/>
                                                                </div>
                                                            )}

                                                            {empty(user.user_photo) && empty(user_photo_data) && (
                                                                <div>
                                                                    Belum ada foto saat ini.
                                                                </div>
                                                            )}

                                                            <div style={{ marginTop: 10 }}>
                                                                {empty(user_photo_data) && (
                                                                    <React.Fragment>
                                                                        <Button type="primary"
                                                                                htmlType="button"
                                                                                icon="camera"
                                                                            // style={{ marginTop: 8 }}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        takePhotoModalOpened: true
                                                                                    });
                                                                                }}>
                                                                            Ambil foto dari kamera
                                                                        </Button>
                                                                        &nbsp;&nbsp;
                                                                        atau
                                                                        &nbsp;&nbsp;
                                                                        <input type="file"
                                                                               id="newPhotoFileInput"
                                                                               style={{ display: "none" }}
                                                                               onChange={this.onChangeUploadPhoto}
                                                                        />
                                                                        <label htmlFor="newPhotoFileInput"
                                                                               style={{
                                                                                   fontWeight: "bold",
                                                                                   textDecoration: "underline",
                                                                                   cursor: "pointer"
                                                                               }}
                                                                        >
                                                                            Upload File
                                                                        </label>
                                                                    </React.Fragment>
                                                                )}

                                                                {!empty(user_photo_data) && (
                                                                    <Button type="danger"
                                                                            htmlType="button"
                                                                            icon="delete"
                                                                        // style={{ marginTop: 8 }}
                                                                            onClick={this.onResetPhoto}>
                                                                        Ganti foto
                                                                    </Button>
                                                                )}

                                                                <Modal
                                                                    title="Ambil Foto"
                                                                    visible={this.state.takePhotoModalOpened}
                                                                    footer={null}
                                                                    onOk={() => {
                                                                        this.setState({
                                                                            takePhotoModalOpened: false
                                                                        });
                                                                    }}
                                                                    onCancel={() => {
                                                                        this.setState({
                                                                            takePhotoModalOpened: false
                                                                        });
                                                                    }}
                                                                >
                                                                    <Camera
                                                                        onTakePhoto={(dataUri) => {
                                                                            this.onTakePhoto(dataUri);
                                                                        }}
                                                                        onCameraError={(error) => {
                                                                            this.onCameraError(error);
                                                                        }}
                                                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                                                        idealResolution={{ width: 400, height: 400 }}
                                                                        imageType={IMAGE_TYPES.JPG}
                                                                        imageCompression={0.97}
                                                                        isMaxResolution={false}
                                                                        isImageMirror={false}
                                                                        isDisplayStartCameraError={true}
                                                                        sizeFactor={1}
                                                                    />
                                                                </Modal>
                                                            </div>

                                                            {/*<Button type="primary"
                                                htmlType="button"
                                                icon="camera"
                                                style={{ marginTop: 8, marginRight: 5 }}
                                                onClick={() => {
                                                    this.setState({
                                                        takePhotoModalOpened: true
                                                    });
                                                }}>
                                            Ambil foto baru
                                        </Button>

                                        <Button type="danger"
                                                htmlType="button"
                                                icon="delete"
                                                style={{ marginTop: 8 }}
                                                onClick={this.onResetPhoto}>
                                            Hapus foto
                                        </Button>

                                        <Modal
                                            title="Ambil Foto"
                                            visible={this.state.takePhotoModalOpened}
                                            footer={null}
                                            onOk={() => {
                                                this.setState({
                                                    takePhotoModalOpened: false
                                                });
                                            }}
                                            onCancel={() => {
                                                this.setState({
                                                    takePhotoModalOpened: false
                                                });
                                            }}
                                        >
                                            <Camera
                                                onTakePhoto={(dataUri) => {
                                                    this.onTakePhoto(dataUri);
                                                }}
                                                onCameraError={(error) => {
                                                    this.onCameraError(error);
                                                }}
                                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                                idealResolution={{ width: 400, height: 400 }}
                                                imageType={IMAGE_TYPES.JPG}
                                                imageCompression={0.97}
                                                isMaxResolution={false}
                                                isImageMirror={false}
                                                isDisplayStartCameraError={true}
                                                sizeFactor={1}
                                            />
                                        </Modal>*/}
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>Nama</label>
                                                            <Input
                                                                type="text"
                                                                onChange={this.onChangeUserName}
                                                                value={user.user_name}
                                                            />
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>NIK</label>
                                                            <Input
                                                                type="text"
                                                                onChange={this.onChangeUserNik}
                                                                value={user.nik}
                                                            />
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>Tanggal Lahir</label>
                                                            <DatePicker {...birthDateDefaultValue}
                                                                        format={dateFormat}
                                                                        onChange={
                                                                            this.onDatePickerSelected
                                                                        }
                                                                        showToday={true}
                                                                        allowClear={true}/>
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>Jenis Kelamin</label>
                                                            <Radio.Group
                                                                defaultValue=""
                                                                buttonStyle="solid"
                                                                value={user.user_gender}
                                                                onChange={
                                                                    this.onChangeUserGender
                                                                }
                                                            >
                                                                <Radio.Button value={1}>
                                                                    Laki-laki
                                                                </Radio.Button>
                                                                <Radio.Button value={2}>
                                                                    Perempuan
                                                                </Radio.Button>
                                                            </Radio.Group>
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>Catatan</label>
                                                            <Input.TextArea
                                                                type="text"
                                                                onChange={
                                                                    this.onChangeUserNotes
                                                                }
                                                                rows={4}
                                                                value={user.user_notes}
                                                            />
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>Reference Code</label>
                                                            <Input
                                                                type="text"
                                                                onChange={this.onChangeReferenceCode}
                                                                value={user.reference_code}
                                                            />
                                                        </div>

                                                        <div className={styles.formGroup}>
                                                            <label>Notification Email</label>
                                                            <Input
                                                                type="text"
                                                                onChange={this.onChangeUserNotificationEmail}
                                                                value={user.user_email_notification}
                                                            />
                                                        </div>

                                                        <div style={{ marginTop: 30, marginBottom: 50 }}>
                                                            <Button
                                                                type="danger"
                                                                htmlType="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        step: 2
                                                                    });
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                            >
                                                                Simpan
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Spin>
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Kupon" key="2">
                                    {!empty(user) && !empty(user.active_coupons) && (
                                        <Col xxl={24} sm={24}>
                                            <Input.Search type="text"
                                                          onChange={e => {
                                                              this.setState({ coupon_filter: e.target.value });
                                                          }}
                                                          value={coupon_filter}
                                                          placeholder="Cari kupon.."
                                                          style={{
                                                              width: "500px",
                                                              maxWidth: "100%",
                                                              marginBottom: "20px"
                                                          }}
                                            />

                                            <div>
                                                <Table rowKey="coupon_user_id"
                                                       dataSource={couponData}
                                                       columns={couponColumns}
                                                       style={{ maxWidth: "100%" }}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Tabs.TabPane>

                                {configs.subscription_rules.customer_trx_history && !empty(user) && (
                                    <Tabs.TabPane tab="Riwayat Transaksi (Bill)" key="3">
                                        <React.Fragment>
                                            <Row style={{ minHeight: "100vh" }} gutter={48}>
                                                <Col xxl={12} sm={12}>
                                                    <Spin spinning={this.state.userTrxLoading}>
                                                        {configs.subscription_rules.advanced_member_trx_history && (
                                                            <div className={styles.formGroup}>
                                                                <DatePicker placeholder="Sejak Tanggal"
                                                                            format={dateFormat}
                                                                            onChange={(momentDate, dateString) => {
                                                                                this.setState({
                                                                                    userTrxDate: dateString
                                                                                });
                                                                            }}
                                                                            disabledDate={current => {
                                                                                const minYear = parseInt(moment().format("YYYY")) - 1;
                                                                                return current < moment(minYear + "-01-01", MOMENT_SQL_DATE_FORMAT);
                                                                            }}
                                                                            showToday={true}
                                                                            allowClear={true}
                                                                            style={{ marginRight: 10 }}
                                                                />
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="button"
                                                                    onClick={this.loadCustomerTrxs}
                                                                >
                                                                    Tampilkan Transaksi
                                                                </Button>
                                                            </div>
                                                        )}

                                                        {!empty(userTrxs) && (
                                                            <div style={{ marginBottom: 20 }}>
                                                                <Select defaultValue="all"
                                                                        style={{ width: "100%" }}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                userTrxTypeFilter: e,
                                                                                userTrxSelected: null
                                                                            });
                                                                        }}
                                                                >
                                                                    <Select.Option value="all">Semua Jenis Transaksi</Select.Option>
                                                                    <Select.Option value="redeem">Redeem Hadiah</Select.Option>
                                                                    <Select.Option value="coupon-sell">Penjualan Kupon</Select.Option>
                                                                    <Select.Option value="coupon-use">Penggunaan Kupon</Select.Option>
                                                                    <Select.Option value="deposit-topup">Top-up Deposit</Select.Option>
                                                                    <Select.Option value="deposit-use">Penggunaan Deposit</Select.Option>
                                                                    <Select.Option value="piutang">Memiliki Piutang</Select.Option>
                                                                    <Select.Option value="piutang-pembayaran">Pelunasan / Pembayaran Piutang</Select.Option>
                                                                </Select>
                                                            </div>
                                                        )}

                                                        {!empty(filteredUserTrxs) && filteredUserTrxs.map(trx => {
                                                            return (
                                                                <Card className={styles.trxHistoryCard}
                                                                      key={`TrxHistory${trx.local_trx_id}`}
                                                                      onClick={() => {
                                                                          let receiptPhone = "";
                                                                          let receiptMail = "";
                                                                          if (!empty(trx.customer)) {
                                                                              if (trx.customer.user_identifier_type === "phone") {
                                                                                  receiptPhone = trx.customer.user_identifier_string;
                                                                              }

                                                                              if (!empty(trx.customer.user_email_notification)) {
                                                                                  receiptMail = trx.customer.user_email_notification;
                                                                              }
                                                                          }

                                                                          this.setState({
                                                                              userTrxSelected: trx,
                                                                              receiptPhone,
                                                                              receiptMail
                                                                          });
                                                                      }}
                                                                >
                                                                    <h4>{moment(trx.trx_local_end_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}</h4>
                                                                    <div>
                                                                        Transaksi #{trx.local_trx_id.toUpperCase()}<br/>
                                                                        {this.currencyCode} {number_format(trx.drv_grand_total)}
                                                                        {!empty(trx.trx_notes) && (
                                                                            <React.Fragment>
                                                                                <br/>
                                                                                Catatan:
                                                                                <div style={{ paddingLeft: 5 }}>
                                                                                    {nl2brReact(trx.trx_notes)}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </Card>
                                                            );
                                                        })}

                                                        {empty(filteredUserTrxs) && (<Empty/>)}
                                                    </Spin>
                                                </Col>

                                                <Col xxl={12} sm={12}>
                                                    {!empty(this.state.userTrxSelected) && (
                                                        <Affix offsetTop={30} className={styles.trxHistoryAffix}>
                                                            <PosPriceList
                                                                style={{
                                                                    marginTop: 0
                                                                }}
                                                                trx={this.state.userTrxSelected}
                                                                isPrint={true}
                                                                showSecretNotes={true}
                                                                showStockOut={true}
                                                                showWorker={true}
                                                                showSeller={true}
                                                            />

                                                            {configs.pos_print_digital_invoice === 1 && (
                                                                <div style={{ textAlign: "center" }}>
                                                                    <Spin spinning={sendReceiptLoading}>
                                                                        <br/>

                                                                        {configs.is_sms_receipt === 1 && (
                                                                            <React.Fragment>
                                                                                <Input.Search
                                                                                    placeholder="No. HP"
                                                                                    enterButton="SMS"
                                                                                    style={{ margin: 10, width: "60%" }}
                                                                                    value={this.state.receiptPhone}
                                                                                    onChange={(e) => {
                                                                                        this.setState({
                                                                                            receiptPhone: e.target.value
                                                                                        });
                                                                                    }}
                                                                                    onSearch={val => {
                                                                                        this.onSendSmsReceipt(this.state.userTrxSelected.local_trx_id, val);
                                                                                    }}
                                                                                />

                                                                                <br/>
                                                                            </React.Fragment>
                                                                        )}

                                                                        {appConfig.displayMode === "DESKTOP" && (
                                                                            <>
                                                                                <Input.Search
                                                                                    placeholder="No. HP"
                                                                                    enterButton="WA Web"
                                                                                    style={{ margin: 10, width: "60%" }}
                                                                                    value={this.state.receiptPhone}
                                                                                    onChange={(e) => {
                                                                                        this.setState({
                                                                                            receiptPhone: e.target.value
                                                                                        });
                                                                                    }}
                                                                                    onSearch={val => {
                                                                                        this.onSendWhatsAppReceipt(this.state.userTrxSelected.local_trx_id, val);
                                                                                    }}
                                                                                />

                                                                                <br/>
                                                                            </>
                                                                        )}

                                                                        <Input.Search
                                                                            placeholder="No. HP"
                                                                            enterButton="WA App"
                                                                            style={{ margin: 10, width: "60%" }}
                                                                            value={this.state.receiptPhone}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    receiptPhone: e.target.value
                                                                                });
                                                                            }}
                                                                            onSearch={val => {
                                                                                this.onSendWhatsAppDesktopReceipt(this.state.userTrxSelected.local_trx_id, val);
                                                                            }}
                                                                        />

                                                                        <br/>

                                                                        <Input.Search
                                                                            placeholder="Email Address"
                                                                            enterButton="e-Mail"
                                                                            style={{ margin: 10, width: "60%" }}
                                                                            value={this.state.receiptMail}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    receiptMail: e.target.value
                                                                                });
                                                                            }}
                                                                            onSearch={val => {
                                                                                this.onSendEmailReceipt(this.state.userTrxSelected.local_trx_id, val);
                                                                            }}
                                                                        />

                                                                        <br/>
                                                                    </Spin>
                                                                </div>
                                                            )}
                                                        </Affix>
                                                    )}
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    </Tabs.TabPane>
                                )}

                                {configs.subscription_rules.customer_trx_history && !empty(user) && (
                                    <Tabs.TabPane tab="Riwayat Transaksi (Item)" key="4">
                                        <React.Fragment>
                                            <Row style={{ minHeight: "100vh", overflow: "auto" }} gutter={48}>
                                                <Col xxl={20} sm={24}>
                                                    <Spin spinning={this.state.userTrxLoading}>
                                                        {configs.subscription_rules.advanced_member_trx_history && (
                                                            <div className={styles.formGroup}>
                                                                <DatePicker placeholder="Sejak Tanggal"
                                                                            format={dateFormat}
                                                                            onChange={(momentDate, dateString) => {
                                                                                this.setState({
                                                                                    userItemTrxDate: dateString
                                                                                });
                                                                            }}
                                                                            disabledDate={current => {
                                                                                const minYear = parseInt(moment().format("YYYY")) - 1;
                                                                                return current < moment(minYear + "-01-01", MOMENT_SQL_DATE_FORMAT);
                                                                            }}
                                                                            showToday={true}
                                                                            allowClear={true}
                                                                            style={{ marginRight: 10 }}
                                                                />
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="button"
                                                                    onClick={this.loadCustomerItemTrxs}
                                                                >
                                                                    Tampilkan Transaksi
                                                                </Button>
                                                            </div>
                                                        )}

                                                        {!empty(userTrxs) && (
                                                            <div style={{ marginBottom: 20 }}>
                                                                <Select defaultValue="all"
                                                                        style={{ width: "100%" }}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                userItemTrxTypeFilter: e,
                                                                                userItemTrxSelected: null
                                                                            });
                                                                        }}
                                                                >
                                                                    <Select.Option value="all">Semua Jenis Transaksi</Select.Option>
                                                                    <Select.Option value="redeem">Redeem Hadiah</Select.Option>
                                                                    <Select.Option value="coupon-sell">Penjualan Kupon</Select.Option>
                                                                    <Select.Option value="coupon-use">Penggunaan Kupon</Select.Option>
                                                                    <Select.Option value="deposit-topup">Top-up Deposit</Select.Option>
                                                                    <Select.Option value="deposit-use">Penggunaan Deposit</Select.Option>
                                                                    <Select.Option value="piutang">Memiliki Piutang</Select.Option>
                                                                    <Select.Option value="piutang-pembayaran">Pelunasan / Pembayaran Piutang</Select.Option>
                                                                </Select>
                                                            </div>
                                                        )}

                                                        {!empty(filteredUserItemTrxs) && (
                                                            <Table rowKey={(row) => {
                                                                return row.local_trx_id + row.item;
                                                            }}
                                                                   dataSource={mappedItemTrx}
                                                                   columns={itemTrxColumns}
                                                                   style={{ maxWidth: "100%" }}
                                                            />
                                                        )}

                                                        {empty(filteredUserItemTrxs) && (<Empty/>)}
                                                    </Spin>
                                                </Col>
                                            </Row>

                                            <Modal
                                                title="Jurnal Pelanggan"
                                                visible={journalModalOpened}
                                                onOk={this.closeJournalModal}
                                                onCancel={this.closeJournalModal}
                                                cancelButtonProps={{ style: { display: "none" } }}
                                                okText="Close"
                                            >
                                                <div>
                                                    <Spin spinning={journalLoading}>
                                                        <div>

                                                            {journals?.activities?.filter(row => !empty(row.activity_notes))
                                                                .map(row => {
                                                                    return <div style={{ marginBottom: 50 }}>
                                                                        <div>
                                                                            <strong>JOURNAL {moment(row.created_at, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}</strong>
                                                                            <br/>
                                                                            Notes: {row.activity_notes}
                                                                        </div>
                                                                        <table className={styles.journalTable}>
                                                                            {row.customer_activity_photos.map(photo => (
                                                                                <tr>
                                                                                    <td><img src={photo.photo_url}
                                                                                             style={{
                                                                                                 maxWidth: 200,
                                                                                                 maxHeight: 200,
                                                                                                 marginRight: 20
                                                                                             }}
                                                                                             alt={photo.photo_desc}/></td>
                                                                                    <td>{photo.photo_desc}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </table>
                                                                    </div>;
                                                                })
                                                            }

                                                        </div>
                                                    </Spin>
                                                </div>
                                            </Modal>
                                        </React.Fragment>
                                    </Tabs.TabPane>
                                )}
                            </Tabs>
                        )}
                    </Col>

                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        configs: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    addLoyaltyTrx: loyaltyOperations.addLoyaltyTrx,
    addLocalLoyaltyTrx: loyaltyOperations.addLocalLoyaltyTrx,
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberInfo);
