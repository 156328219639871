import actions from './actions';

const addLoyaltyTrx = trx_data => actions.addLoyaltyTrx(trx_data);
const addLocalLoyaltyTrx = trx_data => actions.addLocalLoyaltyTrx(trx_data);
const setLoyaltyStates = new_state => actions.setLoyaltyStates(new_state);

export default {
    addLoyaltyTrx,
    addLocalLoyaltyTrx,
    setLoyaltyStates
};
