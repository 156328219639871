import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Icon, Input, message, Row, Spin, Table } from "antd";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import styles from "./OrganizationList.module.scss";
import connect from "react-redux/es/connect/connect";
import { posOperations } from "../../state/ducks/pos";
import Highlighter from "react-highlight-words";
import { empty } from "../../shared/helpers/generalHelper";

class OrganizationList extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            organizations: []
        };

        this.state = { ...this.initialState };
    }

    componentDidMount() {
        this.loadOrganizations();
    }

    loadOrganizations = () => {
        this.setState({ formLoading: true });

        apiCall(
            endpoint.ERM_ORGANIZATION,
            "get",
            null,
            result => {
                if(empty(result.data.payload.organizations)){
                    this.props.history.push("/emr/organization/initialization");
                }else{
                    this.setState({
                        organizations: result.data.payload.organizations
                    });
                }
            },
            error => {
                if (error === "timeout") {
                    // Network is not available
                    message.error(
                        "Telah terjadi kegagalan koneksi, silakan coba lagi."
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
            },
            res => {
                this.setState({ formLoading: false });
            }
        );
    };

    customFilterProps = (dataIndex, dataLabel, customRender = null) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataLabel}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => {
            let modifiedText = text || '';
            if(!empty(customRender)){
                modifiedText = customRender(text);
            }
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={modifiedText}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const {
            formLoading, organizations
        } = this.state;

        const organizationColumns = [
            {
                title: "Nama Struktur Organisasi",
                dataIndex: "organization_name",
                key: "organization_name",
                sorter: (a, b) => a.organization_name.length - b.organization_name.length,
                ...this.customFilterProps('organization_name', 'Nama Struktur Organisasi')
            },
            {
                title: "Bagian Dari",
                dataIndex: "parent_emr_organization",
                key: "parent_emr_organization",
                sorter: (a, b) => a.parent_emr_organization.length - b.parent_emr_organization.length,
                ...this.customFilterProps('parent_emr_organization', 'Bagian Dari')
            },
            {
                title: "Status",
                dataIndex: "is_active",
                key: "is_active",
                sorter: (a, b) => a.is_active.length - b.is_active.length,
                render: (val) => {
                    return val === 1 ? 'Aktif' : 'Non Aktif';
                }
            },
            {
                title: "Action",
                key: "action",
                render: (text, record) => (
                    <span>
                        <Button size="small"
                                type="primary"
                                htmlType="button"
                                style={{marginRight: 5}}
                                onClick={() => {
                                    message.info('Edited');
                                }}>Edit</Button>

                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    message.info('Deleted');
                                }}>Delete</Button>
                    </span>
                )
            }
        ];

        const pageTitle = (
            <React.Fragment>
                Daftar Struktur Organisasi
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={24} sm={24}>
                        <Spin spinning={formLoading}>
                            <div style={{marginBottom: 10, textAlign: 'right'}}>
                                <Button type={"primary"} onClick={() => {
                                    this.props.history.push("/emr/organization/new");
                                }}>Tambah Struktur Organisasi</Button>
                            </div>
                            <div className={styles.formItem}>
                                <Table rowKey="trx_id" columns={organizationColumns} dataSource={organizations} pagination={false}/>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationList);