import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Empty, Row, Spin } from "antd";
import "./PosReceiptPublic.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import PosPriceListPublic from "../modules/PosPriceListPublic/PosPriceListPublic";

class PosReceiptPublic extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            trx: null,
            retrieved: false,
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        apiCall(
            endpoint.POST_POS_RECEIPT_RETRIEVE + `/${this.props.match.params.receipt_token}`,
            "get",
            null,
            result => {
                this.setState({
                    trx: result.data
                });
            },
            error => {
                ErrorHandler.handleGeneralError(error);
            },
            () => {
                this.setState({
                    loading: false,
                    retrieved: true
                });
            });
    }

    render() {
        const { trx, retrieved, loading } = this.state;

        return (
            <Spin spinning={loading} tip="loading..">
                <Row>
                    <Col span={24}>
                        {!empty(trx) && (
                            <PosPriceListPublic
                                trx={trx}
                                posConfig={trx.cashier_configs}
                                isPrint={true}
                                isLegit={true}
                                preSettlement={false}
                            />
                        )}
                        {empty(trx) && !retrieved && (
                            <Empty style={{ marginTop: '15vh' }} description="Loading receipt, please wait.."/>
                        )}
                        {empty(trx) && retrieved && (
                            <Empty style={{ marginTop: '15vh' }} description="Failed to load receipt, please try again."/>
                        )}
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default PosReceiptPublic;
