import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import "react-html5-camera-photo/build/css/index.css";
import { Button, Card, Col, Form, Icon, Input, InputNumber, message, Modal, Row, Select, Spin, Table } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./InventoryStockIn.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import connect from "react-redux/es/connect/connect";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, INV_SRC_TYPE_FROM_SUPPLIER } from "../shared/utils/constants";
import uuid from "uuid/v4";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { number_format, processDecimalInput } from "../shared/helpers/stringHelper";

class InventoryStockIn extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            local_trx_id: uuid(),
            sourceType: INV_SRC_TYPE_FROM_SUPPLIER,
            inv_vendor_id: null,
            purchase_order_number: '',
            invoice_number: '',
            inv_sloc_id: this.props.posConfig.storage_locations.length === 1 ? this.props.posConfig.storage_locations[0].inv_sloc_id : null,
            fetchingItems: false,
            fetchedItems: [],
            suppliers: [],
            items: [],
            selectedItem: null,
            currentStockQty: null,
            currentBadStockQty: null,
            qty: 0,
            bad_qty: 0,
            itemDiscPercent: 0,
            itemPrice: 0,
            itemPriceText: "",
            notes: null,
            document_no: null,
            formLoading: false,
            itemSku: ""
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;

        this.goodItemQty = React.createRef();
        this.itemSku = React.createRef();
        this.fetchItem = debounce(this.fetchItem, 500);
    }

    componentDidMount() {
        this.fetchItem("");
        this.loadSuppliers();
    }

    loadSuppliers = () => {
        apiCall(
            endpoint.GET_INVENTORY_SUPPLIERS,
            "get",
            null,
            (res) => {
                this.setState({ suppliers: res.data });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    fetchItem = (value) => {
        this.setState({ fetchedItems: [], fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?item_name=${value}`,
            "get",
            null,
            (res) => {
                this.setState({ fetchedItems: res.data, fetchingItems: false });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    fetchItemWithSku = () => {
        this.setState({ fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?sku=${this.state.itemSku}`,
            "get",
            null,
            (res) => {
                if (empty(res.data)) {
                    message.error("Barang tidak ditemukan");
                } else {
                    this.setState({
                        selectedItem: res.data[0],
                        itemSku: "",
                        fetchingItems: false
                    }, () => {
                        this.loadSelectedItemDetails();
                        this.goodItemQty.current.focus();
                    });
                }
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    onChangeItemSKU = e => {
        this.setState({
            itemSku: e.target.value
        });
    };

    handleSkuEnterPressed = e => {
        if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            this.onSubmitSKU();
        }
    };

    onSubmitSKU = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        let isValidForm = true;

        if (empty(this.state.itemSku)) {
            isValidForm = false;
            message.error("SKU produk harus diisi");
        }

        if (isValidForm) {
            this.fetchItemWithSku();
        }
    };

    loadSelectedItemDetails = () => {
        const { selectedItem, inv_sloc_id } = this.state;
        const { posConfig } = this.props;
        this.setState({
            formLoading: true
        });
        const that = this;

        that.goodItemQty.current.focus();
        apiCall(
            endpoint.GET_INVENTORY_CHECK_ITEM_STOCK + `?pos_item_variant_id=${selectedItem.pos_item_variant_id}&inv_sloc_id=${inv_sloc_id}`,
            "get",
            null,
            (res) => {
                this.setState({
                    currentStockQty: res.data.qty,
                    currentBadStockQty: res.data.drv_bad_qty,
                    itemPrice: posConfig.myob === 1 ? res.data.variant_cogs : 0,
                    itemPriceText:posConfig.myob === 1 ? number_format(res.data.variant_cogs, 0) : '0',
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
                this.setState({ selectedItem: null });
            },
            () => {
                this.setState({
                    formLoading: false
                });
            }
        );
    };

    addItem = () => {
        const { items, selectedItem, currentStockQty, currentBadStockQty, qty, bad_qty, itemDiscPercent, itemPrice } = this.state;
        if (!empty(items.find(item => item.key === selectedItem.pos_item_variant_id))) {
            message.error("Barang ini sudah ada dalam daftar, silakan hapus terlebih dahulu untuk mengganti jumlah qty.");
        } else if (empty(qty) && empty(bad_qty)) {
            message.error("Qty barang tidak boleh nol.");
        } else {
            this.setState({
                items: items.concat({
                    local_id: uuid(),
                    key: selectedItem.pos_item_variant_id,
                    item_name: selectedItem.item_name,
                    qty_now: currentStockQty,
                    bad_qty_now: currentBadStockQty,
                    qty: qty,
                    bad_qty: bad_qty,
                    disc_percent: itemDiscPercent,
                    price: itemPrice,
                    unit_name: selectedItem.unit_name,
                    inv_unit_id: selectedItem.inv_unit_id
                }),
                currentStockQty: null,
                currentBadStockQty: null,
                itemDiscPercent: 0,
                itemPrice: 0,
                itemPriceText: "",
                qty: 0,
                bad_qty: 0,
                selectedItem: null
            }, () => {
                if (this.props.posConfig.pos_item_search_by_sku === 1) {
                    this.itemSku.current.focus();
                }
            });
        }
    };

    deleteItem = (key) => {
        const that = this;
        Modal.confirm({
            title: "Hapus Barang",
            content: "Apakah Anda yakin ingin menghapus?",
            onOk() {
                that.setState({
                    items: that.state.items.filter(item => item.key !== key)
                });
            },
            onCancel() {
            }
        });
    };

    onFormSubmit = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            local_trx_id,
            document_no,
            sourceType,
            inv_vendor_id,
            purchase_order_number,
            invoice_number,
            inv_sloc_id,
            items,
            notes
        } = this.state;

        if (empty(items)) {
            message.error("Barang harus dipilih");
        } else {
            this.setState({ formLoading: true });
            apiCall(
                endpoint.POST_INVENTORY_IN_FROM_SUPPLIER,
                "post",
                {
                    local_trx_id: local_trx_id,
                    document_no: document_no,
                    trx_type: sourceType,
                    inv_vendor_id,
                    purchase_order_number,
                    invoice_number,
                    inv_sloc_id: inv_sloc_id,
                    notes: notes,
                    items: items.map(item => {
                        return {
                            uuid: uuid(),
                            pos_item_variant_id: item.key,
                            inv_unit_id: item.inv_unit_id,
                            qty: item.qty,
                            bad_qty: item.bad_qty,
                            disc_percent: item.disc_percent,
                            price: item.price,
                        };
                    })
                },
                (res) => {
                    message.success("Barang berhasil diterima.");
                    this.setState({
                        ...this.initialState,
                        local_trx_id: uuid()
                    }, () => {
                        this.fetchItem("");
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }, () => {
                    this.setState({ formLoading: false });
                }
            );
        }
    };

    render() {
        const {
            inv_vendor_id,
            purchase_order_number,
            invoice_number,
            inv_sloc_id,
            selectedItem,
            fetchingItems,
            fetchedItems,
            items,
            suppliers,
            currentStockQty,
            currentBadStockQty,
            qty,
            bad_qty,
            itemDiscPercent,
            itemPriceText,
            notes,
            document_no,
            formLoading
        } = this.state;
        const { posConfig } = this.props;

        let itemColumns;
        if (posConfig.input_price_on_stock_in === 1) {
            itemColumns = [
                {
                    title: "Nama Barang",
                    dataIndex: "item_name",
                    key: "item_name"
                },
                {
                    title: "Qty Baik Saat Ini",
                    dataIndex: "qty_now",
                    key: "qty_now"
                },
                {
                    title: "Qty Baik",
                    dataIndex: "qty",
                    key: "qty"
                },
                {
                    title: "Qty Buruk Saat Ini",
                    dataIndex: "bad_qty_now",
                    key: "bad_qty_now"
                },
                {
                    title: "Qty Buruk",
                    dataIndex: "bad_qty",
                    key: "bad_qty"
                },
                {
                    title: "Unit",
                    dataIndex: "unit_name",
                    key: "unit_name"
                },
                {
                    title: "Discount",
                    dataIndex: "disc_percent",
                    key: "disc_percent",
                    render: (value, row, index) => {
                        return `${number_format(value, 2)}%`;
                    }
                },
                {
                    title: "Harga",
                    dataIndex: "price",
                    key: "price",
                    render: (value, row, index) => {
                        return `${this.currencyCode} ${number_format(Math.floor(value), 0, this.decimalSeparator, this.thousandSeparator)}`;
                    }
                },
                {
                    title: "Action",
                    key: "action",
                    render: (text, record) => (
                        <span>
                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    this.deleteItem(record.key);
                                }}>Delete</Button>
                    </span>
                    )
                }
            ];
        } else {
            itemColumns = [
                {
                    title: "Nama Barang",
                    dataIndex: "item_name",
                    key: "item_name"
                },
                {
                    title: "Qty Baik Saat Ini",
                    dataIndex: "qty_now",
                    key: "qty_now"
                },
                {
                    title: "Qty Baik",
                    dataIndex: "qty",
                    key: "qty"
                },
                {
                    title: "Qty Buruk Saat Ini",
                    dataIndex: "bad_qty_now",
                    key: "bad_qty_now"
                },
                {
                    title: "Qty Buruk",
                    dataIndex: "bad_qty",
                    key: "bad_qty"
                },
                {
                    title: "Unit",
                    dataIndex: "unit_name",
                    key: "unit_name"
                },
                {
                    title: "Action",
                    key: "action",
                    render: (text, record) => (
                        <span>
                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    this.deleteItem(record.key);
                                }}>Delete</Button>
                    </span>
                    )
                }
            ];
        }

        const pageTitle = (
            <React.Fragment>
                <Icon type="gold"/>
                &nbsp;&nbsp; Inventory - Stok Masuk
            </React.Fragment>
        );
        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={12} sm={24}>
                        <Spin spinning={formLoading}>
                            <Form onSubmit={this.onFormSubmit}>
                                {/*<div className={styles.formItem}>
                                    <label>Sumber Barang</label>
                                    <Radio.Group
                                        defaultValue={INV_SRC_TYPE_FROM_SUPPLIER}
                                        onChange={e => {
                                            this.setState({
                                                sourceType: e.target.value
                                            });
                                        }}
                                        buttonStyle="solid">
                                        <Radio.Button value={INV_SRC_TYPE_FROM_SUPPLIER}>Dari Supplier</Radio.Button>
                                        <Radio.Button value={INV_SRC_TYPE_FROM_BAD_STOCK}>Dari Stok Rusak</Radio.Button>
                                    </Radio.Group>
                                </div>*/}

                                <div className={styles.formItem}>
                                    <label>Vendor</label>
                                    {!empty(suppliers) && (
                                        <Select
                                            showSearch={true}
                                            placeholder="Pilih Vendor"
                                            optionFilterProp="children"
                                            value={inv_vendor_id}
                                            onChange={e => {
                                                this.setState({ inv_vendor_id: e });
                                            }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {suppliers.map(row => {
                                                return <Select.Option key={`Vendor${row.inv_vendor_id}`} value={row.inv_vendor_id}>{row.vendor_name} {row.vendor_last_name}</Select.Option>;
                                            })}

                                        </Select>
                                    )}
                                </div>

                                {posConfig.myob === 1 && (
                                    <>
                                        <div className={styles.formItem}>
                                            <label>PO Number</label>
                                            <Input
                                                type="text"
                                                value={purchase_order_number}
                                                onChange={e => {
                                                    this.setState({ purchase_order_number: e.target.value });
                                                }}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Invoice Number</label>
                                            <Input
                                                type="text"
                                                value={invoice_number}
                                                onChange={e => {
                                                    this.setState({ invoice_number: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className={styles.formItem}>
                                    <label>Document No.</label>
                                    <Input
                                        type="text"
                                        value={document_no}
                                        onChange={e => {
                                            this.setState({ document_no: e.target.value });
                                        }}
                                    />
                                </div>

                                <React.Fragment>
                                    <div className={styles.formItem}>
                                        <label>Lokasi Stok Tujuan</label>
                                        {!empty(posConfig.storage_locations) && (
                                            <Select
                                                showSearch={true}
                                                placeholder="Pilih Lokasi Stok Tujuan"
                                                optionFilterProp="children"
                                                value={inv_sloc_id}
                                                onChange={e => {
                                                    this.setState({ inv_sloc_id: e });
                                                }}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {posConfig.storage_locations.map(row => {
                                                    return <Select.Option key={`StorageLocation${row.inv_sloc_id}`} value={row.inv_sloc_id}>{row.location_name}</Select.Option>;
                                                })}

                                            </Select>
                                        )}
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Catatan / Keterangan</label>
                                        <Input.TextArea
                                            type="text"
                                            rows={3}
                                            onChange={e => {
                                                this.setState({ notes: e.target.value });
                                            }}
                                            value={notes}
                                        />
                                    </div>

                                    <h2>Detail Barang Masuk</h2>

                                    <div className={styles.formItem}>
                                        <Table columns={itemColumns} dataSource={items} pagination={false}/>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Tambah Barang</label>
                                        {posConfig.pos_item_search_by_sku === 1 && (
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                <tr>
                                                    <td style={{ width: "50%" }}>
                                                        <Input
                                                            placeholder="SKU produk"
                                                            onChange={
                                                                this.onChangeItemSKU
                                                            }
                                                            onKeyDown={this.handleSkuEnterPressed}
                                                            value={
                                                                this.state.itemSku
                                                            }
                                                            addonBefore={<Icon type="barcode"/>}
                                                            ref={this.itemSku}
                                                            autoFocus
                                                        />
                                                    </td>
                                                    <td style={{ width: "50%" }}>
                                                        <Select
                                                            showSearch={true}
                                                            placeholder="Pilih Barang"
                                                            notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                            filterOption={false}
                                                            onSearch={this.fetchItem}
                                                            onChange={e => {
                                                                this.setState({
                                                                    selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                                }, this.loadSelectedItemDetails);
                                                            }}
                                                            style={{ width: "100%" }}
                                                            value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                                        >
                                                            {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                                        </Select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}

                                        {posConfig.pos_item_search_by_sku !== 1 && (
                                            <Select
                                                showSearch={true}
                                                placeholder="Pilih Barang"
                                                notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                filterOption={false}
                                                onSearch={this.fetchItem}
                                                onChange={e => {
                                                    this.setState({
                                                        selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                    }, this.loadSelectedItemDetails);
                                                }}
                                                style={{ width: "100%" }}
                                                value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                            >
                                                {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                            </Select>
                                        )}
                                    </div>

                                    {!empty(selectedItem) && (
                                        <React.Fragment>
                                            <div className={styles.formItem}>
                                                <label>Stok Baik Saat Ini ({selectedItem.unit_name})</label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={currentStockQty}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Qty Barang Diterima Kondisi Baik ({selectedItem.unit_name})</label>
                                                <Input
                                                    type="text"
                                                    onChange={e => {
                                                        this.setState({ qty: e.target.value });
                                                    }}
                                                    value={qty}
                                                    ref={this.goodItemQty}
                                                    onFocus={e => {
                                                        e.target.select();
                                                    }}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Stok Buruk Saat Ini ({selectedItem.unit_name})</label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={currentBadStockQty}
                                                />
                                            </div>

                                            <div className={styles.formItem}>
                                                <label>Qty Barang Diterima Kondisi Buruk ({selectedItem.unit_name})</label>
                                                <Input
                                                    type="text"
                                                    onChange={e => {
                                                        this.setState({ bad_qty: e.target.value });
                                                    }}
                                                    value={bad_qty}
                                                />
                                            </div>

                                            {posConfig.input_price_on_stock_in === 1 && (
                                                <React.Fragment>
                                                    <div className={styles.formItem}>
                                                        <label>Discount (%)</label>
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            defaultValue={0}
                                                            step={0.1}
                                                            precision={2}
                                                            placeholder="%"
                                                            style={{ width: "100%", marginTop: 10 }}
                                                            onChange={e => {
                                                                this.setState({
                                                                    itemDiscPercent: e
                                                                });
                                                            }}
                                                            value={itemDiscPercent}
                                                        />
                                                    </div>

                                                    <div className={styles.formItem}>
                                                        <label>Harga</label>
                                                        <Input
                                                            defaultValue={0}
                                                            type="text"
                                                            addonBefore={this.currencyCode}
                                                            size="large"
                                                            style={{ width: "100%", marginTop: 10 }}
                                                            onChange={e => {
                                                                const inputCallback = (value, valueText) => {
                                                                    this.setState({
                                                                        itemPrice: value,
                                                                        itemPriceText: valueText
                                                                    });
                                                                };
                                                                processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                            }}
                                                            value={itemPriceText}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            <div className={styles.formItem}>
                                                <Button
                                                    type="primary"
                                                    size="default"
                                                    block={false}
                                                    htmlType="button"
                                                    disabled={empty(selectedItem)}
                                                    onClick={this.addItem}
                                                >
                                                    Masukkan ke dalam Daftar
                                                </Button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>

                                <Button
                                    style={{ marginTop: 50 }}
                                    type="primary"
                                    size="large"
                                    block={false}
                                    htmlType="submit"
                                >
                                    Terima Barang
                                </Button>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryStockIn);
