import React, { Component } from "react";
import { Button, Card, Col, DatePicker, Icon, Modal, Row, Spin } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./Appointment.module.scss";
import connect from "react-redux/es/connect/connect";
import { padLeft } from "../shared/helpers/stringHelper";
import { posOperations } from "../state/ducks/pos";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT, MOMENT_SQL_TIME_FORMAT_MINUTE } from "../shared/utils/constants";
import moment from "moment";
import { empty } from "../shared/helpers/generalHelper";
import { REST_APPOINTMENT } from "../shared/utils/endpoints";

class Appointment extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            appointments: [],
            heightPerMinute: 2,
            currentDate: moment().format(MOMENT_SQL_DATE_FORMAT),
            currentTime: moment().format(MOMENT_SQL_DATETIME_FORMAT)
        };

        this.state = { ...this.initialState };

        this.appointmentTimelineRef = React.createRef();
    }

    componentDidMount() {
        this.loadAppointments();
        setTimeout(this.setTimelineScrollOffset, 1000);
    }

    setTimelineScrollOffset = () => {
        const currentTimeMoment = moment(this.state.currentTime, MOMENT_SQL_DATETIME_FORMAT);
        const currentHourOffset = parseInt(currentTimeMoment.format('HH')) - 1;
        const currentMinuteOffset = (currentHourOffset * 60) + parseInt(currentTimeMoment.format('mm'));
        this.appointmentTimelineRef.current.scrollTop = (currentMinuteOffset * this.state.heightPerMinute);
    }

    loadAppointments = () => {
        this.setState({ formLoading: true });

        apiCall(
            endpoint.REST_APPOINTMENT + `?start_date=${this.state.currentDate}&end_date=${this.state.currentDate}`,
            "get",
            null,
            result => {
                this.setState({
                    appointments: result.data.customer_appointments
                });
            },
            error => {
                ErrorHandler.handleGeneralError(error);
            },
            res => {
                this.setState({ formLoading: false });
            }
        );
    };

    renderTimePointer = () => {
        let timePointers = [(
            <div key={`timePointerColHead`} className={styles.timePointerColHead}>
                &nbsp;
            </div>
        )];
        for (let i = 0; i <= 23; i++) {
            timePointers.push((
                <div key={`${padLeft(i, 2)}.00`}
                     className={styles.timePointerCell}
                     style={{height: (4*30) + 1}}
                >
                    {padLeft(i, 2)}.00
                </div>
            ));
        }
        return timePointers;
    };

    renderWorkerCol = (workers) => {
        const {appointments, currentDate, heightPerMinute} = this.state;

        return workers.map(worker => {
            let workerCells = [];
            const workerAppointments = appointments.filter(appointment => {
                return appointment.appointment_date === currentDate &&
                    appointment.business_commission_worker_id === worker.business_commission_worker_id
            });

            for (let i = 0; i <= 23; i++) {
                const currentHourAppointment = workerAppointments.filter(appointment => {
                    const startTimeHour = moment(appointment.start_time, MOMENT_SQL_DATETIME_FORMAT).format('H');
                    return startTimeHour === i.toString();
                });

                const currentHour = moment().format('H');
                let isPast = false;
                if(moment(currentDate, MOMENT_SQL_DATE_FORMAT).isBefore(moment(), "day") || (moment(currentDate, MOMENT_SQL_DATE_FORMAT).isSame(moment(), "day") && parseInt(currentHour) > i) ){
                    isPast = true;
                }

                workerCells.push((
                    <div key={`${worker.business_commission_worker_id}ScheduleCell${i}`}
                         className={`${styles.appointmentScheduleCell}`}
                         style={{height: 'auto'}}
                    >
                        <div className={styles.appointmentScheduleSubCell}
                             style={{
                                 height: 30,
                                 lineHeight: '30px',
                                 background: isPast ? '#F8F8F8' : 'transparent',
                                 cursor: isPast ? 'default' : 'pointer'
                             }}
                             onClick={() => {
                                 this.newAppointment(worker.business_commission_worker_id, currentDate, `${i}:00`);
                             }}
                        >
                            <span>{padLeft(i, 2)}.00</span>
                        </div>
                        <div className={styles.appointmentScheduleSubCell}
                             style={{
                                 height: 30,
                                 lineHeight: '30px',
                                 background: isPast ? '#F8F8F8' : 'transparent',
                                 cursor: isPast ? 'default' : 'pointer'
                             }}
                             onClick={() => {
                                 this.newAppointment(worker.business_commission_worker_id, currentDate, `${i}:15`);
                             }}
                        >
                            <span>{padLeft(i, 2)}.15</span>
                        </div>
                        <div className={styles.appointmentScheduleSubCell}
                             style={{
                                 height: 30,
                                 lineHeight: '30px',
                                 background: isPast ? '#F8F8F8' : 'transparent',
                                 cursor: isPast ? 'default' : 'pointer'
                             }}
                             onClick={() => {
                                 this.newAppointment(worker.business_commission_worker_id, currentDate, `${i}:30`);
                             }}
                        >
                            <span>{padLeft(i, 2)}.30</span>
                        </div>
                        <div className={styles.appointmentScheduleSubCell}
                             style={{
                                 height: 30,
                                 lineHeight: '30px',
                                 background: isPast ? '#F8F8F8' : 'transparent',
                                 cursor: isPast ? 'default' : 'pointer'
                             }}
                             onClick={() => {
                                 this.newAppointment(worker.business_commission_worker_id, currentDate, `${i}:45`);
                             }}
                        >
                            <span>{padLeft(i, 2)}.45</span>
                        </div>
                        {!empty(currentHourAppointment) && currentHourAppointment.map(appointment => {
                            const startTimeMoment = moment(appointment.start_time, MOMENT_SQL_DATETIME_FORMAT);
                            const endTimeMoment = moment(appointment.end_time, MOMENT_SQL_DATETIME_FORMAT);
                            const appointmentMinutes = endTimeMoment.diff(startTimeMoment, 'm');
                            const offsetMinutes = parseInt(startTimeMoment.format('mm'));

                            let products = [];
                            if(!empty(appointment.pos_trx) && !empty(appointment.pos_trx.details)){
                                products = appointment.pos_trx.details.map(detail => {
                                    return detail.itemMeta.item_name;
                                });
                            }

                            return (
                                <div className={styles.appointmentCard}
                                     key={appointment.local_appointment_id}
                                     style={{
                                         top: offsetMinutes * heightPerMinute,
                                         height: heightPerMinute * appointmentMinutes
                                     }}
                                >
                                    <div style={{position: 'relative'}}>
                                        {startTimeMoment.format(MOMENT_SQL_TIME_FORMAT_MINUTE)} - {endTimeMoment.format(MOMENT_SQL_TIME_FORMAT_MINUTE)}
                                        <br/>
                                        {appointment.user_name}
                                        <br/>
                                        {products.join(', ')}
                                        <br/>

                                        <div className={styles.appointmentCardActions}>
                                            <Button type="link"
                                                    onClick={() => {this.editAppointment(appointment.local_appointment_id)}}
                                                    style={{padding: 0}}
                                                    className={styles.editAppointmentBtn}
                                            ><i className="fa fa-edit" />&nbsp;Ubah</Button>

                                            <Button type="link"
                                                    onClick={() => {this.deleteAppointment(appointment.local_appointment_id)}}
                                                    className={styles.deleteAppointmentBtn}
                                                    style={{
                                                        padding: 0
                                                    }}
                                            ><i className="fa fa-trash" />&nbsp;Hapus</Button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ));
            }
            return (
                <div key={`${worker.business_commission_worker_id}ScheduleCol`}
                     className={styles.appointmentScheduleCol}
                >
                    <div key={`${worker.business_commission_worker_id}ScheduleHead`} className={styles.colHead}>
                        {`${worker.firstname} ${worker.lastname}`}
                    </div>
                    {workerCells}
                </div>
            );
        });
    };

    goToAppointmentSales = (local_trx_id) => {
        this.props.switchCurrentTrx(local_trx_id);
        this.props.history.push("/pos");
    };

    editAppointment = (local_appointment_id) => {
        this.props.history.push(`/appointment/${local_appointment_id}`);
    }

    deleteAppointment = (local_appointment_id) => {
        Modal.confirm({
            title: "Hapus Appointment",
            content: "Apakah Anda ingin menghapus appointment ini?",
            onOk: () => {
                this.setState({formLoading:true});
                apiCall(
                    REST_APPOINTMENT + `/${local_appointment_id}`,
                    'delete',
                    null,
                    res => {
                        const appointment = this.state.appointments.find(appointment => appointment.local_appointment_id === local_appointment_id);
                        if(!empty(appointment)){
                            if(!empty(appointment.pos_local_trx_id)){
                                this.props.deleteTrx(appointment.pos_local_trx_id);
                            }

                            this.props.deleteAppointment(local_appointment_id);
                            this.setState({
                                appointments: this.state.appointments.filter(appointment => appointment.local_appointment_id !== local_appointment_id)
                            });
                        }

                    },
                    err => {

                    },
                    res => {
                        this.setState({formLoading:false});
                    }
                )
            },
            onCancel: () => {
            }
        });
    }

    newAppointment = (business_commission_worker_id, appointment_date, start_time) => {
        this.props.history.push(`/appointment/new?worker_id=${business_commission_worker_id}&appointment_date=${appointment_date}&start_time=${start_time}`);
    };

    onChangeCurrentDate = (date, dateString) => {
        this.setState({
            currentDate: dateString
        }, () => {
            this.loadAppointments();
        });
    };

    render() {
        const { formLoading, currentDate } = this.state;
        const { configs } = this.props;

        const pageTitle = (
            <React.Fragment>
                <Icon type="clock-circle"/>
                &nbsp;&nbsp; Appointment
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row>
                    <Col>
                        <Spin spinning={formLoading}>
                            <div className={styles.appointmentControls}>
                                <div className={styles.formItem}>
                                    <label>Tanggal</label>
                                    <DatePicker value={moment(currentDate, MOMENT_SQL_DATE_FORMAT)}
                                                onChange={this.onChangeCurrentDate}
                                                allowClear={false}
                                    />
                                </div>
                            </div>
                            <div className={styles.appointmentWrapper} ref={this.appointmentTimelineRef}>
                                {/*<div className={styles.timePointerColClone}>
                                    {this.renderTimePointer()}
                                </div>*/}

                                <div className={styles.appointmentSchedule}>
                                    <div className={styles.timePointerCol}>
                                        {this.renderTimePointer()}
                                    </div>

                                    {this.renderWorkerCol(configs.commission_worker)}
                                </div>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        configs: state.authState.userData,
        posTrxs: state.posState.posTrx,
    };
};

const mapDispatchToProps = {
    setNewLocalTrx: posOperations.setNewLocalTrx,
    switchCurrentTrx: posOperations.switchCurrentTrx,
    deleteTrx: posOperations.deleteTrx,
    deleteAppointment: posOperations.deleteAppointment
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appointment);