import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { empty } from "../../helpers/generalHelper";
import styles from "./PosHeader.module.scss";

const PosHeader = props => {
    return (
        <div className={styles.header}>
            {!empty(props.backBtnAction) && (
            <div className={styles.backButton} onClick={props.backBtnAction}>
                <Icon type="left" theme="outlined" /> Back
            </div>
            )}
            <div className={styles.pageTitle}>{props.pageTitle}</div>
            {!empty(props.menus) && (
                <div className={styles.pageUpperRightMenu}>{props.menus}</div>
            )}
        </div>
    );
};

PosHeader.propTypes = {
    pageTitle: PropTypes.string,
    menus: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
    backBtnAction: PropTypes.func
};

PosHeader.defaultProps = {
    pageTitle: "",
    menus: null
};

export default PosHeader;
