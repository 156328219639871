import React, { Component } from "react";
import { empty } from "../shared/helpers/generalHelper";
import moment from "moment";
import { MOMENT_READABLE_DATE_FORMAT, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import { apiCall } from "../shared/helpers/networkHelper";
import { GET_REPORT_COUPON_EXPIRED } from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { Col, Empty, Row, Spin, Table } from "antd";
import { number_format } from "../shared/helpers/stringHelper";
import { connect } from "react-redux";

class ReportCouponPrint extends Component {

    constructor(props) {
        super(props);

        const url = new URL(window.location.href);

        this.state = {
            loading: false,
            coupons: null,
            reportDateFrom: url.searchParams.get('start_date'),
            reportDateTo: url.searchParams.get('end_date'),
            cashiers: []
        };
    }

    componentDidMount() {
        const { reportDateFrom, reportDateTo } = this.state;
        const cashiers = JSON.parse(localStorage.getItem('sign_history'));

        this.loaderShow();
        apiCall(
            GET_REPORT_COUPON_EXPIRED + `?start_date=${reportDateFrom}&end_date=${reportDateTo}`,
            "get",
            null,
            res => {
                this.setState({
                    coupons: res.data,
                    cashiers: cashiers
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }, res => {
                this.loaderHide();
            }
        );
    }

    loaderShow = () => {
        this.setState({loading: true});
    };

    loaderHide = () => {
        this.setState({loading: false});
    };

    renderConsumptionTable = (coupons) => {
        let mappedCoupons = coupons.map((coupon, index) => {
            return {
                no: index+1,
                ...coupon
            }
        });
        mappedCoupons = mappedCoupons.concat({
            ...mappedCoupons[0],
            fake: true
        });

        const columns = [
            {
                title: "No",
                dataIndex: "no",
                key: "no",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Nomor HP",
                dataIndex: "drv_primary_phone",
                key: "drv_primary_phone",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Nama Pelanggan",
                dataIndex: "name",
                key: "name",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Nama Kupon",
                dataIndex: "coupon_name",
                key: "coupon_name",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Catatan Kupon",
                dataIndex: "coupon_notes",
                key: "coupon_notes",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Outlet Asal",
                dataIndex: "outlet_name",
                key: "outlet_name",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Qty",
                dataIndex: "qty",
                key: "qty",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        const sumReducer = (accumulator, currentValue) => parseInt(currentValue.qty) + accumulator;
                        return <strong>x{number_format(mappedCoupons.filter(coupon => !coupon.fake).reduce(sumReducer, 0), 0)}</strong>;
                    }
                    return `x${number_format(value, 0)}`;
                }
            },
            {
                title: "Tanggal Kadaluwarsa",
                dataIndex: "original_end_date",
                key: "original_end_date",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "Sisa Masa Aktif",
                dataIndex: "num_of_days",
                key: "num_of_days",
                render: (value, row, index) => {
                    if(index === (mappedCoupons.length - 1)){
                        return ``;
                    }
                    return value;
                }
            },
            {
                title: "",
                dataIndex: "drv_primary_phone",
                key: "drv_primary_phone",
                render: (value, row, index) => {
                    return (<a target="_blank" rel="noopener noreferrer" href={`https://wa.me/62${value}`}>Open WhatsApp</a>);
                }
            }

        ];

        return (
            <div style={{ margin: "50px 0" }}>
                <Table size="small"
                       tableLayout="auto"
                       rowKey="coupon_user_id"
                       columns={columns}
                       dataSource={mappedCoupons}
                       style={{maxWidth: 1000}}
                       pagination={false}/>
            </div>
        );
    };

    render() {
        const { loading, coupons, reportDateFrom, reportDateTo} = this.state;

        return (
            <Spin spinning={loading}>
            <Row>
                <Col>
                    <div style={{ padding: 10 }}>
                        <h1>Kupon Expired</h1>
                        <table>
                            <tbody>
                            <tr>
                                <td>Outlet</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.brand_name} - {this.props.posConfig.outlet_name}</td>
                            </tr>
                            <tr>
                                <td>Kasir</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{this.props.posConfig.cashier_name}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment(reportDateFrom, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)} - {moment(reportDateTo, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}</td>
                            </tr>
                            <tr>
                                <td>Waktu Pembuatan Laporan</td>
                                <td style={{width: 15, textAlign: 'center'}}>:</td>
                                <td>{moment().format(MOMENT_READABLE_DATETIME_FORMAT)}</td>
                            </tr>
                            </tbody>
                        </table>

                        {empty(coupons) && <Empty style={{maxWidth: 500, margin: '100px 0'}} />}

                        {!empty(coupons) && this.renderConsumptionTable(coupons)}
                    </div>
                </Col>
            </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

export default connect(mapStateToProps)(ReportCouponPrint);
