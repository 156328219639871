import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Divider, Icon, message } from "antd";
import uuid from "uuid/v4";
import { posOperations } from "../../state/ducks/pos";
import { empty } from "../../shared/helpers/generalHelper";
import { nl2brReact, number_format } from "../../shared/helpers/stringHelper";
import styles from "./PosPriceList.module.scss";
import PosItemModal from "../PosItemModal/PosItemModal";
import PosPriceCalculator from "../../shared/classes/PosPriceCalculator";
import PropTypes from "prop-types";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATETIME_FORMAT, PAYMENT_TYPE_DEPOSIT, RECEIPT_TEMPLATE_DEFAULT, RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END, TRX_TYPE_COUPON_SALES, TRX_TYPE_NORMAL, TRX_TYPE_TOP_UP_DEPOSIT } from "../../shared/utils/constants";
import PosCustomItemModal from "../PosCustomItemModal/PosCustomItemModal";
import DeleteTrxItemPasswordModal from "../../shared/components/DeleteTrxItemPasswordModal";

class PosPriceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalItemOpened: false,
            modalItem: null,
            modalItemTrxDetail: null,
            modalCustomItemOpened: false,
            modalCustomItem: null,
            modalCustomItemTrxDetail: null,
            printSize: !empty(this.props.configs.printerPaperSize) ? this.props.configs.printerPaperSize : "8cm",
            displayMode: !empty(this.props.configs.displayMode) ? this.props.configs.displayMode : "DESKTOP",

            deleteTrxItemModalOpened: false,
            deleteTrxItemLocalTrxId: null,
            deleteTrxItemLocalTrxDetailId: null,
            deleteTrxItem: null
        };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    static propTypes = {
        trx: PropTypes.object,
        itemEditable: PropTypes.bool,
        preSettlement: PropTypes.bool,
        orderPreview: PropTypes.bool,
        orderPreviewUnprinted: PropTypes.bool,
        copy: PropTypes.bool,
        isLegit: PropTypes.bool,
        showSecretNotes: PropTypes.bool,
        showStockOut: PropTypes.bool,
        mobilePrintable: PropTypes.bool,
        mobilePrintCloseable: PropTypes.bool,
        postEditMode: PropTypes.bool,
        postEditSessionId: PropTypes.string,
        postEditOnSubmit: PropTypes.func,
        style: PropTypes.object
    };

    static defaultProps = {
        itemEditable: true,
        preSettlement: false,
        orderPreview: false,
        orderPreviewUnprinted: false,
        copy: false,
        isLegit: false,
        showSecretNotes: false,
        showStockOut: false,
        mobilePrintable: false,
        mobilePrintCloseable: false,
        postEditMode: false,
        postEditSessionId: '',
        postEditOnSubmit: () => {},
        style: {}
    };

    setComponentState = newState => {
        this.setState(newState);
    };

    removeItem = local_trx_detail_id => {
        const trxDetail = this.props.trx.details.find(detail => detail.local_trx_detail_id === local_trx_detail_id);
        if(!empty(trxDetail)){
            if(!empty(trxDetail.coupon)){
                alert('Barang ini dapat dihapus dengan menghapus kupon yang sedang digunakan.');
            }else if(!empty(trxDetail.redeem)) {
                alert('Barang ini dapat dihapus dengan menghapus redeem yang sedang digunakan.');
            }else{
                if (this.props.posConfig.pos_cashier_delete_by_pin === 1) {
                    this.setState({
                        deleteTrxItemModalOpened: true,
                        deleteTrxItemLocalTrxId: this.props.trx.local_trx_id,
                        deleteTrxItemLocalTrxDetailId: local_trx_detail_id,
                        deleteTrxItem: trxDetail
                    });
                } else {
                    this.removeItemAction(local_trx_detail_id, trxDetail);
                }
            }
        }
    };

    removeItemAction = (local_trx_detail_id, deletedDetail) => {
        const { posConfig } = this.props;

        this.props.deleteTrxDetail(
            this.props.trx.local_trx_id,
            local_trx_detail_id,
            deletedDetail
        );

        if(posConfig.auth_role_accesses.DISCOUNT_TRANSACTION === 0){
            this.props.setTrxProps(this.props.trx.local_trx_id, {
                discounts: []
            });
        }

        message.success("Item successfully removed");
    }

    onDeleteGlobalDiscount = local_trx_id => {
        this.props.setTrxProps(local_trx_id, {
            discounts: []
        });
    };

    onDeleteMemberDiscount = local_trx_id => {
        const { trx } = this.props;
        this.props.setTrxProps(local_trx_id, {
            customer: {
                ...trx.customer,
                discount_level: 0
            }
        });
    };

    onEditDetailClicked = detail => {
        if (this.props.itemEditable) {
            if (detail.itemMeta.is_custom === 1) {
                this.setComponentState({
                    modalCustomItemOpened: true,
                    modalCustomItem: detail.itemMeta,
                    modalCustomItemTrxDetail: detail
                });
            } else {
                this.setComponentState({
                    modalItemOpened: true,
                    modalItem: detail.itemMeta,
                    modalItemTrxDetail: detail
                });
            }
        }
    };

    renderTrxDetail = (trx, detail, isOrderPreview = false) => {
        const { posConfig } = this.props;
        const currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;

        const pos_item_variant = detail.itemMeta.pos_item_variants.find(
            variant =>
                variant.pos_item_variant_id === detail.pos_item_variant_id
        );
        const formattedItemPrice =
            number_format(parseInt(pos_item_variant.variant_price), 0) + ` ${currencyCode}`;
        const itemNotes = detail.notes;
        const secretNotes = detail.secret_notes;
        const itemCommissionWorker = detail.commission_worker;
        const itemCommissionWorkers = detail.commission_workers;
        const itemSellers = detail.item_sellers;
        const isPackage = detail.is_package;
        const packageDetails = detail.pos_item_package_details;
        const itemDisc = detail.pos_item_discounts;
        const customDisc = {
            custom_discount_type: detail.custom_discount_type,
            custom_discount_value: detail.custom_discount_value
        };
        let modifierPrice = 0;
        if (!empty(detail.pos_item_pos_item_modifiers)) {
            detail.pos_item_pos_item_modifiers.map(modifier => {
                modifier.pos_item_modifier_options.map(option => {
                    modifierPrice += parseInt(option.option_price);
                    return option;
                });
                return modifier;
            });
        }
        const item_price_with_modifier = parseInt(pos_item_variant.variant_price) + modifierPrice < 0 ? 0 : parseInt(pos_item_variant.variant_price) + modifierPrice;
        const calculatedSubTotal = PosPriceCalculator.calculateItemPrice(
            trx,
            detail,
            currencyCode
        ).totalPriceValue;
        let formattedSubTotal =
            number_format(calculatedSubTotal, 0) + ` ${currencyCode}`;

        if (posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END) {
            formattedSubTotal = number_format(PosPriceCalculator.calculateGrossItemPrice(trx, detail).totalPriceValue, 0) + ` ${currencyCode}`;
        }

        let couponDisc = "";
        if (!empty(trx.coupon)) {
            if (
                (trx.coupon.coupon_item.pos_link_type === "item" &&
                    trx.coupon.coupon_item.pos_item_id ===
                    detail.itemMeta.pos_item_id) ||
                (trx.coupon.coupon_item.pos_link_type === "item_category" &&
                    trx.coupon.coupon_item.pos_item_category_id ===
                    detail.itemMeta.pos_item_category_id)
            ) {
                const couponDiscTotal = PosPriceCalculator.calculateCouponItem(
                    detail,
                    trx,
                    currencyCode
                );
                if (couponDiscTotal.totalDisc > 0) {
                    couponDisc = couponDiscTotal.formattedDisc;
                }
            }
        }

        let redeemDisc = "";
        if (!empty(trx.redeem)) {
            if (
                (trx.redeem.redeem_item.pos_link_type === "item" &&
                    trx.redeem.redeem_item.pos_item_id ===
                    detail.itemMeta.pos_item_id) ||
                (trx.redeem.redeem_item.pos_link_type === "item_category" &&
                    trx.redeem.redeem_item.pos_item_category_id ===
                    detail.itemMeta.pos_item_category_id)
            ) {
                const redeemDiscTotal = PosPriceCalculator.calculateRedeemItem(
                    detail,
                    trx,
                    currencyCode
                );
                if (redeemDiscTotal.totalDisc > 0) {
                    redeemDisc = redeemDiscTotal.formattedDisc;
                }
            }
        }

        let drv_discount_type = "";
        if (empty(drv_discount_type) && !empty(trx.coupon)) {
            if (
                (trx.coupon.coupon_item.pos_link_type === "item" &&
                    trx.coupon.coupon_item.pos_item_id ===
                    detail.itemMeta.pos_item_id) ||
                (trx.coupon.coupon_item.pos_link_type === "item_category" &&
                    trx.coupon.coupon_item.pos_item_category_id ===
                    detail.itemMeta.pos_item_category_id)
            ) {
                drv_discount_type = "coupon";
            }
        }
        if (empty(drv_discount_type) && !empty(trx.redeem)) {
            if (
                (trx.redeem.redeem_item.pos_link_type === "item" &&
                    trx.redeem.redeem_item.pos_item_id ===
                    detail.itemMeta.pos_item_id) ||
                (trx.redeem.redeem_item.pos_link_type === "item_category" &&
                    trx.redeem.redeem_item.pos_item_category_id ===
                    detail.itemMeta.pos_item_category_id)
            ) {
                drv_discount_type = "redeem";
            }
        }
        if (empty(drv_discount_type) && !empty(itemDisc)) {
            drv_discount_type = "defined";
        }
        if (
            empty(drv_discount_type) &&
            !empty(customDisc.custom_discount_value)
        ) {
            drv_discount_type = "custom";
        }

        if (!this.props.isPrint) {
            return (
                <React.Fragment key={detail.local_trx_detail_id}>
                    <tr className={styles.itemRow}>
                        <td
                            className={styles.itemName}
                            onClick={() => {
                                this.onEditDetailClicked(detail);
                            }}
                        >
                            {detail.itemMeta.item_name}
                            {!empty(trx.account_receivable) ? (
                                <React.Fragment>
                                    <br/>
                                    Transaksi {trx.account_receivable.local_trx_id.toUpperCase().substr(-6).toUpperCase()}<br/>
                                    {trx.account_receivable.trx_id}<br/>
                                    {moment(trx.account_receivable.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                </React.Fragment>
                            ) : ""}
                        </td>
                        <td
                            className={styles.itemQty}
                            onClick={() => {
                                this.onEditDetailClicked(detail);
                            }}
                        >
                            x{detail.qty}{/*{number_format(detail.qty, posConfig.qty_decimal_length)}*/}
                        </td>
                        <td
                            className={styles.itemSubTotal}
                            onClick={() => {
                                this.onEditDetailClicked(detail);
                            }}
                        >
                            {formattedSubTotal}
                        </td>
                        <td className={styles.itemRemove}>
                            {!this.props.postEditMode && (
                            <Icon
                                onClick={() => {
                                    this.removeItem(
                                        detail.local_trx_detail_id
                                    );
                                }}
                                type="close-circle"
                                theme="outlined"
                            />
                            )}
                        </td>
                    </tr>
                    {this.renderItemSub(
                        uuid(),
                        `${pos_item_variant.variant_name} @`,
                        formattedItemPrice
                    )}
                    {!empty(detail.pos_item_pos_item_modifiers) && detail.pos_item_pos_item_modifiers.map(modifier => {
                        return modifier.pos_item_modifier_options.map(option => {
                            return this.renderItemSub(
                                uuid(),
                                `${modifier.modifier_name}: ${option.option_name} @`,
                                number_format(option.option_price, 0) + ` ${currencyCode}`
                            );
                        });
                    })}
                    {drv_discount_type === "coupon" &&
                    this.renderItemSub(
                        uuid(),
                        `Coupon Disc.`,
                        couponDisc
                    )}
                    {drv_discount_type === "redeem" &&
                    this.renderItemSub(
                        uuid(),
                        `Redeem Disc.`,
                        redeemDisc
                    )}
                    {drv_discount_type === "custom" &&
                    this.renderCustomDisc(
                        item_price_with_modifier,
                        customDisc,
                        itemDisc,
                        currencyCode
                    )}
                    {drv_discount_type === "defined" &&
                    this.renderDiscByItem(
                        item_price_with_modifier,
                        itemDisc,
                        currencyCode
                    )}
                    {!empty(itemNotes) &&
                    this.renderItemSub(uuid(), `Notes `, itemNotes)}
                    {!empty(secretNotes) && this.props.showSecretNotes &&
                    this.renderItemSub(uuid(), `S. Notes`, secretNotes)}
                    {!empty(itemCommissionWorker) &&
                    this.renderItemSub(
                        uuid(),
                        `Handled by `,
                        itemCommissionWorker.firstname +
                        (empty(itemCommissionWorker.lastname)
                            ? ""
                            : " " + itemCommissionWorker.lastname)
                    )}
                    {!empty(itemCommissionWorkers) &&
                    itemCommissionWorkers.map(row => {
                        return this.renderItemSub(
                            uuid(),
                            `Handled by `,
                            row.commission_worker.firstname +
                            (empty(row.commission_worker.lastname)
                                ? ""
                                : " " + row.commission_worker.lastname) + " - " + row.percentage + "%"
                        );
                    })}
                    {
                        !empty(itemSellers) &&
                        itemSellers.map(row => {
                            return this.renderItemSub(
                                uuid(),
                                `Sold by `,
                                row.firstname +
                                (empty(row.lastname)
                                    ? ""
                                    : " " + row.lastname)
                            );
                        })
                    }
                    {!empty(detail.service_area) && this.renderItemSub(
                        uuid(),
                        `Service Area `,
                        detail.service_area.area_name
                    )}
                    {isPackage === 1 &&
                    !empty(packageDetails) &&
                    packageDetails.map(packageDetail => {
                        let itemString = "--- ";
                        itemString += `x${packageDetail.qty} ${
                            packageDetail.item_name
                        }`;
                        if (!empty(packageDetail.pos_item_package_detail_variants)) {
                            if(packageDetail.pos_item_package_detail_variants.length > 1){
                                const chosen_variant = packageDetail.pos_item_package_detail_variants.find(
                                    variant => variant.is_chosen === 1
                                );
                                if (!empty(chosen_variant)) {
                                    itemString += ` (${
                                        chosen_variant.variant_name
                                    })`;
                                }
                            }else{
                                itemString += ` (${
                                    packageDetail.pos_item_package_detail_variants[0].variant_name
                                })`;
                            }
                        }

                        if (
                            !empty(
                                packageDetail.business_commission_worker
                            )
                        ) {
                            const chosen_commission_worker = this.props.posConfig.commission_worker.find(
                                worker =>
                                    worker.business_commission_worker_id ===
                                    packageDetail.business_commission_worker.business_commission_worker_id
                            );
                            itemString += ` (By ${
                                chosen_commission_worker.firstname
                            } ${chosen_commission_worker.lastname})`;
                        }

                        if (
                            !empty(
                                packageDetail.commission_workers
                            )
                        ) {
                            packageDetail.commission_workers.map(row => {
                                itemString += ` (By ${
                                    row.commission_worker.firstname
                                } ${row.commission_worker.lastname} - ${row.percentage}%)`;
                                return row;
                            });
                        }

                        if (!empty(packageDetail.service_area)) {
                            itemString += ` (Service Area - ${packageDetail.service_area.area_name})`;
                        }

                        return this.renderItemSub(
                            uuid(),
                            itemString,
                            ""
                        );
                    })}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {this.state.printSize === "6cm" && (
                        <React.Fragment>
                            <tr className={styles.itemRow}>
                                <td className={styles.itemQty6cm}>
                                    x{detail.qty}{/*{number_format(detail.qty, posConfig.qty_decimal_length)}*/}
                                </td>
                                {!this.props.orderPreview && !isOrderPreview && (
                                    <td className={styles.itemSubTotal6cm} colSpan={2}>
                                        {formattedSubTotal}
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td className={styles.itemName6cm} colSpan={3}>
                                    {detail.itemMeta.item_name}

                                    {!empty(trx.account_receivable) ? (
                                        <React.Fragment>
                                            <br/>
                                            Transaksi {trx.account_receivable.local_trx_id.toUpperCase().substr(-6)}<br/>
                                            {trx.account_receivable.trx_id}<br/>
                                            {moment(trx.account_receivable.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                        </React.Fragment>
                                    ) : ""}
                                </td>
                            </tr>
                        </React.Fragment>
                    )}

                    {this.state.printSize === "8cm" && (
                        <tr className={styles.itemRow}>
                            <td className={styles.itemName}>
                                {detail.itemMeta.item_name}
                                {!empty(trx.account_receivable) ? (
                                    <React.Fragment>
                                        <br/>
                                        Transaksi {trx.account_receivable.local_trx_id.toUpperCase().substr(-6)}<br/>
                                        {trx.account_receivable.trx_id}<br/>
                                        {moment(trx.account_receivable.trx_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}
                                    </React.Fragment>
                                ) : ""}
                            </td>
                            {!this.props.orderPreview && !isOrderPreview && (
                                <React.Fragment>
                                    <td className={styles.itemQty}>
                                        x{number_format(detail.qty, posConfig.qty_decimal_length)}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {formattedSubTotal}
                                    </td>
                                </React.Fragment>
                            )}
                            {(this.props.orderPreview || isOrderPreview) && (
                                <React.Fragment>
                                    <td className={styles.itemSubTotal} colSpan={2}>
                                        x{number_format(detail.qty, posConfig.qty_decimal_length)}
                                    </td>
                                </React.Fragment>
                            )}

                        </tr>
                    )}

                    {(this.props.orderPreview || isOrderPreview) && (
                        <React.Fragment>
                            {!empty(pos_item_variant.variant_name) && this.renderItemSubWithoutPrice(
                                uuid(),
                                `Variant: ${pos_item_variant.variant_name}`
                            )}
                            {!empty(detail.pos_item_pos_item_modifiers) && detail.pos_item_pos_item_modifiers.map(modifier => {
                                return modifier.pos_item_modifier_options.map(option => {
                                    return this.renderItemSubWithoutPrice(
                                        uuid(),
                                        `${modifier.modifier_name}: ${option.option_name}`
                                    );
                                });
                            })}
                        </React.Fragment>
                    )}

                    {!this.props.orderPreview && !isOrderPreview && (
                        <React.Fragment>
                            {this.renderItemSub(
                                uuid(),
                                `${pos_item_variant.variant_name} @`,
                                formattedItemPrice
                            )}
                            {!empty(detail.pos_item_pos_item_modifiers) && detail.pos_item_pos_item_modifiers.map(modifier => {
                                return modifier.pos_item_modifier_options.map(option => {
                                    return this.renderItemSub(
                                        uuid(),
                                        `${modifier.modifier_name}: ${option.option_name} @`,
                                        number_format(option.option_price, 0) + ` ${currencyCode}`
                                    );
                                });
                            })}
                            {drv_discount_type === "coupon" &&
                            this.renderItemSub(
                                uuid(),
                                `Coupon Disc.`,
                                couponDisc
                            )}
                            {drv_discount_type === "redeem" &&
                            this.renderItemSub(
                                uuid(),
                                `Redeem Disc.`,
                                redeemDisc
                            )}
                            {drv_discount_type === "custom" &&
                            this.renderCustomDisc(
                                item_price_with_modifier,
                                customDisc,
                                itemDisc,
                                currencyCode
                            )}
                            {drv_discount_type === "defined" &&
                            this.renderDiscByItem(
                                item_price_with_modifier,
                                itemDisc,
                                currencyCode
                            )}
                        </React.Fragment>
                    )}


                    {!empty(itemNotes) &&
                    this.renderItemSub(uuid(), `Notes `, itemNotes)}
                    {!empty(secretNotes) && this.props.showSecretNotes &&
                    this.renderItemSub(uuid(), `S. Notes`, secretNotes)}

                    {(this.props.showWorker || posConfig.pos_print_worker === 1) && !empty(itemCommissionWorker) &&
                    this.renderItemSub(
                        uuid(),
                        `Handled by `,
                        posConfig.pos_print_initial === 1
                            ? itemCommissionWorker.initial
                            : itemCommissionWorker.firstname +
                            (empty(itemCommissionWorker.lastname)
                                ? ""
                                : " " + itemCommissionWorker.lastname)
                    )}
                    {(this.props.showWorker || posConfig.pos_print_worker === 1) && !empty(itemCommissionWorkers) &&
                    itemCommissionWorkers.map(row => {
                        return this.renderItemSub(
                            uuid(),
                            `Handled by `,
                            posConfig.pos_print_initial === 1
                                ? row.commission_worker.initial
                                : row.commission_worker.firstname +
                                (empty(row.commission_worker.lastname)
                                    ? ""
                                    : " " + row.commission_worker.lastname) + " - " + row.percentage + "%"
                        );
                    })}

                    {
                        !empty(itemSellers) &&
                        (this.props.showSeller || posConfig.pos_print_seller === 1) &&
                        itemSellers.map(row => {
                            return this.renderItemSub(
                                uuid(),
                                `Sold by `,
                                row.firstname +
                                (empty(row.lastname)
                                    ? ""
                                    : " " + row.lastname)
                            );
                        })
                    }
                    {!empty(detail.service_area) && this.renderItemSub(
                        uuid(),
                        `Service Area `,
                        detail.service_area.area_name
                    )}

                    {isPackage === 1 &&
                    !empty(packageDetails) &&
                    packageDetails.map(packageDetail => {
                        let itemString = "--- ";
                        itemString += `x${packageDetail.qty} ${
                            packageDetail.item_name
                        }`;

                        if (!empty(packageDetail.pos_item_package_detail_variants)) {
                            if(packageDetail.pos_item_package_detail_variants.length > 1){
                                const chosen_variant = packageDetail.pos_item_package_detail_variants.find(
                                    variant => variant.is_chosen === 1
                                );
                                if (!empty(chosen_variant)) {
                                    itemString += ` (${
                                        chosen_variant.variant_name
                                    })`;
                                }
                            }else{
                                itemString += ` (${
                                    packageDetail.pos_item_package_detail_variants[0].variant_name
                                })`;
                            }
                        }

                        if (
                            !empty(
                                packageDetail.business_commission_worker
                            ) &&
                            (this.props.showWorker || posConfig.pos_print_worker === 1)
                        ) {
                            const chosen_commission_worker = packageDetail.business_commission_worker; /*this.props.posConfig.commission_worker.find(
                                worker =>
                                    worker.business_commission_worker_id ===
                                    packageDetail.business_commission_worker.business_commission_worker_id
                            );*/
                            itemString += posConfig.pos_print_initial === 1
                                ? ` (By ${chosen_commission_worker.initial})`
                                : ` (By ${
                                    chosen_commission_worker.firstname
                                } ${chosen_commission_worker.lastname})`;
                        }

                        if (
                            !empty(
                                packageDetail.commission_workers
                            ) &&
                            (this.props.showWorker || posConfig.pos_print_worker === 1)
                        ) {
                            packageDetail.commission_workers.map(row => {
                                itemString += posConfig.pos_print_initial === 1
                                    ? ` (By ${row.commission_worker.initial})`
                                    : ` (By ${
                                        row.commission_worker.firstname
                                    } ${row.commission_worker.lastname} - ${row.percentage}%)`;
                                return row;
                            });
                        }

                        if (!empty(packageDetail.service_area)) {
                            itemString += ` (Service Area - ${packageDetail.service_area.area_name})`;
                        }

                        return this.renderItemSub(
                            uuid(),
                            itemString,
                            ""
                        );
                    })}
                </React.Fragment>
            );
        }
    }

    renderTrxDetails = (trx, isInventoryItem = null, groupByCategory = false, isOrderPreview = false, onlyUnprinted = false) => {
        const trxDetails = trx.details;

        const filteredTrxDetails = trxDetails
            .filter(row => {
                if (isInventoryItem === null) {
                    return row;
                } else {
                    if (isInventoryItem) {
                        return row.itemMeta.item_nature === "RETAIL";
                    } else {
                        return row.itemMeta.item_nature !== "RETAIL";
                    }
                }
            })
            .filter(row => {
                if(this.props.orderPreviewUnprinted || onlyUnprinted){
                    return row.last_printed_at === null;
                }else{
                    return row;
                }
            });

        let categories = [];
        if(groupByCategory){
            filteredTrxDetails.map(detail => {
                if(!categories.find(cat => cat === detail.itemMeta.category_name)){
                    categories = [...categories, detail.itemMeta.category_name];
                }
                return detail;
            });
        }

        if(!groupByCategory){
            return filteredTrxDetails.map((detail, index) => this.renderTrxDetail(trx, detail, isOrderPreview));
        }else{
            return categories.map(category => {
                const categoryFilteredDetails = filteredTrxDetails.filter(detail => detail.itemMeta?.category_name === category)

                return (
                    <>
                        <td colSpan={2} style={{paddingTop: 10, textTransform: 'uppercase', fontSize: 16, fontWeight: 'bold'}}>{category}</td>
                        {categoryFilteredDetails.map(detail => this.renderTrxDetail(trx, detail, isOrderPreview))}
                    </>
                );
            });
        }
    };

    renderItemSub = (index, label, nominal) => {
        if (!empty(label)) {
            return (
                <tr className={styles.itemSubRow} key={index}>
                    <td colSpan={3} className={styles.itemSub}>
                        {label} {!empty(nominal) ? `(${nominal})` : ""}
                    </td>
                </tr>
            );
        }
    };

    renderItemSubWithoutPrice = (index, label) => {
        if (!empty(label)) {
            return (
                <tr className={styles.itemSubRow} key={index}>
                    <td colSpan={3} className={styles.itemSub}>
                        {label}
                    </td>
                </tr>
            );
        }
    };

    renderDiscByItem = (itemPrice, itemDisc, currencyCode) => {
        return itemDisc.map((disc, index) => {
            const calculatedDisc = PosPriceCalculator.calculateDiscByItem(
                itemPrice,
                disc.deduction_type,
                disc.deduction_value,
                currencyCode,
                disc
            );
            return this.renderItemSub(
                index,
                (empty(disc.discount_name) ? "Discount" : disc.discount_name),
                calculatedDisc.formattedDisc
            );
        });
    };

    renderCustomDisc = (itemPrice, customDisc, itemDisc, currencyCode) => {
        const calculatedDisc = PosPriceCalculator.calculateCustomDisc(
            itemPrice,
            customDisc,
            itemDisc,
            currencyCode
        );
        let discountString = "C. Disc";
        if (customDisc.custom_discount_type === "percentage") {
            discountString += ` ${customDisc.custom_discount_value}%`;
        }
        return this.renderItemSub(
            uuid(),
            discountString,
            calculatedDisc.formattedDisc
        );
    };

    render() {
        const { trx, posConfig, isPrint, style, preSettlement, copy } = this.props;
        const { modalItemOpened, modalItem, modalItemTrxDetail, modalCustomItemOpened, modalCustomItem, modalCustomItemTrxDetail } = this.state;

        let cashier_name = posConfig.cashier_name;
        if(!empty(trx.cashier_configs)){
            cashier_name = trx.cashier_configs.cashier_name;
        }

        const subTotal = PosPriceCalculator.calculateSubTotal(trx, this.currencyCode);
        const globalDiscount = PosPriceCalculator.calculateGlobalDiscount(trx, subTotal.subTotal, this.currencyCode);
        const subTotalAfterGlobalDiscount =
            subTotal.subTotal - globalDiscount.totalDiscountValue;
        const serviceCharge = PosPriceCalculator.calculateServiceCharge(
            trx,
            posConfig.service_charge,
            this.currencyCode
        );
        const additionalCharge = PosPriceCalculator.calculateAdditionalCharge(
            trx,
            posConfig.additional_charge,
            this.currencyCode
        );
        let tax = 0;
        if (trx.trx_type === TRX_TYPE_NORMAL || trx.trx_type === TRX_TYPE_COUPON_SALES) {
            tax = PosPriceCalculator.calculateTax(
                subTotalAfterGlobalDiscount,
                serviceCharge.serviceChargeValue,
                additionalCharge.additionalChargeValue,
                posConfig.tax,
                this.currencyCode
            );
        } else {
            tax = PosPriceCalculator.calculateTax(
                subTotalAfterGlobalDiscount,
                serviceCharge.serviceChargeValue,
                additionalCharge.additionalChargeValue,
                null,
                this.currencyCode
            );
        }
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            trx,
            posConfig,
            this.currencyCode
        );
        let customerName = "GUEST";

        const reducer = (accumulator, currentValue) => parseInt(currentValue.payment_amount) + accumulator;
        const totalPayment = trx.payments.reduce(reducer, 0);

        const grossTotalService = PosPriceCalculator.calculateGrossTotal(trx.details, "SERVICE", this.currencyCode).grossTotal;
        const grossTotalRetail = PosPriceCalculator.calculateGrossTotal(trx.details, "RETAIL", this.currencyCode).grossTotal;
        const grossTotal = PosPriceCalculator.calculateGrossTotal(trx.details, null, this.currencyCode).grossTotal;

        const itemDiscountPriceReducer = (total, detail) =>
            total +
            PosPriceCalculator.calculateItemPrice(trx, detail, this.currencyCode).totalDiscountValue;
        const totalItemDiscount = trx.details.reduce(itemDiscountPriceReducer, 0);

        const totalDiscount = totalItemDiscount + globalDiscount.totalDiscountValue;
        const currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;

        let drv_global_discount_type = "";
        if (
            trx.trx_type === TRX_TYPE_NORMAL &&
            empty(drv_global_discount_type) &&
            !empty(trx.coupon) &&
            trx.coupon.coupon_item.pos_link_type === "discount"
        ) {
            drv_global_discount_type = "coupon";
        }
        if (
            trx.trx_type === TRX_TYPE_NORMAL &&
            empty(drv_global_discount_type) &&
            !empty(trx.redeem) &&
            trx.redeem.redeem_item.pos_link_type === "discount"
        ) {
            drv_global_discount_type = "redeem";
        }
        if (
            trx.trx_type === TRX_TYPE_NORMAL &&
            empty(drv_global_discount_type) &&
            !empty(trx.customer) &&
            !empty(trx.customer.discount_level) &&
            !empty(trx.customer.discount_level.reward_value) &&
            trx.customer.discount_level.reward_value > 0
        ) {
            drv_global_discount_type = "member";
        }
        if (empty(drv_global_discount_type) && !empty(trx.discounts)) {
            trx.discounts.map(discount => {
                if (discount.discount_type === "custom") {
                    drv_global_discount_type = "custom";
                }
                return discount;
            });
        }
        if (empty(drv_global_discount_type) && !empty(trx.discounts)) {
            trx.discounts.map(discount => {
                if (discount.discount_type === "basic") {
                    drv_global_discount_type = "defined";
                }
                return discount;
            });
        }

        if (!empty(trx.customer)) {
            if ("user_name" in trx.customer) {
                customerName = trx.customer.user_name;
            }
        }

        let couponLabel = "";
        if (!empty(trx.coupon)) {
            if (trx.coupon.coupon_item.pos_link_type === "discount") {
                couponLabel = ` (Coupon ${
                    trx.coupon.coupon_item.discount_percentage
                }% off)`;
            }
        }

        let redeemLabel = "";
        if (!empty(trx.redeem)) {
            if (trx.redeem.redeem_item.pos_link_type === "discount") {
                redeemLabel = ` (Redeem ${
                    trx.redeem.redeem_item.discount_percentage
                }% off)`;
            }
        }



        const unPrintedOrderDetails = trx.details
            .filter(row => row.last_printed_at === null);


        if (!isPrint) {
            return (
                <div className={styles.outerWrapper}
                     style={style}
                     ref={el => (this.container = el)}
                >
                    {this.state.deleteTrxItemModalOpened && !empty(this.state.deleteTrxItemLocalTrxId) && (
                        <DeleteTrxItemPasswordModal
                            localTrxId={this.state.deleteTrxItemLocalTrxId}
                            localTrxDetailId={this.state.deleteTrxItemLocalTrxDetailId}
                            trxDetail={this.state.deleteTrxItem}
                            opened={this.state.deleteTrxItemModalOpened}
                            onClose={() => {
                                this.setState({
                                    deleteTrxItemModalOpened: false,
                                    deleteTrxItemLocalTrxId: null,
                                    deleteTrxItemLocalTrxDetailId: null,
                                    deleteTrxItem: null
                                });
                            }}
                            deleteAction={deletedDetail => {
                                this.removeItemAction(this.state.deleteTrxItemLocalTrxDetailId, deletedDetail);
                            }}
                        />
                    )}

                    {!empty(trx.details) && (
                        <table className={styles.itemListTable}>
                            <tbody>
                            {trx.trx_type === TRX_TYPE_NORMAL &&
                            posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END &&
                            (<React.Fragment>
                                {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") && (
                                    <React.Fragment>
                                        <tr>
                                            <td>
                                                <strong>SERVICES</strong>
                                            </td>
                                        </tr>
                                        {this.renderTrxDetails(trx, false)}
                                    </React.Fragment>
                                )}

                                {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") &&
                                trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                    <tr>
                                        <td colSpan={4}>
                                            <Divider style={{ margin: "10px 0" }}/>
                                        </td>
                                    </tr>
                                )}

                                {trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                    <React.Fragment>
                                        <tr>
                                            <td>
                                                <strong>ITEMS</strong>
                                            </td>
                                        </tr>
                                        {this.renderTrxDetails(trx, true)}
                                    </React.Fragment>
                                )}
                            </React.Fragment>)}

                            {(trx.trx_type !== TRX_TYPE_NORMAL ||
                                (
                                    trx.trx_type === TRX_TYPE_NORMAL &&
                                    posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT
                                )) && (this.renderTrxDetails(trx))}

                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <Divider style={{ margin: "10px 0" }}/>
                                </td>
                            </tr>

                            {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "coupon" && (
                                <tr>
                                    <td colSpan={2}>
                                        Discount {couponLabel}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "redeem" && (
                                <tr>
                                    <td colSpan={2}>
                                        Discount {redeemLabel}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "member" && (
                                <tr>
                                    <td colSpan={2}>
                                        Member Discount -{" "}
                                        {
                                            trx.customer.discount_level
                                                .reward_value
                                        }
                                        %&nbsp;&nbsp;
                                        <button
                                            onClick={() =>
                                                this.onDeleteMemberDiscount(
                                                    trx.local_trx_id
                                                )
                                            }
                                            className={
                                                styles.linkBtn
                                            }
                                        >
                                            hapus
                                        </button>
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {drv_global_discount_type === "custom" && (
                                <tr>
                                    <td colSpan={2}>
                                        C. Disc&nbsp;&nbsp;
                                        {!empty(trx.discounts) && (
                                            <React.Fragment>
                                                <button
                                                    onClick={() =>
                                                        this.onDeleteGlobalDiscount(
                                                            trx.local_trx_id
                                                        )
                                                    }
                                                    className={
                                                        styles.linkBtn
                                                    }
                                                >
                                                    hapus
                                                </button>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {drv_global_discount_type === "defined" && (
                                <tr>
                                    <td colSpan={2}>
                                        Discount&nbsp;&nbsp;
                                        {!empty(trx.discounts) && (
                                            <React.Fragment>
                                                <button
                                                    onClick={() =>
                                                        this.onDeleteGlobalDiscount(
                                                            trx.local_trx_id
                                                        )
                                                    }
                                                    className={
                                                        styles.linkBtn
                                                    }
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                >
                                                    hapus
                                                </button>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            globalDiscount.formattedDiscountValue
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {!empty(serviceCharge.serviceChargeValue) && (
                                <tr>
                                    <td>Service Charge</td>
                                    <td>&nbsp;</td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            serviceCharge.formattedServiceCharge
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {!empty(
                                additionalCharge.additionalChargeValue
                            ) && (
                                <tr>
                                    <td>Additional Charge</td>
                                    <td>&nbsp;</td>
                                    <td className={styles.itemSubTotal}>
                                        {
                                            additionalCharge.formattedAdditionalCharge
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            {!empty(tax.taxValue) && (
                                <tr>
                                    <td>
                                        Tax ({tax.taxPercent}
                                        %)
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={styles.itemSubTotal}>
                                        {tax.formattedTax}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            )}

                            <tr>
                                <td style={{ fontWeight: 'bold' }}>
                                    TOTAL
                                </td>
                                <td>&nbsp;</td>
                                <td
                                    className={styles.itemSubTotal}
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {grandTotal.formattedGrandTotal}
                                </td>
                                <td>&nbsp;</td>
                            </tr>

                            {!empty(trx.coupon) && (
                                <>
                                    <tr>
                                        <td colSpan={4}>
                                            <Divider style={{ margin: "10px 0" }}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={4}>
                                            [COUPON] {trx?.coupon?.coupon_item?.coupon_name}
                                        </td>
                                    </tr>
                                </>
                            )}

                            {
                                this.props.showStockOut &&
                                !empty(trx.consumptions) && (
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={4}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>STOCK OUT - CONSUMPTION</td>
                                        </tr>
                                        {trx.consumptions.map(row => (
                                            <tr key={row.item_name}>
                                                <td colSpan={2}>{row.item_name}{!empty(row.variant_name)?` - ${row.variant_name}`:''}</td>
                                                <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                    {row.qty} {row.unit_name}
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                )
                            }

                            {
                                this.props.showStockOut &&
                                !empty(trx.ingredients) && (
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={4}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>STOCK OUT - INGREDIENT</td>
                                        </tr>
                                        {trx.ingredients.map(row => (
                                            <tr>
                                                <td colSpan={2}>{row.item_name}{!empty(row.variant_name)?` - ${row.variant_name}`:''}</td>
                                                <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                    {row.qty} {row.unit_name}
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                )
                            }
                            </tfoot>
                        </table>
                    )}

                    {empty(trx.details) && <div>Belum ada produk dipilih..</div>}

                    {modalItemOpened &&
                    !empty(modalItem) &&
                    !empty(modalItemTrxDetail) &&
                    trx.trx_type !== TRX_TYPE_COUPON_SALES && (
                        <PosItemModal
                            modalOpened={modalItemOpened}
                            item={modalItem}
                            trxDetail={modalItemTrxDetail}
                            postEditMode={this.props.postEditMode}
                            postEditSessionId={this.props.postEditSessionId}
                            postEditOnSubmit={this.props.postEditOnSubmit}
                            trx={trx}
                            close={() => {
                                this.setComponentState({
                                    modalItemOpened: false,
                                    modalItem: null,
                                    modalItemTrxDetail: null
                                });
                            }}
                        />
                    )
                    }

                    {modalItemOpened &&
                    !empty(modalItem) &&
                    !empty(modalItemTrxDetail) &&
                    trx.trx_type === TRX_TYPE_COUPON_SALES && (
                        <PosItemModal
                            modalOpened={modalItemOpened}
                            item={modalItem}
                            itemType="coupon"
                            trxDetail={modalItemTrxDetail}
                            close={() => {
                                this.setComponentState({
                                    modalItemOpened: false,
                                    modalItem: null,
                                    modalItemTrxDetail: null
                                });
                            }}
                        />
                    )
                    }

                    {modalCustomItemOpened &&
                    !empty(modalCustomItem) &&
                    !empty(modalCustomItemTrxDetail) && (
                        <PosCustomItemModal
                            modalOpened={modalCustomItemOpened}
                            item={modalCustomItem}
                            trxDetail={modalCustomItemTrxDetail}
                            close={() => {
                                this.setComponentState({
                                    modalCustomItemOpened: false,
                                    modalCustomItem: null,
                                    modalCustomItemTrxDetail: null
                                });
                            }}
                        />
                    )
                    }
                </div>
            );
        } else {
            let chosenWorkers = [];
            trx.details.map(row => {
                if(row.is_package === 0){
                    if(!empty(row.commission_worker)){
                        if(!chosenWorkers.includes(row.commission_worker.business_commission_worker_id)){
                            chosenWorkers = [...chosenWorkers, row.commission_worker.business_commission_worker_id];
                        }
                    }

                    if(!empty(row.commission_workers)){
                        row.commission_workers.map(worker => {
                            if(!chosenWorkers.includes(worker.commission_worker.business_commission_worker_id)){
                                chosenWorkers = [...chosenWorkers, worker.commission_worker.business_commission_worker_id];
                            }
                            return worker;
                        });
                    }
                }else{
                    row.pos_item_package_details.map(package_detail => {
                        if(!empty(package_detail.business_commission_worker)){
                            if(!chosenWorkers.includes(package_detail.business_commission_worker.business_commission_worker_id)){
                                chosenWorkers = [...chosenWorkers, package_detail.business_commission_worker.business_commission_worker_id];
                            }
                        }

                        if(!empty(package_detail.commission_workers)){
                            package_detail.commission_workers.map(worker => {
                                if(!chosenWorkers.includes(worker.commission_worker.business_commission_worker_id)){
                                    chosenWorkers = [...chosenWorkers, worker.commission_worker.business_commission_worker_id];
                                }
                                return worker;
                            });
                        }

                        return package_detail;
                    });
                }

                return row;
            });

            return (
                <React.Fragment>
                    <div style={style} className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop}`}>
                        {!empty(trx.details) && (
                            <React.Fragment>
                                <table className={styles.headerTable}>
                                    <tbody>
                                    {this.props.orderPreview && (
                                        <tr>
                                            <td colSpan={2} className={styles.printoutSectionTitle}>ORDER LIST</td>
                                        </tr>
                                    )}

                                    {!this.props.orderPreview && (
                                        <React.Fragment>
                                            {(empty(posConfig.brand_logo) || posConfig.pos_print_logo !== 1) && (
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className={styles.brandName}
                                                    >
                                                        {posConfig.brand_name}
                                                    </td>
                                                </tr>
                                            )}

                                            {!empty(posConfig.brand_logo) && posConfig.pos_print_logo === 1 && (
                                                <React.Fragment>
                                                    <tr>
                                                        <td
                                                            colSpan={2}
                                                            className={styles.brandName}
                                                        >
                                                            <img src={posConfig.brand_logo}
                                                                 className={styles.brandLogo} alt={posConfig.brand_name} />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            colSpan={2}
                                                            className={styles.outletName}
                                                        >
                                                            {posConfig.brand_name}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}

                                            {posConfig.pos_print_outlet_name === 1 && (
                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    className={styles.outletName}
                                                >
                                                    {posConfig.outlet_name}
                                                </td>
                                            </tr>
                                            )}

                                            {posConfig.pos_print_address === 1 && (
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className={styles.outletAddress}
                                                    >
                                                        {posConfig.outlet_address}
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    className={styles.outletAddress}
                                                >
                                                    {posConfig.outlet_phone}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>&nbsp;</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    <tr>
                                        <td>Customer</td>
                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                            {customerName}
                                        </td>
                                    </tr>

                                    {posConfig.pos_print_user_identifier === 1 && !empty(trx.customer) && (
                                        <tr>
                                            <td>Customer ID</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.customer.user_identifier_string}
                                            </td>
                                        </tr>
                                    )}

                                    {posConfig.pos_print_label_if_no_user_identifier === 1 && empty(trx.customer) && !empty(trx.trx_label) && (
                                        <tr>
                                            <td>Label</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.trx_label}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>Cashier</td>
                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                            {cashier_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Rcp. Num</td>
                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                            {trx.local_trx_id
                                                .slice(-6)
                                                .toUpperCase()}
                                        </td>
                                    </tr>
                                    {!empty(trx.order_no) &&
                                    posConfig.pos_print_receipt_order_number_top === 1 && (
                                        <tr>
                                            <td>Order No</td>
                                            <td className={styles.alignRight}>
                                                {trx.order_no}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>
                                            {moment(
                                                posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                        !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                            trx.trx_local_start_time,
                                                MOMENT_SQL_DATETIME_FORMAT
                                            ).format("D MMM YYYY")}
                                        </td>
                                        <td className={styles.alignRight}>
                                            {moment(
                                                posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                        !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                            trx.trx_local_start_time,
                                                MOMENT_SQL_DATETIME_FORMAT
                                            ).format("HH:mm")}
                                        </td>
                                    </tr>

                                    {!empty(trx.service_area) && (
                                        <tr>
                                            <td>Area</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.service_area.area_name}{!empty(trx.service_area.areaCategory_name) && ` - ${trx.service_area.areaCategory_name}`}
                                            </td>
                                        </tr>
                                    )}

                                    {this.props.orderPreview && (
                                        <tr>
                                            <td>
                                                Print Time
                                            </td>
                                            <td className={styles.alignRight}>
                                                {moment().format("YYYY.MM.DD")} {moment().format("HH:mm")}
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>

                                <div className={styles.receiptDivider}/>

                                <table className={styles.itemListTable}>
                                    <tbody>

                                    {!this.props.orderPreview && (
                                        <>
                                            {trx.trx_type === TRX_TYPE_NORMAL &&
                                            posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END &&
                                            (<React.Fragment>
                                                {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") && (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>
                                                                <strong>SERVICES</strong>
                                                            </td>
                                                        </tr>
                                                        {this.renderTrxDetails(trx, false)}
                                                    </React.Fragment>
                                                )}

                                                {trx.details.some(row => row.itemMeta.item_nature !== "RETAIL") &&
                                                trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div
                                                                className={
                                                                    styles.receiptDivider
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                )}

                                                {trx.details.some(row => row.itemMeta.item_nature === "RETAIL") && (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>
                                                                <strong>ITEMS</strong>
                                                            </td>
                                                        </tr>
                                                        {this.renderTrxDetails(trx, true)}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>)}

                                            {(trx.trx_type !== TRX_TYPE_NORMAL ||
                                                (
                                                    trx.trx_type === TRX_TYPE_NORMAL &&
                                                    posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT
                                                )) && (this.renderTrxDetails(trx))}
                                        </>
                                    )}

                                    {this.props.orderPreview && (
                                        <>
                                            {this.renderTrxDetails(trx, null, false, true)}
                                        </>
                                    )}

                                    {copy && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div style={{
                                                        textAlign: "center",
                                                        fontSize: 18,
                                                        padding: "7px 0"
                                                    }}>
                                                        THIS IS A COPY
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {!this.props.orderPreview && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>

                                            {posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT && (
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {subTotal.formattedSubTotal}
                                                    </td>
                                                </tr>
                                            )}

                                            {posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END && (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>Total Service</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(grossTotalService, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Retail</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(grossTotalRetail, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Subtotal</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(grossTotal, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(totalDiscount, 0) + ` ${currencyCode}`}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}

                                            {posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT && (
                                                <React.Fragment>
                                                    {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "coupon" && (
                                                        <tr>
                                                            <td>
                                                                Discount {couponLabel}
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "redeem" && (
                                                        <tr>
                                                            <td>
                                                                Discount {redeemLabel}
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {trx.trx_type === TRX_TYPE_NORMAL && drv_global_discount_type === "member" && (
                                                        <tr>
                                                            <td>
                                                                Member Discount -{" "}
                                                                {
                                                                    trx.customer.discount_level
                                                                        .reward_value
                                                                }
                                                                %
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {drv_global_discount_type === "custom" && (
                                                        <tr>
                                                            <td>
                                                                C. Disc
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {drv_global_discount_type === "defined" && (
                                                        <tr>
                                                            <td>
                                                                Discount
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {
                                                                    globalDiscount.formattedDiscountValue
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {!empty(
                                                serviceCharge.serviceChargeValue
                                            ) && (
                                                <tr>
                                                    <td>Service Charge</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {
                                                            serviceCharge.formattedServiceCharge
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                            {!empty(
                                                additionalCharge.additionalChargeValue
                                            ) && (
                                                <tr>
                                                    <td>Additional Charge</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {
                                                            additionalCharge.formattedAdditionalCharge
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                            {!empty(tax.taxValue) && (
                                                <tr>
                                                    <td>
                                                        Tax ({tax.taxPercent}
                                                        %)
                                                    </td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {tax.formattedTax}
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td colSpan={3}>
                                                    <div
                                                        className={
                                                            styles.receiptDivider
                                                        }
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ fontWeight: '900' }}>
                                                    TOTAL
                                                </td>
                                                <td
                                                    colSpan={2}
                                                    className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}
                                                    style={{ fontWeight: '900' }}
                                                >
                                                    {grandTotal.formattedGrandTotal}
                                                </td>
                                            </tr>

                                            {!empty(trx.coupon) && (
                                                <>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div
                                                                className={
                                                                    styles.receiptDivider
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            [COUPON] {trx?.coupon?.coupon_item?.coupon_name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div
                                                                className={
                                                                    styles.receiptDivider
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </React.Fragment>
                                    )}


                                    {!preSettlement && !this.props.orderPreview && (
                                        <React.Fragment>
                                            {trx.payments.map(row => {
                                                return (
                                                    <React.Fragment key={`PaymentMethod${row.payment_method.payment_method_name}`}>
                                                        <tr>
                                                            <td>
                                                                {
                                                                    row.payment_method
                                                                        .payment_method_name
                                                                }
                                                            </td>
                                                            <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                {`${number_format(
                                                                    row.payment_amount,
                                                                    0
                                                                )} ${currencyCode}`}
                                                            </td>
                                                        </tr>

                                                        {row.payment_method.is_charge_to_customer === 1 && (
                                                            <tr>
                                                                <td>
                                                                    <div style={{
                                                                        fontSize: '0.9em',
                                                                        paddingLeft: 10
                                                                    }}>
                                                                        {row.payment_method.charge_label}
                                                                    </div>
                                                                </td>
                                                                <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                                    {`${number_format(
                                                                        row.payment_charge,
                                                                        0
                                                                    )} ${currencyCode}`}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}


                                            {!empty(trx.payments.find(row => row.payment_method.payment_method_name === "Cash")) && (
                                                <tr>
                                                    <td>Change</td>
                                                    <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                        {number_format(
                                                            totalPayment -
                                                            trx.drv_grand_total,
                                                            0
                                                        )}{" "}
                                                        {currencyCode}
                                                    </td>
                                                </tr>
                                            )}

                                            {(!empty(trx.top_up_deposit_rewards?.deposit_reward_amount) || !empty(trx.top_up_deposit_rewards?.coupon_reward_amount) || !empty(trx.top_up_deposit_rewards?.point_reward_amount)) && (
                                                <>
                                                    {/*<tr>
                                                        <td colSpan={3}>&nbsp;</td>
                                                    </tr>*/}
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div
                                                                className={
                                                                    styles.receiptDivider
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}><strong>BONUS TOP UP</strong></td>
                                                    </tr>
                                                </>
                                            )}

                                            {!empty(trx.top_up_deposit_rewards?.deposit_reward_amount) &&
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={2}>Bonus Deposit</td>
                                                        <td className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(trx.top_up_deposit_rewards.deposit_reward_amount)}{" "}
                                                            {currencyCode}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                            }

                                            {!empty(trx.top_up_deposit_rewards?.coupon_reward_amount) &&
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={2}>Bonus Kupon</td>
                                                        <td className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(trx.top_up_deposit_rewards.coupon_reward_amount)}{" "}
                                                            Kupon
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                            }

                                            {!empty(trx.top_up_deposit_rewards?.point_reward_amount) &&
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={2}>Bonus Poin</td>
                                                        <td className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(trx.top_up_deposit_rewards.point_reward_amount)}{" "}
                                                            {trx.top_up_deposit_rewards.point_reward_name}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                            }

                                            {posConfig.print_deposit_balance_on_receipt === 1 &&
                                            this.props.isLegit &&
                                            (trx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT ||
                                                (trx.trx_type === TRX_TYPE_NORMAL &&
                                                    !empty(trx.payments.find(row => row.payment_type === PAYMENT_TYPE_DEPOSIT))
                                                )
                                            ) &&
                                            (trx.deposit_balance !== undefined && trx.deposit_balance !== null) &&
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={3}>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Deposit</td>
                                                        <td colSpan={2} className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {number_format(trx.deposit_balance)}{" "}
                                                            {currencyCode}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                            }
                                        </React.Fragment>
                                    )}
                                    </tbody>
                                </table>

                                {!empty(trx.trx_notes) && (
                                    <React.Fragment>
                                        <div className={styles.receiptDivider}/>
                                        <div>
                                            Notes
                                            <br/>
                                            {nl2brReact(trx.trx_notes)}
                                        </div>
                                    </React.Fragment>
                                )}

                                {preSettlement && (
                                    <React.Fragment>
                                        <div className={styles.receiptDivider}/>
                                        <div style={{
                                            textAlign: "center",
                                            fontSize: 18,
                                            padding: "7px 0",
                                            lineHeight: "1em"
                                        }}>
                                            PRE-SETTLEMENT<br/>
                                            <span style={{
                                                fontSize: 12
                                            }}>
                                                {moment().format(MOMENT_READABLE_DATETIME_FORMAT)}
                                            </span>
                                        </div>
                                        <div className={styles.receiptDivider}/>
                                    </React.Fragment>
                                )}

                                {!empty(trx.order_no) &&
                                posConfig.pos_print_receipt_order_number_bottom === 1 &&
                                (
                                    <div style={{ textAlign: "center", marginTop: 30 }}>
                                        <div>Order No</div>
                                        <div style={{
                                            fontSize: 50,
                                            lineHeight: "60px"
                                            // fontWeight: 'bold'
                                        }}>
                                            {trx.order_no}
                                        </div>
                                    </div>
                                )}

                                {!empty(posConfig.receipt_footer) && !this.props.orderPreview && !preSettlement && (
                                    <div style={{
                                        textAlign: "center",
                                        marginTop: 30,
                                        lineHeight: "1.3em"
                                    }}>
                                        {nl2brReact(posConfig.receipt_footer)}
                                    </div>
                                )}

                                <br/>
                            </React.Fragment>
                        )}

                        {empty(trx.details) && <div>No items yet</div>}
                    </div>

                    {posConfig.pos_print_order_detail === 1 && this.props.isLegit && !empty(unPrintedOrderDetails) && (
                        <div className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${styles.pageBreak}`}>
                            {!empty(trx.details) && (
                                <React.Fragment>
                                    <table className={styles.headerTable}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2} className={styles.printoutSectionTitle}>ORDER LIST</td>
                                        </tr>

                                        <tr>
                                            <td>Customer</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {customerName}
                                            </td>
                                        </tr>

                                        {posConfig.pos_print_user_identifier === 1 && !empty(trx.customer) && (
                                            <tr>
                                                <td>Customer ID</td>
                                                <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                    {trx.customer.user_identifier_string}
                                                </td>
                                            </tr>
                                        )}

                                        {posConfig.pos_print_label_if_no_user_identifier === 1 && empty(trx.customer) && !empty(trx.trx_label) && (
                                            <tr>
                                                <td>Label</td>
                                                <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                    {trx.trx_label}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td>Cashier</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {cashier_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rcp. Num</td>
                                            <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                {trx.local_trx_id
                                                    .slice(-6)
                                                    .toUpperCase()}
                                            </td>
                                        </tr>
                                        {!empty(trx.order_no) &&
                                        posConfig.pos_print_receipt_order_number_top === 1 && (
                                            <tr>
                                                <td>Order No</td>
                                                <td className={styles.alignRight}>
                                                    {trx.order_no}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td>
                                                {moment(
                                                    posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                        !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                            trx.trx_local_start_time,
                                                    MOMENT_SQL_DATETIME_FORMAT
                                                ).format("D MMM YYYY")}
                                            </td>
                                            <td className={styles.alignRight}>
                                                {moment(
                                                    posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                        !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                            trx.trx_local_start_time,
                                                    MOMENT_SQL_DATETIME_FORMAT
                                                ).format("HH:mm")}
                                            </td>
                                        </tr>

                                        {!empty(trx.service_area) && (
                                            <tr>
                                                <td>Area</td>
                                                <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                    {trx.service_area.area_name}{!empty(trx.service_area.areaCategory_name) && ` - ${trx.service_area.areaCategory_name}`}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td>
                                                Print Time
                                            </td>
                                            <td className={styles.alignRight}>
                                                {moment().format("YYYY.MM.DD")} {moment().format("HH:mm")}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div className={styles.receiptDivider}/>

                                    <table className={styles.itemListTable}>
                                        <tbody>

                                        {this.renderTrxDetails(trx, null, true, true, true)}

                                        {copy && (
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div
                                                            className={
                                                                styles.receiptDivider
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div style={{
                                                            textAlign: "center",
                                                            fontSize: 18,
                                                            padding: "7px 0"
                                                        }}>
                                                            THIS IS A COPY
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                        </tbody>
                                    </table>

                                    {!empty(trx.trx_notes) && (
                                        <React.Fragment>
                                            <div className={styles.receiptDivider}/>
                                            <div>
                                                Notes
                                                <br/>
                                                {nl2brReact(trx.trx_notes)}
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {preSettlement && (
                                        <React.Fragment>
                                            <div className={styles.receiptDivider}/>
                                            <div style={{
                                                textAlign: "center",
                                                fontSize: 18,
                                                padding: "7px 0",
                                                lineHeight: "1em"
                                            }}>
                                                PRE-SETTLEMENT<br/>
                                                <span style={{
                                                    fontSize: 12
                                                }}>
                                            {moment().format(MOMENT_READABLE_DATETIME_FORMAT)}
                                        </span>
                                            </div>
                                            <div className={styles.receiptDivider}/>
                                        </React.Fragment>
                                    )}

                                    {!empty(trx.order_no) &&
                                    posConfig.pos_print_receipt_order_number_bottom === 1 &&
                                    (
                                        <div style={{ textAlign: "center", marginTop: 30 }}>
                                            <div>Order No</div>
                                            <div style={{
                                                fontSize: 50,
                                                lineHeight: "60px"
                                                // fontWeight: 'bold'
                                            }}>
                                                {trx.order_no}
                                            </div>
                                        </div>
                                    )}

                                    <br/>
                                </React.Fragment>
                            )}
                            ***
                        </div>
                    )}

                    {(this.props.orderPreview || (posConfig.pos_print_order_detail === 1 && this.props.isLegit)) && posConfig.pos_print_order_detail_type === 'CATEGORY' && posConfig.order_print_out_section?.map(row => {
                        let filteredTrxDetails = trx.details.filter(
                            detail => row.pos_item_categories.some(
                                categories => categories.pos_item_category_id === detail.itemMeta.pos_item_category_id
                            )
                        );

                        if(this.props.orderPreviewUnprinted || (posConfig.pos_print_order_detail === 1 && this.props.isLegit)){
                            filteredTrxDetails = filteredTrxDetails.filter(row => row.last_printed_at === null);
                        }

                        const filteredTrx = {
                            ...trx,
                            details: filteredTrxDetails
                        };

                        if(!empty(filteredTrxDetails)){
                            return (
                                <div className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop} ${styles.pageBreakVisible}`}>
                                    {!empty(filteredTrxDetails) && (
                                        <React.Fragment>
                                            <table className={styles.headerTable}>
                                                <tbody>

                                                <tr>
                                                    <td colSpan={2} className={styles.printoutSectionTitle}>{row.pos_order_print_out_section_name}</td>
                                                </tr>

                                                <tr>
                                                    <td>Customer</td>
                                                    <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                        {customerName}
                                                    </td>
                                                </tr>

                                                {posConfig.pos_print_user_identifier === 1 && !empty(trx.customer) && (
                                                    <tr>
                                                        <td>Customer ID</td>
                                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                            {trx.customer.user_identifier_string}
                                                        </td>
                                                    </tr>
                                                )}

                                                {posConfig.pos_print_label_if_no_user_identifier === 1 && empty(trx.customer) && !empty(trx.trx_label) && (
                                                    <tr>
                                                        <td>Label</td>
                                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                            {trx.trx_label}
                                                        </td>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <td>Cashier</td>
                                                    <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                        {cashier_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rcp. Num</td>
                                                    <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                        {trx.local_trx_id
                                                            .slice(-6)
                                                            .toUpperCase()}
                                                    </td>
                                                </tr>
                                                {!empty(trx.order_no) &&
                                                posConfig.pos_print_receipt_order_number_top === 1 && (
                                                    <tr>
                                                        <td>Order No</td>
                                                        <td className={styles.alignRight}>
                                                            {trx.order_no}
                                                        </td>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <td>
                                                        {moment(
                                                            posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                                !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                                    trx.trx_local_start_time,
                                                            MOMENT_SQL_DATETIME_FORMAT
                                                        ).format("D MMM YYYY")}
                                                    </td>
                                                    <td className={styles.alignRight}>
                                                        {moment(
                                                            posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                                !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                                    trx.trx_local_start_time,
                                                            MOMENT_SQL_DATETIME_FORMAT
                                                        ).format("HH:mm")}
                                                    </td>
                                                </tr>

                                                {!empty(trx.service_area) && (
                                                    <tr>
                                                        <td>Area</td>
                                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                            {trx.service_area.area_name}{!empty(trx.service_area.areaCategory_name) && ` - ${trx.service_area.areaCategory_name}`}
                                                        </td>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <td>
                                                        Print Time
                                                    </td>
                                                    <td className={styles.alignRight}>
                                                        {moment().format("YYYY.MM.DD")} {moment().format("HH:mm")}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <div className={styles.receiptDivider}/>

                                            <table className={styles.itemListTable}>
                                                <tbody>

                                                {this.renderTrxDetails(filteredTrx, null, true, true, (posConfig.pos_print_order_detail === 1 && this.props.isLegit) )}

                                                {copy && (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div
                                                                    className={
                                                                        styles.receiptDivider
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontSize: 18,
                                                                    padding: "7px 0"
                                                                }}>
                                                                    THIS IS A COPY
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                                </tbody>
                                            </table>

                                            {!empty(trx.trx_notes) && (
                                                <React.Fragment>
                                                    <div className={styles.receiptDivider}/>
                                                    <div>
                                                        Notes
                                                        <br/>
                                                        {nl2brReact(trx.trx_notes)}
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {preSettlement && (
                                                <React.Fragment>
                                                    <div className={styles.receiptDivider}/>
                                                    <div style={{
                                                        textAlign: "center",
                                                        fontSize: 18,
                                                        padding: "7px 0",
                                                        lineHeight: "1em"
                                                    }}>
                                                        PRE-SETTLEMENT<br/>
                                                        <span style={{
                                                            fontSize: 12
                                                        }}>
                                            {moment().format(MOMENT_READABLE_DATETIME_FORMAT)}
                                        </span>
                                                    </div>
                                                    <div className={styles.receiptDivider}/>
                                                </React.Fragment>
                                            )}

                                            {!empty(trx.order_no) &&
                                            posConfig.pos_print_receipt_order_number_bottom === 1 &&
                                            (
                                                <div style={{ textAlign: "center", marginTop: 30 }}>
                                                    <div>Order No</div>
                                                    <div style={{
                                                        fontSize: 50,
                                                        lineHeight: "60px"
                                                        // fontWeight: 'bold'
                                                    }}>
                                                        {trx.order_no}
                                                    </div>
                                                </div>
                                            )}

                                            <br/>
                                        </React.Fragment>
                                    )}
                                    ***
                                </div>
                            );
                        }else{
                            return '';
                        }
                    })}

                    {(this.props.orderPreview || (posConfig.pos_print_order_detail === 1 && this.props.isLegit)) && posConfig.pos_print_order_detail_type === 'WORKER' && chosenWorkers?.map(chosenWorkerId => {
                        let filteredTrxDetails = trx.details.filter(
                            detail => {
                                if(detail.is_package === 0){
                                    if(!empty(detail.commission_worker)){
                                        if(chosenWorkerId === detail.commission_worker.business_commission_worker_id){
                                            return true;
                                        }
                                    }

                                    if(!empty(detail.commission_workers)){
                                        return detail.commission_workers.some(
                                            worker => chosenWorkerId === worker.commission_worker.business_commission_worker_id
                                        );
                                    }
                                }else{
                                    return detail.pos_item_package_details.some(package_detail => {
                                        if(!empty(package_detail.business_commission_worker)){
                                            return chosenWorkerId === package_detail.business_commission_worker.business_commission_worker_id;
                                        }

                                        if(!empty(package_detail.commission_workers)){
                                            return package_detail.commission_workers.some(
                                                worker => chosenWorkerId === worker.commission_worker.business_commission_worker_id
                                            );
                                        }

                                        return false;
                                    });
                                }

                                return false;
                            }
                        );

                        if(this.props.orderPreviewUnprinted || (posConfig.pos_print_order_detail === 1 && this.props.isLegit)){
                            filteredTrxDetails = filteredTrxDetails.filter(row => row.last_printed_at === null);
                        }

                        const filteredTrx = {
                            ...trx,
                            details: filteredTrxDetails
                        };

                        if(!empty(filteredTrxDetails)){
                            return (
                                <div className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop} ${styles.pageBreakVisible}`}>
                                    {!empty(filteredTrxDetails) && (
                                        <React.Fragment>
                                            <table className={styles.headerTable}>
                                                <tbody>

                                                <tr>
                                                    <td colSpan={2} className={styles.printoutSectionTitle}>{posConfig.commission_worker?.find(row => row.business_commission_worker_id === chosenWorkerId)?.firstname} {posConfig.commission_worker?.find(row => row.business_commission_worker_id === chosenWorkerId)?.lastname}</td>
                                                </tr>

                                                <tr>
                                                    <td>Customer</td>
                                                    <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                        {customerName}
                                                    </td>
                                                </tr>

                                                {posConfig.pos_print_user_identifier === 1 && !empty(trx.customer) && (
                                                    <tr>
                                                        <td>Customer ID</td>
                                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                            {trx.customer.user_identifier_string}
                                                        </td>
                                                    </tr>
                                                )}

                                                {posConfig.pos_print_label_if_no_user_identifier === 1 && empty(trx.customer) && !empty(trx.trx_label) && (
                                                    <tr>
                                                        <td>Label</td>
                                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                            {trx.trx_label}
                                                        </td>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <td>Cashier</td>
                                                    <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                        {cashier_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rcp. Num</td>
                                                    <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                        {trx.local_trx_id
                                                            .slice(-6)
                                                            .toUpperCase()}
                                                    </td>
                                                </tr>
                                                {!empty(trx.order_no) &&
                                                posConfig.pos_print_receipt_order_number_top === 1 && (
                                                    <tr>
                                                        <td>Order No</td>
                                                        <td className={styles.alignRight}>
                                                            {trx.order_no}
                                                        </td>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <td>
                                                        {moment(
                                                            posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                                !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                                    trx.trx_local_start_time,
                                                            MOMENT_SQL_DATETIME_FORMAT
                                                        ).format("D MMM YYYY")}
                                                    </td>
                                                    <td className={styles.alignRight}>
                                                        {moment(
                                                            posConfig.pos_print_date === 'DOCUMENT_DATE' ? trx.document_date :
                                                                !empty(trx.trx_local_end_time) ? trx.trx_local_end_time :
                                                                    trx.trx_local_start_time,
                                                            MOMENT_SQL_DATETIME_FORMAT
                                                        ).format("HH:mm")}
                                                    </td>
                                                </tr>

                                                {!empty(trx.service_area) && (
                                                    <tr>
                                                        <td>Area</td>
                                                        <td className={`${styles.alignRight} ${styles.ellipsis}`}>
                                                            {trx.service_area.area_name}{!empty(trx.service_area.areaCategory_name) && ` - ${trx.service_area.areaCategory_name}`}
                                                        </td>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <td>
                                                        Print Time
                                                    </td>
                                                    <td className={styles.alignRight}>
                                                        {moment().format("YYYY.MM.DD")} {moment().format("HH:mm")}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <div className={styles.receiptDivider}/>

                                            <table className={styles.itemListTable}>
                                                <tbody>

                                                {this.renderTrxDetails(filteredTrx, null, true, true, (posConfig.pos_print_order_detail === 1 && this.props.isLegit) )}

                                                {copy && (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div
                                                                    className={
                                                                        styles.receiptDivider
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontSize: 18,
                                                                    padding: "7px 0"
                                                                }}>
                                                                    THIS IS A COPY
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                                </tbody>
                                            </table>

                                            {!empty(trx.trx_notes) && (
                                                <React.Fragment>
                                                    <div className={styles.receiptDivider}/>
                                                    <div>
                                                        Notes
                                                        <br/>
                                                        {nl2brReact(trx.trx_notes)}
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {preSettlement && (
                                                <React.Fragment>
                                                    <div className={styles.receiptDivider}/>
                                                    <div style={{
                                                        textAlign: "center",
                                                        fontSize: 18,
                                                        padding: "7px 0",
                                                        lineHeight: "1em"
                                                    }}>
                                                        PRE-SETTLEMENT<br/>
                                                        <span style={{
                                                            fontSize: 12
                                                        }}>
                                            {moment().format(MOMENT_READABLE_DATETIME_FORMAT)}
                                        </span>
                                                    </div>
                                                    <div className={styles.receiptDivider}/>
                                                </React.Fragment>
                                            )}

                                            {!empty(trx.order_no) &&
                                            posConfig.pos_print_receipt_order_number_bottom === 1 &&
                                            (
                                                <div style={{ textAlign: "center", marginTop: 30 }}>
                                                    <div>Order No</div>
                                                    <div style={{
                                                        fontSize: 50,
                                                        lineHeight: "60px"
                                                        // fontWeight: 'bold'
                                                    }}>
                                                        {trx.order_no}
                                                    </div>
                                                </div>
                                            )}

                                            <br/>
                                        </React.Fragment>
                                    )}
                                    ***
                                </div>
                            );
                        }else{
                            return '';
                        }
                    })}

                    {
                        this.props.showStockOut && !empty(trx.sales) && (
                    <div style={{
                        marginTop: 20,
                        marginBottom: 20
                    }} className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop}`}>
                        <table className={styles.itemListTable}>
                            {
                                this.props.showStockOut &&
                                !empty(trx.sales) && (
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={3} style={{textAlign:'center', fontWeight:'bold'}}>STOCK OUT - SALES</td>
                                        </tr>
                                        {trx.sales.map(row => (
                                            <tr>
                                                <td colSpan={2}>{row.item_name}{!empty(row.variant_name)?` - ${row.variant_name}`:''}</td>
                                                <td className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                    {row.qty} {row.unit_name}
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                )
                            }
                        </table>
                    </div>
                    )}

                    {
                        this.props.showStockOut && !empty(trx.consumptions) && (
                            <div style={{
                                marginTop: 20,
                                marginBottom: 20
                            }} className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop}`}>
                                <table className={styles.itemListTable}>
                                    {
                                        this.props.showStockOut &&
                                        !empty(trx.consumptions) && (
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={3} style={{textAlign:'center', fontWeight:'bold'}}>STOCK OUT - CONSUMPTIONS</td>
                                                </tr>
                                                {trx.consumptions.map(row => (
                                                    <tr>
                                                        <td colSpan={2}>{row.item_name}{!empty(row.variant_name)?` - ${row.variant_name}`:''}</td>
                                                        <td className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {row.qty} {row.unit_name}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        )
                                    }
                                </table>
                            </div>
                        )}

                    {
                        this.props.showStockOut && !empty(trx.ingredients) && (
                            <div style={{
                                marginTop: 20,
                                marginBottom: 20
                            }} className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop}`}>
                                <table className={styles.itemListTable}>
                                    {
                                        this.props.showStockOut &&
                                        !empty(trx.ingredients) && (
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={3} style={{textAlign:'center', fontWeight:'bold'}}>STOCK OUT - INGREDIENTS</td>
                                                </tr>
                                                {trx.ingredients.map(row => (
                                                    <tr>
                                                        <td colSpan={2}>{row.item_name}{!empty(row.variant_name)?` - ${row.variant_name}`:''}</td>
                                                        <td className={this.state.printSize === "6cm" ? styles.itemSubTotal6cm : styles.itemSubTotal}>
                                                            {row.qty} {row.unit_name}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        )
                                    }
                                </table>
                            </div>
                        )
                    }

                    {this.props.mobilePrintable && (
                        <div className={styles.tabletActionButtons}>
                            <Button type={"primary"}
                                    size={"large"}
                                    onClick={() => {
                                        if(!empty(this.props.onPrint)){
                                            this.props.onPrint();
                                        }
                                        window.scrollTo(0, 0);
                                        window.print();
                                    }}
                                    style={{ marginRight: 10 }}
                            >
                                Print
                            </Button>

                            {this.props.mobilePrintCloseable && (
                                <Button type={"danger"}
                                        size={"large"}
                                        onClick={() => {
                                            if(!empty(this.props.onCloseMobile)){
                                                this.props.onCloseMobile();
                                            }else{
                                                window.close();
                                            }
                                        }}
                                >
                                    Tutup
                                </Button>
                            )}

                            {!this.props.mobilePrintCloseable && (
                                <Button type={"danger"}
                                        size={"large"}
                                        onClick={() => {
                                            window.location = "/pos";
                                        }}
                                >
                                    Kembali
                                </Button>
                            )}
                        </div>
                    )}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData,
        configs: state.authState.configs
    };
};

const mapDispatchToProps = {
    deleteTrxDetail: posOperations.deleteTrxDetail,
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosPriceList);
