import * as types from './types';

export const addLoyaltyTrx = trx_data => {
    return dispatch => {
        dispatch({
            type: types.ADD_TRX_LOYALTY,
            payload: trx_data
        });
    };
};

export const addLocalLoyaltyTrx = trx_data => {
    return dispatch => {
        dispatch({
            type: types.ADD_LOCAL_TRX_LOYALTY,
            payload: trx_data
        });
    };
};

export const setLoyaltyStates = new_state => {
    return dispatch => {
        dispatch({
            type: types.SET_LOYALTY_STATES,
            payload: new_state
        });
    };
};

export default {
    addLoyaltyTrx,
    addLocalLoyaltyTrx,
    setLoyaltyStates
};
