import React, { Component } from "react";
import uuid from "uuid/v4";
import { empty } from "../../shared/helpers/generalHelper";
import styles from "./PosPayments.module.scss";
import { Avatar, Button, Col, Divider, Icon, Input, List, message, Modal, Radio, Row } from "antd";
import { posOperations } from "../../state/ducks/pos";
import { connect } from "react-redux";
import { GREEN, RED } from "../../shared/utils/colors";
import { number_format, processDecimalInput } from "../../shared/helpers/stringHelper";
import PosPriceCalculator from "../../shared/classes/PosPriceCalculator";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, PAYMENT_TYPE_CREDIT, PAYMENT_TYPE_DEPOSIT, PAYMENT_TYPE_NORMAL, TRX_TYPE_ACCOUNT_RECEIVABLE, TRX_TYPE_COUPON_SALES, TRX_TYPE_TOP_UP_DEPOSIT } from "../../shared/utils/constants";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";

class PosPayments extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            payment_method: null,
            payment_amount: 0,
            payment_amountText: '0',
            payment_ref: "",
            deposit_confirmed: false,
            deposit_amount: 0,
            deposit_amountText: '0',
            credit_confirmed: false,
            credit_amount: 0,
            credit_amountText: '0',
            credit_ref: '',
            redeemSmsTimer: 0,
            isRefreshingBalance: false
        };
        this.initialNormalPaymentState = {
            payment_method: null,
            payment_amount: 0,
            payment_amountText: '0',
            payment_ref: ""
        };
        this.initialDepositPaymentState = {
            deposit_confirmed: false,
            deposit_amount: 0,
            deposit_amountText: '0',
        };
        this.initialCreditPaymentState = {
            credit_confirmed: false,
            credit_amount: 0,
            credit_amountText: '0',
            credit_ref: ''
        };
        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
        this.resendSmsTimeout = null;
    }

    componentDidMount() {
        const trx = this.props.currentTrx;
        if (!empty(trx.payments)) {
            const depositPayment = trx.payments.filter(
                row => row.payment_type === PAYMENT_TYPE_DEPOSIT
            );
            if (!empty(depositPayment)) {
                this.setState({ deposit_confirmed: true });
            }

            const creditPayment = trx.payments.filter(
                row => row.payment_type === PAYMENT_TYPE_CREDIT
            );
            if (!empty(creditPayment)) {
                this.setState({ credit_confirmed: true });
            }
        }

        const { currentTrx, posConfig } = this.props;
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            this.currencyCode
        ).grandTotal;
        const reducer = (accumulator, currentValue) =>
            accumulator + currentValue.payment_amount;
        const totalPayment = currentTrx.payments.reduce(reducer, 0);
        const unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment);
        if (unpaidRemainder > 0) {
            this.setState({
                deposit_amount: !empty(trx.customer) ? (trx.customer.deposit_amount < unpaidRemainder ? trx.customer.deposit_amount : unpaidRemainder) : 0,
                deposit_amountText: !empty(trx.customer) ? (trx.customer.deposit_amount < unpaidRemainder ? `${trx.customer.deposit_amount}` : `${unpaidRemainder}`) : '0',
                credit_amount: unpaidRemainder,
                credit_amountText: `${unpaidRemainder}`
            });
        }
    }

    componentWillUnmount() {
        if(!empty(this.resendSmsTimeout)){
            clearTimeout(this.resendSmsTimeout);
        }
    }

    // PAYMENT
    onChangePaymentMethodId = e => {
        const paymentMethodArray = e.target.value.split("-");
        const table_name = paymentMethodArray[0];
        const table_id = paymentMethodArray[1];

        const paymentMethod = this.props.posConfig.payment_method.find(
            paymentMethod =>
                paymentMethod.table_name === table_name &&
                parseInt(paymentMethod.table_id) === parseInt(table_id)
        );

        const { currentTrx, posConfig } = this.props;
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            this.currencyCode
        ).grandTotal;
        const reducer = (accumulator, currentValue) =>
            accumulator + currentValue.payment_amount;
        const totalPayment = currentTrx.payments.reduce(reducer, 0);
        const unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment);

        this.setState({
            payment_method: paymentMethod,
            payment_amount: unpaidRemainder,
            payment_amountText: `${unpaidRemainder}`
        });
    };

    addNormalPayment = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { currentTrx, posConfig } = this.props;
        const {payment_method, payment_amount, payment_ref} = this.state;
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            this.currencyCode
        ).grandTotal;
        const reducer = (accumulator, currentValue) =>
            accumulator + currentValue.payment_amount;
        let totalPayment = currentTrx.payments.reduce(reducer, 0);
        let unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment);

        if (empty(payment_method)) {
            message.error("Metode pembayaran harus dipilih!");
        } else if (empty(payment_amount)) {
            message.error("Nominal pembayaran tidak boleh nol atau kosong!");
        } else if(
            payment_method.payment_method_name !== "Cash" &&
            parseInt(payment_amount) > unpaidRemainder
        ) {
            message.error(
                "Nominal pembayaran tidak boleh melebihi sisa pembayaran, kecuali pembayaran cash."
            );
        } else if(payment_method.is_reference_required && empty(payment_ref)) {
            message.error(
                "Kode referensi pembayaran harus diisi!"
            );
        } else if(!empty(payment_method.reference_min_length) && payment_ref.length < payment_method.reference_min_length) {
            message.error(
                "Kode referensi pembayaran harus diisi!"
            );
        } else {
            let payment_charge = 0;
            if(payment_method.is_charge_to_customer === 1){
                payment_charge = (payment_amount * (parseFloat(payment_method.charge_percentage) / 100)) + parseFloat(payment_method.charge_value);
            }

            this.props.setTrxProps(this.props.currentLocalTrxId, {
                payments: [
                    ...this.props.currentTrx.payments,
                    {
                        local_payment_id: uuid(),
                        payment_type: PAYMENT_TYPE_NORMAL,
                        payment_method: payment_method,
                        payment_amount: payment_amount,
                        payment_amountText: `${payment_amount}`,
                        payment_ref: payment_ref,
                        payment_charge
                    }
                ]
            });

            unpaidRemainder = Math.round(grandTotal) - totalPayment - payment_amount;
            this.setState({
                ...this.initialNormalPaymentState,
                deposit_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                deposit_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0',
                credit_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                credit_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0',
                payment_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                payment_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0'
            });
        }
    };

    addDepositPayment = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { currentTrx, posConfig } = this.props;
        const {deposit_amount} = this.state;
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            this.currencyCode
        ).grandTotal;
        const reducer = (accumulator, currentValue) =>
            accumulator + currentValue.payment_amount;
        let totalPayment = currentTrx.payments.reduce(reducer, 0);
        let unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment);

        if (empty(deposit_amount)) {
            message.error("Nominal deposit tidak boleh nol atau kosong!");
        } else if( parseInt(deposit_amount) > unpaidRemainder ){
            message.error("Nominal deposit tidak boleh melebihi jumlah total harga");
        } else {
            this.props.setTrxProps(this.props.currentLocalTrxId, {
                payments: [
                    ...this.props.currentTrx.payments,
                    {
                        local_payment_id: uuid(),
                        payment_type: PAYMENT_TYPE_DEPOSIT,
                        payment_method: {
                            payment_method_name: "Deposit"
                        },
                        payment_amount: deposit_amount,
                        payment_amountText: `${deposit_amount}`,
                        payment_ref: ""
                    }
                ]
            });

            totalPayment = currentTrx.payments.reduce(reducer, 0);
            unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment) - this.state.deposit_amount;
            this.setState({
                deposit_confirmed: true,
                deposit_amount: 0,
                deposit_amountText: '0',
                credit_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                credit_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0',
                payment_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                payment_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0'
            });
        }
    };

    addCreditPayment = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(this.state.credit_amount)) {
            message.error("Nominal piutang tidak boleh nol atau kosong!!");
        } else {
            this.props.setTrxProps(this.props.currentLocalTrxId, {
                payments: [
                    ...this.props.currentTrx.payments,
                    {
                        local_payment_id: uuid(),
                        payment_type: PAYMENT_TYPE_CREDIT,
                        payment_method: {
                            payment_method_name: "Piutang"
                        },
                        payment_amount: this.state.credit_amount,
                        payment_amountText: `${this.state.credit_amount}`,
                        payment_ref: this.state.credit_ref
                    }
                ]
            });

            const { currentTrx, posConfig } = this.props;
            const grandTotal = PosPriceCalculator.calculateGrandTotal(
                currentTrx,
                posConfig,
                this.currencyCode
            ).grandTotal;
            const reducer = (accumulator, currentValue) =>
                accumulator + currentValue.payment_amount;
            let totalPayment = currentTrx.payments.reduce(reducer, 0);
            let unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment) - this.state.credit_amount;
            this.setState({
                credit_confirmed: true,
                credit_amount: 0,
                credit_amountText: '0',
                credit_ref: '',
                deposit_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                deposit_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0',
                payment_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                payment_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0'
            });
        }
    };

    deletePayment = local_payment_id => {
        const that = this;
        Modal.confirm({
            title: "Pembayaran akan dihapus",
            content: "Apakah Anda yakin ingin menghapus?",
            onOk: () => {
                const selectedPayment = this.props.currentTrx.payments.find(
                    row => row.local_payment_id === local_payment_id
                );
                if (selectedPayment.payment_type === PAYMENT_TYPE_DEPOSIT) {
                    that.setState(this.initialDepositPaymentState);
                }
                if (selectedPayment.payment_type === PAYMENT_TYPE_CREDIT) {
                    that.setState(this.initialCreditPaymentState);
                }
                this.props.setTrxProps(this.props.currentLocalTrxId, {
                    payments: this.props.currentTrx.payments.filter(
                        row => row.local_payment_id !== local_payment_id
                    )
                });

                const { currentTrx, posConfig } = this.props;
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    currentTrx,
                    posConfig,
                    this.currencyCode
                ).grandTotal;
                const reducer = (accumulator, currentValue) =>
                    accumulator + currentValue.payment_amount;
                let totalPayment = currentTrx.payments.reduce(reducer, 0);
                let unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment) + selectedPayment.payment_amount;
                this.setState({
                    credit_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                    credit_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0',
                    deposit_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                    deposit_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0',
                    payment_amount: unpaidRemainder > 0 ? unpaidRemainder : 0,
                    payment_amountText: unpaidRemainder > 0 ? `${unpaidRemainder}` : '0'
                });
            }
        });
    };

    onChangeRedeemCode = e => {
        this.props.setTrxProps(this.props.currentLocalTrxId, {
            redeem: {
                ...this.props.currentTrx.redeem,
                redeem_code: e.target.value
            }
        });
    };

    resendRedeemSms = e => {
        const hideLoading = message.loading('Mengirim ulang SMS..', 0);

        apiCall(
            endpoint.POST_RESEND_SMS_REDEEM,
            'post',
            {
                reward_redeem_sms_id: this.props.currentTrx.redeem.redeem_item.reward_redeem_sms_id
            },
            result => {
                hideLoading();
                message.success('SMS berhasil dikirim ulang');
                this.setState({
                    redeemSmsTimer: 30
                }, () => {
                    this.startRedeemSmsTimer();
                });
            },
            error => {
                hideLoading();
                ErrorHandler.handleGeneralError(error);
            }
        );
    };

    startRedeemSmsTimer = () => {
        this.resendSmsTimeout = setTimeout(() => {
            if(this.state.redeemSmsTimer > 0){
                this.setState({
                    redeemSmsTimer: this.state.redeemSmsTimer - 1
                }, this.startRedeemSmsTimer);
            }else{
                clearTimeout(this.resendSmsTimeout);
            }
        }, 1000);
    };

    refreshBalance = () => {
        const { currentTrx, currentLocalTrxId, posConfig } = this.props;
        const refreshBalanceLoader = message.loading('Memuat ulang saldo..', 0);

        this.setState({
            isRefreshingBalance: true
        });

        apiCall(
            endpoint.GET_CUSTOMER_INFO,
            'post',
            {
                user_identifier_type: currentTrx.customer.user_identifier_type,
                user_identifier_string: currentTrx.customer.user_identifier_string
            },
            result => {
                refreshBalanceLoader();
                message.success('Saldo berhasil dimuat ulang');
                this.setState({
                    isRefreshingBalance: false
                });

                this.props.setTrxCustomer(currentLocalTrxId, {
                    ...currentTrx.customer,
                    deposit_amount: result.data.deposit_amount
                });

                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    currentTrx,
                    posConfig,
                    this.currencyCode
                ).grandTotal;
                const reducer = (accumulator, currentValue) =>
                    accumulator + currentValue.payment_amount;
                const totalPayment = currentTrx.payments.reduce(reducer, 0);
                const unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment);
                if (unpaidRemainder > 0) {
                    this.setState({
                        deposit_amount: !empty(currentTrx.customer) ? (currentTrx.customer.deposit_amount < unpaidRemainder ? currentTrx.customer.deposit_amount : unpaidRemainder) : 0,
                        deposit_amountText: !empty(currentTrx.customer) ? (currentTrx.customer.deposit_amount < unpaidRemainder ? `${currentTrx.customer.deposit_amount}` : `${unpaidRemainder}`) : '0'
                    });
                }
            },
            error => {
                refreshBalanceLoader();
                ErrorHandler.handleGeneralError(error);
                this.setState({
                    isRefreshingBalance: false
                });
            }
        );
    }

    render() {
        const {
            payment_method,
            payment_amount,
            payment_amountText,
            payment_ref,
            deposit_confirmed,
            deposit_amount,
            deposit_amountText,
            credit_confirmed,
            credit_amount,
            credit_amountText,
            credit_ref,
            redeemSmsTimer,
            isRefreshingBalance
        } = this.state;
        const { currentTrx, posConfig } = this.props;
        const paymentMethods = this.props.posConfig.payment_method;
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            this.currencyCode
        ).grandTotal;
        const reducer = (accumulator, currentValue) =>
            accumulator + currentValue.payment_amount;
        const totalPayment = currentTrx.payments.reduce(reducer, 0);
        const unpaidRemainder = Math.round(grandTotal) - parseInt(totalPayment);

        let depositEligible = true;
        /*if(posConfig.wallet_usage_restriction_on_coupon === 1 && currentTrx.trx_type === TRX_TYPE_COUPON_SALES){
            depositEligible = false;
        }*/

        currentTrx.details.map(detail => {
            if(currentTrx.trx_type === TRX_TYPE_COUPON_SALES){
                if(posConfig.businessWallet_restrictedPosItemCategories?.some(row => row.pos_item_category_id === detail.itemMeta.coupon_meta.coupon_category_id)){
                    console.log(detail.itemMeta.coupon_meta.pos_item_category_id);
                    depositEligible = false;
                }
            }else{
                if(posConfig.businessWallet_restrictedPosItemCategories?.some(row => row.pos_item_category_id === detail.itemMeta.pos_item_category_id)){
                    depositEligible = false;
                }
            }

            return detail;
        });

        return (
            <Row gutter={24}>
                <Col md={12}>
                    <div className={styles.formItem}>
                        {unpaidRemainder >= 0 && (
                            <React.Fragment>
                                <h4>Sisa Pembayaran</h4>
                                <span className={unpaidRemainder===0?styles.paymentRemainderChange:styles.paymentRemainder}>
                                    {unpaidRemainder===0 && <Icon type="check-circle" />} {this.currencyCode} {number_format(unpaidRemainder)},-
                                </span>
                            </React.Fragment>
                        )}

                        {unpaidRemainder < 0 && (
                            <React.Fragment>
                                <h4>Uang Kembalian</h4>
                                <span className={styles.paymentRemainderChange}>
                                    <Icon type="check-circle" /> {this.currencyCode} {number_format(-1*unpaidRemainder)},-
                                </span>
                            </React.Fragment>
                        )}
                    </div>

                    <div className={styles.formItem}>
                        <h4>Pembayaran</h4>
                        {empty(currentTrx.payments) && (
                            <div>Belum ada pembayaran</div>
                        )}

                        {!empty(currentTrx.payments) && (
                            <List
                                bordered
                                dataSource={currentTrx.payments}
                                renderItem={row => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar
                                                    size="large"
                                                    style={{
                                                        backgroundColor:
                                                            "transparent",
                                                        color: GREEN
                                                    }}
                                                    icon={
                                                        row.payment_type ===
                                                        PAYMENT_TYPE_DEPOSIT
                                                            ? "wallet"
                                                            : row.payment_method
                                                                  .payment_method_name ===
                                                              "Cash"
                                                            ? "dollar"
                                                            : "credit-card"
                                                    }
                                                />
                                            }
                                            title={
                                                row.payment_method
                                                    .payment_method_name
                                            }
                                            description={
                                                <span>
                                                    <div style={{
                                                        fontSize: 18
                                                    }}>
                                                        <strong>{this.currencyCode} {number_format( row.payment_amount )},-</strong>
                                                    </div>

                                                    {row.payment_method.is_charge_to_customer === 1 && (
                                                        <div style={{marginTop: 5}}>
                                                            {row.payment_method.charge_label}: {this.currencyCode} {number_format(row.payment_charge, 0)}
                                                            <br/>
                                                            Total: <strong>{this.currencyCode} {number_format(row.payment_charge + row.payment_amount, 0)}</strong>
                                                        </div>
                                                    )}

                                                    {!empty(row.payment_ref) && (
                                                        <div>
                                                            Ref: {row.payment_ref}
                                                        </div>
                                                    )}
                                                </span>
                                            }
                                        />
                                        <button
                                            className={styles.linkButton}
                                            onClick={() => {
                                                this.deletePayment(
                                                    row.local_payment_id
                                                );
                                            }}
                                        >
                                            Hapus
                                        </button>
                                    </List.Item>
                                )}
                            />
                        )}

                        {!empty(currentTrx.redeem) &&
                        currentTrx.redeem.redeem_type === "sms" && (
                            <div
                                className={styles.formItem}
                            >
                                <h4
                                    style={{
                                        marginTop: 15
                                    }}
                                >
                                    Kode Redeem dari SMS
                                </h4>
                                <Input
                                    size="large"
                                    style={{
                                        width: 400,
                                        maxWidth: "100%"
                                    }}
                                    onChange={
                                        this
                                            .onChangeRedeemCode
                                    }
                                    value={
                                        this.props
                                            .currentTrx
                                            .redeem
                                            .redeem_code
                                    }
                                />
                                <br/>
                                <Button type="link"
                                        size="small"
                                        style={{ marginTop: 10 }}
                                        htmlType="button"
                                        onClick={this.resendRedeemSms}
                                        disabled={redeemSmsTimer!==0}
                                >{redeemSmsTimer === 0 ? `Kirim Ulang SMS` : `${redeemSmsTimer} detik untuk mengirim ulang`}</Button>
                            </div>
                        )}

                        {!empty(
                            currentTrx.payments.find(
                                row =>
                                    row.payment_type ===
                                    PAYMENT_TYPE_DEPOSIT
                            )
                        ) &&
                        posConfig.use_wallet_security_code ===
                        1 && (
                            <div
                                className={styles.formItem}
                            >
                                <h4
                                    style={{
                                        marginTop: 15
                                    }}
                                >
                                    Kode Receh
                                </h4>
                                <Input
                                    size="large"
                                    style={{
                                        width: 400,
                                        maxWidth: "100%"
                                    }}
                                    onChange={e => {
                                        this.props.setTrxProps(
                                            this.props
                                                .currentLocalTrxId,
                                            {
                                                receh_code:
                                                e.target
                                                    .value
                                            }
                                        );
                                    }}
                                    value={
                                        this.props
                                            .currentTrx
                                            .receh_code
                                    }
                                />
                            </div>
                        )}
                    </div>
                </Col>

                <Col md={12}>
                    {unpaidRemainder > 0 && (
                        <div className={styles.newPayment}>
                            <form onSubmit={this.addNormalPayment}>
                                <h2 style={{ textAlign: "left" }}>
                                    Tambah Pembayaran Baru
                                </h2>
                                {empty(paymentMethods) && (
                                    <span>
                                        Seluruh metode pembayaran telah
                                        terpakai.
                                    </span>
                                )}

                                {!empty(paymentMethods) && (
                                    <React.Fragment>
                                        <div className={styles.formItem}>
                                            <h4>Pilih Metode Pembayaran</h4>
                                            <Radio.Group
                                                defaultValue={
                                                    !empty(payment_method)
                                                        ? payment_method.table_name +
                                                          "-" +
                                                          payment_method.table_id
                                                        : ""
                                                }
                                                buttonStyle="solid"
                                                onChange={
                                                    this.onChangePaymentMethodId
                                                }
                                                value={
                                                    empty(payment_method)
                                                        ? ""
                                                        : payment_method.table_name +
                                                          "-" +
                                                          payment_method.table_id
                                                }
                                            >
                                                {paymentMethods.map(
                                                    (paymentMethod, index) => {
                                                        return (
                                                            <Radio.Button
                                                                value={
                                                                    paymentMethod.table_name +
                                                                    "-" +
                                                                    paymentMethod.table_id
                                                                }
                                                                key={index}
                                                            >
                                                                {
                                                                    paymentMethod.payment_method_name
                                                                }
                                                            </Radio.Button>
                                                        );
                                                    }
                                                )}
                                            </Radio.Group>
                                        </div>

                                        {/*{!empty(payment_method) && payment_method.is_charge_to_customer === 1 && (
                                            <div className={styles.formItem}>
                                                <Checkbox defaultChecked={true}>Bebankan biaya kepada pelanggan ({parseFloat(payment_method.charge_percentage)}% + {this.currencyCode} {number_format(parseFloat(payment_method.charge_value), 0)})</Checkbox>
                                            </div>
                                        )}*/}

                                        {!empty(payment_method) && (
                                            <div className={styles.formItem}>
                                                <h4>Jumlah Pembayaran ({this.currencyCode})</h4>
                                                <Input
                                                    defaultValue={0}
                                                    type="text"
                                                    addonBefore={this.currencyCode}
                                                    size="large"
                                                    onChange={e => {
                                                        const inputCallback = (value, valueText) => {
                                                            this.setState({
                                                                payment_amount: value,
                                                                payment_amountText: valueText
                                                            });
                                                        };
                                                        processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                                    }}
                                                    value={payment_amountText}
                                                />
                                                {payment_method.is_charge_to_customer === 1 && (
                                                    <div style={{marginTop: 5}}>
                                                        {payment_method.charge_label}: {this.currencyCode} {number_format((payment_amount * (parseFloat(payment_method.charge_percentage) / 100)) + parseFloat(payment_method.charge_value), 0)}
                                                        <br/>
                                                        Total: <strong>{this.currencyCode} {number_format(((payment_amount * (parseFloat(payment_method.charge_percentage) / 100)) + parseFloat(payment_method.charge_value)) + payment_amount, 0)}</strong>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {!empty(payment_method) &&
                                            payment_method.is_reference_required ===
                                                1 && (
                                                <div
                                                    className={styles.formItem}
                                                >
                                                    <h4>
                                                        Kode Referensi
                                                    </h4>
                                                    <Input
                                                        size="large"
                                                        style={{
                                                            width: 400,
                                                            maxWidth: "100%"
                                                        }}
                                                        onChange={e => {
                                                            this.setState({
                                                                payment_ref: e.target.value
                                                            });
                                                        }}
                                                        value={payment_ref}
                                                    />
                                                </div>
                                            )}

                                        <div className={styles.formItem}>
                                            <Button
                                                htmlType="submit"
                                                disabled={
                                                    empty(payment_method) ||
                                                    empty(payment_amount)
                                                }
                                                type="primary"
                                            >
                                                Tambahkan
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </form>
                        </div>
                    )}

                    <Divider />

                    {currentTrx.trx_type !== TRX_TYPE_TOP_UP_DEPOSIT &&
                    posConfig.subscription_rules &&
                    posConfig.subscription_rules.deposit &&
                    !empty(currentTrx.customer) &&
                    !deposit_confirmed &&
                    unpaidRemainder > 0 &&
                    depositEligible
                    && (
                        <div className={styles.newPayment}>
                            <form onSubmit={this.addDepositPayment}>
                                <h2 style={{ textAlign: "left", color: GREEN }}>
                                    Gunakan Deposit
                                </h2>

                                <div className={styles.formItem}>
                                    <h4>Jumlah Pembayaran ({this.currencyCode})</h4>
                                    <Input
                                        defaultValue={0}
                                        type="text"
                                        addonBefore={this.currencyCode}
                                        size="large"
                                        onChange={e => {
                                            const inputCallback = (value, valueText) => {
                                                this.setState({
                                                    deposit_amount: value,
                                                    deposit_amountText: valueText
                                                });
                                            };
                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                        }}
                                        value={deposit_amountText}
                                    />
                                </div>

                                <p>Saldo: {this.currencyCode} {number_format(currentTrx.customer.deposit_amount)}</p>

                                <div className={styles.formItem}>
                                    <Button
                                        htmlType="submit"
                                        disabled={empty(deposit_amount)}
                                        type="primary"
                                        style={{background: GREEN}}
                                    >
                                        Gunakan Deposit
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        htmlType="button"
                                        disabled={isRefreshingBalance}
                                        type="link"
                                        onClick={this.refreshBalance}
                                        // style={{background: GREEN}}
                                    >
                                        Refresh Saldo
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}

                    {posConfig.pos_account_receivable === 1 &&
                        !empty(currentTrx.customer) &&
                        !credit_confirmed &&
                        unpaidRemainder > 0 &&
                        currentTrx.trx_type !== TRX_TYPE_ACCOUNT_RECEIVABLE && (
                        <div className={styles.newPayment}>
                            <form onSubmit={this.addCreditPayment}>
                                <h2 style={{ textAlign: "left", color: RED }}>
                                    Gunakan Piutang
                                </h2>

                                <div className={styles.formItem}>
                                    <h4>Jumlah Pembayaran ({this.currencyCode})</h4>
                                    <Input
                                        defaultValue={0}
                                        type="text"
                                        addonBefore={this.currencyCode}
                                        size="large"
                                        onChange={e => {
                                            const inputCallback = (value, valueText) => {
                                                this.setState({
                                                    credit_amount: value,
                                                    credit_amountText: valueText
                                                });
                                            };
                                            processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
                                        }}
                                        value={credit_amountText}
                                    />
                                </div>

                                <div
                                    className={styles.formItem}
                                >
                                    <h4>
                                        Kode Referensi
                                    </h4>
                                    <Input
                                        size="large"
                                        style={{
                                            width: 400,
                                            maxWidth: "100%"
                                        }}
                                        onChange={e => {
                                            this.setState({
                                                credit_ref:
                                                e.target
                                                    .value
                                            });
                                        }}
                                        value={credit_ref}
                                    />
                                </div>

                                <div className={styles.formItem}>
                                    <Button
                                        htmlType="submit"
                                        disabled={empty(credit_amount)}
                                        type="danger"
                                    >
                                        Gunakan Piutang
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    let currentTrx = {};
    if (!empty(currentLocalTrxId)) {
        currentTrx = state.posState.posTrx.find(
            trx => trx.local_trx_id === currentLocalTrxId
        );
    }

    return {
        currentLocalTrxId,
        currentTrx,
        posTrx: state.posState.posTrx,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    setPosConfig: posOperations.setPosConfig,
    setNewLocalTrx: posOperations.setNewLocalTrx,
    setTrxProps: posOperations.setTrxProps,
    resetTrx: posOperations.resetTrx,
    deleteTrx: posOperations.deleteTrx,
    addTrxDetail: posOperations.addTrxDetail,
    editTrxDetail: posOperations.editTrxDetail,
    setTrxCustomer: posOperations.setTrxCustomer
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosPayments);
