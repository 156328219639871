import React from 'react';
import PropTypes from 'prop-types';
import styles from './PosItem.module.scss';
import {
    prepImageUrl,
    generateInitialDummyImageUrl
} from '../../helpers/imageHelper';

const PosItem = props => {
    return (
        <div className={styles.posItemWrapper} onClick={props.onClick}>
            <img
                src={prepImageUrl(
                    props.itemImage,
                    generateInitialDummyImageUrl(props.itemName, '150x125')
                )}
                alt={props.itemName}
            />
            <div className={styles.posItemName}>{props.itemName}</div>
        </div>
    );
};

PosItem.propTypes = {
    posItemId: PropTypes.number.isRequired,
    itemName: PropTypes.string.isRequired,
    itemImage: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

PosItem.defaultProps = {
    itemImage: ''
};

export default PosItem;
