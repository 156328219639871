import React, { Component } from "react";
import { empty } from "../../shared/helpers/generalHelper";
import { number_format } from "../../shared/helpers/stringHelper";
import styles from "./SettlementSummary.module.scss";
import PosPriceCalculator from "../../shared/classes/PosPriceCalculator";
import moment from "moment";
import { DEFAULT_CURRENCY_SIGN, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT, PAYMENT_TYPE_CREDIT, PAYMENT_TYPE_DEPOSIT, RECEIPT_TEMPLATE_DEFAULT, RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END, TRX_TYPE_ACCOUNT_RECEIVABLE, TRX_TYPE_COUPON_SALES, TRX_TYPE_MEMBERSHIP_SALES, TRX_TYPE_NORMAL, TRX_TYPE_TOP_UP_DEPOSIT } from "../../shared/utils/constants";
import { connect } from "react-redux";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import { Button, message } from "antd";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import { Loader } from "react-overlay-loader";
import Synchronizer from "../../shared/classes/Synchronizer";
import { posOperations } from "../../state/ducks/pos";

class SettlementSummary extends Component {
    constructor(props) {
        super(props);

        this.settlementData = {
            posTrxs: this.props.posTrx,
            posConfig: this.props.userData,
            configs: this.props.configs,
            signedInAt: localStorage.getItem("signed_in_at")
        };

        this.state = {
            printSize: !empty(this.settlementData.configs.printerPaperSize) ? this.settlementData.configs.printerPaperSize : "8cm",
            displayMode: !empty(this.props.configs.displayMode) ? this.props.configs.displayMode : "DESKTOP",
            settlementData: null,
            pageLoading: false,
            loadingText: "",
            startTime: ( (this.props.match.params.settlement_type === 'today' || this.props.match.params.settlement_type === 'today-by-outlet') ? moment().format(MOMENT_SQL_DATE_FORMAT) + ' 00:00:00' : localStorage.getItem("signed_in_at") ),
            endTime: moment().format(MOMENT_SQL_DATETIME_FORMAT)
        };
        this.currencyCode = this.props.userData.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        // let signedInAt = localStorage.getItem("signed_in_at");
        // if(this.props.match.params.settlement_type === 'today' || this.props.match.params.settlement_type === 'today-by-outlet'){
        //     signedInAt = moment().format(MOMENT_SQL_DATE_FORMAT) + ' 00:00:00';
        // }
        //
        // const now = moment().format(MOMENT_SQL_DATETIME_FORMAT);

        this.setState({
            pageLoading: true,
            loadingText: "Synchronizing Transactions.."
        });

        Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            true,
            () => {
                this.setState({
                    pageLoading: true,
                    loadingText: "Transactions Synchronized, loading settlement data.."
                });

                apiCall(
                    endpoint.GET_SETTLEMENT_DATA + `?start_datetime=${this.state.startTime}&end_datetime=${this.state.endTime}&all_trx=1&group_by=${this.props.match.params.settlement_type === 'today-by-outlet' ? 'outlet' : 'user'}`,
                    "GET",
                    null,
                    response => {
                        this.setState({
                            settlementData: response.data
                        });
                    },
                    response => {
                        ErrorHandler.handleGeneralError(response);
                    },
                    () => {
                        this.setState({
                            pageLoading: false,
                            loadingText: ""
                        });
                    }
                );
            }
        ).catch(err => {
            message.error("Gagal melakukan proses sinkronisasi, silakan mencoba kembali dengan refresh halaman ini.");
        });

    }

    renderItemSub = (index, label, nominal) => {
        if (!empty(label)) {
            return (
                <tr className={styles.itemSubRow} key={index}>
                    <td colSpan={3} className={styles.itemSub}>
                        {label} {!empty(nominal) ? `(${nominal})` : ""}
                    </td>
                </tr>
            );
        }
    };

    renderPaymentMethodsRemote = (payment_summary) => {
        return payment_summary.map(row => {
            return (
                <React.Fragment key={`PaymentMethod${row.payment_method_name}`}>
                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            {row.payment_method_name}
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(row.trx_count, 0)} transaksi
                        </td>
                    </tr>
                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            &nbsp;
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(row.payment_after_change_sum, 0)}
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    renderPaymentMethods = (posTrxs, posConfig) => {
        return posConfig.payment_method.map(row => {
            const completedTrx = posTrxs.filter(
                trx => {
                    return !empty(trx.payments.find(payment => (
                        trx.trx_status_id === 3 &&
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )));
                }
            );

            const paymentReducer = (total, payment) => {
                return total + payment.payment_amount;
            };
            const completedTrxCount = completedTrx.length;
            const completedTrxReducer = (total, trx) => {
                let change = 0;
                if (row.payment_method_name === "Cash") {
                    const grandTotal = PosPriceCalculator.calculateGrandTotal(
                        trx, posConfig, this.currencyCode
                    ).grandTotal;
                    const reducer = (accumulator, currentValue) =>
                        accumulator + currentValue.payment_amount;
                    const totalPayment = trx.payments.reduce(reducer, 0);
                    change = Math.round(grandTotal) - parseInt(totalPayment);
                }

                return total + change +
                    trx.payments.filter(payment => (
                        trx.trx_status_id === 3 &&
                        payment.payment_method.table_name === row.table_name &&
                        payment.payment_method.table_id === row.table_id
                    )).reduce(paymentReducer, 0);
            };
            const completedTrxTotal = completedTrx.reduce(
                completedTrxReducer,
                0
            );

            return (
                <React.Fragment key={`PaymentMethod${row.payment_method_name}`}>
                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            {row.payment_method_name}
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(completedTrxCount, 0)} transaksi
                        </td>
                    </tr>
                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            &nbsp;
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(completedTrxTotal, 0)}
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    renderAccountReceivablePayment = (posTrxs, posConfig) => {
        const completedTrx = posTrxs.filter(
            trx => {
                return !empty(trx.payments.find(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_CREDIT
                )));
            }
        );

        const paymentReducer = (total, payment) => {
            return total + payment.payment_amount;
        };
        const completedTrxCount = completedTrx.length;
        const completedTrxReducer = (total, trx) => {
            return total +
                trx.payments.filter(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_CREDIT
                )).reduce(paymentReducer, 0);
        };
        const completedTrxTotal = completedTrx.reduce(
            completedTrxReducer,
            0
        );

        return (
            <React.Fragment key={`PaymentMethodAccountReceivable`}>
                <tr className={styles.itemRow}>
                    <td className={styles.itemName}>
                        Piutang
                    </td>
                    <td className={styles.itemSubTotal}>
                        {number_format(completedTrxCount, 0)} transaksi
                    </td>
                </tr>
                <tr className={styles.itemRow}>
                    <td className={styles.itemName}>
                        &nbsp;
                    </td>
                    <td className={styles.itemSubTotal}>
                        {number_format(completedTrxTotal, 0)}
                    </td>
                </tr>
            </React.Fragment>
        );
    };

    renderDepositPayment = (posTrxs, posConfig) => {
        const completedTrx = posTrxs.filter(
            trx => {
                return !empty(trx.payments.find(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_DEPOSIT
                )));
            }
        );

        const paymentReducer = (total, payment) => {
            return total + payment.payment_amount;
        };
        const completedTrxCount = completedTrx.length;
        const completedTrxReducer = (total, trx) => {
            return total +
                trx.payments.filter(payment => (
                    trx.trx_status_id === 3 &&
                    payment.payment_type === PAYMENT_TYPE_DEPOSIT
                )).reduce(paymentReducer, 0);
        };
        const completedTrxTotal = completedTrx.reduce(
            completedTrxReducer,
            0
        );

        return (
            <React.Fragment key={`PaymentMethodDeposit`}>
                <tr className={styles.itemRow}>
                    <td className={styles.itemName}>
                        Deposit
                    </td>
                    <td className={styles.itemSubTotal}>
                        {number_format(completedTrxCount, 0)} transaksi
                    </td>
                </tr>
                <tr className={styles.itemRow}>
                    <td className={styles.itemName}>
                        &nbsp;
                    </td>
                    <td className={styles.itemSubTotal}>
                        {number_format(completedTrxTotal, 0)}
                    </td>
                </tr>
            </React.Fragment>
        );
    };

    renderItemsRemote = (item_summary) => {
        let total = 0;

        return item_summary.map(item => {
            total += parseFloat(item.gross_total_sum);

            return (
                <React.Fragment key={`Item${item.pos_item_id}`}>
                    <tr className={styles.itemRow}>
                        <td colSpan={2} className={styles.itemName}>
                            {item.item_name}{!empty(item.variant_name) && ` - ${item.variant_name}`}
                        </td>
                    </tr>
                    <tr className={styles.itemRow}>
                        <td>
                            x{number_format(item.qty, 0)} @{number_format(item.variant_price, 0)}
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(item.gross_total_sum, 0)}
                        </td>
                    </tr>
                </React.Fragment>
            );
        }).concat((
            <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                <td className={`${styles.itemName}`}>
                    <strong>TOTAL</strong>
                </td>
                <td className={styles.itemSubTotal}>
                    <strong>{number_format(total, 0)}</strong>
                </td>
            </tr>
        ));

    };

    renderItems = (posTrxs, posConfig) => {
        let items = [];
        let total = 0;

        posTrxs.map(trx => {
            trx.details.map(detail => {
                const chosenVariant = detail.itemMeta.pos_item_variants.find(row => row.pos_item_variant_id === detail.pos_item_variant_id);
                total += (chosenVariant.variant_price * detail.qty);

                if (empty(items.find(
                    item => item.pos_item_id === detail.pos_item_id && item.pos_item_variant_id === detail.pos_item_variant_id
                ))) {
                    items.push({
                        pos_item_id: detail.pos_item_id,
                        pos_item_variant_id: detail.pos_item_variant_id,
                        item_name: detail.itemMeta.item_name,
                        variant_name: chosenVariant.variant_name,
                        qty: detail.qty,
                        price: chosenVariant.variant_price
                    });
                } else {
                    items = items.map(item => {
                        if (item.pos_item_id === detail.pos_item_id && item.pos_item_variant_id === detail.pos_item_variant_id) {
                            return {
                                ...item,
                                qty: item.qty + detail.qty
                            };
                        } else {
                            return item;
                        }
                    });
                }

                return detail;
            });

            return trx;
        });

        return items.map(item => {
            return (
                <React.Fragment key={`Item${item.pos_item_id}-${item.pos_item_variant_id}`}>
                    <tr className={styles.itemRow}>
                        <td colSpan={2} className={styles.itemName}>
                            {item.item_name}{!empty(item.variant_name) && ` - ${item.variant_name}`}
                        </td>
                    </tr>
                    <tr className={styles.itemRow}>
                        <td>
                            x{number_format(item.qty, 0)} @{number_format(item.price, 0)}
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(item.price * item.qty, 0)}
                        </td>
                    </tr>
                </React.Fragment>
            );
        }).concat((
            <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                <td className={`${styles.itemName}`}>
                    <strong>TOTAL</strong>
                </td>
                <td className={styles.itemSubTotal}>
                    <strong>{number_format(total, 0)}</strong>
                </td>
            </tr>
        ));

    };

    renderItemSalesRemote = (item_summary, posConfig) => {
        if (!empty(item_summary)) {
            if(posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END){
                return (
                    <React.Fragment>
                        <div className={styles.receiptDivider}/>

                        <h3 className={styles.segmentTitle}><strong>Penjualan Service</strong></h3>

                        <table className={styles.itemListTable}>
                            <tbody>

                            {this.renderItemsRemote(item_summary.service)}

                            </tbody>
                        </table>

                        <div className={styles.receiptDivider}/>

                        <h3 className={styles.segmentTitle}><strong>Penjualan Retail</strong></h3>

                        <table className={styles.itemListTable}>
                            <tbody>

                            {this.renderItemsRemote(item_summary.retail)}

                            </tbody>
                        </table>
                    </React.Fragment>
                );
            }else{
                return (
                    <React.Fragment>
                        <div className={styles.receiptDivider}/>

                        <h3 className={styles.segmentTitle}><strong>Penjualan Barang / Service</strong></h3>

                        <table className={styles.itemListTable}>
                            <tbody>

                            {this.renderItemsRemote(item_summary)}

                            </tbody>
                        </table>
                    </React.Fragment>
                );
            }
        }
    };

    renderItemSales = (posTrxs, posConfig) => {
        const filteredPosTrxs = posTrxs.filter(trx => trx.trx_type === TRX_TYPE_NORMAL);
        if (!empty(filteredPosTrxs)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Penjualan Barang / Service</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>

                        {this.renderItems(filteredPosTrxs, posConfig)}

                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    renderCouponSalesRemote = (coupon_summary) => {
        if (!empty(coupon_summary)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Penjualan Kupon</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>

                        {this.renderItemsRemote(coupon_summary)}

                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    renderCouponSales = (posTrxs, posConfig) => {
        const filteredPosTrxs = posTrxs.filter(trx => trx.trx_type === TRX_TYPE_COUPON_SALES);
        if (!empty(filteredPosTrxs)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Penjualan Kupon</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>

                        {this.renderItems(filteredPosTrxs, posConfig)}

                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    renderDepositTopUpRemote = (top_up_deposit_summary) => {
        return (
            <React.Fragment>
                <div className={styles.receiptDivider}/>

                <h3 className={styles.segmentTitle}><strong>Top Up Deposit</strong></h3>

                <table className={styles.itemListTable}>
                    <tbody>
                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            Jumlah Transaksi
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(top_up_deposit_summary.trx_count, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                        <td className={`${styles.itemName}`}>
                            <strong>TOTAL</strong>
                        </td>
                        <td className={styles.itemSubTotal}>
                            <strong>{number_format(top_up_deposit_summary.drv_gross_total, 0)}</strong>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    };

    renderDepositTopUp = (posTrxs, posConfig) => {
        const filteredPosTrxs = posTrxs.filter(trx => trx.trx_type === TRX_TYPE_TOP_UP_DEPOSIT);
        const totalReducer = (total, trx) =>
            total +
            trx.drv_grand_total;
        const grandTotal = filteredPosTrxs.reduce(totalReducer, 0);

        if (!empty(filteredPosTrxs)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Top Up Deposit</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>
                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                Jumlah Transaksi
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(filteredPosTrxs.length, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                            <td className={`${styles.itemName}`}>
                                <strong>TOTAL</strong>
                            </td>
                            <td className={styles.itemSubTotal}>
                                <strong>{number_format(grandTotal, 0)}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    renderMembershipSalesRemote = (membership_summary) => {
        return (
            <React.Fragment>
                <div className={styles.receiptDivider}/>

                <h3 className={styles.segmentTitle}><strong>Penjualan Membership</strong></h3>

                <table className={styles.itemListTable}>
                    <tbody>
                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            Jumlah Transaksi
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(membership_summary.trx_count, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                        <td className={`${styles.itemName}`}>
                            <strong>TOTAL</strong>
                        </td>
                        <td className={styles.itemSubTotal}>
                            <strong>{number_format(membership_summary.drv_gross_total, 0)}</strong>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    };

    renderMembershipSales = (posTrxs, posConfig) => {
        const filteredPosTrxs = posTrxs.filter(trx => trx.trx_type === TRX_TYPE_MEMBERSHIP_SALES);
        const totalReducer = (total, trx) =>
            total +
            trx.drv_grand_total;
        const grandTotal = filteredPosTrxs.reduce(totalReducer, 0);

        if (!empty(filteredPosTrxs)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Penjualan Membership</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>
                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                Jumlah Transaksi
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(filteredPosTrxs.length, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                            <td className={`${styles.itemName}`}>
                                <strong>TOTAL</strong>
                            </td>
                            <td className={styles.itemSubTotal}>
                                <strong>{number_format(grandTotal, 0)}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    renderAccountReceivablePaymentsRemote = (account_receivable_summary) => {
        return (
            <React.Fragment>
                <div className={styles.receiptDivider}/>

                <h3 className={styles.segmentTitle}><strong>Pembayaran Piutang</strong></h3>

                <table className={styles.itemListTable}>
                    <tbody>
                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            Jumlah Transaksi
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(account_receivable_summary.trx_count, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                        <td className={`${styles.itemName}`}>
                            <strong>TOTAL</strong>
                        </td>
                        <td className={styles.itemSubTotal}>
                            <strong>{number_format(account_receivable_summary.drv_gross_total, 0)}</strong>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    };

    renderAccountReceivablePayments = (posTrxs, posConfig) => {
        const filteredPosTrxs = posTrxs.filter(trx => trx.trx_type === TRX_TYPE_ACCOUNT_RECEIVABLE);
        const totalReducer = (total, trx) =>
            total +
            trx.drv_grand_total;
        const grandTotal = filteredPosTrxs.reduce(totalReducer, 0);

        if (!empty(filteredPosTrxs)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Pembayaran Piutang</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>
                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                Jumlah Transaksi
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(filteredPosTrxs.length, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                            <td className={`${styles.itemName}`}>
                                <strong>TOTAL</strong>
                            </td>
                            <td className={styles.itemSubTotal}>
                                <strong>{number_format(grandTotal, 0)}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    renderTrxsSummaryRemote = (trx_summary, item_nature='') => {
        return (
            <React.Fragment>
                <div className={styles.receiptDivider}/>

                <h3 className={styles.segmentTitle}><strong>Ringkasan {item_nature === 'RETAIL'? 'Retail' : (item_nature === 'SERVICE' ? 'Service' : '')}</strong></h3>

                <table className={styles.itemListTable}>
                    <tbody>
                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            Penjualan Kotor
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(trx_summary.drv_gross_total, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Item Discount
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(trx_summary.discount_value, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Transaction Discount
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(trx_summary.trx_discount_value, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            &nbsp;
                        </td>
                        <td className={styles.itemSubTotal}>
                            &nbsp;
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Tax
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(trx_summary.drv_tax_value, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Service Charge
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(trx_summary.drv_service_charge_value, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow}`}>
                        <td className={`${styles.itemName}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Additional Charge
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(trx_summary.drv_additional_charge_value, 0)}
                        </td>
                    </tr>

                    <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                        <td className={`${styles.itemName}`}>
                            <strong>Penjualan Bersih</strong>
                        </td>
                        <td className={styles.itemSubTotal}>
                            <strong>{number_format(trx_summary.drv_grand_total, 0)}</strong>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    };

    renderTrxsSummary = (posTrxs, posConfig) => {
        const grossSalesItemPriceReducer = (total, detail) =>
            total +
            (detail.itemMeta.pos_item_variants.find(row => row.pos_item_variant_id === detail.pos_item_variant_id).variant_price * detail.qty);
        const grossSalesReducer = (total, trx) =>
            total +
            trx.details.reduce(grossSalesItemPriceReducer, 0);
        const totalGross = posTrxs.reduce(grossSalesReducer, 0);

        const taxReducer = (total, trx) => {
            const subTotal = PosPriceCalculator.calculateSubTotal(trx, this.currencyCode).subTotal;
            const serviceCharge = PosPriceCalculator.calculateServiceCharge(
                trx,
                posConfig.service_charge,
                this.currencyCode
            ).serviceChargeValue;
            const additionalCharge = PosPriceCalculator.calculateAdditionalCharge(
                trx,
                posConfig.additional_charge,
                this.currencyCode
            ).additionalChargeValue;

            if (trx.trx_type === TRX_TYPE_NORMAL || trx.trx_type === TRX_TYPE_COUPON_SALES) {
                return total +
                    PosPriceCalculator.calculateTax(subTotal, serviceCharge, additionalCharge, posConfig.tax, this.currencyCode).taxValue;
            } else {
                return total;
            }
        };
        const totalTax = posTrxs.reduce(taxReducer, 0);

        const serviceChargeReducer = (total, trx) => {
            const serviceCharge = PosPriceCalculator.calculateServiceCharge(
                trx,
                posConfig.service_charge,
                this.currencyCode
            ).serviceChargeValue;

            return total + serviceCharge;
        };
        const totalServiceCharge = posTrxs.reduce(serviceChargeReducer, 0);

        const additionalChargeReducer = (total, trx) => {
            const additionalCharge = PosPriceCalculator.calculateServiceCharge(
                trx,
                posConfig.service_charge,
                this.currencyCode
            ).additionalChargeValue;

            return total + additionalCharge;
        };
        const totalAdditionalCharge = posTrxs.reduce(additionalChargeReducer, 0);

        const itemDiscountReducer = (total, trx) => {
            const itemDiscountPriceReducer = (total, detail) =>
                total +
                PosPriceCalculator.calculateItemPrice(trx, detail, this.currencyCode).totalDiscountValue;

            return total +
                trx.details.reduce(itemDiscountPriceReducer, 0);
        };
        const totalItemDiscount = posTrxs.reduce(itemDiscountReducer, 0);

        const globalDiscountReducer = (total, trx) => {
            const globalDiscountPriceReducer = (total, detail) =>
                total +
                PosPriceCalculator.calculateGlobalDiscount(trx, PosPriceCalculator.calculateItemPrice(trx, detail, this.currencyCode).totalPriceValue, this.currencyCode)
                    .totalDiscountValue;

            return total +
                trx.details.reduce(globalDiscountPriceReducer, 0);
        };
        const totalGlobalDiscount = posTrxs.reduce(globalDiscountReducer, 0);

        const netSalesReducer = (total, trx) =>
            total +
            PosPriceCalculator.calculateGrandTotal(trx, posConfig, this.currencyCode).grandTotal/*trx.drv_grand_total*/;
        const totalNetSales = posTrxs.reduce(netSalesReducer, 0);

        if (!empty(posTrxs)) {
            return (
                <React.Fragment>
                    <div className={styles.receiptDivider}/>

                    <h3 className={styles.segmentTitle}><strong>Ringkasan</strong></h3>

                    <table className={styles.itemListTable}>
                        <tbody>
                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                Penjualan Kotor
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(totalGross, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Item Discount
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(totalItemDiscount, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Transaction Discount
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(totalGlobalDiscount, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                &nbsp;
                            </td>
                            <td className={styles.itemSubTotal}>
                                &nbsp;
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Tax
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(totalTax, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Service Charge
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(totalServiceCharge, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow}`}>
                            <td className={`${styles.itemName}`}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Additional Charge
                            </td>
                            <td className={styles.itemSubTotal}>
                                {number_format(totalAdditionalCharge, 0)}
                            </td>
                        </tr>

                        <tr className={`${styles.itemRow} ${styles.segmentTotal}`}>
                            <td className={`${styles.itemName}`}>
                                <strong>Penjualan Bersih</strong>
                            </td>
                            <td className={styles.itemSubTotal}>
                                <strong>{number_format(totalNetSales, 0)}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };


    render() {
        const {
            posConfig
        } = this.settlementData;
        const { settlementData, pageLoading, loadingText } = this.state;

        // let signedInAt = localStorage.getItem("signed_in_at");
        // if(this.props.match.params.settlement_type === 'today'){
        //     signedInAt = moment().format(MOMENT_SQL_DATE_FORMAT) + ' 00:00:00';
        // }

        return (
            <React.Fragment>
                <Loader fullPage loading={pageLoading} text={loadingText}/>
                {!empty(settlementData) && (
                    <div className={`${styles.outerWrapperPrint} ${this.state.printSize === "8cm" ? styles.outerWrapperPrint8cm : styles.outerWrapperPrint6cm} ${this.state.displayMode === "TABLET" ? styles.outerWrapperPrintTablet : styles.outerWrapperPrintDesktop}`}>
                        <table className={styles.headerTable}>
                            <tbody>
                            <tr>
                                <td
                                    colSpan={2}
                                    className={styles.brandName}
                                >
                                    {posConfig.brand_name}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={2}
                                    className={styles.outletName}
                                >
                                    {posConfig.outlet_name}
                                    <br/><br/>
                                    <strong>CASHIER SETTLEMENT</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Cashier</td>
                                <td className={styles.alignRight}>
                                    {posConfig.cashier_name}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Start
                                </td>
                                <td className={styles.alignRight}>
                                    {moment(
                                        this.state.startTime,
                                        MOMENT_SQL_DATETIME_FORMAT
                                    ).format("D MMM YYYY, HH:mm")}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    End
                                </td>
                                <td className={styles.alignRight}>
                                    {moment(
                                        this.state.endTime,
                                        MOMENT_SQL_DATETIME_FORMAT
                                    ).format("D MMM YYYY, HH:mm")}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        {posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END && (
                            <>
                                {this.renderItemSalesRemote({
                                    service: settlementData.service_item_summary,
                                    retail: settlementData.retail_item_summary
                                }, posConfig)}
                            </>
                        )}

                        {posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT && this.renderItemSalesRemote(settlementData.item_summary, posConfig)}

                        {this.renderCouponSalesRemote(settlementData.coupon_summary)}
                        {this.renderDepositTopUpRemote(settlementData.top_up_deposit_summary)}
                        {this.renderMembershipSalesRemote(settlementData.membership_summary)}
                        {this.renderAccountReceivablePaymentsRemote(settlementData.account_receivable_summary)}

                        {posConfig.receipt_template === RECEIPT_TEMPLATE_GROUP_DISCOUNT_AT_THE_END && (
                            <>
                                {this.renderTrxsSummaryRemote(settlementData.service_trx_summary, 'SERVICE')}
                                {this.renderTrxsSummaryRemote(settlementData.retail_trx_summary, 'RETAIL')}
                            </>
                        )}

                        {posConfig.receipt_template === RECEIPT_TEMPLATE_DEFAULT && this.renderTrxsSummaryRemote(settlementData.trx_summary)}


                        {/*<div className={styles.receiptDivider}/>

                <h3><strong>Income</strong></h3>

                <table className={styles.itemListTable}>
                    <tbody>

                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            Gross Sales
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(totalGross, 0)}
                        </td>
                    </tr>

                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            Tax
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(totalTax, 0)}
                        </td>
                    </tr>

                    </tbody>
                </table>*/}

                        <div className={styles.receiptDivider}/>

                        <h3 className={styles.segmentTitle}><strong>Pembayaran</strong></h3>

                        <table className={styles.itemListTable}>
                            <tbody>

                            {/*{this.renderDepositPayment(completedTrx, posConfig)}
                        {this.renderAccountReceivablePayment(completedTrx, posConfig)}*/}
                            {this.renderPaymentMethodsRemote(settlementData.payment_summary)}

                            {/*<tr>
                        <td colSpan={3}>
                            <div className={styles.receiptDivider}/>
                        </td>
                    </tr>

                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            Completed Transactions
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(completedTrxCount, 0)}
                        </td>
                    </tr>

                    <tr className={styles.itemRow}>
                        <td className={styles.itemName}>
                            Transactions Value
                        </td>
                        <td className={styles.itemSubTotal}>
                            {number_format(completedTrxTotal, 0)}
                        </td>
                    </tr>*/}

                            </tbody>
                        </table>

                        <br/>

                        <br/><br/><br/><br/>
                        <div style={{textAlign: 'center'}}>
                            ####
                        </div>
                    </div>
                )}

                {this.props.configs.displayMode === "TABLET" && (
                    <div className={styles.tabletActionButtons}>
                        <Button type={"primary"}
                                size={"large"}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    window.print();
                                }}
                                style={{ marginRight: 10 }}
                        >
                            Print
                        </Button>

                        <Button type={"danger"}
                                size={"large"}
                                onClick={() => {
                                    window.close();
                                }}
                        >
                            Tutup
                        </Button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

const mapStateToProps = state => {
    return {
        loyaltyTrx: state.loyaltyState.loyaltyTrx,
        posTrx: state.posState.posTrx,
        userData: state.authState.userData,
        configs: state.authState.configs,
        posState: state.posState
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettlementSummary);