import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-html5-camera-photo/build/css/index.css";
import {
    Card,
    Form,
    Input,
    Button,
    Row,
    Col,
    Spin,
    Icon,
    Select,
    message,
    Table} from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./InventoryTransferIn.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import connect from "react-redux/es/connect/connect";
import ErrorHandler from "../shared/classes/ErrorHandler";

class InventoryTransferIn extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            transferOuts: [],
            selectedTrx: null,
            inv_sloc_id: this.props.configs.storage_locations.length === 1 ? this.props.configs.storage_locations[0].inv_sloc_id : null,
            notes: null,
            formLoading: false
        };

        this.state = { ...this.initialState };
    }

    componentDidMount() {
        this.retrieveTransferOuts();
    }

    retrieveTransferOuts = () => {
        this.setState({ formLoading: true });
        apiCall(
            endpoint.POST_INVENTORY_TRANSFER_IN,
            "get",
            null,
            (res) => {
                this.setState({ transferOuts: res.data, formLoading: false });
            },
            err => {
                this.setState({ formLoading: false });
            }
        );
    };

    selectTrx = (trx_id) => {
        this.setState({
            step: 2,
            selectedTrx: this.state.transferOuts.find(trx => trx.trx_id === trx_id)
        });
    };

    unSelectTrx = () => {
        this.setState({
            step: 1,
            selectedTrx: null
        });
    };

    onFormSubmit = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            selectedTrx,
            inv_sloc_id,
            notes
        } = this.state;

        if (empty(inv_sloc_id)) {
            message.error("Lokasi stok penerimaan harus dipilih");
        } else {
            this.setState({ formLoading: true });
            apiCall(
                endpoint.POST_INVENTORY_TRANSFER_IN,
                "post",
                {
                    inv_stock_trf_head_id: selectedTrx.inv_stock_trf_head_id,
                    inv_sloc_id: inv_sloc_id,
                    notes: notes
                },
                (res) => {
                    message.success("Barang berhasil ditransfer masuk.");
                    this.setState(
                        this.initialState,
                        () => { this.retrieveTransferOuts() }
                    );
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }, () => {
                    this.setState({ formLoading: false });
                }
            );
        }
    };

    render() {
        const {
            step,
            notes,
            transferOuts,
            selectedTrx,
            formLoading
        } = this.state;
        const { configs } = this.props;

        const mappedTransferOuts = transferOuts.map(row => {
            let itemQtySum = 0;
            row.inv_stock_trf_details.map(detail => {
                itemQtySum += detail.qty;
                return detail;
            });

            return {
                trx_id: row.trx_id,
                trx_time: row.trx_time,
                item_qty_sum: itemQtySum,
                source_location_name: row.source_location_name
            }
        });

        const transferOutColumns = [
            {
                title: "Transfer ID",
                dataIndex: "trx_id",
                key: "trx_id"
            },
            {
                title: "Waktu Transfer",
                dataIndex: "trx_time",
                key: "trx_time"
            },
            {
                title: "Jumlah Barang",
                dataIndex: "item_qty_sum",
                key: "item_qty_sum"
            },
            {
                title: "Transfer Dari",
                dataIndex: "source_location_name",
                key: "source_location_name"
            },
            {
                title: "Action",
                key: "action",
                render: (text, record) => (
                    <span>
                        <Button size="small"
                                type="primary"
                                htmlType="button"
                                onClick={() => {this.selectTrx(record.trx_id)}}>Detail Barang</Button>
                    </span>
                )
            }
        ];

        const itemColumns = [
            {
                title: "Nama Barang",
                dataIndex: "item_name",
                key: "item_name"
            },
            {
                title: "Qty Saat Ini",
                dataIndex: "qty_now",
                key: "qty_now"
            },
            {
                title: "Qty Transfer",
                dataIndex: "qty",
                key: "qty"
            },
            {
                title: "Unit",
                dataIndex: "unit_name",
                key: "unit_name"
            }
        ];

        const pageTitle = (
            <React.Fragment>
                <Icon type="gold"/>
                &nbsp;&nbsp; Inventory - Transfer Masuk
            </React.Fragment>
        );
        return (
            <Card title={pageTitle} bordered={false}>
                <Form onSubmit={this.onFormSubmit}>
                    <Row gutter={48}>
                        <Col xxl={10} sm={24}>
                            <Spin spinning={formLoading}>
                                {step === 1 && (
                                    <div className={styles.formItem}>
                                        <Table rowKey="trx_id" columns={transferOutColumns} dataSource={mappedTransferOuts} pagination={false}/>
                                    </div>
                                )}

                                {step === 2 && !empty(selectedTrx) && (
                                    <React.Fragment>
                                        <div className={styles.formItem}>
                                            <label>Lokasi Stok Penerimaan</label>
                                            {!empty(configs.storage_locations) && (
                                                <Select
                                                    showSearch
                                                    placeholder="Pilih Lokasi Stok Penerimaan"
                                                    optionFilterProp="children"
                                                    defaultValue={configs.storage_locations.length === 1 ? configs.storage_locations[0].inv_sloc_id : null}
                                                    onChange={e => {
                                                        this.setState({ inv_sloc_id: e });
                                                    }}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {configs.storage_locations.map(row => {
                                                        return <Select.Option key={`StorageLocation${row.inv_sloc_id}`} value={row.inv_sloc_id}>{row.location_name}</Select.Option>;
                                                    })}

                                                </Select>
                                            )}
                                        </div>

                                        <h2>Detail Barang Transfer</h2>

                                        <div className={styles.formItem}>
                                            <Table rowKey="inv_stock_trf_detail_id" columns={itemColumns} dataSource={selectedTrx.inv_stock_trf_details} pagination={false}/>
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Catatan / Keterangan</label>
                                            <Input.TextArea
                                                type="text"
                                                rows={5}
                                                onChange={e => {
                                                    this.setState({ notes: e.target.value });
                                                }}
                                                value={notes}
                                            />
                                        </div>

                                        <div style={{ marginTop: 30 }}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                htmlType="submit"
                                            >
                                                Transfer Masuk
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                type="danger"
                                                size="large"
                                                htmlType="submit"
                                                onClick={this.unSelectTrx}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        configs: state.authState.userData
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryTransferIn);
