import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import "react-html5-camera-photo/build/css/index.css";
import { Avatar, Button, Card, Col, Empty, Form, Icon, Input, message, Modal, Radio, Row, Select, Spin, Table, Tabs, DatePicker } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./InventoryStockOut.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import connect from "react-redux/es/connect/connect";
import { posOperations } from "../state/ducks/pos";
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, INV_CONSUMPTION_TYPE_FOR_CUSTOMER, INV_CONSUMPTION_TYPE_GENERAL, INV_SRC_TYPE_CONSUMPTION, INV_SRC_TYPE_TO_BAD_STOCK, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../shared/utils/constants";
import uuid from "uuid/v4";
import ErrorHandler from "../shared/classes/ErrorHandler";
import PosPriceCalculator from "../shared/classes/PosPriceCalculator";
import { number_format } from "../shared/helpers/stringHelper";
import moment from "moment";
import { PosPriceList } from "../modules";

class InventoryStockOut extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            local_trx_id: uuid(),
            targetType: INV_SRC_TYPE_CONSUMPTION,
            consumptionType: null,
            posSalesLocalTrxId: null,
            savedSalesTrxs: null,
            pendingSalesTrxs: null,
            completedSalesTrxs: null,
            completedSalesTrxsDateFilter: "",
            completedTrxFilterKeyword: "",
            selectedSalesTrx: null,
            inv_sloc_id: this.props.posConfig.storage_locations.length === 1 ? this.props.posConfig.storage_locations[0].inv_sloc_id : null,
            fetchingItems: false,
            fetchedItems: [],
            selectedItem: null,
            currentStockQty: null,
            currentBadStockQty: null,
            cogs: null,
            qty: 0,
            notes: null,
            formLoading: false,
            itemSku: "",
            items: [],
            document_no: ""
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;

        this.qtyOut = React.createRef();
        this.itemSku = React.createRef();
        this.fetchItem = debounce(this.fetchItem, 500);
    }

    componentDidMount() {
        this.fetchItem("");
    }

    fetchItem = (value) => {
        this.setState({
            fetchedItems: [],
            fetchingItems: true
        });

        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?item_name=${value}`,
            "get",
            null,
            (res) => {
                this.setState({ fetchedItems: res.data, fetchingItems: false });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    fetchItemWithSku = () => {
        this.setState({ fetchingItems: true });
        apiCall(
            endpoint.GET_INVENTORY_SEARCH_ITEM + `?sku=${this.state.itemSku}`,
            "get",
            null,
            (res) => {
                if (empty(res.data)) {
                    message.error("Barang tidak ditemukan, mohon masukkan SKU yang tepat");
                    return;
                }

                this.setState({
                    selectedItem: res.data[0],
                    itemSku: "",
                    fetchingItems: false
                }, () => {
                    this.loadSelectedItemDetails();
                    this.qtyOut.current.focus();
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    onChangeItemSKU = e => {
        this.setState({
            itemSku: e.target.value
        });
    };

    handleSkuEnterPressed = e => {
        if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            this.onSubmitSKU();
        }
    };

    onSubmitSKU = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        let isValidForm = true;

        if (empty(this.state.itemSku)) {
            isValidForm = false;
            message.error("SKU produk harus diisi");
        }

        if (isValidForm) {
            this.fetchItemWithSku();
        }
    };

    loadSelectedItemDetails = () => {
        const { selectedItem, inv_sloc_id } = this.state;

        this.setState({
            formLoading: true
        });

        apiCall(
            endpoint.GET_INVENTORY_CHECK_ITEM_STOCK + `?pos_item_variant_id=${selectedItem.pos_item_variant_id}&inv_sloc_id=${inv_sloc_id}`,
            "get",
            null,
            (res) => {
                this.setState({
                    currentStockQty: res.data.qty,
                    currentBadStockQty: res.data.drv_bad_qty,
                    cogs: res.data.variant_cogs || 0
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
                this.setState({ selectedItem: null });
            },
            () => {
                this.setState({
                    formLoading: false
                });
            }
        );
    };

    addItem = () => {
        const { items, selectedItem, currentStockQty, currentBadStockQty, qty, bad_qty, cogs } = this.state;
        if (!empty(items.find(item => item.key === selectedItem.pos_item_variant_id))) {
            message.error("Barang ini sudah ada dalam daftar, silakan hapus terlebih dahulu untuk mengganti jumlah qty.");
        } else if (empty(qty) && empty(bad_qty)) {
            message.error("Qty barang tidak boleh nol.");
        } else if (isNaN(qty)) {
            message.error("Qty barang hanya boleh angka.");
        } else {
            this.setState({
                items: items.concat({
                    key: selectedItem.pos_item_variant_id,
                    item_name: selectedItem.item_name,
                    qty_now: currentStockQty,
                    bad_qty_now: currentBadStockQty,
                    qty: qty,
                    bad_qty: bad_qty,
                    unit_name: selectedItem.unit_name,
                    inv_unit_id: selectedItem.inv_unit_id,
                    cogs: cogs
                }),
                currentStockQty: null,
                currentBadStockQty: null,
                qty: 0,
                bad_qty: 0,
                selectedItem: null
            }, () => {
                if (this.props.posConfig.pos_item_search_by_sku === 1) {
                    this.itemSku.current.focus();
                }
            });
        }
    };

    deleteItem = (key) => {
        const that = this;
        Modal.confirm({
            title: "Hapus Barang",
            content: "Apakah Anda yakin ingin menghapus?",
            onOk() {
                that.setState({
                    items: that.state.items.filter(item => item.key !== key)
                });
            },
            onCancel() {
            }
        });
    };

    loadSavedSalesTrxs = () => {
        apiCall(
            endpoint.GET_ARCHIVED_TRXS,
            "get",
            null,
            response => {
                this.setState({
                    savedSalesTrxs: response.data
                });
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    loadPendingSalesTrxs = () => {
        const pendingTrxs = this.props.posTrxs.filter(trx => trx.trx_status_id === 2);
        this.setState({
            pendingSalesTrxs: pendingTrxs
        });
    };

    loadCompletedSalesTrxs = (trxDate = moment().format(MOMENT_SQL_DATE_FORMAT)) => {
        // const start_date = moment().subtract(7, "days").format(MOMENT_SQL_DATE_FORMAT);
        // const end_date = moment().format(MOMENT_SQL_DATE_FORMAT);

        apiCall(
            endpoint.GET_TRX_HISTORY + `?start_date=${trxDate}&end_date=${trxDate}`,
            "get",
            null,
            response => {
                this.setState({
                    completedSalesTrxs: response.data.trxs
                });
            },
            response => {
                ErrorHandler.handleGeneralError(response);
            }
        );
    };

    renderSalesTrxs = (trxs) => {
        const { posConfig } = this.props;
        const { completedSalesTrxsDateFilter, completedTrxFilterKeyword } = this.state;

        if (empty(trxs)) {
            return "";
        }

        return trxs
            .filter(trx => {
                if (empty(completedTrxFilterKeyword)) {
                    return true;
                }

                const customerName = String(
                    empty(trx.customer)
                        ? "GUEST"
                        : empty(trx.customer.user_name)
                            ? "GUEST"
                            : trx.customer.user_name
                );
                const customerIdentifierString = String(
                    empty(trx.customer)
                        ? "No Contact Information"
                        : empty(trx.customer.user_identifier_string)
                            ? "No Contact Information"
                            : trx.customer.user_identifier_string
                );
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    trx,
                    this.props.posConfig,
                    this.currencyCode
                ).grandTotal;
                let totalQty = 0;
                if (!empty(trx.details)) {
                    trx.details.map(detail => {
                        totalQty += detail.qty;
                        return detail;
                    });
                }
                const totalQtyString = number_format(totalQty, this.props.posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);
                const totalString = `${totalQtyString} Produk, Total ${this.currencyCode} ${number_format(
                    grandTotal,
                    0
                )}`;
                const timeString = `${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).format(
                    MOMENT_READABLE_DATETIME_FORMAT
                )} (${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).fromNow()})`;
                return (
                    String(customerName).toLowerCase().includes(String(completedTrxFilterKeyword).toLowerCase()) ||
                    String(customerIdentifierString).toLowerCase().includes(String(completedTrxFilterKeyword).toLowerCase()) ||
                    String(trx.local_trx_id).toLowerCase().includes(String(completedTrxFilterKeyword).toLowerCase()) ||
                    String(totalString).toLowerCase().includes(String(completedTrxFilterKeyword).toLowerCase()) ||
                    String(timeString).toLowerCase().includes(String(completedTrxFilterKeyword).toLowerCase()) ||
                    String(trx.trx_label).toLowerCase().includes(String(completedTrxFilterKeyword).toLowerCase())
                );
            })
            .filter(trx => {
                if (!empty(completedSalesTrxsDateFilter)) {
                    return trx.trx_time?.includes(completedSalesTrxsDateFilter);
                } else {
                    return true;
                }
            })
            .map((trx) => {
                const { selectedSalesTrx } = this.state;
                const customerName = empty(trx.customer)
                    ? "GUEST"
                    : empty(trx.customer.user_name)
                        ? "GUEST"
                        : trx.customer.user_name;
                const customerIdentifierString = empty(trx.customer)
                    ? "No Contact Information"
                    : empty(trx.customer.user_identifier_string)
                        ? "No Contact Information"
                        : trx.customer.user_identifier_string;
                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                    trx,
                    posConfig,
                    this.currencyCode
                ).grandTotal;
                let totalQty = 0;
                if (!empty(trx.details)) {
                    trx.details.map(detail => {
                        totalQty += detail.qty;
                        return detail;
                    });
                }

                let isSelected = false;
                if (!empty(selectedSalesTrx)) {
                    if (selectedSalesTrx.local_trx_id === trx.local_trx_id) {
                        isSelected = true;
                    }
                }

                return (
                    <div
                        className={`${styles.trxItem} ${isSelected ? styles.trxItemSelected : ""}`}
                        key={`SavedTrx${trx.local_trx_id}`}
                        style={{ borderTop: 0, borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                    >
                        <table style={{ width: "100%" }}>
                            <tbody>
                            <tr onClick={() => {
                                this.setState({
                                    selectedSalesTrx: trx
                                });
                            }} className={isSelected ? styles.selectedSalesTrx : ""}>
                                <td style={{ textAlign: "center" }}>
                                    <Avatar
                                        icon="user"
                                        size="large"
                                        className={styles.trxItemAvatar}
                                    />
                                </td>
                                <td>
                                    <div className={styles.trxInfo}>
                                        [{trx.local_trx_id.toUpperCase().substr(-6)}] - {trx.trx_name}
                                    </div>
                                    <div className={styles.trxCustomerName}>
                                        {customerName}
                                    </div>
                                    <div className={styles.trxInfo}>
                                        {customerIdentifierString}
                                    </div>
                                    <div className={styles.trxInfo}>
                                        {totalQty} Produk, Total {`${this.currencyCode} `}
                                        {number_format(grandTotal, 0)}
                                    </div>
                                    <div className={styles.trxInfo}>
                                        Dibuat{" "}
                                        {moment(
                                            trx.trx_local_start_time
                                        ).format(
                                            MOMENT_READABLE_DATETIME_FORMAT
                                        )}{" "}
                                        (
                                        {moment(
                                            trx.trx_local_start_time
                                        ).fromNow()}
                                        )
                                    </div>

                                    {!empty(trx.trx_label) &&
                                        empty(trx.customer) && (
                                            <div
                                                className={styles.trxLabel}
                                            >
                                                {trx.trx_label}
                                            </div>
                                        )}
                                </td>
                            </tr>
                            {isSelected && (
                                <tr>
                                    <td colSpan={2}>
                                        <PosPriceList
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                            }}
                                            trx={trx}
                                            posConfig={trx.cashier_configs}
                                            isPrint={true}
                                            showSecretNotes={true}
                                            showStockOut={true}
                                        />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                );
            });
    };

    onFormSubmit = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            local_trx_id,
            targetType,
            consumptionType,
            selectedSalesTrx,
            inv_sloc_id,
            document_no,
            items,
            notes
        } = this.state;

        if (empty(items)) {
            message.error("Barang harus dipilih");
        } else {
            this.setState({ formLoading: true });
            apiCall(
                endpoint.POST_INVENTORY_OUT,
                "post",
                {
                    local_trx_id: local_trx_id,
                    document_no: document_no,
                    trx_type: targetType,
                    consumption_type: consumptionType,
                    pos_sales_local_trx_id: !empty(selectedSalesTrx) ? selectedSalesTrx.local_trx_id : null,
                    inv_sloc_id: inv_sloc_id,
                    notes: notes,
                    items: items.map(item => {
                        return {
                            uuid: uuid(),
                            pos_item_variant_id: item.key,
                            inv_unit_id: item.inv_unit_id,
                            qty: item.qty,
                            cogs: item.cogs
                        };
                    })
                },
                (res) => {
                    message.success("Barang berhasil dikeluarkan.");
                    this.setState({
                        ...this.initialState,
                        local_trx_id: uuid()
                    }, () => {
                        this.fetchItem("");
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }, () => {
                    if (targetType === INV_SRC_TYPE_CONSUMPTION) {
                        this.props.setPosVariables({ has_done_inventory_consumption: true });
                    }
                    this.setState({ formLoading: false });
                }
            );
        }
    };

    render() {
        const {
            document_no,
            targetType,
            consumptionType,
            savedSalesTrxs,
            pendingSalesTrxs,
            completedSalesTrxs,
            inv_sloc_id,
            selectedItem,
            fetchingItems,
            fetchedItems,
            items,
            currentStockQty,
            currentBadStockQty,
            cogs,
            qty,
            notes,
            formLoading
        } = this.state;
        const { posConfig } = this.props;
        const requestFromCashier = posConfig.subscription_rules.sales_request_to_inventory || false;

        let itemColumns;
        if (posConfig.show_cogs_on_stock_out === 1) {
            itemColumns = [
                {
                    title: "Nama Barang",
                    dataIndex: "item_name",
                    key: "item_name"
                },
                {
                    title: "Qty Baik Saat Ini",
                    dataIndex: "qty_now",
                    key: "qty_now"
                },
                {
                    title: "Qty Buruk Saat Ini",
                    dataIndex: "bad_qty_now",
                    key: "bad_qty_now"
                },
                {
                    title: "Qty Keluar",
                    dataIndex: "qty",
                    key: "qty"
                },
                {
                    title: "Unit",
                    dataIndex: "unit_name",
                    key: "unit_name"
                },
                {
                    title: "Cost",
                    dataIndex: "cogs",
                    key: "cogs",
                    render: (text, record) => (
                        <span>{number_format(text, 0)}</span>
                    )
                },
                {
                    title: "Total Cost",
                    dataIndex: "cogs",
                    key: "total_cost",
                    render: (text, record) => (
                        <span>{number_format(parseFloat(record.cogs) * parseFloat(record.qty), 0)}</span>
                    )
                },
                {
                    title: "Action",
                    key: "action",
                    render: (text, record) => (
                        <span>
                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    this.deleteItem(record.key);
                                }}>Delete</Button>
                    </span>
                    )
                }
            ];
        } else {
            itemColumns = [
                {
                    title: "Nama Barang",
                    dataIndex: "item_name",
                    key: "item_name"
                },
                {
                    title: "Qty Baik Saat Ini",
                    dataIndex: "qty_now",
                    key: "qty_now"
                },
                {
                    title: "Qty Buruk Saat Ini",
                    dataIndex: "bad_qty_now",
                    key: "bad_qty_now"
                },
                {
                    title: "Qty Keluar",
                    dataIndex: "qty",
                    key: "qty"
                },
                {
                    title: "Unit",
                    dataIndex: "unit_name",
                    key: "unit_name"
                },
                {
                    title: "Action",
                    key: "action",
                    render: (text, record) => (
                        <span>
                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    this.deleteItem(record.key);
                                }}>Delete</Button>
                    </span>
                    )
                }
            ];
        }

        const pageTitle = (
            <React.Fragment>
                <Icon type="gold"/>
                &nbsp;&nbsp; Inventory - Stok Keluar
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={12} sm={24}>
                        <Spin spinning={formLoading}>
                            <Form onSubmit={this.onFormSubmit}>
                                <div className={styles.formItem}>
                                    <label>Jenis Barang Keluar</label>
                                    <Radio.Group
                                        value={targetType}
                                        onChange={e => {
                                            this.setState({
                                                targetType: e.target.value
                                            });
                                        }}
                                        buttonStyle="solid">
                                        <Radio.Button value={INV_SRC_TYPE_CONSUMPTION}>Konsumsi</Radio.Button>
                                        <Radio.Button value={INV_SRC_TYPE_TO_BAD_STOCK}>Stok Buruk/Rusak</Radio.Button>
                                    </Radio.Group>
                                </div>

                                {requestFromCashier
                                    && targetType === INV_SRC_TYPE_CONSUMPTION && (
                                        <div className={styles.formItem}>
                                            <label>Tipe Konsumsi</label>
                                            <Radio.Group
                                                value={consumptionType}
                                                onChange={e => {
                                                    this.setState({
                                                        consumptionType: e.target.value
                                                    }, () => {
                                                        if (e.target.value === INV_CONSUMPTION_TYPE_FOR_CUSTOMER) {
                                                            this.loadSavedSalesTrxs();
                                                            this.loadPendingSalesTrxs();
                                                            this.loadCompletedSalesTrxs();
                                                        }
                                                    });
                                                }}
                                                buttonStyle="solid">
                                                <Radio.Button value={INV_CONSUMPTION_TYPE_GENERAL}>Umum</Radio.Button>
                                                <Radio.Button value={INV_CONSUMPTION_TYPE_FOR_CUSTOMER}>Untuk Customer</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    )}

                                <div className={styles.formItem}>
                                    <label>Document No.</label>
                                    <Input
                                        type="text"
                                        value={document_no}
                                        onChange={e => {
                                            this.setState({ document_no: e.target.value });
                                        }}
                                    />
                                </div>

                                <div className={styles.formItem}>
                                    <label>Lokasi Stok</label>
                                    {!empty(posConfig.storage_locations) && (
                                        <Select
                                            showSearch
                                            placeholder="Pilih Lokasi Stok Tujuan"
                                            optionFilterProp="children"
                                            value={inv_sloc_id}
                                            onChange={e => {
                                                this.setState({ inv_sloc_id: e });
                                            }}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {posConfig.storage_locations.map(row => {
                                                return <Select.Option key={`StorageLocation${row.inv_sloc_id}`} value={row.inv_sloc_id}>{row.location_name}</Select.Option>;
                                            })}

                                        </Select>
                                    )}
                                </div>

                                <div className={styles.formItem}>
                                    <label>Catatan / Keterangan</label>
                                    <Input.TextArea
                                        type="text"
                                        rows={3}
                                        onChange={e => {
                                            this.setState({ notes: e.target.value });
                                        }}
                                        value={notes}
                                    />
                                </div>

                                <h2>Detail Barang Keluar</h2>

                                <div className={styles.formItem}>
                                    <Table columns={itemColumns} dataSource={items} pagination={false}/>
                                    {posConfig.show_cogs_on_stock_out === 1 && !empty(items) && (
                                        <div style={{ textAlign: "right", marginTop: 10 }}>
                                            Cost Grand Total: <strong>{number_format(items.reduce((a, b) => a + (b.cogs * b.qty), 0), 0)}</strong>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.formItem}>
                                    <label>Tambah Barang</label>
                                    {posConfig.pos_item_search_by_sku === 1 && (
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                            <tr>
                                                <td style={{ width: "50%" }}>
                                                    <Input
                                                        placeholder="SKU produk"
                                                        onChange={
                                                            this.onChangeItemSKU
                                                        }
                                                        onKeyDown={this.handleSkuEnterPressed}
                                                        value={
                                                            this.state.itemSku
                                                        }
                                                        addonBefore={<Icon type="barcode"/>}
                                                        ref={this.itemSku}
                                                        autoFocus
                                                    />
                                                </td>
                                                <td style={{ width: "50%" }}>
                                                    <Select
                                                        showSearch={true}
                                                        placeholder="Pilih Barang"
                                                        notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                        filterOption={false}
                                                        onSearch={this.fetchItem}
                                                        onChange={e => {
                                                            this.setState({
                                                                selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                            }, this.loadSelectedItemDetails);
                                                        }}
                                                        style={{ width: "100%" }}
                                                    >
                                                        {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                                    </Select>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )}

                                    {posConfig.pos_item_search_by_sku !== 1 && (
                                        <Select
                                            showSearch={true}
                                            placeholder="Pilih Barang"
                                            notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                            filterOption={false}
                                            onSearch={this.fetchItem}
                                            onChange={e => {
                                                this.setState({
                                                    selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                }, this.loadSelectedItemDetails);
                                            }}
                                            style={{ width: "100%" }}
                                            value={empty(selectedItem) ? null : selectedItem.pos_item_variant_id}
                                        >
                                            {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                        </Select>
                                    )}
                                </div>

                                {!empty(selectedItem) && (
                                    <React.Fragment>
                                        <div className={styles.formItem}>
                                            <h2>{selectedItem.item_name}</h2>
                                        </div>
                                        <div className={styles.formItem}>
                                            <label>Stok Baik Saat Ini ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={currentStockQty}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Stok Buruk Saat Ini ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={currentBadStockQty}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Qty Barang Keluar ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                onChange={e => {
                                                    this.setState({ qty: e.target.value });
                                                }}
                                                value={qty}
                                                ref={this.qtyOut}
                                                onFocus={e => {
                                                    e.target.select();
                                                }}
                                            />
                                        </div>

                                        {posConfig.show_cogs_on_stock_out === 1 && (
                                            <div className={styles.formItem}>
                                                <label>Cost</label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={cogs}
                                                />
                                            </div>
                                        )}

                                        <div className={styles.formItem}>
                                            <Button
                                                type="primary"
                                                size="default"
                                                block={false}
                                                htmlType="button"
                                                disabled={empty(selectedItem)}
                                                onClick={this.addItem}
                                            >
                                                Masukkan ke dalam Daftar
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}

                                {/*<div className={styles.formItem}>
                                    <label>Barang</label>
                                    {posConfig.pos_item_search_by_sku === 1 && (
                                        <table style={{width: '100%'}}>
                                            <tbody>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    <Input
                                                        placeholder="SKU produk"
                                                        onChange={
                                                            this.onChangeItemSKU
                                                        }
                                                        onKeyDown={this.handleSkuEnterPressed}
                                                        addonBefore={<Icon type="barcode" />}
                                                        autoFocus
                                                    />
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    <Select
                                                        showSearch={true}
                                                        placeholder="Pilih Barang"
                                                        notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                                        filterOption={false}
                                                        onSearch={this.fetchItem}
                                                        onChange={e => {
                                                            this.setState({
                                                                selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                            }, this.loadSelectedItemDetails);
                                                        }}
                                                        style={{ width: "100%" }}
                                                        value={empty(this.state.selectedItem)?null:this.state.selectedItem.pos_item_variant_id}
                                                    >
                                                        {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                                    </Select>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )}

                                    {posConfig.pos_item_search_by_sku !== 1 && (
                                        <Select
                                            showSearch={true}
                                            placeholder="Pilih Barang"
                                            notFoundContent={fetchingItems ? <Spin size="small"/> : null}
                                            filterOption={false}
                                            onSearch={this.fetchItem}
                                            onChange={e => {
                                                this.setState({
                                                    selectedItem: fetchedItems.find(row => row.pos_item_variant_id === e)
                                                }, this.loadSelectedItemDetails);
                                            }}
                                            style={{ width: "100%" }}
                                            value={empty(this.state.selectedItem)?null:this.state.selectedItem.pos_item_variant_id}
                                        >
                                            {fetchedItems.map(row => <Select.Option key={`PosItem${row.pos_item_variant_id}`} value={row.pos_item_variant_id}>{row.item_name}</Select.Option>)}
                                        </Select>
                                    )}
                                </div>

                                {!empty(selectedItem) && (
                                    <React.Fragment>
                                        <div className={styles.formItem}>
                                            <label>Stok Baik Saat Ini ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={currentStockQty}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Stok Buruk Saat Ini ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={currentBadStockQty}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Qty Barang Keluar ({selectedItem.unit_name})</label>
                                            <Input
                                                type="text"
                                                onChange={e => {
                                                    this.setState({qty: e.target.value});
                                                }}
                                                value={qty}
                                                ref={this.qtyOut}
                                            />
                                        </div>

                                        <div className={styles.formItem}>
                                            <label>Catatan / Keterangan</label>
                                            <Input.TextArea
                                                type="text"
                                                rows={3}
                                                onChange={e => {
                                                    this.setState({notes: e.target.value});
                                                }}
                                                value={notes}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}*/}

                                <Button
                                    type="primary"
                                    size="large"
                                    block={false}
                                    htmlType="submit"
                                >
                                    Keluarkan Barang
                                </Button>
                            </Form>
                        </Spin>
                    </Col>
                    <Col xxl={12} sm={24}>
                        {consumptionType === INV_CONSUMPTION_TYPE_FOR_CUSTOMER && (
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Transaksi Tertunda" disabled={pendingSalesTrxs === null} key="1">
                                    {pendingSalesTrxs === null && (<Spin/>)}
                                    {pendingSalesTrxs !== null && empty(pendingSalesTrxs) && (<Empty/>)}
                                    {this.renderSalesTrxs(pendingSalesTrxs)}
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Transaksi Selesai" key="2">
                                    {completedSalesTrxs === null && (<Spin/>)}
                                    {completedSalesTrxs !== null && empty(completedSalesTrxs) && (<Empty/>)}
                                    {completedSalesTrxs !== null && (
                                        <div style={{ marginBottom: 10 }}>
                                            <DatePicker placeholder="Filter Tanggal Transaksi"
                                                        format={"YYYY-MM-DD"}
                                                        onChange={(momentDate, dateString) => {
                                                            this.loadCompletedSalesTrxs(dateString);
                                                            this.setState({
                                                                completedSalesTrxsDateFilter: dateString
                                                            });
                                                        }}
                                                        showToday={true}
                                                        allowClear={true}
                                                        style={{ width: "100%" }}
                                                        disabledDate={current => {
                                                            return current < moment().subtract(7, "days") || current > moment().endOf("day");
                                                        }}
                                            />
                                        </div>
                                    )}
                                    {!empty(completedSalesTrxs) && (
                                        <div style={{ marginBottom: 20 }}>
                                            <Input.Search
                                                placeholder="Kata kunci pencarian.."
                                                onChange={e => {
                                                    this.setState({ completedTrxFilterKeyword: e.target.value });
                                                }}
                                                onSearch={value => {
                                                    this.setState({ completedTrxFilterKeyword: value });
                                                }}
                                                style={{ width: "100%" }}
                                                allowClear={true}
                                            />
                                        </div>
                                    )}
                                    {this.renderSalesTrxs(completedSalesTrxs)}
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Transaksi Tersimpan" disabled={savedSalesTrxs === null} key="3">
                                    {savedSalesTrxs === null && (<Spin/>)}
                                    {savedSalesTrxs !== null && empty(savedSalesTrxs) && (<Empty/>)}
                                    {this.renderSalesTrxs(savedSalesTrxs)}
                                </Tabs.TabPane>
                            </Tabs>

                        )}
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData,
        appConfig: state.authState.configs,
        posTrxs: state.posState.posTrx
    };
};

const mapDispatchToProps = {
    setPosVariables: posOperations.setPosVariables
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryStockOut);
