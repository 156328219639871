import React, { Component } from "react";
import PropTypes from "prop-types";
import { Affix, Button, Card, Col, DatePicker, Empty, Icon, Row, Spin, Input, message } from "antd";
import { apiCall } from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./TrxHistory.module.scss";
import { empty } from "../shared/helpers/generalHelper";
import { DEFAULT_CURRENCY_SIGN, MOMENT_READABLE_DATETIME_FORMAT, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT, TRX_TYPE_NORMAL } from "../shared/utils/constants";
import connect from "react-redux/es/connect/connect";
import { nl2brReact, number_format } from "../shared/helpers/stringHelper";
import moment from "moment";
import { PosPriceList } from "../modules";
import { Link } from "react-router-dom";
import ErrorHandler from "../shared/classes/ErrorHandler";
import PosPriceCalculator from "../shared/classes/PosPriceCalculator";
import Synchronizer from "../shared/classes/Synchronizer";
import { posOperations } from "../state/ducks/pos";

class TrxHistory extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            sendReceiptLoading: false,
            trxs: [],
            total_trx_count: 0,
            defaultPageSize: 20,
            currentPage: 1,
            trxSelected: null,
            filterKeyword: '',
            receiptPhone: '',
            receiptMail: ''
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        this.loadTrxs();
    }

    setSendReceiptLoading = state => {
        this.setState({
            sendReceiptLoading: state
        });
    };

    loadTrxs = (trxDate = moment().format(MOMENT_SQL_DATE_FORMAT)) => {
        this.setState({ formLoading: true });

        Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            true,
            () => {
                apiCall(
                    endpoint.GET_TRX_HISTORY + `?start_date=${trxDate}&end_date=${trxDate}`,
                    "get",
                    null,
                    result => {
                        this.setState({
                            trxs: result.data.trxs,
                            total_trx_count: result.data.total_trx_count
                        });
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                    },
                    res => {
                        this.setState({
                            formLoading: false,
                            trxSelected: null
                        });
                    }
                );
            }
        ).catch(err => {
            message.error("Gagal melakukan proses sinkronisasi, silakan mencoba kembali dengan refresh halaman ini.");
            this.setState({ formLoading: false });
        });
    };

    /*loadTrxs = (page=1, pageSize=this.state.defaultPageSize) => {
        this.setState({ formLoading: true });
        const startRow = (page - 1) * pageSize;

        apiCall(
            endpoint.GET_TRX_HISTORY + `?start_row=${startRow}&num_of_rows=${pageSize}`,
            "get",
            null,
            result => {
                this.setState({
                    trxs: result.data.trxs,
                    total_trx_count: result.data.total_trx_count
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({
                    formLoading: false,
                    trxSelected: null
                });
            }
        );
    };*/

    onSendSmsReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "phone",
                    phone
                },
                result => {
                    message.success("SMS Berhasil dikirimkan");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    onSendWhatsAppReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            let parsedPhone = phone;
            if (phone[0] !== "0" && phone.substring(0, 1) !== "62") {
                parsedPhone = "62" + phone;
            }
            if (phone[0] === "0") {
                parsedPhone = "62" + phone.substring(1);
            }

            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "text",
                    phone
                },
                result => {
                    const waText = `phone=${parsedPhone}&text=${result.data.message}`;
                    const waURL = "https://api.whatsapp.com/send?" + waText;
                    window.open(waURL, "_blank");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    onSendWhatsAppDesktopReceipt = (local_trx_id, phone) => {
        if (empty(phone)) {
            message.error("Phone number is required");
        } else if (!/^[0-9]+$/.test(phone)) {
            message.error("Phone number can only contain numbers");
        } else {
            let parsedPhone = phone;
            if (phone[0] !== "0" && phone.substring(0, 1) !== "62") {
                parsedPhone = "62" + phone;
            }
            if (phone[0] === "0") {
                parsedPhone = "62" + phone.substring(1);
            }

            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "text",
                    phone
                },
                result => {
                    const waText = `phone=${parsedPhone}&text=${result.data.message}`;
                    const waURL = "whatsapp://send?" + waText;
                    window.open(waURL, "_blank");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    onSendEmailReceipt = (local_trx_id, email) => {
        if (empty(email)) {
            message.error("Email is required");
        } else {
            this.setSendReceiptLoading(true);
            apiCall(
                endpoint.POST_POS_RECEIPT_SEND,
                "post",
                {
                    local_trx_id,
                    receipt_type: "email",
                    email
                },
                result => {
                    message.success("Email Berhasil dikirimkan");
                },
                error => {
                    ErrorHandler.handleGeneralError(error);
                },
                () => {
                    this.setSendReceiptLoading(false);
                });
        }
    };

    render() {
        const {
            formLoading, trxs, trxSelected, filterKeyword, sendReceiptLoading
        } = this.state;

        const { posConfig, cashierConfig } = this.props;

        const pageTitle = (
            <React.Fragment>
                <Icon type="database"/>
                &nbsp;&nbsp; Riwayat Transaksi
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false} style={{minHeight: '100vh'}}>
                <Row gutter={48}>
                    <Col xxl={12} sm={12}>
                        <Spin spinning={formLoading}>
                            {/*<div className={styles.formGroup}>
                                        <DatePicker placeholder="Sejak Tanggal"
                                                    format={dateFormat}
                                                    onChange={(momentDate, dateString) => {
                                                        this.setState({
                                                            userTrxDate: dateString
                                                        });
                                                    }}
                                                    showToday={true}
                                                    allowClear={true}
                                                    style={{ marginRight: 10 }}
                                        />
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            onClick={this.loadCustomerTrxs}
                                        >
                                            Tampilkan Transaksi
                                        </Button>
                                    </div>*/}

                            <div style={{marginBottom: 20}}>
                            <DatePicker placeholder="Filter Tanggal Transaksi"
                                        format={"YYYY-MM-DD"}
                                        onChange={(momentDate, dateString) => {
                                            this.loadTrxs(dateString);
                                            this.setState({
                                                userTrxDate: dateString
                                            });
                                        }}
                                        showToday={true}
                                        allowClear={true}
                                        style={{width: '100%'}}
                                        disabledDate={current => {
                                            return current < moment().subtract(40, 'days') || current > moment().endOf('day');
                                        }}
                            />
                            </div>

                            {/*{!empty(total_trx_count) && (
                                <div style={{margin: '20px 0'}}>
                                    <Pagination simple
                                                defaultCurrent={1}
                                                current={currentPage}
                                                pageSize={defaultPageSize}
                                                total={total_trx_count}
                                                onChange={(page, pageSize) => {
                                                    this.loadTrxs(page, pageSize);
                                                    this.setState({currentPage: page});
                                                }}
                                    />
                                </div>
                            )}*/}

                            {empty(trxs) && (
                                <Empty />
                            )}

                            {!empty(trxs) && (
                                <div style={{marginBottom: 20}}>
                                    <Input.Search
                                        placeholder="Kata kunci pencarian.."
                                        onChange={e => {
                                            this.setState({filterKeyword: e.target.value});
                                        }}
                                        onSearch={value => {
                                            this.setState({filterKeyword: value});
                                        }}
                                        style={{ width: '100%' }}
                                        allowClear={true}
                                    />
                                </div>
                            )}

                            {!empty(trxs) &&
                            trxs.filter(trx => {
                                if(empty(filterKeyword)){
                                    return true;
                                }

                                const customerName = String(
                                    empty(trx.customer)
                                        ? 'GUEST'
                                        : empty(trx.customer.user_name)
                                        ? 'GUEST'
                                        : trx.customer.user_name
                                );
                                const customerIdentifierString = String(
                                    empty(trx.customer)
                                        ? 'No Contact Information'
                                        : empty(trx.customer.user_identifier_string)
                                        ? 'No Contact Information'
                                        : trx.customer.user_identifier_string
                                );
                                const grandTotal = PosPriceCalculator.calculateGrandTotal(
                                    trx,
                                    this.props.posConfig,
                                    this.currencyCode
                                ).grandTotal;
                                let totalQty = 0;
                                if (!empty(trx.details)) {
                                    trx.details.map(detail => {
                                        totalQty += detail.qty;
                                        return detail;
                                    });
                                }
                                const totalQtyString = number_format(totalQty, this.props.posConfig.qty_decimal_length, this.decimalSeparator, this.thousandSeparator);
                                const totalString = `${totalQtyString} Produk, Total ${this.currencyCode} ${number_format(
                                    grandTotal,
                                    0
                                )}`;
                                const timeString = `${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).format(
                                    MOMENT_READABLE_DATETIME_FORMAT
                                )} (${moment(trx.trx_local_start_time, MOMENT_SQL_DATETIME_FORMAT).fromNow()})`;
                                return (
                                    String(customerName).toLowerCase().includes(String(filterKeyword).toLowerCase()) ||
                                    String(customerIdentifierString).toLowerCase().includes(String(filterKeyword).toLowerCase()) ||
                                    String(trx.local_trx_id).toLowerCase().includes(String(filterKeyword).toLowerCase()) ||
                                    String(totalString).toLowerCase().includes(String(filterKeyword).toLowerCase()) ||
                                    String(timeString).toLowerCase().includes(String(filterKeyword).toLowerCase()) ||
                                    String(trx.trx_label).toLowerCase().includes(String(filterKeyword).toLowerCase())
                                );
                            })
                                .map(trx => {
                                return (
                                    <Card className={styles.trxHistoryCard}
                                          key={`TrxHistory${trx.local_trx_id}`}
                                          onClick={() => {
                                              let receiptPhone = "";
                                              let receiptMail = "";
                                              if (!empty(trx.customer)) {
                                                  if (trx.customer.user_identifier_type === "phone") {
                                                      receiptPhone = trx.customer.user_identifier_string;
                                                  }

                                                  if (!empty(trx.customer.user_email_notification)) {
                                                      receiptMail = trx.customer.user_email_notification;
                                                  }
                                              }

                                              this.setState({
                                                  trxSelected: trx,
                                                  receiptPhone,
                                                  receiptMail
                                              });


                                          }}
                                    >
                                        <h4>{moment(trx.trx_local_end_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_READABLE_DATETIME_FORMAT)}</h4>
                                        <div>
                                            Transaksi #{trx.local_trx_id.slice(-6).toUpperCase()}<br/>
                                            {!empty(trx.order_no) && (
                                                <div>Order No. {trx.order_no}</div>
                                            )}
                                            <strong>{!empty(trx.customer)?trx.customer.user_name:empty(trx.trx_label)?'GUEST':trx.trx_label}</strong><br/>
                                            {this.currencyCode} {number_format(trx.drv_grand_total)}
                                            {!empty(trx.trx_notes) && (
                                                <React.Fragment>
                                                    <br/>
                                                    Catatan:
                                                    <div style={{ paddingLeft: 5 }}>
                                                        {nl2brReact(trx.trx_notes)}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        {this.props.posConfig.auth_role_accesses.TRX_WORKER_EDIT === 1 && trx.trx_type === TRX_TYPE_NORMAL && (
                                            <div style={{marginTop:10}}>
                                                <Link to={`/trx-edit/${trx.local_trx_id}`}>
                                                    <Button type={"primary"}>Edit</Button>
                                                </Link>
                                            </div>
                                        )}
                                    </Card>
                                );
                            })}

                            {/*{!empty(total_trx_count) && (
                                <div style={{margin: '20px 0'}}>
                                    <Pagination simple
                                                defaultCurrent={1}
                                                current={currentPage}
                                                pageSize={defaultPageSize}
                                                total={total_trx_count}
                                                onChange={(page, pageSize) => {
                                                    this.loadTrxs(page, pageSize);
                                                    this.setState({currentPage: page});
                                                }}
                                    />
                                </div>
                            )}*/}
                        </Spin>
                    </Col>

                    <Col xxl={12} sm={12}>
                        {!empty(trxSelected) && (
                            <Affix offsetTop={30} className={styles.trxHistoryAffix}>
                                <PosPriceList
                                    style={{
                                        marginTop: 0
                                    }}
                                    trx={trxSelected}
                                    isPrint={true}
                                    showSecretNotes={true}
                                    showSeller={true}
                                    showWorker={true}
                                />
                                <div style={{textAlign: 'center', marginTop: 20}}>
                                    <Button onClick={() => {
                                        window.open('trx-history/' + trxSelected.local_trx_id, '_blank', 'rel=noopener noreferrer');
                                    }}
                                            icon="printer"
                                            htmlType="button"
                                            type="primary">Print</Button>
                                </div>

                                {posConfig.pos_print_digital_invoice === 1 && (
                                    <div style={{textAlign: "center"}}>
                                        <Spin spinning={sendReceiptLoading}>
                                        <br/>

                                        {posConfig.is_sms_receipt === 1 && (
                                            <React.Fragment>
                                                <Input.Search
                                                    placeholder="No. HP"
                                                    enterButton="SMS"
                                                    style={{ margin: 10, width: '60%' }}
                                                    value={this.state.receiptPhone}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            receiptPhone: e.target.value
                                                        });
                                                    }}
                                                    onSearch={val => {
                                                        this.onSendSmsReceipt(trxSelected.local_trx_id, val);
                                                    }}
                                                />

                                                <br/>
                                            </React.Fragment>
                                        )}

                                        {cashierConfig.displayMode === "DESKTOP" && (
                                            <>
                                                <Input.Search
                                                    placeholder="No. HP"
                                                    enterButton="WA Web"
                                                    style={{ margin: 10, width: '60%' }}
                                                    value={this.state.receiptPhone}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            receiptPhone: e.target.value
                                                        });
                                                    }}
                                                    onSearch={val => {
                                                        this.onSendWhatsAppReceipt(trxSelected.local_trx_id, val);
                                                    }}
                                                />

                                                <br/>
                                            </>
                                        )}

                                        <Input.Search
                                            placeholder="No. HP"
                                            enterButton="WA App"
                                            style={{ margin: 10, width: '60%' }}
                                            value={this.state.receiptPhone}
                                            onChange={(e) => {
                                                this.setState({
                                                    receiptPhone: e.target.value
                                                });
                                            }}
                                            onSearch={val => {
                                                this.onSendWhatsAppDesktopReceipt(trxSelected.local_trx_id, val);
                                            }}
                                        />

                                        <br/>

                                        <Input.Search
                                            placeholder="Email Address"
                                            enterButton="e-Mail"
                                            style={{ margin: 10, width: '60%' }}
                                            value={this.state.receiptMail}
                                            onChange={(e) => {
                                                this.setState({
                                                    receiptMail: e.target.value
                                                });
                                            }}
                                            onSearch={val => {
                                                this.onSendEmailReceipt(trxSelected.local_trx_id, val);
                                            }}
                                        />

                                        <br/>
                                        </Spin>
                                    </div>
                                )}
                            </Affix>
                        )}
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posState: state.posState,
        posConfig: state.authState.userData,
        cashierConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrxHistory);