import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, DatePicker, Input, message, Row, Select, Spin, TimePicker } from "antd";
import { posOperations } from "../state/ducks/pos";
import { empty } from "../shared/helpers/generalHelper";
import { REST_APPOINTMENT } from "../shared/utils/endpoints";
import { apiCall } from "../shared/helpers/networkHelper";
import styles from "./AppointmentSales.module.scss";
import { MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT, MOMENT_SQL_TIME_FORMAT_MINUTE, TRX_TYPE_NORMAL } from "../shared/utils/constants";
import PosHeader from "../shared/components/PosHeader/PosHeader";
import queryString from "qs";
import moment from "moment";
import ErrorHandler from "../shared/classes/ErrorHandler";
import uuid from "uuid/v4";

class AppointmentSales extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        const { posConfig } = this.props;

        const default_appointment_start_time_moment = moment();
        const rounded_default_appointment_start_time = default_appointment_start_time_moment.minute() || default_appointment_start_time_moment.second() || default_appointment_start_time_moment.millisecond() ? default_appointment_start_time_moment.add(1, 'hour').startOf('hour') : default_appointment_start_time_moment.startOf('hour');

        this.initialState = {
            page: "appointment-metadata-form",
            pageTitle: "GD Business - Appointment",
            formLoading: false,
            formLoadingMessage: "",
            appointment: null,
            local_appointment_id: this.props.match.params.appointment_id,
            business_worker: null,
            sales_type: posConfig.sales_type[0],
            appointment_date: moment().format(MOMENT_SQL_DATE_FORMAT),
            appointment_start_time: rounded_default_appointment_start_time,
            appointment_end_time: null,
            appointment_notes: "",
            pos_local_trx_id: null,
            pos_trx: null
        };
        this.state = { ...this.initialState };
    }

    componentDidMount() {
        const parsedQueryString = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
        const { local_appointment_id } = this.state;

        if (local_appointment_id === "new") {
            if (!empty(parsedQueryString.worker_id)) {
                const worker_id = parseInt(parsedQueryString.worker_id);
                const preset_worker = this.props.posConfig.commission_worker.find(commission_worker => {
                    return commission_worker.business_commission_worker_id === worker_id;
                });

                if (!empty(preset_worker)) {
                    this.setState({
                        business_worker: preset_worker
                    });
                }
            }

            if (!empty(parsedQueryString.appointment_date)) {
                this.setState({
                    appointment_date: moment(parsedQueryString.appointment_date, MOMENT_SQL_DATE_FORMAT).format(MOMENT_SQL_DATE_FORMAT)
                });
            }

            if (!empty(parsedQueryString.start_time)) {
                this.setState({
                    appointment_start_time: moment(parsedQueryString.start_time, MOMENT_SQL_TIME_FORMAT_MINUTE).format(MOMENT_SQL_TIME_FORMAT_MINUTE),
                    appointment_end_time: moment(parsedQueryString.start_time, MOMENT_SQL_TIME_FORMAT_MINUTE).add(1, "hour").format(MOMENT_SQL_TIME_FORMAT_MINUTE)
                });
            }
        } else {
            const appointment = this.props.appointments.find(appointment => appointment.local_appointment_id === local_appointment_id);

            if(!empty(appointment)){
                this.setState({
                    appointment,
                    business_worker: this.findWorkerById(appointment.business_commission_worker_id),
                    sales_type: this.findSalesTypeById(appointment.pos_salesType_id),
                    appointment_date: moment(appointment.appointment_date, MOMENT_SQL_DATE_FORMAT).format(MOMENT_SQL_DATE_FORMAT),
                    appointment_start_time: moment(appointment.start_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_SQL_TIME_FORMAT_MINUTE),
                    appointment_end_time: moment(appointment.end_time, MOMENT_SQL_DATETIME_FORMAT).format(MOMENT_SQL_TIME_FORMAT_MINUTE),
                    appointment_notes: appointment.appointment_notes,
                    pos_local_trx_id: appointment.pos_local_trx_id,
                    pos_trx: appointment.pos_trx || null
                });
            }
        }
    }

    showPageLoader = (message = "") => {
        this.setState({
            formLoading: true,
            formLoadingMessage: message
        });
    };

    hidePageLoader = () => {
        this.setState({
            formLoading: false
        });
    };

    onChangePosSalesType = (value) => {
        this.setState({
            sales_type: this.props.posConfig.sales_type.find(sales_type => sales_type.pos_salesType_id === value)
        });
    };

    findWorkerById = business_commission_worker_id => {
        return this.props.posConfig.commission_worker.find(worker => worker.business_commission_worker_id === business_commission_worker_id);
    };

    findSalesTypeById = pos_salesType_id => {
        return this.props.posConfig.sales_type.find(sales_type => sales_type.pos_salesType_id === pos_salesType_id);
    };

    onChangeWorker = (value) => {
        this.setState({
            business_worker: this.props.posConfig.commission_worker.find(commission_worker => commission_worker.business_commission_worker_id === value)
        });
    };

    onChangeAppointmentDate = (date, dateString) => {
        this.setState({
            appointment_date: dateString
        });
    };

    onChangeAppointmentStartTime = (time, timeString) => {
        this.setState({
            appointment_start_time: timeString
        });
    };

    onChangeAppointmentEndTime = (time, timeString) => {
        this.setState({
            appointment_end_time: timeString
        });
    };

    onChangeAppointmentNotes = e => {
        this.setState({
            appointment_notes: e.target.value
        });
    };

    injectDuration = durationMinutes => {
        const startTimeMoment = moment(this.state.appointment_start_time, MOMENT_SQL_TIME_FORMAT_MINUTE);
        const newEndTime = startTimeMoment.add(durationMinutes, 'minutes');
        this.setState({
            appointment_end_time: newEndTime.format(MOMENT_SQL_TIME_FORMAT_MINUTE)
        });
    };

    onSaveAppointment = () => {
        const {
            appointment,
            local_appointment_id,
            sales_type,
            business_worker,
            appointment_date,
            appointment_start_time,
            appointment_end_time,
            appointment_notes,
            pos_local_trx_id,
            pos_trx
        } = this.state;
        let isValid = true;

        if (empty(sales_type)) {
            isValid = false;
            message.error("Sales type harus dipilih");
        }

        if (empty(business_worker)) {
            isValid = false;
            message.error("Worker harus dipilih");
        }

        if (empty(appointment_date)) {
            isValid = false;
            message.error("Tanggal appointment harus dipilih");
        }

        if (empty(appointment_start_time)) {
            isValid = false;
            message.error("Waktu mulai appointment harus dipilih");
        }

        if (empty(appointment_end_time)) {
            isValid = false;
            message.error("Waktu selesai appointment harus dipilih");
        }

        if (isValid) {
            this.showPageLoader("Menyimpan appointment..");

            if(local_appointment_id === 'new'){
                const appointment_id = uuid();
                const new_pos_local_trx_id = uuid();

                const formData = {
                    local_appointment_id: appointment_id,
                    user_code: null,
                    user_name: 'Guest',
                    pos_salesType_id: sales_type.pos_salesType_id,
                    business_commission_worker_id: business_worker.business_commission_worker_id,
                    appointment_date: appointment_date,
                    start_time: appointment_start_time,
                    end_time: appointment_end_time,
                    appointment_notes: appointment_notes,
                    pos_local_trx_id: new_pos_local_trx_id,
                    pos_trx: null
                };

                apiCall(
                    REST_APPOINTMENT,
                    "post",
                    formData,
                    res => {
                        this.setState({
                            local_appointment_id: appointment_id,
                            pos_local_trx_id: new_pos_local_trx_id
                        }, () => {
                            Promise.all([
                                this.props.setNewAppointment(res.data.appointment),
                                this.props.setNewLocalTrx({
                                    local_trx_id: new_pos_local_trx_id,
                                    local_appointment_id: appointment_id,
                                    trx_status_id: 1,
                                    trx_type: TRX_TYPE_NORMAL,
                                    pos_salesType_id: sales_type.pos_salesType_id,
                                    trx_name: sales_type.pos_salesType_name
                                })
                            ]).then(() => {
                                message.success('Appointment berhasil dibuat, silakan masukkan customer dan detail penjualan.');
                                this.redirectToPos(new_pos_local_trx_id);
                            });
                        });

                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                    },
                    res => {
                        this.hidePageLoader();
                    }
                );
            }else{
                const formData = {
                    local_appointment_id: local_appointment_id,
                    user_code: appointment.user_code,
                    user_name: appointment.user_name,
                    pos_salesType_id: sales_type.pos_salesType_id,
                    business_commission_worker_id: business_worker.business_commission_worker_id,
                    appointment_date: appointment_date,
                    start_time: appointment_start_time,
                    end_time: appointment_end_time,
                    appointment_notes: appointment_notes,
                    pos_local_trx_id: pos_local_trx_id,
                    pos_trx: empty(pos_trx)? null : {
                        ...pos_trx,
                        pos_salesType_id: sales_type.pos_salesType_id
                    }
                };

                apiCall(
                    REST_APPOINTMENT,
                    "put",
                    formData,
                    res => {
                        Promise.all([
                            this.props.setAppointmentProps(local_appointment_id, res.data.appointment),
                            this.props.setTrxProps(pos_local_trx_id, {
                                pos_salesType_id: sales_type.pos_salesType_id
                            })
                        ]).then(() => {
                            if(empty(pos_trx)){
                                message.success('Data appointment berhasil diupdate, silakan masukkan customer dan detail penjualan.');
                                this.redirectToPos(pos_local_trx_id);
                            }else{
                                message.success('Data appointment berhasil diupdate.');
                            }
                        });
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                    },
                    res => {
                        this.hidePageLoader();
                    }
                );
            }
        }
    };

    redirectToPos = (local_trx_id) => {
        Promise.all([
            this.props.switchCurrentTrx(local_trx_id)
        ]).then(() => {
            this.props.history.push("/pos?next=true");
        });

    };

    redirectToAppointment = () => {
        this.props.history.push("/appointment");
    };

    render() {
        const {
            page,
            pageTitle,
            formLoading,
            formLoadingMessage,
            sales_type,
            business_worker,
            appointment_date,
            appointment_start_time,
            appointment_end_time,
            appointment_notes,
            pos_trx,
            pos_local_trx_id
        } = this.state;
        const { posConfig } = this.props;

        return (
            <Spin spinning={formLoading} tip={formLoadingMessage}>
                {page === "appointment-metadata-form" && (
                    <React.Fragment>
                        <Row>
                            <Col span={24}>
                                <PosHeader
                                    pageTitle={pageTitle}
                                    backBtnAction={this.navigateToAppointmentPage}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className={styles.appointmentFormContainer}>
                                    <div className={styles.formItem}>
                                        <label>Sales Type</label>
                                        <Select className={styles.formControl}
                                                defaultValue={sales_type.pos_salesType_id}
                                                placeholder="Pilih sales type.."
                                                onChange={this.onChangePosSalesType}
                                        >
                                            {
                                                posConfig.sales_type.map(row => {
                                                    return (
                                                        <Select.Option key={`SalesType-${row.pos_salesType_id}`}
                                                                       value={row.pos_salesType_id}>
                                                            {row.pos_salesType_name}
                                                        </Select.Option>
                                                    );
                                                })
                                            }

                                        </Select>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Worker</label>
                                        <Select className={styles.formControl}
                                                value={empty(business_worker) ? null : business_worker.business_commission_worker_id}
                                                placeholder="Pilih worker.."
                                                onChange={this.onChangeWorker}
                                        >
                                            {
                                                posConfig.commission_worker.map(row => {
                                                    return (
                                                        <Select.Option key={`Worker-${row.business_commission_worker_id}`}
                                                                       value={row.business_commission_worker_id}>
                                                            {row.firstname} {row.lastname}
                                                        </Select.Option>
                                                    );
                                                })
                                            }

                                        </Select>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Tanggal</label>
                                        <DatePicker value={moment(appointment_date, MOMENT_SQL_DATE_FORMAT)}
                                                    onChange={this.onChangeAppointmentDate}/>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Waktu Mulai</label>
                                        <TimePicker format={"HH:mm"}
                                                    minuteStep={15}
                                                    value={moment(appointment_start_time, MOMENT_SQL_TIME_FORMAT_MINUTE)}
                                                    onChange={this.onChangeAppointmentStartTime}
                                        />
                                    </div>

                                    <div className={`${styles.formItem} ${styles.durationButtons}`}>
                                        <Button type={"dashed"} onClick={()=>{this.injectDuration(30);}}>30 min</Button>
                                        <Button type={"dashed"} onClick={()=>{this.injectDuration(60);}}>60 min</Button>
                                        <Button type={"dashed"} onClick={()=>{this.injectDuration(90);}}>90 min</Button>
                                        <Button type={"dashed"} onClick={()=>{this.injectDuration(120);}}>120 min</Button>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Waktu Selesai</label>
                                        <TimePicker format={"HH:mm"}
                                                    minuteStep={15}
                                                    value={empty(appointment_end_time) ? null : moment(appointment_end_time, MOMENT_SQL_TIME_FORMAT_MINUTE)}
                                                    onChange={this.onChangeAppointmentEndTime}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Catatan</label>
                                        <Input.TextArea value={appointment_notes}
                                                        onChange={this.onChangeAppointmentNotes}
                                                        rows={5}/>
                                    </div>

                                    {empty(pos_trx) && (
                                        <div className={styles.formItem}>
                                            {/*{empty(pos_local_trx_id) && (
                                                <Button className={styles.actionButton} onClick={this.onSaveAppointment} type={"primary"}>Tambahkan Detail Sales</Button>
                                            )}

                                            {!empty(pos_local_trx_id) && (
                                                <Button className={styles.actionButton} onClick={this.onSaveAppointment} type={"primary"}>Simpan Data Appointment</Button>
                                            )}*/}

                                            <Button className={styles.actionButton} onClick={this.onSaveAppointment} type={"primary"}>Simpan dan Tambahkan Detail Sales</Button>

                                            {/*<Button className={styles.actionButton} onClick={this.onSaveAppointment} type={"primary"}>Simpan Data Appointment</Button>*/}

                                            <Button className={styles.actionButton} onClick={this.redirectToAppointment} type={"danger"}>Kembali ke Halaman Appointment</Button>
                                        </div>
                                    )}

                                    {!empty(pos_trx) && (
                                        <div className={styles.formItem}>
                                            <Button className={styles.actionButton} onClick={this.onSaveAppointment} type={"primary"}>Simpan Data Appointment</Button>
                                            <Button className={styles.actionButton} onClick={() => {this.redirectToPos(pos_local_trx_id)}} type={"primary"}>Ubah Detail Sales</Button>
                                            <Button className={styles.actionButton} onClick={this.redirectToAppointment} type={"danger"}>Kembali ke Halaman Appointment</Button>
                                        </div>
                                    )}


                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    let currentTrx = {};
    if (!empty(currentLocalTrxId)) {
        currentTrx = state.posState.posTrx.find(
            trx => trx.local_trx_id === currentLocalTrxId
        );
    }

    return {
        currentLocalTrxId,
        currentTrx,
        posTrx: state.posState.posTrx,
        appointments: state.posState.appointments,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    setNewAppointment: posOperations.setNewAppointment,
    setAppointmentProps: posOperations.setAppointmentProps,
    setNewLocalTrx: posOperations.setNewLocalTrx,
    setTrxProps: posOperations.setTrxProps,
    switchCurrentTrx: posOperations.switchCurrentTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentSales);
